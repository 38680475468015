import React, { useState } from "react";
import moment from "moment";
import { useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { Icon } from "../../../Utilities/Icons";
import { HealthCareBookingDetails, clearCartItem, clearSelectedMember } from "../../../Store/Reducer/LabtestSlice";
import { clearSelectedCouponCode } from "../../../Store/Reducer/BookingSlice";
import PaymentSuccessfullModel from "../LabTestModel/PaymentSuccessfullModel";
import { HealthCareBookingCreate } from "../../../Store/Reducer/RadiologySlice";
import { Logo } from "../../../Utilities/Icons";
import { useFormik } from "formik";
import HealthPaymentSucessFully from "./HealthPaymentSucessFully";

const PayModal = (props) => {
    
    const { session } = useSelector(({ AuthSlice }) => AuthSlice);
    const [successfullModel, setSuccessfullModel] = useState(false);
    const [rzorPayModal, setRazorPayModal] = useState(false);
    const [detailpay, SetDetailpay] = useState();
    const dispatch = useDispatch();
    useEffect(() => {
        if (rzorPayModal) {
            const script = document.createElement("script");
            script.src = "https://checkout.razorpay.com/v1/checkout.js";
            script.async = true;
            document.body.appendChild(script);
        }

    }, [rzorPayModal]);
    const {
        healthbooking
      } = useSelector(({ RadiologySlice }) => RadiologySlice);
    const options = {
        key: process.env.REACT_APP_PAYMENT_KEY,
        key_secret: process.env.REACT_APP_PAYMENT_SECRET_KEY,
        prefill: {
            name: session?.name,
            contact: session?.mobile_number,
            email: session?.email,
        },
        notes: {
            address: "some address",
        },
        theme: {
            color: "#F37254",
            hide_topbar: false,
        },
    };
    const openPayModal = (options) => {

        var rzp1 = new window.Razorpay(options);
       
        rzp1.open();
    };
    const createBooking = (values, resetForm) => {
        try {
          
            let tempOptions = {
                ...options,
                amount: Math.round(props?.paidData?.total_amount) * 100,
                currency: "INR",
                name: "THS-User",
                description: "some description",
                image: Logo.THS_logo,
                handler: async function (response) {
                    
                    if (response) {
                        let newValues = {
                            ...values,
                            transaction_id: response.razorpay_payment_id,
                            total_amount: props?.paidData?.grand_total,
                            total_paid_amount: props?.paidData?.grand_total,
                        };
                        
                        dispatch(HealthCareBookingCreate(newValues)).then((res) => {
                            SetDetailpay(res?.payload)
                            if (healthbooking?.order_id) {
                                dispatch(HealthCareBookingDetails(healthbooking?.order_id)).then((res) => {
                           
                                    if (res?.payload) {
                                        dispatch(clearCartItem());
                                        dispatch(clearSelectedCouponCode());
                                        dispatch(clearSelectedMember());
                                        setSuccessfullModel(true);

                                        // resetForm();
                                        // setTotalAmount("");
                                    }
                                });

                            }
                        });
                    }
                },
            };
            openPayModal(tempOptions);

        } catch (error) { }
    };


    const initialValues = {
        member_id: props?.paidData?.member_id,
        discount: props?.paidData?.discount,
        wallet_amount: props?.paidData?.wallet_amount,
        ADDRESS: "",
        MEMBER: "",
        address_id: "",
        termsAndConditions: false,
        transaction_id: "",
        convenience_fee: props?.paidData?.convenience_fee,
        type: 2,
        gold_discount: props?.paidData?.gold_discount,
        service: "1",
        total_amount: props?.paidData?.grand_total,
        total_paid_amount: props?.paidData?.grand_tota
    }

const {healthCareBookingDetailsById} = useSelector(
    ({ LabtestSlice }) => LabtestSlice
  );

    return (
        <>
            <HealthPaymentSucessFully
                show={successfullModel}
                onHide={() => setSuccessfullModel(false)}
                detailpay={detailpay}

            />

            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                dialogClassName="lab_test_modal_box"
                backdrop="static"

            >
                <Modal.Header
                    className="lab_test_modal_box_head_padding"
                    style={{
                        backgroundColor: " #F4F4F4"
                    }}
                >
                    <h5 className="lab_test_popup_title">Id #{props?.paidData?.order_id}</h5>
                    <img
                        src={Icon.cross}
                        className="lab_test_popup_close"
                        onClick={props.onHide}
                        alt=""
                    ></img>
                </Modal.Header>
                <Modal.Body className=""
                    style={{
                        backgroundColor: " #F4F4F4"
                    }}>
                    <div>
                        <div className="payment_done_card_box mb_10">
                            <h3 className="payment_done_payment_info">Service</h3>
                            <hr className="payment_done_hr" />
                            <div className="row">
                                <div className="">
                                    <p className="docname">For {props?.paidData?.member_details?.name} </p>
                                    <button className="carebtn">{props?.paidData?.category_name}</button>
                                    <div className="nameflex">
                                        <p className="careparagraph pt_10">{props?.paidData?.service?.map((val) => {
                                                        return (
                                                         val?.name
                                                        )
                                                    })}</p>
                                        <h5 className="payment_done_payment_info_value">
                                            ₹ {props?.paidData?.total_amount}
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="payment_done_card_box">
                            <h3 className="payment_done_payment_info">Payment info</h3>
                            <hr className="payment_done_hr" />
                            <div className="row">
                                <div className="col-md-6 col-6">
                                    <h5 className="payment_done_payment_info_title">Amount</h5>
                                </div>
                                <div className="col-md-6 col-6">
                                    <h5 className="payment_done_payment_info_value">
                                        ₹ {props?.paidData?.grand_total}
                                    </h5>
                                </div>
                            </div>
                            {/* <div className="row">
                                <div className="col-md-6 col-6">
                                    <h5 className="payment_done_payment_info_title" style={{ color: "#48988E" }}>Discount</h5>
                                </div>
                                <div className="col-md-6 col-6">
                                    <h5 className="payment_done_payment_info_value" style={{ color: "#48988E" }}>
                                        - ₹ {props?.paidData?.discount}
                                    </h5>
                                </div>
                            </div> */}
                            {/* <div className="row">
                                <div className="col-md-6 col-6">
                                    <h5 className="payment_done_payment_info_title" style={{ color: "#48988E" }}>THS GOLD Discount (5%)</h5>
                                </div>
                                <div className="col-md-6 col-6">
                                    <h5 className="payment_done_payment_info_value" style={{ color: "#48988E" }}>
                                        -₹ {props?.paidData?.gold_discount}
                                    </h5>
                                </div>
                            </div> */}
                            {/* <div className="row">
                                <div className="col-md-6 col-6">
                                    <h5 className="payment_done_payment_info_title">Convenience fee</h5>
                                </div>
                                <div className="col-md-6 col-6">
                                    <h5 className="payment_done_payment_info_value">
                                        ₹ {props?.paidData?.convenience_fee}
                                    </h5>
                                </div>
                            </div> */}
                            <div className="row">
                                <div className="col-md-6 col-6">
                                    <h5 className="payment_done_payment_info_title">Total amount</h5>
                                </div>
                                <div className="col-md-6 col-6">
                                    <h5 className="payment_done_payment_info_value">
                                        ₹ {props?.paidData?.total_amount}
                                    </h5>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 col-6">
                                    <h5 className="payment_done_payment_info_title">Status</h5>
                                </div>
                                <div className="col-md-6 col-6">
                                    <h5 className="payment_done_payment_info_value">
                                        {
                                            props?.paidData?.type == 1 ? <>
                                                <h5 className="payment_done_payment_info_title" style={{ color: "#F6773F" }} >Unpaid</h5>
                                            </> : <></>
                                        }

                                    </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <center>
                        <Button
                            type="submit"
                            className="consult_modal_btn"
                            onClick={() => { createBooking(initialValues); setRazorPayModal(true); props.onNext() }}

                        >
                            Pay Now
                        </Button>
                    </center>

                </Modal.Body>
            </Modal >


        </>
    );
};

export default PayModal;
