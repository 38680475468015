import React from "react";
import { Button, Modal } from "react-bootstrap";
import { Icon } from "../../../Utilities/Icons";
import { RadiologyTestCenterDetails } from "../../../Store/Reducer/RadiologySlice";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";

const RadiologyCenterDetailModel = (props) => {
  const dispatch = useDispatch();
  const {
    radiologyTestCenterDetalisList,
    radiologyTestCenterDetailsById,
    radiologyCart,
  } = useSelector(({ RadiologySlice }) => RadiologySlice);

  useEffect(() => {
    if (radiologyTestCenterDetailsById?.id) {
      dispatch(RadiologyTestCenterDetails(radiologyTestCenterDetailsById?.id));
    }
  }, [dispatch, radiologyTestCenterDetailsById?.id]);
  const getTotal = () => {
    let totalQuantity = 0;
    let totalPrice = 0;
    radiologyCart?.forEach((item) => {
      totalPrice += item?.price * item?.quantity;
    });
    return { totalPrice, totalQuantity };
  };
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="modal_476"
      backdrop="static"
    >
      <Modal.Header
        className="healthradiological_modal_box_head"
        style={{ marginBottom: "none", border: "none" }}
      >
        <div className="health_radiological_popup_list_box_inner">
          <div>
            <img
              className="tab_details_head_img"
              src={`${radiologyTestCenterDetalisList?.lab_logo}`}
              alt=""
              height="70px"
              width="70px"
            ></img>
          </div>
          <div>
            <label for="Radiological_Test7">
              <h3 className="healthradiological_radiological_test_title">
                {radiologyTestCenterDetalisList?.lab_name}
              </h3>
              <div className="healthradiological_star_box">
                <h5 className="healthradiological_star_text">
                  <img
                    src={Icon.star}
                    className="healthradiological_star_icon"
                    alt=""
                  ></img>{" "}
                  {radiologyTestCenterDetalisList?.avg_ratings}
                </h5>
                <h6 className="healthradiological_star_review">11 Reviews</h6>
              </div>
            </label>
          </div>
        </div>
        <img
          src={Icon.cross}
          className="health_radiological_popup_close"
          onClick={props.onHide}
          alt=""
        ></img>
      </Modal.Header>
      <Modal.Body className="healthradiological_modal_box_body">
        <div className="healthradiological_modal_box_body_inner">
          <hr className="healthradiological_modal_box_hr" />
        </div>
        <div className="tab_details_review_scroll_box">
          <div className="healthradiological_modal_box_body_inner">
            <div className="healthradiological_tab_details_box">
              <div>
                <h3 className="healthradiological_tab_details_title">
                  Address
                </h3>
                <h5 className="healthradiological_tab_details_subtitle">
                  Tanta radiology
                </h5>
                <h6 className="healthradiological_tab_details_para">
                  {radiologyTestCenterDetalisList?.address}
                </h6>
              </div>
              <div>
                <Button className="tab_details_view_map_btn">
                  View on map
                </Button>
              </div>
            </div>
            <hr className="healthradiological_modal_box_hr" />
            <div>
              <h3 className="healthradiological_tab_details_title">
                Reviews & Ratings
              </h3>
              {radiologyTestCenterDetalisList?.length > 0 && radiologyTestCenterDetalisList?.ratings?.map((item, key) => (
                <>
                  <div className="tab_details_review_box">
                    <div>
                      <div className="my_test_profile_icon_1">K</div>
                    </div>
                    <div style={{ width: "100%" }}>
                      <div>
                        <div className="tab_details_review_box_inner">
                          <div>
                            <h5 className="tab_details_review_name">
                              {item?.user_details?.name}
                            </h5>
                            <h5 className="tab_details_review_date">
                              on 12.3.2022
                            </h5>
                          </div>
                          <div>
                            <h5 className="healthradiological_star_text">
                              <img
                                src={Icon.star}
                                className="healthradiological_star_icon"
                                alt=""
                              ></img>{" "}
                              {item?.rating}
                            </h5>
                          </div>
                        </div>
                      </div>
                      <div>
                        <p className="tab_details_review_text">
                          {item?.message}
                        </p>
                      </div>
                    </div>
                  </div>
                  <hr className="healthradiological_modal_box_hr" />
                </>
              ))}
              {radiologyTestCenterDetalisList?.length == 0 && (<>
                <h6> No Reviews and Ratings Found</h6>
              </>)}

              {/* <div className="tab_details_review_box">
                <div>
                  <div className="tab_details_profile_bg_1">J</div>
                </div>
                <div>
                  <div>
                    <div className="tab_details_review_box_inner">
                      <div>
                        <h5 className="tab_details_review_name">Kevin</h5>
                        <h5 className="tab_details_review_date">
                          on 12.3.2022
                        </h5>
                      </div>
                      <div>
                        <h5 className="healthradiological_star_text">
                          <img
                            src={Icon.star}
                            className="healthradiological_star_icon"
                          ></img>{" "}
                          4
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div>
                    <p className="tab_details_review_text">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s
                    </p>
                  </div>
                </div>
              </div>
              <hr className="healthradiological_modal_box_hr" /> */}
              {/* <div className="tab_details_review_box">
                <div>
                  <div className="tab_details_profile_bg_2">J</div>
                </div>
                <div>
                  <div>
                    <div className="tab_details_review_box_inner">
                      <div>
                        <h5 className="tab_details_review_name">Kevin</h5>
                        <h5 className="tab_details_review_date">
                          on 12.3.2022
                        </h5>
                      </div>
                      <div>
                        <h5 className="healthradiological_star_text">
                          <img
                            src={Icon.star}
                            className="healthradiological_star_icon"
                          ></img>{" "}
                          4
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div>
                    <p className="tab_details_review_text">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s
                    </p>
                  </div>
                </div>
              </div>
              <hr className="healthradiological_modal_box_hr" />
              <div className="tab_details_review_box">
                <div>
                  <div className="tab_details_profile_bg_2">J</div>
                </div>
                <div>
                  <div>
                    <div className="tab_details_review_box_inner">
                      <div>
                        <h5 className="tab_details_review_name">Kevin</h5>
                        <h5 className="tab_details_review_date">
                          on 12.3.2022
                        </h5>
                      </div>
                      <div>
                        <h5 className="healthradiological_star_text">
                          <img
                            src={Icon.star}
                            className="healthradiological_star_icon"
                          ></img>{" "}
                          4
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div>
                    <p className="tab_details_review_text">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s
                    </p>
                  </div>
                </div>
              </div>
              <hr className="healthradiological_modal_box_hr" /> */}
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="healthpackage_modal_footer_box">
        <div className="lab_test_modal_footer_inner">
          <div className="">
            <h5 className="lab_test_modal_footer_subtitle">Total amount</h5>
            <h3 className="lab_test_modal_footer_title">
              {" "}
              ₹ {Math.round(getTotal().totalPrice)}
            </h3>
          </div>
          <div className="">
            <Button
              className="lab_test_modal_footer_btn"
              onClick={() => {
                props.onHide(true);
              }}
            >
              Next
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default RadiologyCenterDetailModel;
