import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  SelectedSpecialityId,
  SpecialityWiseHealthProblems,
} from "../../../Store/Reducer/BookingSlice";
import { Icon } from "../../../Utilities/Icons";
import { useNavigate } from "react-router-dom";
import SpecialityhealthModel from "./SpecialityhealthModel";
import { Container } from "react-bootstrap";

const Speciality = (props) => {
  const { specialityList, selectedSpecialityId } = useSelector(
    (state) => state.BookingSlice
  );
  const [healthProblemModel, setHealthProblemModel] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { specialityhealth } = useSelector((state) => state.CommonSlice);

  useEffect(() => {
    if (selectedSpecialityId && props.healthProblemModel) {
      dispatch(SpecialityWiseHealthProblems(selectedSpecialityId));
    }
  }, [selectedSpecialityId, props.healthProblemModel, dispatch]);

  const handleClick = (item) => {
    dispatch(SelectedSpecialityId(item?.id));
    localStorage.setItem("specialityId", item?.id);
    dispatch(SpecialityWiseHealthProblems(item?.id));
  };
  const { token } = useSelector(({ AuthSlice }) => AuthSlice);

  return (
    <>
      {/* <SpecialityhealthModel
        onHide={() => {
          setHealthProblemModel(false);
          if (!specialityhealth) {
            props?.onHide();
          }
        }}
        setHealthProblemModel={setHealthProblemModel}
        healthProblemModel={healthProblemModel}
        show={healthProblemModel}
      /> */}
      <Container>
      <div>
        <div className="" style={{ padding: "40px 0px" }}>
          <div className="">
            <h5 className="healthproblem_modal_title">Choose doctor speciality</h5>
          </div>
          <div>
            <div className="heath_problem_list_box_modal">
              {specialityList?.length &&
                specialityList?.map((item, index) => (
                  <div
                    key={`${item?.id + index}`}
                    className="heath_problem_list_box_model_map_sub"
                    onClick={() => {
                      {
                        token ?
                          navigate("/health") : navigate("/signin");
                      }  handleClick(item);
                    }}
                  >
                    <div className="healthproblem_icon_bg_modal">
                      <center>
                        <img
                          src={item?.image}
                          className="healthproblem_icon"
                          alt=""
                        ></img>
                      </center>
                    </div>
                    <h5 className="healthproblem_text">{item?.speciality}</h5>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
      </Container>
    
    </>
  );
};

export default Speciality;
