import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Icon } from "../../../../Utilities/Icons";
import { Button } from "react-bootstrap";
import "../../../../Assets/css/responsive.css";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  decrementQuantity,
  incrementQuantity,
  removeItem,
} from "../../../../Store/Reducer/MedicineSlice";
import ApplyCouponModel from "../../../Common/ApplyCouponModel/ApplyCouponModel";
import {
  clearSelectedCouponCode,
  CouponList,
  MedicineCouponList,
} from "../../../../Store/Reducer/BookingSlice";
import * as Yup from "yup";
import {
  MedicineOrderCreate,
  OrderPlace,
} from "../../../../Store/Reducer/OrderSilce";
import { MK_TOKEN } from "../../../../Utilities/Enums";
import { Logo } from "../../../../Utilities/Icons";
import OrderSuccessModel from "../../../Common/OrderSuccessModel/OrderSuccessModel";
import SelectAddressModel from "../../../Common/AddressModel/SelectAddressModel";
import { walletPrice } from "../../../../Store/Reducer/RadiologySlice";
import { useNavigate } from "react-router-dom";
import { clickCardHome } from "../../../../Store/Reducer/ProfileReducer";
function BuyMedicineCart() {
  let delivery_charge = 50;
  const [medicine_cart_length, setMedicineCartLenghth] = useState(
    1
  );
  const {
    wallet_price
  } = useSelector(({ RadiologySlice }) => RadiologySlice);
  const { coupencodeid } = useSelector(
    ({ BookingSlice }) => BookingSlice
  );
  const [couponAmount, setCouponAmount] = useState(0);
  const [couponPercentage, setCouponPercentage] = useState(0);
  const { cart } = useSelector(({ MedicineSlice }) => MedicineSlice);
  const getTotal = () => {
    let totalQuantity = 0;
    let totalPrice = 0;
    cart?.forEach((item) => {
      totalQuantity += item.quantity;
      totalPrice += item.sales_price * item.quantity;
    });
    totalPrice = totalPrice.toFixed(0)

    return { totalPrice, totalQuantity };
  };

  const { goldMembershipStatus, goldMembershipStatus1 } = useSelector(
    ({ RadiologySlice }) => RadiologySlice
  );
  const [orderSuccessModel, setOrderSuccessModel] = useState(false);
  const [selectAddressModel, setSelecteAddressModel] = useState(false);

  let amt = Math.round(getTotal().totalPrice)
  const [totalamount, setTotalAmount] = useState(
    amt.toFixed(0)
  );
  const navigate = useNavigate();
  const { selectedMemberDetail, goldMembershipdetail } = useSelector(
    ({ LabtestSlice }) => LabtestSlice
  );

  const [applyCoupon, setApplyCoupon] = useState(false);
  const { couponList, couponCodeDetail } = useSelector(
    ({ BookingSlice }) => BookingSlice
  );
  const dispatch = useDispatch();
  const { userWallet } = useSelector(({ ProfileSlice }) => ProfileSlice);
  const { medicineOrderReview } = useSelector(({ OrderSilce }) => OrderSilce);
  const [updatedCart, setUpdatedCart] = useState();
  const { session } = useSelector(({ AuthSlice }) => AuthSlice);
  const [walletbox, setwalletbox] = React.useState(userWallet);
  const ShoppingCartSchema = Yup.object().shape({
    mobile_no: Yup.string().required("Please Enter Mobail Number"),
    address: Yup.object().required("Please Select/Add Address"),
    termsAndConditions: Yup.bool().oneOf(
      [true],
      "Before you order medicines ,make sure you are aware about THS terms and conditions."
    ),
  });
  console.log("updatedCart", updatedCart);

  const options = {
    key: process.env.REACT_APP_PAYMENT_KEY,
    key_secret: process.env.REACT_APP_PAYMENT_SECRET_KEY,

    prefill: {
      name: session?.name,
      contact: session?.mobile_number,
      email: session?.email,
    },
    notes: {
      address: "some address",
    },
    theme: {
      color: "#F37254",
      hide_topbar: false,
    },
  };

  const openPayModal = (options) => {
    var rzp1 = new window.Razorpay(options);
    rzp1.open();
  };
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);
  useEffect(() => {
    const medicineIds = cart?.filter((item) => item?.id).map((item) => item.id);
    const medicineIdsString = medicineIds.join(',');
    dispatch(
      clickCardHome({
        type: "2",
        medicine: medicineIdsString,
      })
    );
  }, [dispatch, cart]);


  const filteredCart = cart?.map(item => ({
    id: item.id,
    product_id: item.id,
    product_name: item.name,
    qty: item.quantity,
  }));

  const createOrder = (values) => {

    try {

      let tempOptions = {
        ...options,
        amount: Math.round(totalamount) * 100,
        currency: "INR",
        name: "THS-User",
        description: "some description",
        image: Logo.THS_logo,
        handler: function (response) {
          if (response) {
            let updateValues = {
              ...values,
              reference_id: response.razorpay_payment_id,
              transaction_id: response.razorpay_payment_id,
              wallet_amount: wallet_price?.cut_amount,
              products: updatedCart?.map(function (i) {
                return { id: i?.id, quantity: i?.quantity };
              }),
            };
            dispatch(OrderPlace(updateValues)).then((res) => {

              if (res?.payload != "ERROR") {
                let updatedOrder = {
                  order_data: JSON.stringify(res?.payload?.order),
                  transaction_id: response.razorpay_payment_id,
                  delivery_charges_THS: delivery_charge,
                  medkart_response_status: 1,
                  coupen_code: coupencodeid,
                  products_data: JSON.stringify(filteredCart),
                  applied_cashback_amount: 0,
                  address: JSON.stringify(addressDetails)
                };
                if (res?.payload != []) {
                  dispatch(MedicineOrderCreate(updatedOrder))
                  setOrderSuccessModel(true);
                  dispatch(clearSelectedCouponCode());
                }

              } else {
                let updatedOrder = {
                  order_data: JSON.stringify(res?.payload?.order),
                  transaction_id: response.razorpay_payment_id,
                  delivery_charges_THS: delivery_charge,
                  medkart_response_status: 0,
                  products_data: JSON.stringify(filteredCart),
                  coupen_code: coupencodeid,
                  applied_cashback_amount: 0,
                  address: JSON.stringify(addressDetails)
                };

                if (res?.payload != []) {
                  dispatch(MedicineOrderCreate(updatedOrder))
                  dispatch(clearSelectedCouponCode());
                }

              }
            });
          }
        },
      };
      if (updatedCart.length > 0) {
        const walletprice = Math.round(getTotal().totalPrice) - discountAmount
        if (totalamount == 0) {

          let updateValues = {
            ...values,
            reference_id: "user_wallet",
            products: updatedCart?.map(function (i) {
              return { id: i?.id, quantity: i?.quantity };
            }),
          };
          dispatch(OrderPlace(updateValues)).then((res) => {
           
            if (res?.payload != "ERROR") {

              let updatedOrder = {
                order_data: JSON.stringify(res?.payload?.order),
                transaction_id: "user_wallet",
                delivery_charges_THS: delivery_charge,
                wallet_amount: wallet_price?.cut_amount,
                prepaid: totalamount,
                medkart_response_status: 1,
                coupen_code: coupencodeid,
                products_data: JSON.stringify(filteredCart),
                applied_cashback_amount: 0,
                address: JSON.stringify(addressDetails)
              };

              if (res?.payload != []) {
                dispatch(MedicineOrderCreate(updatedOrder))
                setOrderSuccessModel(true);
                dispatch(clearSelectedCouponCode());
              }

            } else {
              let updatedOrder = {
                order_data: JSON.stringify(res?.payload?.order),
                transaction_id: "user_wallet",
                delivery_charges_THS: delivery_charge,
                wallet_amount: wallet_price?.cut_amount,
                prepaid: totalamount,
                medkart_response_status: 0,
                products_data: JSON.stringify(filteredCart),
                coupen_code: coupencodeid,
                applied_cashback_amount: 0,
                address: JSON.stringify(addressDetails)
              };
              if (res?.payload != []) {
                dispatch(MedicineOrderCreate(updatedOrder))
                dispatch(clearSelectedCouponCode());
              }

            }

          });
        } else {
          if (Number(tempOptions.amount) >= 30000)
            openPayModal(tempOptions);
          else
            toast.error("Min. order amount is 300Rs to place an order");
        }
      }

      else {
        toast.error("Medicine Cart Is Empty");
        setMedicineCartLenghth(0)
      }

    } catch (error) { }
  };

  const formik = useFormik({
    initialValues: {
      mobile_no: session?.mobile_number,
      address: "",
      user: {
        name: session?.first_name,
      },
      patient: {
        name: session?.first_name,
        dob: session?.birthdate,
        gender: session?.gender,
        relationship: "Self",
      },
      dr: {
        name: "Dr. Feku",
        city: "Fekar",
      },
      isMK_TOKEN: localStorage.getItem(MK_TOKEN),
      termsAndConditions: false,
    },
    validationSchema: ShoppingCartSchema,

    onSubmit: createOrder,
  });

  const addressDetails = formik?.values?.address



  const getDiscount = (cupon) => {
    if (cupon?.coupon_percentage) {
      let amt = Math.round(getTotal()?.totalPrice) -
        (Math.round(getTotal()?.totalPrice) *
          Number(cupon?.coupon_percentage)) /
        100
      setCouponAmount((Math.round(getTotal()?.totalPrice) *
        Number(cupon?.coupon_percentage)) /
        100)
      setCouponPercentage(cupon?.coupon_percentage)
      amt = amt + delivery_charge
      setTotalAmount(
        amt.toFixed(0)
      );
    }
  };
  const handleCheckboxClick = () => {
    // Replace the URL below with the link you want to open
    const link = 'https://thsindia.in/medical-web/privacy-policy';
    window.open(link, '_blank'); // Opens the link in a new tab
  };

  useEffect(() => {
    if (cart.length > 0) {
      if (goldMembershipdetail != true) {
        let amt = Math.round(getTotal()?.totalPrice) + delivery_charge - discountAmount
        setTotalAmount(amt.toFixed(0));
      }
      else {
        let amt = Math.round(getTotal()?.totalPrice) + delivery_charge
        setTotalAmount(amt.toFixed(0));
        setUpdatedCart(cart);
      }

    } else {
      setTotalAmount(Math.round(getTotal()?.totalPrice) + delivery_charge);
      setUpdatedCart(cart);
    }

  }, [cart, updatedCart]);
  useEffect(() => {
    if (couponCodeDetail) {
      let discountedAmount =
        (Math.round(getTotal()?.totalPrice) *
          (100 - Number(couponCodeDetail?.coupon_percentage))) /
        100 + delivery_charge;
      setTotalAmount(discountedAmount.toFixed(0));

    } else if (goldMembershipdetail != true) {

      let totalAmt =
        Math.round(getTotal()?.totalPrice) + delivery_charge;
      if (goldMembershipStatus?.discount) {
        totalAmt -= discountAmount;
      }
      setTotalAmount(totalAmt.toFixed(0));
    } else {

      let totalAmt =
        Math.round(getTotal()?.totalPrice) + delivery_charge;

      setTotalAmount(totalAmt.toFixed(0));
    }
  }, [couponCodeDetail]);

  const discountAmount = (Math.round(getTotal()?.totalPrice) * goldMembershipStatus?.discount) / 100;


  useEffect(() => {
    dispatch(MedicineCouponList(5));
    dispatch(walletPrice({ "wallet_prices": walletbox?.wallet, "cut_amount": 0 }));
  }, [dispatch]);

  // let discount = getTotal()?.totalPrice - totalamount;
  let discount = couponAmount;
  discount = discount.toFixed(0)

  function handleChange(event) {
    setwalletbox((prevFormData) => {
      return {
        ...prevFormData,
        [event.target.name]: event.target.checked,
      };
    });

    if (event.target.checked) {
      if (couponCodeDetail) {
        const amout = getTotal()?.totalPrice - walletbox.wallet - coupendiscount - discountAmount + delivery_charge;
        setTotalAmount(amout <= 0 ? "0" : amout);
        const cutOfPrice = getTotal()?.totalPrice - (coupendiscount + discountAmount)
        const incAmount = walletbox.wallet - cutOfPrice;
        dispatch(walletPrice({ "wallet_prices": cutOfPrice >= walletbox?.wallet ? "0" : incAmount, "cut_amount": cutOfPrice >= walletbox?.wallet ? "0" : cutOfPrice }));
      } else {
        const amout = totalamount - walletbox.wallet;
        const data = totalamount - walletbox.wallet - coupendiscount - discountAmount + delivery_charge
        let removeDis;
        if (goldMembershipdetail !== true) {
          removeDis = totalamount
        } else {
          removeDis = totalamount
        }
        dispatch(walletPrice({ "wallet_prices": removeDis <= 0 ? 0 : (walletbox?.wallet - removeDis) <= 0 ? 0 : walletbox?.wallet - removeDis, "cut_amount": walletbox?.wallet - removeDis <= 0 ? walletbox?.wallet : removeDis }));
        if (amout >= 0) {
          setTotalAmount(Math.max(isNaN(amout) ? 0 : amout));
        } else {
          setTotalAmount(Math.max(isNaN(data) ? 0 : data));
        }
      }
    } else {
      if (couponCodeDetail) {
        const amount = getTotal()?.totalPrice - (coupendiscount + discountAmount + delivery_charge)
        if (wallet_price?.wallet_prices == 0) {
          dispatch(walletPrice({ "wallet_prices": wallet_price?.cut_amount }));
          setTotalAmount(amount <= 0 ? "0" : amount);
        } else {
          dispatch(walletPrice({ "wallet_prices": wallet_price?.wallet_prices + (getTotal()?.totalPrice - (coupendiscount + discountAmount + delivery_charge)) }));
          setTotalAmount(amount <= 0 ? "0" : amount);
        }

      } else {

        if (goldMembershipdetail !== true) {
          const amount = (getTotal()?.totalPrice - discountAmount + delivery_charge)
          if (wallet_price?.wallet_prices == 0) {
            dispatch(walletPrice({ "wallet_prices": wallet_price?.cut_amount }));
            setTotalAmount(amount <= 0 ? "0" : amount);
          } else {
            dispatch(walletPrice({ "wallet_prices": wallet_price?.wallet_prices + (getTotal()?.totalPrice - discountAmount + delivery_charge) }));
            setTotalAmount(amount <= 0 ? "0" : amount);
          }


        } else {
          const amount = getTotal()?.totalPrice + delivery_charge
          if (wallet_price?.wallet_prices == 0) {
            dispatch(walletPrice({ "wallet_prices": wallet_price?.cut_amount }));
            setTotalAmount(amount <= 0 ? "0" : amount);
          } else {
            dispatch(walletPrice({ "wallet_prices": wallet_price?.wallet_prices + (getTotal()?.totalPrice + delivery_charge) }));
            setTotalAmount(amount <= 0 ? "0" : amount);

          }

        }
      }
    }
  }
  const handleAddTestClick = () => {

    navigate('/medicines'); // Navigate to the '/medicines' route using your navigation method
  };
  const coupendiscount = (Math.round(getTotal()?.totalPrice) * Number(couponCodeDetail?.coupon_percentage)) / 100
  return (
    <>
      <SelectAddressModel
        show={selectAddressModel}
        formik={formik}
        onHide={(e) => {
          setSelecteAddressModel(false);
        }}
      />


      <OrderSuccessModel
        show={orderSuccessModel}
        onHide={(e) => {
          setOrderSuccessModel(false);
        }}
      />

      <ApplyCouponModel
        couponList={couponList}
        show={applyCoupon}
        onHide={() => setApplyCoupon(!couponList)}
        // couponType={2}
        getDiscount={getDiscount}
      />

      <Container fluid className="lab_test_cart_container">
        <Container>
          <div className="row">
            <div className="col-md-12">
              <h3 className="cart_head">Cart</h3>
            </div>
          </div>
          <form onSubmit={formik.handleSubmit}>
            {/* <div>{formik.errors.address}</div> */}

            <div className="lab_test_cart_box">
              <div className="lab_test_cart_box_inner">
                <div className="row">
                  <div className="col-xl-8 col-md-7 lab_test_right_padding">
                    <div className="lab_test_cart_left_side">
                      <div className="row mb_15">
                        <div className="col-md-6 col-6">
                          <h3 className="buy_medicines_cart_left_side_title">
                            Medicines
                          </h3>
                        </div>
                        <div className="col-md-6 col-6">
                          <Button
                            className="lab_test_add_test_btn"
                            onClick={handleAddTestClick}
                          >
                            Add Medicine
                          </Button>
                        </div>
                      </div>
                      <div>
                        <div>
                          {cart.length > 0 &&
                            cart?.map((shoppingCart) => (
                              <>
                                <div className="row mt_20">
                                  <div className="col-md-6 col-6">
                                    <div className="buy_medicine_box">
                                      <h3 className="buy_medicine_name">
                                        {shoppingCart?.name}
                                      </h3>
                                      <p className="buy_medicine_strip_of">
                                        Strip of 15 tablet
                                      </p>
                                      <p className="buy_medicine_strip_of">
                                        Minimum order quantity: 2 strips
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-md-6 col-6">
                                    <div style={{ alignSelf: "center" }}>
                                      <div className="buy_medicine_right_detail">
                                        <div className="quantity_field">
                                          <Button
                                            className="decrease_button"
                                            onClick={() => {
                                              dispatch(
                                                decrementQuantity(
                                                  shoppingCart?.id
                                                )
                                              )
                                              let amt = Math.round(getTotal()?.totalPrice) -
                                                (Math.round(getTotal()?.totalPrice) *
                                                  Number(couponPercentage)) /
                                                100
                                              setCouponAmount((Math.round(getTotal()?.totalPrice) *
                                                Number(couponPercentage)) /
                                                100)
                                              amt = amt + delivery_charge
                                              setTotalAmount(
                                                amt.toFixed(0)
                                              );
                                            }

                                            }
                                          >
                                            -
                                          </Button>
                                          <div className="number" >
                                            {shoppingCart?.quantity}
                                          </div>
                                          <Button
                                            className="increase_button"
                                            onClick={() => {
                                              dispatch(
                                                incrementQuantity(
                                                  shoppingCart?.id
                                                )
                                              )
                                              let amt = Math.round(getTotal()?.totalPrice) -
                                                (Math.round(getTotal()?.totalPrice) *
                                                  Number(couponPercentage)) /
                                                100
                                              setCouponAmount((Math.round(getTotal()?.totalPrice) *
                                                Number(couponPercentage)) /
                                                100)
                                              amt = amt + delivery_charge
                                              setTotalAmount(
                                                amt.toFixed(0)
                                              );
                                            }

                                            }
                                          >
                                            +
                                          </Button>
                                        </div>
                                        <h5 className="buy_medicine_value">
                                          ₹ {shoppingCart.sales_price}
                                        </h5>
                                        <div>
                                          <img
                                            src={Icon.cross}
                                            style={{
                                              padding: "3px",
                                              border: "1px solid #e0e0e0",
                                              borderRadius: "10px",
                                              cursor: "pointer",
                                              marginLeft: "10px",
                                            }}
                                            alt=""
                                            onClick={() => {
                                              if (updatedCart.length == 1)
                                                setTotalAmount(0)
                                              dispatch(
                                                removeItem(shoppingCart?.id)
                                              )
                                            }
                                            }
                                          ></img>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <hr className="lab_test_for_test_hr" />
                              </>
                            ))}
                          {cart.length == 0 && (<>
                            <h6>No Medicines Selected</h6>
                          </>)}
                        </div>
                      </div>
                    </div>
                    <div className="lab_test_cart_left_side_2">
                      <div className="row">
                        <div className="col-md-6">
                          <h3 className="lab_test_address"> Select Your Address</h3>
                          <div
                            className="lab_test_address_box"
                            onClick={() => setSelecteAddressModel(true)}
                          >
                            <h5 className="lab_test_address_box_text">
                              {!formik?.values?.address && "Select Address"}
                              {formik?.values?.address &&
                                formik?.values?.address?.address}
                            </h5>
                          </div>
                          {formik?.errors?.address && formik.touched.address && (
                            <div className="address_error_text">
                              {formik?.errors?.address}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-md-5 lab_test_left_padding">
                    <div className="lab_test_cart_right_side">
                      <h5 className="offer_benifit_text">
                        Offers &amp; Benifits
                      </h5>
                      <div
                        className="offer_benifit_card"
                        onClick={() => setApplyCoupon(true)}
                      >
                        <div className="apply_coupon_box">
                          <img
                            src={Icon.coupon}
                            className="apply_coupon_icon"
                            alt=""
                          ></img>
                          <h5 className="offer_benifit_text">Apply Coupon</h5>
                        </div>
                        <div>
                          <svg
                            width="8"
                            height="14"
                            className="subscrip_consult_card_arrow"
                            viewBox="0 0 8 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1.25 12.5L6.75 7L1.25 1.5"
                              stroke="#9393AA"
                              strokeWidth="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </div>
                      </div>
                      <h5 className="payment_summary">Payment Summary</h5>
                      <div className="payment_summary_card">
                        <div className="payment_summary_card_inner mb_5">
                          <div>
                            <h5 className="payment_summary_title">
                              Total amount
                            </h5>
                          </div>
                          <div>
                            <h5 className="payment_summary_price">
                              ₹ {getTotal()?.totalPrice}
                            </h5>
                          </div>
                        </div>
                        {couponCodeDetail && (
                          <div className="payment_summary_card_inner">
                            <div>
                              <h5 className="payment_summary_title">
                                Discount
                              </h5>
                            </div>
                            <div>
                              <h5 className="payment_summary_price_discount">
                                - ₹ {coupendiscount}
                              </h5>
                            </div>
                          </div>
                        )}

                        {
                          goldMembershipdetail == true ? " " : <>
                            <div className="payment_summary_card_inner">
                              <div>
                                <h5 className="payment_done_payment_info_title" style={{ color: "#48988E" }}>
                                  Gold Discount [{goldMembershipStatus?.discount}%]
                                </h5>
                              </div>
                              <div>
                                <h5 className="payment_summary_price_discount">
                                  - ₹{" "}
                                  {discountAmount && discountAmount}

                                </h5>
                              </div>
                            </div>
                          </>
                        }
                        <div className="payment_summary_card_inner">
                          <div>
                            <h5 className="payment_summary_title">
                              Delivery Charge
                            </h5>
                          </div>
                          <div>
                            <h5 className="payment_summary_price_discount">
                              + ₹ {delivery_charge}
                            </h5>
                          </div>
                        </div>

                        <hr className="payment_hr" />
                        <div className="payment_summary_card_inner">
                          <div>
                            <h5 className="payment_summary_total_title">
                              Total Payable
                            </h5>
                          </div>
                          <div>
                            <h5 className="payment_summary_total_price">
                              ₹ {totalamount ? parseFloat(totalamount).toFixed(0) : 0}
                            </h5>
                          </div>
                        </div>
                      </div>
                      <div className="wallet_card">
                        <div>
                          <div className="form-group">
                            <input
                              type="checkbox"
                              id={walletbox.id}
                              onChange={handleChange}
                              name={userWallet?.wallet}
                              value={walletbox}
                            />
                            <label className="wallet_text" for={walletbox.id}>
                              <span className="wallet_subtext">
                                Use wallet Amount
                              </span>
                            </label>
                          </div>
                        </div>
                        <div>
                          <h5 className="available_bal_text">
                            Avbl. bal.
                            <span className="available_bal_price">
                              ₹{parseFloat(wallet_price?.wallet_prices).toFixed(0) || "0"}
                            </span>
                          </h5>
                        </div>
                      </div>
                      <h5 className="T_C_title pt_10">Terms and conditions</h5>
                      <div className="lab_T_C_card">
                        <p className="T_C_text">
                          Before you order medicines ,make sure you are aware about THS terms and conditions.

                        </p>
                        <div className="col-md-12">
                          <div>
                            <div className="healthpackage">
                              <input
                                id="checkbox1"
                                type="checkbox"
                                className="vh"
                                name="termsAndConditions"
                                onChange={() =>
                                  formik.setFieldValue(
                                    "termsAndConditions",
                                    !formik.values.termsAndConditions
                                  )
                                }
                              />
                              <label
                                className="healthpackage_checkbox_title"
                                for="checkbox1"
                                style={{
                                  display: "inline-flex",
                                  alignItems: "center",
                                }}
                              >
                                {" "}
                                <span
                                  className="i_agree_cancellation_policy"
                                  style={{ padding: "25px" }}
                                >
                                  <h5 className="view_T_C_text"
                                    onClick={handleCheckboxClick}
                                  >
                                    View Terms And Conditions
                                  </h5>
                                </span>
                              </label>
                            </div>

                            {formik?.errors?.termsAndConditions &&
                              formik.touched.termsAndConditions && (
                                <div className="terms_conditions_error_text">
                                  {formik?.errors?.termsAndConditions}
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="lab_test_footer_card">
                <div className="lab_test_card_box">
                  {(!medicine_cart_length || totalamount < 300) && (<>
                    <ToastContainer />
                  </>)}
                  <div className="lab_test_price_box">
                    <h5 className="lab_test_price">Rs {totalamount ? parseFloat(totalamount).toFixed() : 0}</h5>
                    <h6 className="lab_test_subtitle">Amount to pay</h6>
                  </div>
                  <div>
                    <Button className="lab_test_btn" type="submit">
                      Pay Now
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </Container>
      </Container>
    </>
  );
}
export default BuyMedicineCart;
