import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import { Icon } from "../../../Utilities/Icons";
import { Formik } from "formik";
import {
  AppointmentTime,
  CreateRescheduleBooking,
  SelectedDate,
  SelectedPlan,
  UpcomingBookingList,
} from "../../../Store/Reducer/BookingSlice";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { setActiveKey } from "../../../Store/Reducer/CommonSlice";
import { ConsultionCard } from "../../../Store/Reducer/ProfileReducer";

const SelectBookingDateTime = (props) => {

  console.log("prwerweqweqwae", props);
  const current = new Date();
  const [date, setNewDate] = useState();

  const datew = ` ${current.getFullYear()}/${current.getMonth() + 1
    }/${current.getDate()}`;

  const tomorrow = ` ${current.getFullYear()}/${current.getMonth() + 1}/${current.getDate() + 1
    }`;
  const nexttomorrow = ` ${current.getFullYear()}/${current.getMonth() + 1}/${current.getDate() + 2
    }`;
  const nexttomorrow2 = ` ${current.getFullYear()}/${current.getMonth() + 1}/${current.getDate() + 3
    }`;
  const dispatch = useDispatch();
  const { subscribedPlansList, userSelectedDate } = useSelector(
    ({ BookingSlice }) => BookingSlice
  );

  const [selecteDate, setSelectedDate] = useState(date);
  useEffect(() => {
    if (props?.formikProps?.values?.follow_up_booking_id) {
      setNewDate(props?.formikProps?.values?.appointment_date);
      setSelectedDate(props?.formikProps?.values?.appointment_date);
      dispatch(SelectedDate(props?.formikProps?.values?.appointment_date));
    } else {
      // setSelectedDate(date);
      setSelectedDate(datew);
      setNewDate(datew);
    }
  }, [props?.formikProps?.values?.follow_up_booking_id]);


  // useEffect(() => {
  //   if (props?.setdoctorid != undefined) {
  //     dispatch(ConsultionCard({
  //       type: "1",
  //       speciality_id: localStorage.getItem("specialityId"),
  //       health_problem_ids: localStorage.getItem("selectedHealthId"),
  //       doctor_id: props?.setdoctorid
  //     }))
  //   }
  // }, [dispatch,props?.setdoctorid])

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="book_modal_531"
      backdrop="static"
    >
      <Modal.Header
        className=""
        style={{ marginBottom: "none", border: "none" }}
      >
        <h5 className="book_date_time">Select Booking Date & Time</h5>
        <img
          src={Icon.cross}
          className="book_modal_close"
          onClick={props.onHide}
          alt=""
        ></img>
      </Modal.Header>
      <Modal.Body>
        <div className="book_date_time_box">
          {props?.bookingDetail ||
            props?.formikProps?.values?.follow_up_booking_id ? (
            <>
              <h5 className="date_title">
                For, {moment(selecteDate).format("DD-MM-YYYY")}
                {/* {date === selecteDate
                  ? "Today"
                  : moment(selecteDate).format("DD-MM-YYYY")}{" "} */}
              </h5>
              <div className="book_date_card_box">
                <div
                  className={`book_date_card  ${selecteDate === date ? "book_date_card_active " : null
                    } `}
                  onClick={() => {
                    setSelectedDate(date);
                    dispatch(SelectedDate(date));
                  }}
                >
                  <h5 className="book_date_card_text">
                    {props?.formikProps?.values?.appointment_date
                      ? moment(selecteDate).format("DD-MM-YYYY")
                      : "Today"}
                  </h5>
                </div>
                <div
                  className={`book_date_card  ${selecteDate === tomorrow ? "book_date_card_active " : null
                    } `}
                  onClick={() => {
                    setSelectedDate(tomorrow);
                    dispatch(SelectedDate(tomorrow));
                  }}
                >
                  <h5 className="book_date_card_text">
                    {" "}
                    {moment(tomorrow).format("DD-MM-YYYY")}
                  </h5>
                </div>
                <div
                  className={`book_date_card  ${selecteDate === nexttomorrow
                    ? "book_date_card_active "
                    : null
                    } `}
                  onClick={() => {
                    setSelectedDate(nexttomorrow);
                    dispatch(SelectedDate(nexttomorrow));
                  }}
                >
                  <h5 className="book_date_card_text">
                    {moment(nexttomorrow).format("DD-MM-YYYY")}
                  </h5>
                </div>
                <div
                  className={`book_date_card  ${selecteDate === nexttomorrow2
                    ? "book_date_card_active "
                    : null
                    } `}
                  onClick={() => {
                    setSelectedDate(nexttomorrow2);
                    dispatch(SelectedDate(nexttomorrow2));
                  }}
                >
                  <h5 className="book_date_card_text">
                    {moment(nexttomorrow2).format("DD-MM-YYYY")}
                  </h5>
                </div>
              </div>
            </>
          ) : null}

          <h5 className="time_title">Select time</h5>
          <div className="select_time_box">
            {props.bookingDateTime ? (
              <Formik
                initialValues={{
                  appointmentDetails: ""
                }}
                onSubmit={(values) => {

                  if (values) {
                    props?.formikProps?.setFieldValue(
                      "slot_id",
                      values.appointmentDetails.id
                    );
                    props?.formikProps?.setFieldValue(
                      "appointment_time",
                      values.appointmentDetails.start_time
                    );
                    props?.formikProps?.setFieldValue(
                      "appointment_date",
                      userSelectedDate
                    );
                  }
                  if (props?.bookingDetail) {
                    let request = {
                      slot_id: values.appointmentDetails.id,
                      appointment_date: userSelectedDate,
                      appointment_time: values.appointmentDetails.start_time,
                      booking_id: props.bookingDetail.id,
                      reason_id: 1,
                    };

                    dispatch(CreateRescheduleBooking(request)).then((res) => {
                      if (res.payload.data) {
                        dispatch(UpcomingBookingList());
                      }
                    });
                    props.onHide();


                  } else {
                    dispatch(AppointmentTime(values));
                    props.onHide();
                    dispatch(setActiveKey("subscription_plan"));
                    if (subscribedPlansList.length > 0) {
                      dispatch(SelectedPlan(true));
                    }
                  }

                  if (props?.formikProps?.values?.follow_up_booking_id) {
                    props?.setSelectSubscribedPlansModel();
                  }
                }}
              >
                {({
                  values,
                  handleChange,
                  setFieldValue,
                  handleBlur,
                  handleSubmit,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Accordion defaultActiveKey="0">
                      {props?.bookingDateTime?.available_slots?.morning[0] == undefined ? <>     <div className="abc">
                        <div className="row">
                          <div className="col-md-12 p-4">
                            <div className="time_slot_card_box">
                              {props.bookingDateTime?.available_slots_day?.map(
                                (item, index) => {
                                  if (props.bookingDateTime.next_availble_time === item.start_time && !values.appointmentDetails.id) {
                                    setFieldValue(
                                      "appointmentDetails",
                                      item
                                    )
                                  }
                                  return (
                                    <>
                                      <div className="consultation_cancel_modal consulatation-time">
                                        <div
                                          className={`time_slot_card  ${values.appointmentDetails.id ===
                                            item.id || (props.bookingDateTime.next_availble_time === item.start_time && !values.appointmentDetails.id)
                                            ? "time_slot_card_active"
                                            : null
                                            } `}
                                          key={index}
                                        >
                                          <label>
                                            <input
                                              type="radio"
                                              name="appointmentDetails"
                                              value={item}
                                              onChange={() =>
                                                setFieldValue(
                                                  "appointmentDetails",
                                                  item
                                                )
                                              }
                                              onBlur={handleBlur}
                                              checked={
                                                values.appointmentDetails ===
                                                item?.title
                                              }
                                              style={{ accentColor: "#199a8e" }}
                                            />
                                            <h5 className="time_slot_card_text time_slot_card_text_active">
                                              {item.start_time}
                                            </h5>
                                          </label>
                                        </div>
                                      </div>
                                    </>
                                  )
                                }
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      </> : <>
                        {props?.bookingDateTime?.available_slots?.morning
                          ?.length > 0 && (
                            <Accordion.Item eventKey="0">
                              <Accordion.Header>
                                Morning{" "}
                                <span className="time_avilable_subtitle">
                                  {" "}
                                  {props.bookingDateTime?.available_slots?.morning
                                    .length > 0
                                    ? props.bookingDateTime?.available_slots.morning
                                      .length +
                                    " " +
                                    "Slots available"
                                    : "Not available"}{" "}
                                </span>
                              </Accordion.Header>
                              <Accordion.Body>
                                <div className="abc">
                                  <div className="row">
                                    <div className="col-md-12">
                                      <div className="time_slot_card_box">
                                        {props.bookingDateTime?.available_slots?.morning?.map(
                                          (item, index) => {
                                            if (props.bookingDateTime.next_availble_time === item.start_time && !values.appointmentDetails.id) {
                                              setFieldValue(
                                                "appointmentDetails",
                                                item
                                              )
                                            }
                                            return (
                                              <>
                                                <div className="consultation_cancel_modal consulatation-time">
                                                  <div
                                                    className={`time_slot_card  ${values.appointmentDetails.id ===
                                                      item.id || (props.bookingDateTime.next_availble_time === item.start_time && !values.appointmentDetails.id)
                                                      ? "time_slot_card_active"
                                                      : null
                                                      } `}
                                                    key={index}
                                                  >
                                                    <label>
                                                      <input
                                                        type="radio"
                                                        name="appointmentDetails"
                                                        value={item}
                                                        onChange={() =>
                                                          setFieldValue(
                                                            "appointmentDetails",
                                                            item
                                                          )
                                                        }
                                                        onBlur={handleBlur}
                                                        checked={
                                                          values.appointmentDetails ===
                                                          item?.title
                                                        }
                                                        style={{ accentColor: "#199a8e" }}
                                                      />
                                                      <h5 className="time_slot_card_text time_slot_card_text_active">
                                                        {item.start_time}
                                                      </h5>
                                                    </label>
                                                  </div>
                                                </div>
                                              </>
                                            )
                                          }
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Accordion.Body>
                            </Accordion.Item>
                          )}
                        {props?.bookingDateTime?.available_slots?.afternoon
                          ?.length > 0 && (
                            <Accordion.Item eventKey="1">
                              <Accordion.Header>
                                Afternoon{" "}
                                <span className="time_avilable_subtitle">
                                  {" "}
                                  {props.bookingDateTime?.available_slots?.afternoon
                                    .length > 0
                                    ? props.bookingDateTime?.available_slots
                                      .afternoon.length +
                                    " " +
                                    "Slots available"
                                    : "Not available"}{" "}
                                </span>
                              </Accordion.Header>
                              <Accordion.Body>
                                <div className="row">
                                  <div className="col-md-12">
                                    <div className="time_slot_card_box">
                                      {props.bookingDateTime?.available_slots?.afternoon?.map(
                                        (item, index) => {
                                          if (props.bookingDateTime.next_availble_time === item.start_time && !values.appointmentDetails.id) {
                                            setFieldValue(
                                              "appointmentDetails",
                                              item
                                            )
                                          }
                                          return (
                                            <>
                                              <div className="consultation_cancel_modal consulatation-time">
                                                <div
                                                  className={`time_slot_card  ${values.appointmentDetails.id ===
                                                    item.id || (props.bookingDateTime.next_availble_time === item.start_time && !values.appointmentDetails.id)
                                                    ? "time_slot_card_active"
                                                    : null
                                                    } `}
                                                  key={index}
                                                >
                                                  <label>
                                                    <input
                                                      type="radio"
                                                      name="appointmentDetails"
                                                      value={item}
                                                      onChange={() =>
                                                        setFieldValue(
                                                          "appointmentDetails",
                                                          item
                                                        )
                                                      }
                                                      onBlur={handleBlur}
                                                      checked={
                                                        values.appointmentDetails ===
                                                        item?.title
                                                      }
                                                      style={{ accentColor: "#199a8e" }}
                                                    />
                                                    <h5 className="time_slot_card_text time_slot_card_text_active">
                                                      {item.start_time}
                                                    </h5>
                                                  </label>
                                                </div>
                                              </div>
                                            </>
                                          )
                                        }
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </Accordion.Body>
                            </Accordion.Item>
                          )}
                        {props?.bookingDateTime?.available_slots?.evening
                          ?.length > 0 && (
                            <Accordion.Item eventKey="2">
                              <Accordion.Header>
                                Evening
                                <span className="time_avilable_subtitle">
                                  {props.bookingDateTime?.available_slots?.evening
                                    .length > 0
                                    ? props.bookingDateTime?.available_slots
                                      ?.evening.length +
                                    " " +
                                    "Slots available"
                                    : "Not available"}
                                </span>
                              </Accordion.Header>
                              <Accordion.Body>
                                <div className="time_slot_card_box">
                                  {props.bookingDateTime?.available_slots?.evening?.map(
                                    (item, index) => {
                                      if (props.bookingDateTime.next_availble_time === item.start_time && !values.appointmentDetails.id) {
                                        setFieldValue(
                                          "appointmentDetails",
                                          item
                                        )
                                      }


                                      return (
                                        <>
                                          <div className="consultation_cancel_modal consulatation-time">
                                            <div
                                              className={`time_slot_card  ${values.appointmentDetails.id ===
                                                item.id || (props.bookingDateTime.next_availble_time === item.start_time && !values.appointmentDetails.id)
                                                ? "time_slot_card_active"
                                                : null
                                                } `}
                                              key={index}
                                            >
                                              <label>
                                                <input
                                                  type="radio"
                                                  name="appointmentDetails"
                                                  value={item}
                                                  onChange={() =>
                                                    setFieldValue(
                                                      "appointmentDetails",
                                                      item
                                                    )
                                                  }
                                                  onBlur={handleBlur}
                                                  checked={
                                                    values.appointmentDetails ===
                                                    item?.title
                                                  }
                                                  style={{ accentColor: "#199a8e" }}
                                                />
                                                <h5 className="time_slot_card_text time_slot_card_text_active">
                                                  {item.start_time}
                                                </h5>
                                              </label>
                                            </div>
                                          </div>
                                        </>
                                      )
                                    }
                                  )}
                                </div>
                              </Accordion.Body>
                            </Accordion.Item>
                          )}

                        {props.bookingDateTime?.available_slots?.night?.length >
                          0 && (
                            <Accordion.Item eventKey="3">
                              <Accordion.Header>
                                Night
                                <span className="time_avilable_subtitle">
                                  {props.bookingDateTime?.available_slots?.night
                                    .length > 0
                                    ? props.bookingDateTime?.available_slots?.night
                                      .length +
                                    " " +
                                    "Slots available"
                                    : "Not available"}
                                </span>
                              </Accordion.Header>
                              <Accordion.Body>
                                <div className="time_slot_card_box">
                                  {props.bookingDateTime?.available_slots?.night?.map(
                                    (item, index) => {
                                      if (props.bookingDateTime.next_availble_time === item.start_time && !values.appointmentDetails.id) {
                                        setFieldValue(
                                          "appointmentDetails",
                                          item
                                        )
                                      }
                                      return (
                                        <>
                                          <div className="consultation_cancel_modal consulatation-time">
                                            <div
                                              className={`time_slot_card  ${values.appointmentDetails.id ===
                                                item.id || (props.bookingDateTime.next_availble_time === item.start_time && !values.appointmentDetails.id)
                                                ? "time_slot_card_active"
                                                : null
                                                } `}
                                              key={index}
                                            >
                                              <label>
                                                <input
                                                  type="radio"
                                                  name="appointmentDetails"
                                                  value={item}
                                                  onChange={() =>
                                                    setFieldValue(
                                                      "appointmentDetails",
                                                      item
                                                    )
                                                  }
                                                  onBlur={handleBlur}
                                                  checked={
                                                    values.appointmentDetails ===
                                                    item?.title
                                                  }
                                                  style={{ accentColor: "#199a8e" }}
                                                />
                                                <h5 className="time_slot_card_text time_slot_card_text_active">
                                                  {item.start_time}
                                                </h5>
                                              </label>
                                            </div>
                                          </div>
                                        </>
                                      )
                                    }
                                  )}
                                </div>
                              </Accordion.Body>
                            </Accordion.Item>
                          )}
                      </>}

                    </Accordion>
                    <center>
                      {props.bookingDetail ? (
                        <Button type="submit" className="time_slot_btn">
                          Reshedule
                        </Button>
                      ) : (
                        <Button type="submit" className="time_slot_btn"

                        >
                          Done
                        </Button>
                      )}
                    </center>
                  </form>
                )}
              </Formik>
            ) : null}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SelectBookingDateTime;
