import React from "react";
import "../../../../Assets/css/responsive.css";
import { useNavigate } from "react-router-dom";
import { Button, Container } from "react-bootstrap";
import { Icon } from "../../../../Utilities/Icons";
import ConsultationList from "../myConsutation/ConsultationList";
import PersonalInfo from "../personalInfo/PersonalInfo";
import ProfileTestBooking from "../testBooking/ProfileTestBooking";
import TabComponent from "../../../Common/Tabs";

function UserProfileSidebar() {
  const navigate = useNavigate();
  const tabs = [
    {
      title: "Personal Info",
      key: "personal_info",
      icon: Icon.user,
      component: () => <PersonalInfo />,
    },
    {
      title: "My Consultations",
      key: "my_Consultations",
      icon: Icon.Consultations,
      component: () => <ConsultationList />,
    },
    {
      title: "Test Booking",
      key: "test_Booking",
      icon: Icon.TestBookings,
      component: () => <ProfileTestBooking />,
    },
  ];
  // useEffect(() => {
  //   dispatch(UpcomingBookingList());
  //   dispatch(PastBookingsList());
  //   dispatch(CancelationReasonList());
  // }, []);
  return (
    <>
      <Container fluid className="chat_container">
        <Container>
          <div className="row">
            <div className="col-md-12">
              <Button
                className="back_btn"
                onClick={() => {
                  navigate(-1);
                }}
              >
                <img
                  src={Icon.backarrow}
                  className="back_btn_icon"
                  alt=""
                ></img>{" "}
                <span className="back_btn_text">Back</span>
              </Button>
            </div>
            <div className="col-md-12 p-3">
              {/* <h3 className="chat_head">Bones & joints</h3> */}
            </div>
          </div>
          <TabComponent tabs={tabs} active="personal_info" />
        </Container>
      </Container>
    </>
  );
}
export default UserProfileSidebar;
