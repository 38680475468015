  import React, { useEffect, useState } from "react";
  import { Form } from "react-bootstrap";
  import {
    useSortBy,
    useTable,
    useRowSelect,
    usePagination,
    useExpanded,
  } from "react-table";
  import backimg from '../../../Assets/img/png/Frame 34277.png'
  import { useDispatch, useSelector } from "react-redux";
  import { Pagination } from '@mui/material';

  export default function Table(props) {
    const Collapse = (row) => props.collapseComponent(row);
    const data = React.useMemo(() => [...props?.data], [props.data]);
    const columns = React.useMemo(() => [...props.columns], [props.columns]);

    const RowSubComponent = React.useCallback(({ row }) => {
      return <Collapse row={row} />;
    }, []);
   

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      prepareRow,
      page,
      rows,
      canPreviousPage,
      canNextPage,
      pageOptions,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      state: { pageIndex },
    } = useTable(
      {
        columns,
        data,
        initialState: { pageSize: 15 },
      },
      useSortBy,
      useExpanded,
      props.pagination && usePagination
    );
    useEffect(() => {
      return () => { };
    }, [props.data]);
    const { medicines, cart } = useSelector(({ MedicineSlice }) => MedicineSlice);

  

    return (
      <>
        <div className="consultation_card_box mt_20">
          <div className="table-responsive">
            <table {...getTableProps()} className="table consultation_table">
              <thead>
                {headerGroups?.map((headerGroup, i) => (
                  <tr
                    key={i}
                    className="consultation_table_head"
                    {...headerGroup.getHeaderGroupProps()}
                  >
                    {headerGroup.headers?.map((column, i) => (
                      <th
                        key={i}
                        className="consultation_table_head_text"
                        {...column.getHeaderProps([
                          {
                            className: column.className,
                            style: column.style,
                          },
                          column.getSortByToggleProps(),
                        ])}
                      >
                        {/* {column?.sort && (
                          <span>
                            {column?.isSorted ? (
                              column.isSortedDesc ? (
                                <img src={Icon.UpCiver} alt="up" />
                              ) : (
                                <img src={Icon.DownCiver} alt="down" />
                              )
                            ) : (
                              <img src={Icon.UpDown} alt="updown" />
                            )}
                          </span>
                        )}
                        {column.render("Header")} */}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              
              {props.data[0] != undefined ?
                <>
                  <tbody className="" {...getTableBodyProps()}>
                    {(page || rows).map((row, i) => {
                    
                      prepareRow(row);

                      return (
                        <React.Fragment key={i}>
                          <tr
                            className={`consultation_table_body_row ${row.isExpanded ? "view open" : "view"
                              }`}
                            {...row.getRowProps()}
                          >
                            {row.cells.map((cell, index) => {
                              return (
                                <td
                                  className="consultation_table_body_text"
                                  key={index}
                                  {...cell.getCellProps()}
                                >
                                  {cell.render("Cell")}
                                </td>
                              );
                            })}
                          </tr>
                          {row.isExpanded ? (
                            <RowSubComponent row={row.values} />
                          ) : null}
                        </React.Fragment>
                      );
                    })}
                  </tbody>
                </>
                : <tbody className="" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <tr className="">
                    <th colSpan="4">
                      <img src={backimg} alt="Image Description" />
                    </th>
                  </tr>
                </tbody>

              }

            </table>
          </div>
        </div>
      
      </>
    );
  }
