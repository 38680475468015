import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Icon } from "../../../Utilities/Icons";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { GoldMemberBokingstatus } from "../../../Store/Reducer/RadiologySlice";
import { GoldMemberBookingDetails } from "../../../Store/Reducer/LabtestSlice";
const Congratulations = (props) => {
    const dispatch = useDispatch();
    const handleClose = () => {
        props.onHide();
    };
    const {
        cards
      } = useSelector(({ BookingSlice }) => BookingSlice);
    const { goldMembershipStatus, goldMembershipStatus1  , goldmemberbooking} = useSelector(
        ({ RadiologySlice }) => RadiologySlice
    );
    const {goldMembershipdetail} = useSelector(
        ({ LabtestSlice }) => LabtestSlice
    );
    useEffect(() => {
        dispatch(GoldMemberBookingDetails());
    }, [dispatch])

   
    return (
        <>
            <Modal
                {...props}
                size=""
                aria-labelledby="contained-modal-title-vcenter"
                centered
                dialogClassName=""
                backdrop="static"
                className="modal_415 black_theam"
            >
                <div className="sucess_gold">
                    <div className="pt_10" style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                        <img src={Icon.trueSuccess} alt="Image The" />
                    </div>
                    <p className="Golden_text pt_5" style={{ fontWeight: "600", fontSize: "20px" }}>Congratulations!!</p>
                    <p className="Golden_text" style={{ fontWeight: "400", fontSize: "16px", padding: "0px" }}> You are now THS Gold Membership</p>
                    <div className="">
                        <div className="book_successfully_cards">
                            <h5 className="gold_info_title">Payment Information</h5>
                            <hr className="gold_successfully_hr" />
                            <div className="row">
                                <div className="col-md-6">
                                    <h5 className="gold_successfully_details_title">Valid till</h5>
                                </div>
                                <div className="col-md-6">
                                    <h5 className="good_successfully_details_value">
                                    {moment(goldMembershipdetail?.expiry_date).format(
                                                                        "DD MMM YYYY | h:mm A"
                                                                    )}{" "}
                                    </h5>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <h5 className="gold_successfully_details_title">Paid amount</h5>
                                </div>
                                <div className="col-md-6">
                                    <h5 className="good_successfully_details_value">
                                    ₹ {goldMembershipdetail?.total_pay }
                                    </h5>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6">
                                    <h5 className="gold_successfully_details_title">Payment ID</h5>
                                </div>
                                <div className="col-md-6">
                                    <h5 className="good_successfully_details_value">
                                    {goldMembershipdetail?.transaction_id}
                                    </h5>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6">
                                    <h5 className="gold_successfully_details_title">Date and Time</h5>
                                </div>
                                <div className="col-md-6">
                                    <h5 className="good_successfully_details_value">
                                    {moment(goldMembershipdetail?.booking_date).format(
                                                                        "DD MMM YYYY | h:mm A"
                                                                    )}{" "}
                                    </h5>
                                </div>
                            </div>
                        </div>
                        <center>
                            <Button
                                className="payment_ok_btn"
                                style={{ color: "black" }}
                                onClick={handleClose}
                            >
                                OK
                            </Button>
                        </center>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default Congratulations