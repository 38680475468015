import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { Modal, Button } from "react-bootstrap";
import FormControl from "../../Common/Forms/FormControl";
import Form from "react-bootstrap/Form";
import { Icon } from "../../../Utilities/Icons";
import { useDispatch } from "react-redux";
import { Field, Formik } from "formik";
import { useSelector } from "react-redux";
import {
  GetHeightList,
  GetPackageMemberDetails,
  GetWeightList,
} from "../../../Store/Reducer/ProfileReducer";
import CustomSelect from "./CustomSelect";
import { FaCamera } from "react-icons/fa";

const AddMember = (props) => {
  const dispatch = useDispatch();
  const { medicalHistory } = useSelector(({ BookingSlice }) => BookingSlice);
  const { userHeightList, userWeightList } = useSelector(
    ({ ProfileSlice }) => ProfileSlice
  );

  const [yesNo, setYesNo] = React.useState("No");
  function onChangeValue(event) {
    setYesNo(event.target.value);
  }

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const MemberSchema = Yup.object({
    name: Yup.string().required("Please Enter Name"),
    email: Yup.string()
      .email("Must be a valid email")
      .max(255)
      .required("Email is required"),
    mobile_number: Yup.string().matches(
      phoneRegExp,
      "Phone number is not valid"
    ),
    age: Yup.number().min(0).required("Please Enter Age"),
    height: Yup.string().required("Please Enter Height"),
    weight: Yup.string().min(0).required("Please Enter Weight"),
    gender: Yup.string().required("Please Enter Gender"),
    blood_group: Yup.string().required("Please Enter Blood Group"),
  });
 

  useEffect(() => {
    if (props?.show) {

    } else if (props?.addEditMemberinitialState?.medical_history?.length > 0) {
      setYesNo("Yes");
    }
  }, [
    props?.show,
    props?.addEditMemberinitialState?.medical_history?.length,
    dispatch,
  ]);
  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const [img, setImg] = useState();
  const [selectedImage, setSelectedImage] = useState(null);

  const imgHandler = (event) => {
    const selectedFile = event.target.files[0];
    setImg(event.target.files[0])
    if (selectedFile) {
      setSelectedImage(URL.createObjectURL(selectedFile));
    }
  };

  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="modal_476"
      backdrop="static"
    >
      <Modal.Header style={{ border: "none" }}>
        <h5 className="select_consult_member_modal_title">Add new member</h5>
        <img
          src={Icon.cross}
          className="book_modal_close"
          onClick={props.onHide}
          alt="My Awesome Image"
        ></img>
      </Modal.Header>
      <Modal.Body>
        <div className="add_member_modal_box">
          <Formik
            initialValues={props?.addEditMemberinitialState}
            enableReinitialize
            validationSchema={MemberSchema}
            onSubmit={(values) => {
              const combinedValues = {
                ...values,
                image: img // Replace 'imageFile' with the actual image data
              };
              dispatch(props?.addEditMember(combinedValues)).then((res) => {
                if (res?.payload?.data?.id) {
                  dispatch(GetPackageMemberDetails(res?.payload?.data?.id));
                }
              });
              props.onHide();
            }}
          >
            {({
              values,
              setFieldValue,
              handleChange,
              handleBlur,
              touched,
              errors,
              handleSubmit,
            }) => (
              <Form onSubmit={handleSubmit}>
                <div className="imgupload">
                  <label className="uploadlabel">
                    {
                      props?.packageMemberDetails?.image == null ? <>
                        {!selectedImage ? (
                          <>
                            <FaCamera className="text-primary text-[20px]" />
                            <span className="mt_2" style={{ fontWeight: "bold" }}>
                              add
                            </span>
                            <input type="file" className="hidden" name="img" onChange={imgHandler} />
                          </>
                        ) : (
                          <>
                            <div className="image-preview">
                              <img src={selectedImage} alt="Selected" style={{ height: "100px", width: "100px", borderRadius: "50%" }} />
                            </div>
                            <input type="file" className="hidden" name="img" onChange={imgHandler} />
                          </>
                        )} </> : <>


                        <div className="image-preview">
                          <img src={props?.packageMemberDetails?.image} alt="Selected" style={{ height: "100px", width: "100px", borderRadius: "50%" }} />
                        </div>

                      </>

                    }

                  </label>
                </div>
                <h2 className="uploadtext">Upload profile image</h2>
                <div className="row">
                  <div className="col-md-12">
                    <div className="input_box">
                      <div className="form_group">
                        <FormControl
                          control="input"
                          type="text"
                          name="name"
                          className="add_member_input"
                          placeholder="Name"
                          value={values.name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="input_box">
                      <div className="form_group">
                        <FormControl
                          control="input"
                          type="text"
                          name="email"
                          className="add_member_input"
                          placeholder="Email"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="input_box">
                      <div className="form_group">
                        <FormControl
                          control="input"
                          type="text"
                          name="mobile_number"
                          className="add_member_input"
                          placeholder="Phone Number"
                          value={values.mobile_number}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          maxLength={10}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="input_box">
                      <div className="form_group">
                        <FormControl
                          control="input"
                          type="date"
                          name="birthdate"
                          className="add_member_input"
                          value={values.birthdate}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          max={getCurrentDate()}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 ">
                    <div className="input_box">
                      <div className="form_group">
                        <FormControl
                          control="input"
                          type="text"
                          name="age"
                          className="dob_input_2"
                          id="age"
                          placeholder="Age :"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={
                            (values.age =
                              Math.floor(
                                (new Date() -
                                  new Date(values.birthdate).getTime()) /
                                3.15576e10
                              ) || "")
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="input_box">
                      <div className="form_group">
                        <Form.Select
                          aria-label="Default select example"
                          name="height"
                          value={values.height}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="add_member_input"
                        >
                          <option>Select Height </option>
                          {userHeightList?.length &&
                            userHeightList?.map((item) => (
                              <option value={item?.name}>{item?.name}</option>
                            ))}
                        </Form.Select>
                        {errors.height && touched.height && (
                          <div className="error_text pt-2">{errors.height}</div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="input_box">
                      <div className="form_group">
                        <Form.Select
                          aria-label="Default select example"
                          name="weight"
                          value={values.weight}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="add_member_input"
                        >
                          <option>Select Weight </option>
                          {userWeightList?.length &&
                            userWeightList?.map((item) => (
                              <option value={item?.name}>{item?.name}</option>
                            ))}
                        </Form.Select>

                        {errors.weight && touched.weight && (
                          <div className="error_text pt-2">{errors.weight}</div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 mb_25">
                    <div className="col-md-12">
                      <div className="input_box">
                        <div className="form_group">
                          <div className="col-md-12 mb_25">
                            <Form.Select
                              aria-label="Default select example"
                              name="blood_group"
                              value={values.blood_group}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className="add_member_input"
                            >
                              <option>Select Blood Group</option>
                              <option value="A+">A+</option>
                              <option value="A-">A-</option>
                              <option value="B+">B+</option>
                              <option value="B-">B-</option>
                              <option value="AB+">AB+</option>
                              <option value="AB-">AB-</option>
                              <option value="o+">o+</option>
                              <option value="o-">o-</option>
                            </Form.Select>
                            {errors.blood_group && touched.blood_group && (
                              <div className="error_text pt-2">
                                {errors.blood_group}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <h3 className="signup_details_title">Gender</h3>
                <div className="row">
                  <div className="col-md-12">
                    <div className="radio_box">
                      <div className="gender_option" onClick={(e) =>
                        setFieldValue("gender", "male")
                      }>
                        <center >
                          <label className="" htmlFor={"male"}>
                            {values?.gender == "male" ? (
                              <label className="gender_option gender_active">
                                <center>
                                  <svg
                                    className="gender_icon"
                                    xmlns="http://www.w3.org/2000/svg"
                                    x="0"
                                    y="0"
                                    viewBox="0 0 1000 1000"
                                  >
                                    <path
                                      fill="#fff"
                                      d="M949.4 9.9H622.9c-22.5 0-40.7 18.3-40.7 40.7 0 22.5 18.2 40.8 40.7 40.8H851L570 372.3c-4.4 4.4-7.3 9.5-9.2 14.9-60.1-48.3-133.3-76-211.5-76-90.7 0-175.9 35.3-240.1 99.3-132.3 132.4-132.3 347.6 0 480.1 64.1 64.1 149.3 99.4 240 99.4 90.7 0 175.9-35.4 240-99.4C712.6 767 719.3 572.2 612.5 439c5.5-1.9 10.6-4.9 15.1-9.3l280.9-281V377c0 22.5 18.2 40.7 40.8 40.7 22.4 0 40.7-18.2 40.7-40.7V50.6c.1-22.4-18.2-40.7-40.6-40.7zM531.7 833.2c-97.5 97.5-267.4 97.5-364.8 0-100.6-100.6-100.6-264.3 0-364.9 48.7-48.7 113.5-75.5 182.5-75.5 68.8 0 133.7 26.7 182.3 75.5 100.5 100.6 100.5 264.3 0 364.9z"
                                    ></path>
                                  </svg>
                                </center>
                                <span className="gender_option_active_title">
                                  Male
                                </span>
                              </label>
                            ) : (
                              <svg
                                className="gender_icon"
                                xmlns="http://www.w3.org/2000/svg"
                                x="0"
                                y="0"
                                viewBox="0 0 1000 1000"
                              >
                                <path
                                  fill="#909196"
                                  d="M949.4 9.9H622.9c-22.5 0-40.7 18.3-40.7 40.7 0 22.5 18.2 40.8 40.7 40.8H851L570 372.3c-4.4 4.4-7.3 9.5-9.2 14.9-60.1-48.3-133.3-76-211.5-76-90.7 0-175.9 35.3-240.1 99.3-132.3 132.4-132.3 347.6 0 480.1 64.1 64.1 149.3 99.4 240 99.4 90.7 0 175.9-35.4 240-99.4C712.6 767 719.3 572.2 612.5 439c5.5-1.9 10.6-4.9 15.1-9.3l280.9-281V377c0 22.5 18.2 40.7 40.8 40.7 22.4 0 40.7-18.2 40.7-40.7V50.6c.1-22.4-18.2-40.7-40.6-40.7zM531.7 833.2c-97.5 97.5-267.4 97.5-364.8 0-100.6-100.6-100.6-264.3 0-364.9 48.7-48.7 113.5-75.5 182.5-75.5 68.8 0 133.7 26.7 182.3 75.5 100.5 100.6 100.5 264.3 0 364.9z"
                                ></path>
                              </svg>
                            )}
                          </label>
                          <span className="gender_option_title">Male</span>
                          <input
                            type="radio"
                            name="gender"
                            onChange={(e) =>
                              setFieldValue("gender", e.target.value)
                            }
                            id="male"
                            value="male"
                            checked={values?.gender === "male"}
                            placeholder=""
                            style={{ accentColor: "#199a8e" }}
                          />
                        </center>
                      </div>
                      <div className="gender_option" onClick={(e) =>
                        setFieldValue("gender", "female")
                      }>
                        <center >
                          <label className="" htmlFor={"female"}>
                            {values?.gender == "female" ? (
                              <lable className="gender_option gender_active_2">
                                <center>
                                  <svg
                                    className="gender_icon"
                                    xmlns="http://www.w3.org/2000/svg"
                                    x="0"
                                    y="0"
                                    viewBox="0 0 1000 1000"
                                  >
                                    <path
                                      fill="#fff"
                                      d="M775.6 285.6C775.6 133.4 652.2 10 500 10c-152.2 0-275.6 123.4-275.6 275.6 0 140.5 105.3 256.3 241.2 273.2v103.7H356.1c-22.4 0-40.6 18.2-40.6 40.6 0 22.4 18.2 40.6 40.6 40.6h109.6v205.7c0 22.4 18.2 40.6 40.6 40.6 22.4 0 40.6-18.2 40.6-40.6V743.7h102.5c22.4 0 40.6-18.2 40.6-40.6 0-22.4-18.2-40.6-40.6-40.6H546.9V556.9c129.8-22.3 228.7-135.1 228.7-271.3zm-472.7-.1c0-108.9 88.2-197.1 197.1-197.1 108.9 0 197.1 88.3 197.1 197.1 0 108.9-88.2 197.1-197.1 197.1-108.9 0-197.1-88.2-197.1-197.1z"
                                    ></path>
                                  </svg>
                                </center>
                                <span className="gender_option_active_title">
                                  Female
                                </span>
                              </lable>
                            ) : (
                              <svg
                                className="gender_icon"
                                xmlns="http://www.w3.org/2000/svg"
                                x="0"
                                y="0"
                                viewBox="0 0 1000 1000"
                              >
                                <path
                                  fill="#909196"
                                  d="M775.6 285.6C775.6 133.4 652.2 10 500 10c-152.2 0-275.6 123.4-275.6 275.6 0 140.5 105.3 256.3 241.2 273.2v103.7H356.1c-22.4 0-40.6 18.2-40.6 40.6 0 22.4 18.2 40.6 40.6 40.6h109.6v205.7c0 22.4 18.2 40.6 40.6 40.6 22.4 0 40.6-18.2 40.6-40.6V743.7h102.5c22.4 0 40.6-18.2 40.6-40.6 0-22.4-18.2-40.6-40.6-40.6H546.9V556.9c129.8-22.3 228.7-135.1 228.7-271.3zm-472.7-.1c0-108.9 88.2-197.1 197.1-197.1 108.9 0 197.1 88.3 197.1 197.1 0 108.9-88.2 197.1-197.1 197.1-108.9 0-197.1-88.2-197.1-197.1z"
                                ></path>
                              </svg>
                            )}
                          </label>
                          <span className="gender_option_title">Female</span>
                          <input
                            type="radio"
                            name="gender"
                            onChange={(e) =>
                              setFieldValue("gender", e.target.value)
                            }
                            id="female"
                            value="female"
                            checked={values?.gender === "female"}
                            placeholder=""
                            style={{ accentColor: "#199a8e" }}
                          />
                        </center>
                      </div>
                      <div className="gender_option" onClick={(e) =>
                        setFieldValue("gender", "other")
                      }>
                        <center >
                          <label className="" htmlFor={"other"}>
                            {values?.gender == "other" ? (
                              <label className="gender_option gender_active_3">
                                <center>
                                  <svg
                                    className="gender_icon"
                                    xmlns="http://www.w3.org/2000/svg"
                                    x="0"
                                    y="0"
                                    viewBox="0 0 1000 1000"
                                  >
                                    <path
                                      fill="#fff"
                                      d="M949.4 9.9H622.9c-22.5 0-40.7 18.3-40.7 40.7 0 22.5 18.2 40.8 40.7 40.8H851L570 372.3c-4.4 4.4-7.3 9.5-9.2 14.9-60.1-48.3-133.3-76-211.5-76-90.7 0-175.9 35.3-240.1 99.3-132.3 132.4-132.3 347.6 0 480.1 64.1 64.1 149.3 99.4 240 99.4 90.7 0 175.9-35.4 240-99.4C712.6 767 719.3 572.2 612.5 439c5.5-1.9 10.6-4.9 15.1-9.3l280.9-281V377c0 22.5 18.2 40.7 40.8 40.7 22.4 0 40.7-18.2 40.7-40.7V50.6c.1-22.4-18.2-40.7-40.6-40.7zM531.7 833.2c-97.5 97.5-267.4 97.5-364.8 0-100.6-100.6-100.6-264.3 0-364.9 48.7-48.7 113.5-75.5 182.5-75.5 68.8 0 133.7 26.7 182.3 75.5 100.5 100.6 100.5 264.3 0 364.9z"
                                    ></path>
                                  </svg>
                                </center>
                                <span className="gender_option_active_title">
                                  Other
                                </span>
                              </label>
                            ) : (
                              <svg
                                className="gender_icon"
                                xmlns="http://www.w3.org/2000/svg"
                                x="0"
                                y="0"
                                viewBox="0 0 1000 1000"
                              >
                                <path
                                  fill="#909196"
                                  d="M949.4 9.9H622.9c-22.5 0-40.7 18.3-40.7 40.7 0 22.5 18.2 40.8 40.7 40.8H851L570 372.3c-4.4 4.4-7.3 9.5-9.2 14.9-60.1-48.3-133.3-76-211.5-76-90.7 0-175.9 35.3-240.1 99.3-132.3 132.4-132.3 347.6 0 480.1 64.1 64.1 149.3 99.4 240 99.4 90.7 0 175.9-35.4 240-99.4C712.6 767 719.3 572.2 612.5 439c5.5-1.9 10.6-4.9 15.1-9.3l280.9-281V377c0 22.5 18.2 40.7 40.8 40.7 22.4 0 40.7-18.2 40.7-40.7V50.6c.1-22.4-18.2-40.7-40.6-40.7zM531.7 833.2c-97.5 97.5-267.4 97.5-364.8 0-100.6-100.6-100.6-264.3 0-364.9 48.7-48.7 113.5-75.5 182.5-75.5 68.8 0 133.7 26.7 182.3 75.5 100.5 100.6 100.5 264.3 0 364.9z"
                                ></path>
                              </svg>
                            )}
                          </label>
                          <span className="gender_option_title">Other</span>
                          <input
                            type="radio"
                            name="gender"
                            onChange={(e) =>
                              setFieldValue("gender", e.target.value)
                            }
                            id="other"
                            value="other"
                            checked={values?.gender === "other"}
                            placeholder=""
                            style={{ accentColor: "#199a8e" }}
                          />
                        </center>
                      </div>
                    </div>
                  </div>
                  {errors.gender && touched.gender && (
                    <div className="error_text">{errors.gender}</div>
                  )}
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <h5 className="medical_history_title">
                      Any Medical History?
                    </h5>
                  </div>
                </div>
                <div className="row mb_25">
                  <div className="medical_history_box">
                    <div className="medical_history_box_inner">
                      <input
                        type="radio"
                        value="Yes"
                        name="yesNo"
                        checked={yesNo === "Yes"}
                        className="mt_10"
                        onChange={onChangeValue}
                        style={{ accentColor: "#199a8e" }}
                      />
                      <lable for="Yes">
                        <h5 className="medical_history_title_1">Yes</h5>
                      </lable>
                    </div>
                    <div className="medical_history_box_inner">
                      <input
                        type="radio"
                        value="No"
                        name="yesNo"
                        checked={yesNo === "No"}
                        className="mt_10"
                        onChange={onChangeValue}
                        style={{ accentColor: "#199a8e" }}
                      />
                      <lable for="Yes">
                        <h5 className="medical_history_title_1">No</h5>
                      </lable>
                    </div>
                  </div>
                </div>
                {yesNo === "Yes" ? (
                  <div className="row">
                    <div className="col-md-12">
                      <div className="input_box mb_30">
                        <Field
                          className="custom-select"
                          name="medical_history"
                          options={
                            medicalHistory?.length &&
                            medicalHistory?.map((item) => {
                              return {
                                label: item?.name,
                                value: item?.id,
                              };
                            })
                          }
                          component={CustomSelect}
                          placeholder="Select Medical History..."
                          isMulti={true}
                        />
                      </div>
                    </div>
                  </div>
                ) : null}

                <div className="row">
                  <div className="col-md-12">
                    <center>
                      <Button type="submit" className="add_member_save_2">
                        Save
                      </Button>
                    </center>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Modal.Body>
      <Modal.Footer style={{ border: "none", display: "none" }}></Modal.Footer>
    </Modal>
  );
};

export default AddMember;
