import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  HealthCategoryList,
  HealthList,
  RadiologicalCoupanListAPI,
  RadiologyBookingCreateApi,
  HealthCareBookingCreateApi,
  RadiologyBookingDetailsApi,
  RadiologyCancelBookingApi,
  RadiologyDataApi,
  RadiologyPastBookingApi,
  RadiologyRescheduleBookingApi,
  RadiologyTestCenterDetailsApi,
  RadiologyTestCentersApi,
  RadiologyTestCenterSlotsApi,
  RadiologyUpcomingBookingsApi,
  HealthCareUpcomingBookingsApi,
  PreceptionApi,
  CreateSpecialityBookingAPI,
  MemberShipBooking,
  MemberShipBookingStatus,
  SurgeryList,
  SurgeryServiceList,
  SurgeryBookingAPI,
  ReferalDetail,

} from "../../Routes/Service";
import { setLoading, setMessage } from "./LayoutsSice";
import { AlertEnum } from "../../Utilities/Enums";

const initialState = {
  radiologyData: [],
  radiologyCart: [],
  radiologyTestCenterList: [],
  radiologyTestCenterDetalisList: [],
  radiologyTestCenterDetailsById: "",
  radiologyUpcomingBookingsList: [],
  healthCareUpcomingBookingsList: [],
  radiologyTestCenterSlotsList: [],
  radiologyBookingDetailsById: "",
  radiologyBookingDetail: "",
  radiologyPastBookingList: [],
  radiologyCoupanListData: [],
  healthListData: [],
  surgeryListData: [],
  healthCategoryList: [],
  surgeryserviceList: [],
  preceptionList: [],
  healthbooking: "",
  goldmemberbooking: "",
  specialitybooking: {},
  wallet_price: "",
  goldMembershipStatus: '',
  goldMembershipStatus1: {},
  surgerybooking: {},
  referListData: {},
  bookNows:{},
  wallet_cutPrice:{},
  paramas:""
};

export const HealthListData = createAsyncThunk(
  "HealthListData",
  async (values, { dispatch }) => {
    try {

      const result = await HealthList(values);

      if (result) {
        return result?.data;
      } else {
        throw result;
      }
    } catch (error) {
      dispatch(setLoading(false));
      // dispatch(
      //   setMessage({
      //     text: error?.message,
      //     type: AlertEnum.Error,
      //   })
      // );
      return error;
    }
  }
);
export const SurgeryListData = createAsyncThunk(
  "SurgeryListData",
  async (values, { dispatch }) => {
    try {

      const result = await SurgeryList(values);
      if (result) {
        return result?.data;
      } else {
        throw result;
      }
    } catch (error) {
      dispatch(setLoading(false));
      // dispatch(
      //   setMessage({
      //     text: error?.message,
      //     type: AlertEnum.Error,
      //   })
      // );
      return error;
    }
  }
);
export const ReferListData = createAsyncThunk(
  "ReferListData",
  async (values, { dispatch }) => {
    try {

      const result = await ReferalDetail(values);
      if (result) {
        return result?.data;
      } else {
        throw result;
      }
    } catch (error) {
      dispatch(setLoading(false));
      // dispatch(
      //   setMessage({
      //     text: error?.message,
      //     type: AlertEnum.Error,
      //   })
      // );
      return error;
    }
  }
);
export const PreceptionList = createAsyncThunk(
  "PreceptionList",
  async (values, { dispatch }) => {
    try {
      const result = await PreceptionApi(values);
      if (result) {
        return result?.data;
      } else {
        throw result;
      }
    } catch (error) {
      dispatch(setLoading(false));
      // dispatch(
      //   setMessage({
      //     text: error?.message,
      //     type: AlertEnum.Error,
      //   })
      // );
      return error;
    }
  }
);
export const HealthCategoryListData = createAsyncThunk(
  "healthCategoryList",
  async (values, { dispatch }) => {
    try {
      const result = await HealthCategoryList(values);
      if (result) {
        return result?.data;
      } else {
        throw result;
      }
    } catch (error) {
      dispatch(setLoading(false));
      // dispatch(
      //   setMessage({
      //     text: error?.message,
      //     type: AlertEnum.Error,
      //   })
      // );
      return error;
    }
  }
);

export const SurgeryServiceListData = createAsyncThunk(
  "SurgeryServiceListData",
  async (values, { dispatch }) => {
    try {
      const result = await SurgeryServiceList(values);
      if (result) {
        return result?.data;
      } else {
        throw result;
      }
    } catch (error) {
      dispatch(setLoading(false));
      // dispatch(
      //   setMessage({
      //     text: error?.message,
      //     type: AlertEnum.Error,
      //   })
      // );
      return error;
    }
  }
);
export const GetRadioLogyData = createAsyncThunk(
  "GetRadioLogyData",
  async (values, { dispatch }) => {
    try {
      const result = await RadiologyDataApi({ center_id: values });
      if (result) {
        return result?.data;
      } else {
        throw result;
      }
    } catch (error) {
      dispatch(setLoading(false));
      // dispatch(
      //   setMessage({
      //     text: error?.message,
      //     type: AlertEnum.Error,
      //   })
      // );
      return error;
    }
  }
);

export const RadiologyBookingDetails = createAsyncThunk(
  "RadiologyBookingDetails",
  async (values, { dispatch }) => {
    try {
      const result = await RadiologyBookingDetailsApi({ booking_id: values });

      if (result) {
        return result?.data;
      } else {
        throw result;
      }
    } catch (error) {
      dispatch(setLoading(false));
      // dispatch(
      //   setMessage({
      //     text: error?.message,
      //     type: AlertEnum.Error,
      //   })
      // );
      return error;
    }
  }
);
export const RadiologyTestCenters = createAsyncThunk(
  "RadiologyTestCenters",
  async (values, { dispatch }) => {
    try {
      const result = await RadiologyTestCentersApi(values);
      if (result) {
        return result?.data;
      } else {
        throw result;
      }
    } catch (error) {
      dispatch(setLoading(false));
      // dispatch(
      //   setMessage({
      //     text: error?.message,
      //     type: AlertEnum.Error,
      //   })
      // );
      return error;
    }
  }
);
export const RadiologyTestCenterDetails = createAsyncThunk(
  "RadiologyTestCenterDetails",
  async (values, { dispatch }) => {
    try {
      const result = await RadiologyTestCenterDetailsApi({
        test_center_id: values,
      });
      if (result) {
        return result?.data;
      } else {
        throw result;
      }
    } catch (error) {
      dispatch(setLoading(false));
      // dispatch(
      //   setMessage({
      //     text: error?.message,
      //     type: AlertEnum.Error,
      //   })
      // );
      return error;
    }
  }
);
export const RadiologyTestCenterSlots = createAsyncThunk(
  "RadiologyTestCenterSlots",
  async (values, { dispatch }) => {
    try {
      const result = await RadiologyTestCenterSlotsApi(values);
      if (result) {
        return result?.data;
      } else {
        throw result;
      }
    } catch (error) {
      dispatch(setLoading(false));
      // dispatch(
      //   setMessage({
      //     text: error?.message,
      //     type: AlertEnum.Error,
      //   })
      // );
      return error;
    }
  }
);
export const RadiologyUpcomingBookings = createAsyncThunk(
  "RadiologyUpcomingBookings",
  async (values, { dispatch }) => {
    try {
      const result = await RadiologyUpcomingBookingsApi(values);
      if (result) {
        return result?.data;
      } else {
        throw result;
      }
    } catch (error) {
      dispatch(setLoading(false));
      // dispatch(
      //   setMessage({
      //     text: error?.message,
      //     type: AlertEnum.Error,
      //   })
      // );
      return error;
    }
  }
);
export const HealthCareUpcomingBookings = createAsyncThunk(
  "HealthCareUpcomingBookings",
  async (values, { dispatch }) => {
    try {
      const result = await HealthCareUpcomingBookingsApi(values);
      if (result) {
        return result?.data;
      } else {
        throw result;
      }
    } catch (error) {
      dispatch(setLoading(false));
      // dispatch(
      //   setMessage({
      //     text: error?.message,
      //     type: AlertEnum.Error,
      //   })
      // );
      return error;
    }
  }
);
export const RadiologyRescheduleBooking = createAsyncThunk(
  "RadiologyRescheduleBooking",
  async (values, { dispatch }) => {
    try {
      const result = await RadiologyRescheduleBookingApi(values);
      if (result) {
        dispatch(
          setMessage({
            text: result?.message,
            type: AlertEnum.Success,
          })
        );
        return result?.data;
      } else {
        throw result;
      }
    } catch (error) {
      dispatch(setLoading(false));
      // dispatch(
      //   setMessage({
      //     text: error?.message,
      //     type: AlertEnum.Error,
      //   })
      // );
      return error;
    }
  }
);
export const RadiologyCancelBooking = createAsyncThunk(
  "RadiologyRescheduleBooking",
  async (values, { dispatch }) => {
    try {
      const result = await RadiologyCancelBookingApi(values);
      if (result) {
        dispatch(
          setMessage({
            text: result?.message,
            type: AlertEnum.Success,
          })
        );
        dispatch(RadiologyUpcomingBookings());
        return result?.data;
      } else {
        throw result;
      }
    } catch (error) {
      dispatch(setLoading(false));
      // dispatch(
      //   setMessage({
      //     text: error?.message,
      //     type: AlertEnum.Error,
      //   })
      // );
      return error;
    }
  }
);

export const RadiologyPastBooking = createAsyncThunk(
  "RadiologyPastBooking",
  async (values, { dispatch }) => {
    try {
      const result = await RadiologyPastBookingApi(values);
      if (result) {
        return result?.data;
      } else {
        throw result;
      }
    } catch (error) {
      dispatch(setLoading(false));
      // dispatch(
      //   setMessage({
      //     text: error?.message,
      //     type: AlertEnum.Error,
      //   })
      // );
      return error;
    }
  }
);
export const RadiologicalCoupanList = createAsyncThunk(
  "RadiologicalCoupanList",
  async (values, { dispatch }) => {
    try {
      const result = await RadiologicalCoupanListAPI(values);
      if (result) {
        return result?.data;
      } else {
        throw result;
      }
    } catch (error) {
      dispatch(setLoading(false));
      // dispatch(
      //   setMessage({
      //     text: error?.message,
      //     type: AlertEnum.Error,
      //   })
      // );
      return error;
    }
  }
);
export const RadiologyBookingCreate = createAsyncThunk(
  "RadiologyBookingCreate",
  async (values, { dispatch }) => {
    try {
      const result = await RadiologyBookingCreateApi(values);
      if (result) {
        dispatch(RadiologyUpcomingBookings());
        return result?.data;
      } else {
        throw result;
      }
    } catch (error) {
      dispatch(setLoading(false));
      // dispatch(
      //   setMessage({
      //     text: error?.message,
      //     type: AlertEnum.Error,
      //   })
      // );
      return error;
    }
  }
);
export const HealthCareBookingCreate = createAsyncThunk(
  "HealthCareBookingCreate",
  async (values, { dispatch }) => {
    try {
      const result = await HealthCareBookingCreateApi(values);

      if (result) {
        // dispatch(HealthCareUpcomingBookingsApi());

        return result?.data;
      } else {

        throw result;
      }
    } catch (error) {
      dispatch(setLoading(false));
      // dispatch(
      //   setMessage({
      //     text: error?.message,
      //     type: AlertEnum.Error,
      //   })
      // );
      return error;
    }
  }
);
export const SurgeryBookingCreate = createAsyncThunk(
  "SurgeryBookingCreate",
  async (values, { dispatch }) => {
    try {
      const result = await SurgeryBookingAPI(values);

      if (result) {


        return result?.data;
      } else {

        throw result;
      }
    } catch (error) {
      dispatch(setLoading(false));
    
      return error;
    }
  }
);
export const SpecialityBoking = createAsyncThunk(
  "SpecialityBoking",
  async (values, { dispatch }) => {
    try {
      const result = await CreateSpecialityBookingAPI(values);

      if (result) {
      
        return result?.data;
      } else {

        throw result;
      }
    } catch (error) {
      dispatch(setLoading(false));
    
      return error;
    }
  }
);
export const GoldMemberBoking = createAsyncThunk(
  "GoldMemberBoking",
  async (values, { dispatch }) => {
    try {
      const result = await MemberShipBooking(values);
      if (result) {
        return result?.data;
      } else {
        throw result;
      }
    } catch (error) {
      dispatch(setLoading(false));
  
      return error;
    }
  }
);
export const GoldMemberBokingstatus = createAsyncThunk(
  "GoldMemberBokingstatus",
  async (values, { dispatch }) => {
    try {
      const result = await MemberShipBookingStatus({ membership_id: values });

      if (result) {
        return result?.data;
      } else {
        throw result;
      }
    } catch (error) {
      dispatch(setLoading(false));
      return error;
    }
  }
);
export const RadiologySlice = createSlice({
  name: "RadiologySlice",
  initialState,
  reducers: {
    radiologyaddToCart: (state, action) => {
      if (action.payload) {
      const itemInCart = state.radiologyCart.find(
        (item) => item.id === action.payload.id
      );
      if (itemInCart) {
        itemInCart.quantity++;
      } else {
        state.radiologyCart.push({ ...action.payload, quantity: 1 });
      }
    }
    },
    bookButtons: (state, action) => {
      state.bookNows = action.payload
    },
    params:(state, action) => {
      state.paramas = action.payload
    },
    removeItem: (state, action) => {
      const removeItem = state.radiologyCart?.filter(
        (item) => item.id !== action.payload
      );
      state.radiologyCart = removeItem;
    },
    clearRadiologyCartItem: (state, action) => {
      state.radiologyCart = [];
    },
    clearGoldBookConsultant: (state, action) => {
      state.goldmemberbooking = "";
    },
    clearSpecalityBooking: (state, action) => {
      state.specialitybooking = "";
    },
    walletPrice: (state, action) => {

      state.wallet_price = action.payload;
    },
    WalletCute:(state, action) => {
     
      state.wallet_cutPrice = action.payload;
    },
    clearRadiologyTestCenterDetailsById: (state, action) => {
      state.radiologyTestCenterDetailsById = "";
    },
    setRadiologyTestCenterDetailsById: (state, action) => {
      state.radiologyTestCenterDetailsById = action.payload;
    },
    setServiceid: (state, action) => {
      state.setServicesurgeryid = action.payload;
    },
    setRadiologyBookingDetailsById: (state, action) => {
      state.radiologyBookingDetailsById = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(GetRadioLogyData.fulfilled, (state, action) => {
      state.radiologyData = action.payload;
    });
    builder.addCase(HealthListData.fulfilled, (state, action) => {
      state.healthListData = action.payload;
    });
    builder.addCase(SurgeryListData.fulfilled, (state, action) => {
      state.surgeryListData = action.payload;
    });
    builder.addCase(ReferListData.fulfilled, (state, action) => {
      state.referListData = action.payload;
    });
    builder.addCase(GoldMemberBokingstatus.fulfilled, (state, action) => {

      state.goldMembershipStatus = action.payload;
    
    });
  
    builder.addCase(PreceptionList.fulfilled, (state, action) => {
      state.preceptionList = action.payload;
    });
    builder.addCase(HealthCategoryListData.fulfilled, (state, action) => {
      state.healthCategoryList = action.payload;
    });
    builder.addCase(SurgeryServiceListData.fulfilled, (state, action) => {
      state.surgeryserviceList = action.payload;
    });
    builder.addCase(RadiologyTestCenters.fulfilled, (state, action) => {
      state.radiologyTestCenterList = action.payload;
    });
    builder.addCase(RadiologyTestCenterDetails.fulfilled, (state, action) => {
      state.radiologyTestCenterDetalisList = action.payload;
    });
    builder.addCase(RadiologyUpcomingBookings.fulfilled, (state, action) => {
      state.radiologyUpcomingBookingsList = action.payload;
    });
    builder.addCase(HealthCareUpcomingBookings.fulfilled, (state, action) => {
      state.healthCareUpcomingBookingsList = action.payload;
    });
    builder.addCase(RadiologyTestCenterSlots.fulfilled, (state, action) => {
      state.radiologyTestCenterSlotsList = action.payload;
    });
    builder.addCase(RadiologyBookingDetails.fulfilled, (state, action) => {
      state.radiologyBookingDetail = action.payload;
    });
    builder.addCase(RadiologyPastBooking.fulfilled, (state, action) => {
      state.radiologyPastBookingList = action.payload;
    });
    builder.addCase(HealthCareBookingCreate.fulfilled, (state, action) => {
      state.healthbooking = action.payload;
    });
    builder.addCase(SurgeryBookingCreate.fulfilled, (state, action) => {
      state.surgerybooking = action.payload;
    });
    builder.addCase(SpecialityBoking.fulfilled, (state, action) => {
      {
        if (action.payload == undefined) {
          state.specialitybooking = true;
        } else {
          state.specialitybooking = action.payload;
        }
      }
    });
    builder.addCase(GoldMemberBoking.fulfilled, (state, action) => {
      state.goldmemberbooking = action.payload;
    });
    builder.addCase(RadiologicalCoupanList.fulfilled, (state, action) => {
      state.radiologyCoupanListData = action.payload;
    });
  },
});
export const {
  radiologyaddToCart,
  removeItem,
  setRadiologyTestCenterDetailsById,
  setRadiologyBookingDetailsById,
  clearRadiologyCartItem,
  clearRadiologyTestCenterDetailsById,
  clearSpecalityBooking,
  walletPrice,
  clearGoldBookConsultant,
  setServiceid,
  bookButtons,
  WalletCute,
  params
} = RadiologySlice.actions;
export default RadiologySlice.reducer;
