import React, { useEffect, useState } from "react";
import { Icon } from "../../../../Utilities/Icons";
import { useDispatch, useSelector } from "react-redux";
import {
  OrderCancel,
  setOrderDetailByID,
} from "../../../../Store/Reducer/OrderSilce";
import { MK_TOKEN } from "../../../../Utilities/Enums";
import { useNavigate } from "react-router-dom";
import Pagination from "../../../Common/Pagination/Pagination";

const MedicineOrders = () => {
  const { medicineOrderList } = useSelector(({ OrderSilce }) => OrderSilce);
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const { session } = useSelector(({ AuthSlice }) => AuthSlice);


  const [currentPage, setCurrentPage] = useState(0); // Current page index
  const itemsPerPage = 5; // Number of bookings to display per page

  const handlePageChange = (selected) => {
    setCurrentPage(selected); // Update the current page index
  };

  const mideicalList = medicineOrderList.hasError
    ? []
    : medicineOrderList?.slice(
      currentPage * itemsPerPage,
      (currentPage + 1) * itemsPerPage
    );


  return (
    <>
      <div className="medicine_orders_body_box_card_head">
        <div className="row">
          <div className="col-md-6">
            <h5 className="medicine_orders_body_card_head_title">
              Medicine Orders
            </h5>
          </div>
          <div className="col-md-6"></div>
        </div>
      </div>
      <div className="medicine_orders_body_box_card_body">
        <div className="row">
          {mideicalList?.map((item, index) => {
            // let item_address = JSON.parse(item?.address);
            let item_product = JSON.parse(item?.products);
            let address = JSON.parse(item?.address);

            return (
              <div className="col-md-12" key={index}>
                <div className="medicine_order_card_box mb_25">
                  <div
                    className="medicine_orders_card_head"
                    onClick={() => dispatch(setOrderDetailByID(item?.id))}
                  >
                    <div className="row" onClick={() => navigate(`/orders/order-detail/${item?.id}`)}>
                      <div className="col-md-6 col-8">
                        <img
                          src={Icon.bag}
                          className="medicine_order_card_icon"
                        ></img>
                        <span className="medicine_orders_card_title">
                          Order Id #{item?.order_id}
                        </span>
                      </div>
                      <div className="col-md-6 col-4">
                        <h5 className="medicine_orders_card_head_status_processing">
                          {item?.status}
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="medicine_orders_card_body">
                    <div className="row">
                      <div className="col-md-3 col-6">
                        <h5 className="radiology_booked_test_for">Medicines For</h5>
                        <div className="radiology_booked_test_profile_box">
                          <div>
                            <div className="my_test_profile_icon_2">
                              {" "}
                              {session?.name.charAt(0)}{" "}
                            </div>
                          </div>
                          <div>
                            <h5 className="radiology_booked_test_for_name">
                              {session?.name}{" "}
                            </h5>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3 col-6">
                        <h5 className="radiology_booked_test_for">Items</h5>
                        <h5 className="radiology_booked_test_for_name">
                          {item_product?.length}
                        </h5>
                      </div>
                      <div className="col-md-3 col-3">
                        <h5 className="radiology_booked_test_for">Amount</h5>
                        <h5 className="radiology_booked_test_for_name radiology_booked_test_for_amount">
                          ₹ {item?.sales_total_amount}
                        </h5>
                      </div>
                      {/* <div className="col-md-3 col-9">
                        <div
                          className="medicine_order_box_inner"
                          onClick={() =>
                            dispatch(
                              OrderCancel({
                                mobile_no: address?.mobile_no,
                                order_id: item?.order_id,
                                isMK_TOKEN: localStorage.getItem(MK_TOKEN),
                              })
                            )
                          }
                        >
                          <img src={Icon.cancel} className="cancel_icon"></img>{" "}
                          <span className="cancel_appointment">
                            Cancle Booking
                          </span>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
          {
            mideicalList && mideicalList?.length > 10 && (
              <Pagination
              totalItems={medicineOrderList.length}
              itemsPerPage={itemsPerPage}
              onPageChange={handlePageChange}
            />
            )
          }

         
        </div>
      </div>
    </>
  );
};

export default MedicineOrders;
