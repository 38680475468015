import React, { useState } from "react";
import { useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { LabTestCenters, LabtestCities, setLabTestCenterDetailsById } from "../../../Store/Reducer/LabtestSlice";
import { Icon } from "../../../Utilities/Icons";
import location from '../../../Assets/img/png/location.png'
import { GetCity } from "../../../Store/Reducer/CommonSlice";
import downloadArrow from '../../../Assets/img/svg/downarrow_solid.svg'
import LabCityModal from "../LabTestModel/LabCityModal";
import { HealthCategoryListData, HealthListData, SurgeryListData, SurgeryServiceListData } from "../../../Store/Reducer/RadiologySlice";
import { SurgeryServiceList } from "../../../Routes/Service";
const SurgeryListModal = (props) => {

    const dispatch = useDispatch();
    const [gender, setGender] = useState();
    const [category, setCategory] = useState()
    function onChangeValue(event) {
        setGender(event.target.value);
    }
    useEffect(() => {
        if (gender) {
            surgeryListData?.filter(function (item) {
            });
        } else {
            setGender(surgeryListData?.id);
        }
    }, [gender]);
    useEffect(() => {
        dispatch(SurgeryListData())
    }, [dispatch])
    const { surgeryListData } = useSelector(
        ({ RadiologySlice }) => RadiologySlice
    );
    useEffect(() => {
        dispatch(HealthCategoryListData());
    }, [dispatch]);
    const [inputText, setInputText] = useState("");
    let inputHandler = (e) => {
        var lowerCase = e.target.value.toLowerCase(); //.toLowerCase();
        setInputText(lowerCase);
    };
    const currentDate = new Date().toISOString().split('T')[0];
   
    const filteredData =
        surgeryListData?.length &&
        surgeryListData?.filter((el) => {
            if (inputText === "") {
                return el;
            }
            else {
               
                return el?.name?.toLowerCase().includes(inputText);
            }
        });

    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                dialogClassName="lab_test_modal_box"
                backdrop="static"
            >
                <Modal.Header
                    className="lab_test_modal_box_head_padding"
                    style={{ marginBottom: "none", border: "none" }}
                >
                    <h5 className="lab_test_popup_title">Surgery Inquiry</h5>
                    <img
                        src={Icon.cross}
                        className="lab_test_popup_close"
                        onClick={props.onHide}
                        alt=""
                    ></img>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12">
                            <img
                                src={Icon.search}
                                className="select_city_search_icon"
                                alt=""
                            ></img>
                            <input
                                type="search"
                                style={{ width: "100%" }}
                                className="health_package_input_search"
                                placeholder="Search"
                                onChange={inputHandler}
                            />
                        </div>
                    </div>
                    <div className="lab_test_modal_box_2">
                        {
                            filteredData && filteredData?.map((item, index) => (
                                <>
                                    <div className="lab_member_box" key={index}>
                                        <div className="lab_member_box_inner">
                                            <div>
                                                <div className="health_radiological_popup_list_box_inner">
                                                    <div>
                                                        <img
                                                            className="select_radiology_img"
                                                            src={`${item?.image}`}
                                                            alt=""
                                                            height="70px"
                                                            width="70px"
                                                            style={{
                                                                borderRadius: "50%"
                                                            }}
                                                        ></img>
                                                    </div>
                                                    <div>
                                                        <label for="Radiological_Test7">
                                                            <h3
                                                                className="healthradiological_radiological_test_title"
                                                                onClick={() => {
                                                                    props.setRadiologyCenterDetailModel(true);
                                                                    props.onHide();
                                                                }}
                                                            >
                                                                {item?.name}
                                                            </h3>
                                                            <div className="healthradiological_star_box">
                                                                <h6 className="amounthealthcare">
                                                                    {/* ₹ {item?.amount} */}
                                                                </h6>
                                                            </div>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <input
                                                    type="radio"
                                                    id={item?.id}
                                                    name="gender"
                                                    className="healthradiological_input"
                                                    checked={gender == item?.id}
                                                    // onChange={(e) => onChangeValue(e)}
                                                    value={item?.id}
                                                    onChange={(e) => {
                                                        dispatch(SurgeryServiceListData({ category: item?.id }));
                                                        props?.formik?.setFieldValue("center_id", item?.id);
                                                        props?.formik?.setFieldValue("center_name", item?.name);
                                                        onChangeValue(e)
                                                        setCategory(item)
                                                    }}
                                                    style={{ accentColor: "#199a8e"}}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <hr className="consult_member_hr" />
                                </>
                            ))}

                        <center>
                            { gender && (
                                <div
                                    className="col-md-12 text-center"
                                    style={{
                                        position: "fixed",
                                        bottom: "20px",
                                        left: 0,
                                        right: 0,
                                    }}
                                >
                                    <Button
                                        className="lab_member_modal_btn"
                                        onClick={() => {
                                            props.onHide();
                                            props.onNext();

                                        }}
                                    >
                                        Continue
                                    </Button>
                                </div>
                            )}

                        </center>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default SurgeryListModal;
