import React, { useEffect, useState } from "react";
import { Icon } from "../../../../Utilities/Icons";
import { Button } from "react-bootstrap";
import ReactStars from "react-rating-stars-component";
import { useDispatch, useSelector } from "react-redux";
import {
  AddRatings,
  RescheduleDoctorDetails,
  BookingDetail,
} from "../../../../Store/Reducer/BookingSlice";
import CancelationReasonModel from "../../../Common/CancelationReasonModel/CancelationReasonModel";
import SelectBookingDateTime from "../../../Common/SelectBookingDateTime/SelectBookingDateTime";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import PresciptionDetails from "../../../Common/BookingSuccessModel/PresciptionDetails";
import { DownloadURL } from "../../../../Utilities/Functions";
import ReactPaginate from 'react-paginate';
import Pagination from "../../../Common/Pagination/Pagination";
import backimg from '../../../../Assets/img/png/NoDocuments.png'

const ConsultationList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const {
    upcomingBookings,
    pastBookings,
    cancelationReason,
    rescheduleDoctorDetails,
    bookingDetail,
  } = useSelector(({ BookingSlice }) => BookingSlice);
  const [cancelationReasonModel, setCancelationReasonModel] = useState(false);
  const [selectBookingDateTimeModel, setSelectBookingDateTimeModel] =
    useState(false);
  const [presciptionDetailModel, setPresciptionDetailModel] = useState(false);
  const [ByIdPastBookings, setByIdPastBookings] = useState(pastBookings);
  const [ByIdUpCommingBookings, setByIdUpCommingBookings] =
    useState(upcomingBookings);

  const ratingChanged = (newRating, booking) => {
    dispatch(
      AddRatings({
        booking_id: booking,
        rating: newRating,
        rating_message: "test",
      })
    );
  };

  useEffect(() => {
    if (id) {
      let newData =
        pastBookings?.length &&
        pastBookings?.filter(function (item) {
          if (id == item?.patient_details?.id) {
            return item;
          }
        });
      setByIdPastBookings(newData);
      let newData2 =
        upcomingBookings?.length &&
        upcomingBookings?.filter(function (item) {
          if (id == item?.patients_details?.id) {
            return item;
          }
        });
      setByIdUpCommingBookings(newData2);
    } else {
      setByIdUpCommingBookings(upcomingBookings);
      setByIdPastBookings(pastBookings);
    }
  }, [upcomingBookings, id]);


  const [currentPage, setCurrentPage] = useState(0); // Current page index
  const itemsPerPage = 5; // Number of bookings to display per page

  const handlePageChange = (selected) => {
    setCurrentPage(selected); // Update the current page index
  };

  const displayedUpcomingBookings = ByIdUpCommingBookings.hasError
    ? []
    : ByIdUpCommingBookings?.slice(
      currentPage * itemsPerPage,
      (currentPage + 1) * itemsPerPage
    );


  const [currentPage2, setCurrentPage2] = useState(0);
  const handlePageChange2 = (selected) => {
    setCurrentPage2(selected); // Update the current page index
  };
  const displayedBookings = ByIdPastBookings.hasError
    ? []
    : ByIdPastBookings?.slice(
      currentPage2 * itemsPerPage,
      (currentPage2 + 1) * itemsPerPage
    );


  return (
    <>
      {console.log("bookingDetailbookingDetail", bookingDetail)}
      <SelectBookingDateTime
        show={selectBookingDateTimeModel}
        bookingDateTime={rescheduleDoctorDetails?.data}
        bookingDetail={bookingDetail}
        onHide={() => setSelectBookingDateTimeModel(false)}
      />
      <CancelationReasonModel
        show={cancelationReasonModel}
        cancelationReason={cancelationReason}
        onHide={() => {
          setCancelationReasonModel(!cancelationReasonModel);
        }}
      />
      <PresciptionDetails
        show={presciptionDetailModel}
        onHide={() => setPresciptionDetailModel(false)}
      />
      <div className="my_consultation_body_box_card_body">
        <div className="row">
          <div className="col-md-12">
            <h3 className="upcoming_consultation_title">
              Upcoming Consultations
            </h3>

            {displayedUpcomingBookings?.length > 0 ? (
              <>
                {displayedUpcomingBookings?.map((upcomingBooking, index) => (
                  <div className=" mb_25">
                    <div
                      className="upcoming_consultation_card_head"
                      key={index}
                      onClick={() => navigate(`/chat/${upcomingBooking?.id}`)}
                    >
                      <img
                        src={Icon.scheduled}
                        className="upcoming_consultation_card_icon"
                        alt=""
                      ></img>{" "}
                      <span className="upcoming_consultation_card_title">
                        Scheduled on {upcomingBooking?.appointment_date} at{" "}
                        {upcomingBooking?.appointment_time}
                      </span>
                    </div>
                    <div className="upcoming_consultation_card_body">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="upcoming_consultation_box">
                            <div>
                              <img
                                src={upcomingBooking?.doctor_details.image}
                                className="my_consultation_doc_img"
                                alt=""
                              ></img>
                            </div>
                            <div>
                              <h5
                                cla
                                ssName="upcoming_consultation_card_body_toptitle"
                              >
                                Appointment with
                              </h5>
                              <h3 className="upcoming_consultation_card_body_title">
                                {upcomingBooking?.doctor_details?.name}
                              </h3>
                              <h6 className="upcoming_consultation_card_body_subtitle">
                                For {upcomingBooking?.patients_details?.name}
                              </h6>
                              <h6 className="upcoming_consultation_card_body_endtitle">
                                Problem :{" "}
                                {upcomingBooking?.health_problems_name}
                              </h6>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="consultation_option_box">
                            <div
                              className="consultation_option_box_inner_1"
                              onClick={() => {
                                setCancelationReasonModel(true);
                                dispatch(BookingDetail(upcomingBooking));
                              }}
                            >
                              <img
                                src={Icon.cancel}
                                className="cancel_icon"
                                alt=""
                              ></img>{" "}
                              <span className="cancel_appointment">
                                Cancle Appointment
                              </span>
                            </div>
                            <div
                              className="consultation_option_box_inner_2"
                              onClick={() => {
                                setSelectBookingDateTimeModel(true);
                                dispatch(
                                  RescheduleDoctorDetails({
                                    doctor_id: upcomingBooking.doctor_id,
                                    date: upcomingBooking.appointment_date,
                                  })
                                );
                                dispatch(BookingDetail(upcomingBooking));
                              }}
                            >
                              <img
                                src={Icon.reschedule}
                                className="reschedule_icon"
                                alt=""
                              ></img>{" "}
                              <span className="reschedule_title">
                                Reschedule
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
                {
                  displayedUpcomingBookings?.length > 10 && (
                    <Pagination
                      totalItems={ByIdUpCommingBookings.length}
                      itemsPerPage={itemsPerPage}
                      onPageChange={handlePageChange}
                    />
                  )
                }

              </>
            ) : (
              <div>
                <div className="col-md-12 d-flex justify-content-center align-items-center doctorequ ">
                  <div className="">
                    <img src={backimg} alt="Image Description" />
                    <p className="text-center">You don't have any upcoming appointments.</p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <h3 className="past_consultation_title">Past Consultations</h3>
            {displayedBookings?.length > 0 ? (
              <>
                {displayedBookings?.map((pastBooking, index) => (
                  <div className="past_card_shadow mb_25">
                    <div className="past_consultation_card_head">
                      {pastBooking?.status === 3 ? (
                        <div className="past_consultation_card_head">
                          <img
                            src={Icon.scheduled_orange}
                            className="past_consultation_card_icon"
                            alt=""
                          ></img>{" "}
                          <span className="past_consultation_card_title_2">
                            Cancelled on{" "}
                            {moment(pastBooking?.cancelation_date).format(
                              "DD-MMM-YYYY | h:mm A"
                            )}
                          </span>
                          <img
                            src={Icon.info_icon}
                            className="info_icon"
                            alt=""
                          ></img>
                        </div>
                      ) : (
                        <div className="row">
                          <div
                            className="col-md-6"
                            onClick={() => {
                              navigate(
                                `/consultation-details/${pastBooking?.id}`
                              );
                              dispatch(BookingDetail(pastBooking));
                            }}
                          >
                            <img
                              src={Icon.scheduled_green}
                              className="past_consultation_card_icon"
                              alt=""
                            ></img>{" "}
                            <span className="past_consultation_card_title">
                              Completed on {pastBooking.appointment_date} at{" "}
                              {pastBooking.appointment_time}
                            </span>
                          </div>
                          <div className="col-md-6">
                            <div className="rating_star_box">
                              <h3 className="rating_star_title">
                                Rate Our Service
                              </h3>
                              <ReactStars
                                count={5}
                                onChange={(reating) =>
                                  ratingChanged(reating, pastBooking.id)
                                }
                                size={24}
                                isHalf={true}
                                emptyIcon={<i className="far fa-star"></i>}
                                halfIcon={
                                  <i className="fa fa-star-half-alt"></i>
                                }
                                fullIcon={<i className="fa fa-star"></i>}
                                activeColor="#ffd700"
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="past_consultation_card_body">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="past_consultation_box">
                            <div>
                              <img
                                src={pastBooking.doctor_details.image}
                                className="my_consultation_doc_img"
                                alt=""
                              ></img>
                            </div>
                            <div>
                              <h5 className="past_consultation_card_body_toptitle">
                                Appointment with
                              </h5>
                              <h3 className="past_consultation_card_body_title">
                                {pastBooking?.doctor_details?.name}
                              </h3>
                              <h6 className="past_consultation_card_body_subtitle">
                                For {pastBooking?.patient_details?.name}
                              </h6>
                              <h6 className="past_consultation_card_body_endtitle">
                                Problem : {pastBooking?.health_problems_name}
                              </h6>
                            </div>
                          </div>
                        </div>
                        {pastBooking?.status === 2 && (
                          <div className="col-md-6">
                            <div>
                              <Button
                                className="prescription_btn"
                                onClick={() => {
                                  setPresciptionDetailModel(true);
                                  dispatch(BookingDetail(pastBooking));
                                }}
                              >
                                View Prescription{" "}
                                <img
                                  src={Icon.prescription}
                                  className="billdownload"
                                  alt=""
                                ></img>
                              </Button>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
                {
                  displayedBookings?.length > 10 && (
                    <Pagination
                      totalItems={ByIdPastBookings.length}
                      itemsPerPage={itemsPerPage}
                      onPageChange={handlePageChange2}
                    />
                  )
                }

              </>
            ) : (
              <div> <div>
                <div className="col-md-12 d-flex justify-content-center align-items-center doctorequ ">
                  <div className="">
                    <img src={backimg} alt="Image Description" />
                    <p className="text-center">You don't have any past appointments.</p>
                  </div>
                </div>
              </div></div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ConsultationList;
