import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SelectedMember } from "../../../Store/Reducer/LabtestSlice";
import { AddNewMembers } from "../../../Store/Reducer/ProfileReducer";
import { Icon } from "../../../Utilities/Icons";
import AddMember from "./AddMember";

const SelecteMemberModel = (props) => {
  const navigate = useNavigate();
  const [newMembers, setNewMembers] = useState(false);
  const dispatch = useDispatch();
  let addMemberinitialState = {
    name: "",
    email: "",
    mobile_number: "",
    age: "",
    height: "",
    weight: "",
    gender: "",
    blood_group: "",
    birthdate: "",
    medical_history: [],
  };

  const { memberList } = useSelector(({ ProfileSlice }) => ProfileSlice);


  return (
    <>
      <AddMember
        addEditMemberinitialState={addMemberinitialState}
        addEditMember={AddNewMembers}
        show={newMembers}
        onHide={() => setNewMembers(false)}
      />

      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="lab_test_modal_box"
        backdrop="static"
      >
        <Modal.Header
          className="lab_test_modal_box_head_padding"
          style={{ marginBottom: "none", border: "none" }}
        >
          <h5 className="lab_test_popup_title">Select Member</h5>
          <img
            src={Icon.cross}
            className="lab_test_popup_close"
            onClick={props.onHide}
          ></img>
        </Modal.Header>
        <Modal.Body>
          <div className="lab_test_modal_box_2">
            {memberList.length &&
              memberList?.map((member, index) => {
                return (
                  <>
                    <div className="lab_member_box" key={index} style={{gap:"20px"}}>
                      <div>
                        {
                          member?.image == null ? <>
                            <img src={Icon.docprofile}></img></> : <> <img src={member?.image} style={{ borderRadius: "50%", height: "50px", width: "50px" }}></img></>
                        }
                      </div>
                      <div className="lab_member_box_inner">
                        <div>
                          <h5 className="consult_members_title">
                            {member.name}{" "}<br/>
                            <span className="consult_members_subtitle">
                              ({member.gender}, Age:{member.age} )
                            </span>
                          </h5>
                        </div>
                        <div>
                          <input
                            type="radio"
                            id={member?.id}
                            name="radio-group"
                            value={member?.id}
                            onChange={(e) => {
                              dispatch(SelectedMember(member));
                              props?.formik?.setFieldValue(
                                "member_id",
                                member?.id
                              );
                            }}
                            style={{ accentColor: "#199a8e"}}
                          />
                        </div>
                      </div>
                    </div>
                    <hr className="consult_member_hr" />
                  </>
                );
              })}
            <div className="add_member_box" onClick={() => setNewMembers(true)}>
              <img
                src={Icon.add}
                className="add_member_icon"
                alt="My Awesome Image"
              ></img>
              <h5 className="add_member_text">Add New Member</h5>
            </div>
            <center>
              <Button
                className="lab_member_modal_btn"
                onClick={() => {
                  if (props?.radiologyCart?.length > 0) {
                    navigate("/radiology-test-cart");
                    props.onHide();
                  } else if (props?.packageId) {
                    navigate("/health-Package-cart");
                  } else {
                    navigate("/labtestcart");
                    props.onHide();
                  }
                }}
              >
                Continue
              </Button>
            </center>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SelecteMemberModel;
