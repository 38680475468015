import React, { useState, useEffect } from "react";
import "../../../Assets/css/responsive.css";
import { useNavigate } from "react-router-dom";
import { Button, Container } from "react-bootstrap";
import { Icon } from "../../../Utilities/Icons";
import {
  GetMember,
  GetUserWallet,
} from "../../../Store/Reducer/ProfileReducer";
import {
  BookConsultant,
  CheckBookingSlot,
  clearFollowUpPassData,
  clearSelectedCouponCode,
  clearSelectedPlan,
  clearSelectedPlanDetails,
  cleaSelectedDate,
  CouponList,
  GetDoctorListById,
  MedicalHistoryList,
  SubscribedPlansList,
  UpcomingBookingList,
  RescheduleDoctorDetails,
  GetSubscriptionPlan,
  clearcoupencode,
} from "../../../Store/Reducer/BookingSlice";
import { useDispatch, useSelector } from "react-redux";
import { Logo } from "../../../Utilities/Icons";
import moment from "moment";
import TabComponent from "../../Common/Tabs";
import { Formik } from "formik";
import * as Yup from "yup";
import BookingSuccessModel from "../../Common/BookingSuccessModel/BookingSuccessModel";
import Doctor from "./Doctor";
import SubPlan from "./Subscription/SubPlan";

function DoctorSidebar() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { session } = useSelector(({ AuthSlice }) => AuthSlice);
  const [bookingSuccessModel, setBookingSuccessModel] = useState(false);
  const { cretaBookConsultant , coupencodeid } = useSelector(
    ({ BookingSlice }) => BookingSlice
  );
  const [rescheduleDoctorDetails, setRescheduleDoctorDetails] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await dispatch(
          RescheduleDoctorDetails({ doctor_id: 769, link_key: "1qoJkdG6V2" })
        );
        setRescheduleDoctorDetails(result); // Set the fetched data in state
      } catch (error) {
  
      }
    };
    fetchData();
  }, [dispatch]);
  const [activeKey, setActiveKey] = useState("profile");
  const [showSubPlan, setShowSubPlan] = useState(false);
  const onDoneClick = () => {
    setActiveKey("my_Consultations");
    setShowSubPlan(true);
  };
  const tabs = [
    {
      title: "1. Doctor",
      key: "profile",
      component: () => <Doctor rescheduleDoctorDetails={rescheduleDoctorDetails}
        onDoneClick={onDoneClick} />,
    },
    {
      title: "2. Select Doctor",
      key: "my_Consultations",
      component: () => (
        <SubPlan
          rescheduleDoctorDetails={rescheduleDoctorDetails}
        />
      ),
    },
  ];

  useEffect(() => {
    let newDate = new Date();
    let request = {
      health_problem_id: localStorage.getItem("selectedHealthId"),
      date: moment(newDate).format("YYYY-MM-DD"),
    };
    dispatch(GetDoctorListById(request));
     dispatch(CouponList({ coupon_type: 1 }));
    dispatch(GetSubscriptionPlan(32));
    dispatch(GetUserWallet());
    dispatch(GetMember());
    dispatch(SubscribedPlansList(32));
    dispatch(MedicalHistoryList());
    return () => { };
  }, [dispatch]);

  const openPayModal = (options) => {
    var rzp1 = new window.Razorpay(options);

    rzp1.open();
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const validationSchema = Yup.object().shape({
    slot_id: Yup.string().required("Required"),
    subscription_plan_id: Yup.string().required("Required"),
    consultation_member_id: Yup.string().required("Required"),
    termsAndConditions: Yup.bool().oneOf(
      [true],
      "You need to accept the terms and conditions"
    ),
  });

  const options = {
    key: process.env.REACT_APP_PAYMENT_KEY,
    key_secret: process.env.REACT_APP_PAYMENT_SECRET_KEY,

    prefill: {
      name: session?.name,
      contact: session?.mobile_number,
      email: session?.email,
    },
    notes: {
      address: "some address",
    },
    theme: {
      color: "#F37254",
      hide_topbar: false,
    },
  };

  const createBooking = (values, resetForm) => {
    if (values?.selectedPlanName) {
      let selectPlan = {
        health_problem_id: localStorage.getItem("selectedHealthId"),
        slot_id: values.slot_id,
        doctor_id: values.doctor_id,
        subscription_plan_id: values?.selectedPlanName?.id,
        consultation_member_id: values?.consultation_member_id,
        total_amount: values.consulting_fee,
        total_paid_amount: values.consulting_fee,
        appointment_date: values?.appointment_date,
        appointment_time: values?.appointment_time,
        payment_type: values?.payment_type,
        plan_type: values.plan_type,
        termsAndConditions: values.termsAndConditions,
        speciality_id: values.speciality_id,
      };
      dispatch(BookConsultant(selectPlan)).then((res) => {
        if (res.payload.status_code === 200 && res.payload.success === true) {
          dispatch(UpcomingBookingList());
          resetForm();
          dispatch(clearSelectedCouponCode());
          dispatch(clearSelectedPlanDetails());
          dispatch(clearSelectedPlan());
          dispatch(clearFollowUpPassData());
          dispatch(cleaSelectedDate());
          setBookingSuccessModel(true);
        }
      });
    } else {
      try {
        let tempOptions = {
          ...options,

          amount: Math.round(values.total_paid_amount) * 100,
          currency: "INR",
          name: "THS-User",
          description: "some description",
          image: Logo.THS_logo,
          handler: function (response) {
            if (response) {
              let updatedValues = {
                ...values,
                transaction_id: response.razorpay_payment_id,
                coupen_code:coupencodeid
              };
              dispatch(BookConsultant(updatedValues)).then((res) => {
                if (
                  res.payload.status_code === 200 &&
                  res.payload.success === true
                ) {
                  dispatch(UpcomingBookingList());
                  resetForm();
                  dispatch(clearSelectedCouponCode());
                  dispatch(clearSelectedPlanDetails());
                  dispatch(clearSelectedPlan());
                  dispatch(clearFollowUpPassData());
                  dispatch(cleaSelectedDate());
                  setBookingSuccessModel(true);
                  dispatch(clearcoupencode())
                }
              });
            }
          },
        };
        dispatch(
          CheckBookingSlot({
            slot_id: values.slot_id,
            date: values.appointment_date,
          })
        ).then((res) => {
          if (res.payload.status_code === 200 && res.payload.success === true) {
            openPayModal(tempOptions);
          }
        });
      } catch (error) {
      }
    }
  };
  return (
    <>
      <BookingSuccessModel
        show={bookingSuccessModel}
        onHide={() => setBookingSuccessModel(false)}
        cretaBookConsultant={cretaBookConsultant}
      />
      <Container fluid className="chat_container">
        <Container>
          <div className="row">
            <div className="col-md-12">
              <Button
                className="back_btn"
                onClick={() => {
                  navigate(-1);
                }}
              >
                <img src={Icon.backarrow} className="back_btn_icon" alt="" />
                <span className="back_btn_text">Back</span>
              </Button>
            </div>
            <div className="col-md-12 p-3">
            </div>
          </div>
          <Formik
            initialValues={{
              health_problem_id: "18",
              speciality_id: "51",
              slot_id: 90438,
              doctor_id: 356,
              subscription_plan_id: 1,
              consultation_member_id: 1813,
              total_amount: 100,
              total_paid_amount: 100,
              appointment_date: "2023-07-07",
              appointment_time: "05:00 AM",
              payment_type: 1,
              plan_type: "new",
              selectedPlanName: "",
              consulting_fee: "",
              termsAndConditions: false,
              follow_up_booking_id: "",
            }}
            validationSchema={validationSchema}
            onSubmit={(values, { resetForm }) => {
              createBooking(values, resetForm);
            }}
          >
            {({ handleSubmit, values }) => (
              <form onSubmit={handleSubmit}>
                <TabComponent tabing={true}  tabs={tabs} active={activeKey} />
              </form>
            )}
          </Formik>
        </Container>
      </Container>
    </>
  );
}

export default DoctorSidebar;
