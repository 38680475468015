import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Icon } from "../../../Utilities/Icons";
import {
  clearSelectedCouponCode,
  coupencode,
  coupenListall,
  CouponCodeDetail,
  CouponList,
  MedicineCouponList,
} from "../../../Store/Reducer/BookingSlice";
import { SESSION } from "../../../Utilities/Enums";
import { BASE_URL } from "../../../Utilities/HTTP";
import { useDispatch, useSelector } from "react-redux";
import backimg from '../../../Assets/img/png/Frame 34279.png'
import { useEffect } from "react";
import { useLocation } from 'react-router-dom';
import axios from "axios";
const ApplyCouponModel2 = (props) => {
  const [inputText, setInputText] = useState("");
  const [data, SetData] = useState(props.couponList);
  const dispatch = useDispatch();
  const location = useLocation();
  const currentPath = location.pathname;
  const SessionData = JSON.parse(localStorage.getItem(SESSION));
  const inputHandler = async (e) => {
    const lowerCase = e.target.value.toLowerCase();
    setInputText(lowerCase);
    if (currentPath === '/buymedicinecart') {
      dispatch(MedicineCouponList({ coupon_type: 5, search: lowerCase }));
    } else {
      try {
        const response = await axios.post(
          `${BASE_URL}/user/coupon-list`,
          { coupon_type: props?.couponType, search: lowerCase },
          {
            headers: {
              "Content-Type": 'application/json',
              Authorization: `Bearer ${SessionData?.token}`,
            },
          }
        );
        const responseData = response.data; 
        SetData(responseData?.data)
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  const filteredData = Array.isArray(data)
    ? data.filter((el) => {
      if (inputText === "") {
        return el;
      }
      else {
        return el?.coupon_code?.toLowerCase().includes(inputText);
      }
    })
    : [];

  const { couponCodeDetail } = useSelector(({ BookingSlice }) => BookingSlice);

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="modal_476"
      backdrop="static"
    >
      <Modal.Header
        className="lab_sample_apply_coupon_box_header"
        style={{ marginBottom: "none", border: "none" }}
      >
        <div>
          <h5 className="select_subscription_plan">Apply Coupon</h5>
        </div>
        <img
          src={Icon.cross}
          className="book_modal_close"
          onClick={props.onHide}
          alt="My Awesome Image"
        ></img>
      </Modal.Header>
      <Modal.Body>
        <div className="lab_sample_apply_coupon_box">
          <div className="row">
            <div className="col-md-12">
              <input
                type="text"
                className="lab_sample_apply_coupon_input"
                placeholder="Enter Coupon Code"
                onChange={(e) => { inputHandler(e) }}
              />
              <span className="lab_sample_apply_coupon_apply">Apply</span>
            </div>
          </div>

          <div className="lab_sample_apply_coupon_list_box">
            <div style={{ height: "500px", overflow: "auto" }}>
              {
                filteredData == [] ? <>
                  <div className="lab_sample_apply_coupon_list">
                    <div className="col-md-12 d-flex justify-content-center align-items-center doctorequ ">
                      <div className="">
                        <img src={backimg} alt="Image Description" />
                      </div>
                    </div>
                  </div>

                </> : <>
                  {filteredData && filteredData?.length > 0 &&
                    filteredData && filteredData?.map((cupon, index) => (
                      <>
                        <div className="lab_sample_apply_coupon_list" key={index}>
                          <div>
                            <h3 className="lab_sample_apply_coupon_list_title">
                              {cupon.coupon_title}
                            </h3>
                            <h5 className="lab_sample_apply_coupon_list_subtitle">
                              {cupon.coupon_description}
                            </h5>
                          </div>
                          <div>
                            {couponCodeDetail?.id == cupon.id ? (
                              <h5
                                className="lab_sample_apply_coupon_list_title_apply"
                                onClick={() => { dispatch(clearSelectedCouponCode()) }}
                              >
                                Applied
                              </h5>
                            ) : (
                              <h5
                                onClick={() => {
                                  dispatch(CouponCodeDetail(cupon));
                                  props?.getDiscount(cupon);
                                  dispatch(coupencode(cupon?.id))
                                }}
                                className="lab_sample_apply_coupon_list_title_apply"
                              >
                                Apply
                              </h5>
                            )}
                          </div>
                        </div>
                        <hr className="lab_sample_apply_coupon_list_hr" />
                      </>
                    ))}</>
              }

            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ApplyCouponModel2;
