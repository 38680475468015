import React, { useState, useEffect } from "react";
import "../../../Assets/css/responsive.css";
import { useNavigate, useParams } from "react-router-dom";
import SelectDoctor from "./SelectDoctor";
import SubscriptionPlan from "../subscription_plan/Subscription_plan";
import Payment from "../payment/Payment";
import { Button, Container } from "react-bootstrap";
import { Icon } from "../../../Utilities/Icons";
import {
    ConsultionCard,
    GetMember,
    GetUserWallet,
} from "../../../Store/Reducer/ProfileReducer";
import {
    BookConsultant,
    CardHome,
    CheckBookingSlot,
    clearcoupencode,
    clearFollowUpPassData,
    clearSelectedCouponCode,
    clearSelectedPlan,
    clearSelectedPlanDetails,
    cleaSelectedDate,
    CouponList,
    GetDoctorListById,
    GetSubscriptionPlan,
    MedicalHistoryList,
    ShareMyLinkStore,
    SubscribedPlansList,
    UpcomingBookingList,
} from "../../../Store/Reducer/BookingSlice";
import { useDispatch, useSelector } from "react-redux";
import { Logo } from "../../../Utilities/Icons";
import moment from "moment";
import TabComponent from "../../Common/Tabs";
import { Formik } from "formik";
import * as Yup from "yup";
import BookingSuccessModel from "../../Common/BookingSuccessModel/BookingSuccessModel";
import BotIndex from "./ChatBot/BotIndex";
import { GoldMemberBokingstatus, walletPrice } from "../../../Store/Reducer/RadiologySlice";
import { setActiveKey } from "../../../Store/Reducer/CommonSlice";

function ShareMyLink() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { uid, doctorId } = useParams();
    const { session } = useSelector(({ AuthSlice }) => AuthSlice);
    const [bookingSuccessModel, setBookingSuccessModel] = useState(false);
    const { cretaBookConsultant, coupencodeid } = useSelector(
        ({ BookingSlice }) => BookingSlice
    );
    const [cardHome, SetcardHome] = useState("");
    const [specialityId, setSpecialityId] = useState("");
    const [doctorDetails, setDoctorDetails] = useState("")
    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await dispatch(CardHome());
                console.log("result?.payload", result?.payload);
                SetcardHome(result?.payload)
            } catch (error) { }
        };
        fetchData();
    }, [dispatch]);

    // shareMyLink api calling

    useEffect(() => {
        dispatch(setActiveKey("select_doctor"))
        const getShareMyLink = async () => {
            try {
                const values = {
                    doctor_id: doctorId,
                    link_key: uid
                }
                const result = await dispatch(ShareMyLinkStore(values));
                setSpecialityId(result?.payload?.speciality_id);
                setDoctorDetails(result?.payload);
            } catch (error) { }
        }
        getShareMyLink();
    }, [dispatch])

    const {
        cards
    } = useSelector(({ BookingSlice }) => BookingSlice);
    useEffect(() => {
        dispatch(CardHome())
        dispatch(GoldMemberBokingstatus(cards?.gold_membership?.id));
    }, [dispatch, cards?.gold_membership?.id])


    console.log("doctorDetails", doctorDetails, cardHome);
    const tabs = [
        {
            title: "1. Chat",
            key: "chat",
            component: () => <BotIndex />,
        },
        {
            title: "2. Select Doctor",
            key: "select_doctor",
            component: () => <SelectDoctor doctorData={doctorDetails} />,
        },
        {
            title: "3. Subscription Plan",
            key: "subscription_plan",
            component: () => <SubscriptionPlan cardHome={cardHome} doctorData={doctorDetails} />,
        },
        {
            title: "4. Payment",
            key: "payment",
            disabled: true,
            component: () => <Payment />,
        },
    ];


    useEffect(() => {
        let newDate = new Date();
        let request = {
            speciality_id: specialityId,
            date: moment(newDate).format("YYYY-MM-DD"),
        };
        dispatch(GetDoctorListById(request));
        dispatch(CouponList({ coupon_type: 1 }));
        dispatch(GetSubscriptionPlan(specialityId));
        dispatch(GetUserWallet());
        dispatch(GetMember());
        dispatch(SubscribedPlansList(specialityId));
        dispatch(MedicalHistoryList());
        return () => { };
    }, [dispatch, specialityId]);

    useEffect(() => {
        dispatch(GetUserWallet());
    }, [dispatch])
    const openPayModal = (options) => {
        var rzp1 = new window.Razorpay(options);

        rzp1.open();
    };
    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://checkout.razorpay.com/v1/checkout.js";
        script.async = true;
        document.body.appendChild(script);
    }, []);
    const { userWallet, memberList } = useSelector(
        ({ ProfileSlice }) => ProfileSlice
    );
    const [walletbox, setwalletbox] = React.useState(userWallet);
    console.log("walletbox", walletbox, userWallet);
    useEffect(() => {
        dispatch(walletPrice(`{ "wallet_prices": ${walletbox?.wallet}}`));
    }, [dispatch])

    const validationSchema = Yup.object().shape({
        // slot_id: Yup.string().required("Required"),
        // subscription_plan_id: Yup.string().required("Required"),
        consultation_member_id: Yup.string().required("Required"),
        termsAndConditions: Yup.bool().oneOf(
            [true],
            "Before you book your consultation,make sure you are aware about THS terms and conditions."
        ),
    });
    const options = {
        key: process.env.REACT_APP_PAYMENT_KEY,
        key_secret: process.env.REACT_APP_PAYMENT_SECRET_KEY,

        prefill: {
            name: session?.name,
            contact: session?.mobile_number,
            email: session?.email,
        },
        notes: {
            address: "some address",
        },
        theme: {
            color: "#F37254",
            hide_topbar: false,
        },
    };
    const { goldMembershipdetail } = useSelector(
        ({ LabtestSlice }) => LabtestSlice
    );
    const { goldMembershipStatus, goldMembershipStatus1, wallet_price, wallet_cutPrice } = useSelector(
        ({ RadiologySlice }) => RadiologySlice
    );
    const jsonString = wallet_price;
    let jsonObject;

    try {
        jsonObject = JSON.parse(jsonString);
    } catch (error) {

    }

    const cut_amount = jsonObject?.cut_amount;

    const createBooking = (values, resetForm) => {
        if (doctorDetails) {
            let type;
            if (cut_amount != undefined) {
                type = 0
            } else {
                type = 1
            }
            ;
            let tempOptions = {
                ...options,
                amount: Math.round(doctorDetails?.fee_details?.consulting_fee) * 100,
                currency: "INR",
                name: "THS-User",
                description: "some description",
                image: Logo.THS_logo,
                handler: function (response) {
                    if (response) {
                        let updatedValues = {
                            ...values,
                            payment_id: response.razorpay_payment_id,
                            wallet_amount: cut_amount,
                            total_amount: Math.round(doctorDetails?.fee_details?.consulting_fee) * 100,
                            total_paid_amount: Math.round(doctorDetails?.fee_details?.consulting_fee) * 100,
                            payment_type: type,
                            coupen_code: coupencodeid
                        };
                        dispatch(BookConsultant(updatedValues)).then((res) => {
                            if (
                                res.payload.status_code == 200 &&
                                res.payload.success == true
                            ) {
                                dispatch(UpcomingBookingList());
                                resetForm();
                                dispatch(clearSelectedCouponCode());
                                dispatch(clearSelectedPlanDetails());
                                dispatch(clearSelectedPlan());
                                dispatch(clearFollowUpPassData());
                                dispatch(cleaSelectedDate());
                                setBookingSuccessModel(true);
                                dispatch(clearcoupencode())
                            }
                        });
                    }
                },
            };
            dispatch(
                CheckBookingSlot({
                    slot_id: values.slot_id,
                    date: values.appointment_date,
                })
            ).then((res) => {
                if (res.payload.status_code == 200 && res.payload.success == true) {
                    openPayModal(tempOptions);
                }
            });

        }
    };


    return (
        <>
            <BookingSuccessModel
                show={bookingSuccessModel}
                onHide={() => setBookingSuccessModel(false)}
                cretaBookConsultant={cretaBookConsultant}
                cut_amount={cut_amount}
            />
            <Container fluid className="chat_container">
                <Container>
                    <div className="row">
                        <div className="col-md-12">
                            <Button
                                className="back_btn"
                                onClick={() => {
                                    navigate(-1);
                                }}
                            >
                                <img
                                    src={Icon.backarrow}
                                    className="back_btn_icon"
                                    alt=""
                                ></img>{" "}
                                <span className="back_btn_text">Back</span>
                            </Button>
                        </div>
                        <Formik
                            initialValues={{
                                health_problem_ids: localStorage.getItem("selectedHealthId"),
                                speciality_id: localStorage.getItem("specialityId"),
                                slot_id: "",
                                doctor_id: "",
                                subscription_plan_id: "",
                                consultation_member_id: "",
                                // total_amount: "",
                                // total_paid_amount: "",
                                appointment_date: "",
                                appointment_time: "",
                                payment_type: "",
                                plan_type: "",
                                selectedPlanName: "",
                                consulting_fee: "",
                                termsAndConditions: false,
                                follow_up_booking_id: "",
                                gold_discount: "",
                                coupen_code: coupencodeid

                            }}
                            validationSchema={validationSchema}
                            onSubmit={(values, { resetForm }) => {
                                // do your stuff

                                createBooking(values, resetForm);
                            }}
                        >
                            {({ handleSubmit, values }) => (
                                <form onSubmit={handleSubmit}>
                                    <TabComponent tabing={true} tabs={tabs} active="chat" />
                                </form>
                            )}
                        </Formik>
                    </div>
                </Container>
            </Container>
        </>
    );
}
export default ShareMyLink;
