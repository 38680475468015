import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container } from 'react-bootstrap'
import { BackGround, Icon, Logo } from '../../../Utilities/Icons'
import { useDispatch, useSelector } from "react-redux";
import { PackageList } from "../../../Store/Reducer/PackageSlice";
import PackageDetailModal from "../HealthPackage/PackageDetailModal";
import PathologySuceessModal from "../../Common/LabTestModel/PathologySuceessModal";
import LabCityModal from "../../Common/LabTestModel/LabCityModal";
import PathologyLab from "../../Common/LabTestModel/PathologyLab";
import { HealthPackageBookingDetails, LabTestCenters, LabtestCities, LabtestLabs, setLabTestCenterDetailsById } from "../../../Store/Reducer/LabtestSlice";
import { GetMember, clickCardHome } from "../../../Store/Reducer/ProfileReducer";
import LabTestModel from "../../Common/LabTestModel/LabTestModel";
import { BookConsultant, GetSubscriptionHomePlan, GetSubscriptionPlan, UpcomingBookingList, cleaSelectedDate, clearFollowUpPassData, clearSelectedCouponCode, clearSelectedPlan, clearSelectedPlanDetails } from "../../../Store/Reducer/BookingSlice";
import BookingSuccessModel from "../../Common/BookingSuccessModel/BookingSuccessModel";
import { HealthCareBookingCreate, SpecialityBoking } from "../../../Store/Reducer/RadiologySlice";
import { GetMemberList } from "../../../Routes/Service";
import HealthBookingSucessModal from "../../Common/Healthpackagemodal/HealthBookingSucessModal";

import img1 from '../../../../src/Assets/img/png/Group 34669.png';
import img2 from '../../../../src/Assets/img/png/Group 34657.png';
import img3 from '../../../../src/Assets/img/png/Group 34658.png';
import img4 from '../../../../src/Assets/img/png/Group 34670.png';
import PaymentSummery from "./PaymentSummery";
const HomeHealth = (props) => {
    const dispatch = useDispatch();
    const [PackageDetail, SetPAckageDetail] = useState(false);
    const [packageId, SetPackageId] = useState();
    const navigate = useNavigate();
    useEffect(() => {
        dispatch(PackageList(2));
    }, [dispatch]);
    const { packageList } = useSelector(({ PackageSlice }) => PackageSlice);
    const [radiomodal, setRadioshow] = useState(false);
    const [radiomodal1, setRadioshow1] = useState(false);
    const [packagedetail, setPackageDetails] = useState(false);
    const { labTestList } = useSelector(({ LabtestSlice }) => LabtestSlice);
    const [labtestmodel, setLabtestmodel] = useState(false);
    const [selectedCity, setSelectedCity] = useState("");
    const [labcentermodal, setLabcentermodal] = useState(false);
    const [labcitymodal, setLabcitymodal] = useState(false);
    const [labdetailmodal, setLabdetailmodal] = useState(false);
    const [selectMember, setSelectMember] = useState(false);
    const [cityModal, setCityModal] = useState(false);
    const [radiologyCenterDetailModel, setRadiologyCenterDetailModel] =
        useState(false);
    const [healthPackageDetailModel, setHealthPackageDetailModel] =
        useState(false);
    const { radiologyData, radiologyCart, radiologyTestCenterList, specialitybooking } = useSelector(
        ({ RadiologySlice }) => RadiologySlice
    );

    const { labtestStates, labtestCities } = useSelector(
        ({ LabtestSlice }) => LabtestSlice
    );

    const cityJSON = localStorage.getItem("USER");
    const cityObject = JSON.parse(cityJSON);
    useEffect(() => {
        dispatch(LabtestCities(cityObject?.city_details?.state_id))
    }, [dispatch]);
    useEffect(() => {
        setSelectedCity(labtestCities?.length > 0 && labtestCities[0])
    }, [dispatch, labtestCities])
    const { session } = useSelector(({ AuthSlice }) => AuthSlice);
    useEffect(() => {
        dispatch(LabtestLabs({center_id : labTestCenterDetailsById && labTestCenterDetailsById?.id,
            city_id :labTestCenterDetailsById && labTestCenterDetailsById?.city_id
          }));
        dispatch(GetMember());
    }, [dispatch]);




    useEffect(() => {
        dispatch(PackageList(2));

    }, [dispatch]);



    //lablist


    const { labTestCenterList, labTestCenterDetailsById } =
        useSelector(({ LabtestSlice }) => LabtestSlice);
    const data = localStorage.getItem("healthcare")
    const { subScriptionHomePlanList } = useSelector(
        ({ BookingSlice }) => BookingSlice
    );
    const { memberList } = useSelector(
        ({ ProfileSlice }) => ProfileSlice
    );
    useEffect(() => {
        let request = {
            city_id: cityObject?.city_id,
        };
        const id = localStorage.getItem("specialityId");

        dispatch(LabTestCenters(request));
        dispatch(GetSubscriptionHomePlan());
        GetMemberList();
    }, [dispatch, cityObject?.city_id]);



    const [gender, setGender] = useState();

    function onChangeValue(event) {
        setGender(event.target.value);
    }
    function handlePackageItemClick(packageItem) {
        // Store the packageItem array in local storage as a JSON string
        localStorage.setItem("packageItemList", JSON.stringify(packageItem));
    }

    useEffect(() => {
        if (gender) {
            labTestCenterList?.filter(function (item) {
                if (gender == item?.id) {
                    dispatch(setLabTestCenterDetailsById(item));
                    props?.formik?.setFieldValue("center_id", item?.id);
                }
            });
        } else {
            setGender(labTestCenterDetailsById.id);
        }
    }, [gender]);
    const colors = ['#7164ce', '#56938d', '#70a8eb'];
    const cardBackgroundImages = [img2, img3];
    const [bookingSuccessModel, setBookingSuccessModel] = useState(false);
    const { cretaBookConsultant } = useSelector(
        ({ BookingSlice }) => BookingSlice
    );
    useEffect(()=>{
        dispatch(clickCardHome({ type: "5" }));
    },[])
    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://checkout.razorpay.com/v1/checkout.js";
        script.async = true;
        document.body.appendChild(script);
    }, []);
    const options = {
        key: process.env.REACT_APP_PAYMENT_KEY,
        key_secret: process.env.REACT_APP_PAYMENT_SECRET_KEY,
        amount: "100", //cretaBookConsultant?.data?.total_price, //  = INR 1
        name: "THS-User",
        description: "some description",
        image: Logo.THS_logo,
        handler: function (response) {
            if (response) {
                setBookingSuccessModel(true);
            }
        },
        prefill: {
            name: session?.name,
            contact: session?.mobile_number,
            email: session?.email,
        },
        notes: {
            address: "some address",
        },
        theme: {
            color: "#F37254",
            hide_topbar: false,
        },
    };

    const openPayModal = (options) => {
        var rzp1 = new window.Razorpay(options);

        rzp1.open();
    };


    const createBooking = (subscriptionPlan) => {
        try {
            let tempOptions = {
                ...options,
                amount: Math.round(subscriptionPlan?.discounted_price) * 100,
                plan_id: subscriptionPlan?.id,
                currency: "INR",
                name: "THS-User",
                description: "some description",
                image: Logo.THS_logo,
                handler: async function (response) {
                    let updatedValues = {
                        plan_id: subscriptionPlan?.id,
                        transaction_id: response.razorpay_payment_id,
                    };
                    dispatch(SpecialityBoking(updatedValues)).then((res) => {

                        setBookingSuccessModel(true);
                    });
                },
            };
            openPayModal(tempOptions);
        } catch (error) { }
    };
    const [payment, setPayment] = useState(false);
    const [payrelateddata , setPayRelateddata] = useState("")
    return (
        <>
            <PaymentSummery
                show={payment}
                onNext={() => {
                    setPayment(false);
                }}
                payrelateddata={payrelateddata}
                onHide={() => setPayment(false)}
            />
            <HealthBookingSucessModal
                show={bookingSuccessModel}
                onHide={() => setBookingSuccessModel(false)}
                specialitybooking={specialitybooking}
            />
            <LabTestModel
                show={labtestmodel}
                onNext={() => {
                    setLabdetailmodal(true);
                    setLabtestmodel(false);
                }}
                onHide={(e) => {
                    setLabtestmodel(false);
                }}
            />
            <PackageDetailModal
                show={PackageDetail}
                onHide={(e) => {
                    SetPAckageDetail(false);
                }}
                packageId={packageId}
                onNext={() => {
                    setLabcentermodal(true);
                }}
            />
            <PathologyLab
                show={labcentermodal}
                onNext={() => {
                    // setLabdetailmodal(true);
                    // setLabtestmodel(true);
                    navigate("/helathCart");
                    setLabcentermodal(false);
                }}
                onNextCityModal={() => {
                    setCityModal(true)
                    setLabcentermodal(false)
                    setLabcitymodal(true)
                }}
                selectedCitys={selectedCity}
                onHide={(e) => {
                    setLabcentermodal(false);
                }}
            />
            {cityModal && <LabCityModal
                show={labcitymodal}
                onNextCityModal={() => {
                    // setLabdetailmodal(true);
                    // setLabcitymodal(true);
                    setLabcentermodal(false);
                }}
                onNext={(selectedCity) => {
                    setLabcentermodal(true);
                    setLabcitymodal(false);
                    setSelectedCity(selectedCity)
                    // setLabdetailmodal(true);
                }}
                onHide={(e) => {
                    setLabcitymodal(false);
                }}
            />}
            <PathologySuceessModal
                show={labdetailmodal}
                onNext={() => {
                    navigate("/helathCart");

                    // window.location = '/patient/labtestcart'
                    setLabdetailmodal(false);
                }}
                onHide={(e) => {
                    setLabdetailmodal(false);
                }}
            />
            <div>
                <Container>
                
                    <h3 className="follow_up_consultation_title pt_30">Health Packages</h3>
                    <div className="packageList row " style={{ flexWrap: "wrap", justifyItems: "center" }}>
                        {packageList.map((packageItem, index) => (
                            <div key={index}  className=" col-xs-6  col-sm-6 col-lg-3 col-xs-6 col-md-4" style={{ marginBottom: '10px' }}   onClick={() => handlePackageItemClick(packageItem)} >
                                <div className="shadowclass1" onClick={() => { SetPAckageDetail(true); SetPackageId(packageItem) }}>
                                    <div className="shadowclassimg1" >
                                        <img src={packageItem?.image} className="shadowclassimg1" alt="Package" />
                                    </div>
                                    <div className="pt_10" style={{height:"130px"}}>
                                        <h3 className="subtext1">{packageItem?.package_title}</h3>
                                        <p className="subTect23 " style={{ color: "#303236", paddingTop: "5px" }}>{packageItem?.package_description}</p>
                                        <p className="subtext " style={{marginTop:"0px"}}>₹{packageItem?.discount_amount}</p>

                                    </div>
                                </div>
                            </div>  
                        ))}
                    </div>


                </Container>
            </div>
            <div>
                <Container>
                
                          <h3 className="follow_up_consultation_title pt_30">Subscription Plans</h3>
                  

                    <div className="subscription-plans-container">
                        <>
                            {
                                subScriptionHomePlanList.length > 0 ? 
                                    <>
                                        <div className="subscription-plans-container" style={{ display: 'flex', flexWrap: 'wrap' }}>
                                            {subScriptionHomePlanList && subScriptionHomePlanList?.map((subscriptionPlan, index) => (
                                                <>
                                                    <div
                                                        className="subscription_plan_card mb_10 "
                                                        key={index}

                                                        style={{
                                                            backgroundImage: `url('${cardBackgroundImages[index % cardBackgroundImages.length]}')`,
                                                            backgroundSize: '200px 154px ',
                                                            backgroundRepeat: 'no-repeat',
                                                        }}
                                                        onClick={() => {
                                                            if (subscriptionPlan?.discounted_price == 0) {
                                                                dispatch(SpecialityBoking({ plan_id: subscriptionPlan?.id }));
                                                                setBookingSuccessModel(true);
                                                            } else {
                                                                // createBooking(subscriptionPlan)
                                                                setPayment(true);
                                                                setPayRelateddata(subscriptionPlan)
                                                            }
                                                        }}

                                                    >
                                                        <div className="price" style={{ padding: "14px" }}>
                                                            <div>
                                                                <h5 className="subscription_plan_price">
                                                                    {subscriptionPlan.discounted_price == 0 ? "Free" : `₹ ${subscriptionPlan.discounted_price}`}
                                                                    {/* ₹{subscriptionPlan.discounted_price} */}
                                                                </h5>
                                                                <h5
                                                                    className="subscription_plan_allprice"
                                                                    style={{ marginBottom: "0px" }}
                                                                >
                                                                    {subscriptionPlan.price == 0 ? "" : `₹ ${subscriptionPlan.price}`}
                                                                </h5>
                                                            </div>
                                                        </div>
                                                        <div className="subscription_plan_box pt_10" style={{ padding: "14px" }}>
                                                            <h3 className="subscription_plan_title">
                                                                {subscriptionPlan?.title}
                                                            </h3>
                                                        </div>
                                                    </div>
                                                </>
                                            ))}
                                        </div>
                                    </> : <>
                                    <p>We are working on adding new subscription plans soon. Please stay tuned.</p>
                                    </>
                                }

                        </>
                    </div>
                </Container>
            </div>
        </>
    )
}

export default HomeHealth
