import React, { useEffect, useState } from "react";
import { Container, Tab } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { setActiveKey } from "../../Store/Reducer/CommonSlice";
import { Icon } from "../../Utilities/Icons";

function TabComponent({ tabs, active, tabing, showTab, setShow }) {
  const dispatch = useDispatch();
  const [isMobile, setIsMobile] = useState(false);
  const { activeKey } = useSelector(({ CommonSlice }) => CommonSlice);
  useEffect(() => {
    dispatch(setActiveKey(active));
    return () => { };
  }, [active]);
  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth <= 767);
    }
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  return (
    <>
      <Container fluid className="subscription_container">
        <Tab.Container activeKey={activeKey}>
          <div className="row ">
            <div className={`col-md-3  ${active === "chat" ? "consultation_hide" : ""}`}>

              {isMobile ?
                <>
                  {
                    showTab == true && <div className="chat_card_box">
                      <ul className="chat_ul">
                        {tabs?.map((item, index) => (
                          <li
                            key={item?.key + index}
                            className={`chat_tab_option_bg sidebar-link-pointer ${activeKey === item?.key && "active"
                              }`}
                            onClick={(e) => {
                              e.preventDefault();
                              setShow();
                              if (tabing) {
                               
                              } else {
                                dispatch(setActiveKey(item?.key));
                              }
                            }}
                          >
                            <span
                              className={`chat_tab_option_text ${activeKey === item?.key && "active"
                                }`}
                            >
                              {item.icon ? (
                                <span className="my_consultation_icons">
                                  <img src={item.icon} alt="SVG as an image" />
                                </span>
                              ) : null}
                              {item?.title}
                            </span>
                            <span>
                              {activeKey === item?.key ? (
                                <img src={Icon.CiverRightGreen} alt="My Awesome Image" />
                              ) : (
                                <img src={Icon.CiverRight} alt="My Awesome Image" />
                              )}
                            </span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  }
                </>
                :
                <>
                  <div className="chat_card_box">
                    <ul className="chat_ul">
                      {tabs?.map((item, index) => (
                        <li
                          key={item?.key + index}
                          className={`chat_tab_option_bg sidebar-link-pointer ${activeKey === item?.key && "active"
                            }`}
                          onClick={(e) => {
                            e.preventDefault();
                            if (tabing) {
                          
                            } else {
                              dispatch(setActiveKey(item?.key));
                            }
                          }}
                        >
                          <span
                            className={`chat_tab_option_text ${activeKey === item?.key && "active"
                              }`}
                          >
                            {item.icon ? (
                              <span className="my_consultation_icons">
                                <img src={item.icon} alt="SVG as an image" />
                              </span>
                            ) : null}
                            {item?.title}
                          </span>
                          <span>
                            {activeKey === item?.key ? (
                              <img src={Icon.CiverRightGreen} alt="My Awesome Image" />
                            ) : (
                              <img src={Icon.CiverRight} alt="My Awesome Image" />
                            )}
                          </span>
                        </li>
                      ))}
                    </ul>
                  </div>

                </>
              }


            </div>
            <div className="col-md-9">
              <Tab.Content>
                {tabs?.map(
                  (Item, index) =>
                    activeKey === Item?.key && (
                      <Tab.Pane
                        key={index}
                        eventKey={Item?.key}
                      // onClick={handleSelect(Item?.key)}
                      >
                        <Item.component key={index} />
                      </Tab.Pane>
                    )
                )}
              </Tab.Content>
            </div>
          </div>
        </Tab.Container>
      </Container>
    </>
  );
}
export default TabComponent;
