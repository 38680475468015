import React, { useEffect, useState } from "react";
import { Icon } from "../../../Utilities/Icons.js";
import { Button } from "react-bootstrap";
import "../../../Assets/css/responsive.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SelectBookingDateTime from "../../Common/SelectBookingDateTime/SelectBookingDateTime.js";

import moment from "moment";
import { Formik, useFormikContext } from "formik";
import {
  DoctorId,
  GetDoctorListById,
  SelectedDate,
} from "../../../Store/Reducer/BookingSlice.js";
import Pagination from "../../Common/Pagination/Pagination.js";
import backimg from '../../../Assets/img/png/Group 34672.png'
import { ConsultionCard } from "../../../Store/Reducer/ProfileReducer.js";

function ChatScreen1(props) {
  console.log("propssssss", props);
  const [modalShow, setModalShow] = useState(false);
  const [modalOpenCon, setModalOpenCon] = useState(props.doctorData);
  const [bookingDateTime, setBookingDateTime] = useState();
  const [allDetail, setAllDetails] = useState(false);
  const { doctors, userSelectedDate, specialityList } = useSelector(
    ({ BookingSlice }) => BookingSlice
  );

  // useEffect(() => {
  //   setModalOpenCon(props.doctorData)
  // }, [])


  // const { setFieldValue } = useFormikContext();
  const formikProps = useFormikContext();
  const dispatch = useDispatch();
  const current = new Date();
  const date = moment(current).add(0, "days").format("YYYY-MM-DD");

  const tomorrow = moment(current).add(1, "days").format("YYYY-MM-DD");
  const nexttomorrow = moment(current).add(2, "days").format("YYYY-MM-DD");
  const nexttomorrow2 = moment(current).add(3, "days").format("YYYY-MM-DD");
  const nexttomorrow3 = moment(current).add(4, "days").format("YYYY-MM-DD");
  const onDateByDoctor = (date) => {
    let request = {
      // health_problem_id: localStorage.getItem("selectedHealthId"),
      speciality_id: localStorage.getItem("specialityId"),
      date: moment(date).format("YYYY-MM-DD"),
    };
    dispatch(SelectedDate(date));
    dispatch(GetDoctorListById(request));
  };

  var specialityID = specialityList?.filter(function (item) {
    if (item?.id == localStorage?.getItem("specialityId")) {
      {
        return item?.speciality;
      }
    }
  });
  const [doctorid, setdoctorid] = useState();



  const [currentPage, setCurrentPage] = useState(0); // Current page index
  const itemsPerPage = 5; // Number of bookings to display per page

  const handlePageChange = (selected) => {
    setCurrentPage(selected); // Update the current page index
  };

  const doctor = doctors.hasError
    ? []
    : doctors?.slice(
      currentPage * itemsPerPage,
      (currentPage + 1) * itemsPerPage
    );

  console.log("doctordoctordoctor", doctors);

  useEffect(() => {

    if (modalOpenCon?.id && doctors.length > 0) {
      console.log("modalOpenCon.id", modalOpenCon.id);
      const recordWithId2 = doctors?.find(item => item.id == modalOpenCon.id);
      console.log("recordWithId2recordWithId2", recordWithId2);
      setModalOpenCon(props.doctorData)
      setModalShow(true);
      formikProps.setFieldValue("doctor_id", recordWithId2?.id);
      formikProps.setFieldValue(
        "consulting_fee",
        recordWithId2?.consulting_fee
      );
      setdoctorid(recordWithId2?.id)
      setBookingDateTime(recordWithId2);
      setAllDetails(true)
    }
  }, [dispatch])

  return (
    <>
      {allDetail == true && <Formik
      // initialValues={{ email: "", password: "" }}
      >
        {() => (
          <SelectBookingDateTime
            show={modalShow}
            formikProps={formikProps}
            bookingDateTime={bookingDateTime}
            onHide={() => setModalShow(false)}
            setdoctorid={doctorid}
            doctorDetail={modalOpenCon}
          />
        )}
      </Formik>}

      <Formik
      // initialValues={{ email: "", password: "" }}
      >
        {() => (
          <SelectBookingDateTime
            show={modalShow}
            formikProps={formikProps}
            bookingDateTime={bookingDateTime}
            onHide={() => setModalShow(false)}
            setdoctorid={doctorid}
          />
        )}
      </Formik>
      <div className="chat_body_box_card_head">
        <div className="book_date_cun_card_box  doctor_date_card_box">
          <div
            className={`  ${userSelectedDate === date ? "doctor_date_card_box_active " : null
              } `}
            onClick={() => {
              onDateByDoctor(date);
              dispatch(SelectedDate(date));
            }}
          >
            <h5 className="book_date_card_text">Today</h5>
          </div>
          <div
            className={`  ${userSelectedDate === tomorrow
              ? "doctor_date_card_box_active "
              : null
              } `}
            onClick={() => {
              onDateByDoctor(tomorrow);
              dispatch(SelectedDate(tomorrow));
            }}
          >
            <h5 className="book_date_card_text">Tomorrow</h5>
          </div>
          <div
            className={`  ${userSelectedDate === nexttomorrow
              ? "doctor_date_card_box_active"
              : null
              } `}
            onClick={() => {
              onDateByDoctor(nexttomorrow);
              dispatch(SelectedDate(nexttomorrow));
            }}
          >
            <h5 className="book_date_card_text">
              {" "}
              {moment(nexttomorrow).format("DD-MM-YYYY")}
            </h5>
          </div>
          <div
            className={`  ${userSelectedDate === nexttomorrow2
              ? "doctor_date_card_box_active "
              : null
              } `}
            onClick={() => {
              onDateByDoctor(nexttomorrow2);
              dispatch(SelectedDate(nexttomorrow2));
            }}
          >
            <h5 className="book_date_card_text">
              {moment(nexttomorrow2).format("DD-MM-YYYY")}
            </h5>
          </div>
          <div
            className={`  ${userSelectedDate === nexttomorrow3
              ? "doctor_date_card_box_active "
              : null
              } `}
            onClick={() => {
              onDateByDoctor(nexttomorrow3);
              dispatch(SelectedDate(nexttomorrow3));
            }}
          >
            <h5 className="book_date_card_text">
              {moment(nexttomorrow3).format("DD-MM-YYYY")}
            </h5>
          </div>
        </div>
      </div>

      {
        doctor?.length < 0 ?
          <>
            <div className="chat_body_box_card_body">
              <div className="row">
                <div className="col-md-12 d-flex justify-content-center align-items-center doctorequ " style={{ height: "70vh" }}>
                  <div className="">
                    <img src={backimg} alt="Image Description" />
                  </div>
                </div>
              </div>
            </div>

          </> :
          <>
            <div className="chat_body_box_card_head">
              <div className="row">
                <div className="col-md-12">
                  <h3 className="chat_body_box_title mt_10">
                    Consultation for {specialityID[0]?.speciality}
                  </h3>
                  <h5 className="chat_body_box_subtitle">
                    Here are some of the best doctors for you.
                  </h5>
                </div>
              </div>
            </div>
            <div className="chat_body_box_card_body">
              {doctor?.length > 0 ? (
                <>
                  {
                    doctor?.map((doctor, index) => (
                      <>
                        <div className="row">
                          <div className="col-md-6" key={index}>
                            <div className="row">
                              <div className="col-md-3 col-4 padding_right_0">
                                <img
                                  src={doctor.image}
                                  className="consultant_doc_img"
                                  alt=""
                                ></img>
                              </div>
                              <div className="col-md-9 col-8 padding_left_0 name_experience">
                                <div className="consult_info_box">
                                  <h5 className="consultant_doc_name">{doctor?.name}</h5>
                                  <h5 className="consultant_doc_designation">
                                    {doctor?.speciality}
                                  </h5>
                                  <h5 className="consultant_doc_experience">
                                    Exp - {doctor.experience} Years
                                  </h5>
                                  <h5 className="consultant_count">
                                    {doctor.total_consulation} Consultations{" "}
                                    <img
                                      src={Icon.consultant}
                                      className="consultant_count_icon"
                                      alt=""
                                    ></img>
                                  </h5>
                                  <h3 className="consultant_qualification_title">
                                    Qualification
                                  </h3>
                                  <h5 className="consultant_qualification_text">
                                    {doctor.qualification}
                                  </h5>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="consult_time_btn_box consult_time_btn_box2">
                              <h5 className="available_time available_time2">
                                Next Available
                                <br /> {doctor.next_availble_time}
                              </h5>

                              <Button
                                className="consult_now_btn consult_now_btn_2 "
                                onClick={() => {
                                  setModalShow(true);
                                  formikProps.setFieldValue("doctor_id", doctor?.id);
                                  formikProps.setFieldValue(
                                    "consulting_fee",
                                    doctor?.consulting_fee
                                  );
                                  setdoctorid(doctor?.id)
                                  setBookingDateTime(doctor);
                                }}
                              >
                                {" "}
                                CONSULT NOW{" "}
                              </Button>
                            </div>
                          </div>
                        </div>
                        <hr className="consult_bottom_border" />
                      </>
                    ))
                  }
                  <Pagination
                    totalItems={doctors.length}
                    itemsPerPage={itemsPerPage}
                    onPageChange={handlePageChange}
                  />
                </>
              ) : (
                <div>
                  <div className="chat_body_box_card_body">
                    <div className="row">
                      <div className="col-md-12 d-flex justify-content-center align-items-center doctorequ " style={{ height: "70vh" }}>
                        <div className="">
                          <img src={backimg} alt="Image Description" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

            </div>
          </>
      }



    </>
  );
}

export default ChatScreen1;
