import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { PackageDetailsById } from "../../../Store/Reducer/PackageSlice";
import { GetMember } from "../../../Store/Reducer/ProfileReducer";
import { BackGround, Icon } from "../../../Utilities/Icons";
import SelecteMemberModel from "../MemberModel/SelecteMemberModel";

const HealthPackageDetailModel = (props) => {
  const dispatch = useDispatch();
  const [selectMember, setSelectMember] = useState(false);

  const { packageDetailsById, packageId } = useSelector(
    ({ PackageSlice }) => PackageSlice
  );
  useEffect(() => {
    if (packageId) {
      dispatch(PackageDetailsById(packageId));
      dispatch(GetMember());
    }
  }, [packageId, dispatch]);

  return (
    <>
      <SelecteMemberModel
        show={selectMember}
        onHide={() => setSelectMember(false)}
        packageId={packageId}
      />

      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="lab_test_modal_box"
        backdrop="static"
      >
        <Modal.Header
          className="lab_test_modal_box_head_padding"
          style={{ marginBottom: "none", border: "none" }}
        >
          <h5 className="lab_test_popup_title">Package Details</h5>
          <img
            src={Icon.cross}
            className="lab_test_popup_close"
            onClick={props.onHide}
            alt=""
          ></img>
        </Modal.Header>
        <Modal.Body>
          {packageDetailsById &&
            packageDetailsById?.map((item) => (
              <>
                <div className="lab_test_modal_box_2">
                  <div className="fullbody_package_box">
                    <div className="fullbody_package_box_inner">
                      <div>
                        <img
                          src={BackGround.package}
                          className="fullbody_package_img"
                          alt=""
                        ></img>
                      </div>
                      <div>
                        <h3 className="fullbody_package_title">
                          {item?.package_title}
                        </h3>
                        <h5 className="fullbody_package_subtitle">
                          {item?.labtestdetails?.length} tests
                        </h5>
                        <h6 className="fullbody_package_price">
                          ₹ {item?.package_amount}
                        </h6>
                      </div>
                    </div>
                    <div>
                      <Button
                        className="fullbody_package_btn"
                        onClick={() => {
                          props.onHide(true);
                          setSelectMember(true);
                        }}
                      >
                        Book Now
                      </Button>
                    </div>
                  </div>
                  <hr className="fullbody_package_hr" />
                </div>
                <div className="fullbody_package_scroll_box">
                  <p className="fullbody_package_scroll_para">
                    <span className="fullbody_package_scroll_paratitle">
                      Test Instructions:
                    </span>{" "}
                    {item?.package_description}
                  </p>
                  <h3 className="fullbody_package_scroll_test_title">
                    Tets Included
                  </h3>
                  {item?.labtestdetails?.length > 0 &&
                    item?.labtestdetails.map((labtest) => (
                      <>
                        <h5 className="fullbody_package_scroll_test_name">
                          {labtest?.test_name
                            ? labtest?.test_name
                            : labtest?.name}
                        </h5>
                        <h6 className="fullbody_package_scroll_test_para">
                          {labtest?.test_preparation
                            ? labtest?.test_preparation
                            : labtest?.prescription}
                        </h6>
                        <hr className="fullbody_package_hr" />
                      </>
                    ))}
                </div>
              </>
            ))}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default HealthPackageDetailModel;
