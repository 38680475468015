import { useFormik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  RadiologyPastBooking,
  setRadiologyTestCenterDetailsById,
} from "../../../../Store/Reducer/RadiologySlice";
import { Icon } from "../../../../Utilities/Icons";
import CancelationReasonModel from "../../../Common/CancelationReasonModel/CancelationReasonModel";
import RadiologicalTestSlotModel from "../../../Common/RadiolodicalModel/RadiologicalTestSlotModel";
import Pagination from "../../../Common/Pagination/Pagination";

const NewLabTest = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [timeSample, settimeSample] = useState(false);
  const [cancelationReasonModel, setCancelationReasonModel] = useState(false);

  const {
    radiologyUpcomingBookingsList,
    radiologyTestCenterDetailsById,
    radiologyPastBookingList,
  } = useSelector(({ RadiologySlice }) => RadiologySlice);
  const { cancelationReason } = useSelector(({ BookingSlice }) => BookingSlice);
  useEffect(() => {
    dispatch(RadiologyPastBooking());
  }, [dispatch]);
  const formik = useFormik({
    initialValues: {
      slot_id: "",
    },
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
    },
  });


  const [currentPage, setCurrentPage] = useState(0); // Current page index
  const itemsPerPage = 5; // Number of bookings to display per page

  const handlePageChange = (selected) => {
    setCurrentPage(selected); // Update the current page index
  };

  const radiologyUpcomingBookingList = radiologyUpcomingBookingsList?.hasError
    ? []
    : radiologyUpcomingBookingsList?.slice(
      currentPage * itemsPerPage,
      (currentPage + 1) * itemsPerPage
    );


  const [currentPage2, setCurrentPage2] = useState(0);
  const handlePageChange2 = (selected) => {
    setCurrentPage2(selected); // Update the current page index
  };
  const radiologyPastBooksingList = radiologyPastBookingList?.hasError
    ? []
    : radiologyPastBookingList?.slice(
      currentPage2 * itemsPerPage,
      (currentPage2 + 1) * itemsPerPage
    );


  return (
    <>
      <RadiologicalTestSlotModel
        show={timeSample}
        formik={formik}
        test_center_id={radiologyTestCenterDetailsById?.center_id}
        onHide={() => settimeSample(false)}
      />

      <CancelationReasonModel
        show={cancelationReasonModel}
        cancelationReason={cancelationReason}
        onHide={() => {
          setCancelationReasonModel(!cancelationReasonModel);
        }}
      />
      <div>
        <div className="row">
          <div className="col-xl-12 col-md-8">
            <div className="my_test_body_box_card_head">
              <div className="row">
                <div className="col-md-6">
                  <h3 className="my_test_body_card_head_title">
                    Radiology Test Booking
                  </h3>
                </div>
                <div className="col-md-6"></div>
              </div>
            </div>
            <div className="my_test_body_box_card_body">
              <div className="row">
                <div className="col-md-12">
                  <h3 className="upcoming_test_booking_title">
                    Upcoming Radiology Test Booking
                  </h3>


                  {radiologyUpcomingBookingList?.length > 0 ? (
                    <>
                      {radiologyUpcomingBookingList?.map((item, index) => (
                        <div>
                          <div
                            className="upcoming_test_card_head"
                            onClick={() =>
                              navigate(`/radiology-test-detail/${item?.id}`)
                            }
                          >
                            <img
                              src={Icon.scheduled}
                              className="upcoming_consultation_card_icon"
                              alt=""
                            ></img>{" "}
                            <span className="upcoming_consultation_card_title">
                              {moment(item?.booking_date_time).format(
                                "DD-MMM-YYYY | h:mm A"
                              )}{" "}
                            </span>
                          </div>
                          <div className="upcoming_test_card_body mb_25">
                            <div className="row">
                              <div className="col-md-12">
                                <h5 className="my_test_id">ID#{item?.id}</h5>
                              </div>
                            </div>
                        
                            <div className="row">
                              <div className="col-md-6">
                                <div className="row">
                                  <div className="col-md-6 col-6">
                                    <h5 className="radiology_booked_test_for">
                                      Test For
                                    </h5>
                                    <div className="radiology_booked_test_profile_box mb_10">
                                      <div>
                                        <div className="addnewmember_profile_icon">
                                          {" "}
                                          {item?.member_details?.name?.charAt(
                                            0
                                          )}{" "}
                                        </div>
                                      </div>
                                      <div>
                                        <h5 className="radiology_booked_test_for_name">
                                          {item?.member_details?.name}
                                        </h5>
                                      </div>
                                    </div>
                                    {item?.test_data?.map((test) => (
                                      <h3 className="my_test_card_head_title">
                                        Test: {test?.test_name}
                                      </h3>
                                    ))}

                                    {/* <h5 className="my_test_note">Note: --</h5> */}
                                  </div>
                                  {/* <div className="col-md-6 col-6">
                           <h5 className="radiology_booked_test_for">
                             Test For
                           </h5>
                           <div className="radiology_booked_test_profile_box mb_10">
                             <div>
                               <div className="my_test_profile_icon_1">
                                 {" "}
                                 B{" "}
                               </div>
                             </div>
                             <div>
                               <h5 className="radiology_booked_test_for_name">
                                 Brother
                               </h5>
                             </div>
                           </div>
                           <h3 className="my_test_card_head_title">
                             Test: Covid -19
                           </h3>
                           <h5 className="my_test_note">Note: --</h5>
                         </div> */}
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="consultation_option_box">
                                  <div
                                    className="consultation_option_box_inner_1"
                                    onClick={() => {
                                      setCancelationReasonModel(true);
                                      dispatch(
                                        setRadiologyTestCenterDetailsById(item)
                                      );
                                    }}
                                  >
                                    <img
                                      src={Icon.cancel}
                                      className="cancel_icon"
                                      alt=""
                                    ></img>{" "}
                                    <span className="cancel_appointment">
                                      Cancle Booking
                                    </span>
                                  </div>
                                  <div className="consultation_option_box_inner_2">
                                    <img
                                      src={Icon.reschedule}
                                      className="reschedule_icon"
                                      alt=""
                                    ></img>{" "}
                                    <span
                                      className="reschedule_title"
                                      onClick={() => {
                                        settimeSample(true);
                                        dispatch(
                                          setRadiologyTestCenterDetailsById(item)
                                        );
                                      }}
                                    >
                                      Reschedule
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                        {
                          radiologyUpcomingBookingList?.length > 10 && (
                            <Pagination
                            totalItems={radiologyUpcomingBookingsList.length}
                            itemsPerPage={itemsPerPage}
                            onPageChange={handlePageChange}
                          />
                          )
                        }
                    
                    </>
                  ) : (
                    <div>No Data Found</div>
                  )}



                  {radiologyPastBooksingList?.length > 0 && (
                    <h3 className="past_test_booking_title mt_25">
                      Past Test Booking
                    </h3>
                  )}

                  {radiologyPastBooksingList?.length > 0 ? (
                    <>
                      {radiologyPastBookingList?.map((radiologyPastBookings) => (
                        <div>
                          <div className="past_test_card_head"
                           onClick={() =>
                            navigate(`/radiology-test-detail/${radiologyPastBookings?.id}`)
                          }
                          >
                            <img
                              src={Icon.scheduled_green}
                              className="upcoming_consultation_card_icon"
                              alt=""
                            ></img>{" "}
                            <span className="past_test_card_title">
                              Completed
                            </span>
                          </div>
                          <div className="past_test_card_body mb_25">
                            <div className="row">
                              <div className="col-md-12">
                                <h5 className="my_test_id">
                                  ID#{radiologyPastBookings?.order_id}
                                </h5>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="row">
                                  <div className="col-md-6 col-6">
                                    <h5 className="radiology_booked_test_for">
                                      Test For
                                    </h5>
                                    <div className="radiology_booked_test_profile_box mb_10">
                                      <div>
                                        <div className="addnewmember_profile_icon">
                                          {" "}
                                          J{" "}
                                        </div>
                                      </div>
                                      <div>
                                        <h5 className="radiology_booked_test_for_name">
                                          {
                                            radiologyPastBookings?.member_details
                                              ?.name
                                          }
                                        </h5>
                                      </div>
                                    </div>
                                    <h3 className="my_test_card_head_title">
                                      Test: {radiologyPastBookings?.test_name}
                                    </h3>
                                    {/* <h5 className="my_test_note">Note: --</h5> */}
                                  </div>
                                  <div className="col-md-6 col-6">
                                    <h5 className="radiology_booked_test_for">
                                      Test For
                                    </h5>
                                    <div className="radiology_booked_test_profile_box mb_10">
                                      <div>
                                        <div className="my_test_profile_icon_1">
                                          {" "}
                                          B{" "}
                                        </div>
                                      </div>
                                      <div>
                                        <h5 className="radiology_booked_test_for_name">
                                          Brother
                                        </h5>
                                      </div>
                                    </div>
                                    <h3 className="my_test_card_head_title">
                                      Test: Covid -19
                                    </h3>
                                    <h5 className="my_test_note">Note: --</h5>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6"></div>
                            </div>
                          </div>
                        </div>
                      ))
                      }<Pagination
                        totalItems={radiologyPastBookingList.length}
                        itemsPerPage={itemsPerPage}
                        onPageChange={handlePageChange2}
                      />
                    </>
                  ) : (
                    <div>No Data Found</div>
                  )}

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewLabTest;
