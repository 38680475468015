import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Logo } from "../../Utilities/Icons";
import { Button, NavDropdown, Dropdown } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { removeSession } from "../../Store/Reducer/AuthSlice";
import { GetPackageMemberDetails } from "../../Store/Reducer/ProfileReducer";

function Header() {
  const { token, session } = useSelector(({ AuthSlice }) => AuthSlice);
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  const dispatch = useDispatch();
  const { packageMemberDetails } = useSelector(
    ({ ProfileSlice }) => ProfileSlice
  );
  useEffect(() => {
    dispatch(GetPackageMemberDetails(1));
  }, [dispatch]);


  return (
    <div className="sticky-header">
      <Navbar className="headerNav" expand="lg">
        <Container>
          <Navbar.Brand >
            {/* href="/patient" */}
            <Nav.Link href="https://thsindia.in/" target="_blank">
              <img src={Logo.THS_logo} className="nav_logo"></img>
            </Nav.Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="me-auto nav_options_2 my-2 my-lg-0"
              style={{ maxHeight: "100px" }}
              navbarScroll
            >
              <Nav.Link onClick={() => navigate("/")}>

                <span
                  className={`nav_links ${currentPath === '/' ? 'active' : ''}`}
                // onClick={() => handleLinkClick('home')}
                >
                  Home
                </span>
              </Nav.Link>
              <Nav.Link onClick={() => { navigate("/homehealth"); localStorage.setItem("healthcare", true) }}>

                <span
                  className={`nav_links ${currentPath === '/homehealth' ? 'active' : ''}`}
                // onClick={() => setActiveLink("package")}

                >
                  {/* Health Package */}
                  Health Package
                </span>
                {/* <span className="nav_links">Health Package</span> */}
              </Nav.Link>

              <Nav.Link onClick={() => { navigate("/speciality") }}>

                <span
                  className={`nav_links ${currentPath === '/speciality' ? 'active' : ''}`}
                // onClick={() => setActiveLink("package")}

                >
                  {/* Health Package */}
                  Doctor Consultation
                </span>
                {/* <span className="nav_links">Health Package</span> */}
              </Nav.Link>

              <Nav.Link onClick={() => { navigate("/medicines") }}>

                <span
                  className={`nav_links ${currentPath === '/medicines' ? 'active' : ''}`}
                // onClick={() => setActiveLink("package")}
                >
                  Medicine
                </span>
              </Nav.Link>

              {/* <Nav.Link href="https://thsindia.in/surgery/" target="_blank"> */}
              <Nav.Link onClick={() => { navigate("/surgery") }}>

                <span
                  className={`nav_links ${currentPath === '/surgery' ? 'active' : ''}`}
                >
                  {/* Help */}
                  Surgery
                </span>
              </Nav.Link>
              <Nav.Link onClick={() => { navigate("/labtestpackage") }}>

                <span className={`nav_links ${currentPath === '/labtestpackage' ? 'active' : ''}`}>
                  {/* 18003097847 */}
                  Lab Test
                </span>
              </Nav.Link>
              {/* <Nav.Link onClick={() => { navigate("/corporate-plans") }}> */}
              <Nav.Link href="https://thsindia.in/corporate-plans/" target="_blank">

                <span className={`nav_links ${currentPath === '/corporate-plans' ? 'active' : ''}`}>

                  corporate
                </span>
              </Nav.Link>
              {/* <Nav.Link onClick={() => { navigate("/ths-plus") }}> */}
              <Nav.Link href="https://thsindia.in/ths-plus/" target="_blank">

                <span className={`nav_links ${currentPath === '/corporate-plans' ? 'active' : ''}`}>
                  THS Plus
                </span>
              </Nav.Link>
            </Nav>
            {token ? (
              <Nav pullRight>
                <NavDropdown
                  eventKey={1}
                  className="usermenu-dropdown-nav"
                  title={
                    <div className="pull-left">
                      {
                        packageMemberDetails && packageMemberDetails?.image == null ?
                        <img
                        className="thumbnail-image rounded-circle "
                        src={Logo.THS_logo}
                        height="50px"
                        width="50px"
                        style={{ padding: "0" }}
                        alt="user pic"
                      /> : 
                      <img
                      className="thumbnail-image rounded-circle "
                      src={session?.image}
                      height="50px"
                      width="50px"
                      style={{ padding: "0" }}
                      alt="user pic"
                    />
                      }
                    
                      <div className="profileName">{session.name}</div>
                    </div>
                  }
                  id="basic-nav-dropdown"
                >
                  <Dropdown.Item
                    eventKey={1.1}
                    onClick={() => navigate("/profileSidebar")}
                  >
                    Profile
                  </Dropdown.Item>
                  <Dropdown.Item
                    eventKey={1.3}
                    onClick={() => {
                      {
                        dispatch(removeSession());
                      }
                      navigate("/");
                    }}
                  >
                    <i className="fa fa-sign-out"></i> Logout
                  </Dropdown.Item>
                </NavDropdown>
              </Nav>
            ) : (
              <Button
                className="login_signup_btn"
                onClick={() => navigate("/signin")}
              >
                Login / Sign up
              </Button>
            )}
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}

export default Header;
