import React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DashboardHome from "../Private/Dashboard/Dashboard";

export default function Universal() {
  const { token } = useSelector(({ AuthSlice }) => AuthSlice);
  const navigate = useNavigate();
  useEffect(() => {
    navigate("");
  }, []);

  return (
    <div>
      <DashboardHome />
    </div>
  );
}
