import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  AddressCreateAPI,
  AddressDeleteAPI,
  AddressEditAPI,
  AddressListAPI,
} from "../../Routes/Service";

import { AlertEnum } from "../../Utilities/Enums";
import { setLoading, setMessage } from "./LayoutsSice";

const initialState = {
  addressList: [],
  selectedAddress: "",
  editAddress: "",
};
export const AddressList = createAsyncThunk(
  "AddressList",
  async (values, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const result = await AddressListAPI(values);
      if (result?.success) {
        dispatch(setLoading(false));
        return result?.data;
      } else {
        throw result;
      }
    } catch (error) {
      dispatch(setLoading(false));
      // dispatch(
      //   setMessage({
      //     text: error?.message,
      //     type: AlertEnum.Error,
      //   })
      // );
      return error;
    }
  }
);
export const AddressCreate = createAsyncThunk(
  "AddressCreate",
  async (values, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const result = await AddressCreateAPI(values);
      if (result?.success) {
        dispatch(setLoading(false));
        dispatch(AddressList());
        return result?.data;
      } else {
        throw result;
      }
    } catch (error) {
      dispatch(setLoading(false));
      // dispatch(
      //   setMessage({
      //     text: error?.message,
      //     type: AlertEnum.Error,
      //   })
      // );

      return error;
    }
  }
);

export const AddressEdit = createAsyncThunk(
  "AddressEdit",
  async (values, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const result = await AddressEditAPI(values);
      if (result?.success) {
        dispatch(setLoading(false));
        dispatch(AddressList());
        return result?.data;
      } else {
        throw result;
      }
    } catch (error) {
      dispatch(setLoading(false));
      // dispatch(
      //   setMessage({
      //     text: error?.message,
      //     type: AlertEnum.Error,
      //   })
      // );

      return error;
    }
  }
);
export const AddressDelete = createAsyncThunk(
  "AddressDelete",
  async (values, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const result = await AddressDeleteAPI({ address_id: values });
      if (result?.success) {
        dispatch(setLoading(false));
        dispatch(AddressList());
        return result?.data;
      } else {
        throw result;
      }
    } catch (error) {
      dispatch(setLoading(false));
      // dispatch(
      //   setMessage({
      //     text: error?.message,
      //     type: AlertEnum.Error,
      //   })
      // );

      return error;
    }
  }
);
export const AddressSlice = createSlice({
  name: "AddressSlice",
  initialState,
  reducers: {
    setSelectedAddress: (state, action) => {
      state.selectedAddress = action.payload;
    },
    setEditdAddress: (state, action) => {
      state.editAddress = action.payload;
    },
    setClearAddress: (state, action) => {
      state.editAddress = "";
    },
  },

  extraReducers: (builder) => {
    builder.addCase(AddressList.fulfilled, (state, action) => {
      state.addressList = action.payload;
    });
  },
});

export const { setSelectedAddress, setEditdAddress, setClearAddress } =
  AddressSlice.actions;
export default AddressSlice.reducer;
