import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Icon } from "../../../Utilities/Icons";
import { Button, Modal } from "react-bootstrap";
import AddressModel from "./AddressModel";

const SelectAddressModel = (props) => {
  const [newAddressModel, setAddressModel] = useState(false);
  const [addressId, setAddressId] = useState();
  const { labtestAddressList } = useSelector(
    ({ LabtestSlice }) => LabtestSlice
  );

  const onChangeAddress = (address) => {

    props.formik.setFieldValue("ADDRESS", address?.address);
    props.formik.setFieldValue("STATE", address?.State);
    props.formik.setFieldValue("CITY", address?.City);
    props.formik.setFieldValue("ZIP", address?.pincode);
    props.formik.setFieldValue("address_id", address?.id);
    props.formik.setFieldValue("address_type", address?.type);
    props.formik.setFieldValue("city_id", address?.City_id);
    props?.setSelectedCityId(address);
    setAddressId(address?.id);
  };

  return (
    <>

      <AddressModel
        show={newAddressModel}
        onHide={() => setAddressModel(!newAddressModel)}
      />
      <Modal
        {...props}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="modal_500"
        backdrop="static"
      >
        <Modal.Header
          className="lab_test_modal_box_head_padding"
          style={{ marginBottom: "none", border: "none" }}
        >
          <h5 className="lab_test_popup_title">
            Select  Address
          </h5>
          <img
            src={Icon.cross}
            className="lab_test_popup_close"
            onClick={props.onHide}
          ></img>
        </Modal.Header>
        <Modal.Body>
          <div className="p-3">
            <div className="select_member_box_2">
              {labtestAddressList?.length &&
                labtestAddressList?.map((address, index) => (
                  <>
                    <div className="consult_member_box_1" key={index}>
                      <div className="consult_member_box_inner">
                        <div>
                          <input
                            type="radio"
                            name="address"
                            onChange={(e) => onChangeAddress(address)}
                            id="address"
                            value={address?.id}
                            checked={
                              props?.formik?.values?.address_id === address?.id

                            }
                            placeholder="Male"
                            style={{ marginTop: "20px" , accentColor: "#199a8e" }}
                        
                          />
                          <lable for={address?.id}>
                            <h5 className="lab_sample_collection_add_title">
                              {address?.type}
                            </h5>
                            <h5 className="lab_sample_collection_add_para">
                              {address?.address}
                              
                              {/* 11 shivam flat, newar sport complex,
                              <br /> tulishidham, manjalpur, vadodara */}
                            </h5>
                          </lable>
                        </div>
                      </div>
                    </div>
                    <hr className="consult_member_hr" />
                  </>
                ))}
            </div>
            <div className="add_member_box">
              <img src={Icon.add} className="add_member_icon"></img>
              <h5
                className="add_member_text"
                onClick={() => {
                  props.onHide();
                  setAddressModel(true);
                }}
              >
                Add New Address
              </h5>
            </div>
            <center>
              <Button
                className="consult_modal_btn"
                type="submit"
                onClick={() => {props.onHide(); addressId={addressId}}}
              >
                Continue
              </Button>
            </center>
          </div>
        </Modal.Body>
        <Modal.Footer
          style={{ border: "none", display: "none" }}
        ></Modal.Footer>
      </Modal>
    </>
  );
};

export default SelectAddressModel;
