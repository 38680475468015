import React, { useState } from "react";
import moment from "moment";
import { useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { Icon } from "../../../Utilities/Icons";

const PaidModal = (props) => {
    
    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                dialogClassName="lab_test_modal_box"
                backdrop="static"

            >
                <Modal.Header
                    className="lab_test_modal_box_head_padding"
                    style={{
                        backgroundColor: " #F4F4F4"
                    }}
                >
                    <h5 className="lab_test_popup_title">Id #{props?.paidData?.order_id}</h5>
                    <img
                        src={Icon.cross}
                        className="lab_test_popup_close"
                        onClick={props.onHide}
                        alt=""
                    ></img>
                </Modal.Header>
                <Modal.Body className=""
                    style={{
                        backgroundColor: " #F4F4F4"
                    }}>
                    <div>
                        <div className="payment_done_card_box mb_10">
                            <h3 className="payment_done_payment_info">Service</h3>
                            <hr className="payment_done_hr" />
                            <div className="row">
                                <div className="">
                                    <p className="docname">For {props?.paidData?.member_details?.name} </p>
                                    <button className="carebtn">Nursing Care</button>
                                    <div className="nameflex">
                                        <p className="careparagraph pt_10"> Doctor dressing</p>
                                        <h5 className="payment_done_payment_info_value">
                                            ₹ {props?.paidData?.total_amount}
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="payment_done_card_box">
                            <h3 className="payment_done_payment_info">Payment info</h3>
                            <hr className="payment_done_hr" />
                            <div className="row">
                                <div className="col-md-6 col-6">
                                    <h5 className="payment_done_payment_info_title">Amount</h5>
                                </div>
                                <div className="col-md-6 col-6">
                                    <h5 className="payment_done_payment_info_value">
                                        ₹ {props?.paidData?.grand_total}
                                    </h5>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 col-6">
                                    <h5 className="payment_done_payment_info_title" style={{color:"#48988E"}}>Discount</h5>
                                </div>
                                <div className="col-md-6 col-6">
                                    <h5 className="payment_done_payment_info_value" style={{color:"#48988E"}}>
                                        - ₹ {props?.paidData?.discount}
                                    </h5>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 col-6">
                                    <h5 className="payment_done_payment_info_title" style={{color:"#48988E"}}>THS GOLD Discount (5%)</h5>
                                </div>
                                <div className="col-md-6 col-6">
                                    <h5 className="payment_done_payment_info_value" style={{color:"#48988E"}}>
                                        -₹ {props?.paidData?.gold_discount}
                                    </h5>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 col-6">
                                    <h5 className="payment_done_payment_info_title">Convenience fee</h5>
                                </div>
                                <div className="col-md-6 col-6">
                                    <h5 className="payment_done_payment_info_value">
                                        ₹ {props?.paidData?.convenience_fee}
                                    </h5>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 col-6">
                                    <h5 className="payment_done_payment_info_title">Total amount</h5>
                                </div>
                                <div className="col-md-6 col-6">
                                    <h5 className="payment_done_payment_info_value">
                                        ₹ {props?.paidData?.total_amount}
                                    </h5>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 col-6">
                                    <h5 className="payment_done_payment_info_title">Status</h5>
                                </div>
                                <div className="col-md-6 col-6">
                                    <h5 className="payment_done_payment_info_value">
                                        {
                                            props?.paidData?.type == 2 ? <>
                                                <h5 className="payment_done_payment_info_title" style={{ color: "#199A8E" }}>paid</h5>
                                            </> : <></>
                                        }

                                    </h5>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 col-6">
                                    <h5 className="payment_done_payment_info_title">Wallet</h5>
                                </div>
                                <div className="col-md-6 col-6">
                                    <h5 className="payment_done_payment_info_value">
                                        ₹ {props?.paidData?.wallet_amount}
                                    </h5>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 col-6">
                                    <h5 className="payment_done_payment_info_title">Online</h5>
                                </div>
                                <div className="col-md-6 col-6">
                                    <h5 className="payment_done_payment_info_value">
                                        ₹ 100
                                    </h5>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 col-6">
                                    <h5 className="payment_done_payment_info_title">Txn. Id</h5>
                                </div>
                                <div className="col-md-6 col-6">
                                    <h5 className="payment_done_payment_info_value">
                                        {props?.paidData?.transaction_id}
                                    </h5>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 col-6">
                                    <h5 className="payment_done_payment_info_title">Date and Time</h5>
                                </div>
                                <div className="col-md-6 col-6">
                                    <h5 className="payment_done_payment_info_value">
                                        {moment(props?.paidData?.booking_date_time).format(
                                            "DD MMM YYYY | h:mm A"
                                        )}{" "}

                                    </h5>
                                </div>
                            </div>

                        </div>
                    </div>

                </Modal.Body>
            </Modal>

        </>
    );
};

export default PaidModal;
