import React from "react";
import ReactDOM from "react-dom";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import "./Assets/css/style.css";
import "./Assets/css/responsive.css";
import "./Components/Common/style.css";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { store } from "./Store/Store";

import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";



ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router basename="/">
        <App />
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
