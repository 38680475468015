import React, { useState } from "react";
import { Icon } from "../../../../Utilities/Icons";
import AddressModel from "../../../Common/AddressModel/AddressModel";
import { useDispatch, useSelector } from "react-redux";
import {
  AddressDelete,
  setClearAddress,
  setEditdAddress,
} from "../../../../Store/Reducer/AddressSlice";

const Address = () => {
  const [newAddressModel, setAddressModel] = useState(false);
  const { addressList } = useSelector(({ AddressSlice }) => AddressSlice);
  const dispatch = useDispatch();
  return (
    <>
      <AddressModel
        show={newAddressModel}
        onHide={() => {
          setAddressModel(!newAddressModel);
        }}
      />
      <div className="address_body_box_card_head">
        <div className="row">
          <div className="col-md-6 col-4">
            <h3 className="address_body_card_head_title">Address</h3>
          </div>
          <div className="col-md-6 col-8">
            <h5
              className="address_body_card_head_new_add"
              onClick={() => {
                setAddressModel(true);
                dispatch(setClearAddress());
              }}
            >
              <img
                src={Icon.add}
                className="address_body_card_head_add_icon"
              ></img>
              Add New Address
            </h5>
          </div>
        </div>
      </div>
      <div className="address_body_box_card_body">
        <div className="row">
          <div className="col-md-12">
            {addressList?.map((address) => (
              <div className="address_box_card mb_25">
                <h3 className="address_box_card_title">{address?.type}</h3>
                <h5 className="address_box_card_para">
                  {address?.address}
                  {/* 11 shivam flat, newar sport complex,
                <br /> tulishidham, manjalpur, vadodara */}
                </h5>
                <div className="address_box_card_btn_box">
                  <div
                    onClick={() => {
                      setAddressModel(!newAddressModel);
                      dispatch(setEditdAddress(address));
                    }}
                  >
                    <h3 className="address_box_card_add_edit">Edit</h3>
                  </div>
                  <div onClick={() => dispatch(AddressDelete(address?.id))}>
                    <h3 className="address_box_card_add_delete">Delete</h3>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
export default Address;
