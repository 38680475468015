import moment from "moment";
import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { MedicineOrderDetails } from "../../../../Store/Reducer/OrderSilce";
import { Icon } from "../../../../Utilities/Icons";

const MedicineOrdersDetail = () => {
  const { medicineOrderDetail } = useSelector(({ OrderSilce }) => OrderSilce);
  const [item_product, setitem_product] = useState();
  const [address, setaddress] = useState();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { session } = useSelector(({ AuthSlice }) => AuthSlice);

  useEffect(() => {
    if (id) {
      dispatch(MedicineOrderDetails(id)).then((res) => {
        setitem_product(JSON?.parse(res?.payload?.products));
        setaddress(JSON?.parse(res?.payload?.address));
      });
    }
  }, [dispatch, id]);

  return (
    <Container fluid className="medicine_orders_placed_container">
      <Container>
        <div className="row">
          <div className="col-md-12">
            <h3 className="ownprofile_head">
              ID #{medicineOrderDetail?.order_id}
            </h3>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="medicine_orders_placed_card_box">
              <div className="row">
                <h5 className="medicine_orders_placed_title mb_20">
                  Medicines
                </h5>
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="radiology_booked_test_profile_box">
                        <div>
                          <div className="addnewmember_profile_icon">
                            {session?.name?.charAt(0)}{" "}
                          </div>
                        </div>
                        <div>
                          <h5 className="radiology_booked_for_title">For</h5>
                          <h5 className="radiology_booked_test_for_name">
                            {session?.name}
                          </h5>
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-md-6">
                      <div className="test_completed_option_box_1">
                        <div className="download_e_report_width">
                          <div className="download_e_report">
                            Download Invoice
                            <img
                              // src={require("../Assets/img/png/download_black.png")}
                              className="e-report_icon"
                            ></img>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
              <hr className="medicine_orders_placed_hr" />
              <div className="row">
                <div className="col-md-6">
                  <div className="row mb_10">
                    <div className="col-md-6 col-6">
                      <h5 className="medicine_orders_placed_toptitle">Name</h5>
                    </div>
                    <div className="col-md-6 col-6">
                      <h5 className="medicine_orders_placed_toptitle">Qty.</h5>
                    </div>
                  </div>
                  {item_product?.length &&
                    item_product?.map((item) => (
                      <div className="row mb_20">
                        <div className="col-md-6 col-6">
                          <h5 className="medicine_orders_placed_data">
                            {item?.product_name}
                          </h5>
                        </div>
                        <div className="col-md-6 col-6">
                          <h5 className="medicine_orders_placed_data">
                            {item?.qty}
                          </h5>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div className="medicine_orders_placed_card_box mt_10">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12">
                  <div className="row">
                    <div className="col-md-12">
                      <h5 className="test_completed_body_title">Tracking</h5>
                    </div>
                  </div>
                  <div className="order_tracking">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="row">
                          <div className="col-12 col-md-10 pt45 pb20">
                            <div className="row">
                              <div className={`order-tracking   completed`}>
                                <span className="is-complete"></span>
                                <h3 className="booking_placed_title active">
                                  Booking Placed
                                </h3>
                                <p>21 Jun, 2022 | 2:00 pm</p>
                              </div>
                              <div
                                className={`order-tracking ${
                                  medicineOrderDetail?.is_paid === 1 &&
                                  "completed"
                                } `}
                              >
                                <span className="is-complete"></span>
                                <h3 className="booking_placed_title active">
                                  Booking Confirmed
                                </h3>
                                <p>21 Jun, 2022 | 2:00 pm</p>
                              </div>
                              <div
                                className={`order-tracking ${
                                  medicineOrderDetail?.is_paid > 2 &&
                                  "completed"
                                } `}
                              >
                                <span className="is-complete"></span>
                                <h3 className="booking_placed_title active">
                                  Sample Colelcted
                                </h3>
                                <p>21 Jun, 2022 | 2:00 pm</p>
                              </div>
                              <div
                                className={`order-tracking ${
                                  medicineOrderDetail?.is_paid > 3 &&
                                  "completed"
                                } `}
                              >
                                <span className="is-complete"></span>
                                <h3 className="booking_placed_title active">
                                  E - Report Generated
                                </h3>
                                <p>21 Jun, 2022 | 2:00 pm</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="test_completed_card_box mt_10">
              {/* <div className="row">
                <div className="col-md-12">
                  <h3 className="sample_collected_by">Prescribed by</h3>
                  <div className="sample_collected_by_box">
                    <div>
                      <img src={Icon.dr_shreeja}></img>
                    </div>
                    <div>
                      <h3 className="sample_collected_doc">
                        Shreeja Maheshwar
                      </h3>
                      <h5 className="sample_collected_emp_id">
                        Employeed id: 123121131
                      </h5>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="row">
                <div className="col-md-12">
                  <h3 className="sample_collection_add">Delivery Address</h3>
                  <h5 className="sample_collection_add_title">
                    {address?.type}
                  </h5>
                  <p className="sample_collection_add_para">
                    {address?.address}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="test_completed_card_box mt_10">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-xs-8 col-sm-12">
                  <h3 className="test_payment_title">Payment Summary</h3>
                  <div className="row">
                    <div className="col-md-6 col-6">
                      <h5 className="test_payment_summary_text">Amount</h5>
                    </div>
                    <div className="col-md-6 col-6">
                      <h5 className="test_payment_summary_value">
                        ₹ {medicineOrderDetail?.amount}
                      </h5>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 col-6">
                      <h5 className="test_payment_summary_discount_text">
                        Discount
                      </h5>
                    </div>
                    <div className="col-md-6 col-6">
                      <h5 className="test_payment_summary_discount_value">
                        -₹ {medicineOrderDetail?.total_savings}
                      </h5>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 col-6">
                      <h5 className="test_payment_summary_text">
                        Total amount
                      </h5>
                    </div>
                    <div className="col-md-6 col-6">
                      <h5 className="test_payment_summary_value">
                        ₹ {medicineOrderDetail?.net_amount}
                      </h5>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 col-6">
                      <h5 className="test_payment_summary_text">Status</h5>
                    </div>
                    <div className="col-md-6 col-6">
                      <h5 className="test_payment_summary_discount_value">
                        {medicineOrderDetail?.status}
                      </h5>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 col-6">
                      <h5 className="test_payment_summary_text">Trxn Id</h5>
                    </div>
                    <div className="col-md-6 col-6">
                      <h5 className="test_payment_summary_value">
                        {medicineOrderDetail?.transaction_id}
                      </h5>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-5 col-5">
                      <h5 className="test_payment_summary_text">
                        Date and Time
                      </h5>
                    </div>
                    <div className="col-md-7 col-7">
                      <h5 className="test_payment_summary_value">
                        {moment(medicineOrderDetail?.created_at).format(
                          "DD MMM YYYY | hh:mm a"
                        )}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </Container>
  );
};

export default MedicineOrdersDetail;
