import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  addToCart,
  clearCartItem,
  clearLabTestCenterDetailsById,
  clearList,
  LabtestLabs,
  removeItem,
  updatelist,
} from "../../../Store/Reducer/LabtestSlice";
import { Icon } from "../../../Utilities/Icons";
import Checkbox from "../Checkbox";
import { clearRadiologyCartItem } from "../../../Store/Reducer/RadiologySlice";

const LabTestModel = (props) => {
  const { labTestList, labCart, labTestCenterDetailsById, list, bookNow } = useSelector(
    ({ LabtestSlice }) => LabtestSlice
  );
  const dispatch = useDispatch();
  const [inputText, setInputText] = useState("");
  const inputHandler = (e) => {
    const lowerCase = e.target.value.toLowerCase();
    setInputText(lowerCase);
  };
  const filteredData = list?.length && list?.filter((el) => {
    if (inputText === "") {
      return true; // Return all items if the input is empty
    } else {
      return el?.['Test-data']?.test_name.toLowerCase().includes(inputText);
    }
  });
  const getTotal = () => {
    let totalQuantity = 0;
    let totalPrice = 0;
    labCart?.forEach((item) => {
      totalPrice += item?.price * item?.quantity;
    });
    return { totalPrice, totalQuantity };
  };

  let test_name = labCart?.map(function (i) {
    return i?.['Test-data']?.id;
  });
  useEffect(() => {
    setIsCheck(test_name)
  }, [labCart])

  const [isCheck, setIsCheck] = useState(test_name);

  const [loading, setLoading] = useState(true);
  const handleClick = (e) => {
    const { id, checked } = e.target;
    const numberId = Number(id);
    setIsCheck([...isCheck, numberId]);
    filteredData?.filter(function (item) {
      if (numberId == item?.['Test-data']?.id) {
        dispatch(addToCart(item));
      }
    });

    if (!checked) {
      filteredData?.filter(function (item) {
        if (numberId == item?.['Test-data']?.id) {
          dispatch(removeItem(item?.id));
        }
      });
      setIsCheck(isCheck.filter((item) => item !== numberId));
    }
  };
  const [currentPage, setCurrentPage] = useState(1);
  const { session } = useSelector(({ AuthSlice }) => AuthSlice);

  const handleScroll = (e) => {
    const { scrollTop, clientHeight, scrollHeight } = e.target;

    if (scrollHeight - scrollTop === clientHeight) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };


  const fetchData = async (page) => {
    try {
      dispatch(LabtestLabs({
        center_id: labTestCenterDetailsById?.id,
        city_id: labTestCenterDetailsById?.city_id,
        page: page
      }));
    } catch (error) {
    }
  };

  useEffect(() => {

    setLoading(true); // Set loading to true before fetching data
    fetchData(currentPage).then(() => {
      setLoading(false);
      // Set loading to false after data is fetched

    });
  }, [currentPage]);

  const [status, setStatus] = useState(true);
  const numberId = Number(bookNow?.id);
  const data = (list.length > 0 && bookNow?.id != undefined) && list?.find((data) => data?.['Test-data'].id == bookNow.id)
  const iddata = labCart?.find((data) => data?.['Test-data']?.id == bookNow?.id)

  useEffect(() => {

    setIsCheck([...isCheck, numberId]);

    if (iddata == undefined) {
      dispatch(addToCart(data));
    }
  }, [dispatch, data])

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="lab_test_modal_box"
      backdrop="static"
    >
      <Modal.Header
        className="lab_test_modal_box_head_padding"
        style={{ marginBottom: "none", border: "none" }}
      >
        <h5 className="lab_test_popup_title">Lab Test</h5>
        <img
          src={Icon.cross}
          className="lab_test_popup_close"
          onClick={() => {
            setStatus(true)
            props.onHide();
          }}
        ></img>
      </Modal.Header>
      <Modal.Body>
        <form>
          <div className="lab_test_modal_box_inner_1">
            <div className="row">
              <div className="col-md-12">
                <img
                  src={Icon.search}
                  className="select_city_search_icon"
                ></img>
                <input
                  type="search"
                  className="health_package_input_search"
                  placeholder="Search"
                  onChange={inputHandler}
                />
              </div>
            </div>
          </div>
          <div className="lab_test_popup_box" onScroll={(e) => handleScroll(e)}>
            <div className="lab_test_modal_box_inner_2">
              {loading && <p>Loading...</p>}
              {filteredData?.length > 0 &&
                filteredData?.map((item, index) => (
                  <>
                    <div className="healthpackage_checkbox_box" key={index}>
                      <div className="healthpackage">
                        <Checkbox
                          key={item?.['Test-data']?.id}
                          type="checkbox"
                          name={item?.['Test-data']?.test_name}
                          id={item?.['Test-data']?.id}
                          handleClick={handleClick}
                          isChecked={isCheck?.includes(item?.['Test-data']?.id)}

                        />
                        <label
                          className="healthpackage_checkbox_title"
                          for={item?.['Test-data']?.id}
                          style={{
                            display: "inline-flex",
                            alignItems: "center",
                          }}
                        >
                          {item?.['Test-data']?.test_name}
                        </label>
                      </div>
                      <div>
                        <h5 className="healthpackage_checkbox_value">
                          ₹ {item?.price}
                        </h5>
                      </div>
                    </div>
                    <hr className="health_package_lab_text_hr" />
                  </>
                ))}
              {list?.length == 0 && (
                <>
                  <h6> No Lab Tests Found</h6>
                </>
              )}
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer className="healthpackage_modal_footer_box">
        <div className="lab_test_modal_footer_inner">
          <div className="">
            <h5 className="lab_test_modal_footer_subtitle">Total amount</h5>
            <h3 className="lab_test_modal_footer_title">
              ₹ {isNaN(getTotal().totalPrice) ? 0 : Math.round(getTotal().totalPrice)}
            </h3>

          </div>
          <div className="">
            {getTotal().totalPrice !== 0 && (
              <div className="">
                <Button
                  className="lab_test_modal_footer_btn"
                  type="submit"
                  onClick={() => {
                    props.onNext();
                  }}
                >
                  Next
                </Button>
              </div>
            )}
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default LabTestModel;
