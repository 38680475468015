import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Icon } from "../../../Utilities/Icons";
import FormControl from "../Forms/FormControl";
import * as Yup from "yup";
import {
  AddressCreate,
  AddressEdit,
  setClearAddress,
} from "../../../Store/Reducer/AddressSlice";
import { useDispatch, useSelector } from "react-redux";

const AddressModel = (props) => {
  const { session } = useSelector(({ AuthSlice }) => AuthSlice);
  const [initialAddress, setinitialAddress] = useState({
    pincode: "",
    type: "",
    address: "",
    others: "",
    landMark: "",
    longitude: "",
    city_state: session?.city_details?.name,
  });
  const dispatch = useDispatch();
  const AddressSchema = Yup.object({
    pincode: Yup.string()
      .length(6)
      .matches(/^[0-9]{5}/)
      .required()
      .label("Postal Code"),
    address: Yup.string().required("Please Enter address"),
    landMark: Yup.string().required("Please Enter landmark"),
    type: Yup.string().required("Please Enter type")
  });
  const { editAddress } = useSelector(({ AddressSlice }) => AddressSlice);
  useEffect(() => {
    if (editAddress?.id) {
      let editState = {
        address_id: editAddress?.id,
        pincode: editAddress?.pincode,
        type: editAddress?.type,
        address: editAddress?.address,
        others: editAddress?.others,
        latitude: editAddress?.latitude,
        longitude: editAddress?.longitude,
        city_state: session?.city_details?.name,
      };
      setinitialAddress(editState);
    }
  }, [editAddress?.id]);

  return (
    <Formik
      initialValues={initialAddress}
      enableReinitialize
      validationSchema={AddressSchema}
      onSubmit={(values) => {
        if (editAddress?.id) {
          dispatch(AddressEdit(values));
          props.onHide();
        } else {
          dispatch(AddressCreate(values));
          props.onHide();
        }
      }}
    >
      {({
        values,
        setFieldValue,
        handleChange,
        handleBlur,
        touched,
        errors,
        handleSubmit,
      }) => (
        <Modal
          {...props}
          size="sm"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          dialogClassName="modal_476"
          backdrop="static"
        >
          <Modal.Header style={{ border: "none" }}>
            <h5 className="select_consult_member_modal_title">
              Add New Address
            </h5>
            <img
              src={Icon.cross}
              className="book_modal_close"
              onClick={() => {
                props.onHide();
                dispatch(setClearAddress());
                setinitialAddress(!initialAddress);
              }}
              alt="My Awesome Image"
            ></img>
          </Modal.Header>
          <Modal.Body>
            <div className="add_member_modal_box">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-12">
                    <div className="input_box">
                      <div className="form_group">
                        <FormControl
                          control="input"
                          type="text"
                          name="pincode"
                          className="add_member_input"
                          placeholder="Pin Code"
                          value={values.pincode}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <lable className="lab_add_tag">
                          {session?.city_details?.name}, Gujarat
                        </lable>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="input_box">
                      <div className="form_group">
                        <FormControl
                          control="input"
                          type="text"
                          name="address"
                          className="add_member_input"
                          placeholder="Address"
                          value={values.address}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="input_box">
                      <div className="form_group">
                        <FormControl
                          control="input"
                          type="text"
                          name="landMark"
                          className="add_member_input"
                          placeholder="land mark"
                          value={values.landMark}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="input_box">
                      <div className="form_group">
                        <FormControl
                          control="input"
                          type="hidden"
                          name="longitude"
                          className="add_member_input"
                          placeholder="Longitude"
                          value={values.longitude}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <h5 className="medical_history_title">Save as</h5>
                  </div>
                </div>
                <div className="row mb_15">
                  <div className="medical_history_box">
                    <div className="medical_history_box_inner">
                      <input
                        type="radio"
                        id="Home"
                        value="Home"
                        checked={values.type === "Home"}
                        onChange={() => setFieldValue("type", "Home")}
                        className="mt_10"
                      />
                      <lable for="Home">
                        <h5 className="medical_history_title_1">Home</h5>
                      </lable>
                    </div>
                    <div className="medical_history_box_inner">
                      <input
                        type="radio"
                        id="Office"
                        value="Office"
                        checked={values.type === "Office"}
                        onChange={() => setFieldValue("type", "Office")}
                        className="mt_10"
                      />
                      <lable for="Office">
                        <h5 className="medical_history_title_1">Office</h5>
                      </lable>
                    </div>
                    <div className="medical_history_box_inner">
                      <input
                        type="radio"
                        id="Other"
                        value="Other"
                        checked={values.type === "Other"}
                        onChange={() => setFieldValue("type", "Other")}
                        className="mt_10"
                      />
                      <lable for="Other">
                        <h5 className="medical_history_title_1">Other</h5>
                      </lable>
                    </div>
                  </div>
                  <div className="terms_conditions_error_text pt-2">
                    {" "}
                    {errors.type && touched.type && errors.type}
                  </div>
                </div>
                <center>
                  <Button
                    type="submit"
                    className="consult_modal_btn"
                    //onClick={props.onHide}
                  >
                    Save
                  </Button>
                </center>
              </form>
            </div>
          </Modal.Body>
          <Modal.Footer
            style={{ border: "none", display: "none" }}
          ></Modal.Footer>
        </Modal>
      )}
    </Formik>
  );
};

export default AddressModel;
