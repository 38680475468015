import USER from "../Assets/img/svg/User.svg";
import Consultations from "../Assets/img/svg/consultations.svg";
// import TestBookings from "../Assets/img/svg/ testBookings.svg";
import MedicineOrders from "../Assets/img/svg/medicineOrders.svg";
import NewLabTest from "../Assets/img/svg/newLabTest.svg";
import Wallet from "../Assets/img/svg/wallet.svg";
import Address from "../Assets/img/svg/address.svg";
import MySubscriptions from "../Assets/img/svg/mySubscriptions.svg";
import Support from "../Assets/img/svg/support.svg";
import LogOut from "../Assets/img/svg/logOut.svg";
import CiverRight from "../Assets/img/svg/CiverRight.svg";
import CiverRightGreen from "../Assets/img/svg/CiverRightGreen.svg";
import Send from "../Assets/img/svg/Send.svg";
import LOGOWHITE from "../Assets/img/svg/LogoWhite.svg";
import SupportNew from "../Assets/img/svg/support_new.svg";
import SupportMail from "../Assets/img/svg/support_mail.svg";
import SupportCall from "../Assets/img/svg/support_call.svg";

export const Logo = {
  THS: require("../Assets/img/png/header_logo.png"),
  THS_new: require("../Assets/img/png/THS_Logo_New.png"),
  THS_logo: require("../Assets/img/png/THS New logo.png"),
  THS_WHITE: LOGOWHITE,
  THS_FOOTER: require("../Assets/img/png/Group 34048.png")
};
export const Icon = {
  user: USER,
  Send,
  CiverRight: CiverRight,
  CiverRightGreen,
  Consultations: Consultations,
  MedicineOrders: MedicineOrders,
  NewLabTest: NewLabTest,
  Wallet: Wallet,
  Address: Address,
  MySubscriptions: MySubscriptions,
  Support: Support,
  LogOut: LogOut,
  SupportNew: SupportNew,
  SupportCall: SupportCall,
  SupportMail: SupportMail,
  TestBookings: require("../Assets/img/png/First, aid, box, red, cross.png"),
  call: require("../Assets/img/png/call.png"),
  male: require("../Assets/img/png/male.png"),
  female: require("../Assets/img/png/female.png"),
  other: require("../Assets/img/png/other.png"),
  search: require("../Assets/img/png/search.png"),
  general: require("../Assets/img/png/General.png"),
  lungs: require("../Assets/img/png/Lungs.png"),
  dentist: require("../Assets/img/png/Dentist.png"),
  psychiatrist: require("../Assets/img/png/Psychiatrist.png"),
  covid_19: require("../Assets/img/png/Covid_19.png"),
  cardiologist: require("../Assets/img/png/Cardiologist.png"),
  surgeon: require("../Assets/img/png/Surgeon.png"),
  backarrow: require("../Assets/img/png/back_arrow.png"),
  consultant: require("../Assets/img/png/consultation_icon.png"),
  cross: require("../Assets/img/png/cross.png"),
  crossw: require("../Assets/img/png/cross_white.png"),
  crossb: require("../Assets/img/png/cross_black.png"),
  add: require("../Assets/img/png/add_member.png"),
  coupon: require("../Assets/img/png/coupon.png"),
  docprofile: require("../Assets/img/png/doctor_img_2.png"),
  chatcall: require("../Assets/img/png/call_2.png"),
  videocall: require("../Assets/img/png/video.png"),
  scheduled: require("../Assets/img/png/scheduled.png"),
  booking: require("../Assets/img/png/watch.png"),
  attach_image: require("../Assets/img/png/chat/attach_image.png"),
  attach_pin: require("../Assets/img/png/chat/attach_pin.png"),
  call_end: require("../Assets/img/png/chat/call_end.png"),
  call_missed: require("../Assets/img/png/chat/call_missed.png"),
  close: require("../Assets/img/png/chat/close.png"),
  download: require("../Assets/img/png/chat/download.png"),
  info: require("../Assets/img/png/chat/info.png"),
  lab: require("../Assets/img/png/chat/lab.png"),
  more: require("../Assets/img/png/chat/more.png"),
  pin: require("../Assets/img/png/chat/pin.png"),
  refresh: require("../Assets/img/png/chat/refresh.png"),
  refresh_attach: require("../Assets/img/png/chat/refresh_attach.png"),
  close_black: require("../Assets/img/png/chat/close_black.png"),
  addmember: require("../Assets/img/png/profile/add_member.png"),
  scheduled_black: require("../Assets/img/png/scheduled_black.png"),
  cancel: require("../Assets/img/png/cancel.png"),
  reschedule: require("../Assets/img/png/reschedule.png"),
  scheduled_green: require("../Assets/img/png/scheduled_green.png"),
  download_white: require("../Assets/img/png/download_white.png"),
  stethoscope: require("../Assets/img/png/chat/stethoscope.png"),
  medicine: require("../Assets/img/png/chat/medicine.png"),
  scheduled_red: require("../Assets/img/png/scheduled_red.png"),
  download_black: require("../Assets/img/png/download_black.png"),
  completed: require("../Assets/img/png/completed.png"),
  bag: require("../Assets/img/png/bag.png"),
  trash: require("../Assets/img/png/trash.png"),
  edit: require("../Assets/img/png/edit.png"),
  star: require("../Assets/img/png/star.png"),
  scheduled_orange: require("../Assets/img/png/scheduled_orange.png"),
  true: require("../Assets/img/svg/Subtract.png"),
  setting: require("../Assets/img/svg/Shape.png"),
  scroll: require("../Assets/img/png/animation_500_l5o40htz 1.png"),
  plus: require("../Assets/img/png/plus.png"),
  the: require("../Assets/img/png/Vector.png"),
  golden: require("../Assets/img/png/gold.png"),
  trueSuccess: require("../Assets/img/png/Group 34266.png"),
  bg: require("../Assets/img/png/Group 34657.png"),
  location: require("../Assets/img/png/Help, info.png"),
  pngimg: require("../Assets/img/png/Location, pin, map.png"),
  ismage: require("../Assets/img/png/doctorCheckUp.png"),
  leftside: require("../Assets/img/png/Group 34009.png"),
  rightside: require("../Assets/img/png/Group 34025.png"),
  start: require("../Assets/img/png/Group 34011.png"),
  perciption : require("../Assets/img/png/Newspaper, magazine, poster.png"),
  care : require("../Assets/img/png/care.png"),
  share : require("../Assets/img/png/Share, media, network, connect.png"),
  round: require("../Assets/img/png/round.png")
};

export const BackGround = {
  owlsliderbanner: require("../Assets/img/png/THS Banner 2.png"),
  owlsliderbanner3: require("../Assets/img/png/THS Banner 3.png"),
  owlsliderbanner4: require("../Assets/img/png/THS Banner 4.png"),
  owlslider: require("../Assets/img/png/qwe.png"),
  homeslider: require("../Assets/img/png/slider_img.png"),
  consultnow: require("../Assets/img/png/consult_now.png"),
  medicines: require("../Assets/img/png/medicines.png"),
  labtest: require("../Assets/img/png/labtest.png"),
  doctorimg: require("../Assets/img/png/doctor_img.png"),
  package: require("../Assets/img/png/package_img.png"),
  bgimage: require("../Assets/img/png/Rectangle 1957.png"),
};
