import {
    RETRIEVE_SUBSCRIPTION_PLAN,RETRIEVE_MEMBERS,CONSULLATION_MEMBER_CREATE
  } from "../actions/types";
  
  const initialState = [  ];
  
  export function subscriptionPlanReducer(subscriptionPlan = initialState, action) {
    const { type, payload } = action;
  
    switch (type) {
      case RETRIEVE_SUBSCRIPTION_PLAN:
        return payload;
      default:
        return subscriptionPlan;
    }
  };
  

 const memberinitialState = []
  export function memberReducer(memiberList = memberinitialState, action) {
    const { type, payload } = action;
  
    switch (type) {
      case CONSULLATION_MEMBER_CREATE:
        return [...memiberList, payload];

        case RETRIEVE_MEMBERS:
          return payload;
      default:
        return memiberList;
    }
  };
  
