import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  MedicineOrdersAPI,
  MedicineOrderCreateAPI,
  MedicineOrderDetailsAPI,
  OrderPlaceAPI,
  OrderCancelAPI,
} from "../../Routes/Service";

import { AlertEnum } from "../../Utilities/Enums";
import { setLoading, setMessage } from "./LayoutsSice";

const initialState = {
  medicineOrderList: [],
  orderDetailByid: "",
  medicineOrderReview: [],
  medicineOrderDetail: "",
};
export const OrderPlace = createAsyncThunk(
  "OrderPlace",
  async (values, { dispatch }) => {
    try {
      const result = await OrderPlaceAPI(values);
      if (result) {
        if (result?.response?.data?.status === "ERROR") {
          dispatch(
            setMessage({
              text: result?.response?.data?.message,
              type: AlertEnum.Success,
            })
          );
          return result?.response?.data?.status
        } else {
          return result?.data;
        }
      } else {
        throw result;
      }
    } catch (error) {
      dispatch(setLoading(false));
      // dispatch(
      //   setMessage({
      //     text: error?.message,
      //     type: AlertEnum.Error,
      //   })
      // );
      return error;
    }
  }
);

export const MedicineOrderCreate = createAsyncThunk(
  "MedicineOrderCreate",
  async (values, { dispatch }) => {
    try {
      const result = await MedicineOrderCreateAPI(values);

      if (result) {
        dispatch(MedicineOrdersData());
        return result?.data;
      } else {
        throw result;
      }
    } catch (error) {
      dispatch(setLoading(false));
      // dispatch(
      //   setMessage({
      //     text: error?.message,
      //     type: AlertEnum.Error,
      //   })
      // );
      return error;
    }
  }
);
export const MedicineOrderDetails = createAsyncThunk(
  "MedicineOrderDetails",
  async (values, { dispatch }) => {
    try {
      const result = await MedicineOrderDetailsAPI({ order_id: values });
      if (result) {
        return result?.data;
      } else {
        throw result;
      }
    } catch (error) {
      dispatch(setLoading(false));
      // dispatch(
      //   setMessage({
      //     text: error?.message,
      //     type: AlertEnum.Error,
      //   })
      // );
      return error;
    }
  }
);
export const MedicineOrdersData = createAsyncThunk(
  "MedicineOrdersData",
  async (values, { dispatch }) => {
    try {
      const result = await MedicineOrdersAPI(values);
      if (result) {
        return result?.data;
      } else {
        throw result;
      }
    } catch (error) {
      dispatch(setLoading(false));
      // dispatch(
      //   setMessage({
      //     text: error?.message,
      //     type: AlertEnum.Error,
      //   })
      // );
      return error;
    }
  }
);

export const OrderCancel = createAsyncThunk(
  "OrderCancel",
  async (values, { dispatch }) => {
    try {
      const result = await OrderCancelAPI(values);
      if (result) {
        dispatch(MedicineOrdersData());
        dispatch(
          setMessage({
            text: result?.message,
            type: AlertEnum.Success,
          })
        );
        return result;
      } else {
        throw result;
      }
    } catch (error) {
      dispatch(setLoading(false));
      // dispatch(
      //   setMessage({
      //     text: error?.message,
      //     type: AlertEnum.Error,
      //   })
      // );
      return error;
    }
  }
);

export const OrderSilce = createSlice({
  name: "OrderSilce",
  initialState,
  reducers: {
    setOrderDetailByID: (state, action) => {
      state.orderDetailByid = action?.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(MedicineOrdersData.fulfilled, (state, action) => {
      state.medicineOrderList = action.payload;
    });
    builder.addCase(MedicineOrderCreate.fulfilled, (state, action) => {
      state.medicineOrderReview = action.payload;
    });
    builder.addCase(MedicineOrderDetails.fulfilled, (state, action) => {
      state.medicineOrderDetail = action.payload;
    });
  },
});

export const { setOrderDetailByID } = OrderSilce.actions;
export default OrderSilce.reducer;
