import React from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { GetConsultDetails } from "../../../../Store/Reducer/BookingSlice";
import {
  SetUpRoom,
  toggleDetails,
  UpdateRoom,
} from "../../../../Store/Reducer/ChatReducer";
import { Icon } from "../../../../Utilities/Icons";
import AudioCall from "../Calling/AudioCall";
import VideoCall from "../Calling/VideoCall";
import BookingDetails from "./BookingDetails";
import Conversations from "./Conversations";
import { GetToken } from "../../../../Store/Reducer/CallingReducer";
export default function ChatIndex() {
  const dispatch = useDispatch();
  const [consultDetails, setConsultDetails] = useState();
  // const { ChatSlice } = useSelector((state) => state);
  const { ChatSlice, ProfileSlice, ConsultSlice } = useSelector(
    (state) => state
  );
  const { room, snapShot, isDetails } = ChatSlice;
  const [videocall, setVideocall] = useState(false);
  const [audiocall, setAudiocall] = useState(false);
  const audioRef = useRef();

  const { booking_id } = useParams();

  const { userProfile } = ProfileSlice;

  useEffect(() => {
    if (booking_id) {
      dispatch(GetConsultDetails({ booking_id: booking_id })).then((res) => {
        if (res?.payload) {
          setConsultDetails(res?.payload);
          dispatch(SetUpRoom(res?.payload));
        }
      });
    }
    return () => {
      snapShot();
    };
  }, [booking_id]);
  return (
    <Container fluid className="completed_consult_chat_container">
      <Container>
        <div className="completed_consult_chat_card_bg">
          <div className="row">
            <div
              className={`${isDetails && "col-lg-8 col-md-7 col-xl-8 col-12	col-sm-12"
                } completed_consultation_right_pading`}
            >
              <div className="completed_consultation_chat_card_head">
                <div className="row">
                  <div className="col-md-6 col-8">
                    <div className="start_consult_user_box">
                      <div>
                        <img
                          src={consultDetails?.doctor_image}
                          className="start_consult_user_img"
                          alt=""
                        ></img>
                      </div>
                      <div>
                        <h5 className="start_consult_user_title">
                          {consultDetails?.doctor_name}
                        </h5>
                        <h6 className="start_consult_user_subtitle">
                          {consultDetails?.doctor_speciality}
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-4">
                    <div className="start_consultation_chat_head_inner">
                      <img
                        src={Icon.chatcall}
                        className="consult_chat_call_icon"
                        disabled={audiocall || videocall}
                        alt=""
                        onClick={() => {
                          dispatch(UpdateRoom({ isCallingStatus: 1 })).then(
                            (res) => {
                              setAudiocall(true);
                              audioRef.current.join();
                            }
                          );
                          dispatch(
                            GetToken({
                              user_id: userProfile?.id,
                              channel_name: `Channel_${userProfile?.id}_${room?.userId}`,
                            })
                          );
                        }}
                      />

                      <img
                        src={Icon.videocall}
                        className="consult_chat_videocall_icon"
                        disabled={audiocall || videocall}
                        onClick={() => {
                          dispatch(UpdateRoom({ isCallingStatus: 1 })).then(
                            (res) => {
                              setVideocall(true);
                            }
                          );
                        }}
                        alt=""
                      />

                      <div>
                        {/* <img
                          src={Icon.more}
                          onClick={(e) => {
                            e.preventDefault();
                            dispatch(toggleDetails(true));
                          }}
                          className="consul_chat_more_icon"
                          alt=""
                        /> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="completed_consultation_chat_card_body">
                {videocall ? (
                  <VideoCall
                    endCall={() => {
                      setVideocall(false);
                    }}
                  />
                ) : audiocall ? (
                  <AudioCall
                    ref={audioRef}
                    endCall={() => {
                      setAudiocall(false);
                    }}
                  />
                ) : (
                  <Conversations
                    roomData={room}
                    consultDetails={consultDetails}
                    audioRef={audioRef}
                  />
                )}
              </div>
            </div>
            {isDetails && <BookingDetails consultDetails={consultDetails} />}
          </div>
        </div>
      </Container>
    </Container>
  );
}
