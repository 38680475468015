import React, { useEffect } from "react";
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Icon } from "../../../Utilities/Icons";
import { useDispatch, useSelector } from "react-redux";
import {
  GetRadioLogyData,
  radiologyaddToCart,
  removeItem,
} from "../../../Store/Reducer/RadiologySlice";
import Checkbox from "../Checkbox";
import { addToCart } from "../../../Store/Reducer/LabtestSlice";


const RadiologicalModel = (props) => {
  const [inputText, setInputText] = useState("");
  const dispatch = useDispatch();
  const { radiologyData, radiologyCart, radiologyTestCenterDetailsById, bookNows } =
    useSelector(({ RadiologySlice }) => RadiologySlice);
  let inputHandler = (e) => {
    //convert input text to lower case
    var lowerCase = e.target.value.toLowerCase(); //.toLowerCase();
    setInputText(lowerCase);
  };
  const filteredData =
    radiologyData?.length &&
    radiologyData?.filter((el) => {
      if (inputText === "") {
        return el;
      }
      //return the item which contains the user input
      else {

        return el?.Testdata?.name.toLowerCase().includes(inputText);
      }
    });
  let test_name = radiologyCart?.map(function (i) {
    return i?.id;
  });


  const [isCheck, setIsCheck] = useState(test_name);

  useEffect(() => {
    setIsCheck(test_name)
  }, [ radiologyCart])
 
  const handleClick = (e) => {
    const { id, checked } = e.target;
    const newNumber = Number(id);
    setIsCheck([...isCheck, newNumber]);
    filteredData?.filter(function (item) {
      if (newNumber == item?.id) {
        dispatch(radiologyaddToCart(item));
      }
    });
    if (!checked) {
      filteredData?.filter(function (item) {
        if (newNumber == item?.id) {
          dispatch(removeItem(item?.id));
        }
      });
      setIsCheck(isCheck.filter((item) => item !== newNumber));
    }
  };

  const {

    cards
  } = useSelector(({ BookingSlice }) => BookingSlice);


  const getTotal = () => {
    let totalQuantity = 0;
    let totalPrice = 0;
    radiologyCart?.forEach((item) => {
      totalPrice += item?.price * item?.quantity;
    });
    return { totalPrice, totalQuantity };
  };

  useEffect(() => {
    dispatch(GetRadioLogyData(radiologyTestCenterDetailsById?.id));
  }, [dispatch, radiologyTestCenterDetailsById?.id]);
  const { labTestList, labCart, labTestCenterDetailsById, list, bookNow } = useSelector(
    ({ LabtestSlice }) => LabtestSlice
  );

  const numberId = Number(bookNows?.id);
  const data = (radiologyData?.length > 0 && bookNows?.id != undefined) && radiologyData?.find((data) => data?.id == bookNows.id)
  const iddata = radiologyCart?.find((data) => data?.id == bookNows.id)

  useEffect(() => {
    setIsCheck([...isCheck, numberId]);

    if (iddata == undefined) {
      dispatch(addToCart(data));
    }
  }, [dispatch, data])
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="lab_test_modal_box"
      backdrop="static"
    >
      <Modal.Header
        className="lab_test_modal_box_head_padding"
        style={{ marginBottom: "none", border: "none" }}
      >
        <h5 className="lab_test_popup_title">Radiological Test</h5>
        <img
          src={Icon.cross}
          className="lab_test_popup_close"
          onClick={() => {
            props.onHide();
          }}
          alt="My Awesome Image"
        ></img>
      </Modal.Header>
      <Modal.Body className="health_radiological_popup_box_body">
        <form>
          <div className="lab_test_modal_box_inner_1">
            <div className="row">
              <div className="col-md-12">
                <img
                  src={Icon.search}
                  className="select_city_search_icon"
                  alt="My Awesome Image"
                ></img>
                <input
                  type="search"
                  className="health_package_input_search"
                  placeholder="Search"
                  onChange={inputHandler}
                />
              </div>
            </div>
          </div>
          <div className="health_radiological_popup_box">
            <div className="lab_test_modal_box_inner_2">
              {
                filteredData && filteredData?.map((item, index) => (
                  <>
                    <div
                      className="health_radiological_popup_list_box"
                      key={index}
                    >
                      <div className="healthradiological">
                        <Checkbox
                          key={item?.id}
                          type="checkbox"
                          name={item?.Testdata?.name}
                          id={item?.id}
                          handleClick={handleClick}
                          isChecked={isCheck?.includes(item?.id)}
                        />
                        <label
                          className="healthpackage_checkbox_title"
                          for={item?.id}
                          style={{
                            display: "inline-flex",
                            alignItems: "center",
                          }}
                        ></label>{" "}
                      </div>
                      <div className="health_radiological_popup_list_box_inner">
                        <div>
                          <img
                            src={`${item?.Testdata?.image}`}
                            height="70px"
                            width="70px"
                            alt="My Awesome Image"
                          ></img>
                        </div>
                        <div>
                          <label for="x-ray">
                            <h3 className="health_package_radiological_test_title">
                              {item?.Testdata?.name}
                            </h3>
                            <h5 className="health_package_radiological_test_subtitle">
                              ₹ {item.price}
                            </h5>
                          </label>
                        </div>
                      </div>
                    </div>
                    <hr className="health_package_lab_text_hr" />
                  </>
                ))}
              {filteredData?.length == 0 && (
                <>
                  <h6> No Radiology Tests Found</h6>
                </>
              )}
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer className="healthpackage_modal_footer_box">
        <div className="lab_test_modal_footer_inner">
          <div className="">
            <h5 className="lab_test_modal_footer_subtitle">Total amount</h5>
            <h3 className="lab_test_modal_footer_title">
              ₹ {isNaN(getTotal().totalPrice) ? 0 : Math.round(getTotal().totalPrice)}
            </h3>
          </div>
          <div className="">
            {getTotal().totalPrice !== 0 && (
              <div className="">
                <Button
                  className="lab_test_modal_footer_btn"
                  type="submit"
                  onClick={() => {
                    props.onNext();
                  }}
                >
                  Next
                </Button>
              </div>
            )}
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default RadiologicalModel;
