import { Field, Formik } from "formik";
import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Icon } from "../../../Utilities/Icons";
import * as Yup from "yup";
import { LabtestCancel } from "../../../Store/Reducer/LabtestSlice";
const LabCancelationReasonModel = (props) => {
  const [cancellationPolicy, setCancellationPolicy] = useState(false);
  const { selectedCancelReasons } = useSelector(
    ({ LabtestSlice }) => LabtestSlice
  );
  const dispatch = useDispatch();

  const ReasonCancellationSchema = Yup.object({
    // cancelationDetail: Yup.object().required(
    //   "Please Select Reason for Cancellation"
    // ),
    termsAndConditions: Yup.bool().oneOf(
      [true],
      "You need to accept the terms and conditions"
    ),
  });


  return (
    <>
      <CancellationPolicyModel
        show={cancellationPolicy}
        onHide={() => setCancellationPolicy(false)}
        setCancelReasonModel={props.onHide}
      />

      <Modal
        {...props}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="modal_476"
        backdrop="static"
      >
        <Modal.Header style={{ border: "none" }}>
          <div className="consultation_cancel_modal_head_box">
            <h5 className="consultation_cancel_modal_title">
              Select Reason for Cancellation
            </h5>
            <h5 className="consultation_cancel_modal_subtitle">
              Can cancel before 2 hours of booking
            </h5>
          </div>
          <img
            src={Icon.cross}
            className="book_modal_close"
            onClick={props.onHide}
          ></img>
        </Modal.Header>
        <Modal.Body>
          <div className="consultation_cancel_modal_body_box">
            <Formik
              initialValues={{
                cancelationDetail: "",
                termsAndConditions: false,
              }}
              validationSchema={ReasonCancellationSchema}
              onSubmit={(values) => {
                let request = {
                  OrderID: selectedCancelReasons?.order_id,
                  ReasonID: values?.cancelationDetail?.id,
                };

                dispatch(LabtestCancel(request)).then((res) => {
                  if (res) {
                    props?.onHide();
                  }
                });
              }}
            >
              {({
                values,
                setFieldValue,
                handleSubmit,
                handleBlur,
                touched,
                errors,
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-12">
                      <div style={{ height: "500px", overflow: "auto" }}>
                        {props?.cancelationReason?.length &&
                          props?.cancelationReason?.map(
                            (cancelation, index) => (
                              <>
                                <div
                                  className="consultation_cancel_modal"
                                  key={index}
                                >
                                  <div className="consultation_cancel_box_inner">
                                    <input
                                      type="radio"
                                      className="mt_10"
                                      name="cancelationDetail"
                                      id={cancelation.ID}
                                      value={cancelation}
                                      onChange={() =>
                                        setFieldValue(
                                          "cancelationDetail",
                                          cancelation
                                        )
                                      }
                                      onBlur={handleBlur}
                                      checked={
                                        values.cancelationDetail.name ===
                                        cancelation?.name
                                      }
                                      style={{accentColor: "#199a8e"}}
                                    />
                                    <lable for={cancelation?.ID}>
                                      <h5 className="consultation_cancel_modal">
                                        {cancelation.name}
                                    
                                      </h5>
                                    </lable>
                                  </div>
                                </div>
                                <hr className="consultation_cancel_modal_hr" />
                              </>
                            )
                          )}
                      </div>
                    </div>
                    {touched.cancelationDetail && errors.cancelationDetail && (
                      <div style={{ color: "red", paddingBottom: "10px" }}>
                        {errors.cancelationDetail}
                      </div>
                    )}
                    <div className="col-md-12">
                      <div>
                        <Field type="checkbox" name="termsAndConditions" />
                        <span className="i_agree_cancellation_policy">
                          I agree with{" "}
                          <a>
                            <span
                              className="cancellation_policy_text"
                              onClick={() => {
                                props.onHide();
                                setCancellationPolicy(true);
                              }}
                            >
                              Cancellation Policy
                            </span>{" "}
                          </a>{" "}
                        </span>
                        {errors.termsAndConditions && (
                          <div className="terms_conditions_error_text">
                            {errors.termsAndConditions}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <center>
                        <Button className="cancel_booking_btn" type="submit">
                          Cancel Booking
                        </Button>
                      </center>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </Modal.Body>
        <Modal.Footer
          style={{ border: "none", display: "none" }}
        ></Modal.Footer>
      </Modal>
    </>
  );
};

const CancellationPolicyModel = (props) => {
  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="modal_476"
    >
      <Modal.Header style={{ border: "none" }}>
        <h5 className="consultation_cancel_modal_title">
          Booking Cancellation Policy
        </h5>
        <img
          src={Icon.cross}
          className="book_modal_close"
          onClick={props.onHide}
        ></img>
      </Modal.Header>
      <Modal.Body>
        <div className="consultation_cancel_modal_body_box">
          <p className="booking_cancellation_policy_text">
          Test oder can be canceled up to 4 hours before the scheduled time. Cancellations made within 24 hours of the scheduled time will not be refunded. In case of an emergency, the appointment can be canceled at any time.but will not refunded it can only be rescheduled.
          </p>
         
          
        </div>
      </Modal.Body>

      <Modal.Footer style={{ border: "none" }}>
        <div className="col-md-12">
          <center>
            <Button
              className="cancel_booking_btn"
              onClick={() => {
                props.onHide();
                props.setCancelReasonModel();
              }}
            >
              Ok
            </Button>
          </center>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default LabCancelationReasonModel;
