import React, { useState } from "react";
import { useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { LabTestCenters, LabtestCities, setLabTestCenterDetailsById } from "../../../Store/Reducer/LabtestSlice";
import { Icon, Logo } from "../../../Utilities/Icons";
import location from '../../../Assets/img/png/location.png'
import CommonSlice, { GetCity } from "../../../Store/Reducer/CommonSlice";
import downloadArrow from '../../../Assets/img/svg/downarrow_solid.svg'
import LabCityModal from "../../Common/LabTestModel/LabCityModal";
import HealthBookingSucessModal from "../../Common/Healthpackagemodal/HealthBookingSucessModal";
import { SpecialityBoking } from "../../../Store/Reducer/RadiologySlice";

const PaymentSummery = (props) => {
    const dispatch = useDispatch();
    const { labTestCenterList, labTestCenterDetailsById, goldMembershipdetail } =
        useSelector(({ LabtestSlice }) => LabtestSlice);
    const { userWallet } = useSelector(({ ProfileSlice }) => ProfileSlice);
    const [walletbox, setwalletbox] = React.useState(userWallet);
    const { goldMembershipStatus, goldMembershipStatus1 } = useSelector(
        ({ RadiologySlice }) => RadiologySlice
    );
    const data = props?.payrelateddata
    const createBooking = (data) => {
        try {
            let tempOptions = {
                ...options,
                amount: Math.round((data.discounted_price * 100)),
                // amount: Math.round((data.discounted_price -
                //     (
                //         ((data.discounted_price * goldMembershipStatus?.discount / 100)))
                // )) * 100,

                plan_id: data?.id,
                currency: "INR",
                name: "THS-User",
                description: "some description",
                image: Logo.THS_logo,
                handler: async function (response) {
                    let updatedValues = {
                        plan_id: data?.id,
                        transaction_id: response.razorpay_payment_id,
                    };
                    dispatch(SpecialityBoking(updatedValues)).then((res) => {

                        setBookingSuccessModel(true);
                    });
                },
            };
            const walletprice = props?.payrelateddata?.discounted_price - (props?.payrelateddata?.discounted_price * goldMembershipStatus?.discount / 100)
          

            let OtherOptions = {
                plan_id: data?.id,
                wallet_amount: walletprice,
                gold_discount: "10",
                total_amount: totalamount,
                total_paid_amount: totalamount,
                transaction_id: 'user_wallet'
            }
            if (totalamount == 0) {
                setBookingSuccessModel(true);
                dispatch(SpecialityBoking(OtherOptions))
            } else {
                openPayModal(tempOptions);
            }
        } catch (error) { }
    };
    const openPayModal = (options) => {
        var rzp1 = new window.Razorpay(options);

        rzp1.open();
    };
    const { session } = useSelector(({ AuthSlice }) => AuthSlice);
    const options = {
        key: process.env.REACT_APP_PAYMENT_KEY,
        key_secret: process.env.REACT_APP_PAYMENT_SECRET_KEY,
        amount: "100", //cretaBookConsultant?.data?.total_price, //  = INR 1
        name: "THS-User",
        description: "some description",
        image: Logo.THS_logo,
        handler: function (response) {
            if (response) {
                setBookingSuccessModel(true);
            }
        },
        prefill: {
            name: session?.name,
            contact: session?.mobile_number,
            email: session?.email,
        },
        notes: {
            address: "some address",
        },
        theme: {
            color: "#F37254",
            hide_topbar: false,
        },
    };

    const [bookingSuccessModel, setBookingSuccessModel] = useState(false);
    const { cretaBookConsultant } = useSelector(
        ({ BookingSlice }) => BookingSlice
    );
    const [totalamount, setTotalAmount] = useState(
        (props?.payrelateddata?.discounted_price -
            (
                ((props?.payrelateddata?.discounted_price * goldMembershipStatus?.discount / 100)))
        )
    );
    useEffect(() => {
        setTotalAmount(props?.payrelateddata?.discounted_price)
    }, [props?.payrelateddata?.discounted_price])
    // useEffect(() => {
    //     setTotalAmount(props?.payrelateddata?.discounted_price - (props?.payrelateddata?.discounted_price * goldMembershipStatus?.discount / 100))
    // }, [props?.payrelateddata?.discounted_price])
    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://checkout.razorpay.com/v1/checkout.js";
        script.async = true;
        document.body.appendChild(script);
    }, []);
    const { radiologyData, radiologyCart, radiologyTestCenterList, specialitybooking } = useSelector(
        ({ RadiologySlice }) => RadiologySlice
    );

    function handleChange(event) {
        setwalletbox((prevFormData) => {
            return {
                ...prevFormData,
                [event.target.name]: event.target.checked,
            };
        });

        if (event.target.checked) {

            const amout = (props?.payrelateddata?.discounted_price -
                (
                    ((props?.payrelateddata?.discounted_price * goldMembershipStatus?.discount / 100)))
            ) - walletbox.wallet;
         
            setTotalAmount(Math.max(isNaN(amout) ? 0 : amout, 0)); // Ensure the minimum value is 0
        } else {
            setTotalAmount(); // Set totalAmount to 0 when not checked
        }
    }

    return (
        <>
            <HealthBookingSucessModal
                show={bookingSuccessModel}
                onHide={() => setBookingSuccessModel(false)}
                specialitybooking={specialitybooking}
            />
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                dialogClassName="lab_test_modal_box"
                backdrop="static"
            >
                <Modal.Header
                    className="lab_test_modal_box_head_padding"
                    style={{ marginBottom: "none", border: "none" }}
                >
                    <h5 className="lab_test_popup_title">Payment Summery</h5>
                    <img
                        src={Icon.cross}
                        className="lab_test_popup_close"
                        onClick={props.onHide}
                        alt=""
                    ></img>
                </Modal.Header>
                <Modal.Body>
                    <div className="subscription_inner_3 ">
                        <div className="payment_summary_card">
                            <div className="payment_summary_card_inner mb_5">
                                <div>
                                    <h5 className="payment_summary_title">Total amount</h5>
                                </div>
                                <div>
                                    <h5 className="payment_summary_price">
                                        {props?.payrelateddata?.discounted_price}
                                    </h5>
                                </div>
                            </div>

                            {
                                goldMembershipdetail == true ? "" : <>
                                    {
                                        goldMembershipdetail && (
                                            <div className="payment_summary_card_inner">
                                                <div>
                                                    <h5 className="payment_done_payment_info_title" style={{ color: "#48988E" }}>
                                                        Gold Discount [{goldMembershipStatus?.discount}%]
                                                    </h5>
                                                </div>
                                                <div>
                                                    <h5 className="payment_summary_price_discount">
                                                        - {" "}
                                                        {(props?.payrelateddata?.discounted_price * goldMembershipStatus?.discount / 100)}
                                                    </h5>
                                                </div>
                                            </div>

                                        )
                                    }</>
                            }

                            <hr className="payment_hr" />
                            <div className="payment_summary_card_inner">
                                <div>
                                    <h5 className="payment_summary_total_title">
                                        Total Payable
                                    </h5>
                                </div>
                                <div>
                                    <h5 className="payment_summary_total_price">
                                        ₹ {totalamount ? parseFloat(totalamount).toFixed(0) : 0}

                                    </h5>
                                </div>
                            </div>
                            {/* <div className="wallet_card">
                                <div>
                                    <div className="form-group">
                                        <input
                                            type="checkbox"
                                            id={walletbox.id}
                                            onChange={handleChange}
                                            name={userWallet?.wallet}
                                            value={walletbox}
                                        />
                                        <label className="wallet_text" for={walletbox.id}>
                                            <span className="wallet_subtext">
                                                Use wallet Amount
                                            </span>
                                        </label>
                                    </div>
                                </div>
                                <div>
                                    <h5 className="available_bal_text">
                                        Avbl. bal.
                                        <span className="available_bal_price">
                                            ₹ {userWallet?.wallet || "0"}
                                        </span>
                                    </h5>
                                </div>
                            </div> */}
                        </div>
                        <center>
                            <Button
                                className="lab_member_modal_btn"
                                onClick={() => { props.onNext(); createBooking(data) }}
                            >
                                Pay
                            </Button>
                        </center>


                    </div>
                </Modal.Body>
            </Modal>
            {/* {isCityModalOpen && <LabCityModal />} */}
        </>
    );
};

export default PaymentSummery;
