import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Logo } from "../../../Utilities/Icons";
import BookingSuccessModel from "../../Common/BookingSuccessModel/BookingSuccessModel";

const Payment = () => {
  const [bookingSuccessModel, setBookingSuccessModel] = useState(false);
  const { session } = useSelector(({ AuthSlice }) => AuthSlice);
  const { cretaBookConsultant } = useSelector(
    ({ BookingSlice }) => BookingSlice
  );

  const options = {
    key: process.env.REACT_APP_PAYMENT_KEY,
    key_secret: process.env.REACT_APP_PAYMENT_SECRET_KEY,
    amount: "100", //cretaBookConsultant?.data?.total_price, //  = INR 1
    name: "THS-User",
    description: "some description",
    image: Logo.THS_logo,
    handler: function (response) {
      if (response) {
        setBookingSuccessModel(true);
      }
      // alert(response.razorpay_payment_id);s
    },
    prefill: {
      name: session?.name,
      contact: session?.mobile_number,
      email: session?.email,
    },
    notes: {
      address: "some address",
    },
    theme: {
      color: "#F37254",
      hide_topbar: false,
    },
  };

  const openPayModal = (options) => {
    var rzp1 = new window.Razorpay(options);
    rzp1.open();
  };
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);

  return (
    <>
      <BookingSuccessModel
        show={bookingSuccessModel}
        onHide={() => setBookingSuccessModel(false)}
        cretaBookConsultant={cretaBookConsultant}
      />

      <div>
        <button onClick={() => openPayModal(options)}>Pay</button>
      </div>
    </>
  );
};

export default Payment;
