import React from "react";
import { Formik, Form, ErrorMessage } from "formik";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import Button from "react-bootstrap/Button";
import { Link, useNavigate } from "react-router-dom";

import { BackGround, Icon, Logo } from "../../../Utilities/Icons.js";
import { MobileSignInSchema } from "../../../Utilities/Schema.js";
import OTPInput from "../../OTPInputs/OTPInput";
import {
  OTPResendSignIn,
  OTPVerifySignIn,
  setSession,
  SignIn,
} from "../../../Store/Reducer/AuthSlice";
import { useTimer } from "../../../Utilities/Hooks";
import { padLeadingZeros } from "../../../Utilities/Functions";

import { SignInEnum } from "../../../Utilities/Enums.js";
import FormControl from "../../Common/Forms/FormControl.js";
import Header from "../../Common/Header.js";
import { params } from "../../../Store/Reducer/RadiologySlice.js";
import { useLocation, } from "react-router-dom";

function Signin() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(params(location.pathname))
  }, [])
  return (
    <>
      <div className="section_1_bg">
        <div className="section_1_container">
          <div className="sub_section_1 js-fullheight">
            <div className="row">
              <div className="col-md-12">
                <div className="display_t">
                  <center class="center_logo mt-5">
                    <img src={Logo.THS_new} className="logo_box" alt="" onClick={() => navigate("/patient")}></img>
                  </center>
                  <div className="slider_1">
                    <OwlCarousel
                      className="owl-theme"
                      loop
                      margin={10}
                      items={1}
                      autoplay
                    >
                      <div className="item">
                        <center>
                          <img
                           
                            src={BackGround.owlsliderbanner}
                            className="owl_banner_img_box"
                            alt=""
                          ></img>
                          <h3 className="slider_text">
                            Book an online appointment with the <br />
                            best doctors with in 60 secs.
                          </h3>
                        </center>
                      </div>
                      <div className="item">
                        <center>
                          <img
                        
                            src={BackGround.owlsliderbanner3}
                            className="owl_banner_img_box"
                            alt=""
                          ></img>
                          <h3 className="slider_text">
                            Book an online appointment with the <br />
                            best doctors with in 60 secs.
                          </h3>
                        </center>
                      </div>
                      <div className="item">
                        <center>
                          <img
                      
                            src={BackGround.owlsliderbanner4}
                            className="owl_banner_img_box"
                            alt=""
                          ></img>
                          <h3 className="slider_text">
                            Book an online appointment with the <br />
                            best doctors with in 60 secs.
                          </h3>
                        </center>
                      </div>
                    </OwlCarousel>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <OTPLogin />
        </div>
      </div>
    </>
  );
}
const OTPLogin = (props) => {
  // const { values } = props;
  const navigate = useNavigate();
  // const { session } = useSelector(({ LayoutSlice }) => LayoutSlice);
  const { message } = useSelector(({ LayoutSlice }) => LayoutSlice);
  // const [cancellationPolicy, setCancellationPolicy] = useState(false);

  const { resetTimer, timer } = useTimer();
  const [verified, setVerified] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    return () => { };
  }, []);

  return (
    <>
      {!verified ? (
        <div className="sub_section_2">
          <div className="row">
            <div className="col-md-12">
              <div className="display_t js-fullheight">
                <div className="row">
                  <div className="col-md-12">
                    <Formik
                      initialValues={
                        (SignInEnum, { termsAndConditions: false })
                      }
                      enableReinitialize
                      validationSchema={MobileSignInSchema}
                      onSubmit={(values) => {
                        if (values.termsAndConditions === true) {
                          dispatch(SignIn(values)).then((res) => {
                            if (res.payload.success) {
                              setVerified(values.mobile_number);
                            }
                          });
                        }
                      }}
                    >
                      {({
                        values,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        errors,
                        touched,
                      }) => (
                        <div className="signup_box">
                          <div className="signup_card">
                            <h3 className="signup_in_title">
                              Sign In / Sign Up
                            </h3>
                            <h5 className="signup_in_subtitle">
                              Welcome to THS!
                            </h5>
                            <div className="signup_form_box">
                              <Form onSubmit={handleSubmit}>
                                <div className="row">
                                  <div className="col-md-12 mb_5">
                                    <div className="input_box">
                                      <div className="form_group">
                                        <FormControl
                                          control="input"
                                          type="text"
                                          name="mobile_number"
                                          className="signup_form_input"
                                          placeholder="Please Enter Mobile Number"
                                          value={values.mobile_number}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          icon={Icon}
                                          maxLength={10}
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-12">
                                    <div className="i_agree_box">
                                      <input
                                        type="checkbox"
                                        id="consult_member_1"
                                        className="vh"
                                        name="termsAndConditions"
                                        value={values.termsAndConditions}
                                        checked={values.termsAndConditions}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      // onChange={setFildValue(
                                      //   "termsAndConditions",
                                      //   values.termsAndConditions
                                      // )}
                                      />
                                      <label for="consult_member_1">
                                        <span className="i_agree_text">
                                          I agree with{" "}
                                          <Link
                                            to="/"
                                            className="term_condition_text"
                                          >
                                            Terms and Conditions
                                          </Link>
                                        </span>
                                      </label>
                                      <ErrorMessage
                                        name="termsAndConditions"
                                        render={(error) => (
                                          <div className="terms_conditions_error_text">
                                            {error}
                                          </div>
                                        )}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-12">
                                    <Button
                                      className="request_otp"
                                      type="submit"
                                    >
                                      Request OTP
                                    </Button>
                                    <ToastContainer />
                                  </div>
                                </div>
                              </Form>
                            </div>
                          </div>
                        </div>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="sub_section_2">
          <div className="row">
            <div className="col-md-12">
              <div className="display_t js-fullheight">
                <div className="row">
                  <div className="col-md-12">
                    <div className="signup_box">
                      <div className="signup_card">
                        <h3 className="verification_code_title">
                          Enter Verification Code
                        </h3>
                        <h5 className="verification_code_subtitle">
                          Enter code that we have sent to your number{" "}
                        </h5>
                        <h5 className="verification_number">
                          +91 {verified} -
                          <Link
                            className="verification_number"
                            onClick={() => {
                              setVerified(false);
                            }}
                          >
                            Edit
                          </Link>
                        </h5>
                        {message.error === true && (
                          <h5 className="verification_code_subtitle">
                            <span className=" error_text">
                              {" "}
                              Please Enter Valid OTP !
                            </span>
                          </h5>
                        )}
                        <div className="otp_box">
                          <Formik
                            initialValues={{ mobile_number: verified, otp: "" }}
                            enableReinitialize
                            onSubmit={(values) => {
                              dispatch(OTPVerifySignIn(values)).then((res) => {
                                if (res?.payload?.hasError === true) {
                                } else if (
                                  Number(
                                    res?.payload?.basic_information_done
                                  ) == 1
                                ) {
                                  navigate("/");
                                  dispatch(setSession(res.payload));
                                } else {
                                  navigate("/signupdetails");
                                  dispatch(setSession(res.payload));
                                }
                              });
                            }}
                          >
                            {({ values, setFieldValue, handleSubmit }) => (
                              <Form onSubmit={handleSubmit}>
                                <div className="row">
                                  <div className="col-md-12">
                                    <center>
                                      <div className="display_inline">
                                        <OTPInput
                                          autoFocus
                                          length={4}
                                          className="otpContainer"
                                          inputClassName="otpInput"
                                          onChangeOTP={(otp) =>
                                            setFieldValue("otp", otp)
                                          }
                                        />
                                      </div>
                                    </center>
                                  </div>
                                  <div className="col-md-12">
                                    <h3 className="receive_the_code mb_30">
                                      Didn’t receive the code?{" "}
                                      {parseInt(timer) ? (
                                        <>
                                          <p className="resend mb_10 ms_30">{`0:${padLeadingZeros(
                                            timer,
                                            2
                                          )}`}</p>
                                        </>
                                      ) : (
                                        <Link
                                          to="/"
                                          className="resend"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            dispatch(
                                              OTPResendSignIn({
                                                mobile_number:
                                                  values?.mobile_number,
                                              })
                                            ).then((res) => {
                                              if (res?.payload?.success)
                                                resetTimer(60);
                                            });
                                          }}
                                        >
                                          Resend
                                        </Link>
                                      )}
                                    </h3>
                                  </div>
                                  <div className="col-md-12">
                                    <Button className="verify" type="submit">
                                      Verify
                                    </Button>
                                  </div>
                                </div>
                              </Form>
                            )}
                          </Formik>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Signin;
