import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import { Icon, Logo } from "../../../Utilities/Icons";
import "../../../Assets/css/responsive.css";
import { useNavigate } from "react-router-dom";
import {
  CancelationReasonList,
  CancelFollowups,
  SpecialityList,
  BookingDetail,
  RescheduleDoctorDetails,
  GetHealthProblems,
  UpcomingFollowups,
  BookConsultant,
  UpcomingBookingList,
  SubscribedPlansList,
  clearRescheduleDoctorDetails,
  clearBookingDetail,
  SelectedPlan,
  setFollowUpPassData,
  cleaSelectedDate,
  SelectedSpecialityId,
  SpecialityWiseHealthProblems,
  clearcoupencode,
} from "../../../Store/Reducer/BookingSlice";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import moment from "moment";
import Speciality from "./Speciality";
import CancelationReasonModel from "../../Common/CancelationReasonModel/CancelationReasonModel";
import SelectBookingDateTime from "../../Common/SelectBookingDateTime/SelectBookingDateTime";
import { GoldMemberBokingstatus, RadiologyUpcomingBookings, bookButtons, radiologyaddToCart } from "../../../Store/Reducer/RadiologySlice";
import onlineConsult from "../../../Assets/img/svg/online consult.svg";
import medicinesicon from "../../../Assets/img/svg/medicinesicon.svg";
import labtesticon from "../../../Assets/img/svg/lab tests.svg";
import wallet from '../../../Assets/img/svg/company.svg'
// import homecareicon from "../../../Assets/img/svg/homecare.svg";
import surgeriesicon from "../../../Assets/img/svg/Surgeries.svg";
// import radiologyondemandicon from "../../../Assets/img/svg/Radiology on Demand.svg";
import Frame from '../../../Assets/img/svg/Frame.png'
import OwlCarousel from "react-owl-carousel";
import SelectSubscribedPlansModel from "../../Common/SelectBookingDateTime/SelectSubscribedPlansModel";
import { useFormik } from "formik";
import BookingSuccessModel from "../../Common/BookingSuccessModel/BookingSuccessModel";
import doctorimagepart from '../../../Assets/img/png/Group 34010.png'
import {
  GetBannerList,
  setActiveKey,
  setClearSpecialityhealthModel,
  setSpecialityhealthModel,
} from "../../../Store/Reducer/CommonSlice";
import SpecialityhealthModel from "./SpecialityhealthModel";
import help from '../../../Assets/img/svg/help.svg'
import { BackGround } from "../../../Utilities/Icons";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { CardHome } from "../../../Store/Reducer/BookingSlice";
import firstimage from "../../../Assets/img/svg/Group 34041.png";
import secondimage from "../../../Assets/img/svg/Group 34042.png";
import thridimage from "../../../Assets/img/svg/Group 34043.png";
import { GoldMemberBookingDetails, addToCart, bookButton } from "../../../Store/Reducer/LabtestSlice";
import { GetMemberList } from "../../../Routes/Service";
import { GetMember, clickCardHome } from "../../../Store/Reducer/ProfileReducer";
import MemberShip from "../GoldMemberShip/MemberShip";
import { bookButtonMedicine, madicineAddToCart } from "../../../Store/Reducer/MedicineSlice";
import PackageDetailModal from "../HealthPackage/PackageDetailModal";
import LabCityModal from "../../Common/LabTestModel/LabCityModal";
import PathologyLab from "../../Common/LabTestModel/PathologyLab";

function DashboardHome(props) {
  const dispatch = useDispatch();
  const [specialityModel, setSpecialityModel] = useState(false);
  const [healthProblemModel, sethealthProblemModel] = useState(false);
  const [cancelationReasonModel, setCancelationReasonModel] = useState(false);
  const [selectBookingDateTimeModel, setSelectBookingDateTimeModel] =
    useState(false);
  const [bookingSuccessModel, setBookingSuccessModel] = useState(false);
  const [selectSubscribedPlansModel, setSelectSubscribedPlansModel] =
    useState(false);
  const [HomeCard, SetHomeCard] = useState('');
  const [memberShip, setMembership] = useState(false);
  const [PackageDetail, SetPAckageDetail] = useState(false);
  const [labcentermodal, setLabcentermodal] = useState(false);
  const [packageId, SetPackageId] = useState();
  const [labcitymodal, setLabcitymodal] = useState(false);
  const [cityModal, setCityModal] = useState(false);
  const [selectedCity, setSelectedCity] = useState("");
  const {
    cancelationReason,
    rescheduleDoctorDetails,
    upcomingBookings,
    specialityList,
    selectedSpecialityId,
    bookingDetail,
    specialityHealthProblemsList,
    upcomingFollowups,
    cards
  } = useSelector(({ BookingSlice }) => BookingSlice);
  const { token } = useSelector(({ AuthSlice }) => AuthSlice);

  useEffect(() => {
    if (token) {
      dispatch(CancelationReasonList());
      dispatch(SpecialityList());
      dispatch(RadiologyUpcomingBookings());
      dispatch(GetHealthProblems());
      dispatch(UpcomingFollowups());
      dispatch(GetBannerList());
    } else {
      dispatch(SpecialityList());
      dispatch(GetHealthProblems());
      dispatch(GetBannerList());
    }
    return () => { };
  }, [token, dispatch]);
  useEffect(() => {
    dispatch(GetMember());
  }, [dispatch])
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await dispatch(CardHome());
        SetHomeCard(result)
      } catch (error) {
      }
    };
    fetchData();
  }, [dispatch]);
  const { labtestUpcomingBookingList, goldMembershipdetail, labCart } = useSelector(
    ({ LabtestSlice }) => LabtestSlice
  );

  const { bannerList } = useSelector(({ CommonSlice }) => CommonSlice);

  const { radiologyUpcomingBookingsList, goldMembershipStatus } = useSelector(
    ({ RadiologySlice }) => RadiologySlice
  );

  const navigate = useNavigate();
  const onSetSpecialityHealthProblemsList = (healthProblems) => {
    localStorage.setItem("selectedHealthId", healthProblems?.id);
    localStorage.setItem("specialityId", healthProblems?.speciality_id);
    navigate(`/booking/${healthProblems?.speciality_id}`);
  };
  const { cretaBookConsultant, subscribedPlansList } = useSelector(
    ({ BookingSlice }) => BookingSlice
  );
  const createBooking = (values) => {
    dispatch(BookConsultant(values)).then((res) => {
      if (res.payload.status_code === 200 && res.payload.success === true) {
        setSelectSubscribedPlansModel(!selectSubscribedPlansModel);
        dispatch(UpcomingBookingList());
        formik?.resetForm();
        setBookingSuccessModel(true);
        dispatch(cleaSelectedDate());
        dispatch(clearcoupencode())
      }
    });
  };


  const formik = useFormik({
    initialValues: {
      health_problem_id: localStorage.getItem("selectedHealthId"),
      slot_id: "",
      doctor_id: "",
      subscription_plan_id: "",
      consultation_member_id: "",
      total_amount: "100",
      total_paid_amount: "100",
      appointment_date: "",
      appointment_time: "",
      payment_type: 1,
      plan_type: "old",
      follow_up_booking_id: "",
      speciality_id: "",
      selectedPlanName: false,
    },
    onSubmit: (values, resetForm) => {
      let specialotyId = localStorage.getItem("specialityId");
      if (values?.selectedPlanName) {
        dispatch(setFollowUpPassData(values));
        navigate(`booking/${specialotyId}`);
        dispatch(SelectedPlan(false));
        dispatch(setActiveKey("subscription_plan"));
      } else {
        createBooking(values, resetForm);
      }
    },
  });
  const onScheduleFollowup = (item) => {
    setSelectBookingDateTimeModel(true);
    dispatch(clearRescheduleDoctorDetails());
    dispatch(clearBookingDetail());
    dispatch(
      RescheduleDoctorDetails({
        doctor_id: item.doctor_id,
        date: item.follow_up_date,
      })
    );

    formik?.setFieldValue("appointment_date", item?.follow_up_date);
    formik?.setFieldValue("doctor_id", item?.doctor_details?.id);
    formik?.setFieldValue(
      "consultation_member_id",
      item?.consultation_member_id
    );
    formik?.setFieldValue("follow_up_booking_id", item?.id);
    formik?.setFieldValue("health_problem_id", item?.health_problem_ids);
    formik?.setFieldValue("speciality_id", item?.speciality_id);
    localStorage.setItem("specialityId", item?.speciality_id);
    localStorage.setItem("selectedHealthId", item?.health_problem_ids);
  };

  function callAddTocart(val) {

    const json = {
      id: val.lab_test_id
    }
    dispatch(bookButton(json))
  }
  function callAddTocarts(val) {

    const json = {
      id: val.lab_test_id
    }
    dispatch(bookButtons(json))
  }



  useEffect(() => {
    if (formik?.values?.speciality_id) {
      dispatch(SubscribedPlansList(formik?.values?.speciality_id));
    }
  }, [formik?.values?.speciality_id, dispatch]);
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 3
    },
    phone: {
      breakpoint: { max: 1400, min: 1005 },
      items: 2,
      slidesToSlide: 2
    },
    tablet: {
      breakpoint: { max: 1004, min: 767 },
      items: 2,
      slidesToSlide: 2
    },
    mobile: {
      breakpoint: { max: 766, min: 499 },
      items: 2,
      slidesToSlide: 1
    },
    mobile: {
      breakpoint: { max: 498, min: 300 },
      items: 1,
      slidesToSlide: 1
    }
  };
  const responsives = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1
    }
  };
  const responsivess = {
    desktop: {
      breakpoint: { max: 3000, min: 1400 },
      items: 2,
      slidesToSlide: 2
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1
    }
  };
  //secondCarousal
  const [carousel, setCarousel] = useState(null);
  const handleNext = () => {
    if (carousel) {
      carousel.next();
    }
  };
  const handlePrevious = () => {
    if (carousel) {
      carousel.previous();
    }
  };
  //first
  const [carousel1, setCarousel1] = useState(null);
  const handleNext1 = () => {
    if (carousel1) {
      carousel1.next();
    }
  };
  const handlePrevious1 = () => {
    if (carousel1) {
      carousel1.previous();
    }
  };
  const [carousel2, setCarousel2] = useState(null);
  const handleNext2 = () => {
    if (carousel2) {
      carousel2.next();
    }
  };
  const handlePrevious2 = () => {
    if (carousel2) {
      carousel2.previous();
    }
  };
  const [carousel3, setCarousel3] = useState(null);
  const handleNext3 = () => {
    if (carousel3) {
      carousel3.next();
    }
  };
  const handlePrevious3 = () => {
    if (carousel3) {
      carousel3.previous();
    }
  };

  const setCoraparte = () => {
    localStorage.setItem("corporate", true);
  }
  const setConsult = () => {
    localStorage.setItem("corporate", false)
  }

  useEffect(() => {
    if (selectedSpecialityId && props.healthProblemModel) {
      dispatch(SpecialityWiseHealthProblems(selectedSpecialityId));
    }
  }, [selectedSpecialityId, props.healthProblemModel, dispatch]);

  const handleClick = (item) => {
    dispatch(SelectedSpecialityId(item?.id));
    localStorage.setItem("specialityId", item?.id);
    dispatch(SpecialityWiseHealthProblems(item?.id));
  };

  const { memberList } = useSelector(
    ({ ProfileSlice }) => ProfileSlice
  );
  const memberIdList = memberList.length > 0 && memberList?.map(member => member.id).join(",");

  useEffect(() => {

    dispatch(GoldMemberBokingstatus(cards?.gold_membership?.id));
  }, [dispatch, cards?.gold_membership?.id])
  useEffect(() => {
    dispatch(GoldMemberBookingDetails());
  }, [dispatch])

  const { packageList } = useSelector(({ PackageSlice }) => PackageSlice);
  function handlePackageItemClick(packageItem) {
    // Store the packageItem array in local storage as a JSON string
    localStorage.setItem("packageItemList", JSON.stringify(packageItem));

  }

  return (
    <>
      <BookingSuccessModel
        show={bookingSuccessModel}
        onHide={() => setBookingSuccessModel(false)}
        cretaBookConsultant={cretaBookConsultant}
      />
      <SelectBookingDateTime
        show={selectBookingDateTimeModel}
        bookingDateTime={rescheduleDoctorDetails?.data}
        bookingDetail={bookingDetail}
        formikProps={formik}
        onHide={() => setSelectBookingDateTimeModel(false)}
        setSelectSubscribedPlansModel={() =>
          setSelectSubscribedPlansModel(true)
        }
      />
      <CancelationReasonModel
        show={cancelationReasonModel}
        cancelationReason={cancelationReason}
        onHide={() => {
          setCancelationReasonModel(!cancelationReasonModel);
        }}
      />
      <SelectSubscribedPlansModel
        show={selectSubscribedPlansModel}
        formik={formik}
        subscribedPlansList={subscribedPlansList}
        onHide={() => {
          setSelectSubscribedPlansModel(!selectSubscribedPlansModel);
        }}
      />
      <MemberShip
        show={memberShip}
        onHide={() => {
          setMembership(false);
        }}
        onNext={() => {
          setMembership(false);
        }}
      />
      <PackageDetailModal
        show={PackageDetail}
        onHide={(e) => {
          SetPAckageDetail(false);
        }}
        packageId={packageId}
        onNext={() => {
          setLabcentermodal(true);
        }}
      />
      <PathologyLab
        show={labcentermodal}
        onNext={() => {
          // setLabdetailmodal(true);
          // setLabtestmodel(true);
          navigate("/helathCart");
          setLabcentermodal(false);
        }}
        onNextCityModal={() => {
          setCityModal(true)
          setLabcentermodal(false)
          setLabcitymodal(true)
        }}
        selectedCitys={selectedCity}
        onHide={(e) => {
          setLabcentermodal(false);
        }}
      />
      {cityModal && <LabCityModal
        show={labcitymodal}
        onNextCityModal={() => {
          // setLabdetailmodal(true);
          // setLabcitymodal(true);
          setLabcentermodal(false);
        }}
        onNext={(selectedCity) => {
          setLabcentermodal(true);
          setLabcitymodal(false);
          setSelectedCity(selectedCity)
          // setLabdetailmodal(true);
        }}
        onHide={(e) => {
          setLabcitymodal(false);
        }}
      />}


      {/* <Container>
        <div className="container">
          <div className="mainClass pt_20">
            <div className="location">
              <div className="inlinediv firstinlinediv">
                <img src={Icon.pngimg} alt="Icon" />
                <p style={{ color: "#303236", fontSize: "14px" }}>Vadodara</p>
              </div>
              <div className="inlinediv" style={{ flex: "1", gap: "10px" }}>
                <img src={Icon.search} alt="Search Icon" />
                <input type="text" placeholder="Search doctors, labs, etc." className="inputtype" />
              </div>
            </div>
            <div className="inlinediv secondfg">
              <div>
                <img src={Icon.location} alt="Location Icon" />
              </div>
              <div>
                <p style={{ color: "#FA662F" }}>Need help?</p>
                <p style={{ color: "#303236" }}>Call us on +918231131311</p>
              </div>
            </div>
          </div>
        </div>
      </Container> */}
      {/* <div className="section_1 pt_60" >
        <Container>
          <div className="row">
            <div className="col-md-12 col-12">
              <div className="slider_2">
                <OwlCarousel
                  className="owl-theme dashboard-owl-slider"
                  loop
                  margin={10}
                  items={1}
                  autoplay
                >
                  {bannerList?.length > 0 &&
                    bannerList?.map((item, index) => (
                      <div className="item" key={index}>
                        <img src={item?.image} alt="" className="benner"></img>
                      </div>
                    ))}
                </OwlCarousel>
              </div>
            </div>
          </div>
        </Container>
      </div> */}
      <div className="">
        <Container>
          <div className="d-flex align-items-center justify-content-center">
            <div className="servicdcv" style={{ width: "600px", height: "50px" }} >
              {/* <div className="d-flex align-items-center justify-content-center mb_20 pt_20"  >
                <hr className="line" />
                <h3 className="health_problem_head">Our Services</h3>
                <hr className="line" />
              </div> */}
            </div>
          </div>
        </Container>
        <Container>
          <div className="ourservices_section_box mt_20">
            <div
              className="consult_now_services_box"
              onClick={() => {
                if (token) {
                  dispatch(clickCardHome({ type: "1" }));
                  navigate("/speciality")
                } else {
                  navigate("/signin")
                } setCoraparte();
              }}
            >
              <div className="row hrLine">
                <div className="col">
                  <div className="consult_text_box">
                    <div className="services">
                      <div className="services-svg-bg1">
                        <img src={onlineConsult} alt=""></img>
                      </div>
                    </div>
                    <h4 className="consult_now_head">Online Consultation</h4>
                    <h6 className="consult_now_subhead">
                      Video call with India's best doctors
                    </h6>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="consult_now_services_box"
              onClick={() => {
                if (token) {
                  dispatch(clickCardHome({ type: "2" }));
                  navigate("/medicines")
                } else {
                  navigate("/signin")
                }
              }}
            >
              <div className="row hrLine">
                <div className="col">
                  <div className="consult_text_box">
                    <div className="services">
                      <div className="services-svg-bg2">
                        <img src={medicinesicon} alt=""></img>
                      </div>
                    </div>
                    <h4 className="consult_now_head">Medicines</h4>
                    <h6 className="consult_now_subhead">
                      Get medicine delivery at your door step
                    </h6>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="consult_now_services_box"
              onClick={() => {

                if (token) {
                  dispatch(clickCardHome({ type: "4" }))
                  navigate("/labtestpackage");
                  localStorage.setItem("labradiologytest", "labtest");
                } else {
                  navigate("/signin")
                }

              }}
            >
              <div className="row hrLine">
                <div className="col">
                  <div className="consult_text_box">
                    <div className="services">
                      <div className="services-svg-bg3">
                        <img src={labtesticon} alt=""></img>
                      </div>
                    </div>
                    <h4 className="consult_now_head">Lab Tests</h4>
                    <h6 className="consult_now_subhead">
                      Book lab test at your home
                    </h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="consult_now_services_box"
              onClick={() => {
                if (token) {
                  dispatch(clickCardHome({ type: "6" }))
                  navigate("/healthcare")
                } else {
                  navigate("/signin");
                } setCoraparte();
              }}
            >
              <div className="row hrLine">
                {/* <a href="https://thsindia.in/home-health-care" target="_blank"> */}
                <div
                  className="col"
                >
                  <div className="consult_text_box">
                    <div className="services">
                      <div className="services-svg-bg4">
                        <img src={labtesticon} alt=""></img>
                      </div>
                    </div>
                    <h4 className="consult_now_head">Homecare</h4>
                    <h6 className="consult_now_subhead">
                      Get treated at home
                    </h6>
                  </div>
                </div>
                {/* </a> */}
              </div>
            </div>
            <div className="consult_now_services_box">
              <div className="row hrLines">
                {/* <a href="https://thsindia.in/surgery/" target="_blank"> */}
                <div className="col"
                  onClick={() => {
                    if (token) {
                      navigate("/surgery");
                      localStorage.setItem("labradiologytest", "radiologytest");
                    } else {
                      navigate("/signin")
                    }

                  }}>
                  <div className="consult_text_box">
                    <div className="services">
                      <div className="services-svg-bg5">
                        <img src={surgeriesicon} alt=""></img>
                      </div>
                    </div>
                    <h4 className="consult_now_head">Surgery</h4>
                    <h6 className="consult_now_subhead">Access best doctors from the best hospitals
                    </h6>
                  </div>
                </div>
                {/* </a> */}
              </div>
            </div>
            <div className="consult_now_services_box">
              <div className="row">
                <div className="col"
                  onClick={() => {
                    if (token) {
                      dispatch(clickCardHome({ type: "3" }));
                      navigate("/radiology");
                      localStorage.setItem("labradiologytest", "radiologytest");
                    } else {
                      navigate("/signin")
                    }

                  }}
                >
                  <div className="consult_text_box">
                    <div className="services">
                      <div className="services-svg-bg6">
                        <img src={surgeriesicon} alt=""></img>
                      </div>
                    </div>
                    <h4 className="consult_now_head">Radiology </h4>
                    <h6 className="consult_now_subhead">
                      Xray, CT Scan, MRI
                    </h6>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </Container >

        <Container>
          <h3 className="follow_up_consultation_title pt_30">Our Speciality</h3>
          <div className="healthmodal">
            <div className="heath_problem_list_box_modal">
              {specialityList?.length &&
                specialityList?.slice(0, 10).map((item, index) => (
                  <div
                    key={`${item?.id + index}`}
                    className="heath_problem_list_box_model_map_sub"
                    onClick={() => {
                      {
                        token ?
                          navigate("/health") : navigate("/signin");
                      } handleClick(item);
                    }}
                  >
                    <div className="healthproblem_icon_bg_modal">
                      <center>
                        <img
                          src={item?.image}
                          className="healthproblem_icon"
                          alt=""
                        ></img>
                      </center>
                    </div>
                    <h5 className="healthproblem_text">{item?.speciality}</h5>
                  </div>
                ))}
            </div>
          </div>
          <div className="d-flex justify-content-center pt_20">
            <Button onClick={() => {
              {
                token ? navigate("/speciality") : navigate("/signin");
              }
              setCoraparte();
            }}>View More</Button>
          </div>
        </Container>

        <div className="pt_20">
          <Container>
            <div className="maingolddivs d-flex pt_20" style={{ width: "100%", gap: "30px" }}>
              {
                goldMembershipdetail && goldMembershipdetail == true ?
                  <>
                    <div className="first" style={{ height: "100%", width: "50%" }} onClick={() => { setMembership(true) }}>
                      <div className="goldMainpage">
                        <div className="mainflexdiv">
                          <div>

                            <div className="d-flex imgflexdiv" style={{ gap: "20px" }}>
                              <img src={Icon.the} alt="Image The" className="firstimg" style={{ height: "40px" }} />
                              <img src={Icon.golden} alt="Image Golden" className="seimg" style={{ height: "60px" }} />
                            </div>
                            <p className="Golden_text pt_5" >{goldMembershipStatus?.small_description}</p>
                          </div>
                          <div className="Gold_btn_text ">
                            <button className="Gold_btnes">Join Gold ₹ {goldMembershipStatus?.price} / yr.</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </> : ""

              }

              {
                HomeCard?.payload?.btob_subscriptions == null ? "" : <>
                  <div className="secondDiv " style={{ width: "50%" }}>
                    <div className="paddingDiv">
                      <div className="svgflex">
                        <div className="svgContent">
                          <img src={wallet}></img>
                          <div>
                            <h2 className="consultText">{HomeCard && HomeCard?.payload?.btob_subscriptions?.company?.company_name}</h2>
                            <h5 className="consultText1">{HomeCard && HomeCard?.payload?.btob_subscriptions?.title}</h5>
                            <p className="consultText2" style={{ paddingTop: "20px", width: "350px" }}>{HomeCard && HomeCard?.payload?.btob_subscriptions?.description} family members can use this plan.</p>
                          </div>
                        </div>
                        <div>
                          <button className="ConsultBtns" onClick={() => { navigate("/speciality"); setConsult(); }} >Consult Now</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              }
            </div>
          </Container>
        </div>
        <div>
          <Container>
            <h3 className="follow_up_consultation_title pt_30 ">Health Package</h3>
            <div className="packageList row " style={{ flexWrap: "wrap", justifyItems: "center" }}>

              {cards?.healthpackages?.map((packageItem, index) => (
                <div key={index} className=" col-xs-6  col-sm-6 col-lg-3 col-xs-6 col-md-4" style={{ marginBottom: '10px' }} onClick={() => handlePackageItemClick(packageItem)}>
                  <div className="shadowclass1" onClick={() => { SetPAckageDetail(true); SetPackageId(packageItem) }}>
                    <div className="shadowclassimg1" >
                      <img src={packageItem?.image} className="shadowclassimg1" alt="Package" />
                    </div>
                    <div className="pt_10" style={{ height: "130px" }}>
                      <h3 className="subtext1">{packageItem?.package_title}</h3>
                      <p className="subTect23" style={{ color: "#303236", paddingTop: "5px" }}>{packageItem?.package_description}</p>
                      <p className="subtext " style={{ marginTop: "0px" }}>₹{packageItem?.discount_amount}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </Container>
        </div>
        <div>
          <Container>
            {(upcomingBookings?.length > 0) ? (
              <div className="follow_up_consultation_box">
                <Container>
                  <h3 className="follow_up_consultation_title">
                    Our Services
                  </h3>
                  <div className="follow_up_upcoming_consultation_box">
                    {upcomingBookings?.length > 0 &&
                      upcomingBookings
                        ?.slice(0)
                        .reverse()
                        .map((upcomingBooking, index) => {
                          return index + 1 <= 2 ? (
                            <>
                              <div className="follow_up_consult_width">
                                <div
                                  onClick={() =>
                                    navigate(`/chat/${upcomingBooking?.id}`)
                                  }
                                  className={`follow_up_upcoming_consultation_box_head`}
                                >
                                  <h3
                                    className={`follow_up_upcoming_consultation_box_head_text`}
                                  >
                                    <img
                                      src={Icon.scheduled}
                                      className="subscription_package_consultation_card_head_icon"
                                      alt=""
                                    ></img>
                                    Scheduled on{" "}
                                    {moment(
                                      upcomingBooking?.appointment_date
                                    ).format("DD-MM-YYYY")}{" "}
                                    at{" "}
                                    {
                                      moment(
                                        upcomingBooking?.appointment_time,
                                        "HH:mm"
                                      ).format("h:mm A")
                                    }

                                  </h3>
                                </div>
                                <div className="follow_up_upcoming_consultation_box_body">
                                  <div className="row">
                                    <div className="col-md-12">
                                      <div className="subscription_package_box">
                                        <div>
                                          <img
                                            src={
                                              upcomingBooking?.doctor_details
                                                ?.image
                                            }
                                            className="subscription_package_box_doc_img"
                                            alt=""
                                          ></img>
                                        </div>
                                        <div>
                                          <h5 className="subscription_package_box_toptitle">
                                            Appointment with
                                          </h5>
                                          <h5 className="subscription_package_box_title">
                                            {
                                              upcomingBooking?.doctor_details
                                                ?.name
                                            }
                                          </h5>
                                          <h5 className="subscription_package_box_subtitle">
                                            For{" "}
                                            {
                                              upcomingBooking?.patients_details
                                                ?.name
                                            }
                                          </h5>
                                          <h5 className="subscription_package_box_endtitle">
                                            Problem :
                                            {
                                              upcomingBooking?.health_problems_name
                                            }
                                          </h5>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <hr />
                                  <div className="row">
                                    <div className="col-md-12">
                                      <div className="consultation_option_box" style={{ marginTop: "0px" }}>
                                        <div
                                          className="consultation_option_box_inner_1"
                                          onClick={() => {
                                            setCancelationReasonModel(true);
                                            dispatch(BookingDetail(upcomingBooking));
                                          }}
                                        >
                                          <img
                                            src={Icon.cancel}
                                            className="cancel_icon"
                                            alt=""
                                          ></img>{" "}
                                          <span className="cancel_appointment">
                                            Cancle Appointment
                                          </span>
                                        </div>
                                        <div
                                          className="consultation_option_box_inner_2"
                                          onClick={() => {
                                            setSelectBookingDateTimeModel(true);
                                            dispatch(
                                              RescheduleDoctorDetails({
                                                doctor_id: upcomingBooking.doctor_id,
                                                date: upcomingBooking.appointment_date,
                                              })
                                            );
                                            dispatch(BookingDetail(upcomingBooking));
                                          }}
                                        >
                                          <img
                                            src={Icon.reschedule}
                                            className="reschedule_icon"
                                            alt=""
                                          ></img>{" "}
                                          <span className="reschedule_title">
                                            Reschedule
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : null;
                        })}

                  </div>
                </Container>
                <Container>
                  {upcomingFollowups?.length > 0 &&
                    upcomingFollowups
                      ?.slice(0)
                      .map((item, index) => {
                        return index + 1 <= 1 ? (
                          <>
                            <Container>
                              <h3 className="follow_up_consultation_title" style={{ paddingTop: "50px" }}>
                                Upcoming Followup Consultation
                              </h3>
                              <div className="follow_up_upcoming_followup_box follow_up_consult_width">
                                <div className="follow_up_upcoming_followup_box_head">
                                  <h3 className="follow_up_upcoming_followup_box_head_text">
                                    <img
                                      src={Icon.scheduled_green}
                                      className="subscription_package_consultation_card_head_icon"
                                      alt=""
                                    ></img>
                                    Upcoming followup on{" "}
                                    {moment(item?.follow_up_date).format(
                                      "DD-MM-YYYY"
                                    )}{" "}
                                  </h3>
                                </div>
                                <div className="follow_up_upcoming_consultation_box_body">
                                  <div className="row">
                                    <div className="col-md-12" style={{ borderBottom: "1px solid #E2E2E6" }}>
                                      <div className="subscription_package_box">
                                        <div>
                                          <img
                                            src={item?.doctor_details?.image}
                                            className="subscription_package_box_doc_img"
                                            alt=""
                                          ></img>
                                        </div>
                                        <div>
                                          <h5 className="subscription_package_box_toptitle">
                                            Appointment with
                                          </h5>
                                          <h5 className="subscription_package_box_title">
                                            Dr. {item?.doctor_details?.name}
                                          </h5>
                                          <h5 className="subscription_package_box_subtitle">
                                            {" "}
                                            For {item?.patient_details?.name}
                                          </h5>
                                          <h5 className="subscription_package_box_endtitle">
                                            Problem : {item?.health_problems_name}
                                          </h5>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-12">
                                      <div className="follow_up_upcoming_followup_box_inner">
                                        <div
                                          onClick={() =>
                                            dispatch(CancelFollowups(item?.id))
                                          }
                                        >
                                          <h3 className="follow_up_schedule_remove_text">

                                            Not Now
                                          </h3>
                                        </div>
                                        <div
                                          onClick={() => {
                                            onScheduleFollowup(item);
                                          }}
                                        >
                                          <h3 className="follow_up_schedule_followup_text">
                                            <img
                                              src={Icon.add}
                                              className="follow_up_schedule_followup_icon"
                                              alt=""
                                            ></img>
                                            Schedule Followup
                                          </h3>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Container>
                          </>
                        ) : null;
                      })}
                </Container>
              </div>
            ) : null}
            <div className="follow_up_test_box">
              <Container>
                {
                  cards?.my_prescription?.length > 0 && (
                    <h3 className="follow_up_test_title">
                      My prescription
                    </h3>
                  )}
                <div className="follow_up_upcoming_consultation_box">
                  {cards?.my_prescription?.length > 0 &&
                    cards?.my_prescription?.map((labtestupcomingBooking, index) => {
                      return (
                        <>
                          {
                            labtestupcomingBooking?.prescription_data?.Radiological_test?.length > 0 &&
                            labtestupcomingBooking?.prescription_data?.Radiological_test?.map((val, index) => {
                              return (
                                <>
                                  <div className="follow_up_test_widths">
                                    <div className="follow_up_test_box_card">
                                      <span className="follow_up_test_box_tag">
                                        Radiology
                                      </span>
                                      <h3 className="follow_up_test_box_title pt_5">
                                        - {val?.lab_test_name}
                                      </h3>
                                      <div className="pt_10">
                                        <Button onClick={() => { navigate('/radiology'); callAddTocarts(val) }}>Book Now</Button>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )
                            })
                          }
                          {
                            labtestupcomingBooking?.prescription_data?.lab_test?.length > 0 &&
                            labtestupcomingBooking?.prescription_data?.lab_test?.map((val, index) => {
                              return (
                                <>
                                  <div className="follow_up_test_widths">
                                    <div className="follow_up_test_box_card">
                                      <span className="follow_up_test_box_tag">
                                        Lab Test
                                      </span>
                                      <h3 className="follow_up_test_box_title pt_5">
                                        - {val?.lab_test_name}
                                      </h3>
                                      <div className="pt_10">
                                        <Button onClick={() => { navigate('/labtestpackage'); callAddTocart(val) }}>Book Now</Button>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )
                            })
                          }
                          {
                            labtestupcomingBooking?.medicines?.length > 0 &&
                            labtestupcomingBooking?.medicines?.map((val, index) => {
                              return (
                                <>
                                  <div className="follow_up_test_widths">
                                    <div className="follow_up_test_box_card">
                                      <span className="follow_up_test_box_tag">
                                        Medicine
                                      </span>
                                      <h3 className="follow_up_test_box_title pt_5">
                                        - {val?.medicine_name}
                                      </h3>

                                      <div className="pt_10">
                                        <Button onClick={() => { navigate(`/medicinesdetails/${val?.medicine_id}`) }}>Order Now</Button>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )
                            })
                          }
                        </>
                      );
                    })}
                </div>
              </Container>
            </div>
            <div className="follow_up_test_box">
              <Container>
                {
                  labtestUpcomingBookingList?.length > 0 && (
                    <h3 className="follow_up_test_title">
                      Lab Test
                    </h3>
                  )}
                <div className="follow_up_upcoming_consultation_box">
                  {labtestUpcomingBookingList?.length > 0 &&
                    labtestUpcomingBookingList
                      ?.slice(0)
                      .map((labtestupcomingBooking, index) => {
                        return index + 1 <= 2 ? (
                          <div className="follow_up_test_width"
                            onClick={() => {
                              navigate(
                                `/lab-test-detail/${labtestupcomingBooking?.order_id}`
                              );
                            }}
                          >
                            <div className="follow_up_test_box_card">
                              <span className="follow_up_test_box_tag">
                                Lab Test
                              </span>
                              <h3 className="follow_up_test_box_title">
                                {labtestupcomingBooking?.test_name}
                              </h3>
                              <h5 className="follow_up_test_for_client">
                                For {labtestupcomingBooking?.member_details?.name}
                              </h5>
                              <h5 className="follow_up_test_by_doc">

                                {/* by Dr. Shreeja */}
                              </h5>
                              <div className="follow_up_test_time_status_box">
                                <div>
                                  <h3 className="follow_up_test_time_title">
                                    Time
                                  </h3>
                                  <h5 className="follow_up_test_time">
                                    {moment(
                                      labtestupcomingBooking?.booking_date
                                    ).format("DD MMM, YYYY")}{" "}
                                    at{" "}
                                    {
                                      moment(
                                        labtestupcomingBooking?.booking_time,
                                        "HH:mm"
                                      ).format("h:mm A")
                                    }
                                  </h5>
                                </div>
                                <div>
                                  <h3 className="follow_up_test_status">
                                    Status
                                  </h3>
                                  <h5 className="follow_up_test_status_name">
                                    Booking Confirm
                                  </h5>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : null;
                      })}
                </div>
              </Container>
            </div>
            <div className="follow_up_test_box">
              <Container>

                {
                  radiologyUpcomingBookingsList?.length > 0 && (
                    <h3 className="follow_up_test_title">
                      Radiologiy Test
                    </h3>
                  )}
                <div className="follow_up_upcoming_consultation_box">
                  {radiologyUpcomingBookingsList?.length > 0 &&
                    radiologyUpcomingBookingsList
                      ?.slice(0)
                      .map((radiologyupcomingBooking, index) => {
                        return index + 1 <= 2 ? (
                          <div className="follow_up_test_width"
                            onClick={() =>
                              navigate(`/radiology-test-detail/${radiologyupcomingBooking?.id}`)
                            }
                          >
                            <div className="follow_up_test_box_card">
                              <span className="follow_up_test_box_tag">

                                Prescribed by Doctor
                              </span>

                              <h3 className="follow_up_test_box_title">
                                {" "}
                                {
                                  radiologyupcomingBooking?.test_data[0]
                                    ?.test_name
                                }
                              </h3>

                              <h5 className="follow_up_test_for_client">
                                For{" "}
                                {radiologyupcomingBooking?.member_details?.name}
                              </h5>
                              <h5 className="follow_up_test_by_doc">
                                {/* by Dr. Shreeja */}
                              </h5>
                              <div className="follow_up_test_time_status_box">
                                <div>
                                  <h3 className="follow_up_test_time_title">
                                    Time
                                  </h3>
                                  <h5 className="follow_up_test_time">
                                    {moment(
                                      radiologyupcomingBooking?.booking_date
                                    ).format("DD MMM, YYYY")}{" "}
                                    at{" "}
                                    {
                                      moment(
                                        radiologyupcomingBooking?.booking_time,
                                        "HH:mm"
                                      ).format("h:mm A") // "14:00"
                                    }
                                  </h5>
                                </div>
                                <div>
                                  <h3 className="follow_up_test_status">
                                    Status
                                  </h3>
                                  <h5 className="follow_up_test_status_name">
                                    Booking Confirm
                                  </h5>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : null;
                      })}
                </div>
              </Container>
            </div>
          </Container>
        </div>
        {/* <div className="pb_20">
          <Container>
            <div className="">
              <div className="maincarusal d-flex">
                <h3 className="follow_up_consultation_title">Health Packages</h3>
                <div className="d-flex" style={{ gap: "10px" }}>
                  <div onClick={handleNext1}>
                    <img src={Icon.rightside} alt="Next" />
                  </div>
                  <div onClick={handlePrevious1}>
                    <img src={Icon.leftside} alt="Previous" />
                  </div>
                </div>
              </div>
            </div>
            <div className="" style={{ marginBottom: "80px" }}>
              <Carousel
                swipeable={false}
                draggable={false}
                responsive={responsive}
                infinite={true}
                autoPlaySpeed={1000}
                arrows={false}
                ref={setCarousel1}
              >
                <div>
                  <div className="shadowclass">
                    <img src={BackGround.owlslider} className="shadowclassimg" ></img>
                    <div className="pt_10">
                      <h3 className="subtext1">Female health</h3>
                      <p className="subTect23" style={{ color: "#303236", paddingTop: "5px" }}>Starting from </p>
                      <p className="subtext" >₹499</p>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="shadowclass">
                    <img src={BackGround.owlslider} className="shadowclassimg" ></img>
                    <div className="pt_10">
                      <h3 className="subtext1">Female health</h3>
                      <p className="subTect23" style={{ color: "#303236", paddingTop: "5px" }}>Starting from </p>
                      <p className="subtext" >₹499</p>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="shadowclass">
                    <img src={BackGround.owlslider} className="shadowclassimg" ></img>
                    <div className="pt_10">
                      <h3 className="subtext1">Female health</h3>
                      <p className="subTect23" style={{ color: "#303236", paddingTop: "5px" }}>Starting from </p>
                      <p className="subtext" >₹499</p>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="shadowclass">
                    <img src={BackGround.owlslider} className="shadowclassimg" ></img>
                    <div className="pt_10">
                      <h3 className="subtext1">Female health</h3>
                      <p className="subTect23" style={{ color: "#303236", paddingTop: "5px" }}>Starting from </p>
                      <p className="subtext" >₹499</p>
                    </div>
                  </div>
                </div>
              </Carousel>
            </div>
          </Container>
        </div> */}
        {/* <div className="mb_50">
          <Container>
            <div className="">
              <div className="maincarusal d-flex">
                <h3 className="follow_up_consultation_title">Subscription Plans</h3>
                <div className="d-flex" style={{ gap: "10px" }}>
                  <div onClick={handleNext}>
                    <img src={Icon.rightside} alt="Next" />
                  </div>
                  <div onClick={handlePrevious}>
                    <img src={Icon.leftside} alt="Previous" />
                  </div>
                </div>
              </div>
            </div>
            <div className="mb_20">
              <Carousel
                swipeable={false}
                draggable={false}
                responsive={responsive}
                infinite={true}
                autoPlaySpeed={1000}
                arrows={false}
                ref={setCarousel}
              >
                <div className="shadowclass">
                  <img src={Icon.ismage} className="shadowclassimg"></img>
                  <div className="pt_10">
                    <h3 className="subtext1">20 Consultations</h3>
                    <p className="subTect2">₹ 5000</p>
                    <p className="subtext">₹1499</p>
                  </div>
                </div>
                <div className="shadowclass">
                  <img src={Icon.ismage} className="shadowclassimg"></img>
                  <div className="pt_10">
                    <h3 className="subtext1">20 Consultations</h3>
                    <p className="subTect2">₹ 5000</p>
                    <p className="subtext">₹1499</p>
                  </div>
                </div>
                <div className="shadowclass">
                  <img src={Icon.ismage} className="shadowclassimg"></img>
                  <div className="pt_10">
                    <h3 className="subtext1">20 Consultations</h3>
                    <p className="subTect2">₹ 5000</p>
                    <p className="subtext">₹1499</p>
                  </div>
                </div>
                <div className="shadowclass">
                  <img src={Icon.ismage} className="shadowclassimg"></img>
                  <div className="pt_10">
                    <h3 className="subtext1">20 Consultations</h3>
                    <p className="subTect2">₹ 5000</p>
                    <p className="subtext">₹1499</p>
                  </div>
                </div>
              </Carousel>
            </div>
          </Container>
        </div> */}
        {/* <div className="doctorpart">
          <Container>
            <div className="doctorparetdf">
              <div>
                <h4 className="doctortext">Meet Our Doctors!</h4>
                <h2 className="doctormaintext pt_10">Meet certificated <br />
                  and high qualified doctors</h2>
                <p className="subtextdoctor pt_20">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
                <div className="startflex d-flex " style={{ gap: "30px" }}>
                  <div>
                    <div className="d-flex" style={{ gap: "5px" }}>
                      <img src={Icon.start} className="imagfuil"></img>
                      <p className="starttext">Easy Online Consultations</p>
                    </div>
                    <div className="d-flex" style={{ gap: "5px" }}>
                      <img src={Icon.start} className="imagfuil"></img>
                      <p className="starttext">Easy Online Consultations</p>
                    </div>
                  </div>
                  <div>
                    <div className="d-flex" style={{ gap: "5px" }}>
                      <img src={Icon.start} className="imagfuil"></img>
                      <p className="starttext">Easy Online Consultations</p>
                    </div>
                    <div className="d-flex" style={{ gap: "5px" }}>
                      <img src={Icon.start} className="imagfuil"></img>
                      <p className="starttext">Easy Online Consultations</p>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <img src={doctorimagepart} className="dicimage"></img>
              </div>
            </div>
          </Container>
        </div> */}
        {/* <div className='pt_50'>
          <Container>
            <div className="">
              <div className="maincarusal d-flex">
                <h3 className="follow_up_consultation_title">Latest News & Articles</h3>
                <div className="d-flex" style={{ gap: "10px" }}>
                  <div onClick={handleNext2}>
                    <img src={Icon.rightside} alt="Next" />
                  </div>
                  <div onClick={handlePrevious2}>
                    <img src={Icon.leftside} alt="Previous" />
                  </div>
                </div>
              </div>
            </div>
            <div className="mb_20">
              <Carousel
                swipeable={false}
                draggable={false}
                responsive={responsives}
                infinite={true}
                autoPlaySpeed={1000}
                arrows={false}
                ref={setCarousel2}
              >
                <div className="latestNews" style={{ marginRight: "20px", marginBottom: "20px" }}>
                  <div>
                    <img src={BackGround.bgimage} style={{ width: "100%" }}></img>
                    <div className="pt_20" style={{ paddingLeft: "20px", paddingRight: "30%" }}>
                      <button className="latestOne">
                        <p className="latestOnetext bvg" >20 Mar 2021</p>
                      </button>
                      <h3 className="latestTwo">Get to know about the
                        COVID-19 Vaccine</h3>
                    </div>
                    <div className="latestDiv">
                      <p className="latestparagraph" >Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard ...</p>
                      <p className="latestOnetext bvg">Read more &nbsp;&nbsp;
                        <img src={Frame}></img>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="latestNews" style={{ marginRight: "20px", marginBottom: "20px" }}>
                  <div>
                    <img src={BackGround.bgimage} style={{ width: "100%" }}></img>
                    <div className="pt_20" style={{ paddingLeft: "20px", paddingRight: "30%" }}>
                      <button className="latestOne">
                        <p className="latestOnetext bvg" >20 Mar 2021</p>
                      </button>
                      <h3 className="latestTwo">Get to know about the
                        COVID-19 Vaccine</h3>
                    </div>
                    <div className="latestDiv">
                      <p className="latestparagraph" >Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard ...</p>
                      <p className="latestOnetext bvg">Read more &nbsp;&nbsp;
                        <img src={Frame}></img>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="latestNews" style={{ marginRight: "20px", marginBottom: "20px" }}>
                  <div>
                    <img src={BackGround.bgimage} style={{ width: "100%" }}></img>
                    <div className="pt_20" style={{ paddingLeft: "20px", paddingRight: "30%" }}>
                      <button className="latestOne">
                        <p className="latestOnetext bvg" >20 Mar 2021</p>
                      </button>
                      <h3 className="latestTwo">Get to know about the
                        COVID-19 Vaccine</h3>
                    </div>
                    <div className="latestDiv">
                      <p className="latestparagraph" >Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard ...</p>
                      <p className="latestOnetext bvg">Read more &nbsp;&nbsp;
                        <img src={Frame}></img>
                      </p>
                    </div>
                  </div>
                </div>
              </Carousel>
            </div>
          </Container>
        </div> */}
        {/* <div
          className=""
          style={{
            background: "linear-gradient(to right, #E7F5F0 45%, #FFFFFF 45%)",
            borderRadius: "18px",
            marginBottom: "80px",
            padding: "50px"
          }}
        >
          <Container>
            <div className="reviewcarousal" >
              <div>
                <p className="texth2">Testimonial</p>
                <h1 className="texth3">Reviews from our loyal <br /> customer</h1>
              </div>
              <div className="d-flex" style={{ gap: "10px" }}>
                <div onClick={handleNext3}>
                  <img src={Icon.rightside} alt="Next" />
                </div>
                <div onClick={handlePrevious3}>
                  <img src={Icon.leftside} alt="Previous" />
                </div>
              </div>

            </div>
            <Carousel
              swipeable={false}
              draggable={false}
              responsive={responsivess}
              infinite={true}
              autoPlaySpeed={1000}
              arrows={false}
              ref={setCarousel3}
            >
              <div className="reviewmain reviewtextp">
                <div>
                  <h3 className="reviewtext smoothtext">“Smooth online consultation with
                    experienced doctor”</h3>
                  <p className="reviewtextp loremp">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard  is simply dummy text of the printing and typesetting  ...</p>
                  <div className="d-flex " style={{ gap: "5px" }}>
                    <div>
                      <img src={Icon.docprofile}></img>
                    </div>
                    <div>
                      <h3 className="reviewtext">Jenny</h3>
                      <p className="reviewtextp">Patient</p>
                    </div>

                  </div>
                </div>
              </div>
              <div className="reviewmain">
                <div>
                  <h3 className="reviewtext" style={{ paddingRight: "30%" }}>“Incredible consultation and very fast
                    medicine delivery”</h3>
                  <p className="reviewtextp" style={{ paddingRight: "15%" }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard  is simply dummy text of the printing and typesetting  ...</p>
                  <div className="d-flex" style={{ gap: "5px" }}>
                    <div>
                      <img src={Icon.docprofile}></img>
                    </div>
                    <div>
                      <h3 className="reviewtext">Erika</h3>
                      <p className="reviewtextp">Patient</p>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel>
          </Container>
        </div> */}
        {/* <div className="partnermain">
          <Container>
            <div className="text-center" >
              <h3 className="follow_up_consultation_titles">Our Partners</h3>
            </div>
            <div className="logo">
              <div>
                <img src={firstimage}></img>
              </div>
              <div>
                <img src={secondimage}></img>
              </div>
              <div>
                <img src={thridimage}></img>
              </div>
            </div>
          </Container>
        </div> */}
        {/* <div className="footermainclass">
          <Container>
            <div className="footermain">
              <div>
                <img src={Logo.THS_FOOTER}></img>
              </div>
              <div>
                <h2 className="footerText">Primary Service</h2>
                <p className="footerText1">Online Consultation</p>
                <p className="footerText1">Medicines</p>
                <p className="footerText1">Lab Tests</p>
                <p className="footerText1">Homecare</p>
                <p className="footerText1">Surgeries</p>
                <p className="footerText1">Radiology on Demand</p>
              </div>
              <div>
                <h2 className="footerText">Doctor</h2>
                <p className="footerText1">Join us</p>
                <p className="footerText1">THS Profile</p>
              </div>
              <div>
                <h2 className="footerText">More</h2>
                <p className="footerText1">FAQ</p>
                <p className="footerText1">Help</p>
                <p className="footerText1">About</p>
                <p className="footerText1">Privacy Policy</p>
                <p className="footerText1">Terms & Conditions</p>
              </div>
              <div>
                <h2 className="footerText">Contact us</h2>
                <p className="footerText1">+91231111213</p>
                <p className="footerText1">ths.support@ths.com</p>
                <p className="footerText1">111, joyo BLVD, Ojuta,<br />
                  Gujarat,India</p>
              </div>
            </div>
            <p className="footerText12">Copyright @ 2023, THS. All right reserved.</p>
          </Container>
        </div> */}
      </div >
    </>
  );
}

export default DashboardHome;
