import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { PackageList, setPackageId } from "../../../Store/Reducer/PackageSlice";
import HealthPackageDetailModel from "../../Common/labtestpackage/HealthPackageDetailModel";

const HealthPackage = () => {
  const [healthPackageDetailModel, setHealthPackageDetailModel] =
    useState(false);
  const dispatch = useDispatch();
  const { packageList } = useSelector(({ PackageSlice }) => PackageSlice);
  useEffect(() => {
    dispatch(PackageList(2));
    // dispatch(RadiologyPackageList(3));
  }, [dispatch]);
  return (
    <div>
      <HealthPackageDetailModel
        show={healthPackageDetailModel}
        onHide={(e) => {
          setHealthPackageDetailModel(false);
        }}
      />
      <Container fluid className="health_package_container">
        <Container>
          <div className="row">
            <div className="col-md-12">
              <h3 className="health_package_container_title">Health Package</h3>
            </div>
          </div>
          <div className="health_package_card_box p-4">
            <div className="col-md-12">
              <h3 className="">For Lab Test</h3>
            </div>
            <div className="row">
              <div className="col-xl-12 col-md-12 col-xs-12 health_package_right_padding">
                <div className="health_package_left_side_section">
                  <div className="row">
                    {packageList?.length > 0 &&
                      packageList?.map((item) => (
                        <div className="col-xl-3 col-md-3 col-6 popular_package">
                          <div className="popular_package_card ">
                            <center>
                              <img
                                src={require("../../../Assets/img/png/popular/popular_1.png")}
                                className="popular_package_card_img"
                                alt="My Awesome Image"
                              ></img>
                            </center>
                            <div
                              className="popular_package_card_inner_box"
                              onClick={() => {
                                setHealthPackageDetailModel(true);
                                dispatch(setPackageId(item?.id));
                                localStorage.setItem(
                                  "PackageType",
                                  "LabPackage"
                                );
                              }}
                            >
                              <center>
                                <h3 className="popular_package_card_title">
                                  {item?.package_title}
                                </h3>
                                <h5 className="popular_package_card_subtitle">
                                  Starts from
                                </h5>
                                <h3 className="popular_package_card_price">
                                  ₹ {item?.package_amount}
                                </h3>
                              </center>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="health_package_card_box p-4">
            <div className="col-md-12">
              <h3 className="">For Radiology Test</h3>
            </div>
            <div className="row">
              <div className="col-xl-12 col-md-12 health_package_right_padding">
                <div className="health_package_left_side_section">
                  <div className="row">
                    {radiologyPackageList?.length > 0 &&
                      radiologyPackageList?.map((item) => (
                        <div className="col-md-3 col-3">
                          <div className="popular_package_card p-5">
                            <center>
                              <img
                                src={require("../../../Assets/img/png/popular/popular_1.png")}
                                className="popular_package_card_img"
                                alt="My Awesome Image"
                              ></img>
                            </center>
                            <div
                              className="popular_package_card_inner_box"
                              onClick={() => {
                                setHealthPackageDetailModel(true);
                                dispatch(setPackageId(item?.id));
                                localStorage.setItem(
                                  "PackageType",
                                  "RadiologyPackage"
                                );
                              }}
                            >
                              <center>
                                <h3 className="popular_package_card_title">
                                  {item?.package_title}
                                </h3>
                                <h5 className="popular_package_card_subtitle">
                                  Starts from
                                </h5>
                                <h3 className="popular_package_card_price">
                                  ₹ {item?.package_amount}
                                </h3>
                              </center>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </Container>
      </Container>
    </div>
  );
};

export default HealthPackage;
