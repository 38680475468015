import React from 'react'
import Container from "react-bootstrap/Container";
import "../../../../Assets/css/responsive.css"
import { Icon } from "../../../../Utilities/Icons.js";

const Support = () => {
  return (
    <>
      <Container>
        <div className="row">
          <div className="col-xl-12 col-md-12 col-sm-12">
            <div className="support_container">
              <div className="support_child">

                <div className="row">
                  <div className="col-xl-12 col-md-12 col-sm-12">
                    <div className="support_child_main_logo">
                      <center>
                        <img src={Icon.SupportNew} className="support_logo_box" alt="" ></img>
                        <h4 className="support_logo_heading">How Can We Help You?</h4>
                      </center>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-xl-6 col-md-6 col-sm-12">
                    <div className="support_body_left">
                      <center>
                        <img src={Icon.SupportCall} className="" alt="" ></img>
                        <p className="support_inner_heading">Call Us On </p>
                      </center>
                      <p className="support_inner_description">18003097847</p>
                    </div>
                  </div>
                  <div className="col-xl-6 col-md-6 col-sm-12">
                    <div className="support_body_right">
                      <center>
                        <img src={Icon.SupportMail} className="" alt="" ></img>
                        <p className="support_inner_heading">Send Us An e-mail</p>
                      </center>
                      <p className="support_inner_description">support@thsindia.in</p>
                    </div>
                  </div>
                </div>


              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  )
}

export default Support
