import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import { BackGround, Icon } from "../../../Utilities/Icons";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { GetConsultDetails } from "../../../Store/Reducer/BookingSlice";
import moment from "moment";
import { DownloadURL } from "../../../Utilities/Functions";
import { addToCart, LabtestLabs } from "../../../Store/Reducer/LabtestSlice";
import { GetRadioLogyData } from "../../../Store/Reducer/RadiologySlice";
import { radiologyaddToCart } from "../../../Store/Reducer/RadiologySlice";
import Speciality from "../Dashboard/Speciality";
import {
  SelectedSpecialityId,
  SpecialityWiseHealthProblems,
} from "../../../Store/Reducer/BookingSlice";
import {
  GetMedicine,
  madicineAddToCart,
} from "../../../Store/Reducer/MedicineSlice";
import {
  setClearSpecialityhealthModel,
} from "../../../Store/Reducer/CommonSlice";
import SpecialityhealthModel from "../Dashboard/SpecialityhealthModel";
import jsPDF from "jspdf";

function MyConsultationDetails() {
  const { consultDetails } = useSelector(({ BookingSlice }) => BookingSlice);
  const [specialityModel, setSpecialityModel] = useState(false);
  const [updateState, setUpdateState] = useState(true);
  const { token } = useSelector(({ AuthSlice }) => AuthSlice);



  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  useEffect(() => {
    if (id) {
      dispatch(GetConsultDetails({ booking_id: id }));
    }
  }, [id]);
  let health_problems = consultDetails?.health_problems?.map(function (i) {
    return i?.title;
  });
  const { labTestList } = useSelector(({ LabtestSlice }) => LabtestSlice);
  const { session } = useSelector(({ AuthSlice }) => AuthSlice);
  const { radiologyData } = useSelector(({ RadiologySlice }) => RadiologySlice);


  const { specialityList, selectedSpecialityId } = useSelector(
    ({ BookingSlice }) => BookingSlice
  );
  const [healthProblemModel, sethealthProblemModel] = useState(false);
  const { specialityhealth } = useSelector(({ CommonSlice }) => CommonSlice);

  useEffect(() => {

    dispatch(SpecialityWiseHealthProblems(selectedSpecialityId));

  }, [selectedSpecialityId, dispatch]);


  const onNavigaleLabTestCart = () => {
    if (labTestList.length > 0) {
      {
        labTestList?.map((item1) => {
          consultDetails?.prescription_data?.lab_test?.filter(function (item) {
            if (item1?.labs?.lab_id == item?.lab_test_id) {
              dispatch(addToCart(item1));
              navigate("/labtestpackage");
            }
          });
        });
      }
    } else {
      navigate("/labtestpackage");
    }
  };


  const onNavigaleRadiologyTestCart = () => {
    // setUpdateState(!updateState)
    if (radiologyData.length > 0) {
      {
        radiologyData?.map((item1) => {
          consultDetails?.prescription_data?.Radiological_test?.filter(
            function (item) {
              if (item1?.Testdata?.id == item?.lab_test_id) {
                dispatch(radiologyaddToCart(item1));
                navigate("/radiology-test-cart");
              }
            }
          );
        });
      }
    } else {
      navigate("/radiology-test-cart");
    }
  };

  const onNavigaleMadicineTestCart = () => {
    consultDetails?.prescription_data?.medicines?.filter(function (item) {
      if (Number(item?.medicine_id)) {
        dispatch(
          madicineAddToCart({
            can_sell_online: item?.can_sell_online,
            combinations: item?.combinations,
            content: item?.content,
            deliveryinformation: item?.deliveryinformation,
            description: item?.description,
            id: item?.medicine_id,
            images: item?.images,
            is_assured: item?.is_assured,
            is_banned: item?.is_banned,
            is_chronic: item?.is_chronic,
            is_discontinued: item?.is_discontinued,
            is_live: item?.is_live,
            is_refrigerated: item?.is_refrigerated,
            is_rx_required: item?.is_rx_required,
            manufacturer_name: item?.manufacturer_name,
            mrp: item?.price,
            name: item?.medicine_name,
            name_web: item?.name_web,
            package_size: item?.package_size,
            package_type: item?.package_type,
            quantity: item?.quantity,
            sales_price: item?.price,
            slug: item?.slug,
            type: item?.type,
            uom: item?.uom,
          })
        );
        navigate("/buymedicinecart");
      }
    });
  };

  useEffect(() => {
    if (consultDetails?.prescription_data?.medicines) {
      dispatch(GetMedicine({ q: "e", page: 1 }));
    }
  }, [dispatch, consultDetails?.prescription_data?.medicines]);
  useEffect(() => {
    if (session?.city_id) {
    }
    if (consultDetails?.prescription_data?.Radiological_test[0]?.lab_test_id) {
      dispatch(
        GetRadioLogyData([
          consultDetails?.prescription_data?.Radiological_test[0]?.lab_test_id,
        ])
      );
    }
  }, [

    session?.city_id,
    consultDetails?.prescription_data?.Radiological_test[0]?.lab_test_id,
  ]);



  const handleDownload = (url) => {
    const link = document.createElement("a");
    link.href = url;
    link.target = "_blank"; // Open the link in a new tab to force download
    link.rel = "noopener noreferrer";
    document.body.appendChild(link)
;
    link.click();
    document.body.removeChild(link)
;
  };
  const handleDownloads = (url) => {
    const link = document.createElement("a");
    link.href = url;
    link.target = "_blank"; // Open the link in a new tab to force download
    link.rel = "noopener noreferrer";
    document.body.appendChild(link)
;
    link.click();
    document.body.removeChild(link)
;
  };

  const handleClick = () => {
    dispatch(SelectedSpecialityId(consultDetails?.prescription_data?.refer_doctors[0]?.speciality_id));
    dispatch(SpecialityWiseHealthProblems(consultDetails?.prescription_data?.refer_doctors[0]?.speciality_id));
  };

  return (
    <Container>
      {/* <Speciality
        show={specialityModel}

        onHide={(e) => {
          setSpecialityModel(!specialityModel);
        }}
      /> */}


      <div className="col-lg-12 col-md-12 col-xl-12 col-12	col-sm-12  completed_consultation_left_pading">
        <div className="completed_consultation_chat_card_head_2">
          <div className="completed_appointment_detail_box">
            <div className="row">
              <div className="col-xl-6 col-md-8 col-9">
                <h5 className="completed_appointment_detail_title">
                  Appointment Detail
                </h5>
              </div>
              <div className="col-xl-6 col-md-4 col-3">
                <img
                  src={Icon.crossb}
                  className="completed_appointment_detail_close"
                  onClick={() => navigate("/profileSidebar")}
                  alt=""
                ></img>
              </div>
            </div>
          </div>
        </div>
        <div className="completed_consultation_chat_card_body_2">
          <div className="completed_scheduled_tag mb_30">
            <img
              src={Icon.scheduled_green}
              className="completed_scheduled_tag_icon"
            ></img>{" "}

            Completed on {" "}
            {moment(consultDetails?.completed_date).format(
              "DD-MMM-YYYY | h:mm A"
            )}
          </div>
          <div className="completed_chat_appointment_card mb_15">
            <div className="completed_chat_appointment_card_inner">
              <div>
                <img src={consultDetails.doctor_image} alt="" className="my_consultation_doc_img"></img>
              </div>
              <div>
                <h5 className="completed_chat_appoint_card_consult_name">
                  {consultDetails?.doctor_name}
                </h5>
                <h6 className="completed_chat_appoint_card_consult_designation">
                  {consultDetails?.doctor_speciality}
                </h6>
                <h6 className="completed_chat_appoint_card_consult_exp">
                  Exp. {consultDetails?.doctor_experience} Yrs
                </h6>
                <h6 className="completed_chat_appoint_card_consult_count">
                  {consultDetails?.total_consulation} Consultations done
                </h6>
              </div>
            </div>
          </div>

          <div className="completed_chat_appointment_card mb_15">
            <h5 className="completed_chat_appoint_title">Appointment For</h5>
            <hr className="completed_chat_appoint_hr" />
            <div className="row">
              <div className="col-md-6 col-6">
                <h5 className="completed_chat_appoint_details_title">
                  Problem
                </h5>
              </div>
              <div className="col-md-6 col-6">
                <h5 className="completed_chat_appoint_details_value">
                  {health_problems?.toString()}
                </h5>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-6">
                <h5 className="chat_appoint_details_title">Patient</h5>
              </div>
              <div className="col-md-6 col-6">
                <h5 className="chat_appoint_details_value">
                  {consultDetails?.patient_details?.name}
                </h5>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-6">
                <h5 className="chat_appoint_details_title">Age & Gender</h5>
              </div>
              <div className="col-md-6 col-6">
                <h5 className="chat_appoint_details_value">
                  {consultDetails?.patient_details?.age} Yrs |{" "}
                  {consultDetails?.patient_details?.gender?.toLowerCase() ===
                    "male"
                    ? "Male"
                    : "Female"}
                </h5>
              </div>
            </div>
          </div>

          <div className="completed_chat_appointment_card mb_15">
            <h5 className="completed_chat_appoint_title">Documents</h5>
            <hr className="completed_chat_appoint_hr" />
            <div className="row">
              <div className="col-md-12 col-12">
                <Button
                  className="download_bill"
                  // onClick={() => { handleSubmit() }}
                     onClick={() => handleDownloads(consultDetails?.payslip_url)}

                >
                  Download Bill{" "}
                  <img
                    src={Icon.download_gray}
                    className="download_bill_icon"
                    alt=""
                  ></img>
                </Button>
              </div>
              <div className="col-md-12 col-12">
                <Button
                  className="download_prescription"
                  onClick={() => handleDownload(consultDetails?.pdf_url)}
                // onClick={() => DownloadURL(consultDetails?.payslip_url)}
                >
                  Download Prescription{" "}
                  <img
                    src={Icon.download_gray}
                    className="download_prescription_icon"
                    alt=""
                  ></img>
                </Button>
              </div>
            </div>
          </div>
          {consultDetails?.prescription_data?.lab_test?.length ? (
            <div className="completed_chat_appointment_card mb_15">
              <h5 className="lab_text">
                Lab Test <span className="recommendation">Recommendation</span>
              </h5>
              <h3 className="precribed_title">
                Precribed by Dr. Shreeja Maheshwar
              </h3>
              <hr className="completed_chat_appoint_hr" />
              <div className="row">
                <div className="col-md-12">
                  <ul className="lab_test_text_sty">
                    {consultDetails?.prescription_data?.lab_test?.map(
                      (item, index) => (
                        <li className="lab_test_text" key={index}>
                          {item.lab_test_name}
                        </li>
                      )
                    )}
                  </ul>
                </div>
              </div>
              <div className="row consultation-details-button-center">
                {/* <div className="col-md-6 col-6 completed_consultation_right_pading">
               <center>
                 <Button className="view_details">View Details</Button>
               </center>
             </div> */}
                <div className="col-md-6 col-6 completed_consultation_left_pading">
                  <center>
                    <Button
                      className="lab_book_now"
                      onClick={() => onNavigaleLabTestCart()}
                    >
                      Book Now
                    </Button>
                  </center>
                </div>
              </div>
            </div>
          ) : ""}
          {consultDetails?.prescription_data?.Radiological_test?.length ? (
            <div className="completed_chat_appointment_card mb_15">
              <h5 className="lab_text">
                Radiology Test{" "}
                <span className="recommendation">Recommendation</span>
              </h5>
              <h3 className="precribed_title">
                Precribed by Dr. Shreeja Maheshwar
              </h3>
              <hr className="completed_chat_appoint_hr" />
              <div className="row">
                <div className="col-md-12">
                  <ul className="lab_test_text_sty">
                    {" "}
                    {consultDetails?.prescription_data?.Radiological_test?.map(
                      (item, index) => (
                        <li className="lab_test_text">{item.lab_test_name}</li>
                      )
                    )}
                  </ul>
                </div>
              </div>
              <div className="row consultation-details-button-center">
                {/* <div className="col-md-6 col-6 completed_consultation_right_pading">
                  <center>
                    <Button className="view_details">View Details</Button>
                  </center>
                </div> */}
                <div className="col-md-6 col-6 completed_consultation_left_pading">
                  <center>
                    <Button
                      className="lab_book_now"
                      onClick={() => onNavigaleRadiologyTestCart()}
                    >
                      Book Now
                    </Button>
                  </center>
                </div>
              </div>
            </div>
          ) : ""}
          {consultDetails?.prescription_data?.medicines?.length > 0 ? (
            <div className="completed_chat_appointment_card mb_15">
              <h5 className="lab_text">Medicines Prescribed</h5>
              {/* <h3 className="precribed_title">
                Precribed by Dr. Shreeja Maheshwar
              </h3> */}
              <hr className="completed_chat_appoint_hr" />
              <div className="row">
                <div className="col-md-12">
                  <ul className="lab_test_text_sty">
                    {consultDetails?.prescription_data?.medicines?.map(
                      (item) => (
                        <li className="lab_test_text">
                          {item?.medicine_name}{" "}
                          <span className="completedtext">( X 6 )</span>
                        </li>
                      )
                    )}
                  </ul>
                </div>
              </div>
              <div className="row consultation-details-button-center">
                {/* <div className="col-md-6 col-6 completed_consultation_right_pading">
                      <center>
                        <Button className="view_details">View Details</Button>
                      </center>
                    </div> */}
                <div className="col-md-6 col-6 completed_consultation_left_pading">
                  <center>
                    <Button
                      className="lab_book_now"
                      onClick={() => onNavigaleMadicineTestCart()}
                    >
                      Buy Now
                    </Button>
                  </center>
                </div>
              </div>
            </div>
          ) : ""}
          {
            consultDetails &&
              consultDetails?.prescription_data?.refer_doctors.length > 0 ? (
              <>
                <div className="completed_chat_appointment_card mb_15">
                  <h5 className="lab_text">Recommended Doctor</h5>
                  <h3 className="precribed_title"></h3>
                  <hr className="completed_chat_appoint_hr" />
                  <div className="row">
                    {consultDetails?.prescription_data?.refer_doctors[0] && (
                      <div className="recommended_doc_box">
                        <div>
                          <img
                          // src={require("../Assets/img/png/doctor_img_3.png")}
                          ></img>
                        </div>
                        <div>
                          <h5 className="completed_chat_appoint_card_consult_name">
                            {consultDetails?.doctor_name}
                          </h5>
                          <h6 className="completed_chat_appoint_card_consult_designation">
                          
                            {
                              consultDetails?.prescription_data?.refer_doctors[0]
                                ?.speciality_name
                            }
                          </h6>
                          <h6 className="completed_chat_appoint_card_consult_exp">
                            Exp. {consultDetails?.doctor_experience} Yrs
                          </h6>
                          <h6 className="completed_chat_appoint_card_consult_count">
                            {consultDetails?.total_consulation} Consultations done
                          </h6>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="row ">
                    <div className="col-md-12">
                      <center>
                        <Button
                          className="doc_consult_now_btn"
                          onClick={() => {
                            {
                              token ?
                                navigate("/health") : navigate("/signin");
                            } handleClick();
                          }}
                        >
                          Consult Now
                        </Button>
                      </center>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )
          }



          <div className="completed_chat_appointment_card mb_15">
            <h5 className="completed_chat_appoint_title">
              Appointment Details
            </h5>
            <hr className="completed_chat_appoint_hr" />
            <div className="row">
              <div className="col-md-6 col-6">
                <h5 className="completed_chat_appoint_details_title">
                  Appointment ID
                </h5>
              </div>
              <div className="col-md-6 col-6">
                <h5 className="completed_chat_appoint_details_value">
                  {consultDetails?.appointment_id}
                </h5>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-6">
                <h5 className="completed_chat_appoint_details_title status_margin">
                  Status
                </h5>
              </div>
              <div className="col-md-6 col-6">
                <h5 className="completed_chat_appoint_details_value">
                  {(() => {
                    switch (consultDetails?.status) {
                      case 0:
                        return "InProgress";
                      case 1:
                        return "Scheduled";
                      case 2:
                        return "Complete";
                      case 3:
                        return "Cancel";
                      default:
                        return null;
                    }
                  })()}
                </h5>
              </div>
            </div>
            <div className="row">
              <div className="col-md-5 col-6">
                <h5 className="completed_chat_appoint_details_title">
                  Appointment on
                </h5>
              </div>
              <div className="col-md-7 col-6">
                <h5 className="completed_chat_appoint_details_value">
                  {moment(consultDetails?.appointment_date_time).format(
                    "DD MMM YYYY | hh:mm a"
                  )}
                </h5>
              </div>
            </div>
          </div>

          <div className="completed_chat_appointment_card mb_15">
            <h5 className="completed_chat_appoint_title">
              Payment Information
            </h5>
            <hr className="completed_chat_appoint_hr" />
            <div className="row">
              <div className="col-md-6 col-6">
                <h5 className="chat_appoint_details_title">Payment ID</h5>
              </div>
              <div className="col-md-6 col-6">
                <h5 className="chat_appoint_details_value">
                  {consultDetails?.payment_id == undefined ? "Free" : consultDetails?.payment_id}
                </h5>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-6">
                <h5 className="chat_appoint_details_title status_margin">
                  Paid amount
                </h5>
              </div>
              <div className="col-md-6 col-6">
                <h5 className="chat_appoint_details_value">
                  ₹{" "}
                  {consultDetails?.total_paid_amount ||
                    consultDetails?.total_price}
                </h5>
              </div>
            </div>
            <div className="row">
              <div className="col-md-5 col-6">
                <h5 className="chat_appoint_details_title">Date and Time</h5>
              </div>
              <div className="col-md-7 col-6">
                <h5 className="chat_appoint_details_value">
                  {moment(consultDetails?.updated_at).format(
                    "DD MMM YYYY | hh:mm a"
                  )}
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}

export default MyConsultationDetails;
