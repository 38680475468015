import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import { BackGround, Icon } from "../../../Utilities/Icons";
import { Modal, Button } from "react-bootstrap";
import "../../../Assets/css/responsive.css";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import {
    clearCartItem,
    LabTestCenters,
    LabtestCities,
    LabtestLabs,
    setLabTestCenterDetailsById,
} from "../../../Store/Reducer/LabtestSlice";
import { useDispatch, useSelector } from "react-redux";
import LabTestModel from "../LabTestModel/LabTestModel";
import SelecteMemberModel from "../MemberModel/SelecteMemberModel";
import { GetMember } from "../../../Store/Reducer/ProfileReducer";
import {
    HealthCategoryListData,
    HealthListData,
    SurgeryListData,
    SurgeryServiceListData,
    clearRadiologyCartItem,
    setRadiologyTestCenterDetailsById,
} from "../../../Store/Reducer/RadiologySlice";
import { setPackageId } from "../../../Store/Reducer/PackageSlice";
import { useNavigate } from "react-router-dom";
import RadiologicalModel from "../RadiolodicalModel/RadiologicalModel";
import RadiologyCenterModel from "../RadiolodicalModel/RadiologyCenterModel";
import RadiologyCenterDetailModel from "../RadiolodicalModel/RadiologyCenterDetailModel";
import { PackageList } from "../../../Store/Reducer/PackageSlice";
// import HealthPackageDetailModel from "./HealthPackageDetailModel";
import HealthPackageDetailModel from "../labtestpackage/HealthPackageDetailModel";
import PathologyLab from "../LabTestModel/PathologyLab";
import PathologySuceessModal from "../LabTestModel/PathologySuceessModal";
import LabCityModal from "../LabTestModel/LabCityModal";
import InquiryListmodal from "../Healthpackagemodal/InquiryListmodal";
import { HealthCategoryList } from "../../../Routes/Service";
import HealthCategoryListModal from "../Healthpackagemodal/HealthCategoryListModal";
import SurgeryListModal from "./SurgeryListModal";
import SurgeryCategory from "./SurgeryCategory";
import SurgeryBooking from "./SurgeryBooking";
import RequestModal from "../../Private/HealthPackage/RequestModal";

function SurgeryCart(props) {
    const navigate = useNavigate();
    const [radiomodal, setRadioshow] = useState(false);
    const [radiomodal1, setRadioshow1] = useState(false);
    const dispatch = useDispatch();
    const [packagedetail, setPackageDetails] = useState(false);
    const { labTestList } = useSelector(({ LabtestSlice }) => LabtestSlice);
    const [labtestmodel, setLabtestmodel] = useState(false);
    const [selectedCity, setSelectedCity] = useState("");
    const [labcentermodal, setLabcentermodal] = useState(false);
    const [labcitymodal, setLabcitymodal] = useState(false);
    const [labdetailmodal, setLabdetailmodal] = useState(false);
    const [selectMember, setSelectMember] = useState(false);
    const [cityModal, setCityModal] = useState(false);
    const [category, setCategory] = useState()
    const [radiologyCenterDetailModel, setRadiologyCenterDetailModel] =
        useState(false);
    const [healthPackageDetailModel, setHealthPackageDetailModel] =
        useState(false);
    const { radiologyData, radiologyCart, radiologyTestCenterList, healthListData, healthCategoryList } = useSelector(
        ({ RadiologySlice }) => RadiologySlice
    );

    const { labtestStates, labtestCities } = useSelector(
        ({ LabtestSlice }) => LabtestSlice
    );

    const cityJSON = localStorage.getItem("USER");
    const cityObject = JSON.parse(cityJSON);
    useEffect(() => {
        dispatch(LabtestCities(cityObject?.city_details?.state_id))
    }, [dispatch]);
    useEffect(() => {
        setSelectedCity(labtestCities.length > 0 && labtestCities[0])
    }, [labtestCities])

    const { packageList } = useSelector(({ PackageSlice }) => PackageSlice);
    const { session } = useSelector(({ AuthSlice }) => AuthSlice);
    useEffect(() => {
        dispatch(LabtestLabs({
            center_id: labTestCenterDetailsById && labTestCenterDetailsById?.id,
            city_id: labTestCenterDetailsById && labTestCenterDetailsById?.city_id
        }));
        dispatch(GetMember());
        dispatch(HealthListData());
        dispatch(HealthCategoryListData());
    }, [dispatch]);

    const [inputText, setInputText] = useState("");

    let inputHandler = (e) => {
        //convert input text to lower case
        var lowerCase = e.target.value.toLowerCase(); //.toLowerCase();
        setInputText(lowerCase);
    };

    const labradiologytest = localStorage?.getItem("labradiologytest");

    useEffect(() => {
        dispatch(PackageList(2));
    }, [dispatch]);



    //lablist
    const { labTestCenterList, labTestCenterDetailsById } =
        useSelector(({ LabtestSlice }) => LabtestSlice);
    //   const { session } = useSelector(({ AuthSlice }) => AuthSlice);
    useEffect(() => {
        let request = {
            city_id: 39,
        };
        dispatch(LabTestCenters(request));
    }, [dispatch, 39]);

    const [gender, setGender] = useState();

    function onChangeValue(event) {
        setGender(event.target.value);
    }

    useEffect(() => {
        if (gender) {
            labTestCenterList?.filter(function (item) {
                if (gender == item?.id) {
                    dispatch(setLabTestCenterDetailsById(item));
                    props?.formik?.setFieldValue("center_id", item?.id);
                }
            });
        } else {
            setGender(labTestCenterDetailsById.id);
        }
    }, [gender]);


    const [inquiry, SetInquiry] = useState(false);
    const [secondModal, SetsecondModal] = useState(false);



    ///

    useEffect(() => {
        dispatch(SurgeryListData())
    }, [dispatch])
    const { surgeryListData } = useSelector(
        ({ RadiologySlice }) => RadiologySlice
    );

    const [selectedCategory, setSelectedCategory] = useState(null);
    const [surgerybooking, setSurgeryBooking] = useState(false);
    const [request, setRequest] = useState(false);
    return (
        <>

            <SurgeryListModal
                show={inquiry}
                onNext={() => {
                    SetsecondModal(true);
                    SetInquiry(false);
                }}
                onHide={(e) => {
                    SetInquiry(false);
                }}

            />
            <SurgeryCategory
                show={secondModal}
                onNext={() => {
                    setSurgeryBooking(true)
                    SetsecondModal(false);
                }}
                onHide={(e) => {
                    SetsecondModal(false);
                }}
                category={category}
            />

            <SurgeryBooking
                show={surgerybooking}
                onNext={() => {
                    setRequest(true);
                    setSurgeryBooking(false);
                }}
                onHide={(e) => {
                    setSurgeryBooking(false);
                }}
                category={category}
            />
            <RequestModal
                show={request}
                onNext={() => {
                    navigate("/");
                    setRequest(false);
                }}
                onHide={(e) => {
                    setRequest(false);
                }}
            />


            <PathologySuceessModal
                show={labdetailmodal}
                onNext={() => {
                    navigate("/health-Package-cart");

                    // window.location = '/patient/labtestcart'
                    setLabdetailmodal(false);
                }}
                onHide={(e) => {
                    setLabdetailmodal(false);
                }}
            />
            <RadiologicalModel
                show={radiomodal}
                radiologyData={radiologyData}
                onNext={() => {
                    setRadioshow(false);
                    setSelectMember(true);
                    setRadioshow1(false);
                }}
                onHide={(e) => {
                    setRadioshow(false);
                    e === true && setRadioshow1(true);
                }}
            />

            <Container fluid className="health_package_container">
                <Container>
                    <div className="row">
                        <div className="col-md-12">
                            <h3 className="health_package_container_title"> Surgery Inquiry</h3>
                        </div>
                    </div>
                    <div className="health_package_card_box">
                        <div className="row">
                            <div className="col-xl-12 col-md-12 health_package_left_padding">
                                <div className="health_package_right_side_section_1">
                                    <div className="health_package_lab_test">
                                        {/* <div className="row">
                                            <div className="col-md-12">
                                                <div className="main">
                                                    <form className="search">
                                                        <img
                                                            src={Icon.search}
                                                            className="select_city_search_icon"
                                                            alt="My Awesome Image"
                                                        ></img>
                                                        <input
                                                            type="search"
                                                            className="health_package_input_search"
                                                            placeholder="Search"
                                                            // onChange={inputHandler}
                                                        />
                                                        <ul className="results">
                                                            <div className="row mb_20">
                                                                <div className="col-md-6 col-6">
                                                                    <h3 className="health_package_lab_test_title">
                                                                        Healthcare
                                                                    </h3>
                                                                </div>
                                                                <div className="col-md-6 col-6"></div>
                                                            </div>
                                                        </ul>
                                                        <br />
                                                    </form>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                    <div>
                                        <div className="row mb_25">
                                            <div className="col-md-6 col-6">
                                                <h3 className="health_package_lab_test_title">
                                                    Surgeries
                                                </h3>
                                            </div>
                                            <div className="col-md-6 col-6">
                                                <Button
                                                    className="health_package_lab_test_view_all_btn"
                                                    onClick={() => {
                                                        SetInquiry(true);

                                                    }}
                                                >
                                                    View all
                                                </Button>
                                            </div>
                                        </div>
                                        {surgeryListData?.slice(0, 5).map((item, index) => (
                                            <React.Fragment key={index}>
                                                <div className="lab_member_box">
                                                    <div className="lab_member_box_inner">

                                                        <div className="health_radiological_popup_list_box_inner">
                                                            <div className="" style={{ width: "100px" }}>
                                                                <img
                                                                    className="select_radiology_img"
                                                                    src={`${item?.image}`}
                                                                    alt=""
                                                                    height="70px"
                                                                    width="70px"
                                                                    style={{
                                                                        borderRadius: "50%"
                                                                    }}
                                                                />
                                                            </div>
                                                            <div>
                                                                <label htmlFor="Radiological_Test7">
                                                                    <h3
                                                                        className="healthradiological_radiological_test_title"
                                                                        onClick={() => {
                                                                            props.onHide();
                                                                        }}
                                                                    >
                                                                        {item?.name}
                                                                    </h3>
                                                                    <div className="healthradiological_star_box">
                                                                        <h6 className="amounthealthcare">
                                                                            {/* ₹ {item?.amount} */}
                                                                        </h6>
                                                                    </div>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <input
                                                                type="radio"
                                                                id={item?.id}
                                                                name="gender"
                                                                className="healthradiological_input"
                                                                checked={gender == item?.id}
                                                                // onChange={(e) => onChangeValue(e)}
                                                                value={item?.id}
                                                                onChange={(e) => {
                                                                    dispatch(SurgeryServiceListData({ category: item?.id }));
                                                                    props?.formik?.setFieldValue("center_id", item?.id);
                                                                    onChangeValue(e)
                                                                    setCategory(item)
                                                                }}
                                                                onClick={() => { SetsecondModal(true); }}
                                                                style={{ accentColor: "#199a8e" }}

                                                            />
                                                            {/* <input
                                                                type="radio"
                                                                id={item?.id}
                                                                name="gender"
                                                                className="healthradiological_input"
                                                                value={item?.id}
                                                                checked={gender == item?.id}
                                                                onChange={(e) => onChangeValue(e)}
                                                            
                                                            /> */}
                                                        </div>

                                                    </div>
                                                </div>
                                                <hr className="consult_member_hr" />
                                            </React.Fragment>
                                        ))}

                                        {surgeryListData?.length == 0 && (
                                            <>
                                                <h6> No Surgery data Found</h6>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </Container>
        </>
    );
}

const MyModal_5 = (props5) => {
    return (
        <Modal
            {...props5}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            dialogClassName="lab_test_modal_box"
        >
            <Modal.Header
                className="lab_test_modal_box_head_padding"
                style={{ marginBottom: "none", border: "none" }}
            >
                <h5 className="lab_test_popup_title">Package Details</h5>
                <img
                    src={Icon.cross}
                    className="lab_test_popup_close"
                    onClick={props5.onHide}
                    alt=""
                ></img>
            </Modal.Header>
            <Modal.Body>
                <div className="lab_test_modal_box_2">
                    <div className="fullbody_package_box">
                        <div className="fullbody_package_box_inner">
                            <div>
                                <img
                                    src={BackGround.package}
                                    className="fullbody_package_img"
                                    alt=""
                                ></img>
                            </div>
                            <div>
                                <h3 className="fullbody_package_title">Silver Package</h3>
                                <h5 className="fullbody_package_subtitle">14 tests</h5>
                                <h6 className="fullbody_package_price">₹ 499</h6>
                            </div>
                        </div>
                        <div>
                            <Button
                                className="fullbody_package_btn"
                                onClick={() => {
                                    props5.onHide(true);
                                }}
                            >
                                Book Now
                            </Button>
                        </div>
                    </div>
                    <hr className="fullbody_package_hr" />
                </div>
                <div className="fullbody_package_scroll_box">
                    <p className="fullbody_package_scroll_para">
                        <span className="fullbody_package_scroll_paratitle">
                            Test Instructions:
                        </span>{" "}
                        Lorem Ipsum is simply dummy text of the printing and typesetting
                        industry. Lorem Ipsum has been the industry's standard dummy text
                        ever since the 1500s
                    </p>
                    <h3 className="fullbody_package_scroll_test_title">Tets Included</h3>
                    <h5 className="fullbody_package_scroll_test_name">FBG</h5>
                    <h6 className="fullbody_package_scroll_test_para">
                        Lorem Ipsum is simply dummy text of the printing and typesetting
                        industry
                    </h6>
                    <hr className="fullbody_package_hr" />
                    <h5 className="fullbody_package_scroll_test_name">Test Name</h5>
                    <h6 className="fullbody_package_scroll_test_para">
                        Lorem Ipsum is simply dummy text of the printing and typesetting
                        industry
                    </h6>
                    <hr className="fullbody_package_hr" />
                    <h5 className="fullbody_package_scroll_test_name">Test Name</h5>
                    <h6 className="fullbody_package_scroll_test_para">
                        Lorem Ipsum is simply dummy text of the printing and typesetting
                        industry
                    </h6>
                    <hr className="fullbody_package_hr" />
                    <h5 className="fullbody_package_scroll_test_name">Test Name</h5>
                    <h6 className="fullbody_package_scroll_test_para">
                        Lorem Ipsum is simply dummy text of the printing and typesetting
                        industry
                    </h6>
                    <hr className="fullbody_package_hr" />
                    <h5 className="fullbody_package_scroll_test_name">Test Name</h5>
                    <h6 className="fullbody_package_scroll_test_para">
                        Lorem Ipsum is simply dummy text of the printing and typesetting
                        industry
                    </h6>
                    <hr className="fullbody_package_hr" />
                    <h5 className="fullbody_package_scroll_test_name">Test Name</h5>
                    <h6 className="fullbody_package_scroll_test_para">
                        Lorem Ipsum is simply dummy text of the printing and typesetting
                        industry
                    </h6>
                    <hr className="fullbody_package_hr" />
                    <h5 className="fullbody_package_scroll_test_name">Test Name</h5>
                    <h6 className="fullbody_package_scroll_test_para">
                        Lorem Ipsum is simply dummy text of the printing and typesetting
                        industry
                    </h6>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default SurgeryCart;
