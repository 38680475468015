import React, { useState } from "react";
import { useMemo } from "react";
import { useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { setActiveKey } from "../../../../Store/Reducer/CommonSlice";
import { Icon } from "../../../../Utilities/Icons";
import FormControl from "../../../Common/Forms/FormControl";
import FileUpload from "../../../Common/Layouts/FileUpload";
import { walletPrice } from "../../../../Store/Reducer/RadiologySlice";

function BotIndex() {
  const { id2 } = useParams();
  const dispatch = useDispatch();
  const { specialityList } = useSelector(({ BookingSlice }) => BookingSlice);
  const [jsonData, setJsonData] = useState([]);
  const [questions, setQuestions] = useState([]);
  const { userWallet, memberList } = useSelector(
    ({ ProfileSlice }) => ProfileSlice
  );
  const [walletbox, setwalletbox] = React.useState(userWallet);
  useEffect(() => {

    dispatch(walletPrice(`{ "wallet_prices": ${walletbox?.wallet}, "cut_amount": 0}`));
  }, [dispatch])

  const intialLoad = () => {
    try {
      const speciality = specialityList?.find(
        (item) => parseInt(item?.id) === parseInt(id2)
      );
      if (speciality) {
        let initialBot = JSON.parse(speciality?.json_data);
        setJsonData(initialBot?.intents);
        let first = Array.isArray(initialBot?.intents)
          ? initialBot?.intents[0]
          : null;
        const { content } = first;
        let question = content[0]?.message;
        let options = content[1]?.metadata?.payload;

        let tempObj = {
          chatBoatQuestionsWithOptions: {
            messageList: [question],
            payloadList: options,
            finalAnswer: "",
          },
          answer: null,
          type: null,
          isLoadingBar: null,
        };

        setQuestions([{ ...tempObj, type: 0 }]);
      }
    } catch (error) {}
  };
  const onAnswer = async (answer) => {
    try {
      if (answer?.name === "No") {
        dispatch(setActiveKey("select_doctor"));
      }
      let tempObj;
      let tempAnswer = {
        chatBoatQuestionsWithOptions: null,
        answer: answer?.userAnswer || answer?.file || answer?.name,
        type: 1,
        isLoadingBar: null,
      };
      setQuestions((state) => [...state, tempAnswer]);

      tempObj = new Promise((resolve, reject) => {
        let result = jsonData.find((item) => {
          if (Array.isArray(item?.trainingPhrases)) {
            const isAvail = item?.trainingPhrases?.find(
              (phrase) => phrase.toLowerCase() === answer?.name?.toLowerCase()
            );
            if (isAvail) {
              return item;
            }
          }
        });
        if (result) {
          resolve(result);
        } else {
          dispatch(setActiveKey("select_doctor"));
        }
      }).then((first) => {
        if (first) {
          const { content } = first;
          let question = content[0]?.message;
          let options = content[1]?.metadata?.payload;
          if (!Array.isArray(options)) {
            dispatch(setActiveKey("select_doctor"));
          }
          tempObj = {
            chatBoatQuestionsWithOptions: {
              messageList: [question],
              payloadList: options,
              finalAnswer: "",
            },
            answer: null,
            type: 0,
            isLoadingBar: null,
          };
          setQuestions((state) => [...state, tempObj]);
        } else {
          dispatch(setActiveKey("select_doctor"));
        }
      });
    } catch (error) {}
  };

  useEffect(() => {
    intialLoad();
    return () => {};
  }, []);

  return (
    <>
      <div className="chat_body_box_card_head">
        <div className="row">
          <div className="col-md-12">
            <h3 className="chat_body_box_title">
              Tell us about your health problem
            </h3>
          </div>
        </div>
      </div>
      <div className="chat_body_box_card_body">
        <div id="chat-message-list">
          <div className="chat-message-list-inner">
            {questions?.map((item) => (
              <ChatItem data={item} type={item?.type} onAnswer={onAnswer} />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

const ChatItem = React.forwardRef(({ type, data, onAnswer }, ref) => {
  const { chatBoatQuestionsWithOptions, answer } = data;
  // const [loading, setLoading] = useState(false);

  const metaData = useMemo(() => {
    let question, userAnswer, options;
    if (chatBoatQuestionsWithOptions) {
      question = chatBoatQuestionsWithOptions?.messageList[0];
      options = chatBoatQuestionsWithOptions?.payloadList;
    }
    if (answer) {
      userAnswer = answer;
    }
    // setLoading(false);
    return { question, userAnswer, options };
  }, [chatBoatQuestionsWithOptions]);

  const createAnswer = (e, item) => {
    e.preventDefault();
    try {
      onAnswer(item);
    } catch (error) {}
  };

  // if (loading) {
  //   return <div>Loading...</div>;
  // }

  switch (type) {
    case 0: //Current Question
      return (
        <div className="message-row other-message mt_20">
          <div className="message-content">
            <div className="sender_msg_box">
              <h3 className="sender_text_title">{metaData?.question}</h3>
            </div>
            <div className="yesno_box mt-2">
              {metaData?.options?.map((item) => (
                <div className="yes_box">
                  {item?.name !== "Open textbox" && (
                    <h5 className="yes_text">{item?.name}</h5>
                  )}
                  {item?.name === "Upload photo" ? (
                    <FileUpload
                      className="upload_avatar_btn"
                      icon={Icon.attach_pin}
                      label=""
                      id={item?.name}
                      onChange={(e) => {
                        e.preventDefault();
                        let file = e.target.files[0];
                        createAnswer(e, { ...item, file });
                      }}
                      name="radio-group"
                    />
                  ) : item?.name === "Open textbox" ? (
                    <div className="form_group">
                      <input
                        type="text"
                        placeholder="Please enter your symptoms"
                        id={item?.name}
                        value={item.userAnswer}
                        onChange={(e) => {
                          e.preventDefault();
                          item.userAnswer = e.target.value;
                        }}
                        name="radio-group"
                      />
                      <button
                        onClick={(e) => {
                          createAnswer(e, item);
                        }}
                        className="btn btn-primary p-2 m-2"
                      >
                        Proceed
                      </button>
                    </div>
                  ) : (
                    <input
                      type="radio"
                      onChange={(e) => createAnswer(e, item)}
                      id={item?.name}
                      value={item?.name}
                      name="radio-group"
                      style={{ accentColor: "#199a8e"}}
                    />
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      );
    case 1: //Current Question
      return (
        <div className="message-row you-message">
          <div className="message-content">
            <div className="client_msg_box">
              <div className="attach_file_box">
                <div>
                  {typeof metaData.userAnswer === "object" ? (
                    <img src={URL.createObjectURL(metaData?.userAnswer)} />
                  ) : (
                    <h4 className="client_text_title">{metaData.userAnswer}</h4>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      );

    default:
      return (
        <div className="message-row other-message mt_20">
          <div className="message-content">
            <div className="sender_msg_box">
              <h3 className="sender_text_title">Loading</h3>
            </div>
          </div>
        </div>
      );
  }
});

const MedicalHistory = (props) => {
  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="modal_476"
    >
      <Modal.Header style={{ border: "none" }}>
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="chat_modal_head"
        >
          Add medical history, if you have any
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <textarea
          placeholder="eg: Blood pressure, diabetes etc"
          className="chat_teaxtarea"
        ></textarea>
      </Modal.Body>
      <Modal.Footer style={{ border: "none", justifyContent: "center" }}>
        <div className="chat_modal_footer">
          <Button className="chat_modal_skip_btn" onClick={props.onHide}>
            Skip
          </Button>
          <Button className="chat_modal_submit_btn">Submit</Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default BotIndex;
