import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import {
  GetDoctorList,
  CreateBookConsultant,
  UpcomingBookings,
  PastBookings,
  BookingCancel,
  CancelationReason,
  RescheduleBooking,
  AddRating,
  RescheduleDoctorDetailsAPI,
  RescheduleDoctorDetailsSlot,
  CouponListAPI,
  UpcomingFollowupsAPI,
  CancelFollowupsAPI,
  ConsultDetailsAPI,
  GetSubscriptionPlanAPI,
  MedicalHistoryListAPI,
  SpecialityWiseHealthProblemsAPI,
  SpecialityListAPI,
  SubscribedPlansListAPI,
  CheckBookingSlotAPI,
  GetHealthProblemsAPI,
  MedicineCouponListAPI,
  GetHealthProblemsForNonLoggedUserAPI,
  SpecialityListForNonLoggedUserAPI,
  CardHomeAPI,
  GetSubscriptionHomePlanAPI,
  ShareMyLinkAPI
} from "../../Routes/Service";

import { AlertEnum } from "../../Utilities/Enums";
import { setLoading, setMessage } from "./LayoutsSice";
let newDate = new Date();

const initialState = {
  problemsModal: false,
  profileSuccessModal: false,
  submittedModal: false,
  feeModal: false,
  userProfile: "",
  city: {},
  rejectionDetails: "",
  doctors: [],
  subScriptionPlanList: [],
  CardHome: [],
  upcomingBookings: [],
  pastBookings: [],
  cancelationReason: [],
  memberList: [],
  cretaBookConsultant: {},
  rescheduleDoctorDetails: "",
  rescheduleDoctor: "",
  couponList: [],
  upcomingFollowups: [],
  medicalHistory: [],
  specialityHealthProblemsList: [],
  specialityList: [],
  appointmentTime: {},
  userSelectedDate: moment(newDate).format("YYYY-MM-DD"),
  subscribedPlansList: [],
  selectedSpecialityId: 25,
  selectedPlan: false,
  activeDateRes: 0,
  selectedCouponCodeDetail: "",
  bookingDetail: "",
  couponCodeDetail: "",
  selectedPlanDetails: "",
  consultDetails: "",
  scheduleFollowupDetail: "",
  followUpPassData: "",
  subScriptionHomePlanList: [],
  cards: {},
  coupencodeid: "",
  doctorid: ""
};

export const GetHealthProblems = createAsyncThunk(
  "GetHealthProblems",
  async (values, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      var result = {}
      if (localStorage.getItem("TOKEN") != undefined)
        result = await GetHealthProblemsAPI(values);
      else
        result = await GetHealthProblemsForNonLoggedUserAPI(values);
      if (result?.success) {
        dispatch(setLoading(false));
        return result?.data;
      } else {
        throw result;
      }
    } catch (error) {
      dispatch(setLoading(false));
      // dispatch(
      //   setMessage({
      //     text: error?.message,
      //     type: AlertEnum.Error,
      //   })
      // );

      return error;
    }
  }
);

export const GetDoctorListById = createAsyncThunk(
  "GetDoctorListById",
  async (values, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const result = await GetDoctorList(values);
      if (result?.success) {
        dispatch(setLoading(false));
        return result?.data;
      } else {
        throw result;
      }
    } catch (error) {
      dispatch(setLoading(false));
      // dispatch(
      //   setMessage({
      //     text: error?.message,
      //     type: AlertEnum.Error,
      //   })
      // );

      return error;
    }
  }
);

export const GetSubscriptionPlan = createAsyncThunk(
  "GetSubscriptionPlan",
  async (values, { dispatch }) => {

    try {
      dispatch(setLoading(true));
      const result = await GetSubscriptionPlanAPI({ speciality_id: values });

      return result?.data;
      // if (result?.success) {

      //   dispatch(setLoading(false));

      //   return result?.data;
      // } else {
      //   throw result;
      // }
    } catch (error) {
      dispatch(setLoading(false));
      // dispatch(
      //   setMessage({
      //     text: error?.message,
      //     type: AlertEnum.Error,
      //   })
      // );

      return error;
    }
  }
);
export const GetSubscriptionHomePlan = createAsyncThunk(
  "GetSubscriptionHomePlan",
  async (values, { dispatch }) => {

    try {
      dispatch(setLoading(true));
      const result = await GetSubscriptionHomePlanAPI();
      if (result?.success) {

        dispatch(setLoading(false));
        return result?.data;
      } else {
        throw result;
      }
    } catch (error) {
      dispatch(setLoading(false));
      // dispatch(
      //   setMessage({
      //     text: error?.message,
      //     type: AlertEnum.Error,
      //   })
      // );

      return error;
    }
  }
);
export const CardHome = createAsyncThunk(
  "CardHome",
  async (values, { dispatch }) => {

    try {
      dispatch(setLoading(true));
      const result = await CardHomeAPI();
      if (result?.success) {

        dispatch(setLoading(false));
        return result?.data;
      } else {
        throw result;
      }
    } catch (error) {

      dispatch(setLoading(false));
      dispatch(
        // setMessage({
        //   text: error?.message,
        //   type: AlertEnum.Error,
        // })
      );

      return error;
    }
  }
);

export const ShareMyLinkStore = createAsyncThunk(
  "ShareMyLink",
  async (values, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      console.log("vaaaaaaa", values);
      const result = await ShareMyLinkAPI(values);
      if (result?.success) {
        dispatch(setLoading(false));
        return result?.data;
      } else {
        throw result;
      }
    } catch (error) {
      dispatch(setLoading(false));
      return error;
    }
  }
);

export const SubscribedPlansList = createAsyncThunk(
  "SubscribedPlansList",
  async (specialityId, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const result = await SubscribedPlansListAPI({ speciality_id: specialityId });
      if (result?.success) {
        dispatch(setLoading(false));
        return result?.data;
      } else {
        throw result;
      }
    } catch (error) {
      dispatch(setLoading(false));
    }
  }
);






export const BookConsultant = createAsyncThunk(
  "BookConsultant",
  async (values, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const result = await CreateBookConsultant(values);
      if (result?.success) {
        dispatch(setLoading(false));

        return result;
      } else {
        dispatch(setLoading(false));

        throw result;
      }
    } catch (error) {
      dispatch(setLoading(false));
      // dispatch(
      //   setMessage({
      //     text: error?.message,
      //     type: AlertEnum.Error,
      //   })
      // );
      return error;
    }
  }
);
export const UpcomingBookingList = createAsyncThunk(
  "UpcomingBookingList",
  async (values, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const result = await UpcomingBookings(values);
      if (result?.success) {
        dispatch(setLoading(false));
        return result?.data;
      } else {
        throw result;
      }
    } catch (error) {
      dispatch(setLoading(false));
      // dispatch(
      //   setMessage({
      //     text: error?.message,
      //     type: AlertEnum.Error,
      //   })
      // );

      return error;
    }
  }
);

export const BookingCancelById = createAsyncThunk(
  "BookingCancelById",
  async (values, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const result = await BookingCancel(values);
      if (result?.success) {
        dispatch(setLoading(false));
        dispatch(UpcomingBookingList());
        dispatch(
          setMessage({
            text: result?.message,
            type: AlertEnum.Success,
          })
        );
        return result?.data;
      } else {
        throw result;
      }
    } catch (error) {
      dispatch(setLoading(false));
      // dispatch(
      //   setMessage({
      //     text: error?.message,
      //     type: AlertEnum.Error,
      //   })
      // );

      return error;
    }
  }
);
export const PastBookingsList = createAsyncThunk(
  "PastBookingsList",
  async (values, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const result = await PastBookings(values);
      if (result?.success) {
        dispatch(setLoading(false));
        return result?.data;
      } else {
        throw result;
      }
    } catch (error) {
      dispatch(setLoading(false));
      // dispatch(
      //   setMessage({
      //     text: error?.message,
      //     type: AlertEnum.Error,
      //   })
      // );

      return error;
    }
  }
);

export const CancelationReasonList = createAsyncThunk(
  "CancelationReasonList",
  async (values, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const result = await CancelationReason(values);
      if (result?.success) {
        dispatch(setLoading(false));
        return result?.data;
      } else {
        throw result;
      }
    } catch (error) {
      dispatch(setLoading(false));
      // dispatch(
      //   setMessage({
      //     text: error?.message,
      //     type: AlertEnum.Error,
      //   })
      // );

      return error;
    }
  }
);
export const CreateRescheduleBooking = createAsyncThunk(
  "CreateRescheduleBooking",
  async (values, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const result = await RescheduleBooking(values);
      if (result?.success) {
        dispatch(
          setMessage({
            text: result?.message,
            type: AlertEnum.Success,
          })
        );
        dispatch(UpcomingBookingList());
        return result;
      } else {
        throw result;
      }
    } catch (error) {
      dispatch(setLoading(false));
      // dispatch(
      //   setMessage({
      //     text: error?.message,
      //     type: AlertEnum.Error,
      //   })
      // );
      return error;
    }
  }
);

export const RescheduleDoctorDetails = createAsyncThunk(
  "RescheduleDoctorDetails",
  async (values, { dispatch }) => {
    try {
      const result = await RescheduleDoctorDetailsAPI(values);
      console.log("reslutttt", result);
      if (result?.success) {
        return result;

      } else {
        throw result;
      }
    } catch (error) {
      dispatch(setLoading(false));
      // dispatch(
      //   setMessage({
      //     text: error?.message,
      //     type: AlertEnum.Error,
      //   })
      // );
      return error;
    }
  }
);

export const RescheduleDoctor = createAsyncThunk(
  "RescheduleDoctor",
  async (values, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const result = await RescheduleDoctorDetailsSlot(values);
      if (result?.success) {
        dispatch(setLoading(false));
        return result;

      } else {
        dispatch(setLoading(false));
        throw result;
      }
    } catch (error) {
      dispatch(setLoading(false));
      // dispatch(
      //   setMessage({
      //     text: error?.message,
      //     type: AlertEnum.Error,
      //   })
      // );
      return error;
    }
  }
);

export const ActiveButton = createAsyncThunk({

})
export const AddRatings = createAsyncThunk(
  "AddRating",
  async (values, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const result = await AddRating(values);
      if (result?.success) {
        dispatch(
          setMessage({
            text: result?.message,
            type: AlertEnum.Success,
          })
        );
        return result;
      } else {
        throw result;
      }
    } catch (error) {
      dispatch(setLoading(false));
      // dispatch(
      //   setMessage({
      //     text: error?.message,
      //     type: AlertEnum.Error,
      //   })
      // );
      return error;
    }
  }
);

export const CouponList = createAsyncThunk(
  "CouponList",
  async (values, { dispatch }) => {
    try {
      // dispatch(setLoading(true));
      const result = await CouponListAPI(values);
      if (result?.success) {
        // dispatch(setLoading(false));
        return result?.data;
      } else {
        throw result;
      }
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(
        setMessage({
          text: error?.message,
          type: AlertEnum.Error,
        })
      );


      return error;
    }
  }
);
export const MedicineCouponList = createAsyncThunk(
  "MedicineCouponList",
  async (values, { dispatch }) => {
    try {
      // dispatch(setLoading(true));
      const result = await MedicineCouponListAPI(values);
      if (result?.success) {
        dispatch(setLoading(false));
        return result?.data;
      } else {
        throw result;
      }
    } catch (error) {
      dispatch(setLoading(false));
      // dispatch(
      //   setMessage({
      //     text: error?.message,
      //     type: AlertEnum.Error,
      //   })
      // );

      return error;
    }
  }
);
export const UpcomingFollowups = createAsyncThunk(
  "UpcomingFollowups",
  async (values, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const result = await UpcomingFollowupsAPI(values);
      if (result?.success) {
        dispatch(setLoading(false));
        return result?.data;
      } else {
        throw result;
      }
    } catch (error) {
      dispatch(setLoading(false));
      // dispatch(
      //   setMessage({
      //     text: error?.message,
      //     type: AlertEnum.Error,
      //   })
      // );

      return error;
    }
  }
);
export const CancelFollowups = createAsyncThunk(
  "CancelFollowups",
  async (values, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const result = await CancelFollowupsAPI({ booking_id: values });
      if (result?.success) {
        dispatch(UpcomingFollowups());
        dispatch(setLoading(false));
        return result?.data;
      } else {
        throw result;
      }
    } catch (error) {
      dispatch(setLoading(false));
      // dispatch(
      //   setMessage({
      //     text: error?.message,
      //     type: AlertEnum.Error,
      //   })
      // );

      return error;
    }
  }
);
export const GetConsultDetails = createAsyncThunk(
  "GetConsultDetails",
  async (values, { dispatch }) => {
    try {
      const result = await ConsultDetailsAPI(values);
      if (result?.success) {
        dispatch(setLoading(false));
        return result?.data;
      } else {
        throw result;
      }
    } catch (error) {
      dispatch(setLoading(false));
      // dispatch(
      //   setMessage({
      //     text: error?.message,
      //     type: AlertEnum.Error,
      //   })
      // );
      return error;
    }
  }
);

export const MedicalHistoryList = createAsyncThunk(
  "MedicalHistoryList",
  async (values, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const result = await MedicalHistoryListAPI(values);
      if (result?.success) {
        dispatch(setLoading(false));
        return result?.data;
      } else {
        throw result;
      }
    } catch (error) {
      dispatch(setLoading(false));
      // dispatch(
      //   setMessage({
      //     text: error?.message,
      //     type: AlertEnum.Error,
      //   })
      // );

      return error;
    }
  }
);

export const SpecialityList = createAsyncThunk(
  "SpecialityList",
  async (values, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      var result = {}
      if (localStorage.getItem("TOKEN") != undefined)
        result = await SpecialityListAPI(values);
      else
        result = await SpecialityListForNonLoggedUserAPI(values);
      if (result?.success) {
        dispatch(setLoading(false));
        return result?.data;
      } else {
        throw result;
      }
    } catch (error) {
      dispatch(setLoading(false));
      // dispatch(
      //   setMessage({
      //     text: error?.message,
      //     type: AlertEnum.Error,
      //   })
      // );

      return error;
    }
  }
);
export const SpecialityWiseHealthProblems = createAsyncThunk(
  "SpecialityWiseHealthProblems",
  async (values, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const result = await SpecialityWiseHealthProblemsAPI({
        speciality_id: values,
      });
      if (result?.success) {
        dispatch(setLoading(false));
        return result?.data;
      } else {
        throw result;
      }
    } catch (error) {
      dispatch(setLoading(false));
      // dispatch(
      //   setMessage({
      //     text: error?.message,
      //     type: AlertEnum.Error,
      //   })
      // );

      return error;
    }
  }
);

export const CheckBookingSlot = createAsyncThunk(
  "CheckBookingSlot",
  async (values, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const result = await CheckBookingSlotAPI(values);
      if (result.message) {
        dispatch(setLoading(false));
        return result;
      } else {
        throw result;
      }
    } catch (error) {
      dispatch(setLoading(false));
      // dispatch(
      //   setMessage({
      //     text: error?.message,
      //     type: AlertEnum.Error,
      //   })
      // );

      return error;
    }
  }
);
export const BookingSlice = createSlice({
  name: "BookingSlice",
  initialState,
  reducers: {
    toggleProblemsModal: (state, action) => {
      state.problemsModal = action.payload;
    },
    SelectedPlan: (state, action) => {
      state.selectedPlan = action?.payload;
    },
    activeDate: (state, action) => {
      state.activeDateRes = action?.payload;
    },
    clearSelectedPlan: (state, action) => {
      state.selectedPlan = "";
    },
    SelectedCouponCode: (state, action) => {
      state.selectedCouponCodeDetail = action?.payload;
    },
    SelectedSpecialityId: (state, action) => {
      state.selectedSpecialityId = action?.payload;
    },
    SelectedDate: (state, action) => {
      state.userSelectedDate = action?.payload;
    },
    cleaSelectedDate: (state, action) => {
      state.userSelectedDate = moment(newDate).format("YYYY-MM-DD");
    },
    CouponCodeDetail: (state, action) => {
      state.couponCodeDetail = action?.payload;
    },
    AppointmentTime: (state, action) => {
      state.appointmentTime = action?.payload;
    },
    BookingDetail: (state, action) => {
      state.bookingDetail = action?.payload;
    },
    clearBookingDetail: (state, action) => {
      state.bookingDetail = "";
    },
    ScheduleFollowupDetail: (state, action) => {
      state.scheduleFollowupDetail = action?.payload;
    },
    clearScheduleFollowupDetail: (state, action) => {
      state.scheduleFollowupDetail = "";
    },
    clearSelectedCouponCode: (state, action) => {
      state.selectedCouponCodeDetail = "";
      state.couponCodeDetail = "";
    },
    coupencode: (state, action) => {

      state.coupencodeid = action.payload;
    },
    clearcoupencode: (state, action) => {
      state.coupencodeid = "";
    },
    DoctorId: (state, action) => {
      state.doctorid = "";
    },
    clearBookConsultant: (state, action) => {
      state.cretaBookConsultant = "";
    },
    clearCouponCodeList: (state, action) => {
      state.couponList = [];
    },
    setSelectedPlanDetails: (state, action) => {
      state.selectedPlanDetails = action?.payload;
    },
    setFollowUpPassData: (state, action) => {
      state.followUpPassData = action?.payload;
    },
    clearFollowUpPassData: (state, action) => {
      state.followUpPassData = "";
    },
    clearSelectedPlanDetails: (state, action) => {
      state.selectedPlanDetails = "";
    },
    clearRescheduleDoctorDetails: (state, action) => {
      state.rescheduleDoctorDetails = "";
    },
    clearRescheduleDoctor: (state, action) => {
      state.rescheduleDoctor = "";
    },
    coupenListall: (state, action) => {
      state.couponList = action?.payload;
    }

  },

  extraReducers: (builder) => {
    builder.addCase(GetSubscriptionPlan.fulfilled, (state, action) => {
      state.subScriptionPlanList = action.payload;
    });
    builder.addCase(GetSubscriptionHomePlan.fulfilled, (state, action) => {
      state.subScriptionHomePlanList = action.payload;
    });
    builder.addCase(CardHome.fulfilled, (state, action) => {
      state.cards = action.payload;
    });
    builder.addCase(UpcomingBookingList.fulfilled, (state, action) => {
      state.upcomingBookings = action.payload;
    });
    builder.addCase(PastBookingsList.fulfilled, (state, action) => {
      state.pastBookings = action.payload;
    });
    builder.addCase(GetConsultDetails.fulfilled, (state, action) => {
      state.consultDetails = action.payload;
    });

    builder.addCase(CancelationReasonList.fulfilled, (state, action) => {
      state.cancelationReason = action.payload;
    });
    builder.addCase(GetDoctorListById.fulfilled, (state, action) => {
      state.doctors = action.payload;
    });
    builder.addCase(BookConsultant.fulfilled, (state, action) => {
      state.cretaBookConsultant = action.payload;
    });
    builder.addCase(RescheduleDoctorDetails.fulfilled, (state, action) => {
      state.rescheduleDoctorDetails = action.payload;
    });
    builder.addCase(RescheduleDoctor.fulfilled, (state, action) => {
      state.rescheduleDoctor = action.payload;
    });
    builder.addCase(CouponList.fulfilled, (state, action) => {
      state.couponList = action.payload;
    });
    builder.addCase(MedicineCouponList.fulfilled, (state, action) => {
      state.couponList = action.payload;
    });

    builder.addCase(UpcomingFollowups.fulfilled, (state, action) => {
      state.upcomingFollowups = action.payload;
    });
    builder.addCase(MedicalHistoryList.fulfilled, (state, action) => {
      state.medicalHistory = action.payload;
    });
    builder.addCase(SpecialityList.fulfilled, (state, action) => {
      state.specialityList = action.payload;
    });
    builder.addCase(SpecialityWiseHealthProblems.fulfilled, (state, action) => {
      state.specialityHealthProblemsList = action.payload;
    });
    builder.addCase(GetHealthProblems.fulfilled, (state, action) => {
      state.specialityHealthProblemsList = action.payload;
    });
    builder.addCase(SubscribedPlansList.fulfilled, (state, action) => {
      state.subscribedPlansList = action.payload;
    });
  },
});

export const {
  toggleProblemsModal,
  SelectedPlan,
  activeDate,
  SelectedCouponCode,
  SelectedSpecialityId,
  SelectedDate,
  CouponCodeDetail,
  AppointmentTime,
  BookingDetail,
  clearSelectedCouponCode,
  setSelectedPlanDetails,
  clearBookConsultant,
  clearCouponCodeList,
  clearSelectedPlanDetails,
  clearSelectedPlan,
  ScheduleFollowupDetail,
  clearScheduleFollowupDetail,
  clearRescheduleDoctorDetails,
  clearRescheduleDoctor,
  clearBookingDetail,
  setFollowUpPassData,
  clearFollowUpPassData,
  cleaSelectedDate,
  coupencode,
  clearcoupencode,
  DoctorId,
  coupenListall

} = BookingSlice.actions;
export default BookingSlice.reducer;
