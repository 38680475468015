import React, { useState } from "react";
import { useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { LabTestCenters, LabtestCities, setLabTestCenterDetailsById } from "../../../Store/Reducer/LabtestSlice";
import { Icon } from "../../../Utilities/Icons";
import location from '../../../Assets/img/png/location.png'
import { GetCity } from "../../../Store/Reducer/CommonSlice";
import downloadArrow from '../../../Assets/img/svg/downarrow_solid.svg'
import LabCityModal from "../LabTestModel/LabCityModal";
import { HealthCategoryListData, HealthListData } from "../../../Store/Reducer/RadiologySlice";
const HealthCategoryListModal = (props) => {
    const { labTestCenterList, labTestCenterDetailsById } =
        useSelector(({ LabtestSlice }) => LabtestSlice);
    const { healthCategoryList } = useSelector(
        ({ RadiologySlice }) => RadiologySlice
    );

    //   const { session } = useSelector(({ AuthSlice }) => AuthSlice);
    const [cityId, setCityId] = useState()
    const dispatch = useDispatch();
    const [gender, setGender] = useState();

    function onChangeValue(event) {
        setGender(event.target.value);
    }

    useEffect(() => {
        if (gender) {
            healthListData?.filter(function (item) {
               
            });
        } else {
            setGender(HealthListData?.id);
            
        }
    }, [gender]);


    const { healthListData } = useSelector(
        ({ RadiologySlice }) => RadiologySlice
    );
   
    useEffect(() => {
        dispatch(HealthCategoryListData());
    }, [dispatch]);
    const [inputText, setInputText] = useState("");
    let inputHandler = (e) => {
        //convert input text to lower case
        var lowerCase = e.target.value.toLowerCase(); //.toLowerCase();
        setInputText(lowerCase);
    };
    const currentDate = new Date().toISOString().split('T')[0];
    const filteredData =
        healthCategoryList?.length &&
        healthCategoryList?.filter((el) => {
            //if no input the return the original
            if (inputText === "") {
                return el;
            }
            //return the item which contains the user input
            else {
                return el?.name?.toLowerCase().includes(inputText);
            }
        });
        const [centernames , setCenterNames] = useState();
 

    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                dialogClassName="lab_test_modal_box"
                backdrop="static"
            >
                <Modal.Header
                    className="lab_test_modal_box_head_padding"
                    style={{ marginBottom: "none", border: "none" }}
                >
                    <h5 className="lab_test_popup_title">Home Health-Care Test</h5>
                    <img
                        src={Icon.cross}
                        className="lab_test_popup_close"
                        onClick={props.onHide}
                        alt=""
                    ></img>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12">
                            <img
                                src={Icon.search}
                                className="select_city_search_icon"
                                alt=""
                            ></img>
                            <input
                                type="search"
                                style={{ width: "100%" }}
                                className="health_package_input_search"
                                placeholder="Search"
                                onChange={inputHandler}
                            />
                        </div>
                    </div>
                    <div className="lab_test_modal_box_2">
                        {
                            filteredData && filteredData?.map((item, index) => (
                                <>
                                    <div className="lab_member_box" key={index}>
                                        <div className="lab_member_box_inner">
                                            <div>
                                                <div className="health_radiological_popup_list_box_inner">
                                                    <div>
                                                        <img
                                                            className="select_radiology_img"
                                                            src={`${item?.image}`}
                                                            alt=""
                                                            height="70px"
                                                            width="70px"
                                                            style={{
                                                                borderRadius: "50%"
                                                            }}
                                                        ></img>
                                                    </div>
                                                    <div>
                                                        <label for="Radiological_Test7">
                                                            <h3
                                                                className="healthradiological_radiological_test_title"
                                                                onClick={() => {
                                                                    props.setRadiologyCenterDetailModel(true);
                                                                    props.onHide();
                                                                }}
                                                            >
                                                                {item?.name}
                                                            </h3>
                                                            <div className="healthradiological_star_box">
                                                                <h6 className="amounthealthcare">
                                                                    {/* ₹ {item?.amount} */}
                                                                </h6>
                                                            </div>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <input
                                                    type="radio"
                                                    id={item?.id}
                                                    name="gender"
                                                    className="healthradiological_input"
                                                    checked={gender == item?.id}
                                                    // onChange={(e) => onChangeValue(e)}
                                                    value={item?.id}
                                                   
                                                    onChange={(e) => {
                                                        dispatch(HealthListData({ category: item?.id }));
                                                        props?.formik?.setFieldValue("center_id", item?.id);
                                                        onChangeValue(e)
                                                        localStorage.setItem("centername", item?.name)
                                                    }}
                                                    onClick={() => setCenterNames(item)}
                                                    style={{accentColor: "#199a8e"}}
                                                />
                                              
                                            </div>
                                        </div>
                                    </div>
                                    <hr className="consult_member_hr" />
                                </>
                            ))}

                        <center>
                            <Button
                                className="lab_member_modal_btn"
                                onClick={() => {
                                    props.onHide();
                                    props.onNext();                     
                                }}
                            >
                                Continue
                            </Button>
                        </center>
                    </div>
                </Modal.Body>
            </Modal>
            {/* {isCityModalOpen && <LabCityModal />} */}
        </>
    );
};

export default HealthCategoryListModal;
