import moment from "moment";
import React, { useEffect, useState } from "react";
import { Accordion, Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { SelectedDate } from "../../../Store/Reducer/BookingSlice";
import { LabtestSlot } from "../../../Store/Reducer/LabtestSlice";
import { Icon } from "../../../Utilities/Icons";
import backimg from '../../../Assets/img/png/Frame 34278.png'

const LabTestSlotModel = (props) => {
  const { labtestSlotList, labTestCenterDetailsById } = useSelector(({ LabtestSlice }) => LabtestSlice);
  const current = new Date();

  const hours = current.getHours();
  const minutes = current.getMinutes();

  // Convert hours to 12-hour format
  const formattedHours = (hours % 12 === 0) ? 12 : hours % 12;

  // Determine whether it's AM or PM
  const amOrPm = hours >= 12 ? 'PM' : 'AM';

  // Add leading zeros to minutes if necessary
  const formattedMinutes = (minutes < 10) ? `0${minutes}` : minutes;

  // Combine all the formatted parts
  const formattedTime = `${formattedHours}:${formattedMinutes} ${amOrPm}`;



  const date = ` ${current.getFullYear()}/${current.getMonth() + 1
    }/${current.getDate()}`;
  const tomorrow = ` ${current.getFullYear()}/${current.getMonth() + 1}/${current.getDate() + 1
    }`;
  const nexttomorrow = ` ${current.getFullYear()}/${current.getMonth() + 1}/${current.getDate() + 2
    }`;
  const nexttomorrow2 = ` ${current.getFullYear()}/${current.getMonth() + 1}/${current.getDate() + 3
    }`;


  const dispatch = useDispatch();
  const { userSelectedDate } = useSelector(({ BookingSlice }) => BookingSlice);
  const [selecteDate, setSelectedDate] = useState(date);
  const [initialDataFetched, setInitialDataFetched] = useState(false);



  const [morning, setMorning] = useState();
  const [afternoon, setAfternoon] = useState();
  const [evening, setEvening] = useState();
  const [night, setNight] = useState();
  const handleCardClicks = (section, index) => {

    if (section === "morning") {
      setMorning(index);
      setAfternoon(null);
      setEvening(null);
      setNight(null);
    } else if (section === "afternoon") {
      setAfternoon(index);
      setMorning(null);
      setEvening(null);
      setNight(null);
    } else if (section === "evening") {
      setEvening(index);
      setNight(null);
      setMorning(null);
      setAfternoon(null);
    } else if (section === "night") {
      setNight(index);
      setAfternoon(null);
      setEvening(null);
      setMorning(null);
    }
  };
  useEffect(() => {
    const slotData = {
      test_center_id: labTestCenterDetailsById?.id,
      date: selecteDate, // Use the selected date instead of userSelectedDate
    };
    dispatch(LabtestSlot(slotData));
    props?.formik.setFieldValue("date", selecteDate)

  }, [dispatch, labTestCenterDetailsById?.id, selecteDate]);

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="book_modal_531"
      backdrop="static"
    >
      <Modal.Header
        className=""
        style={{ marginBottom: "none", border: "none" }}
      >
        <h5 className="book_date_time">Select Time For Sample Collection</h5>
        <img
          src={Icon.cross}
          className="book_modal_close"
          onClick={props.onHide}
        ></img>
      </Modal.Header>
      <Modal.Body>
        <div className="book_date_time_box">
          <h5 className="date_title">Select Date</h5>
          <h5 className="date_title">
            For, {moment(selecteDate).format("DD-MM-YYYY")}
          </h5>
          <div className="book_date_card_box">
            <>
              <div className="book_date_card_box">
                <div
                  className={`book_date_card  ${selecteDate === date ? "book_date_card_active " : null
                    } `}
                  onClick={() => {
                    setSelectedDate(date);
                    dispatch(SelectedDate(date));
                  }}
                >
                  <h5 className="book_date_card_text">Today</h5>
                </div>
                <div
                  className={`book_date_card  ${selecteDate === tomorrow ? "book_date_card_active " : null
                    } `}
                  onClick={() => {
                    setSelectedDate(tomorrow);
                    dispatch(SelectedDate(tomorrow));
                  }}
                >
                  <h5 className="book_date_card_text">
                    {moment(tomorrow).format("DD-MM-YYYY")}
                    { }
                  </h5>
                </div>
                <div
                  className={`book_date_card  ${selecteDate === nexttomorrow
                    ? "book_date_card_active "
                    : null
                    } `}
                  onClick={() => {
                    setSelectedDate(nexttomorrow);
                    dispatch(SelectedDate(nexttomorrow));
                  }}
                >
                  <h5 className="book_date_card_text">
                    {moment(nexttomorrow).format("DD-MM-YYYY")}
                  </h5>
                </div>
                <div
                  className={`book_date_card  ${selecteDate === nexttomorrow2
                    ? "book_date_card_active "
                    : null
                    } `}
                  onClick={() => {
                    setSelectedDate(nexttomorrow2);
                    dispatch(SelectedDate(nexttomorrow2));
                  }}
                >
                  <h5 className="book_date_card_text">
                    {moment(nexttomorrow2).format("DD-MM-YYYY")}
                  </h5>
                </div>
              </div>
            </>
          </div>
          <h5 className="time_title">Select time</h5>
          {
            labtestSlotList?.slots == undefined ? <>
              <div className="select_time_box">
              
                  <div className="col-md-12 d-flex justify-content-center align-items-center doctorequ ">
                    <div className="">
                      <img src={backimg} alt="Image Description" />
                    </div>
                  </div>
                
              </div></> : <>
              <div className="select_time_box">
                <Accordion alwaysOpen>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      Morning{" "}
                      <span className="time_avilable_subtitle">
                        {labtestSlotList?.slots?.morning
                          ? labtestSlotList.slots.morning.reduce(
                            (count, item) => {
                              const slotTime = moment(item.start_time, "hh:mm A");
                              if (slotTime.isAfter(moment(formattedTime, "hh:mm A"))) {
                                return count + 1;
                              }
                              return count;
                            },
                            0
                          )
                          : 0} Slots available
                      </span>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="abc">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="time_slot_card_box" style={{ paddingRight: "10px" }}>
                              {labtestSlotList &&
                                labtestSlotList?.slots?.morning?.map((item, index) => {
                                  const slotTime = moment(item.start_time, 'hh:mm A');
                                  if (slotTime.isAfter(moment(formattedTime, 'hh:mm A'))) {
                                    return (
                                      <div className="consultation_cancel_modal consulatation-time" key={index}>
                                        <div
                                          className={`time_slot_card${morning === index ? " time_slot_card_active" : ""}`}
                                        >
                                          <label>
                                            <input
                                              type="radio"
                                              name="appointmentDetails"
                                              onClick={() => { handleCardClicks("morning", index); props?.formik.setFieldValue("slot", item.start_time) }}
                                              style={{ accentColor: "#199a8e" }}
                                            />
                                            <h5 className="time_slot_card_text">
                                              {item.start_time}
                                            </h5>
                                          </label>
                                        </div>
                                      </div>
                                    );
                                  }
                                  return null;
                                })
                              }

                            </div>
                          </div>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                <Accordion defaultActiveKey={["1"]} alwaysOpen>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      Afternoon{" "}
                      <span className="time_avilable_subtitle">
                        {labtestSlotList?.slots?.afternoon
                          ? labtestSlotList.slots.afternoon.reduce(
                            (count, item) => {
                              const slotTime = moment(item.start_time, "hh:mm A");
                              if (slotTime.isAfter(moment(formattedTime, "hh:mm A"))) {
                                return count + 1;
                              }
                              return count;
                            },
                            0
                          )
                          : 0} Slots available
                      </span>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="abc">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="time_slot_card_box" style={{ paddingRight: "10px" }}>
                              {labtestSlotList &&
                                labtestSlotList?.slots?.afternoon?.map((item, index) => {
                                  const slotTime = moment(item.start_time, 'hh:mm A');
                                  if (slotTime.isAfter(moment(formattedTime, 'hh:mm A'))) {
                                    return (
                                      <div className="consultation_cancel_modal consulatation-time" key={index}>
                                        <div
                                          className={`time_slot_card${afternoon === index ? " time_slot_card_active" : ""}`}
                                        >
                                          <label>
                                            <input
                                              type="radio"
                                              name="appointmentDetails"
                                              onClick={() => { handleCardClicks("afternoon", index); props?.formik.setFieldValue("slot", item.start_time) }}
                                              style={{ accentColor: "#199a8e" }}
                                            />
                                            <h5 className="time_slot_card_text">
                                              {item.start_time}
                                            </h5>
                                          </label>
                                        </div>
                                      </div>
                                    );
                                  }
                                  return null;
                                })
                              }

                            </div>
                          </div>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                <Accordion defaultActiveKey={["2"]} alwaysOpen>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      evening{" "}
                      <span className="time_avilable_subtitle">
                        {labtestSlotList?.slots?.evening
                          ? labtestSlotList.slots.evening.reduce(
                            (count, item) => {
                              const slotTime = moment(item.start_time, "hh:mm A");
                              if (slotTime.isAfter(moment(formattedTime, "hh:mm A"))) {
                                return count + 1;
                              }
                              return count;
                            },
                            0
                          )
                          : 0} Slots available
                      </span>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="abc">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="time_slot_card_box" style={{ paddingRight: "10px" }}>
                              {labtestSlotList &&
                                labtestSlotList?.slots?.evening?.map((item, index) => {
                                  const slotTime = moment(item.start_time, 'hh:mm A');
                                  if (slotTime.isAfter(moment(formattedTime, 'hh:mm A'))) {
                                    return (
                                      <div className="consultation_cancel_modal consulatation-time" key={index}>
                                        <div
                                          className={`time_slot_card${evening === index ? " time_slot_card_active" : ""}`}
                                        >
                                          <label>
                                            <input
                                              type="radio"
                                              name="appointmentDetails"
                                              onClick={() => { handleCardClicks("evening", index); props?.formik.setFieldValue("slot", item.start_time) }}
                                              style={{ accentColor: "#199a8e" }}
                                            />
                                            <h5 className="time_slot_card_text">
                                              {item.start_time}
                                            </h5>
                                          </label>
                                        </div>
                                      </div>
                                    );
                                  }
                                  return null;
                                })
                              }

                            </div>
                          </div>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                <Accordion defaultActiveKey={["3"]} alwaysOpen>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      night{" "}
                      <span className="time_avilable_subtitle">
                        {labtestSlotList?.slots?.night
                          ? labtestSlotList.slots.night.reduce(
                            (count, item) => {
                              const slotTime = moment(item.start_time, "hh:mm A");
                              if (slotTime.isAfter(moment(formattedTime, "hh:mm A"))) {
                                return count + 1;
                              }
                              return count;
                            },
                            0
                          )
                          : 0} Slots available
                      </span>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="abc">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="time_slot_card_box" style={{ paddingRight: "10px" }}>
                              {labtestSlotList &&
                                labtestSlotList?.slots?.night?.map((item, index) => {
                                  const slotTime = moment(item.start_time, 'hh:mm A');
                                  if (slotTime.isAfter(moment(formattedTime, 'hh:mm A'))) {
                                    return (
                                      <div className="consultation_cancel_modal consulatation-time" key={index}>
                                        <div
                                          className={`time_slot_card${night === index ? " time_slot_card_active" : ""}`}
                                        >
                                          <label>
                                            <input
                                              type="radio"
                                              name="appointmentDetails"
                                              onClick={() => { handleCardClicks("night", index); props?.formik.setFieldValue("slot", item.start_time) }}
                                              style={{ accentColor: "#199a8e" }}
                                            />
                                            <h5 className="time_slot_card_text">
                                              {item.start_time}
                                            </h5>
                                          </label>
                                        </div>
                                      </div>
                                    );
                                  }
                                  return null; // If slot is before current time, return null to skip rendering
                                })
                              }

                            </div>
                          </div>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div></>
          }

          <center>
            <Button
              className="time_slot_btn"
              onClick={() => {
                props.onHide(true);
              }}
            >
              Done
            </Button>
          </center>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default LabTestSlotModel;