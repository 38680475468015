import moment from "moment";
import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const OrderSuccessModel = (props) => {
  const { medicineOrderReview } = useSelector(({ OrderSilce }) => OrderSilce);
  const navigate = useNavigate();
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="modal_415"
      backdrop="static"
    >
      <Modal.Header
        className="modal_bg popup_modal_head"
        style={{ marginBottom: "none", border: "none" }}
      >
        <div className="">
          <h5 className="payment_done_popup_modal_title">
            Order Successfully placed
          </h5>
        </div>
      </Modal.Header>
      <Modal.Body className="modal_bg payment_modal_body">
        {medicineOrderReview?.length &&
          medicineOrderReview?.map((item) => {
            let item_product = JSON.parse(item?.products);
            return (
              <>
                <div className="payment_done_card_box mb_10">
                  <h5 className="orderplaced_medicines_ordered_text">
                    Medicines Ordered
                  </h5>
                  <hr className="medicine_order_hr" />
                  {item_product?.map((product) => (
                    <>
                      <div className="order_placed">
                        <div>
                          <div className="buy_medicine_box">
                            <h3 className="buy_medicine_name">
                              {product?.product_name}
                            </h3>
                            <p className="buy_medicine_strip_of">
                              Strip of 15 tablet
                            </p>
                            <p className="buy_medicine_strip_of">
                              Quantity:{product?.qty}{" "}
                            </p>
                          </div>
                        </div>
                        <div>
                          <div className="buy_medicine_right_detail">
                            <h5 className="buy_medicine_value">
                              ₹ {product?.mrp}
                            </h5>
                          </div>
                        </div>
                      </div>
                      <hr className="buy_medicine_hr" />
                    </>
                  ))}
                </div>
                <div className="payment_done_card_box mb_10">
                  <h5 className="orderplaced_medicines_ordered_text">
                    Order Info
                  </h5>
                  <hr className="medicine_order_hr" />
                  <div className="order_placed">
                    <div>
                      <h5 className="order_placed_on_text">Order Placed on</h5>
                    </div>
                    <div>
                      <h5 className="order_placed_on_value">
                        {moment(item?.created_at).format(
                          "DD MMM YYYY | hh:mm a"
                        )}
                      </h5>
                    </div>
                  </div>
                  <div className="order_placed">
                    <div>
                      <h5 className="order_placed_on_text">Delivery Date</h5>
                    </div>
                    <div>
                      <h5 className="order_placed_on_value">26 Aug 2022</h5>
                    </div>
                  </div>
                </div>
                <div className="payment_done_card_box">
                  <h5 className="orderplaced_medicines_ordered_text">
                    Payment info
                  </h5>
                  <hr className="medicine_order_hr" />
                  <div className="order_placed">
                    <div>
                      <h5 className="order_placed_on_text">Order ID</h5>
                    </div>
                    <div>
                      <h5 className="order_placed_on_value">
                        {item?.order_id}
                      </h5>
                    </div>
                  </div>
                  <div className="order_placed">
                    <div>
                      <h5 className="order_placed_on_text">Txn. Id</h5>
                    </div>
                    <div>
                      <h5 className="order_placed_on_value">
                        {item?.transaction_id}
                      </h5>
                    </div>
                  </div>
                  <div className="order_placed">
                    <div>
                      <h5 className="order_placed_on_text">prepaid amount</h5>
                    </div>
                    <div>
                      <h5 className="order_placed_on_value">
                        ₹ {item?.prepaid}
                      </h5>
                    </div>
                  </div>
                  <div className="order_placed">
                    <div>
                      <h5 className="order_placed_on_text">wallet amount</h5>
                    </div>
                    <div>
                      <h5 className="order_placed_on_value">
                        ₹ {item?.wallet_amount}
                      </h5>
                    </div>
                  </div>
                  <div className="order_placed">
                    <div>
                      <h5 className="order_placed_on_text">Date and Time</h5>
                    </div>
                    <div>
                      <h5 className="order_placed_on_value">
                        {moment(item?.updated_at).format(
                          "DD MMM YYYY | hh:mm a"
                        )}
                      </h5>
                    </div>
                  </div>
                </div>
              </>
            );
          })}

        <center>
          <Button
            className="payment_ok_btn"
            onClick={() => {
              props.onHide();
              navigate("/");
            }}
          >
            OK
          </Button>
        </center>
      </Modal.Body>
      <Modal.Footer className="modal_bg"></Modal.Footer>
    </Modal>
  );
};

export default OrderSuccessModel;
