import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import "../../../../Assets/css/responsive.css";
import { useDispatch, useSelector } from "react-redux";
import {
  ConsultationMemberDelete,
  EditMemberProfile,
  GetPackageMemberDetails,
} from "../../../../Store/Reducer/ProfileReducer";
import { useNavigate, useParams } from "react-router-dom";
import AddMember from "../../../Common/MemberModel/AddMember";

const PersonalInfo = (props) => {
  const [newMembers, setNewMembers] = useState(false);
  const { packageMemberDetails } = useSelector(
    ({ ProfileSlice }) => ProfileSlice
  );
  const { session } = useSelector(({ AuthSlice }) => AuthSlice);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  let updatemedical_histories = packageMemberDetails?.medical_histories?.map(
    (item) => {
      return item?.medical_history_id;
    }
  );
  let editMemberinitialState = {
    name: packageMemberDetails?.name,
    email: packageMemberDetails?.email,
    mobile_number: packageMemberDetails?.mobile_number,
    age: packageMemberDetails?.age,
    member_id: packageMemberDetails?.id,
    height: packageMemberDetails?.height,
    weight: packageMemberDetails?.weight,
    gender: packageMemberDetails?.gender,
    blood_group: packageMemberDetails?.blood_group,
    birthdate: packageMemberDetails?.birthdate,
    medical_history: updatemedical_histories,
  };

  useEffect(() => {
    dispatch(GetPackageMemberDetails(id));
  }, [dispatch, id]);
  return (
    <>
      <AddMember
        show={newMembers}
        addEditMemberinitialState={editMemberinitialState}
        packageMemberDetails={packageMemberDetails}
        addEditMember={EditMemberProfile}
        onHide={() => setNewMembers(false)}
      />
      <div className="ownprofile_body_box_card_head">
        <div className="row">
          <div className="col-md-6 col-6">
            <h5 className="ownprofile_body_card_head_title">Personal Info</h5>
          </div>
          <div className="col-md-6 col-6">
            {packageMemberDetails?.name === `${session?.name} (self)` ? null : (
              <Button
                className="ownprofile_delete_btn"
                onClick={() => {
                  dispatch(ConsultationMemberDelete(packageMemberDetails?.id));
                  navigate(-1);
                }}
              >
                Delete
              </Button>
            )}

            <Button
              className="ownprofile_edit_btn"
              onClick={() => setNewMembers(true)}
            >
              Edit
            </Button>
          </div>
        </div>
      </div>
      <div className="ownprofile_body_box_card_body">
        <div className="row">
          <div className="col-md-8">
            <div className="ownprofile_info_box">
              <div className="row">
                <div className="col-md-6 col-6">
                  <h5 className="ownprofile_info_box_title">Full Name</h5>
                </div>
                <div className="col-md-6 col-6">
                  <h5 className="ownprofile_info_box_value">
                    {packageMemberDetails?.name}
                  </h5>
                </div>
              </div>
              <hr className="ownprofile_info_box_hr" />
              <div className="row">
                <div className="col-md-6 col-6">
                  <h5 className="ownprofile_info_box_title">Phone Number</h5>
                </div>
                <div className="col-md-6 col-6">
                  <h5 className="ownprofile_info_box_value">
                    {packageMemberDetails?.mobile_number}
                  </h5>
                </div>
              </div>
              <hr className="ownprofile_info_box_hr" />
              <div className="row">
                <div className="col-md-6 col-6">
                  <h5 className="ownprofile_info_box_title">Email</h5>
                </div>
                <div className="col-md-6 col-6">
                  <h5 className="ownprofile_info_box_value">
                    {packageMemberDetails?.email}
                  </h5>
                </div>
              </div>
              <hr className="ownprofile_info_box_hr" />
              <div className="row">
                <div className="col-md-6 col-6">
                  <h5 className="ownprofile_info_box_title">Age</h5>
                </div>
                <div className="col-md-6 col-6">
                  <h5 className="ownprofile_info_box_value">
                    {packageMemberDetails?.age}
                  </h5>
                </div>
              </div>
              <hr className="ownprofile_info_box_hr" />
              <div className="row">
                <div className="col-md-6 col-6">
                  <h5 className="ownprofile_info_box_title">Gender</h5>
                </div>
                <div className="col-md-6 col-6">
                  <h5 className="ownprofile_info_box_value">
                    {packageMemberDetails?.gender}
                  </h5>
                </div>
              </div>
              <hr className="ownprofile_info_box_hr" />
              <div className="row">
                <div className="col-md-6 col-6">
                  <h5 className="ownprofile_info_box_title">Height (cm)</h5>
                </div>
                <div className="col-md-6 col-6">
                  <h5 className="ownprofile_info_box_value">
                    {packageMemberDetails?.height}
                  </h5>
                </div>
              </div>
              <hr className="ownprofile_info_box_hr" />
              <div className="row">
                <div className="col-md-6 col-6">
                  <h5 className="ownprofile_info_box_title">Weight (kg)</h5>
                </div>
                <div className="col-md-6 col-6">
                  <h5 className="ownprofile_info_box_value">
                    {packageMemberDetails?.weight}
                  </h5>
                </div>
              </div>
              <hr className="ownprofile_info_box_hr" />
              <div className="row">
                <div className="col-md-6 col-6">
                  <h5 className="ownprofile_info_box_title">Blood Group</h5>
                </div>
                <div className="col-md-6 col-6">
                  <h5 className="ownprofile_info_box_value">
                    {packageMemberDetails?.blood_group}
                  </h5>
                </div>
              </div>
              <hr className="ownprofile_info_box_hr" />
              <div className="row">
                <div className="col-md-6 col-6">
                  <h5 className="ownprofile_info_box_title">Medical History</h5>
                </div>
                <div className="col-md-6 col-6">
                  {packageMemberDetails?.medical_histories?.length > 0 &&
                    packageMemberDetails?.medical_histories?.map(
                      (histories, index) => (
                        <label className="mb-2 pb-1">
                          <span className="tag_name_box mr_5" key={index}>
                            {histories?.title}
                          </span>
                        </label>
                      )
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PersonalInfo;
