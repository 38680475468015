import moment from "moment";
import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { clearSelectedCouponCode, BookConsultant, clearBookConsultant } from "../../../Store/Reducer/BookingSlice";

const HealthBookingSucessModal = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();


  let health_problems = props?.cretaBookConsultant?.data?.health_problems?.map(
    function (i) {
      return i?.title;
    }
  );


  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="modal_455"
      backdrop="static"
    >
      <Modal.Header
        className="modal_bg popup_modal_head"
        style={{ marginBottom: "none", border: "none" }}
      >
        <div className="">
          <h5 className="popup_modal_title">Booking Successfully Done</h5>
        </div>
      </Modal.Header>
      <Modal.Body className="modal_bg payment_modal_body">
        {/* <div className="book_successfully_card mb_10">
          <center>
            <img
              style={{ width: "100px", height: "100px", borderRadius: "50%" }}
              src={props.cretaBookConsultant?.data?.doctor_image}
              alt="My Awesome Image"
            ></img>
            <h5 className="book_successfully_doc_title">
              {props.cretaBookConsultant?.data?.doctor_name}
            </h5>
            <h6 className="book_successfully_doc_subtitle">
              {props.cretaBookConsultant?.data?.doctor_speciality}
            </h6>
            <hr className="book_successfully_hr" />
          </center>
          <div className="row">
            <div className="col-md-6">
              <h5 className="book_successfully_details_title">Problem</h5>
            </div>
            <div className="col-md-6">
              <h5 className="book_successfully_details_value">
                {health_problems?.toString()}
              </h5>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <h5 className="book_successfully_details_title">Booked for</h5>
            </div>
            <div className="col-md-6">
              <h5 className="book_successfully_details_value_active">
                {props.cretaBookConsultant?.data?.patient_details?.name}
              </h5>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <h5 className="book_successfully_details_title">Date and Time</h5>
            </div>
            <div className="col-md-6">
              <h5 className="book_successfully_details_value">
                {moment(
                  props.cretaBookConsultant?.data?.appointment_date
                ).format("DD MMM YYYY")}{" "}
                |{" "}
                {moment(
                  props.cretaBookConsultant?.data?.appointment_time,
                  "HH:mm"
                ).format("h:mm A")}
              </h5>
            </div>
          </div>
        </div> */}
        <div className="book_successfully_card">
          <h5 className="payment_info_title">Booking Details</h5>
          <hr className="book_successfully_hr" />
          <div className="row">
            <div className="col-md-6">
              <h5 className="book_successfully_details_title">Booking ID</h5>
            </div>
            <div className="col-md-6">
              <h5 className="book_successfully_details_value">
                {props?.specialitybooking?.transaction_id}
              </h5>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <h5 className="book_successfully_details_title">Paid amount</h5>
            </div>
            <div className="col-md-6">
              <h5 className="book_successfully_details_value">
                ₹ {props?.specialitybooking?.total_amount}
              </h5>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <h5 className="book_successfully_details_title">Date and Time</h5>
            </div>
            <div className="col-md-6">
              <h5 className="book_successfully_details_value">
                {moment(props.cretaBookConsultant?.data?.updated_at).format(
                  "DD MMM YYYY | h:mm A"
                )}
              </h5>
            </div>
          </div>
        </div>
        <center>
          <Button
            className="payment_ok_btn"
            onClick={() => {
              props.onHide();
              navigate("/");
              dispatch(clearSelectedCouponCode());
              dispatch(clearBookConsultant());
            }}
          >
            OK
          </Button>
        </center>
      </Modal.Body>
      <Modal.Footer className="modal_bg"></Modal.Footer>
    </Modal>
  );
};

export default HealthBookingSucessModal;
