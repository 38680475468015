import React from "react";
import moment from "moment";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  CardHome,
  clearSelectedCouponCode,
  cleaSelectedDate,
  CouponList,
} from "../../../Store/Reducer/BookingSlice";
import {
  clearSelectedMember,
  GoldMemberBookingDetails,
  LabtestAddressList,
  LabtestStates,
} from "../../../Store/Reducer/LabtestSlice";
import {
  clearRadiologyCartItem,
  clearRadiologyTestCenterDetailsById,
  GoldMemberBokingstatus,
  RadiologyBookingCreate,
  RadiologyBookingDetails,
  RadiologyTestCenters,
  removeItem,
  setRadiologyBookingDetailsById,
  walletPrice,
} from "../../../Store/Reducer/RadiologySlice";
import { Icon, Logo } from "../../../Utilities/Icons";
import ApplyCouponModel from "../../Common/ApplyCouponModel/ApplyCouponModel";
import SelectAddressModel from "../../Common/LabTestModel/SelectAddressModel";
import SelecteMemberModel from "../../Common/MemberModel/SelecteMemberModel";
import * as Yup from "yup";
import { useFormik } from "formik";
import RadiologicalModel from "../../Common/RadiolodicalModel/RadiologicalModel";
import RadiologicalTestSlotModel from "../../Common/RadiolodicalModel/RadiologicalTestSlotModel";
import PaymentSuccessfullModel from "../../Common/RadiolodicalModel/PaymentSuccessfullModel";
import RadiologyCenterModel from "../../Common/RadiolodicalModel/RadiologyCenterModel";
import RadiologyCenterDetailModel from "../../Common/RadiolodicalModel/RadiologyCenterDetailModel";
import { HiOutlineMenu } from 'react-icons/hi';
import { useNavigate } from "react-router-dom";
import { clickCardHome } from "../../../Store/Reducer/ProfileReducer";
function RadiologyTestCart() {
  var today = new Date();
  const { session } = useSelector(({ AuthSlice }) => AuthSlice);
  const [updatedCart, setUpdatedCart] = useState();
  const [show, SetShow] = useState(false);
  const { selectedMemberDetail, goldMembershipdetail } = useSelector(
    ({ LabtestSlice }) => LabtestSlice
  );
  const navigate = useNavigate();

  const { radiologyCart, radiologyTestCenterDetailsById, goldMembershipStatus, radiologyTestCenterList } = useSelector(
    ({ RadiologySlice }) => RadiologySlice
  );
  function formatDate(dateString) {
    if (!dateString) return ""; // Return an empty string if dateString is empty or undefined
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0"); // Get day (2 digits)
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Get month (2 digits)
    const year = date.getFullYear().toString().slice(-2); // Get last 2 digits of the year
    return `${day}-${month}-${year}`;
  }
  const {coupencodeid } = useSelector(
    ({ BookingSlice }) => BookingSlice
  );

  const { userWallet } = useSelector(({ ProfileSlice }) => ProfileSlice);
  const getTotal = () => {
    let totalQuantity = 0;
    let totalPrice = 0;
    radiologyCart?.forEach((item) => {
      totalPrice += item?.price * item.quantity;
    });
    return { totalPrice, totalQuantity };
  };
  const { radiologyData } = useSelector(({ RadiologySlice }) => RadiologySlice);
  const [radiomodal, setRadioshow] = useState(false);
  const [selectMember, setSelectMember] = useState(false);
  const [timeSample, settimeSample] = useState(false);
  const [applyCoupon, setApplyCoupon] = useState(false);
  const [walletbox, setwalletbox] = React.useState(userWallet);
  const [successfullModel, setSuccessfullModel] = useState(false);
  // const { userSelectedDate } = useSelector(({ BookingSlice }) => BookingSlice);

  const [totalamount, setTotalAmount] = useState(
    Math.round(getTotal()?.totalPrice)
  );
  const [unchecktotal, setUnchecktotal] = useState(
    Math.round(getTotal()?.totalPrice)
  );
  const dispatch = useDispatch();
  const { couponList, couponCodeDetail } = useSelector(
    ({ BookingSlice }) => BookingSlice
  );

  const [radiomodal1, setRadioshow1] = useState(false);

  const [selectAddressModel, setSelecteAddressModel] = useState(false);
  const [radiologyCenterDetailModel, setRadiologyCenterDetailModel] =
    useState(false);
  const {
    wallet_price
  } = useSelector(({ RadiologySlice }) => RadiologySlice);
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);
  const handleCheckboxClick = () => {
    // Replace the URL below with the link you want to open
    const link = 'https://thsindia.in/medical-web/privacy-policy';
    window.open(link, '_blank'); // Opens the link in a new tab
  };
  const options = {
    key: process.env.REACT_APP_PAYMENT_KEY,
    key_secret: process.env.REACT_APP_PAYMENT_SECRET_KEY,

    prefill: {
      name: session?.name,
      contact: session?.mobile_number,
      email: session?.email,
    },
    notes: {
      address: "some address",
    },
    theme: {
      color: "#F37254",
      hide_topbar: false,
    },
  };
  const openPayModal = (options) => {
    var rzp1 = new window.Razorpay(options);
    rzp1.open();
  };
  let updatedradiologyCart = radiologyCart?.map(function (i) {
    return { id: i?.id, price: i?.price };
  });

  const createBooking = (values, resetForm) => {
    try {
      let tempOptions = {
        ...options,
        amount: Math.round(totalamount) * 100,
        currency: "INR",
        name: "THS-User",
        description: "some description",
        image: Logo.THS_logo,
        handler: async function (response) {
          if (response) {
            const walletprice = Math.round(getTotal().totalPrice) - discountAmount
    
            let newValues = {
              ...values,
              test_details: JSON.stringify(updatedradiologyCart), //radiologyCart?.map(function (i) {
              transaction_id: response.razorpay_payment_id,
              total_amount: totalamount,
              total_paid_amount: totalamount,
              booking_time: formik?.values?.start_time,
              wallet: wallet_price?.cut_amount || 0,
              slot_id: formik?.values?.slot_id,
              coupen_code:coupencodeid

            };
            dispatch(RadiologyBookingCreate(newValues)).then((res) => {
              if (res.payload.booking_id) {
                dispatch(
                  RadiologyBookingDetails(res?.payload?.booking_id)
                ).then((res1) => {
                  if (res1?.payload) {
                    dispatch(setRadiologyBookingDetailsById(res1));
                    setSuccessfullModel(true);
                    dispatch(clearRadiologyCartItem());
                    dispatch(clearSelectedCouponCode());
                    dispatch(clearSelectedMember());
                    dispatch(clearRadiologyTestCenterDetailsById());
                    dispatch(cleaSelectedDate());
                    resetForm();
                    setTotalAmount("");
                  }
                });
              }
            });
          }
        },
      };
      const walletprice = Math.round(getTotal().totalPrice) - discountAmount
      let OtherOptions = {
        member_id: selectedMemberDetail?.id,
        discount: couponCodeDetail?.coupon_percentage
          ? couponCodeDetail?.coupon_percentage
          : 0,
        wallet: wallet_price?.cut_amount || 0,
        ADDRESS: "",
        MEMBER: "",
        address_id: "",
        termsAndConditions: false,
        transaction_id: "user_wallet",
        convenience_fee: "10",
        type: "",
        gold_discount: "10",
        total_amount: totalamount,
        total_paid_amount: totalamount,
        center_id: radiologyTestCenterDetailsById?.id,
        slot_id: formik?.values?.slot_id,
        booking_time: formik?.values?.start_time,
        booking_date: moment(today).format("DD-MM-YYYY"),
        termsAndConditions: false,
        test_details: JSON.stringify(updatedradiologyCart),
        coupen_code:coupencodeid
      }
      if (totalamount == 0) {
        dispatch(RadiologyBookingCreate(OtherOptions)).then((res) => {
          if (res.payload.booking_id) {
            dispatch(
              RadiologyBookingDetails(res?.payload?.booking_id)
            ).then((res1) => {
              if (res1?.payload) {
                dispatch(setRadiologyBookingDetailsById(res1));
                setSuccessfullModel(true);
                dispatch(clearRadiologyCartItem());
                dispatch(clearSelectedCouponCode());
                dispatch(clearSelectedMember());
                dispatch(clearRadiologyTestCenterDetailsById());
                dispatch(cleaSelectedDate());
                resetForm();
                setTotalAmount("");
              }
            });
          }
        });
      } else {
        openPayModal(tempOptions);
      }

    } catch (error) { }
  };
  let test_ids = radiologyCart?.map(function (i) {
    return i?.id;
  });
  useEffect(() => {
    dispatch(
      clickCardHome({
        type: "3",
        radiologytestid: test_ids?.toString(),
      })
    );
  }, [dispatch, radiologyCart]);

  const radiologyTestSchema = Yup.object({
    center_id: Yup.string().required("Please Select Address"),
    slot_id: Yup.string().required("Please Select Slot"),
    termsAndConditions: Yup.bool().oneOf(
      [true],
      "For radilogy test Before you book slot for diagnostic ,make sure you are aware about THS terms and conditions"
    ),
  });

  const formik = useFormik({

    initialValues: {
      member_id: selectedMemberDetail?.id,
      center_id: radiologyTestCenterDetailsById?.id,
      slot_id: "",
      booking_time: "",
      booking_date: moment(today).format("DD-MM-YYYY"),
      termsAndConditions: false,
    },
    validationSchema: radiologyTestSchema,
    onSubmit: (values, { resetForm }) => {
      // do your stuff
      createBooking(values, resetForm);
    },
  });

  useEffect(() => {
    setSelectMember(false)
    dispatch(CouponList({ coupon_type: 3 }));
  }, [dispatch]);

  const coupendiscount = (Math.round(getTotal()?.totalPrice) * Number(couponCodeDetail?.coupon_percentage)) / 100

  function handleChange(event) {
    setwalletbox((prevFormData) => {
      return {
        ...prevFormData,
        [event.target.name]: event.target.checked,
      };
    });
    if (event.target.checked) {
      if (couponCodeDetail) {
        const amout = getTotal()?.totalPrice - walletbox.wallet - coupendiscount - discountAmount;
      
        setTotalAmount(amout <= 0 ? "0" : amout);
        const cutOfPrice = getTotal()?.totalPrice - (coupendiscount + discountAmount)
        const incAmount = walletbox.wallet - cutOfPrice;
        dispatch(walletPrice({ "wallet_prices": cutOfPrice >= walletbox?.wallet ? "0" : incAmount, "cut_amount": cutOfPrice >= walletbox?.wallet ? "0" : cutOfPrice }));
      } else {
        const amout = totalamount - walletbox.wallet;
        const data = totalamount - walletbox.wallet - coupendiscount - discount
        let removeDis;
        if (goldMembershipdetail !== true) {
          removeDis = totalamount
        } else {
          removeDis = totalamount
        }
        dispatch(walletPrice({ "wallet_prices": removeDis <= 0 ? 0 : (walletbox?.wallet - removeDis) <= 0 ? 0 : walletbox?.wallet - removeDis, "cut_amount": walletbox?.wallet - removeDis <= 0 ? walletbox?.wallet : removeDis }));
        if (amout >= 0) {
          setTotalAmount(Math.max(isNaN(amout) ? 0 : amout));
        } else {
          setTotalAmount(Math.max(isNaN(data) ? 0 : data));
        }
      }
    } else {
      if (couponCodeDetail) {
        const amount = getTotal()?.totalPrice - (coupendiscount + discountAmount)
        if (wallet_price?.wallet_prices == 0) {
          dispatch(walletPrice({ "wallet_prices": wallet_price?.cut_amount }));
          setTotalAmount(amount <= 0 ? "0" : amount);
        } else {
          dispatch(walletPrice({ "wallet_prices": wallet_price?.wallet_prices + (getTotal()?.totalPrice - (coupendiscount + discountAmount)) }));
          setTotalAmount(amount <= 0 ? "0" : amount);
        }

      } else {
        if (goldMembershipdetail !== true) {
          const amount = (getTotal()?.totalPrice - discountAmount);
          if (wallet_price?.wallet_prices == 0) {
            dispatch(walletPrice({ "wallet_prices": wallet_price?.cut_amount }));
            setTotalAmount(amount <= 0 ? "0" : amount);
          } else {
            dispatch(walletPrice({ "wallet_prices": wallet_price?.wallet_prices + (getTotal()?.totalPrice - discountAmount) }));
            setTotalAmount(amount <= 0 ? "0" : amount);
          }
        } else {
          const amount = getTotal()?.totalPrice
          if (wallet_price?.wallet_prices == 0) {
            dispatch(walletPrice({ "wallet_prices": wallet_price?.cut_amount }));
            setTotalAmount(amount <= 0 ? "0" : amount);
          } else {
            dispatch(walletPrice({ "wallet_prices": wallet_price?.wallet_prices + (getTotal()?.totalPrice) }));
            setTotalAmount(amount <= 0 ? "0" : amount);
          }
        }
      }

    }
  }


  useEffect(() => {
    dispatch(LabtestAddressList());
    dispatch(LabtestStates());
    // dispatch(walletPrice({ "wallet_prices": walletbox?.wallet, "cut_amount": 0 }));
    dispatch(walletPrice({ "wallet_prices": walletbox?.wallet }));
  }, [dispatch]);
  useEffect(() => {
    dispatch(GoldMemberBookingDetails())
    dispatch(clearSelectedCouponCode())
  }, [dispatch])

  useEffect(() => {
    if (radiologyCart.length > 0) {

      if (goldMembershipdetail != true) {
        const discountAmount = (Math.round(getTotal()?.totalPrice) * goldMembershipStatus?.discount) / 100;
    
        setTotalAmount(Math.round(getTotal()?.totalPrice) - discountAmount);
      } else {
    
        setTotalAmount(Math.round(getTotal()?.totalPrice));
        setUpdatedCart(radiologyCart);
      }

    } else {
      setTotalAmount(Math.round(getTotal()?.totalPrice));
      setUpdatedCart(radiologyCart);
    }
  }, [radiologyCart, updatedCart]);
  const getDiscount = (cupon) => {
    if (cupon?.coupon_percentage) {
      setTotalAmount(
        Math.round(getTotal()?.totalPrice) -
        (Math.round(getTotal()?.totalPrice) *
          Number(cupon?.coupon_percentage)) /
        100
      );
    }

  };
  useEffect(() => {
    if (couponCodeDetail) {
      if (goldMembershipdetail != true) {
        const discountAmount = (Math.round(getTotal()?.totalPrice) * goldMembershipStatus?.discount) / 100;
        setTotalAmount(
          Math.round(getTotal()?.totalPrice) -
          (Math.round(getTotal()?.totalPrice) *
            Number(couponCodeDetail?.coupon_percentage)) /
          100 - discountAmount
        );
      } else {
        setTotalAmount(
          Math.round(getTotal()?.totalPrice) -
          (Math.round(getTotal()?.totalPrice) *
            Number(couponCodeDetail?.coupon_percentage)) /
          100
        );
      }
    } else if (goldMembershipdetail != true) {
      const discountAmount = (Math.round(getTotal()?.totalPrice) * goldMembershipStatus?.discount) / 100;
      setTotalAmount(Math.round(getTotal()?.totalPrice) - discountAmount);
    } else {
      setTotalAmount(Math.round(getTotal()?.totalPrice));
    }
  }, [couponCodeDetail, goldMembershipdetail]);
  const discountAmount = (Math.round(getTotal()?.totalPrice) * goldMembershipStatus?.discount) / 100;
  const { bookingDetail } = useSelector(({ BookingSlice }) => BookingSlice);
  useEffect(() => {
    if (bookingDetail?.id) {
      setRadioshow1(true);
    }
  }, [bookingDetail?.id]);
  const discountAmounts = (Math.round(getTotal()?.totalPrice) * goldMembershipStatus?.discount) / 100;
  let discount = getTotal()?.totalPrice - totalamount;
  const cityJSON = localStorage.getItem("USER");
  const cityObject = JSON.parse(cityJSON);


  const {
    cards
  } = useSelector(({ BookingSlice }) => BookingSlice);
  useEffect(() => {
    dispatch(CardHome())
    dispatch(GoldMemberBokingstatus(cards?.gold_membership?.id));
  }, [dispatch, cards?.gold_membership?.id])
 

  return (
    <>
      <RadiologicalModel
        show={radiomodal}
        radiologyData={radiologyData}
        onNext={() => {
          setRadioshow(false);
          setSelectMember(true);
        }}
        formik={formik}
        onHide={(e) => {
          setRadioshow(false);
          e === true && setRadioshow1(false);
        }}
      />
      <RadiologyCenterModel
        show={radiomodal1}
        onNext={() => {
          setRadioshow(true);
          setRadiologyCenterDetailModel(false);
        }}
        formik={formik}
        setRadiologyCenterDetailModel={() =>
          setRadiologyCenterDetailModel(true)
        }
        onHide={(e) => {
          setRadioshow1(false);
          e === true && setRadioshow(true);
        }}
      />
      <RadiologyCenterDetailModel
        show={radiologyCenterDetailModel}
        onHide={() => setRadiologyCenterDetailModel(false)}
      />
      <SelecteMemberModel
        show={selectMember}
        onHide={() => setSelectMember(false)}
        formik={formik}
        radiologyCart={radiologyCart}
      />
      <SelectAddressModel
        show={selectAddressModel}
        formik={formik}
        onHide={(e) => {
          setSelecteAddressModel(false);
        }}
      />
      <RadiologicalTestSlotModel
        formik={formik}
        show={timeSample}
        test_center_id={radiologyTestCenterDetailsById?.id}
        onHide={() => settimeSample(false)}
      />
      <ApplyCouponModel
        couponList={couponList}
        show={applyCoupon}
        getDiscount={getDiscount}
        couponType={3}
        onHide={() => setApplyCoupon(!couponList)}
      />
      <PaymentSuccessfullModel
        show={successfullModel}
        onHide={() => setSuccessfullModel(false)}
      />

      <form onSubmit={formik.handleSubmit}>
        <Container fluid className="lab_test_cart_container">
          <Container>
            <div className="row">
              <div className="col-md-12">
                <h3 className="cart_head">Cart</h3>
              </div>
            </div>
            <div className="lab_test_cart_box">
              <div className="lab_test_cart_box_inner">
                <div className="row">
                  <div className="col-xl-8 col-md-7 lab_test_right_padding">
                    <div className="lab_test_cart_left_side">
                      <div className="row mb_15">
                        <div className="col-md-6 col-6">
                          <h3 className="lab_test_cart_left_side_title">
                            Tests
                          </h3>
                        </div>
                        <div className="col-md-6 col-6">
                          <Button
                            className="lab_test_add_test_btn"
                            onClick={() => setRadioshow(true)}
                          >
                            Add Test
                          </Button>
                        </div>
                      </div>
                      <div>
                        <div>
                          <h5 className="lab_test_for_toptitle">Test For</h5>
                          <div className="radiology_booked_test_profile_box">
                            <div>
                              <div className="addnewmember_profile_icon">
                                {" "}
                                {selectedMemberDetail?.name?.charAt(0)}{" "}
                              </div>
                            </div>
                            <div>
                              <h5 className="radiology_booked_test_for_name">
                                {selectedMemberDetail?.name}
                              </h5>
                            </div>
                          </div>
                          {radiologyCart?.length &&
                            radiologyCart?.map((item) => (
                              <>
                                <div className="row mt_20">
                                  <div className="col-md-6 col-6">
                                    <div className="lab_test_for_test_box">
                                      <img
                                        src={Icon.trash}
                                        className="lab_test_for_test_icon"
                                        onClick={() =>
                                          dispatch(removeItem(item?.id))
                                        }
                                      ></img>
                                      <h3 className="lab_test_for_test_name">
                                        {item?.Testdata?.name}
                                      </h3>
                                    </div>
                                  </div>
                                  <div className="col-md-6 col-6">
                                    <h5 className="lab_test_for_test_value">
                                      ₹ {item?.price}
                                    </h5>
                                  </div>
                                </div>
                                <hr className="lab_test_for_test_hr" />
                              </>
                            ))}
                        </div>
                      </div>
                    </div>

                    <div className="lab_test_cart_left_side_2">
                      <div className="row">
                        <div className="col-md-6">
                          <h3 className="lab_test_address">Radiology center address</h3>
                          <div
                            className="lab_test_address_box"

                          >

                            <h5 className="lab_test_address_box_text">
                              {radiologyTestCenterDetailsById && radiologyTestCenterDetailsById?.address}
                            </h5>
                          </div>
                          <div className="error_text pt-2">
                            {" "}
                            {formik.errors.ADDRESS &&
                              formik.touched.ADDRESS &&
                              formik.errors.ADDRESS}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <h3 className="lab_test_time">Test Time</h3>
                          <div
                            className="lab_test_time_box"
                            onClick={() => settimeSample(true)}
                          >
                            <h5 className="lab_test_time_box_text">


                              {
                                formik?.values?.start_time
                                  ? `${formatDate(formik?.values?.booking_date)} , ${formik?.values?.start_time}`
                                  : "Select"
                              }
                            </h5>
                          </div>
                          <div className="error_text pt-2">
                            {" "}
                            {formik.errors.slot_id &&
                              formik.touched.slot_id &&
                              formik.errors.slot_id}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-md-5 lab_test_left_padding">
                    <div className="lab_test_cart_right_side">
                      <h5 className="offer_benifit_text">
                        Offers &amp; Benifits
                      </h5>
                      <div
                        className="offer_benifit_card"
                        onClick={() => setApplyCoupon(true)}
                      >
                        <div className="apply_coupon_box">
                          <img
                            src={Icon.coupon}
                            className="apply_coupon_icon"
                          ></img>
                          <h5 className="offer_benifit_text">Apply Coupon</h5>
                        </div>
                        <div>
                          <svg
                            width="8"
                            height="14"
                            className="subscrip_consult_card_arrow"
                            viewBox="0 0 8 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1.25 12.5L6.75 7L1.25 1.5"
                              stroke="#9393AA"
                              strokeWidth="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </div>
                      </div>
                      <h5 className="payment_summary">Payment Summary</h5>
                      <div className="payment_summary_card">
                        <div className="payment_summary_card_inner mb_5">
                          <div>
                            <h5 className="payment_summary_title">
                              Total amount
                            </h5>
                          </div>
                          <div>
                            <h5 className="payment_summary_price">
                              ₹ {getTotal()?.totalPrice}
                            </h5>
                          </div>
                        </div>
                        {couponCodeDetail && (
                          <div className="payment_summary_card_inner">
                            <div>
                              <h5 className="payment_summary_title">
                                Discount
                              </h5>
                            </div>
                            <div>
                              <h5 className="payment_summary_price_discount">
                                - ₹ {coupendiscount}
                              </h5>
                            </div>
                          </div>
                        )}

                        {
                          goldMembershipdetail && goldMembershipdetail === true ? "" : <>
                            <div className="payment_summary_card_inner">
                              <div>
                                <h5 className="payment_done_payment_info_title" style={{ color: "#48988E" }}>
                                  Gold Discount [{goldMembershipStatus?.discount}%]
                                </h5>

                              </div>
                              <div>
                                <h5 className="payment_summary_price_discount">
                                  - ₹{" "}
                                  {discountAmount && discountAmount}

                                </h5>
                              </div>
                            </div>
                          </>
                        }
                        <hr className="payment_hr" />
                        <div className="payment_summary_card_inner">
                          <div>
                            <h5 className="payment_summary_total_title">
                              Total Payable
                            </h5>
                          </div>
                          <div>
                            <h5 className="payment_summary_total_price">
                              ₹ {totalamount ? parseFloat(totalamount).toFixed(0) : 0}
                            </h5>
                          </div>
                        </div>
                      </div>
                      <div className="wallet_card">
                        <div>
                          <div className="form-group">
                            <input
                              type="checkbox"
                              id={walletbox.id}
                              onChange={handleChange}
                              name={userWallet?.wallet}
                              value={walletbox}
                            />
                            <label className="wallet_text" for={walletbox.id}>
                              <span className="wallet_subtext">
                                Use wallet Amount
                              </span>
                            </label>
                          </div>
                        </div>
                        <div>
                          <h5 className="available_bal_text">
                            Avbl. bal.
                            <span className="available_bal_price">

                              ₹ {parseInt(wallet_price?.wallet_prices || "0")}
                            </span>
                          </h5>
                        </div>
                      </div>

                      <h5 className="T_C_title pt_10">Terms and conditions</h5>
                      <div className="lab_T_C_card">
                        <p className="T_C_text">
                          Before you book slot for diagnostic ,make sure you are aware about THS terms and conditions.
                        </p>
                        <div className="col-md-12">
                          <div>
                            <div className="healthpackage">
                              <input
                                id="checkbox1"
                                type="checkbox"
                                className="vh"
                                name="termsAndConditions"
                                onChange={() =>
                                  formik.setFieldValue(
                                    "termsAndConditions",
                                    !formik.values.termsAndConditions
                                  )
                                }
                              />
                              <label
                                className="healthpackage_checkbox_title"
                                for="checkbox1"
                                style={{
                                  display: "inline-flex",
                                  alignItems: "center",
                                }}
                              >
                                {" "}
                                <span
                                  className="i_agree_cancellation_policy"
                                  style={{ padding: "25px" }}
                                >
                                  <h5 className="view_T_C_text"
                                    onClick={handleCheckboxClick}
                                  >
                                    View Terms And Conditions
                                  </h5>
                                </span>
                              </label>
                            </div>

                            {formik?.errors?.termsAndConditions &&
                              formik.touched.termsAndConditions && (
                                <div className="terms_conditions_error_text">
                                  {formik?.errors?.termsAndConditions}
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="lab_test_footer_card">
                <div className="lab_test_card_box">
                  <div className="lab_test_price_box">
                    <h5 className="lab_test_price">Rs {totalamount ? parseFloat(totalamount).toFixed() : 0}</h5>
                    <h6 className="lab_test_subtitle">Amount to pay</h6>
                  </div>
                  <div>
                    <Button className="lab_test_btn" type="submit">
                      Pay Now
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </Container>
      </form>
    </>
  );
}

export default RadiologyTestCart;
