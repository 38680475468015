export const SESSION = "USER";
export const AGORA = "AGORA_SESSION";
export const TOKEN = "TOKEN";
export const MK_TOKEN = "MEDKART_TOKEN";
export const MK_CLIENT = process.env.REACT_APP_MEDKART_CLIENT;
export const MK_APPID = process.env.REACT_APP_MEDKART_APP_ID;
export const MK_SECRET = process.env.REACT_APP_MEDKART_APP_SECRET;
export const AGORA_APP = process.env.REACT_APP_AGORA_APPID;
export const AlertEnum = {
  Success: "SUCCESS",
  Error: "ERROR",
  Info: "INFO",
  Call: "CALL",
  Booking: "BOOKING",
  Message: "MESSAGE",
  Warning: "WARNING",
};

export const SignInEnum = { mobile_number: "" };
export const SelectEnum = { city_id: "" };
export const SignUpEnum = {
  first_name: "",
  last_name: "",
  email: "",
  dob: "",
  age: "",
  gender: "",
  height: "",
  weight: "",
  birthdate: "",
  city_id: "",
  blood_group: "",
  referral:""
};
export const ChatRoomEnum = {
  callingType: "",
  doctorCallingId: "",
  doctorOnlineLastTime: null,
  doctorOnlineStatus: "",
  isCallingStatus: "",
  lastBookingId: "",
  lastMessage: null,
  lastMessageTime: null,
  lastMessageType: null,
  unreadMessageOfDoctor: "",
  unreadMessageOfUser: "",
  userCallingId: "",
  userId: "",
  userName: "",
  userOnlineLastTime: null,
  userOnlineStatus: "",
  age: "",
  gender: "",
  bloodGroup: "",
  userImage: "",
  doctorImage: "",
};

export const MessageEnum = {
  dateTime: null,
  documentType: 0,
  extension: null,
  imageName: null,
  imageUrl: null,
  message: "",
  sizeOfDocument: null,
  userType: 1,
};

export const NotifyEnum = {
  user_id: "",
  booking_id: "",
  channel_name: "",
  uuid: "",
  incomming_call_type: "", // 1 = VIDEO , 0 = AUDIO
  agora_user_id: "",
  title: "",
  message: "Incoming Call",
  user_name: "",
  caller_user_id: "",
  chat_id: "",
};
