import React, { useState } from "react";
import { useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { Icon } from "../../../Utilities/Icons";
import successimage from '../../../Assets/img/png/Group 34263.png'
import { useNavigate } from "react-router-dom";
import { LabTestCenters, addHealthPackage } from "../../../Store/Reducer/LabtestSlice";
import { useDispatch, useSelector } from "react-redux";

const PackageDetailModal = (props) => {
    const navigate = useNavigate();

    const handleBookNowClick = () => {
     
        // dispatch(addHealthPackage(props?.packageId?.labtestdetails));
        props.onHide(); // Close the modal
        props.onNext();
 
        // navigate("/labtestpackage"); // Navigate to the desired route
    };
    const dispatch = useDispatch();
    const handleOkClick = () => {
        props.onHide();
    };
    



    return (

        <>

            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                dialogClassName="modal_45"
                backdrop="static"
                className="sucess_modal"

            >
                <Modal.Header
                    className="healthradiological_modal_box_head"
                    style={{ marginBottom: "none", border: "none" }}
                >
                    <div>


                        <div className="health_radiological_popup_list_box_inner">
                            <div>
                                <img
                                    className="tab_details_head_img"
                                    src={`${props?.packageId?.image}`}
                                    alt=""
                                    height="80px"
                                    width="80px"
                                    style={{ borderRadius: "8px" }}
                                ></img>
                            </div>
                            <div>
                                <h3 className="healthradiologytext">
                                    {props?.packageId?.package_title}
                                </h3>
                                <h3 className="healthradiologytexts">
                                    {props?.packageId?.labtestdetails?.length} tests
                                </h3>
                                <h3 className="healthradiologytextss">
                                    ₹{props?.packageId?.package_amount}
                                </h3>
                            </div>
                        </div>
                        <div className="pt_10">
                            <p className="textdynamic">Test Instructions</p>
                            <p className="textdynamics pt_5">{props?.packageId?.package_description}</p>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body className="healthradiological_modal_box_body">
                    <div className="healthradiological_modal_box_body_inner">
                        <hr className="healthradiological_modal_box_hr" />
                    </div>
                    <div className="" style={{ padding: "0px 30px" }}>
                        <div>
                            <h4 className="textTest">Test Included</h4>
                        </div>
                        <div>
                            <div className="pt_10">
                                {
                                    props?.packageId?.labtestdetails.map((packageId, index) => {
                                        return (
                                            <>
                                                <div>
                                                    <h4 className="textdynamics">{packageId?.test_name}</h4>
                                                    <p className="textdynamicses">{packageId?.test_preparation}</p>
                                                    <hr />
                                                </div>
                                            </>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer className="healthpackage_modal_footer_box">
                    <div className="health_radiological_popup_list_box_inner">
                        <div className="">
                            <Button
                                className="lab_test_modal_footer_btn"
                                onClick={handleBookNowClick }
                            >
                                Book Now
                            </Button>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>

        </>
    )
}

export default PackageDetailModal