import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  EditUserProfileAPI,
  GetUserProfileAPI,
  GetMemberList,
  AddNewMember,
  GetMemberDetails,
  UserWalletAPI,
  ConsultationMemberDeleteAPI,
  EditMemberProfileAPI,
  GetHeightListAPI,
  GetWeightListAPI,
  GetMyWalletListAPI,
  GetMySubscribedPlansListAPI,
  GetMySubscribedPlansListDetailAPI,
  GetDoctorList,
  ClickCard,
  consultionClick,
  DeleteAccount
} from "../../Routes/Service";
import { AlertEnum } from "../../Utilities/Enums";
import { setSession } from "./AuthSlice";
import { setLoading, setMessage } from "./LayoutsSice";


const initialState = {
  userProfile: "",
  memberList: [],
  walletList: [],
  subscribedPlansList: [],
  packageMemberDetails: [],
  userWallet: "",
  userHeightList: [],
  userWeightList: [],
  clickeventcard:"",
  consutationscards:"",
  deleteaccountdata:""
};
export const EditUserProfile = createAsyncThunk(
  "EditUserProfile",
  async (values, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const result = await EditUserProfileAPI(values);
      if (result?.success) {
        dispatch(setLoading(false));
        dispatch(setSession(result?.data));
        dispatch(GetMember());

        dispatch(
          setMessage({
            text: result?.message,
            type: AlertEnum.Success,
          })
        );
        return result;
      } else {
        throw result;
      }
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(
        setMessage({
          text: error?.message,
          type: AlertEnum.Error,
        })
      );
      return error;
    }
  }
);
export const EditMemberProfile = createAsyncThunk(
  "EditMemberProfile",
  async (values, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const result = await EditMemberProfileAPI(values);
      if (result?.success) {
        dispatch(setLoading(false));
        dispatch(GetMember());
        dispatch(
          setMessage({
            text: result?.message,
            type: AlertEnum.Success,
          })
        );
        return result;
      } else {
        throw result;
      }
    } catch (error) {
      dispatch(setLoading(false));
      // dispatch(
      //   setMessage({
      //     text: error?.message,
      //     type: AlertEnum.Error,
      //   })
      // );
      return error;
    }
  }
);

export const GetUserProfile = createAsyncThunk(
  "GetUserProfile",
  async (values, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const result = await GetUserProfileAPI(values);
      if (result?.success) {
        dispatch(setLoading(false));
        return result?.data;
      } else {
        throw result;
      }
    } catch (error) {
      dispatch(setLoading(false));
      // dispatch(
      //   setMessage({
      //     text: error?.message,
      //     type: AlertEnum.Error,
      //   })
      // );

      return error;
    }
  }
);

export const clickCardHome = createAsyncThunk(
  "clickCardHome",
  async (values, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const result = await ClickCard(values);
      if (result?.success) {
        dispatch(setLoading(false));
        return result?.data;
      } else {
        throw result;
      }
    } catch (error) {
      dispatch(setLoading(false));
      // dispatch(
      //   setMessage({
      //     text: error?.message,
      //     type: AlertEnum.Error,
      //   })
      // );

      return error;
    }
  }
);
export const ConsultionCard = createAsyncThunk(
  "ConsultionCard",
  async (values, { dispatch }) => {
    try {
      // dispatch(setLoading(true));
      const result = await consultionClick(values);
     
      if (result?.success) {
        dispatch(setLoading(false));
        return result?.data;
      } else {
        throw result;
      }
    } catch (error) {
      // dispatch(setLoading(false));
      // dispatch(
      //   setMessage({
      //     text: error?.message,
      //     type: AlertEnum.Error,
      //   })
      // );

      return error;
    }
  }
);
export const Deleteaccount = createAsyncThunk(
  "Deleteaccount",
  async (values, { dispatch }) => {
    try {
      const result = await DeleteAccount(values);
      if (result?.success) {
        dispatch(
          setMessage({
            text: result?.message,
            type: AlertEnum.Success,
          })
        );
        return result;
      } else {
        throw result;
      }
    } catch (error) {
      dispatch(setLoading(false));
      // dispatch(
      //   setMessage({
      //     text: error?.message,
      //     type: AlertEnum.Error,
      //   })
      // );
      return error;
    }
  }
);

export const GetDoctorProfile = createAsyncThunk(
  "GetDoctorProfile",
  async (values, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const result = await GetDoctorList(values);
      if (result?.success) {
        dispatch(setLoading(false));
        return result?.data;
      } else {
        throw result;
      }

    } catch (error) {
      dispatch(setLoading(false));
      // dispatch(
      //   setMessage({
      //     text: error?.message,
      //     type: AlertEnum.Error,
      //   })
      // );

      return error;
    }
  }
)
export const AddNewMembers = createAsyncThunk(
  "AddNewMembers",
  async (values, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const result = await AddNewMember(values);
      if (result?.success) {
        dispatch(setLoading(false));
        dispatch(GetMember());
        dispatch(
          setMessage({
            text: result?.message,
            type: AlertEnum.Success,
          })
        );
        return result?.data;
      } else {
        throw result;
      }
    } catch (error) {
      dispatch(setLoading(false));
      // dispatch(
      //   setMessage({
      //     text: error?.message,
      //     type: AlertEnum.Error,
      //   })
      // );

      return error;
    }
  }
);
export const GetMember = createAsyncThunk(
  "GetMember",
  async (values, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const result = await GetMemberList(values);
      if (result?.success) {
        dispatch(setLoading(false));
        return result?.data;
      } else {
        throw result;
      }
    } catch (error) {
      dispatch(setLoading(false));
      // dispatch(
      //   setMessage({
      //     text: error?.message,
      //     type: AlertEnum.Error,
      //   })
      // );

      return error;
    }
  }
);
export const GetHeightList = createAsyncThunk(
  "GetHeightList",
  async (values, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const result = await GetHeightListAPI(values);
      if (result?.success) {
        dispatch(setLoading(false));
        return result?.data;
      } else {
        throw result;
      }
    } catch (error) {
      dispatch(setLoading(false));
      // dispatch(
      //   setMessage({
      //     text: error?.message,
      //     type: AlertEnum.Error,
      //   })
      // );

      return error;
    }
  }
);
export const GetWeightList = createAsyncThunk(
  "GetWeightList",
  async (values, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const result = await GetWeightListAPI(values);
      if (result?.success) {
        dispatch(setLoading(false));
        return result?.data;
      } else {
        throw result;
      }
    } catch (error) {
      dispatch(setLoading(false));
      // dispatch(
      //   setMessage({
      //     text: error?.message,
      //     type: AlertEnum.Error,
      //   })
      // );

      return error;
    }
  }
);
export const ConsultationMemberDelete = createAsyncThunk(
  "ConsultationMemberDelete",
  async (values, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const result = await ConsultationMemberDeleteAPI({ member_id: values });
      if (result?.success) {
        dispatch(setLoading(false));
        dispatch(GetMember());
        return result?.data;
      } else {
        throw result;
      }
    } catch (error) {
      dispatch(setLoading(false));
      // dispatch(
      //   setMessage({
      //     text: error?.message,
      //     type: AlertEnum.Error,
      //   })
      // );

      return error;
    }
  }
);
export const GetPackageMemberDetails = createAsyncThunk(
  "GetPackageMemberDetails",
  async (values, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const result = await GetMemberDetails({ member_id: values });
      if (result?.success) {
        dispatch(setLoading(false));
        return result?.data;
      } else {
        throw result;
      }
    } catch (error) {
      dispatch(setLoading(false));
      // dispatch(
      //   setMessage({
      //     text: error?.message,
      //     type: AlertEnum.Error,
      //   })
      // );

      return error;
    }
  }
);
export const GetUserWallet = createAsyncThunk(
  "GetUserWallet",
  async (values, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      console.log("valuesssss" , values);
      const result = await UserWalletAPI(values);
      if (result?.success) {
        dispatch(setLoading(false));
        return result?.data;
      } else {
        throw result;
      }
    } catch (error) {
      dispatch(setLoading(false));
      // dispatch(
      //   setMessage({
      //     text: error?.message,
      //     type: AlertEnum.Error,
      //   })
      // );

      return error;
    }
  }
);
export const GetUserWalletList = createAsyncThunk(
  "GetUserWalletList",
  async (values, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const result = await GetMyWalletListAPI();
      if (result?.success) {
        dispatch(setLoading(false));
        return result?.data;
      } else {
        throw result;
      }
    } catch (error) {
      dispatch(setLoading(false));
      // dispatch(
      //   setMessage({
      //     text: error?.message,
      //     type: AlertEnum.Error,
      //   })
      // );

      return error;
    }
  }
);
export const GetMySubscribedPlansList = createAsyncThunk(
  "GetMySubscribedPlansList",
  async (values, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const result = await GetMySubscribedPlansListAPI(values);
      if (result?.success) {
        dispatch(setLoading(false));
        return result?.data;
      } else {
        throw result;
      }
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(
        // setMessage({
        //   text: error?.message,
        //   type: AlertEnum.Error,
        // })
      );

      return error;
    }
  }
);
export const GetMySubscribedPlansListDetail = createAsyncThunk(
  "GetMySubscribedPlansListDetail",
  async (values, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const result = await GetMySubscribedPlansListDetailAPI({ plan_id: values });

      if (result?.success) {
        dispatch(setLoading(false));
        return result?.data;
      } else {
        throw result;
      }
    } catch (error) {
      dispatch(setLoading(false));
      // dispatch(
      //   setMessage({
      //     text: error?.message,
      //     type: AlertEnum.Error,
      //   })
      // );

      return error;
    }
  }
);
export const ProfileSlice = createSlice({
  name: "ProfileSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(GetUserProfile.fulfilled, (state, action) => {
      state.userProfile = action.payload;
    });
    builder.addCase(clickCardHome.fulfilled, (state, action) => {
      state.clickeventcard = action.payload;
    });
    builder.addCase(ConsultionCard.fulfilled, (state, action) => {
      state.consutationscards = action.payload;
    });
    builder.addCase(Deleteaccount.fulfilled, (state, action) => {
      state.deleteaccountdata = action.payload;
    });
    
    
    builder.addCase(GetMember.fulfilled, (state, action) => {
      state.memberList = action.payload;
    });
    builder.addCase(GetPackageMemberDetails.fulfilled, (state, action) => {
      state.packageMemberDetails = action.payload;
    });
    builder.addCase(GetUserWallet.fulfilled, (state, action) => {
      state.userWallet = action.payload;
    });
    builder.addCase(GetUserWalletList.fulfilled, (state, action) => {
      state.walletList = action.payload;
    });
    builder.addCase(GetMySubscribedPlansList.fulfilled, (state, action) => {
      state.subscribedPlansList = action.payload;
    });
    builder.addCase(GetHeightList.fulfilled, (state, action) => {
      state.userHeightList = action.payload;
    });
    builder.addCase(GetWeightList.fulfilled, (state, action) => {
      state.userWeightList = action.payload;
    });
  },
});

export default ProfileSlice.reducer;
