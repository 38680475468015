import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { Container } from "react-bootstrap";
import { ReferListData } from "../../../Store/Reducer/RadiologySlice";



const TermsandCondition = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { referListData } = useSelector(
        ({ RadiologySlice }) => RadiologySlice
    );
    useEffect(() => {
        dispatch(ReferListData())
    }, [dispatch])
  
    return (
        <>
            <div>
                <Container>
                    <div className="row pt_30">
                        <div className="col-xl-10 col-md-8">
                            <div className="my_subscription_body_box_card_heads">
                                <div className="row">
                                    <div className="col-md-6 col-6">
                                        <h3 className="my_subscription_body_card_head_title">
                                            Terms and Conditions
                                        </h3>
                                       
                                    </div>
                                </div>
                                <hr/>
                                <div className="">
                                    <div className="col-md-12 col-12">
                                        {/* <h3 className="maintf">What is Lorem Ipsum?</h3> */}
                                        <p  className="maintfp pt_5">{referListData?.terms}</p>
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>


        </>
    )
}

export default TermsandCondition
