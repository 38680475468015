import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { setLoading, setMessage } from "./LayoutsSice";
import { AlertEnum } from "../../Utilities/Enums";
import { PackageDetailsAPI, PackageListAPI, PreceptionApi } from "../../Routes/Service";

const initialState = {
  packageList: [],
  packageDetailsById: "",
  packageId: "",
  radiologyPackageList: [],
};

export const PackageList = createAsyncThunk(
  "PackageSlice",
  async (values, { dispatch }) => {
    try {
      const result = await PackageListAPI({ package_type: values });
      if (result) {
        return result?.data;
      } else {
        throw result;
      }
    } catch (error) {
      dispatch(setLoading(false));
      // dispatch(
      //   setMessage({
      //     text: error?.message,
      //     type: AlertEnum.Error,
      //   })
      // );
      return error;
    }
  }
);

export const PackageDetailsById = createAsyncThunk(
  "PackageDetailsById",
  async (values, { dispatch }) => {
    try {
      const result = await PackageDetailsAPI({ package_id: values });
      if (result) {
        return result?.data;
      } else {
        throw result;
      }
    } catch (error) {
      dispatch(setLoading(false));
      // dispatch(
      //   setMessage({
      //     text: error?.message,
      //     type: AlertEnum.Error,
      //   })
      // );
      return error;
    }
  }
);
export const RadiologyPackageList = createAsyncThunk(
  "RadiologyPackageList",
  async (values, { dispatch }) => {
    try {
      const result = await PackageListAPI({ package_type: values });
      if (result) {
        return result?.data;
      } else {
        throw result;
      }
    } catch (error) {
      dispatch(setLoading(false));
      // dispatch(
      //   setMessage({
      //     text: error?.message,
      //     type: AlertEnum.Error,
      //   })
      // );
      return error;
    }
  }
);
export const PackageSlice = createSlice({
  name: "PackageSlice",
  initialState,
  reducers: {
    clearPackageId: (state, action) => {
      state.packageId = "";
    },
    clearPackageDetailsById: (state, action) => {
      state.packageDetailsById = "";
    },
    setPackageId: (state, action) => {
      state.packageId = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(PackageList.fulfilled, (state, action) => {
      state.packageList = action.payload;
    });
    builder.addCase(PackageDetailsById.fulfilled, (state, action) => {
      state.packageDetailsById = action.payload;
    });
    builder.addCase(RadiologyPackageList.fulfilled, (state, action) => {
      state.radiologyPackageList = action.payload;
    });
  },
});
export const { setPackageId,clearPackageDetailsById } = PackageSlice.actions;
export default PackageSlice.reducer;
