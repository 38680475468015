import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import '../../../Assets/css/responsive.css';
import { Button } from "react-bootstrap";
import { Icon } from "../../../Utilities/Icons";
import backgroundimg from '../../../Assets/img/png/Group 34274 (2).png'
import copy from '../../../Assets/img/svg/Copy, paste, duplicate, clipboard.svg'
import shape from '../../../Assets/img/svg/Frame.svg'
import person from '../../../Assets/img/png/15 Consultations.png'
import consult from '../../../Assets/img/png/20consult.png'
import { useNavigate } from "react-router-dom";
import { ReferListData } from "../../../Store/Reducer/RadiologySlice";
import { useDispatch, useSelector } from "react-redux";
function Refer() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { referListData } = useSelector(
        ({ RadiologySlice }) => RadiologySlice
    );
    useEffect(() => {
        dispatch(ReferListData())
    }, [dispatch])

    const [copied, setCopied] = useState(false);

    const handleCopyClick = () => {
        const referralCode = referListData?.referral_code;
        if (referralCode) {
            navigator.clipboard.writeText(referralCode);
            setCopied(true);
        }
    };
    return (
        <>

            <Container>

                <div className="row">
                    <div className="col-xl-12 col-md-8">
                        <div className="my_subscription_body_box_card_head">
                            <div className="row">
                                <div className="col-md-6 col-6">
                                    <h3 className="my_subscription_body_card_head_title">
                                        Refer and Earn
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <div className="my_subscription_body_box_card_body">
                            <div className="row">
                                <div className="refermaindiv col-md-12">
                                    <div className="col-md-6 col-6">
                                        <h2 className="refertext">Get your friends to take 1st
                                            consultancy now</h2>
                                        <div className="borderrefer" style={{ paddingTop: "20px" }}>
                                            <div>
                                                <div className="d-flex align-items-center" style={{ gap: "10px" }}>
                                                    <img src={Icon.round}></img>
                                                    <p className="refertextparaggrph">You get <span style={{ color: "#F6773F" }}> Rs {referListData?.inviter_coins}</span>  in your THS wallet when your friend completes his first consultancy.</p>
                                                </div>
                                                <div className="d-flex align-items-center" style={{ gap: "10px" }}>
                                                    <img src={Icon.round}></img>
                                                    <p className="refertextparaggrph">You friend will get<span style={{ color: "#F6773F" }}> Rs{referListData?.new_user_coins}</span>   in his THSwallet on signup.</p>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-md-6 col-6">
                                        <img src={backgroundimg} className="img-fuild" style={{width:"95%"}}></img>
                                    </div>

                                </div>
                            </div>
                            <div className="row">
                                <p className="rtext">Referral Code (share your code with yours peers)</p>
                                <div className="col-md-12 col-12 d-flex " style={{ gap: "10px" }}>

                                    <div
                                        className="col-md-6 col-6 profiledash_member_cards ">

                                        <div className="">
                                            <div className='referbetween'>
                                                <p style={{ marginBottom: "0px" }}>{referListData?.referral_code}</p>
                                                <img
                                                    src={copy}
                                                    alt="Copy"
                                                    onClick={handleCopyClick}
                                                    style={{ cursor: "pointer" }}
                                                />

                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="col-md-6 col-6 profiledash_member_cards "
                                        onClick={() => {
                                            navigate(`/terms`);
                                        }}
                                    >
                                        <div className="">
                                            <div className='referbetween'>
                                                <p style={{ marginBottom: "0px" }}>Terms and Conditions</p>
                                                <img src={shape}></img>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 col-12 d-flex pt_30" style={{ gap: "10px" }}>
                                <div
                                    className="col-md-6 col-6 profiledash_member_card profiledash_member_card_border"

                                >
                                    <div className="profiledash_member_card_inner">
                                        <div>
                                            <div>
                                                <img src={consult}></img>
                                            </div>
                                        </div>
                                        <div>
                                            <h5 className="addnewmember_name">  ₹ {referListData?.inviter_coins}</h5>
                                            <h6 className="addnewmember_subtitle">
                                                You have earn till now
                                            </h6>

                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="col-md-6 col-6 profiledash_member_card profiledash_member_card_border"

                                >
                                    <div className="profiledash_member_card_inner">
                                        <div>
                                            <div>
                                                <img src={person}></img>
                                            </div>
                                        </div>
                                        <div>
                                            <h5 className="addnewmember_name">  ₹ {referListData?.new_user_coins}</h5>
                                            <h6 className="addnewmember_subtitle">
                                                You have earn till now
                                            </h6>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    );
}

export default Refer;
