import React from "react";
import Select from "react-select";

export const CustomSelect = ({
  className,
  placeholder,
  field,
  form,
  options,
  isMulti = false,
}) => {
  const onChange = (option) => {
    form.setFieldValue(
      field.name,
      isMulti ? option.map((item) => item.value) : option.value
    );
  };

  const getValue = () => {
    if (options) {
      return isMulti
        ? options.filter((option) => field.value?.indexOf(option.value) >= 0)
        : options.find((option) => option.value === field.value);
    } else {
      return isMulti ? [] : "";
    }
  };
  const customStyles = {
    control: (base, state) => ({
      ...base,
      // background: "transparent",
      // borderRadius: "10px",
      // width: "-webkit-fill-available",
      // height: "48px !important",
      // border: "1px solid #e2e2e6",
      // boxShadow: state.isFocused ? null : null,
      backgroundColor: state.isSelected ? "#199A8E" : "white",
    }),

    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      // const color = chroma(data.color);
      return {
        ...styles,
        backgroundColor: isSelected && "#199A8E",
        // color: isSelected && "white",
        // borderRadius: "10px",
      };
    },
  };
  return (
    <Select
      className={className}
      name={field.name}
      value={getValue()}
      onChange={onChange}
      placeholder={placeholder}
      options={options}
      isMulti={isMulti}
      styles={customStyles}
    />
  );
};

export default CustomSelect;
