import React from "react";
import Header from "../Components/Common/Header";
export default function PublicRoutes({ children, isHeader }) {
  return (
    <>
      {isHeader && <Header />}
      {children}
    </>
  );
}


