import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import "../../../../Assets/css/responsive.css";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import Pagination from "../../../Common/Pagination/Pagination";
import { GetMember, GetMySubscribedPlansList } from "../../../../Store/Reducer/ProfileReducer";
import { useDispatch, useSelector } from "react-redux";
import { CardHome, SubscribedPlansList } from "../../../../Store/Reducer/BookingSlice";
import GolImg from '../../../../Assets/img/png/Group 34218.png'
import { GoldMemberBoking, GoldMemberBokingstatus } from "../../../../Store/Reducer/RadiologySlice";
import { Logo } from "../../../../Utilities/Icons";
import { GoldMemberBookingDetails } from "../../../../Store/Reducer/LabtestSlice";
import { Navigate } from "react-router-dom";
import Congratulations from "../../GoldMemberShip/Congratulations";
import { Icon } from "../../../../Utilities/Icons";
function GoldMembership() {
    const dispatch = useDispatch();
    const [sucessmodal, setSucessmodal] = useState(false);
    const { memberList } = useSelector(
        ({ ProfileSlice }) => ProfileSlice
    );
    const {
        cards
    } = useSelector(({ BookingSlice }) => BookingSlice);
    const { goldMembershipStatus, goldMembershipStatus1 } = useSelector(
        ({ RadiologySlice }) => RadiologySlice
    );
    useEffect(() => {
        dispatch(GetMember());
    }, [dispatch])
    const memberIdList = memberList?.map(member => member.id).join(",");
    useEffect(() => {
        dispatch(GoldMemberBookingDetails(memberIdList));
    }, [dispatch])
    const { goldMembershipdetail } = useSelector(
        ({ LabtestSlice }) => LabtestSlice
    );
  
    const { session } = useSelector(({ AuthSlice }) => AuthSlice);
    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://checkout.razorpay.com/v1/checkout.js";
        script.async = true;
        document.body.appendChild(script);
    }, []);
    const options = {
        key: process.env.REACT_APP_PAYMENT_KEY,
        key_secret: process.env.REACT_APP_PAYMENT_SECRET_KEY,
        amount: "100", //cretaBookConsultant?.data?.total_price, //  = INR 1
        name: "THS-User",
        description: "some description",
        image: Logo.THS_logo,
        handler: function (response) {
            if (response) {
                setSucessmodal(true);
            }
        },
        prefill: {
            name: session?.name,
            contact: session?.mobile_number,
            email: session?.email,
        },
        notes: {
            address: "some address",
        },
        theme: {
            color: "#F37254",
            hide_topbar: false,
        },
    };
    const openPayModal = (options) => {
        var rzp1 = new window.Razorpay(options);
    
        rzp1.open();
    };


    const createBooking = (goldMembershipdetail) => {
        try {
            let tempOptions = {
                ...options,
                amount: Math.round(goldMembershipStatus.price ) * 100,
                currency: "INR",
                name: "THS-User",
                description: "some description",
                image: Logo.THS_logo,
                handler: async function (response) {
                    const memberIdList = cards?.id;
                    let updatedValues = {
                        transaction_id: response.razorpay_payment_id,
                        membership_id: memberIdList,
                        total_amount: goldMembershipStatus?.price ,
                        total_paid_amount: goldMembershipStatus?.price ,
                        wallet_amount: 0
                    };
                    dispatch(GoldMemberBoking(updatedValues)).then((res) => {

                        setSucessmodal(true);
                    });
                },
            };
            openPayModal(tempOptions);
        } catch (error) { }
    };
    useEffect(() => {
        dispatch(CardHome())
    }, [dispatch]);
    useEffect(() => {
        dispatch(GoldMemberBookingDetails());
    }, [dispatch])
    useEffect(() => {
        dispatch(GoldMemberBokingstatus(cards?.gold_membership?.id));
    }, [dispatch, cards?.gold_membership?.id])
  
    return (
        <>
            <Congratulations
                show={sucessmodal}
                onHide={() => {
                    setSucessmodal(false);
                }}
                onNext={() => {
                    Navigate = '/'
                }}
            />
            {
                goldMembershipdetail && goldMembershipdetail == true ?
                    <>
                        <Container>
                            <div className="row">
                                <div className="col-xl-12 col-md-8">
                                    <div className="my_subscription_body_box_card_head">
                                        <div className="row">
                                            <div className="col-md-6 col-6">
                                                <h3 className="my_subscription_body_card_head_title">
                                                    My Membership
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="my_subscription_body_box_card_body">
                                        <div className="row">
                                            <div className="col-md-12 pt_20">
                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    <img src={GolImg} alt="Centered Image" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row pt_20">
                                            <div className="col-md-12">
                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    <h3 className="goldtv pt_10">No gold membership purchased yet</h3>
                                                </div>
                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    <h4 className="gol" style={{ color: "#909196" }}>You can get extra discounts on <br />
                                                        all THS services</h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row pt_20">
                                            <div className="col-md-12">
                                                <div className="Gold_btn_text mb_20">
                                                    <button className="Gold_btnss" onClick={() => { createBooking(goldMembershipdetail) }}>Buy  Now</button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </Container>
                    </>
                    :
                    <>
                        <Container>
                            <div className="row">
                                <div className="col-xl-12 col-md-8">
                                    <div className="my_subscription_body_box_card_head">
                                        <div className="row">
                                            <div className="col-md-6 col-6">
                                                <h3 className="my_subscription_body_card_head_title">
                                                    My Membership
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="my_subscription_body_box_card_body">
                                        <div className="row">
                                            <div className="col-md-12 pt_20">
                                                <div>
                                                    <div className="payment_done_card_box">
                                                        <h3 className="payment_done_payment_info">Purchase Details</h3>
                                                        <hr className="payment_done_hr" />
                                                        <div className="row">
                                                            <div className="col-md-6 col-6">
                                                                <h5 className="payment_done_payment_info_title">Valid till</h5>
                                                            </div>
                                                            <div className="col-md-6 col-6">
                                                                <h5 className="payment_done_payment_info_value">
                                                                    {moment(goldMembershipdetail?.expiry_date).format(
                                                                        "DD MMM YYYY | h:mm A"
                                                                    )}{" "}
                                                                </h5>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6 col-6">
                                                                <h5 className="payment_done_payment_info_title">Paid amount</h5>
                                                            </div>
                                                            <div className="col-md-6 col-6">
                                                                <h5 className="payment_done_payment_info_value">
                                                                    ₹ {goldMembershipdetail?.total_pay
                                                                    }
                                                                </h5>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6 col-6">
                                                                <h5 className="payment_done_payment_info_title">Payment ID</h5>
                                                            </div>
                                                            <div className="col-md-6 col-6">
                                                                <h5 className="payment_done_payment_info_value">
                                                                    {goldMembershipdetail?.transaction_id}
                                                                </h5>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6 col-6">
                                                                <h5 className="payment_done_payment_info_title">Date and Time</h5>
                                                            </div>
                                                            <div className="col-md-6 col-6">
                                                                <h5 className="payment_done_payment_info_value">
                                                                    {moment(goldMembershipdetail?.booking_date).format(
                                                                        "DD MMM YYYY | h:mm A"
                                                                    )}{" "}
                                                                </h5>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>

                                                <div className="payment_done_card_box pt_20">
                                                    <h3 className="payment_done_payment_info">Benifits</h3>
                                                    <hr className="payment_done_hr" />
                                                    <div className="row">

                                                        <div className="col-md-12 col-12">
                                                            <div>
                                                                <ul className="dot-list">
                                                                    {goldMembershipStatus && goldMembershipStatus?.benifits.map((benefit, index) => (
                                                                        <li key={index} className="pt_5">
                                                                            <span className="bullet" />
                                                                            <span style={{ color: "#303236", fontWeight: "400" }}>{benefit?.benifits}</span>
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Container>
                    </>
            }

        </>
    );
}

export default GoldMembership;
