import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Header from "../Components/Common/Header";
import {
  removeSession,
  VerifySession,
  GetMedkartToken,
} from "../Store/Reducer/AuthSlice";
import { GetUserProfile } from "../Store/Reducer/ProfileReducer";
export default function PrivateRoutes({ children, breadcrumbTitle, crumbs }) {
  const { token, medkart_token } = useSelector(({ AuthSlice }) => AuthSlice);
  const { userProfile } = useSelector(({ ProfileSlice }) => ProfileSlice);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    if (token) {
      dispatch(VerifySession()).then((res) => {
        if (res?.payload?.success) {
          if (!userProfile) {
            !medkart_token && dispatch(GetMedkartToken());
            dispatch(GetUserProfile());
          }
        } else {
          dispatch(removeSession());
          navigate("/");
        }
      });
    } else {
      navigate("/");
    }
    return () => {};
  }, [token]);

  useEffect(() => {
    dispatch(GetMedkartToken());
  }, []);

  return (
    <>
      <Header />
      <div className="header">
        <Container>
          <div className="row">
            <div className="col-md-12">
              <div className="home_text">
                <div
                  className="sidebar-link-pointer"
                  onClick={() => navigate("/")}
                >
                  Home
                </div>{" "}
                {" /"}
                <h3 className="bones_text">{breadcrumbTitle}</h3>
              </div>
            </div>
          </div>
        </Container>
      </div>
      {children}
    </>
  );
}
