import React from "react";
import { Button, Modal } from "react-bootstrap";

import { Icon } from "../../../Utilities/Icons";

const SelectSubscribedPlansModel = (props) => {
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="book_modal_531"
        backdrop="static"
      >
        <Modal.Header
          className=""
          style={{ marginBottom: "none", border: "none" }}
        >
          <div>
            <h5 className="select_subscription_plan">
              Select Subscription Plan
            </h5>
            <h5 className="select_subscription_plan_subtext">
              You have already pruchased plan
            </h5>
          </div>
          <img
            src={Icon.cross}
            className="book_modal_close"
            onClick={() => props.onHide()}
            alt=""
          ></img>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={(e) => props?.formik?.handleSubmit(e)}>
            <div className="select_subscription_plan_option_box">
              <div className="select_member_box_3">
                <div className="consult_member_box_1">
                  {props?.subscribedPlansList?.length &&
                    props?.subscribedPlansList?.map((subPlan, index) => (
                      <>
                        <div className="consult_member_box_inner" key={index}>
                          <div>
                            <input
                              type="radio"
                              id="test1"
                              name="radio-group"
                              className="mt_10"
                              value={subPlan}
                              checked={
                                props?.formik?.values.subscription_plan_id ===
                                subPlan?.id
                              }
                              onChange={() => {
                                // props?.formik?.setFieldValue(
                                //   "selectedPlanName",
                                //   subPlan
                                // );
                                props?.formik?.setFieldValue(
                                  "subscription_plan_id",
                                  subPlan?.id
                                );
                                props?.formik?.setFieldValue(
                                  "total_amount",
                                  100
                                );
                                props?.formik?.setFieldValue(
                                  "plan_type",
                                  "old"
                                );

                                props?.formik?.setFieldValue(
                                  "total_paid_amount",
                                  100
                                );
                              }}
                              style={{ accentColor: "#199a8e"}}
                            />
                            <lable for="test1">
                              <h5 className="consult_members_title_1">
                                {subPlan?.plan_details?.title} <br />
                                <span className="consult_members_subtitle_1">
                                  (
                                  {`${
                                    subPlan?.left_consultations === 0
                                      ? "unlimited"
                                      : subPlan?.left_consultations
                                  }`}{" "}
                                  Consultation left)
                                </span>
                              </h5>
                            </lable>
                          </div>
                        </div>
                        <hr className="consult_member_hr" />
                      </>
                    ))}
                  <div className="consult_member_box_1">
                    <div
                      className="consult_member_box_inner_2"
                      style={{ marginTop: "3px" }}
                    >
                      <div>
                        <input
                          type="radio"
                          id="test1"
                          name="radio-group"
                          value={false}
                          checked={
                            props?.formik?.values?.selectedPlanName === true
                          }
                          onChange={() => {
                            props?.formik?.setFieldValue(
                              "selectedPlanName",
                              true
                            );
                          }}
                          style={{ accentColor: "#199a8e"}}
                        />
                        <lable>
                          <h5 className="consult_members_title_1">
                            Purchase a new plan
                          </h5>
                        </lable>
                      </div>
                    </div>
                  </div>
                </div>

                <center>
                  <Button
                    type="submit"
                    className="select_subscription_plan_continue_btn"
                  >
                    Continue
                  </Button>
                </center>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SelectSubscribedPlansModel;
