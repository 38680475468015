import { configureStore } from "@reduxjs/toolkit";
import AuthSlice from "./Reducer/AuthSlice";
import LayoutSlice from "./Reducer/LayoutsSice";
import ProfileSlice from "./Reducer/ProfileReducer";
import CommonSlice from "./Reducer/CommonSlice";
import BookingSlice from "./Reducer/BookingSlice";
import {
  subscriptionPlanReducer,
  memberReducer,
} from "./Reducer/subscriptionPlanReducer";
import MedicineSlice from "./Reducer/MedicineSlice";
import ChatSlice from "./Reducer/ChatReducer";
import CallingSlice from "./Reducer/CallingReducer";
import OrderSilce from "./Reducer/OrderSilce";
import LabtestSlice from "./Reducer/LabtestSlice";
import AddressSlice from "./Reducer/AddressSlice";
import RadiologySlice from "./Reducer/RadiologySlice";
import PackageSlice from "./Reducer/PackageSlice";

export const store = configureStore({
  reducer: {
    AuthSlice: AuthSlice,
    LayoutSlice: LayoutSlice,
    ProfileSlice: ProfileSlice,
    CommonSlice: CommonSlice,
    BookingSlice: BookingSlice,
    subscriptionPlanlist: subscriptionPlanReducer,
    memiberList: memberReducer,
    MedicineSlice: MedicineSlice,
    ChatSlice: ChatSlice,
    CallingSlice: CallingSlice,
    OrderSilce: OrderSilce,
    AddressSlice: AddressSlice,
    LabtestSlice: LabtestSlice,
    RadiologySlice: RadiologySlice,
    PackageSlice: PackageSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoreActions: ["ChatSlice.GetConversation"],
        ignoreState: ["ChatSlice.conversation"],
      },
    }),
});
