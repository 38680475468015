import { Formik } from "formik";
import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { BookingCancelById } from "../../../Store/Reducer/BookingSlice";
import { Icon } from "../../../Utilities/Icons";
import * as Yup from "yup";
import { RadiologyCancelBooking } from "../../../Store/Reducer/RadiologySlice";

const CancelationReasonModel = (props) => {
  const [cancellationPolicy, setCancellationPolicy] = useState(false);
  const { bookingDetail } = useSelector(({ BookingSlice }) => BookingSlice);
  const dispatch = useDispatch();
  const ReasonCancellationSchema = Yup.object({
    cancelationDetail: Yup.object().required(
      "Please Select Reason for Cancellation"
    ),
    termsAndConditions: Yup.bool().oneOf(
      [true],
      "You need to accept the terms and conditions"
    ),
  });
  const { radiologyTestCenterDetailsById } = useSelector(
    ({ RadiologySlice }) => RadiologySlice
  );

  return (
    <>
      <CancellationPolicyModel
        show={cancellationPolicy}
        onHide={() => setCancellationPolicy(false)}
        setCancelReasonModel={props.onHide}
      />

      <Modal
        {...props}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="modal_476"
        backdrop="static"
      >
        <Modal.Header style={{ border: "none" }}>
          <div className="consultation_cancel_modal_head_box">
            <h5 className="consultation_cancel_modal_title">
              Select Reason for Cancellation
            </h5>
            <h5 className="consultation_cancel_modal_subtitle">
              Can cancel before 2 hours of booking
            </h5>
          </div>
          <img
            src={Icon.cross}
            className="book_modal_close"
            onClick={props.onHide}
            alt="My Awesome Image"
          ></img>
        </Modal.Header>
        <Modal.Body>
          <div className="consultation_cancel_modal_body_box">
            <Formik
              initialValues={{
                cancelationDetail: "",
                termsAndConditions: false,
              }}
              validationSchema={ReasonCancellationSchema}
              onSubmit={(values) => {
                if (
                  bookingDetail?.id &&
                  values.cancelationDetail.name &&
                  values.termsAndConditions == true
                ) {
                  let request = {
                    booking_id: bookingDetail?.id,
                    reason: values.cancelationDetail.name,
                  };
                  dispatch(BookingCancelById(request));
                  props.onHide();
                } else if (radiologyTestCenterDetailsById?.id) {
                  dispatch(
                    RadiologyCancelBooking({
                      booking_id: radiologyTestCenterDetailsById?.id,
                      reason: values?.cancelationDetail?.id,
                    })
                  ).then((res) => {
                    if (res) {
                      props.onHide();
                    }
                  });
                }
              }}
            >
              {({
                values,
                setFieldValue,
                handleSubmit,
                handleBlur,
                touched,
                errors,
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-12">
                      {props?.cancelationReason?.length &&
                        props?.cancelationReason?.map((cancelation, index) => (
                          <>
                            <div
                              className="consultation_cancel_modal"
                              key={index}
                            >
                              <div className="consultation_cancel_box_inner">
                                <input
                                  type="radio"
                                  className="mt_10"
                                  name="cancelationDetail"
                                  // id={cancelation.id}
                                  id='test1'
                                  value={cancelation}
                                  onChange={() =>
                                    setFieldValue(
                                      "cancelationDetail",
                                      cancelation
                                    )
                                  }
                                  onBlur={handleBlur}
                                  checked={
                                    values.cancelationDetail.name ===
                                    cancelation?.name
                                  }
                                />
                                {/* <lable for={cancelation.id}> */}
                                <lable for='test1'>
                                  <h5 className="consultation_cancel_modal">
                                    {cancelation.name}
                                  </h5>
                                </lable>
                              </div>
                            </div>
                            <hr className="consultation_cancel_modal_hr" />
                          </>
                        ))}
                    </div>
                    {errors.cancelationDetail && touched.cancelationDetail && (
                      <div style={{ color: "red", paddingBottom: "10px" }}>
                        {errors.cancelationDetail}
                      </div>
                    )}
                    <div className="col-md-12">
                      <div>
                        <div className="healthpackage ">
                          <input
                            id="checkbox1"
                            type="checkbox"
                            className="vh "
                            name="termsAndConditions"
                            onChange={() =>
                              setFieldValue(
                                "termsAndConditions",
                                !values.termsAndConditions
                              )
                            }
                          />
                          <label
                            className="healthpackage_checkbox_title"
                            for="checkbox1"
                            style={{
                              display: "inline-flex",
                              alignItems: "center",
                            }}
                          >
                            {" "}
                            <span
                              className="i_agree_cancellation_policy"
                              style={{ padding: "25px" }}
                            >
                              I agree with{" "}
                              <a>
                                <span
                                  className="cancellation_policy_text"
                                  onClick={() => {
                                    props.onHide();
                                    setCancellationPolicy(true);
                                  }}
                                >
                                  Cancellation Policy
                                </span>{" "}
                              </a>{" "}
                            </span>
                          </label>
                        </div>

                        {errors.termsAndConditions &&
                          touched.termsAndConditions && (
                            <div className="terms_conditions_error_text">
                              {errors.termsAndConditions}
                            </div>
                          )}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <center>
                        <Button className="cancel_booking_btn" type="submit">
                          Cancel Booking
                        </Button>
                      </center>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </Modal.Body>
        <Modal.Footer
          style={{ border: "none", display: "none" }}
        ></Modal.Footer>
      </Modal>
    </>
  );
};

const CancellationPolicyModel = (props) => {
  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="modal_476"
    >
      <Modal.Header style={{ border: "none" }}>
        <h5 className="consultation_cancel_modal_title">
          Booking Cancellation Policy
        </h5>
        <img
          src={Icon.cross}
          className="book_modal_close"
          onClick={props.onHide}
          alt="My Awesome Image"
        ></img>
      </Modal.Header>
      <Modal.Body>
        <div className="consultation_cancel_modal_body_box">
          <p className="booking_cancellation_policy_text">
          Test oder can be canceled up to 4 hours before the scheduled time. Cancellations made within 24 hours of the scheduled time will not be refunded. In case of an emergency, the appointment can be canceled at any time.but will not refunded it can only be rescheduled.
          </p>
         
        </div>
      </Modal.Body>

      <Modal.Footer style={{ border: "none" }}>
        <div className="col-md-12">
          <center>
            <Button
              className="cancel_booking_btn"
              onClick={() => {
                props.onHide();
                props.setCancelReasonModel();
              }}
            >
              Ok
            </Button>
          </center>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default CancelationReasonModel;
