import moment from "moment";
import React from "react";
import { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearChat, GetSnapShot } from "../../../../Store/Reducer/ChatReducer";
import { Icon } from "../../../../Utilities/Icons";
import PresciptionDetails from "../../../Common/BookingSuccessModel/PresciptionDetails";
import { Document, Page, pdfjs } from 'react-pdf';
import ChatInput from "./ChatInput";

function Conversations({ roomData, consultDetails }) {
  const inputRef = useRef();

  const dispatch = useDispatch();
  const { ChatSlice } = useSelector((state) => state);
  const { snapShot, chat } = ChatSlice;
  const [localFile, setLocalFile] = useState();
  const [isPdfOpen, setIsPdfOpen] = useState(false);
  const messagesEndRef = useRef(null);
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
    return () => { };
  }, [chat]);


  useEffect(() => {
    dispatch(clearChat());
    dispatch(
      GetSnapShot({
        doctor_id: consultDetails?.doctor_id,
        user_id: consultDetails?.user_id,
        booking_id: consultDetails?.id,
      })
    );
    return () => {
      snapShot();
    };
  }, [consultDetails]);
  return (
    <div id="consult_chat_message_list">
      <div className="consult_chat_message_list_inner">

        {localFile ? (
          <div className="attach_items_box">
            <div className="row">
              <div className="col-md-12">
                <img
                  alt="myImg"
                  src={Icon.cross}
                  onClick={(e) => {
                    e.preventDefault();
                    setLocalFile("");
                    scrollToBottom();
                    inputRef.current.clearMessage("");
                  }}
                  className="attach_cross"
                />
              </div>
              <div className="col-md-12">
                <div className="attach_img_box">
                  <center>
                    {localFile?.type?.includes("image") ? (
                      <img
                        className="attch_doc_img"
                        src={URL.createObjectURL(localFile)}
                        alt="file"
                      />
                    ) : (
                      <div className="attach_doc_img_box" >
                        <img src={Icon.pin} alt="doc" />
                        <h5 className="attch_doc_title">{localFile?.name}</h5>
                        <h6 className="attach_doc_size">
                          {localFile?.type?.split("/")[1].toUpperCase()} -{" "}
                          {localFile?.size} mb
                        </h6>
                      </div>
                    )}
                  </center>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            {chat?.map((item, index) => (
              <ChatItem
                key={(item?.sizeOfDocument || 0) + index}
                type={item?.documentType}
                index={index}
                rest={item}
                isPdfOpen={isPdfOpen}
                setIsPdfOpen={setIsPdfOpen}
              />
            ))}
            <div ref={messagesEndRef} />
          </>
        )}
      </div>
      <ChatInput
        ref={inputRef}
        localFile={localFile}
        setLocalFile={setLocalFile}
        consultDetails={consultDetails}
      />
    </div>
  );
}
const ChatItem = ({ type, index, rest, isPdfOpen, setIsPdfOpen }) => {

  const [presciptionDetailModel, setPresciptionDetailModel] = useState(false);

  const togglePdfOpen = () => {
    setIsPdfOpen(!isPdfOpen);
  }
  const openPdfInNewTab = (pdfUrl) => {
    const newWindow = window.open(pdfUrl, '_blank');
    if (newWindow) {
      newWindow.focus();
    } else {
      // Handle popup blocker or window opening failure
      alert('Failed to open PDF in a new tab. Please check your browser settings.');
    }
  }
  const isReciever = rest?.userType === 0;
  switch (parseInt(type)) {
    case 1: //Image
      return (
        <div
          className={`message-row ${isReciever ? "other-message" : "you-message"
            }`}
        >
          <div className="message-content">
            <div
              className={
                isReciever
                  ? "consult_sender_msg_box "
                  : "consult_client_msg_box"
              }
            >
              <div className="consult_attach_img_box">
                <div className="consult_attach_img_card">
                  <img
                    src={rest?.imageUrl}
                    className="consult_attach_img_sty"
                    alt=""
                  ></img>
                </div>
                <h5
                  className={
                    isReciever
                      ? "consult_attach_img_text"
                      : "consult_attach_img_text_check"
                  }
                >
                  {rest.message}
                </h5>
                <div
                  className={
                    isReciever
                      ? "consult_sender_message_time"
                      : "consult_you_message_time"
                  }
                >
                  {rest?.dateTime &&
                    moment(rest?.dateTime?.toDate()).format("hh:mm")}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    case 2: //Video
      return (
        <div
          className={`message-row ${isReciever ? "other-message" : "you-message"
            }`}
        >
          <div className="message-content">
            <div
              className={
                isReciever
                  ? "consult_sender_msg_box "
                  : "consult_client_msg_box"
              }
            >
              <div className="consult_attach_img_box">
                <div className="consult_attach_img_card">
                  <iframe
                    // ref={videoRef}
                    allow="accelerometer;fullscreen; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                    autoSave="false"
                    width="200px"
                    height="200px"
                    src={rest?.imageUrl}
                  ></iframe>
                </div>
                <h5
                  className={
                    isReciever
                      ? "consult_attach_img_text"
                      : "consult_attach_img_text_check"
                  }
                >
                  {rest.message}
                </h5>
                <div
                  className={
                    isReciever
                      ? "consult_sender_message_time"
                      : "consult_you_message_time"
                  }
                >
                  {rest?.dateTime &&
                    moment(rest?.dateTime?.toDate()).format("hh:mm")}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    case 3: //Document

      return (
        <div
          className={`message-row ${isReciever ? "other-message" : "you-message"
            }`}
          onClick={() => { openPdfInNewTab(rest?.imageUrl) }}
        >
          <div className="message-content">
            <div
              className={
                isReciever ? "consult_sender_msg_box" : "consult_client_msg_box"
              }
            >
              <div
                className={
                  isReciever
                    ? "consult_attach_doc_box"
                    : "consult_client_attach_doc_box"
                }
              >
                <div
                  className={
                    isReciever
                      ? "consult_attach_doc_box_inner"
                      : "consult_client_attach_doc_box_inner"
                  }
                >
                  <div className="consult_attach_doc_box_SUBinner">
                    <img
                      src={Icon.pin}
                      className="consult_attach_doc_pin_icon"
                      alt=""
                      onClick={() => { togglePdfOpen() }}
                    ></img>


                    <h5 className="consult_attach_doc_title">
                      {rest?.imageName}
                    </h5>
                  </div>
                  {/* {isReciever && (
                    <div className={"consult_download_icon"}>
                      <img src={Icon.download} alt="" />
                    </div>
                  )} */}
                </div>
              </div>


              <h3
                className={
                  isReciever
                    ? "consult_sender_text_title"
                    : "consult_client_attach_img_text_check"
                }
              >
                {rest?.message}
              </h3>
              <div
                className={
                  isReciever
                    ? "consult_sender_message_time"
                    : "consult_you_message_time"
                }
              >
                {rest?.dateTime &&
                  moment(rest?.dateTime?.toDate()).format("hh:mm")}
              </div>
            </div>
          </div>
        </div>
      );
    case 4: //Prescription
      return (
        <>
          <PresciptionDetails
            show={presciptionDetailModel}
            onHide={() => setPresciptionDetailModel(false)}
          />

          <div
            className={`message-row ${rest?.userType === 0 ? "other-message" : "you-message"
              }`}
          >
            <div className="message-content">
              <div
                className={`${rest?.userType === 0 ? "sender_msg_box" : "client_msg_box"
                  }`}
              >
                <div className="msg_view_box">
                  <div>
                    <h3 className="sender_name_title">Prescription</h3>
                    <h5 className="sender_name_subtitle">
                      Appointment : {rest?.imageName}
                    </h5>
                  </div>
                  {rest?.imageName && (
                    <button
                      variant="primary"
                      className="msg_view_btn"
                      onClick={(e) => {
                        e.preventDefault();
                        setPresciptionDetailModel(true);
                        // dispatch(
                        //   GetPrescDetails({ booking_id: rest?.imageName })
                        // );
                        // dispatch(toggleReview(true));
                      }}
                    >
                      View
                    </button>
                  )}
                </div>
              </div>
              <div
                className={`${rest?.userType === 0
                  ? "sender_message_time"
                  : "client_message_time"
                  }`}
              >
                {rest?.dateTime &&
                  moment(rest?.dateTime?.toDate()).format("hh:mm")}
              </div>
            </div>
          </div>
        </>
      );
    default:
      return (
        <div
          className={`message-row ${rest?.userType === 0 ? "other-message" : "you-message"
            }`}
        >
          <div className="message-content">
            <div
              className={`${rest?.userType === 0 ? "sender_msg_box" : "client_msg_box"
                }`}
            >
              {rest?.question ? (
                <>
                  <h3 className="sender_name_title yellow">{rest?.question}</h3>
                  <h5 className="sender_name_subtitle yellow">
                    {rest?.answer}
                  </h5>
                </>
              ) : (
                <h3
                  className={`${rest?.userType === 0
                    ? "sender_text_title"
                    : "client_text_title"
                    }`}
                >
                  {rest?.message}
                </h3>
              )}
            </div>
            <div
              className={`${rest?.userType === 0
                ? "sender_message_time"
                : "client_message_time"
                }`}
            >
              {rest?.dateTime &&
                moment(rest?.dateTime?.toDate()).format("hh:mm")}
            </div>
          </div>
        </div>
      );
  }
};
export default Conversations;

