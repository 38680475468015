import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import "../../../Assets/css/responsive.css";
import { useDispatch, useSelector } from "react-redux";
import { Icon } from "../../../Utilities/Icons";
import { useNavigate } from "react-router-dom";
import AddMember from "../../Common/MemberModel/AddMember";
import { AddNewMembers } from "../../../Store/Reducer/ProfileReducer";
import { BackGround } from "../../../Utilities/Icons";
import Accordion from "react-bootstrap/Accordion";

import { removeSession } from "../../../Store/Reducer/CommonSlice";
import { RescheduleDoctor, activeDate } from "../../../Store/Reducer/BookingSlice";
import SubPlan from "./Subscription/SubPlan";
import { Formik, useFormikContext } from "formik";
import { useCallback } from "react";

const Doctor = ({ props, onDoneClick }) => {
  const {
    upcomingBookings,
    pastBookings,
    cancelationReason,
    rescheduleDoctorDetails,
    rescheduleDoctor,
    bookingDetail,
    activeDateRes,
  } = useSelector(({ BookingSlice }) => BookingSlice);
  const [newMembers, setNewMembers] = useState(false);
  let addMemberinitialState = {
    name: "",
    email: "",
    mobile_number: "",
    age: "",
    height: "",
    weight: "",
    gender: "",
    blood_group: "",
    birthdate: "",
    medical_history: [],
  };
  let colors = ["red", "blue", "green", "purple", "pink"];
  const randomColor = () => {
    return colors[(Math.random() * colors.length) >> 0];
  };
  const dispatch = useDispatch();
  const [activeCard, setActiveCard] = useState(null);
  const [dateActive, setDateActive] = useState(0);
  const handleCardClick = (date, index) => {
    RescheduleSlot(date, index);
  };
  const [activeIndex, setActiveIndex] = useState();
  const handleCardClicks = (index) => {
    setActiveIndex(index);
  };
  // Slot //
  const RescheduleSlot = (date, index) => {
    const formattedDate = formatDate(date);
    dispatch(
      RescheduleDoctor({
        doctor_id: 364,
        date: formattedDate,
      })
    ).then(() => {
      dispatch(activeDate(index))
    })
  };
  const formatDate = (date) => {
    let selectedDate;
    if (date === "Today") {
      selectedDate = new Date();
    } else if (date === "Tomarrow") {
      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      selectedDate = tomorrow;
    } else {
      const [day, month, year] = date.split("/");
      selectedDate = new Date(`${month}/${day}/${year}`);
    }
    const formattedDate = selectedDate.toLocaleDateString("en-GB");
    return formattedDate;
  };
  const formikProps = useFormikContext();

  return (
    <>
      <AddMember
        addEditMemberinitialState={addMemberinitialState}
        addEditMember={AddNewMembers}
        show={newMembers}
        onHide={() => setNewMembers(false)}
      />
      <div className="profiledash_body_box_card_head">
        <div className="row">
          <div className="col-md-6 col-6">
            <h5 className="profiledash_body_card_head_title">Doctor</h5>
          </div>
        </div>
      </div>
      <div className="profiledash_body_box_card_head" style={{ borderRadius: "0px" }}>
        <div className="discription_under" style={{ padding: "10px 0px" }}>
          <div>
            <img
              src={rescheduleDoctorDetails && rescheduleDoctorDetails.data.image}
              className=""
              alt="not found"
              style={{ height: "95px", width: "85px", borderRadius: "11px" }}
            ></img>{" "}
          </div>
          <div className="desc_second">
            <p className="subscription_package_box_title">{rescheduleDoctorDetails && rescheduleDoctorDetails.data.name}</p>
            <p className="" style={{ fontSize: "12px", color: "#67696C", fontWeight: "normal" }}>
              {rescheduleDoctorDetails && rescheduleDoctorDetails.data.speciality}
            </p>
            <p className="subscription_package_box_subtitle">Exp - {rescheduleDoctorDetails && rescheduleDoctorDetails.data.experience} Years</p>
            <div className="d-flex items-center gap-2">
              <p className="subscription_package_box_endtitle">{rescheduleDoctorDetails && rescheduleDoctorDetails.data.total_consulation} Consultations</p>
              <img src={Icon.consultant} style={{ height: "18px", width: "20px" }}></img>
            </div>
          </div>
        </div>
      </div>
      <div className="profiledash_body_box_card_body">
        <div className="row">
          <div className="col-md-6 col-6">
            <h5 className="profiledash_body_card_head_title">Select Date</h5>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-7">
            <div className="d-flex flex-wrap justify-content-around" style={{ paddingTop: "30px", paddingBottom: "10px" }}>
              {rescheduleDoctorDetails?.data?.available_days ? <>
                {Object.entries(rescheduleDoctorDetails?.data?.available_days).map(([date, info], index) => (
                  <div
                    className={`small_card${activeDateRes == index ? "_active" : ""}`}
                    style={{ padding: "10px 30px", marginBottom: "10px", height: "72px", width: "116px" }}
                    key={date}
                    onClick={() => { handleCardClick(date, index); formikProps.setFieldValue("appointment_date", date) }}
                  >
                    <div>
                      <p style={{ color: "#303236", fontSize: "14px", textAlign: "center", fontWeight: "500" }}>{date}</p>
                      <p style={{ fontSize: "11px", textAlign: "center", color: "#199A8E" }}>{info.slots} slots</p>
                    </div>
                  </div>
                ))}
              </> : ""}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-6">
            <h5 className="profiledash_body_card_head_title">Select time</h5>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-7" style={{ paddingTop: "10px" }}>
            {
              rescheduleDoctor ?
                <>
                  <Accordion >
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        Morning{" "}
                        <span className="time_avilable_subtitle">
                          {rescheduleDoctor && rescheduleDoctor.data?.available_slots?.morning?.length} slots available
                        </span>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="abc">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="time_slot_card_box" style={{ paddingRight: "10px" }}>
                                {rescheduleDoctor &&
                                  rescheduleDoctor.data?.available_slots?.morning?.map((item, index) => (
                                    <div className="consultation_cancel_modal consulatation-time" key={index}>
                                      <div
                                        className={`time_slot_card${activeIndex === index ? " time_slot_card_active" : ""}`}
                                      >
                                        <label>
                                          <input
                                            type="radio"
                                            name="appointmentDetails"
                                            onClick={() => { handleCardClicks(index); formikProps.setFieldValue("appointment_time", item.start_time) }}
                                            style={{ accentColor: "#199a8e"}}
                                          />
                                          <h5 className="time_slot_card_text">{item.start_time}</h5>
                                        </label>
                                      </div>
                                    </div>
                                  ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>
                        Afternoon{" "}
                        <span className="time_avilable_subtitle">
                          {rescheduleDoctor && rescheduleDoctor.data.available_slots.afternoon.length} slots available
                        </span>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="abc">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="time_slot_card_box" style={{ paddingRight: "10px" }}>
                                {rescheduleDoctor &&
                                  rescheduleDoctor.data.available_slots.afternoon.map((item, index) => (
                                    <div className="consultation_cancel_modal consulatation-time" key={index}>
                                      <div
                                        className={`time_slot_card${activeIndex === index ? " time_slot_card_active" : ""}`}
                                      >
                                        <label>
                                          <input
                                            type="radio"
                                            name="appointmentDetails"
                                            onClick={() => { handleCardClicks(index); formikProps.setFieldValue("appointment_time", item.start_time) }}
                                            style={{ accentColor: "#199a8e"}}
                                          />
                                          <h5 className="time_slot_card_text">{item.start_time}</h5>
                                        </label>
                                      </div>
                                    </div>
                                  ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>
                        Evening{" "}
                        <span className="time_avilable_subtitle">
                          {rescheduleDoctor && rescheduleDoctor.data.available_slots.evening.length} slots available
                        </span>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="abc">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="time_slot_card_box" style={{ paddingRight: "10px" }}>
                                {rescheduleDoctor &&
                                  rescheduleDoctor.data.available_slots.evening.map((item, index) => (
                                    <div className="consultation_cancel_modal consulatation-time" key={index}>
                                      <div
                                        className={`time_slot_card${activeIndex === index ? " time_slot_card_active" : ""}`}
                                      >
                                        <label>
                                          <input
                                            type="radio"
                                            name="appointmentDetails"
                                            onClick={() => { handleCardClicks(index); formikProps.setFieldValue("appointment_time", item.start_time) }}
                                            style={{ accentColor: "#199a8e"}}
                                          />
                                          <h5 className="time_slot_card_text">{item.start_time}</h5>
                                        </label>
                                      </div>
                                    </div>
                                  ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header>
                        Night{" "}
                        <span className="time_avilable_subtitle">
                          {rescheduleDoctor && rescheduleDoctor.data.available_slots.night.length} slots available
                        </span>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="abc">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="time_slot_card_box" style={{ paddingRight: "10px" }}>
                                {rescheduleDoctor &&
                                  rescheduleDoctor.data.available_slots.night.map((item, index) => (
                                    <div className="consultation_cancel_modal consulatation-time" key={index}>
                                      <div
                                        className={`time_slot_card${activeIndex === index ? " time_slot_card_active" : ""}`}
                                      >
                                        <label>
                                          <input
                                            type="radio"
                                            name="appointmentDetails"
                                            onClick={() => { handleCardClicks(index); formikProps.setFieldValue("appointment_time", item.start_time) }}
                                            style={{ accentColor: "#199a8e"}}
                                          />
                                          <h5 className="time_slot_card_text">{item.start_time}</h5>
                                        </label>
                                      </div>
                                    </div>
                                  ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </> :
                <>
                  <Accordion >
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        Morning{" "}
                        <span className="time_avilable_subtitle">
                          {rescheduleDoctorDetails && rescheduleDoctorDetails.data.available_slots.morning.length} slots available
                        </span>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="abc">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="time_slot_card_box" style={{ paddingRight: "10px" }}>
                                {rescheduleDoctorDetails &&
                                  rescheduleDoctorDetails.data.available_slots.morning.map((item, index) => (
                                    <div className="consultation_cancel_modal consulatation-time" key={index}>
                                      <div
                                        className={`time_slot_card${activeIndex === index ? " time_slot_card_active" : ""}`} >
                                        <label>
                                          <input
                                            type="radio"
                                            name="appointmentDetails"
                                            onClick={() => { handleCardClicks(index); formikProps.setFieldValue("appointment_time", item.start_time) }}
                                            style={{ accentColor: "#199a8e"}}
                                          />
                                          <h5 className="time_slot_card_text">{item.start_time}</h5>
                                        </label>
                                      </div>
                                    </div>
                                  ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>
                        Afternoon{" "}
                        <span className="time_avilable_subtitle">
                          {rescheduleDoctorDetails && rescheduleDoctorDetails.data.available_slots.afternoon.length} slots available
                        </span>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="abc">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="time_slot_card_box" style={{ paddingRight: "10px" }}>
                                {rescheduleDoctorDetails &&
                                  rescheduleDoctorDetails.data.available_slots.afternoon.map((item, index) => (
                                    <div className="consultation_cancel_modal consulatation-time" key={index}>
                                      <div
                                        className={`time_slot_card${activeIndex === index ? " time_slot_card_active" : ""}`}
                                      >
                                        <label>
                                          <input
                                            type="radio"
                                            name="appointmentDetails"
                                            onClick={() => { handleCardClicks(index); formikProps.setFieldValue("appointment_time", item.start_time) }}
                                            style={{ accentColor: "#199a8e"}}
                                          />
                                          <h5 className="time_slot_card_text">{item.start_time}</h5>
                                        </label>
                                      </div>
                                    </div>
                                  ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>
                        Evening{" "}
                        <span className="time_avilable_subtitle">
                          {rescheduleDoctorDetails && rescheduleDoctorDetails.data.available_slots.evening.length} slots available
                        </span>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="abc">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="time_slot_card_box" style={{ paddingRight: "10px" }}>
                                {rescheduleDoctorDetails &&
                                  rescheduleDoctorDetails.data.available_slots.evening.map((item, index) => (
                                    <div className="consultation_cancel_modal consulatation-time" key={index}>
                                      <div
                                        className={`time_slot_card${activeIndex === index ? " time_slot_card_active" : ""}`}
                                      >
                                        <label>
                                          <input
                                            type="radio"
                                            name="appointmentDetails"
                                            onClick={() => { handleCardClicks(index); formikProps.setFieldValue("appointment_time", item.start_time) }}
                                            style={{ accentColor: "#199a8e"}}
                                          />
                                          <h5 className="time_slot_card_text">{item.start_time}</h5>
                                        </label>
                                      </div>
                                    </div>
                                  ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header>
                        Night{" "}
                        <span className="time_avilable_subtitle">
                          {rescheduleDoctorDetails && rescheduleDoctorDetails.data.available_slots.night.length} slots available
                        </span>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="abc">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="time_slot_card_box" style={{ paddingRight: "10px" }}>
                                {rescheduleDoctorDetails &&
                                  rescheduleDoctorDetails.data.available_slots.night.map((item, index) => (
                                    <div className="consultation_cancel_modal consulatation-time" key={index}>
                                      <div
                                        className={`time_slot_card${activeIndex === index ? " time_slot_card_active" : ""}`}
                                      >
                                        <label>
                                          <input
                                            type="radio"
                                            name="appointmentDetails"
                                            onClick={() => { handleCardClicks(index); formikProps.setFieldValue("appointment_time", item.start_time) }}
                                            style={{ accentColor: "#199a8e"}}
                                          />
                                          <h5 className="time_slot_card_text">{item.start_time}</h5>
                                        </label>
                                      </div>
                                    </div>
                                  ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </>
            }
            <center>
              <Button type="submit" className="time_slot_btn" onClick={() => {onDoneClick()}}>
                Done
              </Button>
            </center>
          </div>
        </div>
      </div>
    </>
  );
};

export default Doctor;
