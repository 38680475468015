import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GetMedicineAPI, GetMedicineDetailAPI } from "../../Routes/Service";
import { AlertEnum } from "../../Utilities/Enums";
import { setLoading, setMessage } from "./LayoutsSice";

const initialState = {
  medicines: "",
  medicineDetail: "",
  shoppingCartProduct: [],
  cart: [],
  bookNowmedicine:{}
};

export const GetMedicine = createAsyncThunk(
  "GetMedicine",
  async (values, { dispatch }) => {
    try {
      dispatch(setLoading(true));

  
      const result = await GetMedicineAPI(values);

      if (result) {
        dispatch(setLoading(false));

        return result?.data;
      } else {
        throw result;
      }
    } catch (error) {
      dispatch(setLoading(false));
      // dispatch(
      //   setMessage({
      //     text: error?.message,
      //     type: AlertEnum.Error,
      //   })
      // );
      return error;
    }
  }
);
export const GetMedicineDetail = createAsyncThunk(
  "GetMedicineDetail",
  async (values, { dispatch }) => {
    try {
      const result = await GetMedicineDetailAPI({ product_id: values });
      if (result) {
        return result?.data;
      } else {
        throw result;
      }
    } catch (error) {
      dispatch(setLoading(false));
      // dispatch(
      //   setMessage({
      //     text: error?.message,
      //     type: AlertEnum.Error,
      //   })
      // );
      return error;
    }
  }
);
export const MedicineSlice = createSlice({
  name: "MedicineSlice",
  initialState,
  reducers: {
    toggleProblemsModal: (state, action) => {
      state.problemsModal = action.payload;
    },
    madicineAddToCart: (state, action) => {
      const itemInCart = state.cart.find(
        (item) => item.id === action.payload.id
      );
      if (itemInCart) {
        itemInCart.quantity++;
      } else {
        state.cart.push({ ...action.payload, quantity: 1 });
      }
    },
    bookButtonMedicine: (state, action) => {
      state.bookNowmedicine = action.payload
    },
    incrementQuantity: (state, action) => {
      const item = state.cart.find((item) => item.id === action.payload);
      item.quantity++;
    },
    decrementQuantity: (state, action) => {
      const item = state.cart.find((item) => item.id === action.payload);

      if (item.quantity === 0) {
        const removeItem = state.cart.filter(
          (item) => item.id !== action.payload
        );
        state.cart = removeItem;
      } else {
        if (item.quantity === 1) {
          item.quantity = 0;
        } else {
          item.quantity--;
        }
      }
    },
    setQuantity: (state, action) => {
      
      const item = state.cart.find((item) => item.id === action.payload.id);
      
      item.quantity = parseInt(action.payload.qty);
    },
    removeItem: (state, action) => {
      const removeItem = state.cart.filter(
        (item) => item.id !== action.payload
      );
      state.cart = removeItem;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(GetMedicine.fulfilled, (state, action) => {
     
      state.medicines = action.payload;
    });
    builder.addCase(GetMedicineDetail.fulfilled, (state, action) => {
      state.medicineDetail = action.payload;
    });
  },
});

export const {
  toggleProblemsModal,
  madicineAddToCart,
  incrementQuantity,
  decrementQuantity,
  removeItem,
  setQuantity,
  bookButtonMedicine,
} = MedicineSlice.actions;
export default MedicineSlice.reducer;
