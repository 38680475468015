

import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
    addToCart,
    LabtestLabs,
    removeItem,
} from "../../../Store/Reducer/LabtestSlice";
import { Icon } from "../../../Utilities/Icons";
import Checkbox from "../Checkbox";
import { HealthList } from "../../../Routes/Service";
import { setServiceid } from "../../../Store/Reducer/RadiologySlice";

const SurgeryCategory = (props) => {
    const { labTestList, labCart, labTestCenterDetailsById } = useSelector(
        ({ LabtestSlice }) => LabtestSlice
    );
    const { surgeryserviceList } = useSelector(
        ({ RadiologySlice }) => RadiologySlice
    );
    const dispatch = useDispatch();
    const [inputText, setInputText] = useState("");
    let inputHandler = (e) => {
        //convert input text to lower case
        var lowerCase = e.target.value.toLowerCase(); //.toLowerCase();
        setInputText(lowerCase);
    };

    
    const filteredData = surgeryserviceList?.filter((el) => {
        //if no input the return the original
        if (inputText === "") {
            return el;
        }
        //return the item which contains the user input
        else {
            return el.name?.toLowerCase().includes(inputText);
        }
    });
    const getTotal = () => {
        let totalQuantity = 0;
        let totalPrice = 0;
        labCart?.forEach((item) => {
            totalPrice += item?.amount * item?.quantity;
        });
        return { totalPrice, totalQuantity };
    };

    let name = labCart?.map(function (i) {
        return i?.id;
    });

    const [isCheck, setIsCheck] = useState(name);

    const handleClick = (e) => {
        const { id, checked } = e.target;
        const numberId = Number(id);
        setIsCheck([...isCheck, numberId]);
        
        filteredData?.filter(function (item) {
            if (numberId == item?.id) {
                dispatch(addToCart(item));
                dispatch(setServiceid(item?.id))
            }
        });

        if (!checked) {
            filteredData?.filter(function (item) {
                if (numberId == item?.id) {
                    dispatch(removeItem(item?.id));
                }
            });
            setIsCheck(isCheck.filter((item) => item !== numberId));
        }
    };

    const { session } = useSelector(({ AuthSlice }) => AuthSlice);



    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            dialogClassName="lab_test_modal_box"
            backdrop="static"
        >
            <Modal.Header
                className="lab_test_modal_box_head_padding"
                style={{ marginBottom: "none", border: "none" }}
            >
                <h5 className="lab_test_popup_title">{props?.category?.name}</h5>
                <img
                    src={Icon.cross}
                    className="lab_test_popup_close"
                    onClick={() => {
                        props.onHide();
                    }}
                ></img>
            </Modal.Header>
            <Modal.Body className="">
                <form>
                    <div className="lab_test_modal_box_inner_1">
                        <div className="row">
                            <div className="col-md-12">
                                <img
                                    src={Icon.search}
                                    className="select_city_search_icon"
                                ></img>
                                <input
                                    type="search"
                                    className="health_package_input_search"
                                    placeholder="Search"
                                    onChange={inputHandler}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="lab_test_popup_box">
                        <div className="lab_test_modal_box_inner_2">
                            {filteredData?.length > 0 &&
                                filteredData?.map((item, index) => (
                                    <div className="healthpackage_checkbox_box" key={index}>
                                        <div className="healthpackage_checkbox_left">
                                            <img
                                                className="select_radiology_img"
                                                src={`${item?.image}`}
                                                alt=""
                                                height="70px"
                                                width="70px"
                                                style={{
                                                    borderRadius: "50%"
                                                }}
                                            />
                                            <label
                                                className="healthpackage_checkbox_title"
                                                htmlFor={item?.id}
                                                style={{
                                                    display: "inline-flex",
                                                    alignItems: "center",
                                                    marginLeft: "20px"
                                                }}
                                            >
                                                {item?.name}
                                            </label>
                                        </div>
                                        <div className="healthpackage_checkbox_right">
                                                <Checkbox
                                                    key={item?.id}
                                                    type="checkbox"
                                                    name={item?.id}
                                                    id={item?.id}
                                                    handleClick={handleClick}
                                                    isChecked={isCheck?.includes(item?.id)}
                                             

                                                />
                                        </div>
                                    </div>
                                ))}
                            {filteredData?.length == 0 && (
                                <h6> No surgery Tests Found</h6>
                            )}
                        </div>
                    </div>

                </form>
                <center>
                    <Button
                        className="lab_test_modal_footer_btn"
                        type="submit"
                        onClick={() => {
                            props.onNext();
                        }}
                    >
                        Next
                    </Button>
                </center>
            </Modal.Body>

        </Modal>
    );
};

export default SurgeryCategory;


