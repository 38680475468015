import React, { useState } from "react";
import { useSelector } from "react-redux";
import AddressModel from "./AddressModel";
import { Icon } from "../../../Utilities/Icons";
import { Button, Modal } from "react-bootstrap";

const SelectAddressModel = (props) => {
  const [newAddressModel, setAddressModel] = useState(false);
  const { session } = useSelector(({ AuthSlice }) => AuthSlice);
  const { addressList } = useSelector(({ AddressSlice }) => AddressSlice);

  return (
    <>
      <AddressModel
        show={newAddressModel}
        onHide={() => setAddressModel(!newAddressModel)}
      />
      <Modal
        {...props}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="modal_500"
        backdrop="static"
      >
        <Modal.Header
          className="lab_test_modal_box_head_padding"
          style={{ marginBottom: "none", border: "none" }}
        >
          <h5 className="lab_test_popup_title">
            Select Address
          </h5>
          <img
            src={Icon.cross}
            className="lab_test_popup_close"
            onClick={props.onHide}
            alt="My Awesome Image"
          ></img>
        </Modal.Header>
        <Modal.Body>
          <div className="p-3">
            <div className="select_member_box_2">
              {addressList?.map((address, index) => (
                <>
                  <div className="consult_member_box_1" key={index}>
                    <div className="consult_member_box_inner">
                      <div>
                        <input
                          type="radio"
                          name="address"
                          onChange={(e) =>
                            props.formik.setFieldValue("address", {
                              name: address?.type,
                              type: address.type,
                              mobile_no: session?.mobile_number,
                              address: address?.address,
                              landmark: "Jalchock",
                              pincode: address?.pincode,
                              city: address?.city_state,
                              state: "Gujarat",
                              id: address?.id,
                             
                            })
                          }
                          id="address"
                          value={address?.id}
                          checked={
                            props?.formik?.values?.address?.id === address?.id
                          }
                          placeholder="Male"
                          style={{ marginTop: "20px" }}
                        />
                        <lable for={address?.id}>
                          <h5 className="lab_sample_collection_add_title">
                            {address?.type}
                          </h5>
                          <h5 className="lab_sample_collection_add_para">
                            {address?.address}
                            {/* 11 shivam flat, newar sport complex,
                              <br /> tulishidham, manjalpur, vadodara */}
                          </h5>
                        </lable>
                      </div>
                    </div>
                  </div>
                  <hr className="consult_member_hr" />
                </>
              ))}
            </div>
            <div className="add_member_box">
              <img
                src={Icon.add}
                className="add_member_icon"
                alt="My Awesome Image"
              ></img>
              <h5
                className="add_member_text"
                onClick={() => {
                  props.onHide();
                  setAddressModel(true);
                }}
              >
                Add New Address
              </h5>
            </div>
            <center>
              <Button
                className="consult_modal_btn"
                type="submit"
                onClick={() => props.onHide()}
              >
                Continue
              </Button>
            </center>
          </div>
        </Modal.Body>
        <Modal.Footer
          style={{ border: "none", display: "none" }}
        ></Modal.Footer>
      </Modal>
    </>
  );
};

export default SelectAddressModel;
