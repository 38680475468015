import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import { Icon } from "../../../Utilities/Icons";
import { SelectedDate } from "../../../Store/Reducer/BookingSlice";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  RadiologyRescheduleBooking,
  RadiologyTestCenterSlots,
} from "../../../Store/Reducer/RadiologySlice";

const RadiologicalTestSlotModel = (props) => {
  const { radiologyTestCenterSlotsList, radiologyTestCenterDetailsById } =
    useSelector(({ RadiologySlice }) => RadiologySlice);
  const { userSelectedDate } = useSelector(({ BookingSlice }) => BookingSlice);
  var today = new Date();

  const current = new Date();
  const hours = current.getHours();
  const minutes = current.getMinutes();

  // Convert hours to 12-hour format
  const formattedHours = (hours % 12 === 0) ? 12 : hours % 12;

  // Determine whether it's AM or PM
  const amOrPm = hours >= 12 ? 'PM' : 'AM';

  // Add leading zeros to minutes if necessary
  const formattedMinutes = (minutes < 10) ? `0${minutes}` : minutes;

  // Combine all the formatted parts
  const formattedTime = `${formattedHours}:${formattedMinutes} ${amOrPm}`;
  const date = ` ${current.getFullYear()}/${current.getMonth() + 1
    }/${current.getDate()}`;
  const tomorrow = moment(current).add(1, "days").format("YYYY/MM/DD"); //DD-MM-YYYY
  const nexttomorrow = moment(current).add(2, "days").format("YYYY/MM/DD");
  const nexttomorrow2 = moment(current).add(3, "days").format("YYYY/MM/DD");
  const dispatch = useDispatch();
  const [selecteDate, setSelectedDate] = useState(date);
  const [resheduleDetail, setresheduleDetail] = useState();
  useEffect(() => {
    let slotData = {
      test_center_id: props?.test_center_id,
      date: moment(userSelectedDate).format("YYYY/MM/DD"),
    };
    props?.formik?.setFieldValue("booking_date", userSelectedDate);
    if (slotData) {
      dispatch(RadiologyTestCenterSlots(slotData));
    }
  }, [userSelectedDate, props?.test_center_id]);


  const onReshedule = () => {
    let request = {
      booking_id: radiologyTestCenterDetailsById?.id,
      slot_id: resheduleDetail?.id,
      reason_id: "1",
      appointment_date: resheduleDetail?.start_time,
      appointment_time: userSelectedDate,
    };

    dispatch(RadiologyRescheduleBooking(request));
  };

  const [morning, setMorning] = useState();
  const [afternoon, setAfternoon] = useState();
  const [evening, setEvening] = useState();
  const [night, setNight] = useState();
  const handleCardClicks = (section, index) => {

    if (section === "morning") {
      setMorning(index);
      setAfternoon(null);
      setEvening(null);
      setNight(null);
    } else if (section === "afternoon") {
      setAfternoon(index);
      setMorning(null);
      setEvening(null);
      setNight(null);
    } else if (section === "evening") {
      setEvening(index);
      setNight(null);
      setMorning(null);
      setAfternoon(null);
    } else if (section === "night") {
      setNight(index);
      setAfternoon(null);
      setEvening(null);
      setMorning(null);
    }
  };
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="book_modal_531"
      backdrop="static"
    >
      <Modal.Header
        className=""
        style={{ marginBottom: "none", border: "none" }}
      >
        <h5 className="book_date_time">Select Booking Date & Time</h5>
        <img
          src={Icon.cross}
          className="book_modal_close"
          onClick={props.onHide}
          alt="My Awesome Image"
        ></img>
      </Modal.Header>
      <Modal.Body>
        <div className="book_date_time_box">
          <>
            <h5 className="date_title">
              For, {moment(selecteDate).format("DD-MM-YYYY")}
            </h5>
            <div className="book_date_card_box">
              <div
                className={`book_date_card  ${selecteDate === date ? "book_date_card_active " : null
                  } `}
                onClick={() => {
                  setSelectedDate(date);
                  dispatch(SelectedDate(date));
                }}
              >
                <h5 className="book_date_card_text">Today</h5>
              </div>
              <div
                className={`book_date_card  ${selecteDate === tomorrow ? "book_date_card_active " : null
                  } `}
                onClick={() => {
                  setSelectedDate(tomorrow);
                  dispatch(SelectedDate(tomorrow));
                }}
              >
                <h5 className="book_date_card_text">
                  {moment(tomorrow).format("DD-MM-YYYY")}
                </h5>
              </div>
              <div
                className={`book_date_card  ${selecteDate === nexttomorrow ? "book_date_card_active " : null
                  } `}
                onClick={() => {
                  setSelectedDate(nexttomorrow);
                  dispatch(SelectedDate(nexttomorrow));
                }}
              >
                <h5 className="book_date_card_text">
                  {moment(nexttomorrow).format("DD-MM-YYYY")}
                </h5>
              </div>
              <div
                className={`book_date_card  ${selecteDate === nexttomorrow2
                    ? "book_date_card_active "
                    : null
                  } `}
                onClick={() => {
                  setSelectedDate(nexttomorrow2);
                  dispatch(SelectedDate(nexttomorrow2));
                }}
              >
                <h5 className="book_date_card_text">
                  {moment(nexttomorrow2).format("DD-MM-YYYY")}
                </h5>
              </div>
            </div>
          </>

          <h5 className="time_title">Select time</h5>
          <div className="select_time_box">
            <Accordion defaultActiveKey="0">
              {radiologyTestCenterSlotsList?.slots?.morning.length > 0 && (
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    Morning{" "}
                    <span className="time_avilable_subtitle">
                      {" "}
                      {radiologyTestCenterSlotsList?.slots?.morning
                        ? radiologyTestCenterSlotsList.slots.morning.reduce(
                          (count, item) => {
                            const slotTime = moment(item.start_time, "hh:mm A");
                            if (slotTime.isAfter(moment(formattedTime, "hh:mm A"))) {
                              return count + 1;
                            }
                            return count;
                          },
                          0
                        )
                        : 0} Slots available
                    </span>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="abc">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="time_slot_card_box">
                            {/* {radiologyTestCenterSlotsList?.slots?.morning?.map(
                              (item, index) => (
                                <>
                                  <div className="consultation_cancel_modal consulatation-time">
                                    <div
                                      className={`time_slot_card  ${
                                        props?.formik?.values?.slot_id ===
                                        item.id
                                          ? "time_slot_card_active"
                                          : null
                                      } `}
                                      key={index}
                                    >
                                      <label>
                                        <input
                                          type="radio"
                                          name="slot_id"
                                          value={item?.id}
                                          onClick={() => {
                                            props?.formik?.setFieldValue(
                                              "slot_id",
                                              item?.id
                                            );
                                            props?.formik?.setFieldValue(
                                              "start_time",
                                              item.start_time
                                            );
                                            setresheduleDetail(item);
                                          }}
                                          onBlur={props?.formik?.handleBlur}
                                          checked={
                                            props?.formik?.values?.slot_id ===
                                            item?.title
                                          }
                                          style={{ accentColor: "#199a8e"}}
                                        />
                                        <h5 className="time_slot_card_text time_slot_card_text_active">
                                          {item.start_time}
                                        </h5>
                                      </label>
                                    </div>
                                  </div>
                                </>
                              )
                            )} */}
                            {radiologyTestCenterSlotsList &&
                              radiologyTestCenterSlotsList?.slots?.morning?.map((item, index) => {
                                const slotTime = moment(item.start_time, 'hh:mm A');
                                if (slotTime.isAfter(moment(formattedTime, 'hh:mm A'))) {
                                  return (
                                    <div className="consultation_cancel_modal consulatation-time" key={index}>
                                      <div
                                        className={`time_slot_card${morning === index ? " time_slot_card_active" : ""}`}
                                      >
                                        <label>
                                          <input
                                            type="radio"
                                            name="appointmentDetails"
                                            onClick={() => {
                                              handleCardClicks("morning", index); props?.formik?.setFieldValue(
                                                "slot_id",
                                                item?.id
                                              );
                                              props?.formik?.setFieldValue(
                                                "start_time",
                                                item.start_time
                                              );
                                            }}
                                            style={{ accentColor: "#199a8e" }}
                                          />
                                          <h5 className="time_slot_card_text">
                                            {item.start_time}
                                          </h5>
                                        </label>
                                      </div>
                                    </div>
                                  );
                                }
                                return null;
                              })
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              )}
              {radiologyTestCenterSlotsList?.slots?.afternoon.length > 0 && (
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    Afternoon{" "}
                    <span className="time_avilable_subtitle">
                      {" "}
                      {radiologyTestCenterSlotsList?.slots?.afternoon
                      ? radiologyTestCenterSlotsList.slots.afternoon.reduce(
                        (count, item) => {
                          const slotTime = moment(item.start_time, "hh:mm A");
                          if (slotTime.isAfter(moment(formattedTime, "hh:mm A"))) {
                            return count + 1;
                          }
                          return count;
                        },
                        0
                      )
                      : 0} Slots available
                    </span>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="time_slot_card_box">
                          {/* {radiologyTestCenterSlotsList?.slots?.afternoon?.map(
                            (item, index) => (
                              <>
                                <div className="consultation_cancel_modal consulatation-time">
                                  <div
                                    className={`time_slot_card  ${props?.formik?.values?.slot_id === item.id
                                        ? "time_slot_card_active"
                                        : null
                                      } `}
                                    key={index}
                                  >
                                    <label>
                                      <input
                                        type="radio"
                                        name="slot_id"
                                        value={item?.id}
                                        onChange={() => {
                                          props.formik.setFieldValue(
                                            "slot_id",
                                            item?.id
                                          );
                                          props?.formik?.setFieldValue(
                                            "start_time",
                                            item.start_time
                                          );
                                          setresheduleDetail(item);
                                        }}
                                        onBlur={props?.formik?.handleBlur}
                                        checked={
                                          props?.formik?.values?.slot_id ===
                                          item?.title
                                        }
                                        style={{ accentColor: "#199a8e" }}
                                      />
                                      <h5 className="time_slot_card_text time_slot_card_text_active">
                                        {item.start_time}
                                      </h5>
                                    </label>
                                  </div>
                                </div>
                              </>
                            )
                          )} */}
                             {radiologyTestCenterSlotsList &&
                            radiologyTestCenterSlotsList?.slots?.afternoon?.map((item, index) => {
                              const slotTime = moment(item.start_time, 'hh:mm A');
                              if (slotTime.isAfter(moment(formattedTime, 'hh:mm A'))) {
                                return (
                                  <div className="consultation_cancel_modal consulatation-time" key={index}>
                                    <div
                                      className={`time_slot_card${afternoon === index ? " time_slot_card_active" : ""}`}
                                    >
                                      <label>
                                        <input
                                          type="radio"
                                          name="appointmentDetails"
                                          onClick={() => { handleCardClicks("afternoon", index);    props.formik.setFieldValue(
                                            "slot_id",
                                            item?.id
                                          );
                                          props?.formik?.setFieldValue(
                                            "start_time",
                                            item.start_time
                                          ); }}
                                          style={{accentColor: "#199a8e"}}
                                        />
                                        <h5 className="time_slot_card_text">
                                          {item.start_time}
                                        </h5>
                                      </label>
                                    </div>
                                  </div>
                                );
                              }
                              return null;
                            })
                          }
                        </div>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              )}

              {radiologyTestCenterSlotsList?.slots?.evening.length > 0 && (
                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    Evening
                    <span className="time_avilable_subtitle">
                    {radiologyTestCenterSlotsList?.slots?.evening
                      ? radiologyTestCenterSlotsList.slots.evening.reduce(
                        (count, item) => {
                          const slotTime = moment(item.start_time, "hh:mm A");
                          if (slotTime.isAfter(moment(formattedTime, "hh:mm A"))) {
                            return count + 1;
                          }
                          return count;
                        },
                        0
                      )
                      : 0} Slots available
                    </span>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="time_slot_card_box">
                      {/* {radiologyTestCenterSlotsList?.slots?.evening?.map(
                        (item, index) => (
                          <>
                            <div className="consultation_cancel_modal consulatation-time">
                              <div
                                className={`time_slot_card  ${props?.formik?.values?.slot_id === item.id
                                    ? "time_slot_card_active"
                                    : null
                                  } `}
                                key={index}
                              >
                                <label>
                                  <input
                                    type="radio"
                                    name="slot_id"
                                    value={item?.id}
                                    onChange={() => {
                                      props.formik.setFieldValue(
                                        "slot_id",
                                        item?.id
                                      );
                                      props?.formik?.setFieldValue(
                                        "start_time",
                                        item.start_time
                                      );
                                      setresheduleDetail(item);
                                    }}
                                    onBlur={props?.formik?.handleBlur}
                                    checked={
                                      props?.formik?.values?.slot_id ===
                                      item?.id
                                    }
                                    style={{ accentColor: "#199a8e" }}
                                  />
                                  <h5 className="time_slot_card_text time_slot_card_text_active">
                                    {item.start_time}
                                  </h5>
                                </label>
                              </div>
                            </div>
                          </>
                        )
                      )} */}
                          {radiologyTestCenterSlotsList &&
                            radiologyTestCenterSlotsList?.slots?.evening?.map((item, index) => {
                              const slotTime = moment(item.start_time, 'hh:mm A');
                              if (slotTime.isAfter(moment(formattedTime, 'hh:mm A'))) {
                                return (
                                  <div className="consultation_cancel_modal consulatation-time" key={index}>
                                    <div
                                      className={`time_slot_card${evening === index ? " time_slot_card_active" : ""}`}
                                    >
                                      <label>
                                        <input
                                          type="radio"
                                          name="appointmentDetails"
                                          onClick={() => { handleCardClicks("evening", index);  props.formik.setFieldValue(
                                            "slot_id",
                                            item?.id
                                          );
                                          props?.formik?.setFieldValue(
                                            "start_time",
                                            item.start_time
                                          ); }}
                                          style={{accentColor: "#199a8e"}}
                                        />
                                        <h5 className="time_slot_card_text">
                                          {item.start_time}
                                        </h5>
                                      </label>
                                    </div>
                                  </div>
                                );
                              }
                              return null;
                            })
                          }

                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              )}
              {radiologyTestCenterSlotsList?.slots?.night.length > 0 && (
                <Accordion.Item eventKey="3">
                  <Accordion.Header>
                    Night
                    <span className="time_avilable_subtitle">
                    {radiologyTestCenterSlotsList?.slots?.night
                      ? radiologyTestCenterSlotsList.slots.night.reduce(
                        (count, item) => {
                          const slotTime = moment(item.start_time, "hh:mm A");
                          if (slotTime.isAfter(moment(formattedTime, "hh:mm A"))) {
                            return count + 1;
                          }
                          return count;
                        },
                        0
                      )
                      : 0} Slots available
                    </span>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="time_slot_card_box">
                      {/* {radiologyTestCenterSlotsList?.slots?.night?.map(
                        (item, index) => (
                          <>
                            <div className="consultation_cancel_modal consulatation-time">
                              <div
                                className={`time_slot_card  ${props?.formik?.values?.slot_id === item.id
                                    ? "time_slot_card_active"
                                    : null
                                  } `}
                                key={index}
                              >
                                <label>
                                  <input
                                    type="radio"
                                    name="slot_id"
                                    value={item?.id}
                                    onChange={() => {
                                      props.formik.setFieldValue(
                                        "slot_id",
                                        item?.id
                                      );
                                      props?.formik?.setFieldValue(
                                        "start_time",
                                        item.start_time
                                      );
                                      setresheduleDetail(item);
                                    }}
                                    onBlur={props?.formik?.handleBlur}
                                    checked={
                                      props?.formik?.values?.slot_id ===
                                      item?.id
                                    }
                                    style={{ accentColor: "#199a8e" }}
                                  />
                                  <h5 className="time_slot_card_text time_slot_card_text_active">
                                    {item.start_time}
                                  </h5>
                                </label>
                              </div>
                            </div>
                          </>
                        )
                      )} */}
                           {radiologyTestCenterSlotsList &&
                            radiologyTestCenterSlotsList?.slots?.night?.map((item, index) => {
                              const slotTime = moment(item.start_time, 'hh:mm A');
                              if (slotTime.isAfter(moment(formattedTime, 'hh:mm A'))) {
                                return (
                                  <div className="consultation_cancel_modal consulatation-time" key={index}>
                                    <div
                                      className={`time_slot_card${night === index ? " time_slot_card_active" : ""}`}
                                    >
                                      <label>
                                        <input
                                          type="radio"
                                          name="appointmentDetails"
                                          onClick={() => { handleCardClicks("night", index);  props.formik.setFieldValue(
                                            "slot_id",
                                            item?.id
                                          );
                                          props?.formik?.setFieldValue(
                                            "start_time",
                                            item.start_time
                                          ); }}
                                          style={{accentColor: "#199a8e"}}
                                        />
                                        <h5 className="time_slot_card_text">
                                          {item.start_time}
                                        </h5>
                                      </label>
                                    </div>
                                  </div>
                                );
                              }
                              return null; // If slot is before current time, return null to skip rendering
                            })
                          }
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              )}
            </Accordion>
            <center>
              {radiologyTestCenterDetailsById?.center_id ? (
                <Button className="time_slot_btn" onClick={() => onReshedule()}>
                  Reshedule
                </Button>
              ) : (
                <Button
                  type="submit"
                  className="time_slot_btn"
                  onClick={() => props.onHide()}
                >
                  Done
                </Button>
              )}
            </center>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default RadiologicalTestSlotModel;
