import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import "../../../../Assets/css/responsive.css";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import Pagination from "../../../Common/Pagination/Pagination";
import { GetMySubscribedPlansList } from "../../../../Store/Reducer/ProfileReducer";
import { useDispatch, useSelector } from "react-redux";
import { SubscribedPlansList } from "../../../../Store/Reducer/BookingSlice";

function MySubscriptions() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { subscribedPlansList } = useSelector(({ ProfileSlice }) => ProfileSlice);
  const [currentPage, setCurrentPage] = useState(0); // Current page index
  const itemsPerPage = 10; // Number of bookings to display per page

  const handlePageChange = (selected) => {
    setCurrentPage(selected); // Update the current page index
  };

  const subscribedPlansLists = subscribedPlansList.hasError
    ? []
    : subscribedPlansList?.slice(
      currentPage * itemsPerPage,
      (currentPage + 1) * itemsPerPage
    );

  const id = localStorage.getItem("specialityId")
  useEffect(() => {
    dispatch(SubscribedPlansList(id));
  }, [dispatch])

  return (
    <>

      <Container>

        <div className="row">
          <div className="col-xl-12 col-md-8">
            <div className="my_subscription_body_box_card_head">
              <div className="row">
                <div className="col-md-6 col-6">
                  <h3 className="my_subscription_body_card_head_title">
                    My Subscription
                  </h3>
                </div>
                {/* <div className="col-md-6 col-6">
                  <h5 className="address_body_card_head_new_add">
                    <img
                      src={Icon.add}
                      className="address_body_card_head_add_icon"
                    ></img>
                    Add New Address
                  </h5>
                </div> */}
              </div>
            </div>
            <div className="my_subscription_body_box_card_body">
              <div className="row">
                <div className="col-md-12">
                  {subscribedPlansLists?.length > 0 ? (
                    <>
                      {subscribedPlansLists?.map((userPlans, index) => {
                        return (
                          <>
                            <div className="my_subscription_box_card mb_25" onClick={() => { navigate(`/subscriptionDetail/${userPlans?.package_id}`); }}>
                           
                              <div className="my_subscription_card_inner">
                                <div>
                                  <h3 className="my_subscription_card_title">
                                    {userPlans.plan_details.patient_limit} Consultations
                                  </h3>
                                  <h3 className="my_subscription_card_desc">
                                    You will get video and chat consultation
                                  </h3>
                                  <h3 className="my_subscription_card_member">
                                    {userPlans.added_members} Members added
                                  </h3>
                                </div>
                                <div>
                                  <h3 className="my_subscription_card_price">₹ {userPlans.plan_details.price}</h3>
                                  <h3 className="my_subscription_card_consultation">
                                    {userPlans.left_consultations} Consultaions Left
                                  </h3>
                                  <h3 className="my_subscription_card_validation">
                                    Valid till {userPlans.expire_date}
                                  </h3>
                                </div>
                              </div>
                            </div>
                          </>
                        )
                      })}
                      {
                        subscribedPlansLists?.length > 10 && (
                          <Pagination
                            totalItems={subscribedPlansList.length}
                            itemsPerPage={itemsPerPage}
                            onPageChange={handlePageChange}
                          />
                        )
                      }

                    </>
                  ) : (
                    <div>No Subscription Found</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
}

export default MySubscriptions;
