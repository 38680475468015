
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
    addToCart,
    clearCartItem,
    LabtestLabs,
    removeItem,
} from "../../../Store/Reducer/LabtestSlice";
import { Icon } from "../../../Utilities/Icons";
import Checkbox from "../Checkbox";
import { HealthList } from "../../../Routes/Service";
import { clearRadiologyCartItem } from "../../../Store/Reducer/RadiologySlice";

const InquiryListmodal = (props) => {

    const { labTestList, labCart, labTestCenterDetailsById } = useSelector(
        ({ LabtestSlice }) => LabtestSlice
    );
    const { healthListData } = useSelector(
        ({ RadiologySlice }) => RadiologySlice
    );


    const dispatch = useDispatch();

    const [inputText, setInputText] = useState("");

    let inputHandler = (e) => {
        //convert input text to lower case
        var lowerCase = e.target.value.toLowerCase(); //.toLowerCase();
        setInputText(lowerCase);
    };


    const filteredData = healthListData?.filter((el) => {
        //if no input the return the original
        if (inputText === "") {
            return el;
        }
        //return the item which contains the user input
        else {
            return el.test_name?.toLowerCase().includes(inputText);
        }
    });
    const getTotal = () => {
        let totalQuantity = 0;
        let totalPrice = 0;
        labCart?.forEach((item) => {
            totalPrice += item?.amount * item?.quantity;
        });
        return { totalPrice, totalQuantity };
    };

    let test_name = labCart?.map(function (i) {
        return i?.id;
    });
    useEffect(() => {
        setIsCheck(test_name)
      }, [ labCart])

    const [isCheck, setIsCheck] = useState(test_name);

    const handleClick = (e) => {
        const { id, checked } = e.target;
        const numberId = Number(id);
        setIsCheck([...isCheck, numberId]);

        props?.formik?.setFieldValue("service", setIsCheck)
        filteredData?.filter(function (item) {
            if (numberId == item?.id) {
                dispatch(addToCart(item));
            }
        });

        if (!checked) {
            filteredData?.filter(function (item) {
                if (numberId == item?.id) {
                    dispatch(removeItem(item?.id));
                }
            });
            setIsCheck(isCheck.filter((item) => item !== numberId));
        }
    };

    const { session } = useSelector(({ AuthSlice }) => AuthSlice);

const name = localStorage.getItem("centername")

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            dialogClassName="lab_test_modal_box"
            backdrop="static"
        >
            <Modal.Header
                className="lab_test_modal_box_head_padding"
                style={{ marginBottom: "none", border: "none" }}
            >
                <h5 className="lab_test_popup_title">{name && name}</h5>
                <img
                    src={Icon.cross}
                    className="lab_test_popup_close"
                    onClick={() => {
                        props.onHide();
                    }}
                ></img>
            </Modal.Header>
            <Modal.Body className="">
                <form>
                    <div className="lab_test_modal_box_inner_1">
                        <div className="row">
                            <div className="col-md-12">
                                <img
                                    src={Icon.search}
                                    className="select_city_search_icon"
                                ></img>
                                <input
                                    type="search"
                                    className="health_package_input_search"
                                    placeholder="Search"
                                    onChange={inputHandler}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="lab_test_popup_box">
                        <div className="lab_test_modal_box_inner_2">
                            {filteredData?.length > 0 &&
                                filteredData?.map((item, index) => (
                                    <>
                                        <div className="healthpackage_checkbox_box" key={index}>
                                            <div className="healthpackage">
                                                <Checkbox
                                                    key={item?.id}
                                                    type="checkbox"
                                                    name={item?.id}
                                                    id={item?.id}
                                                    handleClick={handleClick}
                                                    isChecked={isCheck?.includes(item?.id)}
                                                />
                                                <label
                                                    className="healthpackage_checkbox_title"
                                                    for={item?.id}
                                                    style={{
                                                        display: "inline-flex",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    {item?.name}
                                                </label>
                                            </div>
                                            <div>
                                                <h5 className="healthpackage_checkbox_value">
                                                    ₹ {item?.amount}
                                                </h5>
                                            </div>
                                        </div>
                                        <hr className="health_package_lab_text_hr" />
                                    </>
                                ))}
                            {filteredData?.length == 0 && (
                                <>
                                    <h6> No Health Tests Found</h6>
                                </>
                            )}
                        </div>
                    </div>
                </form>
            </Modal.Body>
            <Modal.Footer className="healthpackage_modal_footer_box">
                <div className="lab_test_modal_footer_inner">
                    <div className="">
                        <h5 className="lab_test_modal_footer_subtitle">Total amount</h5>
                        <h3 className="lab_test_modal_footer_title">
                            ₹ {isNaN(getTotal().totalPrice) ? 0 : Math.round(getTotal().totalPrice)}
                        </h3>

                    </div>
                    <div className="">
                        {
                            (getTotal().totalPrice) > 0 ? <Button
                                className="lab_test_modal_footer_btn"
                                type="submit"
                                onClick={() => {
                                    props.onNext();
                                }}
                            >
                                Next
                            </Button> : ""
                        }

                    </div>
                </div>
            </Modal.Footer>
        </Modal>
    );
};

export default InquiryListmodal;

