import { useFormik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
    PreceptionList,
    RadiologyPastBooking,
    setRadiologyTestCenterDetailsById,
} from "../../../../Store/Reducer/RadiologySlice";
import { Icon } from "../../../../Utilities/Icons";
import CancelationReasonModel from "../../../Common/CancelationReasonModel/CancelationReasonModel";
import RadiologicalTestSlotModel from "../../../Common/RadiolodicalModel/RadiologicalTestSlotModel";
import Pagination from "../../../Common/Pagination/Pagination";
import Arrow from '../../../../Assets/img/svg/Frame.svg'
import Download from '../../../../Assets/img/svg/Group 8654.svg'
import Share from '../../../../Assets/img/svg/Group 8655.svg'
import jsPDF from "jspdf";
import { BookingDetail } from "../../../../Store/Reducer/BookingSlice";
import { AiOutlineEye } from 'react-icons/ai'
import PresciptionDetails from "../../../Common/BookingSuccessModel/PresciptionDetails";
import img from '../../../../Assets/img/png/Screenshot 2023-09-18 173307.png'

const Preception = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [timeSample, settimeSample] = useState(false);
    const [currentPage, setCurrentPage] = useState(0); // Current page index
    const itemsPerPage = 5; // Number of bookings to display per page

    const handlePageChange = (selected) => {
        setCurrentPage(selected); // Update the current page index
    };


    const {
        preceptionList,
    } = useSelector(({ RadiologySlice }) => RadiologySlice);
    useEffect(() => {
        dispatch(PreceptionList());
    }, [dispatch]);

    const handleSubmit = async () => {
        const doc = new jsPDF();

        if (preceptionList?.pdf_url) {
            try {
                const response = await fetch(preceptionList?.pdf_url);
                const blob = await response.blob();

                const img = new Image();
                img.src = URL.createObjectURL(blob);
                img.onload = () => {
                    const canvas = document.createElement("canvas");
                    canvas.width = img.width;
                    canvas.height = img.height;
                    const ctx = canvas.getContext("2d");
                    ctx.drawImage(img, 0, 0);

                    canvas.toBlob(async (canvasBlob) => {
                        const imgDataUrl = URL.createObjectURL(canvasBlob);

                        // Add image to the PDF
                        doc.addImage(imgDataUrl, "JPEG", 10, 40, 50, 50);
                        doc.save("formData.pdf");

                        // Clean up the object URLs
                        URL.revokeObjectURL(img.src);
                        URL.revokeObjectURL(imgDataUrl);
                    }, "image/jpeg");
                };
            } catch (error) {
              
            }
        } else {
            doc.save("formData.pdf");
        }
    };
    const [presciptionDetailModel, setPresciptionDetailModel] = useState(false);




    return (
        <>
            <PresciptionDetails
                show={presciptionDetailModel}
                onHide={() => setPresciptionDetailModel(false)}
            />

            <div>
                <div className="row">
                    <div className="col-xl-12 col-md-8">
                        <div className="my_test_body_box_card_head">
                            <div className="row">
                                <div className="col-md-6">
                                    <h3 className="my_test_body_card_head_title">
                                        My Prescription
                                    </h3>
                                </div>
                                <div className="col-md-6"></div>
                            </div>
                        </div>
                        <div className="my_test_body_box_card_body">
                            <div className="row">
                                <div className="col-md-12">

                                    <div className="card-containers">
                                        {
                                            preceptionList && preceptionList.map((preceptionList, index) => {
                                                return (
                                                    <>
                                                        <div className="mainprecptiondiv">
                                                            <div className="prec">
                                                                <div className="precption_div">
                                                                    <div>
                                                                        <img src={preceptionList?.doctor_details?.image} style={{ height: "50px", width: "50px" }}></img>
                                                                    </div>
                                                                    <div>
                                                                        <h2 className="prectexts">{preceptionList?.doctor_details?.name}  <span className="spantext">Orthopedist</span></h2>
                                                                        <p className="precdoctor">for {preceptionList?.patient_details?.name}</p>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <img src={Arrow}
                                                                        onClick={() => {
                                                                            navigate(
                                                                                `/consultation-details/${preceptionList?.id}`
                                                                            );
                                                                            dispatch(BookingDetail(preceptionList));
                                                                        }}></img>
                                                                </div>
                                                            </div>
                                                            <hr />
                                                            <div className="precssecond">
                                                                <div className="d-flex justify-content-center">
                                                                    <img src={img} style={{ height: "140px", filter: "blur(0.8px)" }}></img>
                                                                </div>

                                                           
                                                                <div className="imagebutton">
                                                                    <img src={Download} onClick={() => { handleSubmit() }}></img>
                                                                    {/* <img src={AiOutlineEye}></img> */}

                                                                    <div className="eyesback" >
                                                                        <div className="" onClick={() => {
                                                                            setPresciptionDetailModel(true);
                                                                            dispatch(BookingDetail(preceptionList));
                                                                        
                                                                        }} > <AiOutlineEye /></div>
                                                                    </div>


                                                                </div>
                                                            </div>


                                                        </div>

                                                    </>
                                                )
                                            })

                                        }

                                    </div>
                                    <div className="pagination">
                                        {
                                            preceptionList?.length > 10 && (
                                                <Pagination
                                                    itemsPerPage={itemsPerPage}
                                                />
                                            )
                                        }
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Preception;
