import React from "react";
import { useEffect, useState } from "react";
import { BackGround, Icon, Logo } from "../../../Utilities/Icons";
import OwlCarousel from "react-owl-carousel";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import { Field, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { SelectEnum, SignUpEnum } from "../../../Utilities/Enums";
import {
  EditUserProfile,
  GetHeightList,
  GetWeightList,
} from "../../../Store/Reducer/ProfileReducer";
import FormControl from "../../Common/Forms/FormControl";
import { SelectSchema, SignUpSchema } from "../../../Utilities/Schema";
import { GetCity } from "../../../Store/Reducer/CommonSlice";
import CustomSelect from "../../Common/MemberModel/CustomSelect";
import { FaCamera } from "react-icons/fa";

function splitUTMParameters(url) {
  const utmSourceMatch = url?.match(/[?&]utm_source=([^&]+)/);
  const utmMediumMatch = url?.match(/[?&]utm_medium=([^&]+)/);
  const utmCampaignMatch = url?.match(/[?&]utm_campaign=([^&]+)/);

  const utmSource = utmSourceMatch ? utmSourceMatch[1] : null;
  const utmMedium = utmMediumMatch ? utmMediumMatch[1] : null;
  const utmCampaign = utmCampaignMatch ? utmCampaignMatch[1] : null;

  // Create an object to store the UTM parameters
  const utmParameters = {
    utm_source: utmSource,
    utm_medium: utmMedium,
    utm_campaign: utmCampaign,
  };

  return utmParameters;
}


function SignupDetails() {
  // const [selectedOption, setSelectedOption] = useState(null);
  const { medicalHistory } = useSelector(({ BookingSlice }) => BookingSlice);

  const { paramas } = useSelector(
    ({ RadiologySlice }) => RadiologySlice
  );



  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [verify, setverify] = useState("");
  const [img, setImg] = useState();
  const [selectedImage, setSelectedImage] = useState(null);

  const imgHandler = (event) => {
    const selectedFile = event.target.files[0];
    setImg(event.target.files[0])
    if (selectedFile) {
      setSelectedImage(URL.createObjectURL(selectedFile));
    }
  };
  const [yesNo, setYesNo] = React.useState("No");
  function onChangeValue(event) {
    setYesNo(event.target.value);
  }

  const { city } = useSelector(({ CommonSlice }) => CommonSlice);
  // const { session } = useSelector(({ AuthSlice }) => AuthSlice);
  const { userHeightList, userWeightList } = useSelector(
    ({ ProfileSlice }) => ProfileSlice
  );
  useEffect(() => {
    dispatch(GetCity());
    dispatch(GetHeightList());
    dispatch(GetWeightList());
  }, [dispatch]);
  const [inputText, setInputText] = useState("");

  let inputHandler = (e) => {
    //convert input text to lower case
    var lowerCase = e.target.value.toLowerCase(); //.toLowerCase();
    setInputText(lowerCase);
  };
  const currentDate = new Date().toISOString().split('T')[0];
  const filteredData =
    city?.length &&
    city?.filter((el) => {
      //if no input the return the original
      if (inputText === "") {
        return el;
      }
      //return the item which contains the user input
      else {
        return el?.label?.toLowerCase().includes(inputText);
      }
    });

  // Determine which set of parameters to use based on 'paramas' payload







  const urls = localStorage.getItem("location");
  console.log("urlllll", urls);


  // Split the UTM parameters
  const utmParams = splitUTMParameters(urls);

  // Log the UTM parameters to the console

  const source = localStorage.getItem("utm_source")
  const medium = localStorage.getItem("utm_medium")
  const campaign = localStorage.getItem("utm_campaign")


  return (
    <>
      <div className="section_1_bg">
        <div className="section_1_container">
          <div className="sub_section_1 js-fullheight">
            <div className="row">
              <div className="col-md-12">
                <div className="display_t">
                  <center>
                    <img src={Logo.THS} className="logo_box" alt=""></img>
                  </center>
                  <div className="slider_1">
                    <OwlCarousel
                      className="owl-theme"
                      loop
                      margin={10}
                      items={1}
                    >
                      <div className="item">
                        <center>
                          <img
                            src={BackGround.owlsliderbanner}
                            className="owl_banner_img_box"
                            alt=""
                          ></img>
                          <h3 className="slider_text">
                            Book an online appointment with the <br /> best
                            doctors with in 60 secs.
                          </h3>
                        </center>
                      </div>
                      <div className="item">
                        <center>
                          <img
                            src={BackGround.owlsliderbanner}
                            className="owl_banner_img_box"
                            alt=""
                          ></img>
                          <h3 className="slider_text">
                            Book an online appointment with the <br /> best
                            doctors with in 60 secs.
                          </h3>
                        </center>
                      </div>
                      <div className="item">
                        <center>
                          <img
                            src={BackGround.owlsliderbanner}
                            className="owl_banner_img_box"
                            alt=""
                          ></img>
                          <h3 className="slider_text">
                            Book an online appointment with the <br /> best
                            doctors with in 60 secs.
                          </h3>
                        </center>
                      </div>
                    </OwlCarousel>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {!verify ? (
            <>
              <div className="sub_section_2">
                <div className="row">
                  <div className="col-md-12">
                    <div className="display_t js-fullheight">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="signup_details_form_box">
                            <div className="signup_card">
                              <h3 className="signup_details_head_title">
                                Sign up Details
                              </h3>
                              <h5 className="signup_details_head_subtitle">
                                Please provide some details so we can improve
                                you app experience
                              </h5>
                              <div className="signup_details_box">
                                <Formik
                                  initialValues={SignUpEnum}
                                  validationSchema={SignUpSchema}
                                  onSubmit={(values) => {
                                    const combinedValues = {
                                      ...values,
                                      image: img,// Replace 'imageFile' with the actual image data
                                      utm_source: source,
                                      utm_campaign: medium,
                                      utm_medium: campaign



                                    };
                                    dispatch(EditUserProfile(combinedValues)).then(
                                      (res) => {
                                        if (res.payload.success === true) {
                                          localStorage.removeItem('utm_source');
                                          localStorage.removeItem('utm_medium');
                                          localStorage.removeItem('utm_campaign');


                                          setverify(values.first_name);
                                        }
                                      }
                                    );
                                  }}
                                >
                                  {({
                                    values,
                                    handleBlur,
                                    handleChange,
                                    handleSubmit,
                                    setFieldValue,
                                    errors,
                                    touched,
                                  }) => (
                                    <Form onSubmit={handleSubmit}>
                                      <div className="imgupload">
                                        <label className="uploadlabel">
                                          {!selectedImage ? (
                                            <>
                                              <FaCamera className="text-primary text-[20px]" />
                                              <span className="mt_2" style={{ fontWeight: "bold" }}>
                                                add
                                              </span>
                                              <input type="file" className="hidden" name="img" onChange={imgHandler} />
                                            </>
                                          ) : (
                                            <div className="image-preview">
                                              <img src={selectedImage} alt="Selected" style={{ height: "100px", width: "100px", borderRadius: "50%" }} />
                                            </div>
                                          )}
                                        </label>
                                      </div>
                                      <h2 className="uploadtext">Upload profile image</h2>
                                      <h3 className="signup_details_title">
                                        Personal Info
                                      </h3>
                                      <div className="personal_box ">
                                        <div className="row">
                                          <div className="col-md-12" >
                                            <FormControl
                                              control="input"
                                              type="text"
                                              name="first_name"
                                              className="personal_input"
                                              placeholder="First Name"
                                              value={values.first_name}
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                              maxLength="32"
                                            />
                                            <FormControl
                                              control="input"
                                              type="text"
                                              name="last_name"
                                              className="personal_input_1"
                                              placeholder="Last Name"
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                              value={values.last_name}
                                              maxLength="32"
                                            />
                                            <FormControl
                                              control="input"
                                              type="email"
                                              name="email"
                                              id="email"
                                              placeholder="Email ID"
                                              className="personal_input_2"
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                              value={values.email}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-md-12">
                                          <label className="question mb_10 mt_15"> Date of birth</label>
                                          <FormControl
                                            control="input"
                                            type="date"
                                            name="dob"
                                            id="dob"
                                            placeholder="Select Date of birth"
                                            className="dob_input"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={
                                              values.dob ||
                                              "Select Date of birth"
                                            }
                                            max={currentDate}
                                          />
                                          <FormControl
                                            control="input"
                                            type="text"
                                            name="age"
                                            className="dob_input_2"
                                            id="age"
                                            placeholder="Age"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={
                                              (values.age =
                                                Math.floor(
                                                  (new Date() -
                                                    new Date(
                                                      values.dob
                                                    ).getTime()) /
                                                  3.15576e10
                                                ) || "")
                                            }
                                          />
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-md-12">
                                          <label className="question mb_10 mt_10">Referral Code (optional)</label>
                                          <FormControl
                                            control="input"
                                            type="text"
                                            name="referral"
                                            id="dob2"
                                            placeholder="Referral Code (optional)"
                                            className="dob_input"
                                            value={values.referral}
                                            onChange={handleChange}
                                          />
                                        </div>
                                      </div>
                                      <div className="d-flex col-12 pt_15" style={{ gap: "10px" }}>
                                        <div className="col-md-6">
                                          <div className="input_box">
                                            <label className="question mb_10 mt_10"> Height </label>
                                            <div className="form_group">
                                              <Form.Select
                                                aria-label="Default select example"
                                                name="height"
                                                value={values.height}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className="add_member_input"
                                              >
                                                <option>Select Height </option>
                                                {userHeightList?.length &&
                                                  userHeightList?.map((item) => (
                                                    <option value={item?.name}>
                                                      {item?.name}
                                                    </option>
                                                  ))}
                                              </Form.Select>
                                              {errors.height &&
                                                touched.height && (
                                                  <div className="error_text " style={{ paddingTop: "8px" }}>
                                                    {errors.height}
                                                  </div>
                                                )}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="input_box">
                                            <label className="question mb_10 mt_10">   Weight</label>
                                            <div className="form_group">
                                              <Form.Select
                                                aria-label="Default select example"
                                                name="weight"
                                                value={values.weight}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className="add_member_input"
                                              >
                                                <option>Select Weight </option>
                                                {userWeightList?.length &&
                                                  userWeightList?.map((item) => (
                                                    <option value={item?.name}>
                                                      {item?.name}
                                                    </option>
                                                  ))}
                                              </Form.Select>
                                              {errors.weight &&
                                                touched.weight && (
                                                  <div className="error_text" style={{ paddingTop: "8px" }}>
                                                    {errors.weight}
                                                  </div>
                                                )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-12 mb_25">
                                        <label className="question mb_10 mt_10"> Blood Group</label>
                                        <Field
                                          className="custom-select "
                                          name="blood_group"
                                          options={[
                                            { label: "A+", value: "A+" },
                                            { label: "A-", value: "A-" },
                                            { label: "B+", value: "B+" },
                                            { label: "B-", value: "B-" },
                                            { label: "AB+", value: "AB+" },
                                            { label: "AB-", value: "AB-" },
                                            { label: "o+", value: "o+" },
                                            { label: "o-", value: "o-" },
                                          ]}
                                          component={CustomSelect}
                                          placeholder="Select Blood Group..."
                                          isMulti={false}
                                          onBlur={handleBlur}
                                        />
                                        {errors.blood_group &&
                                          touched.blood_group && (
                                            <div className="error_text">
                                              {errors.blood_group}
                                            </div>
                                          )}
                                      </div>
                                      <h3 className="signup_details_title">
                                        Gender
                                      </h3>
                                      <div className="row">
                                        <div className="col-md-12">
                                          <div className="radio_box">
                                            <div className="gender_option" onClick={() => toggleGender("male", values, setFieldValue)}>
                                              <center>
                                                <label
                                                  className=""
                                                  htmlFor={"male"}
                                                >

                                                  {values?.gender == "male" ? (
                                                    <center>
                                                      <label className="gender_option gender_active">
                                                        <svg
                                                          className="gender_icon"
                                                          xmlns="http://www.w3.org/2000/svg"
                                                          x="0"
                                                          y="0"
                                                          viewBox="0 0 1000 1000"
                                                        >
                                                          <path
                                                            fill="#fff"
                                                            d="M949.4 9.9H622.9c-22.5 0-40.7 18.3-40.7 40.7 0 22.5 18.2 40.8 40.7 40.8H851L570 372.3c-4.4 4.4-7.3 9.5-9.2 14.9-60.1-48.3-133.3-76-211.5-76-90.7 0-175.9 35.3-240.1 99.3-132.3 132.4-132.3 347.6 0 480.1 64.1 64.1 149.3 99.4 240 99.4 90.7 0 175.9-35.4 240-99.4C712.6 767 719.3 572.2 612.5 439c5.5-1.9 10.6-4.9 15.1-9.3l280.9-281V377c0 22.5 18.2 40.7 40.8 40.7 22.4 0 40.7-18.2 40.7-40.7V50.6c.1-22.4-18.2-40.7-40.6-40.7zM531.7 833.2c-97.5 97.5-267.4 97.5-364.8 0-100.6-100.6-100.6-264.3 0-364.9 48.7-48.7 113.5-75.5 182.5-75.5 68.8 0 133.7 26.7 182.3 75.5 100.5 100.6 100.5 264.3 0 364.9z"
                                                          ></path>
                                                        </svg>
                                                        <span className="gender_option_active_title">
                                                          Male
                                                        </span>
                                                      </label>
                                                    </center>
                                                  ) : (
                                                    <svg
                                                      className="gender_icon"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      x="0"
                                                      y="0"
                                                      viewBox="0 0 1000 1000"
                                                    >
                                                      <path
                                                        fill="#909196"
                                                        d="M949.4 9.9H622.9c-22.5 0-40.7 18.3-40.7 40.7 0 22.5 18.2 40.8 40.7 40.8H851L570 372.3c-4.4 4.4-7.3 9.5-9.2 14.9-60.1-48.3-133.3-76-211.5-76-90.7 0-175.9 35.3-240.1 99.3-132.3 132.4-132.3 347.6 0 480.1 64.1 64.1 149.3 99.4 240 99.4 90.7 0 175.9-35.4 240-99.4C712.6 767 719.3 572.2 612.5 439c5.5-1.9 10.6-4.9 15.1-9.3l280.9-281V377c0 22.5 18.2 40.7 40.8 40.7 22.4 0 40.7-18.2 40.7-40.7V50.6c.1-22.4-18.2-40.7-40.6-40.7zM531.7 833.2c-97.5 97.5-267.4 97.5-364.8 0-100.6-100.6-100.6-264.3 0-364.9 48.7-48.7 113.5-75.5 182.5-75.5 68.8 0 133.7 26.7 182.3 75.5 100.5 100.6 100.5 264.3 0 364.9z"
                                                      ></path>
                                                    </svg>
                                                  )}
                                                </label>
                                                <span className="gender_option_title">
                                                  Male
                                                </span>
                                                <FormControl
                                                  control="input"
                                                  type="radio"
                                                  name="gender"
                                                  onChange={(e) =>
                                                    setFieldValue(
                                                      "gender",
                                                      e.target.value
                                                    )
                                                  }
                                                  id="male"
                                                  value="male"
                                                  checked={
                                                    values?.gender == "male"
                                                  }
                                                  placeholder="Male"
                                                  style={{ accentColor: "#199a8e" }}
                                                />
                                              </center>
                                            </div>
                                            <div className="gender_option" onClick={() => toggleGender("female", values, setFieldValue)}>
                                              <center>
                                                <label
                                                  className=""
                                                  htmlFor={"female"}
                                                >
                                                  {values?.gender ===
                                                    "female" ? (
                                                    <lable className="gender_option gender_active_2">
                                                      <center>
                                                        <svg
                                                          className="gender_icon"
                                                          xmlns="http://www.w3.org/2000/svg"
                                                          x="0"
                                                          y="0"
                                                          viewBox="0 0 1000 1000"
                                                        >
                                                          <path
                                                            fill="#fff"
                                                            d="M775.6 285.6C775.6 133.4 652.2 10 500 10c-152.2 0-275.6 123.4-275.6 275.6 0 140.5 105.3 256.3 241.2 273.2v103.7H356.1c-22.4 0-40.6 18.2-40.6 40.6 0 22.4 18.2 40.6 40.6 40.6h109.6v205.7c0 22.4 18.2 40.6 40.6 40.6 22.4 0 40.6-18.2 40.6-40.6V743.7h102.5c22.4 0 40.6-18.2 40.6-40.6 0-22.4-18.2-40.6-40.6-40.6H546.9V556.9c129.8-22.3 228.7-135.1 228.7-271.3zm-472.7-.1c0-108.9 88.2-197.1 197.1-197.1 108.9 0 197.1 88.3 197.1 197.1 0 108.9-88.2 197.1-197.1 197.1-108.9 0-197.1-88.2-197.1-197.1z"
                                                          ></path>
                                                        </svg>
                                                      </center>
                                                      <span className="gender_option_active_title">
                                                        Female
                                                      </span>
                                                    </lable>
                                                  ) : (
                                                    <svg
                                                      className="gender_icon"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      x="0"
                                                      y="0"
                                                      viewBox="0 0 1000 1000"
                                                    >
                                                      <path
                                                        fill="#909196"
                                                        d="M775.6 285.6C775.6 133.4 652.2 10 500 10c-152.2 0-275.6 123.4-275.6 275.6 0 140.5 105.3 256.3 241.2 273.2v103.7H356.1c-22.4 0-40.6 18.2-40.6 40.6 0 22.4 18.2 40.6 40.6 40.6h109.6v205.7c0 22.4 18.2 40.6 40.6 40.6 22.4 0 40.6-18.2 40.6-40.6V743.7h102.5c22.4 0 40.6-18.2 40.6-40.6 0-22.4-18.2-40.6-40.6-40.6H546.9V556.9c129.8-22.3 228.7-135.1 228.7-271.3zm-472.7-.1c0-108.9 88.2-197.1 197.1-197.1 108.9 0 197.1 88.3 197.1 197.1 0 108.9-88.2 197.1-197.1 197.1-108.9 0-197.1-88.2-197.1-197.1z"
                                                      ></path>
                                                    </svg>
                                                  )}
                                                </label>
                                                <span className="gender_option_title">
                                                  Female
                                                </span>
                                                <FormControl
                                                  control="input"
                                                  type="radio"
                                                  name="gender"
                                                  onChange={(e) =>
                                                    setFieldValue(
                                                      "gender",
                                                      e.target.value
                                                    )
                                                  }
                                                  id="female"
                                                  value="female"
                                                  checked={
                                                    values?.gender === "female"
                                                  }
                                                  placeholder=""
                                                  style={{ accentColor: "#199a8e" }}
                                                />
                                              </center>
                                            </div>
                                            <div className="gender_option" onClick={() => toggleGender("other", values, setFieldValue)}>
                                              <center>
                                                <label
                                                  className=""
                                                  htmlFor={"other"}
                                                >
                                                  {values?.gender ===
                                                    "other" ? (
                                                    <label className="gender_option gender_active_3">
                                                      <center>
                                                        <svg
                                                          className="gender_icon"
                                                          xmlns="http://www.w3.org/2000/svg"
                                                          x="0"
                                                          y="0"
                                                          viewBox="0 0 1000 1000"
                                                        >
                                                          <path
                                                            fill="#fff"
                                                            d="M949.4 9.9H622.9c-22.5 0-40.7 18.3-40.7 40.7 0 22.5 18.2 40.8 40.7 40.8H851L570 372.3c-4.4 4.4-7.3 9.5-9.2 14.9-60.1-48.3-133.3-76-211.5-76-90.7 0-175.9 35.3-240.1 99.3-132.3 132.4-132.3 347.6 0 480.1 64.1 64.1 149.3 99.4 240 99.4 90.7 0 175.9-35.4 240-99.4C712.6 767 719.3 572.2 612.5 439c5.5-1.9 10.6-4.9 15.1-9.3l280.9-281V377c0 22.5 18.2 40.7 40.8 40.7 22.4 0 40.7-18.2 40.7-40.7V50.6c.1-22.4-18.2-40.7-40.6-40.7zM531.7 833.2c-97.5 97.5-267.4 97.5-364.8 0-100.6-100.6-100.6-264.3 0-364.9 48.7-48.7 113.5-75.5 182.5-75.5 68.8 0 133.7 26.7 182.3 75.5 100.5 100.6 100.5 264.3 0 364.9z"
                                                          ></path>
                                                        </svg>
                                                      </center>
                                                      <span className="gender_option_active_title">
                                                        Other
                                                      </span>
                                                    </label>
                                                  ) : (
                                                    <svg
                                                      className="gender_icon"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      x="0"
                                                      y="0"
                                                      viewBox="0 0 1000 1000"
                                                    >
                                                      <path
                                                        fill="#909196"
                                                        d="M949.4 9.9H622.9c-22.5 0-40.7 18.3-40.7 40.7 0 22.5 18.2 40.8 40.7 40.8H851L570 372.3c-4.4 4.4-7.3 9.5-9.2 14.9-60.1-48.3-133.3-76-211.5-76-90.7 0-175.9 35.3-240.1 99.3-132.3 132.4-132.3 347.6 0 480.1 64.1 64.1 149.3 99.4 240 99.4 90.7 0 175.9-35.4 240-99.4C712.6 767 719.3 572.2 612.5 439c5.5-1.9 10.6-4.9 15.1-9.3l280.9-281V377c0 22.5 18.2 40.7 40.8 40.7 22.4 0 40.7-18.2 40.7-40.7V50.6c.1-22.4-18.2-40.7-40.6-40.7zM531.7 833.2c-97.5 97.5-267.4 97.5-364.8 0-100.6-100.6-100.6-264.3 0-364.9 48.7-48.7 113.5-75.5 182.5-75.5 68.8 0 133.7 26.7 182.3 75.5 100.5 100.6 100.5 264.3 0 364.9z"
                                                      ></path>
                                                    </svg>
                                                  )}
                                                </label>
                                                <span className="gender_option_title">
                                                  Other
                                                </span>
                                                <FormControl
                                                  control="input"
                                                  type="radio"
                                                  name="gender"
                                                  onChange={(e) =>
                                                    setFieldValue(
                                                      "gender",
                                                      e.target.value
                                                    )
                                                  }
                                                  id="other"
                                                  value="other"
                                                  checked={
                                                    values?.gender === "other"
                                                  }
                                                  placeholder=""
                                                  style={{ accentColor: "#199a8e" }}
                                                />
                                              </center>
                                            </div>
                                          </div>

                                        </div>
                                        {errors.gender &&
                                          touched.gender && (
                                            <div className="error_text" style={{ paddingTop: "8px" }}>
                                              {errors.gender}
                                            </div>
                                          )}
                                      </div>
                                      <div className="row">
                                        <div className="col-md-12">
                                          <h5 className="medical_history_title">
                                            Any Medical History?
                                          </h5>
                                        </div>
                                      </div>
                                      <div className="row mb_25">
                                        <div className="medical_history_box">
                                          <div className="medical_history_box_inner">
                                            <input
                                              type="radio"
                                              value="Yes"
                                              name="yesNo"
                                              checked={yesNo === "Yes"}
                                              className="mt_10"
                                              onChange={onChangeValue}
                                              style={{ accentColor: "#199a8e" }}
                                            />
                                            <lable for="Yes">
                                              <h5 className="medical_history_title_1">Yes</h5>
                                            </lable>
                                          </div>
                                          <div className="medical_history_box_inner">
                                            <input
                                              type="radio"
                                              value="No"
                                              name="yesNo"
                                              checked={yesNo === "No"}
                                              className="mt_10"
                                              onChange={onChangeValue}
                                              style={{ accentColor: "#199a8e" }}
                                            />
                                            <lable for="Yes">
                                              <h5 className="medical_history_title_1">No</h5>
                                            </lable>
                                          </div>
                                        </div>
                                      </div>
                                      {yesNo === "Yes" ? (
                                        <div className="row">
                                          <div className="col-md-12">
                                            <div className="input_box mb_30">
                                              <Field
                                                className="custom-select"
                                                name="medical_history"
                                                options={
                                                  medicalHistory?.length &&
                                                  medicalHistory?.map((item) => {
                                                    return {
                                                      label: item?.name,
                                                      value: item?.id,
                                                    };
                                                  })
                                                }
                                                component={CustomSelect}
                                                placeholder="Select Medical History..."
                                                isMulti={true}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      ) : null}
                                      <div className="row">
                                        <div className="col-md-12">
                                          <Button
                                            type="submit"
                                            className="signup_details_verify mt_30"
                                          >
                                            Continue
                                          </Button>
                                        </div>
                                      </div>
                                    </Form>
                                  )}
                                </Formik>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </>
          ) : (
            <>
              <div className="sub_section_2">
                <div className="row">
                  <div className="col-md-12">
                    <div className="display_t js-fullheight">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="signup_details_form_box">
                            <div className="signup_card">
                              <h3 className="select_your_city">
                                Select your City
                              </h3>

                              <div className="signup_details_box">
                                <Formik
                                  validationSchema={SelectSchema}
                                  initialValues={SelectEnum}
                                  onSubmit={(values) => {
                                    dispatch(EditUserProfile({
                                      values,
                                      utm_source: source,
                                      utm_campaign: medium,
                                      utm_medium: campaign
                                    }));
                                    navigate("/");
                                    localStorage.removeItem('utm_source');
                                    localStorage.removeItem('utm_medium');
                                    localStorage.removeItem('utm_campaign');
                                  }}
                                >
                                  {({
                                    values,
                                    setFieldValue,
                                    handleBlur,
                                    errors,
                                    touched,
                                    handleSubmit,
                                  }) => (
                                    <Form onSubmit={handleSubmit} id="myForm">
                                      <div className="row">
                                        {/* <div className="col-md-12"> */}
                                        {/* <div className="input_box"> */}
                                        <div className="col-md-12">
                                          <img
                                            src={Icon.search}
                                            className="select_city_search_icon"
                                            alt=""
                                          ></img>
                                          <input
                                            type="search"
                                            style={{ width: "100%" }}
                                            className="health_package_input_search"
                                            placeholder="Search"
                                            onChange={inputHandler}
                                          />
                                        </div>
                                        <div
                                          style={{
                                            height: "600px",
                                            overflow: "auto",
                                          }}
                                        >
                                          {filteredData?.length > 0 &&
                                            filteredData?.map((item, index) => (
                                              <div className="col-md-12">
                                                <label className="city-label-with">
                                                  <input
                                                    type="radio"
                                                    name="city_id"
                                                    value={item?.value}
                                                    onChange={() =>
                                                      setFieldValue(
                                                        "city_id",
                                                        item?.value
                                                      )
                                                    }
                                                    onBlur={handleBlur}
                                                    checked={
                                                      values.city_id ===
                                                      item?.value
                                                    }
                                                    style={{ accentColor: "#199a8e" }}
                                                  />
                                                  <div
                                                    className={`sign-up-city_card   ${values.city_id ===
                                                      item?.value
                                                      ? "sign-up-city_card_active"
                                                      : null
                                                      } `}
                                                    key={index}
                                                  >
                                                    <h5 className="sign-up-city_card_text sign-up-city_card_text_active">
                                                      {item.label}
                                                    </h5>
                                                  </div>
                                                </label>
                                              </div>
                                            ))}
                                          {/* <Field
                                                className="custom-select "
                                                name="city_id"
                                                options={city?.length && city}
                                                component={CustomSelect}
                                                placeholder="Select your City
                          ..."
                                                isMulti={false}
                                                onBlur={handleBlur}
                                              /> */}
                                        </div>
                                        {/* </div> */}
                                        {/* <FormControl
                                            control="select"
                                            options={city}
                                            // isMulti={false}
                                            onChange={(value) => {}}
                                            placeholder="Search City"
                                            name="city_id"
                                            id="city_id"
                                            value={values?.city_id}
                                            setFieldValue={setFieldValue}
                                            onBlur={handleBlur}
                                            errors={errors}
                                            touched={touched}
                                            isSearchable={true}
                                            // styles={colourStyles}
                                          /> */}
                                        {/* </div> */}
                                      </div>

                                      <div className="row">
                                        <div className="col-md-12">
                                          {values.city_id ? (
                                            <Button
                                              type="submit"
                                              className="next mt_30 "
                                            >
                                              Next
                                            </Button>
                                          ) : (
                                            <>
                                              <Button
                                                // disabled={true}
                                                className="nextDisabled mt_30 next-city-btn"
                                              >
                                                Next
                                              </Button>
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    </Form>
                                  )}
                                </Formik>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div >
    </>
  );
  function toggleGender(option, values, setFieldValue) {
    const newGender = values.gender === option ? "" : option;
    setFieldValue("gender", newGender);
  }

}

export default SignupDetails;
