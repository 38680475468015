import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Icon } from "../../../Utilities/Icons";
import successimage from '../../../Assets/img/png/Group 34263.png'
import { useNavigate } from "react-router-dom";

const RequestModal = (props) => {
    const navigate = useNavigate();
    const handleOkClick = () => {
        props.onHide();  // Close the modal or perform necessary actions
        navigate('/');  // Navigate to the root path '/'
    };
    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                dialogClassName="modal_455"
                backdrop="static"
                className="sucess_modal"

            >
                <Modal.Body className="modal_bg payment_modal_body">
                    <div className="" style={{textAlign:"center" ,paddingTop:"50px"}}>
                        <img src={successimage}></img>
                    </div>
                    <div>
                        <h2 className="modal_bg_texts">
                            Request Submitted!
                        </h2>
                        <p className="modal_bg_textes">Our Health Companion will Call you for Assistance</p>
                    </div>
                    <center>
                        <Button
                            className="payment_ok_btn"
                            onClick={handleOkClick}
                            
                        >
                            OK
                        </Button>
                    </center>
                </Modal.Body>
                <Modal.Footer className="modal_bg"></Modal.Footer>
            </Modal>

        </>
    )
}

export default RequestModal