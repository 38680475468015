import React from "react";
import moment from "moment";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { CardHome, CouponList, clearSelectedCouponCode } from "../../../Store/Reducer/BookingSlice";
import { Icon, Logo } from "../../../Utilities/Icons";
import ApplyCouponModel from "../../Common/ApplyCouponModel/ApplyCouponModel";
import SelectAddressModel from "../../Common/LabTestModel/SelectAddressModel";
import SelecteMemberModel from "../../Common/MemberModel/SelecteMemberModel";
import * as Yup from "yup";
import { useFormik } from "formik";
import { HealthCareBookingDetails, LabtestAddressList, LabtestBookingDetails, clearCartItem, clearSelectedMember, removeItem } from "../../../Store/Reducer/LabtestSlice";
import LabTestSlotModel from "../../Common/LabTestModel/LabTestSlotModel";
// import RadiologicalTestSlotModel from "../../Common/RadiolodicalModel/RadiologicalTestSlotModel";
import { clearPackageDetailsById } from "../../../Store/Reducer/PackageSlice";
import HealthCategoryListModal from "../../Common/Healthpackagemodal/HealthCategoryListModal";
import InquiryListmodal from "../../Common/Healthpackagemodal/InquiryListmodal";
import { useNavigate } from "react-router-dom";
import { GoldMemberBokingstatus, HealthCareBookingCreate, RadiologyBookingCreate, walletPrice } from "../../../Store/Reducer/RadiologySlice";
import PaymentSuccessfullModel from "../../Common/LabTestModel/PaymentSuccessfullModel";
import HealthMember from "./HealthMember";
import RequestModal from "./RequestModal";
import HealthPaymentSucessFully from "../../Common/Healthpackagemodal/HealthPaymentSucessFully";
import { clickCardHome } from "../../../Store/Reducer/ProfileReducer";
function HealthPackageCart(props) {
  var today = new Date();
  const PackageType = localStorage.getItem("PackageType");
  const { session } = useSelector(({ AuthSlice }) => AuthSlice);
  const { selectedMemberDetail, labCart, goldMembershipdetail } = useSelector(
    ({ LabtestSlice }) => LabtestSlice
  );

  const {
    wallet_price
  } = useSelector(({ RadiologySlice }) => RadiologySlice);
  const { packageDetailsById } = useSelector(
    ({ PackageSlice }) => PackageSlice
  );
  const { goldMembershipStatus } = useSelector(
    ({ RadiologySlice }) => RadiologySlice
  );
  const { coupencodeid } = useSelector(
    ({ BookingSlice }) => BookingSlice
  );
  const navigate = useNavigate();
  const { userWallet } = useSelector(({ ProfileSlice }) => ProfileSlice);
  const [detailpay, SetDetailpay] = useState();
  const [selectMember, setSelectMember] = useState(false);
  const [applyCoupon, setApplyCoupon] = useState(false);
  const [walletbox, setwalletbox] = React.useState(userWallet);
  const [updatedCart, setUpdatedCart] = useState();
  const [payable, totalPaybel] = useState();
  const [ids, setIds] = useState([]);
  const getTotal = () => {
    let totalQuantity = 0;
    let totalPrice = 0;
    labCart?.forEach((item) => {
      totalPrice += item?.amount * item?.quantity;
    });
    return { totalPrice, totalQuantity };
  };
  const [totalamount, setTotalAmount] = useState(
    Math.round(getTotal().totalPrice)
  );


  useEffect(() => {
    if (labCart.length > 0) {
      labCart.map((res) => {
        setIds([...ids, res.id])
      })
    }
  }, [labCart])
  const [slectedCityId, setSelectedCityId] = useState();
  const dispatch = useDispatch();
  const { couponList, couponCodeDetail } = useSelector(
    ({ BookingSlice }) => BookingSlice
  );
  const [selectAddressModel, setSelecteAddressModel] = useState(false);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);
  const options = {
    key: process.env.REACT_APP_PAYMENT_KEY,
    key_secret: process.env.REACT_APP_PAYMENT_SECRET_KEY,
    prefill: {
      name: session?.name,
      contact: session?.mobile_number,
      email: session?.email,
    },
    notes: {
      address: "some address",
    },
    theme: {
      color: "#F37254",
      hide_topbar: false,
    },
  };
  const openPayModal = (options) => {
    var rzp1 = new window.Razorpay(options);
    rzp1.open();
  };
  const createBooking = (values, resetForm) => {
    try {
      let tempOptions = {
        ...options,
        amount: Math.round(totalamount) * 100,
        currency: "INR",
        name: "THS-User",
        description: "some description",
        image: Logo.THS_logo,
        handler: async function (response) {
          if (response) {
            let newValues = {
              ...values,
              transaction_id: response.razorpay_payment_id,
              total_amount: totalamount,
              total_paid_amount: totalamount,
              wallet: wallet_price?.cut_amount,
              service: ids,
              coupen_code: coupencodeid
            };
            dispatch(HealthCareBookingCreate(newValues)).then((res) => {
              SetDetailpay(res?.payload)
              dispatch(HealthCareBookingDetails(healthbooking?.order_id)).then((res) => {
                if (res?.payload) {
                  dispatch(clearCartItem());
                  dispatch(clearSelectedCouponCode());
                  dispatch(clearSelectedMember());
                  setSuccessfullModel(true);
                }
              });
            });
          }
        },
      };
      let OtherOption = {
        member_id: selectedMemberDetail?.id,
        discount: couponCodeDetail?.coupon_percentage
          ? couponCodeDetail?.coupon_percentage
          : 0,
        wallet: userWallet?.wallet,
        ADDRESS: "",
        MEMBER: "",
        address_id: formik?.values?.address_id,
        category_name: formik?.values?.centername,
        termsAndConditions: false,
        transaction_id: "0",
        convenience_fee: "10",
        type: "",
        gold_discount: "10",
        service: ids,
        total_amount: totalamount,
        total_paid_amount: totalamount,
        wallet: wallet_price?.cut_amount,
        coupen_code: coupencodeid
      }

      const walletprice = Math.round(getTotal().totalPrice) - discountAmount
      let OtherOptions = {
        member_id: selectedMemberDetail?.id,
        discount: couponCodeDetail?.coupon_percentage
          ? couponCodeDetail?.coupon_percentage
          : 0,
        wallet: walletprice,
        ADDRESS: "",
        MEMBER: "",
        address_id: formik?.values?.address_id,
        category_name: formik?.values?.centername,
        termsAndConditions: false,
        transaction_id: "user_wallet",
        convenience_fee: "10",
        type: 2,
        gold_discount: "10",
        service: ids,
        total_amount: totalamount,
        total_paid_amount: totalamount,
        wallet: wallet_price?.cut_amount,
        coupen_code: coupencodeid
      }

      if (values?.type == "1") {
        Setrequest(true);
        dispatch(HealthCareBookingCreate(OtherOption))
      } else {
        if (totalamount == 0) {
          dispatch(HealthCareBookingCreate(OtherOptions)).then((res) => {
            SetDetailpay(res?.payload)

            dispatch(HealthCareBookingDetails(healthbooking?.order_id)).then((res) => {
              if (res?.payload) {
                dispatch(clearCartItem());
                dispatch(clearSelectedCouponCode());
                dispatch(clearSelectedMember());
                setSuccessfullModel(true);

              }
            });

          });
        } else {
          openPayModal(tempOptions);
        }

      }
    } catch (error) { }
  };


  const coupendiscount = (Math.round(getTotal()?.totalPrice) * Number(couponCodeDetail?.coupon_percentage)) / 100
  const healthPackageSchema = Yup.object({
    ADDRESS: Yup.string().required("Please Select Address"),
    // MEMBER: Yup.string().required("Please Select Member"),
    termsAndConditions: Yup.bool().oneOf(
      [true],
      "Before you book your health test ,make sure you are aware about THS terms and conditions."
    ),
  });


  const formik = useFormik({
    initialValues: {
      member_id: selectedMemberDetail?.id,

      discount: couponCodeDetail?.coupon_percentage
        ? couponCodeDetail?.coupon_percentage
        : 0,
      wallet: totalamount - userWallet?.wallet,

      ADDRESS: "",
      MEMBER: "",
      category_name: "",
      address_id: "",
      termsAndConditions: false,
      transaction_id: "0",
      convenience_fee: "10",
      type: "",
      gold_discount: "10",
      service: ids,
      total_amount: totalamount,
      total_paid_amount: totalamount,
      wallet: wallet_price?.cut_amount,
    },
    validationSchema: healthPackageSchema,
    onSubmit: (values, { resetForm }) => {
      createBooking(values, resetForm);
    },
  });


  useEffect(() => {
    dispatch(CouponList({ coupon_type: 3 }));
  }, [dispatch]);
  const {
    cards
  } = useSelector(({ BookingSlice }) => BookingSlice);
  useEffect(() => {
    dispatch(CardHome())
    dispatch(GoldMemberBokingstatus(cards?.gold_membership?.id));
  }, [dispatch, cards?.gold_membership?.id])

  let test_ids = labCart?.map(function (i) {
    return i?.id;
  });

  useEffect(() => {
    dispatch(
      clickCardHome({
        type: "6",
        homehealth_care_id: test_ids?.toString(),
      })
    );
  }, [dispatch, labCart]);



  function handleChange(event) {
    setwalletbox((prevFormData) => {
      return {
        ...prevFormData,
        [event.target.name]: event.target.checked,
      };
    });

    if (event.target.checked) {
      if (couponCodeDetail) {
      
        const amout = getTotal()?.totalPrice - walletbox.wallet - coupendiscount - discountAmount;
        setTotalAmount(amout <= 0 ? "0" : amout);
        const cutOfPrice = getTotal()?.totalPrice - (coupendiscount + discountAmount)
        const incAmount = walletbox.wallet - cutOfPrice;
        

        dispatch(walletPrice({ "wallet_prices": cutOfPrice >= walletbox?.wallet ? "0" : incAmount, "cut_amount": cutOfPrice >= walletbox?.wallet ? "0" : cutOfPrice }));
       
      } else {

        // const amout = totalamount - walletbox.wallet - coupendiscount - discountAmount;
        const amout = totalamount - walletbox.wallet;
        const data = totalamount - walletbox.wallet - coupendiscount - discount
        let removeDis;
        if (goldMembershipdetail !== true) {
          removeDis = totalamount
        } else {
          removeDis = totalamount

        }
     
        dispatch(walletPrice({ "wallet_prices": removeDis <= 0 ? 0 : (walletbox?.wallet - removeDis) <= 0 ? 0 : walletbox?.wallet - removeDis, "cut_amount": walletbox?.wallet - removeDis <= 0 ? walletbox?.wallet : removeDis }));
        if (amout >= 0) {

          setTotalAmount(Math.max(isNaN(amout) ? 0 : amout));

        } else {
          setTotalAmount(Math.max(isNaN(data) ? 0 : data));
        }


      }
    } else {
      if (couponCodeDetail) {
        const amount = getTotal()?.totalPrice - (coupendiscount + discountAmount)
        if (wallet_price?.wallet_prices == 0) {
          dispatch(walletPrice({ "wallet_prices": wallet_price?.cut_amount }));
          setTotalAmount(amount <= 0 ? "0" : amount);
        } else {
          dispatch(walletPrice({ "wallet_prices": wallet_price?.wallet_prices + (getTotal()?.totalPrice - (coupendiscount + discountAmount)) }));
          setTotalAmount(amount <= 0 ? "0" : amount);
        }

      } else {

        if (goldMembershipdetail !== true) {
          const amount = (getTotal()?.totalPrice - discountAmount)
          if (wallet_price?.wallet_prices == 0) {
            dispatch(walletPrice({ "wallet_prices": wallet_price?.cut_amount }));
            setTotalAmount(amount <= 0 ? "0" : amount);
          } else {
            dispatch(walletPrice({ "wallet_prices": wallet_price?.wallet_prices + (getTotal()?.totalPrice - discountAmount) }));
            setTotalAmount(amount <= 0 ? "0" : amount);
          }


        } else {
          const amount = getTotal()?.totalPrice
          if (wallet_price?.wallet_prices == 0) {
            dispatch(walletPrice({ "wallet_prices": wallet_price?.cut_amount }));
            setTotalAmount(amount <= 0 ? "0" : amount);
          } else {
            dispatch(walletPrice({ "wallet_prices": wallet_price?.wallet_prices + (getTotal()?.totalPrice) }));
            setTotalAmount(amount <= 0 ? "0" : amount);
          }


        }
      }
    }
  }

  useEffect(() => {

    if (labCart.length > 0) {
      
      if (goldMembershipdetail != true) {
        const discountAmount = (Math.round(getTotal()?.totalPrice) * goldMembershipStatus?.discount) / 100;
      
        setTotalAmount(Math.round(getTotal()?.totalPrice) - discountAmount);
      } else {
       
        setTotalAmount(Math.round(getTotal()?.totalPrice));
        setUpdatedCart(labCart);
      }

    } else {
      setTotalAmount(Math.round(getTotal()?.totalPrice));
      setUpdatedCart(labCart);
    }
  }, [labCart, updatedCart]);


  const getDiscount = (cupon) => {
    if (cupon?.coupon_percentage) {
      setTotalAmount(
        Math.round(getTotal().totalPrice) -
        (Math.round(getTotal().totalPrice) *
          Number(cupon?.coupon_percentage)) /
        100
      );
    }
  };


  useEffect(() => {
    if (couponCodeDetail) {
      if (goldMembershipdetail != true) {
      
        const discountAmount = (Math.round(getTotal()?.totalPrice) * goldMembershipStatus?.discount) / 100;

        setTotalAmount(
          Math.round(getTotal()?.totalPrice) -
          (Math.round(getTotal()?.totalPrice) *
            Number(couponCodeDetail?.coupon_percentage)) /
          100 - discountAmount
        );
     
      } else {
       
        setTotalAmount(
          Math.round(getTotal()?.totalPrice) -
          (Math.round(getTotal()?.totalPrice) *
            Number(couponCodeDetail?.coupon_percentage)) /
          100
        );
      }

    } else if (goldMembershipdetail != true) {
      const discountAmount = (Math.round(getTotal()?.totalPrice) * goldMembershipStatus?.discount) / 100;
      setTotalAmount(Math.round(getTotal()?.totalPrice) - discountAmount);
    
    } else {
      setTotalAmount(Math.round(getTotal()?.totalPrice));
    }
  }, [couponCodeDetail, goldMembershipdetail]);
  const discountAmount = (Math.round(getTotal()?.totalPrice) * goldMembershipStatus?.discount) / 100;
  useEffect(() => {
    dispatch(LabtestAddressList());
    dispatch(walletPrice({ "wallet_prices": walletbox?.wallet, "cut_amount": 0 }));
    dispatch(clearSelectedCouponCode())
  }, [dispatch]);
  const {
    healthbooking
  } = useSelector(({ RadiologySlice }) => RadiologySlice);

  let discount = totalamount - packageDetailsById[0]?.package_amount;
  const [inquiry, SetInquiry] = useState(false);
  const [secondModal, SetsecondModal] = useState(false);
  const [successfullModel, setSuccessfullModel] = useState(false);
  const [request, Setrequest] = useState(false);


  return (
    <>
      {/* <PaymentSuccessfullModel
        show={successfullModel}
        onHide={() => setSuccessfullModel(false)}
      /> */}
      <HealthPaymentSucessFully
        show={successfullModel}
        onHide={() => setSuccessfullModel(false)}
        detailpay={detailpay}
        healthbooking={healthbooking}

      />
      <HealthMember
        show={selectMember}
        onHide={() => setSelectMember(false)}
        formik={formik}
      />
      <SelectAddressModel
        show={selectAddressModel}
        setSelectedCityId={setSelectedCityId}
        formik={formik}
        onHide={(e) => {
          setSelecteAddressModel(false);
        }}
      />
      <ApplyCouponModel
        couponList={couponList}
        show={applyCoupon}
        getDiscount={getDiscount}
        couponType={3}
        onHide={() => setApplyCoupon(!couponList)}
      />
      <RequestModal
        show={request}
        onHide={(e) => {
          Setrequest(false);
        }}
      />
      <HealthCategoryListModal
        show={inquiry}
        onNext={() => {
          SetsecondModal(true);
          SetInquiry(false);
        }}
        onHide={(e) => {
          SetInquiry(false);
        }}
      />

      <InquiryListmodal
        show={secondModal}
        onNext={() => {
          SetsecondModal(false);
        }}
        onHide={(e) => {
          SetsecondModal(false);
        }}
        amount={totalamount}
        formik={formik}
      />

      <form onSubmit={formik.handleSubmit}>
        <Container fluid className="lab_test_cart_container">
          <Container>
            <div className="row">
              <div className="col-md-12">
                <h3 className="cart_head">Cart</h3>
              </div>
            </div>
            <div className="lab_test_cart_box">
              <div className="lab_test_cart_box_inner">
                <div className="row">
                  <div className="col-xl-8 col-md-7 lab_test_right_padding">
                    <div className="lab_test_cart_left_side">
                      <div className="row mb_15">
                        <div className="col-md-6 col-6">
                          <h3 className="lab_test_cart_left_side_title">
                            Tests
                          </h3>
                        </div>
                        <div className="col-md-6 col-6">
                          <Button className="lab_test_add_test_btn"
                            onClick={() => { SetsecondModal(true) }}
                          >
                            Add Test
                          </Button>
                        </div>
                      </div>
                      <div>
                        <div>
                          {labCart.length &&
                            labCart?.map((item) => (
                              <>

                                <div className="row mt_20">
                                  <div className="col-md-6 col-6">
                                    <div className="lab_test_for_test_box">
                                      <img
                                        src={Icon.trash}
                                        className="lab_test_for_test_icon"
                                        alt=""
                                        onClick={() =>
                                          dispatch(removeItem(item?.id))
                                        }
                                      ></img>
                                      <h3 className="lab_test_for_test_name">
                                        {item?.name}
                                      </h3>
                                    </div>
                                  </div>
                                  <div className="col-md-6 col-6">
                                    <h5 className="lab_test_for_test_value">
                                      ₹ {item?.amount}
                                    </h5>
                                  </div>
                                </div>
                                <hr className="lab_test_for_test_hr" />
                              </>
                            ))}
                        </div>
                      </div>
                    </div>

                    <div className="lab_test_cart_left_side_2">
                      <div className="row">
                        <div className="col-md-6">
                          <h3 className="lab_test_address">Select Your Address</h3>
                          <div className="lab_test_address_box" onClick={() => setSelecteAddressModel(true)}>
                            {formik?.values?.address_type ? (
                              <>
                                <h2 className="lab_test_address_box_texts">{formik.values.address_type}</h2>
                                <h5 className="lab_test_address_box_textes">
                                  {formik.values.ADDRESS ? formik.values.ADDRESS + ", " : ""}
                                  {formik.values.CITY ? formik.values.CITY + ", " : ""}
                                  {formik.values.STATE ? formik.values.STATE + ", " : ""}
                                  {formik.values.COUNTRY ? formik.values.COUNTRY : ""}

                                </h5>
                                <div className="changese">
                                  <img src={Icon.edit} alt="Edit Icon" className="editimg" />
                                  <p className="changes">Change Address</p>
                                </div>
                              </>
                            ) : <>
                            
                              <h2 className="lab_test_address_box_texts">
                                {formik?.values?.address_type
                                  ? formik?.values?.address_type
                                  : "Select"}
                              </h2>
                            </>}
                          </div>

                          <div className="error_text pt-2">
                            {" "}
                            {formik.errors.ADDRESS &&
                              formik.touched.ADDRESS &&
                              formik.errors.ADDRESS}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <h3 className="lab_test_time">Select Member</h3>
                          <div className="lab_test_address_box" onClick={() => setSelectMember(true)}>

                            {
                              selectedMemberDetail == [] ? <>
                                <h2 className="lab_test_address_box_texts">Select</h2>
                              </>
                                :
                                <>
                                  <div className="changese">
                                    <div>
                                      <img src={selectedMemberDetail?.image} className="memberimg"></img>
                                    </div>
                                    <div>
                                      <h5 className="radiology_booked_test_for_name">
                                        {selectedMemberDetail?.name}
                                      </h5>
                                      <p className="memberlist">{selectedMemberDetail?.gender},Age: {selectedMemberDetail?.age}</p>
                                    </div>
                                  </div>
                                </>
                            }

                          </div>
                          <div className="error_text pt-2">
                            {" "}
                            {formik.errors.MEMBER &&
                              formik.touched.MEMBER &&
                              formik.errors.MEMBER}
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                  <div className="col-xl-4 col-md-5 lab_test_left_padding">
                    <div className="lab_test_cart_right_side">
                      <h5 className="offer_benifit_text">
                        Offers &amp; Benifits
                      </h5>
                      <div
                        className="offer_benifit_card"
                        onClick={() => setApplyCoupon(true)}
                      >
                        <div className="apply_coupon_box">
                          <img
                            src={Icon.coupon}
                            className="apply_coupon_icon"
                          ></img>
                          <h5 className="offer_benifit_text">Apply Coupon</h5>
                        </div>
                        <div>
                          <svg
                            width="8"
                            height="14"
                            className="subscrip_consult_card_arrow"
                            viewBox="0 0 8 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1.25 12.5L6.75 7L1.25 1.5"
                              stroke="#9393AA"
                              strokeWidth="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </div>
                      </div>
                      <h5 className="payment_summary">Payment Summary</h5>
                      <div className="payment_summary_card">
                        <div className="payment_summary_card_inner mb_5">
                          <div>
                            <h5 className="payment_summary_title">
                              Total amount
                            </h5>
                          </div>
                          <div>
                            <h5 className="payment_summary_price">
                              ₹ {Math.round(getTotal().totalPrice)}
                            </h5>
                          </div>
                        </div>
                        {couponCodeDetail && (
                          <div className="payment_summary_card_inner">
                            <div>
                              <h5 className="payment_summary_title">
                                Discount
                              </h5>
                            </div>
                            <div>
                              <h5 className="payment_summary_price_discount">
                                - ₹ {coupendiscount}
                              </h5>
                            </div>
                          </div>
                        )}
                        {
                          goldMembershipdetail && goldMembershipdetail === true ? "" : <>
                            <div className="payment_summary_card_inner">
                              <div>
                                <h5 className="payment_done_payment_info_title" style={{ color: "#48988E" }}>
                                  Gold Discount [{goldMembershipStatus?.discount}%]
                                </h5>
                              </div>
                              <div>
                                <h5 className="payment_summary_price_discount">
                                  - ₹{" "}
                                  {discountAmount && discountAmount}

                                </h5>
                              </div>
                            </div>
                          </>
                        }

                        <hr className="payment_hr" />
                        <div className="payment_summary_card_inner">
                          <div>
                            <h5 className="payment_summary_total_title">
                              Total Payable
                            </h5>
                          </div>
                          <div>
                            <h5 className="payment_summary_total_price">
                              ₹ {totalamount ? parseFloat(totalamount).toFixed(0) : 0}
                            </h5>
                          </div>
                        </div>
                      </div>
                      <div className="wallet_card">
                        <div>
                          <div className="form-group">
                            <input
                              type="checkbox"
                              id={walletbox.id}
                              onChange={handleChange}
                              name={userWallet?.wallet}
                              value={walletbox}
                            />
                            <label className="wallet_text" for={walletbox.id}>
                              <span className="wallet_subtext">
                                Use wallet Amount
                              </span>
                            </label>
                          </div>
                        </div>
                        <div>
                          <h5 className="available_bal_text">
                            Avbl. bal.
                            <span className="available_bal_price">
                              ₹ {parseInt(wallet_price?.wallet_prices || "0")}
                            </span>
                          </h5>
                        </div>
                      </div>

                      <h5 className="T_C_title">Terms and conditions</h5>
                      <div className="lab_T_C_card">
                        <p className="T_C_text">
                          Before you book your Home care query ,make sure  you are aware about THS terms and conditions
                        </p>
                        <div className="col-md-12">
                          <div>
                            <div className="healthpackage">
                              <input
                                id="checkbox1"
                                type="checkbox"
                                className="vh"
                                name="termsAndConditions"
                                onChange={() =>
                                  formik.setFieldValue(
                                    "termsAndConditions",
                                    !formik.values.termsAndConditions
                                  )
                                }
                              />
                              <label
                                className="healthpackage_checkbox_title"
                                for="checkbox1"
                                style={{
                                  display: "inline-flex",
                                  alignItems: "center",
                                }}
                              >
                                {" "}
                                <span
                                  className="i_agree_cancellation_policy"
                                  style={{ padding: "25px" }}
                                >
                                  <h5 className="view_T_C_text">
                                    View Terms And Conditions
                                  </h5>
                                </span>
                              </label>
                            </div>

                            {formik?.errors?.termsAndConditions &&
                              formik.touched.termsAndConditions && (
                                <div className="terms_conditions_error_text">
                                  {formik?.errors?.termsAndConditions}
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="lab_test_footer_card lab_test_footer_card2">
                <div className="lab_test_card_box lab_test_card_box2">
                  <div className="lab_test_price_box lab_test_price_box2">
                    <h5 className="lab_test_price lab_test_price2 ">Rs   ₹ {totalamount ? parseFloat(totalamount).toFixed(0) : 0} </h5>
                    {/* <h6 className="lab_test_subtitle lab_test_subtitle2">Amount to pay</h6> */}
                  </div>
                  <div className="lab_test_btn2">
                    <Button className="lab_test_btns" type="submit" onClick={() => formik.setFieldValue(
                      "type",
                      "1"
                    )} >
                      Inquiry Only
                    </Button>
                  </div>
                  <div className="lab_test_btn2">
                    <Button className="lab_test_btn" type="submit" onClick={() => formik.setFieldValue(
                      "type",
                      "2"
                    )} >
                      Pay Now
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </Container>
      </form>
    </>
  );
}

export default HealthPackageCart;
