import React, { useEffect, useHistory } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import Universal from "../Components/Common/Universal";
import DashboardHome from "../Components/Private/Dashboard/Dashboard";
import SignIn from "../Components/Public/SignIn/SignIn";
import SignupDetails from "../Components/Public/SingUp/SignUp";
import PrivateRoutes from "./PrivateRoutes";
import PublicRoutes from "./PublicRoutes";
import ProfileSidebar from "../Components/Private/Profile/ProfileSidebar";
import UserProfileSidebar from "../Components/Private/userProfile/profile/UserProfileSidebar";
import Medicines from "../Components/Private/medicines/Medicines";
import LabTestPackage from "../Components/Common/labtestpackage/LabTestPackage";
import MedicinesDetails from ".././Components/Private/medicines/medicinesdetails/MedicinesDetails";
import LabTestCart from "../Components/Private/medicines/labtestcart/LabTestCart";
import LabTestCompleteDetails from "../Components/Private/userProfile/testBooking/LabTestCompleteDetails";
import BuyMedicineCart from "../Components/Private/medicines/buymedicinecart/BuyMedicineCart";
import ChatIndex from "../Components/Private/Consultation/Chat/Chat";
import {
  CancelationReasonList,
  PastBookingsList,
  SpecialityList,
  UpcomingBookingList,
} from "../Store/Reducer/BookingSlice";
import { GetMember, GetUserWallet, GetUserWalletList, GetMySubscribedPlansList, GetHeightList, GetWeightList } from "../Store/Reducer/ProfileReducer";
import ConsultIndex from "../Components/Private/Consultation/ConsultIndex";
import { MedicineOrdersData } from "../Store/Reducer/OrderSilce";
import MedicineOrdersDetail from "../Components/Private/Profile/MedicineOrders/MedicineOrdersDetail";
import { AddressList } from "../Store/Reducer/AddressSlice";
import { HealthPackagePastBooking, HealthPackageUpcomingBooking, LabtestUpcomingBooking } from "../Store/Reducer/LabtestSlice";
import RadiologyTestCart from "../Components/Private/Radiology/RadiologyTestCart";
import RadiologyCompleteDetails from "../Components/Private/userProfile/testBooking/RadiologyCompleteDetails";
import MyConsultationDetails from "../Components/Private/Consultation/MyConsultationDetails";
import HealthPackage from "../Components/Private/HealthPackage/HealthPackage";
import HealthPackageCart from "../Components/Private/HealthPackage/HealthPackageCart";
import { RadiologyUpcomingBookings, clearRadiologyCartItem } from "../Store/Reducer/RadiologySlice";
import MySubscriptionsDetail from "../Components/Private/Profile/MySubscriptions/MySubcriptionDetail";
import DoctorSidebar from "../Components/Private/DoctorLink/DoctorSidebar";
import Speciality from "../Components/Private/Dashboard/Speciality";
import SpecialityhealthModel from "../Components/Private/Dashboard/SpecialityhealthModel";
import HealthPackageDetailModel from "../Components/Common/labtestpackage/HealthPackageDetailModel";
import HealthTestCart from "../Components/Common/Healthpackagemodal/HealthTestCart";
import HomeHealth from "../Components/Private/Dashboard/HomeHealth";
import HealthCartDetail from "../Components/Private/HealthPackage/HealthCartDetail";
import SurgeryCart from "../Components/Common/Surgery/SurgeryCart";
import TermsandCondition from "../Components/Private/Dashboard/TermsandCondition";
import Radiologypackage from "../Components/Common/labtestpackage/Radiologypackage";
import { useLocation } from "react-router-dom";
import RedirectToBooking from "../Components/Private/RedireToBooking";
import ShareMyLink from "../Components/Private/Consultation/ShareMyLink";

export default function RootRoutes() {
  const { token } = useSelector(({ AuthSlice }) => AuthSlice);
  const dispatch = useDispatch();
  const location = useLocation();


  useEffect(() => {
    if (token) {
      dispatch(UpcomingBookingList());
      dispatch(PastBookingsList());
      dispatch(CancelationReasonList());
      dispatch(GetMember());
      dispatch(GetUserWalletList())
      dispatch(GetMySubscribedPlansList())
      dispatch(MedicineOrdersData());
      // dispatch(MedicalHistoryList());
      dispatch(GetHeightList());
      dispatch(GetWeightList());
      dispatch(SpecialityList());
      dispatch(AddressList());
      dispatch(GetUserWallet());
      dispatch(AddressList());
      dispatch(LabtestUpcomingBooking());
      dispatch(RadiologyUpcomingBookings());
      // dispatch(HealthPackageUpcomingBooking())
      // dispatch(HealthPackagePastBooking())
    }
    return () => { };
  }, [dispatch, token]);
  useEffect(() => {
    // Check if the current URL matches the dynamic link
    if (window.location.href === "https://thsindia.in/medical-web/counsult/QMobAQtadN/356") {
      // Redirect to the desired URL
      window.location.href = "/booking/51";
    }
  }, []);


  const routes = [
    {
      path: "/booking/:id2",
      name: "Book Consultation",
      Component: <ConsultIndex />,
    },
    {
      path: "/consultation-details/:id",
      name: "Details",
      Component: <MyConsultationDetails />,
    },
    {
      path: "/profileSidebar",
      name: "Consultation Profile",
      Component: <ProfileSidebar />,
    },
    {
      path: "/doctorsidebar",
      name: "Doctor Link Flow",
      Component: <DoctorSidebar />,
    },
    {
      path: "/speciality",
      name: "speciality",
      Component: <Speciality />,
    },
    {
      path: "/homehealth",

      name: "homehealth",
      Component: <HomeHealth />,
    },
    {
      path: "/terms",
      name: "Refer and Earn",
      Component: <TermsandCondition />,
    },
    {
      path: "/health",
      name: "health",
      Component: <SpecialityhealthModel />,
    },
    {
      path: "/userProfile/:id",
      name: "Profile Details",
      Component: <UserProfileSidebar />,
    },
    {
      path: "/subscriptionDetail/:id",
      name: "Subscription Detail",
      Component: <MySubscriptionsDetail />,
    },
    {
      path: "/medicines",
      name: "Medicines List",
      Component: <Medicines />,
    },
    {
      path: "/medicinesdetails/:id",
      name: "Medicines Detail",
      Component: <MedicinesDetails />,
    },
    {
      path: "/buymedicinecart",
      name: "Buy Medicine Cart",
      Component: <BuyMedicineCart />,
    },
    {
      path: "/labtestpackage",
      name: "Lab Test",
      Component: <LabTestPackage />,
    },
    {
      path: "/radiology",
      name: "Radiology Centers",
      Component: <Radiologypackage />,
    },
    {
      path: "/labtestcart",
      name: "Labtest Cart",
      Component: <LabTestCart />,
    },
    {
      path: "/helathCart",
      name: "helathCart",
      Component: <HealthCartDetail />,
    },
    {
      path: "/lab-test-detail/:labtestId",
      name: "Lab Test Complete Details",
      Component: <LabTestCompleteDetails />,
    },
    {
      path: "/radiology-test-detail/:radiologyById",
      name: "Radiology Test Complete Details",
      Component: <RadiologyCompleteDetails />,
    },
    {
      path: "/chat/:booking_id",
      name: "Chat",
      Component: <ChatIndex />,
    },
    {
      path: "/orders/order-detail/:id",
      name: "MediconeOrder",
      Component: <MedicineOrdersDetail />,
    },
    {
      path: "*",
      name: "Universal",
      Component: <Universal />,
    },
    {
      path: "/radiology-test-cart",
      name: "radiology-test-cart",
      Component: <RadiologyTestCart />,
    },
    {
      path: "/health-package",
      name: "Health Package",
      Component: <HealthPackage />,
    },
    {
      path: "/healthcare",
      name: "Home Health-care",
      Component: <HealthTestCart />,
    },
    {
      path: "/surgery",
      name: "surgery",
      Component: <SurgeryCart />,
    },
    {
      path: "/health-Package-cart",
      name: "Home-Health Care",
      Component: <HealthPackageCart />,
    },
    {
      path: "/counsult/:uid/:doctorId",
      name: "Home-Health Care",
      Component: <ShareMyLink />,
    },
  ];

  return (

    <Routes>
      <Route
        path="/"
        index
        element={
          !token ? (
            <PublicRoutes isHeader={true}>
              <SignIn />
            </PublicRoutes>
          ) : (
            <PublicRoutes isHeader={true}>
              <DashboardHome />
            </PublicRoutes>
          )
        }
      />
      <Route
        path="/signin"
        element={
          <PublicRoutes isHeader={true}> {!token ? <SignIn /> : <DashboardHome />}</PublicRoutes >
        }
      />
      <Route
        path="/signupdetails"
        element={
          < PublicRoutes >
            <SignupDetails />
          </PublicRoutes >
        }
      />
      {/* <Route
        path="/counsult/:param1/:param2"
        element={<RedirectToBooking />}
      /> */}
      {
        routes?.map(({ path, name, Component }, key) => (
          <Route
            exact
            path={path}
            key={key}
            element={
              <PrivateRoutes breadcrumbTitle={name}>{Component}</PrivateRoutes>
            }
          />
        ))
      }
    </Routes >
  );
}
