import React, { useState, useEffect, useContext } from "react";
import { Modal, Button } from "react-bootstrap";
import { Icon } from "../../../Utilities/Icons.js";
import "../../../Assets/css/responsive.css";
import { useFormikContext } from "formik";
import {
  SelectedPlan,
  setSelectedPlanDetails,
} from "../../../Store/Reducer/BookingSlice";
import { useDispatch, useSelector } from "react-redux";
import AddMember from "../../Common/MemberModel/AddMember.js";
import { AddNewMembers, ConsultionCard, GetHeightList, GetUserWallet, GetWeightList } from "../../../Store/Reducer/ProfileReducer.js";
import ApplyCouponModel from "../../Common/ApplyCouponModel/ApplyCouponModel.js";
import BookingSuccessModel from "../../Common/BookingSuccessModel/BookingSuccessModel.js";
import img6 from '../../../Assets/img/svg/Group 34247.svg'
import img7 from '../../../Assets/img/svg/Group 34253.svg'
import img2 from '../../../Assets/img/svg/purple.svg';
import img3 from '../../../Assets/img/svg/blue.svg';
import { Formik } from "formik";
import {
  BookConsultant,
  CheckBookingSlot,
  clearFollowUpPassData,
  clearSelectedCouponCode,
  clearSelectedPlan,
  clearSelectedPlanDetails,
  cleaSelectedDate,
  CouponList,
  GetDoctorListById,
  GetSubscriptionPlan,
  MedicalHistoryList,
  SubscribedPlansList,
  UpcomingBookingList,
} from "../../../Store/Reducer/BookingSlice";
import { CardHome } from "../../../Store/Reducer/BookingSlice";
import Terms from "./Terms.js";
import { useParams } from "react-router-dom";
import { GoldMemberBokingstatus, walletPrice, WalletCute } from "../../../Store/Reducer/RadiologySlice.js";
import ApplyCouponModel2 from "../../Common/ApplyCouponModel/ApplyCoupenModal2.js";
const StateContext = React.createContext();
function SubscriptionPlan(props) {
  const {
    wallet_price,
  } = useSelector(({ RadiologySlice }) => RadiologySlice);
  const [modalShow1, setModalShow1] = useState(false);
  const [applyCoupon, setApplyCoupon] = useState(false);
  const [newMembers, setNewMembers] = useState(false);
  const { values, errors, touched, setFieldValue } = useFormikContext();
  const [bookingSuccessModel, setBookingSuccessModel] = useState(false);
  const [selectedNewMember, setSelectedNewMember] = useState();
  const {
    cretaBookConsultant
  } = useSelector(({ BookingSlice }) => BookingSlice);

  const { goldMembershipStatus, goldMembershipStatus1 } = useSelector(
    ({ RadiologySlice }) => RadiologySlice
  );
  console.log("props", props);

  const handleCheckboxClick = () => {
    const link = 'https://thsindia.in/medical-web/privacy-policy';
    window.open(link, '_blank');
  };
  const SubscriptionPlanInitialState = {
    selectedPlanName: "",
  };
  let addMemberinitialState = {
    name: "",
    email: "",
    mobile_number: "",
    age: "",
    height: "",
    weight: "",
    gender: "",
    blood_group: "",
    birthdate: "",
    medical_history: [],
  };
  const dispatch = useDispatch();
  const {
    couponList,
    subScriptionPlanList,
    subscribedPlansList,
    selectedPlan,
    couponCodeDetail,
    selectedPlanDetails,
    followUpPassData,
  } = useSelector(({ BookingSlice }) => BookingSlice);
  const { userWallet, memberList, consutationscards } = useSelector(
    ({ ProfileSlice }) => ProfileSlice
  );
  const { goldMembershipdetail } = useSelector(
    ({ LabtestSlice }) => LabtestSlice
  );
  const [checkedVal, setCheckedVal] = useState(false);
  const { id2 } = useParams();
  const [walletbox, setwalletbox] = React.useState(userWallet);
  const discountAmount = selectedPlanDetails?.discounted_price * goldMembershipStatus?.discount / 100;
  const coupendiscount = selectedPlanDetails?.discounted_price * Number(couponCodeDetail?.coupon_percentage) / 100;

  { console.log("goldMembershipStatus", goldMembershipStatus) }
  function handleChange(event) {
    if (checkedVal) {
      if (couponCodeDetail) {
        const amount = selectedPlanDetails?.discounted_price - (coupendiscount)
        const walletamount = (
          (Number(walletbox?.wallet) || 0)
        ).toFixed(0);
        dispatch(walletPrice(`{ "wallet_prices": ${walletamount} , "cut_amount" : 0 }`));
        setFieldValue("total_paid_amount", amount <= 0 ? "0" : amount);

      } else {
        if (goldMembershipdetail !== true) {
          const amounts = selectedPlanDetails?.discounted_price
          const walletamount = (
            (Number(walletbox?.wallet) || 0)
          ).toFixed(0);
          setFieldValue("total_paid_amount", amounts <= 0 ? "0" : amounts);
          dispatch(walletPrice(`{ "wallet_prices": ${walletamount},  "cut_amount" : 0 }`));
        } else {
          const amount = selectedPlanDetails?.discounted_price
          const walletamount = (
            (Number(walletbox?.wallet) || 0)
          ).toFixed(0);
          dispatch(walletPrice(`{ "wallet_prices": ${walletamount} ,  "cut_amount" : 0}`));
          setFieldValue("total_paid_amount", amount <= 0 ? "0" : amount);
          setFieldValue("discounted_pricess", amount <= 0 ? "0" : amount);
        }
      }
    } else {
      if (couponCodeDetail) {
        const amout = selectedPlanDetails?.discounted_price - walletbox.wallet - couponCodeDetail?.discount - discountAmount;
        setFieldValue("total_paid_amount", amout <= 0 ? "0" : amout);
        const cutOfPrice = selectedPlanDetails?.discounted_price - (coupendiscount)

        const incAmount = walletbox.wallet - cutOfPrice;

        dispatch(walletPrice(`{ "wallet_prices": ${cutOfPrice >= walletbox?.wallet ? "0" : incAmount}, "cut_amount": ${cutOfPrice >= walletbox?.wallet ? "0" : cutOfPrice} }`));
        dispatch(WalletCute(cutOfPrice >= walletbox?.wallet ? "0" : cutOfPrice))
      } else {
        const walletCount = walletbox?.wallet - (selectedPlanDetails?.discounted_price - discountAmount);
        let removeDis;
        let count;
        if (goldMembershipdetail !== true) {
          removeDis = (values?.discounted_price - ((values?.discounted_price * goldMembershipStatus?.discount / 100))) - walletbox?.wallet
          count = selectedPlanDetails?.discounted_price - discountAmount
          dispatch(walletPrice(`{ "wallet_prices": ${walletCount <= 0 ? 0 : walletCount}, "cut_amount": ${walletbox?.wallet - count <= 0 ? walletbox?.wallet : count} }`));
          dispatch(WalletCute(walletbox?.wallet - count <= 0 ? walletbox?.wallet : count))
          setFieldValue("total_paid_amount", removeDis <= 0 ? "0" : removeDis);
        } else {
          removeDis = values?.discounted_price - walletbox?.wallet

          count = selectedPlanDetails?.discounted_price
          const walletCounts = walletbox?.wallet - (selectedPlanDetails?.discounted_price);
          dispatch(walletPrice(`{ "wallet_prices": ${walletCounts <= 0 ? 0 : walletCounts}, "cut_amount": ${walletbox?.wallet - count <= 0 ? walletbox?.wallet : count} }`));
          dispatch(WalletCute(walletbox?.wallet - count <= 0 ? walletbox?.wallet : count))
          setFieldValue("discounted_pricess", removeDis <= 0 ? "0" : removeDis);
          setFieldValue("total_paid_amount", removeDis <= 0 ? "0" : removeDis);
        }
      }
    }
  }

  const handelCrad = (subscriptionPlan) => {
    dispatch(setSelectedPlanDetails(subscriptionPlan));
    dispatch(walletPrice(`{ "wallet_prices": ${walletbox?.wallet}, "cut_amount": 0}`));
    if (dispatch(setSelectedPlanDetails(subscriptionPlan))) {
      dispatch(walletPrice(`{ "wallet_prices": ${walletbox?.wallet}, "cut_amount": 0}`));
      setFieldValue("selectedPlanName", null);
      setFieldValue("subscription_plan_id", subscriptionPlan?.id);
      setFieldValue("plan_type", "new");
      setFieldValue("termsAndConditions", false);
      setFieldValue("total_amount", subscriptionPlan?.discounted_price);
      setFieldValue("total_paid_amount", subscriptionPlan.discounted_price);
      setFieldValue("discounted_price", parseFloat(subscriptionPlan.discounted_price));
      setFieldValue("discounted_pricess", parseFloat(subscriptionPlan.discounted_price));
      setFieldValue("gold_discount", subscriptionPlan.total_paid_amount - ((subscriptionPlan?.discounted_price * goldMembershipStatus?.discount / 100)));
    }
  }
  useEffect(() => {
    if (!values.total_paid_amount) {
      subScriptionPlanList?.map((list) => {
        if (list?.title === "One Time Consultations") {
          handelCrad(list)
        }
      })
    }
    if (props?.doctorData?.fee_details?.consultation_type === 1) {
      setFieldValue("discounted_price", props?.doctorData?.fee_details?.consulting_fee)
      setFieldValue("consultation_type",props?.doctorData?.fee_details?.consultation_type )
      setFieldValue("consulting_fee",props?.doctorData?.fee_details?.consulting_fee )
    } else {
      setFieldValue("discounted_price", props?.doctorData?.fee_details?.follow_up_fee);
      setFieldValue("follow_up_fee",props?.doctorData?.fee_details?.follow_up_fee )
      setFieldValue("consultation_type",2 )
    }
  }, [dispatch])

  const getDiscount = (cupon) => {
    if (cupon?.coupon_percentage) {
      setFieldValue(
        "total_paid_amount",
        selectedPlanDetails?.discounted_price -
        (selectedPlanDetails?.discounted_price * Number(cupon?.coupon_percentage)) / 100
      );
    }
  };
  useEffect(() => {
    if (couponCodeDetail && selectedPlanDetails?.total_consultunt === 1) {
      setFieldValue(
        "total_paid_amount",
        selectedPlanDetails?.discounted_price -
        (selectedPlanDetails?.discounted_price *
          Number(couponCodeDetail?.coupon_percentage)) /
        100
      );
    }
  }, [couponCodeDetail]);
  // useEffect(() => {
  //   if (!values.total_paid_amount) {
  //     dispatch(ConsultionCard({
  //       type: "1",
  //       speciality_id: localStorage.getItem("specialityId"),
  //       health_problem_ids: localStorage.getItem("selectedHealthId"),
  //       doctor_id: values?.doctor_id
  //     }))
  //   }
  // }, [dispatch])
  const cardBackgroundImages = [img2, img3, img6, img7];

  useEffect(() => {
    if (followUpPassData) {
      setFieldValue("health_problem_ids", followUpPassData?.health_problem_id);
      setFieldValue("slot_id", followUpPassData?.slot_id);
      setFieldValue("doctor_id", followUpPassData?.doctor_id);
      setFieldValue(
        "consultation_member_id",
        followUpPassData?.consultation_member_id
      );
      setFieldValue("appointment_date", followUpPassData?.appointment_date);
      setFieldValue("appointment_time", followUpPassData?.appointment_time);
      setFieldValue("payment_type", followUpPassData?.payment_type);
      setFieldValue("speciality_id", followUpPassData?.speciality_id);
      setFieldValue(
        "follow_up_booking_id",
        followUpPassData?.follow_up_booking_id
      );
    }

  }, [followUpPassData]);
  useEffect(() => {
    if (values?.consultation_member_id) {
      memberList?.length &&
        memberList?.filter(function (item) {
          if (item?.id === values?.consultation_member_id) {
            setSelectedNewMember(item);
          }
        });
    }
  }, [
    values?.consultation_member_id,
    followUpPassData?.consultation_member_id,
  ]);

  const updateState = (newValue) => {
    setBookingSuccessModel(newValue);
  };

  const [dataModel, SetDataModel] = useState(false);

  const corporate = localStorage.getItem("corporate");
  console.log("wallet_pricewallet_price", goldMembershipdetail);
  const data = wallet_price
  const walletPrices = data.wallet_prices !== undefined && wallet_price && JSON.parse(data);
  const formattedWalletPrice = `${parseFloat(walletPrices?.wallet_prices || 0).toFixed(0)}`;



  return (
    <>
      <StateContext.Provider value={{ bookingSuccessModel, updateState }}>
        {cretaBookConsultant?.data && <BookingSuccessModel
          show={true}
          onHide={() => updateState(false)}
          cretaBookConsultant={cretaBookConsultant}
        />}
        {
          props.doctorData ? "" : <>
            {subscribedPlansList && (
              <SelectSubscribedPlansModel
                show={selectedPlan}
                onHide={() => dispatch(SelectedPlan(false))}
                subscriptionPlanInitialState={SubscriptionPlanInitialState}
                subscribedPlansList={subscribedPlansList}
              />
            )}</>
        }

        <MyModal_1
          selectedsubscriptionPlan={selectedPlanDetails}
          show={modalShow1}
          memberList={memberList}
          setSelectedNewMember={setSelectedNewMember}
          onHide={(e) => {
            setModalShow1(false);
            e === true && setNewMembers(true);
          }}
        />
        <AddMember
          addEditMemberinitialState={addMemberinitialState}
          addEditMember={AddNewMembers}
          show={newMembers}
          onHide={() => setNewMembers(false)}
        />
        <ApplyCouponModel2
          couponList={couponList}
          show={applyCoupon}
          onHide={() => setApplyCoupon(!couponList)}
          couponType={1}
          getDiscount={getDiscount}
        />
        <div className="subscription_body_box_card_head">
          <div className="row">
            <div className="col-md-12">
              <h3 className="chat_body_box_title mt_10">
                Select Subscription Plan
              </h3>
            </div>
          </div>
        </div>
        <>
          <div className="subscription_body_box_card_body">
            <div className="subscription_body_box">
              <div className="subscription_inner_1">
                <>
                  {
                    props?.doctorData ? <>
                      <div className="subscription_plan_row">
                        <div
                          className="subscription_plan_cards"
                          style={{
                            backgroundImage: `url(${img2})`,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        // onClick={() => { handelCrad(subscriptionPlan) }}
                        >
                          <div>
                            <div style={{ textAlign: "center", color: "#FFFFFF", text: "20pz" }}>
                              {props.doctorData?.fee_details && props.doctorData.fee_details.consultation_type === 1 ?
                                <>Share My Link <br />
                                  ₹{props.doctorData.fee_details.consulting_fee}</>
                                :
                                <>follow_up_fee <br />
                                  ₹{props.doctorData.fee_details.follow_up_fee}</>
                              }
                            </div>
                          </div>
                        </div>
                      </div>

                    </> : <>
                      {
                        corporate == "false" ?
                          <>
                            {
                              props?.cardHome?.btob_subscriptions && <>
                                <div
                                  className="subscription_plan_cards"
                                  onClick={() => {
                                    dispatch(setSelectedPlanDetails(props?.cardHome?.btob_subscriptions));
                                    if (dispatch(setSelectedPlanDetails(props?.cardHome?.btob_subscriptions))) {
                                      setFieldValue("selectedPlanName", null);
                                      setFieldValue("subscription_plan_id", props?.cardHome?.btob_subscriptions?.id);
                                      setFieldValue("plan_type", "new");
                                      setFieldValue("termsAndConditions", false);
                                      setFieldValue("total_amount", props?.cardHome?.btob_subscriptions.price);
                                      setFieldValue("total_paid_amount", props?.cardHome?.btob_subscriptions.price);
                                      setFieldValue("discounted_price", props?.cardHome?.btob_subscriptions.discounted_price)
                                      setFieldValue("discounted_pricess", props?.cardHome?.btob_subscriptions.discounted_price)

                                    }
                                  }}
                                >

                                  <div className="price" style={{ padding: "10px" }}>
                                    <div>
                                      <h5 className="subscription_plan_price">
                                        {props?.cardHome?.btob_subscriptions.discounted_price == 0 ? "Free" : `₹ ${props?.cardHome?.btob_subscriptions.discounted_price}`}
                                      </h5>
                                      <h5
                                        className="subscription_plan_allprice"
                                        style={{ marginBottom: "0px" }}
                                      >
                                        {props?.cardHome?.btob_subscriptions.price == 0 ? "" : `₹ ${props?.cardHome?.btob_subscriptions.price}`}
                                      </h5>
                                    </div>
                                    <div className="">
                                      {values?.subscription_plan_id === props?.cardHome?.btob_subscriptions?.id && (
                                        <img src={Icon.true} alt="True Icon" />
                                      )}
                                    </div>
                                  </div>
                                  <div className="subscription_plan_box pt_10 pl_5">
                                    <h3 className="subscription_plan_title">
                                      {props?.cardHome?.btob_subscriptions?.title}
                                    </h3>
                                    <h5 className="subscription_plan_subtitle">
                                      {props?.cardHome?.btob_subscriptions?.description}
                                    </h5>
                                  </div>
                                </div>
                              </>}
                          </> :
                          <>
                            {
                              subScriptionPlanList?.length > 0 ? (
                                <>
                                  <div className="subscription_plan_row">
                                    {subScriptionPlanList && subScriptionPlanList?.map((subscriptionPlan, index) => (
                                      <>
                                        <div
                                          className={`subscription_plan_card ${values.selectedPlanName
                                            ? null
                                            : values?.subscription_plan_id === subscriptionPlan?.id
                                              ? ""
                                              : null
                                            } mb_10`}
                                          key={index}
                                          style={{
                                            backgroundImage: `url('${cardBackgroundImages[index % cardBackgroundImages.length]}')`,
                                            backgroundSize: '100%',
                                            backgroundRepeat: 'no-repeat',
                                          }}
                                          onClick={() => { handelCrad(subscriptionPlan) }}
                                        >
                                          <div className="price" >
                                            <div>
                                              <h5 className="subscription_plan_price">
                                                ₹{subscriptionPlan.discounted_price}
                                              </h5>
                                              <h5
                                                className="subscription_plan_allprice"
                                                style={{ marginBottom: "0px" }}
                                              >
                                                ₹{subscriptionPlan.price}
                                              </h5>
                                            </div>
                                            <div className="">
                                              {values?.subscription_plan_id === subscriptionPlan?.id && (
                                                <img src={Icon.true} alt="True Icon" />
                                              )}
                                            </div>
                                          </div>
                                          <div className="subscription_plan_box pt_10" style={{ paddingLeft: "15px", paddingRight: "10px" }}>
                                            <h3 className="subscription_plan_title">
                                              {subscriptionPlan?.title}
                                            </h3>
                                            <h5 className="subscription_plan_subtitle">
                                              {subscriptionPlan?.description}
                                            </h5>
                                          </div>
                                        </div>
                                      </>
                                    ))}
                                  </div>
                                </>
                              ) : ""}
                          </>
                      }

                    </>
                  }
                  {/* 
                  {errors?.subscription_plan_id &&
                    touched?.subscription_plan_id ? (
                    <div className="terms_conditions_error_text">
                      {errors.subscription_plan_id}
                    </div>
                  ) : null} */}
                </>
                {!values?.follow_up_booking_id && (
                  <>
                    <h5 className="subscription_consultation_2">
                      Consultation for
                    </h5>
                    <div
                      className="subscrip_consult_card"
                      onClick={() => setModalShow1(true)}
                    >
                      <div>
                        <h5 className="subscrip_consult_card_text">
                          Select members for consultations
                        </h5>
                      </div>

                      <div>
                        <svg
                          width="8"
                          height="14"
                          className="subscrip_consult_card_arrow"
                          viewBox="0 0 8 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1.25 12.5L6.75 7L1.25 1.5"
                            stroke="#9393AA"
                            strokeWidth="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                      {errors?.consultation_member_id &&
                        touched?.consultation_member_id ? (
                        <div className="terms_conditions_error_text">
                          {errors.consultation_member_id}
                        </div>
                      ) : null}
                    </div>
                  </>
                )}

                {selectedNewMember && (
                  <>
                    <div className="consult_member_modal_box p-2 ">
                      <div className="consult_member_modal_box_inner">
                        <div>
                          <div className="wallet_text">
                            <h5 className="consult_members_title">
                              {selectedNewMember?.name}{" "}
                              <span className="consult_members_subtitle">
                                ({selectedNewMember?.gender}, Age:
                                {selectedNewMember?.age} )
                              </span>
                            </h5>
                          </div>
                        </div>
                        <div>
                          <div className="healthpackage">
                            <input
                              id="checkbox12"
                              type="checkbox"
                              className="vh"
                              name="checkbox12"
                              checked={true}
                            />
                            <label
                              className="healthpackage_checkbox_title"
                              for="checkbox12"
                              style={{
                                display: "inline-flex",
                                alignItems: "center",
                              }}
                            ></label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div className="subscription_inner_2 ">
                {selectedPlanDetails?.total_consultunt === 1 ? (
                  <>
                    <h5 className="offer_benifit_text">Offers & Benifits</h5>
                    <div
                      className="offer_benifit_card"
                      onClick={() => setApplyCoupon(true)}
                    >
                      <div className="apply_coupon_box">
                        <img
                          src={Icon.coupon}
                          className="apply_coupon_icon"
                          alt=""
                        ></img>
                        <h5 className="offer_benifit_text">
                          {couponCodeDetail
                            ? couponCodeDetail.coupon_title
                            : "Apply Coupon"}
                        </h5>
                      </div>
                      <div>
                        <svg
                          width="8"
                          height="14"
                          className="subscrip_consult_card_arrow"
                          viewBox="0 0 8 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1.25 12.5L6.75 7L1.25 1.5"
                            stroke="#9393AA"
                            strokeWidth="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                    </div>
                  </>
                ) : null}


                <h5 className="payment_summary">Payment Summary</h5>
                <div className="payment_summary_card">
                  <div className="payment_summary_card_inner mb_5">
                    <div>
                      <h5 className="payment_summary_title">Total amount</h5>
                    </div>
                    <div>
                      <h5 className="payment_summary_price">
                        ₹{values?.discounted_price ? values?.discounted_price : "0"}
                      </h5>
                    </div>
                  </div>

                  {couponCodeDetail &&
                    selectedPlanDetails?.total_consultunt === 1 && (
                      <div className="payment_summary_card_inner">
                        <div>
                          <h5 className="payment_summary_title">Discount</h5>
                        </div>
                        <div>
                          <h5 className="payment_summary_price_discount">
                            -₹{" "}

                            {(couponCodeDetail ? (values?.discounted_price - values?.total_paid_amount) : values?.discounted_price ? values?.discounted_price : 0).toFixed(0)}
                          </h5>
                        </div>
                      </div>
                    )}
                  {
                    props?.doctorData ? <></> : <>
                      {
                        goldMembershipdetail !== true ? (
                          <>
                            <div className="payment_summary_card_inner">
                              <div>
                                <h5 className="payment_done_payment_info_title" style={{ color: "#48988E" }}>
                                  Gold Discount [{goldMembershipStatus?.discount}%]
                                </h5>
                              </div>
                              <div>
                                <h5 className="payment_summary_price_discount">
                                  -{" "}
                                  {isNaN(values?.discounted_price * goldMembershipStatus?.discount / 100)
                                    ? "0"
                                    : (values?.discounted_price * goldMembershipStatus?.discount / 100).toFixed(0)}
                                </h5>
                              </div>
                            </div>
                          </>
                        ) : null
                      }

                    </>
                  }

                  <hr className="payment_hr" />
                  <div className="payment_summary_card_inner">
                    <div>
                      <h5 className="payment_summary_total_title">
                        Total Payable
                      </h5>
                    </div>
                    <div>
                      <h5 className="payment_summary_total_price">
                        {
                          props?.doctorData ? (
                            // Render based on doctorData
                            <>
                              {props?.doctorData?.fee_details?.consultation_type === 1 ? (
                                // Render consulting_fee
                                <>₹{props?.doctorData?.fee_details?.consulting_fee}</>
                              ) : (
                                // Render follow_up_fee
                                <>₹{props?.doctorData?.fee_details?.follow_up_fee}</>
                              )}
                            </>
                          ) : (
                            // Render based on other conditions
                            <>
                              {goldMembershipdetail !== true ? (
                                // Calculate and render based on total_paid_amount and discount
                                <>₹{(
                                  values.total_paid_amount - (values?.discounted_price * goldMembershipStatus?.discount / 100) < 0
                                    ? 0
                                    : values.total_paid_amount - (values?.discounted_price * goldMembershipStatus?.discount / 100)
                                ).toFixed(0)}</>
                              ) : (
                                // Calculate and render based on other conditions
                                <>₹{(
                                  isNaN(values?.discounted_pricess - (couponCodeDetail ? (values?.discounted_price - values?.total_paid_amount) : 0))
                                    ? 0
                                    : values?.discounted_pricess - (couponCodeDetail ? (values?.discounted_price - values?.total_paid_amount) : 0)
                                ).toFixed(0)}</>
                              )}
                            </>
                          )
                        }




                      </h5>

                    </div>
                  </div>
                </div>
                <div className="wallet_card">
                  <div>
                    <div className="form-group">
                      <input
                        type="checkbox"
                        id={walletbox.id}
                        onChange={handleChange}
                        onClick={() => { setCheckedVal(!checkedVal) }}
                        name={userWallet?.wallet}
                        value={walletbox}
                        checked={checkedVal}
                      />
                      <label className="wallet_text" for={walletbox.id}>
                        <span className="wallet_subtext">
                          Use wallet Amount
                        </span>
                      </label>
                    </div>
                  </div>
                  <div>
                    <h5 className="available_bal_text">
                      Avbl. bal.
                      <span className="available_bal_price">

                        ₹{formattedWalletPrice}
                      </span>
                    </h5>
                  </div>
                </div>
                <h5 className="T_C_title pt_10">Terms and conditions</h5>
                <div className="T_C_card">
                  <p className="T_C_text">
                    Before you book consultation, make sure you are aware about
                    THS terms and conditions.
                  </p>
                  <div className="col-md-12">
                    <div>
                      <div className="healthpackage" >
                        <input
                          id="checkbox1"
                          type="checkbox"
                          className="vh"
                          name="termsAndConditions"
                          onChange={() =>
                            setFieldValue(
                              "termsAndConditions",
                              !values.termsAndConditions
                            )
                          }
                          onClick={() => SetDataModel(true)}
                        />
                        <label
                          className="healthpackage_checkbox_title"
                          htmlFor="checkbox1"

                          style={{
                            display: 'inline-flex',
                            alignItems: 'center',
                          }}
                        >
                          {" "}
                          <span
                            className="i_agree_cancellation_policy"
                            style={{ padding: "25px" }}
                          >
                            <h5 className="view_T_C_text" onClick={handleCheckboxClick}>
                              View Terms And Conditions
                            </h5>
                          </span>
                        </label>
                      </div>
                      {errors?.termsAndConditions &&
                        touched?.termsAndConditions && (
                          <div className="terms_conditions_error_text">
                            {errors?.termsAndConditions}
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="book_consult_card">
            <div className="book_consult_card_box">
              <div className="book_consult_price_box">
                <h5 className="book_consult_price">
                  {/* {
                    goldMembershipdetail !== true ? (
                      <>
                        ₹{(
                          (values.total_paid_amount - ((values?.discounted_price * goldMembershipStatus?.discount / 100)) < 0
                            ? 0
                            : values.total_paid_amount - ((values?.discounted_price * goldMembershipStatus?.discount / 100)))
                        ).toFixed(0)}

                      </>
                    ) : (
                      <>
                        ₹ {(
                          isNaN(values?.discounted_pricess - (couponCodeDetail ? (values?.discounted_price - values?.total_paid_amount) : 0))
                            ? 0
                            : (values?.discounted_pricess - (couponCodeDetail ? (values?.discounted_price - values?.total_paid_amount) : 0))
                        ).toFixed(0)}
                      </>
                    )
                  } */}
                  {
                    props?.doctorData ? (
                      // Render based on doctorData
                      <>
                        {props?.doctorData?.fee_details?.consultation_type === 1 ? (
                          // Render consulting_fee
                          <>₹{props?.doctorData?.fee_details?.consulting_fee}</>
                        ) : (
                          // Render follow_up_fee
                          <>₹{props?.doctorData?.fee_details?.follow_up_fee}</>
                        )}
                      </>
                    ) : (
                      // Render based on other conditions
                      <>
                        {goldMembershipdetail !== true ? (
                          // Calculate and render based on total_paid_amount and discount
                          <>₹{(
                            values.total_paid_amount - (values?.discounted_price * goldMembershipStatus?.discount / 100) < 0
                              ? 0
                              : values.total_paid_amount - (values?.discounted_price * goldMembershipStatus?.discount / 100)
                          ).toFixed(0)}</>
                        ) : (
                          // Calculate and render based on other conditions
                          <>₹{(
                            isNaN(values?.discounted_pricess - (couponCodeDetail ? (values?.discounted_price - values?.total_paid_amount) : 0))
                              ? 0
                              : values?.discounted_pricess - (couponCodeDetail ? (values?.discounted_price - values?.total_paid_amount) : 0)
                          ).toFixed(0)}</>
                        )}
                      </>
                    )
                  }
                </h5>
                <h6 className="book_consult_subtitle">Amount to pay</h6>
              </div>
              <div>
                <Button className="book_consult_btn" type="submit">
                  Book Now
                </Button>
              </div>
            </div>
          </div>
        </>
      </StateContext.Provider>
    </>
  );
}

const MyModal_1 = (props1) => {
  const { values, setFieldValue, handleBlur } = useFormikContext();

  const [isCheck, setIsCheck] = useState();
  const [check, notCheck] = useState(false);

  const handleClick = (e) => {
    const { id, checked } = e.target;
    const newNumber = Number(id);
    setIsCheck({ ...isCheck, newNumber });
    props1?.memberList?.filter(function (item) {
      if (newNumber == item?.id) {
        setFieldValue("consultation_member_id", item?.id);
        props1.setSelectedNewMember(item);
      }
    });


    if (!checked) {
      props1?.memberList?.filter(function (item) {
        if (newNumber == item?.id) {
          props1.setSelectedNewMember("");
          setFieldValue("consultation_member_id", "");
          setIsCheck("");
        }
      });
    }
  };
  return (
    <Modal
      {...props1}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="modal_500"
    >
      <Modal.Header style={{ border: "none" }}>
        <h5 className="select_consult_member_modal_title">Subscription for</h5>
        <img
          src={Icon.cross}
          className="book_modal_close"
          onClick={props1.onHide}
          alt=""
        ></img>
      </Modal.Header>
      <Modal.Body>
        <div>

          <div className="select_member_box_2">
            <div style={{ height: "300px", overflow: "auto" }}>
              {props1?.memberList.length &&
                props1?.memberList?.map((member, index) => (
                  <>
                    <div key={index} className="consult_member_modal_box" onClick={(e) => { notCheck(!check); handleClick(e); }}>
                      <div className="consult_member_modal_box_inner">
                        <div className="consult_member_modal_box_inner_img">
                          {
                            member?.image == null ? <>
                              <img src={Icon.docprofile}></img></> : <> <img src={member?.image} style={{ borderRadius: "50%", height: "50px", width: "50px" }}></img></>
                          }
                          <div>
                            <label className="wallet_text" for="consult_member">
                              <h5 className="consult_members_title">
                                {member.name}{" "}
                              </h5>
                              <span className="consult_members_subtitle">
                                ({member.gender}, Age:{member.age} )
                              </span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="consult_mem">
                          <input
                            // id={member.id}
                            // className="vh"
                            // name="consultation_member_id"
                            // type="checkbox"
                            // onChange={handleClick}
                            // onBlur={handleBlur}
                            // checked={
                            //   values.consultation_member_id === member?.id
                            // }
                            id={member.id}
                            className="vh"
                            name="consultation_member_id"
                            type="checkbox"
                            onChange={handleClick}
                            onBlur={handleBlur}
                            checked={
                              values.consultation_member_id === member?.id
                            }
                            onClick={(e) => e.stopPropagation()}
                          />
                          <label
                            className="wallet_text"
                            for={member.id}

                          ></label>

                        </div>
                      </div>
                    </div>
                    <hr className="consult_member_hr" />
                  </>
                ))}
            </div>
            <div className="add_member_box">
              <img src={Icon.add} className="add_member_icon" alt=""></img>
              <h5
                className="add_member_text"
                onClick={() => {
                  props1.onHide(true);
                }}
              >
                Add New Member
              </h5>
            </div>
          </div>
          <center>
            <Button
              type="submit"
              className="consult_modal_btn"
              onClick={props1.onHide}
            >
              Continue
            </Button>
          </center>
        </div>
      </Modal.Body>
      <Modal.Footer style={{ border: "none", display: "none" }}></Modal.Footer>
    </Modal>
  );
};


const MyModal_2 = (props1) => {

  const { values, setFieldValue, handleBlur } = useFormikContext();
  const { bookingSuccessModel, updateState } = useContext(StateContext);
  const [curModal, setCurModal] = useState(true);
  const [isCheck, setIsCheck] = useState();
  const dispatch = useDispatch();
  const {
    cretaBookConsultant,
  } = useSelector(({ BookingSlice }) => BookingSlice);

  const handleClick = (e) => {
    const { id, checked } = e.target;
    const newNumber = Number(id);
    setIsCheck({ ...isCheck, newNumber });
    props1?.memberList?.filter(function (item) {
      if (newNumber == item?.id) {
        setFieldValue("consultation_member_id", item?.id);
        props1.setSelectedNewMember(item);
      }
    });

    if (!checked) {
      props1?.memberList?.filter(function (item) {
        if (newNumber == item?.id) {
          props1.setSelectedNewMember("");
          setFieldValue("consultation_member_id", "");
          setIsCheck("");
        }
      });
    }
  };



  const createBooking = (values) => {
    let selectPlan = {
      health_problem_ids: localStorage.getItem("selectedHealthId"),
      slot_id: values.slot_id,
      doctor_id: values.doctor_id,
      subscription_plan_id: values?.selectedPlanName?.id,
      consultation_member_id: values?.consultation_member_id ? values?.consultation_member_id : 0,
      total_amount: 0,
      total_paid_amount: 0,
      appointment_date: values?.appointment_date,
      appointment_time: values?.appointment_time,
      payment_type: values?.payment_type,
      plan_type: values.plan_type,
      termsAndConditions: values.termsAndConditions,
      speciality_id: values.speciality_id,
      credit: 1,
      payment_type: 2,
    };
    dispatch(BookConsultant(selectPlan)).then((res) => {

      if (res.payload.status_code == 200 && res.payload.success == true) {

        dispatch(UpcomingBookingList());
        dispatch(clearSelectedCouponCode());
        dispatch(clearSelectedPlanDetails());
        dispatch(clearSelectedPlan());
        dispatch(clearFollowUpPassData());
        dispatch(cleaSelectedDate());
      }
    });
  };

  return (
    <>

      {curModal && <Modal
        {...props1}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="modal_500"
      >
        <Modal.Header style={{ border: "none" }}>
          <h5 className="select_consult_member_modal_title">Subscription for</h5>
          <img
            src={Icon.cross}
            className="book_modal_close"
            onClick={props1.onHide}
            alt=""
          ></img>
        </Modal.Header>
        <Modal.Body>
          <div>

            <div className="subscription_plan_modal_card1 mb_10">
              {values.selectedPlanName ? (
                <>
                  <p className="subscription_title">Unlimited Consultation</p>
                  <p className="subscription_desc">You can add total {values.selectedPlanName.plan_details.unlimited_consultations} members in this package</p>
                </>
              ) : null}
            </div>
            <div className="select_member_box">
              <h5 className="select_member_title">Select members</h5>
              {/* <h5 className="select_member_subtitle">
              Select 10 members to add in this subscription. You can able add
              later also.
            </h5> */}
            </div>
            <div className="select_member_box_2">
              <div style={{ height: "300px", overflow: "auto" }}>
                {props1?.memberList.length &&
                  props1?.memberList?.map((member, index) => (
                    <>
                      <div key={index} className="consult_member_modal_box">
                        <div className="consult_member_modal_box_inner">
                          <div className="d-flex" style={{ gap: "10px" }}>
                            <div>
                              {
                                member?.image == null ? <>
                                  <img src={Icon.docprofile}></img></> : <> <img src={member?.image} style={{ borderRadius: "50%", height: "50px", width: "50px" }}></img></>
                              }
                            </div>
                            <label className="wallet_text" for="consult_member">
                              <h5 className="consult_members_title">
                                {member.name}{" "}<br />
                                <span className="consult_members_subtitle">
                                  ({member.gender}, Age:{member.age} )
                                </span>
                              </h5>
                            </label>
                          </div>

                        </div>
                        <div>
                          <div className="consult_mem">
                            <input
                              id={member.id}
                              className="vh"
                              name="consultation_member_id"
                              type="checkbox"
                              onChange={handleClick}
                              onBlur={handleBlur}
                              checked={
                                values.consultation_member_id === member?.id
                              }
                            />

                            <label
                              className="wallet_text"
                              for={member.id}
                            ></label>
                          </div>
                        </div>
                      </div>
                      <hr className="consult_member_hr" />
                    </>
                  ))}
              </div>
              <div className="add_member_box">
                <img src={Icon.add} className="add_member_icon" alt=""></img>
                <h5
                  className="add_member_text"
                  onClick={() => {
                    props1.onHide(true);
                  }}
                >
                  Add New Member
                </h5>
              </div>
            </div>
            <center>
              <Button
                className="consult_modal_btn"
                onClick={(e) => { e.preventDefault(); createBooking(values) }}
              // onClick={() => { setCurModal(false); }}
              >
                Continue
              </Button>
            </center>
          </div>
        </Modal.Body>
        <Modal.Footer style={{ border: "none", display: "none" }}></Modal.Footer>
      </Modal>}
    </>
  );
};


const SelectSubscribedPlansModel = (props1) => {
  const dispatch = useDispatch();
  const [modalShow1, setModalShow1] = useState(false);
  const { values, setFieldValue } = useFormikContext();
  const [newPlane, oldPlane] = useState(true);
  const [selectedNewMember, setSelectedNewMember] = useState(null);
  const [newMembers, setNewMembers] = useState(false);
  const [currentModalVisible, setCurrentModalVisible] = useState(true);


  const {
    selectedPlanDetails,
  } = useSelector(({ BookingSlice }) => BookingSlice);
  const { userWallet, memberList } = useSelector(
    ({ ProfileSlice }) => ProfileSlice
  );
  return (
    <>
      {modalShow1 && <MyModal_2
        selectedsubscriptionPlan={selectedPlanDetails}
        show={modalShow1}
        memberList={memberList}
        setSelectedNewMember={setSelectedNewMember}
        onHide={(e) => {
          setModalShow1(false);
          setCurrentModalVisible(false)
          e == true && setNewMembers(true);
        }}
      />}

      {currentModalVisible && <Modal
        {...props1}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="book_modal_531"
      >
        <Modal.Header
          className=""
          style={{ marginBottom: "none", border: "none" }}
        >
          <div>
            <h5 className="select_subscription_plan">Select Subscription Plan</h5>
            <h5 className="select_subscription_plan_subtext">
              You have already pruchased plan
            </h5>
          </div>
          <img
            src={Icon.cross}
            className="book_modal_close"
            alt=""
            onClick={() => props1.onHide()}
          ></img>
        </Modal.Header>
        <Modal.Body>
          <div className="select_subscription_plan_option_box">
            <div className="select_member_box_3">

              <div className="consult_member_box_1">
                {props1?.subscribedPlansList.length &&
                  props1?.subscribedPlansList?.map((subPlan, index) => (
                    <>
                      <div className="consult_member_box_inner" key={index}>
                        <label htmlFor={`radio-${index}`} className="consult_member_label">
                          {/* Add a label with a 'for' attribute that associates with the radio button */}
                          <div>
                            <input
                              type="radio"
                              id={`radio-${index}`}
                              name="radio-group"
                              className="mt_10"
                              value={subPlan}
                              checked={values.selectedPlanName === subPlan}
                              onChange={() => {
                                setFieldValue("selectedPlanName", subPlan);
                                setFieldValue("subscription_plan_id", subPlan?.id);
                                setFieldValue("total_amount", values?.consulting_fee);
                                setFieldValue("plan_type", "old");
                                setFieldValue("total_paid_amount", values?.consulting_fee);
                              }}
                              style={{ accentColor: "#199a8e" }}
                            />
                            <div>
                              <h5 className="consult_members_title_1">
                                {subPlan?.plan_details?.title} <br />
                                <span className="consult_members_subtitle_1">
                                  (
                                  {`${subPlan?.left_consultations === 0
                                    ? "unlimited"
                                    : subPlan?.left_consultations
                                    }`}{" "}
                                  Consultation left)
                                </span>
                              </h5>
                            </div>
                          </div>
                        </label>
                      </div>
                      <hr className="consult_member_hr" />
                    </>
                  ))}
                <div className="consult_member_box_1">
                  <div className="consult_member_box_inner_2" style={{ marginTop: "3px" }}>
                    <label htmlFor="test1">
                      <input
                        type="radio"
                        id="test1"
                        name="radio-group"
                        value={false}
                        checked={values.selectedPlanName === false}
                        onChange={() => {
                          setFieldValue("selectedPlanName", false);
                          oldPlane(false);
                        }}
                        style={{ accentColor: "#199a8e" }}
                      />
                      <h5 className="consult_members_title_1">
                        Purchase a new plan
                      </h5>
                    </label>
                  </div>
                </div>

              </div>
              <center>
                <Button
                  type="submit"
                  className="select_subscription_plan_continue_btn"
                  // onClick={() =>  setSuccessfullModel(true)}
                  onClick={() => {
                    if (newPlane) {
                      setCurrentModalVisible(false);  // Close the current modal
                      setModalShow1(true);
                    } else {
                      dispatch(SelectedPlan(false))
                    }

                    // dispatch(ConsultionCard({
                    //   type: "1",
                    //   speciality_id: localStorage.getItem("specialityId"),
                    //   health_problem_ids: localStorage.getItem("selectedHealthId"),
                    //   doctor_id: values?.doctor_id
                    // }))
                  }}

                >
                  Continue
                </Button>
              </center>
            </div>
          </div>
        </Modal.Body>
      </Modal>}

    </>

  );
};
export default SubscriptionPlan;
