import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { UploadFileAPI } from "../../Components/Common/Service";
import { GetBannerListAPI, GetCityAPI, GetBannerListForNonLoggedUserAPI } from "../../Routes/Service";
import { AlertEnum } from "../../Utilities/Enums";
import { setLoading, setMessage } from "./LayoutsSice";

const initialState = {
  healthProblems: [],
  activeKey: "",
  bannerList: [],
  specialityhealth: "",
};

export const GetCity = createAsyncThunk(
  "GetCity",
  async (values, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const result = await GetCityAPI(values);
      if (result?.success) {
        let tempCity = [];
        result?.data?.map((item) =>
          tempCity.push({ label: item.name, value: item.id })
        );
        dispatch(setLoading(false));

        return tempCity;
      } else {
        throw result;
      }
    } catch (error) {
      dispatch(setLoading(false));
      // dispatch(
      //   setMessage({
      //     text: error?.message,
      //     type: AlertEnum.Error,
      //   })
      // );
      return error;
    }
  }
);

export const GetBannerList = createAsyncThunk(
  "GetBannerList",
  async (values, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      var result = {}
      if (localStorage.getItem("TOKEN") != undefined)
        result = await GetBannerListAPI(values);
      else
        result = await GetBannerListForNonLoggedUserAPI(values);
      if (result?.success) {
        dispatch(setLoading(false));

        return result?.data;
      } else {
        throw result;
      }
    } catch (error) {
      dispatch(setLoading(false));
      // dispatch(
      //   setMessage({
      //     text: error?.message,
      //     type: AlertEnum.Error,
      //   })
      // );
      return error;
    }
  }
);

export const UploadFile = createAsyncThunk(
  "UploadFile",
  async (values, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const result = await UploadFileAPI(values);
      if (result?.success) {
        dispatch(setLoading(false));
        return result;
      } else {
        throw result;
      }
    } catch (error) {
    
      dispatch(setLoading(false));
      // dispatch(
      //   setMessage({
      //     text: error?.message,
      //     type: AlertEnum.Error,
      //   })
      // );
      return error;
    }
  }
);

export const CommonSlice = createSlice({
  name: "CommonSlice",
  initialState,
  reducers: {
    setActiveKey: (state, action) => {
      state.activeKey = action?.payload;
    },
    setSpecialityhealthModel: (state, action) => {
      state.specialityhealth = action?.payload;
    },
    setClearSpecialityhealthModel: (state, action) => {
      state.specialityhealth = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(GetCity.fulfilled, (state, action) => {
      state.city = action.payload;
    });
    builder.addCase(GetBannerList.fulfilled, (state, action) => {
      state.bannerList = action.payload;
    });
  },
});

export const {
  setSession,
  setActiveKey,
  removeSession,
  setSpecialityhealthModel,
  setClearSpecialityhealthModel,
} = CommonSlice.actions;

export default CommonSlice.reducer;
