import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Icon, Logo } from "../../../Utilities/Icons";
import { GoldMemberBookingDetails } from "../../../Store/Reducer/LabtestSlice";
import { GetMember } from "../../../Store/Reducer/ProfileReducer";
import { useDispatch, useSelector } from "react-redux";
import { GoldMemberBoking, GoldMemberBokingstatus, SpecialityBoking } from "../../../Store/Reducer/RadiologySlice";
import Congratulations from './Congratulations'
import { Navigate } from "react-router-dom";

const MemberShip = (props) => {
    const dispatch = useDispatch();
    const [sucessmodal, setSucessmodal] = useState(false);
    const { memberList } = useSelector(
        ({ ProfileSlice }) => ProfileSlice
    );
    const {
        cards
    } = useSelector(({ BookingSlice }) => BookingSlice);
    const { radiologyUpcomingBookingsList, goldMembershipStatus } = useSelector(
        ({ RadiologySlice }) => RadiologySlice
      );
    
    useEffect(() => {
        dispatch(GetMember());
    }, [dispatch])
    const memberIdList = memberList.length > 0 && memberList?.map(member => member.id).join(",");
    useEffect(() => {
        let id = cards?.gold_membership?.id
        dispatch(GoldMemberBokingstatus(id));
    }, [dispatch])
    const { goldMembershipdetail } = useSelector(
        ({ LabtestSlice }) => LabtestSlice
    );

    const { session } = useSelector(({ AuthSlice }) => AuthSlice);
    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://checkout.razorpay.com/v1/checkout.js";
        script.async = true;
        document.body.appendChild(script);
    }, []);
    const options = {
        key: process.env.REACT_APP_PAYMENT_KEY,
        key_secret: process.env.REACT_APP_PAYMENT_SECRET_KEY,
        amount: "100", //cretaBookConsultant?.data?.total_price, //  = INR 1
        name: "THS-User",
        description: "some description",
        image: Logo.THS_logo,
        handler: function (response) {
            if (response) {
                setSucessmodal(true);
            }
        },
        prefill: {
            name: session?.name,
            contact: session?.mobile_number,
            email: session?.email,
        },
        notes: {
            address: "some address",
        },
        theme: {
            color: "#F37254",
            hide_topbar: false,
        },
    };
    const openPayModal = (options) => {
        var rzp1 = new window.Razorpay(options);
        rzp1.open();
    };
   

    const createBooking = (goldMembershipdetail) => {
        try {
            let tempOptions = {
                ...options,
                amount: Math.round(goldMembershipdetail.price) * 100,
                currency: "INR",
                name: "THS-User",
                description: "some description",
                image: Logo.THS_logo,
                handler: async function (response) {
                    const memberIdList = cards?.gold_membership?.id;
                    let updatedValues = {
                        transaction_id: response.razorpay_payment_id,
                        membership_id: memberIdList,
                        total_amount: goldMembershipdetail.price ,
                        total_paid_amount: goldMembershipdetail.price ,
                        wallet_amount: 0
                    };
                    dispatch(GoldMemberBoking(updatedValues)).then((res) => {
                        setSucessmodal(true);
                    });
                },
            };
            openPayModal(tempOptions);
        } catch (error) { }
    };

    return (
        <>
            {sucessmodal && <Congratulations
                show={sucessmodal}
                onHide={() => {
                    setSucessmodal(false);
                }}
                onNext={() => {
                    Navigate = '/'
                }}
            />}

            <div className="">
                <Modal
                    {...props}
                    size=""
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    dialogClassName=""
                    backdrop="static"
                    className="modal_415 black_theam"
                    
                >
                    <div className="modal-headersss">
                        <div className="golden_header">
                            <img src={Icon.plus} alt="Icon Plus" />
                            <img
                                src={Icon.cross}
                                className="book_modal_close"
                                onClick={props.onHide}
                                alt="My Awesome Image"
                            />
                        </div>
                        <div className="" style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                            <img src={Icon.the} alt="Image The" style={{ height: "40px" }} />
                            <img src={Icon.golden} alt="Image Golden" className="" style={{ height: "60px" }} />
                        </div>
                        <p className="Golden_text pt_5">{goldMembershipStatus?.small_description}</p>
                        <div className="Gold_btn_text mb_20">
                            <button className="Gold_btn">Join Gold  ₹ {goldMembershipStatus?.price} / yr.</button>
                        </div>
                        <div className="">
                            <div className="border_gold">
                                <div className="padding_text">
                                    <p className="gold_text">THS Gold</p>
                                    <p style={{ color: "#E2E2E6", fontSize: "15px", fontWeight: "400" }}>{goldMembershipStatus?.large_description}</p>
                                    <div className="flexcol">
                                        <div className="rightborder">
                                            <p className="gold_text">Price</p>
                                            <p className="gold_text_under">₹ {goldMembershipStatus?.price}</p>
                                        </div>
                                        <div className="rightborder">
                                            <p className="gold_text">Validy</p>
                                            <p className="gold_text_under">{goldMembershipStatus?.duration}</p>
                                        </div>
                                        <div>
                                            <p className="gold_text">Discount</p>
                                            <p className="gold_text_under">{goldMembershipStatus?.discount} %</p>
                                        </div>
                                    </div>
                                    <p className="gold_text pt_10">Benefits</p>
                                    <div>
                                        <ul className="dot-list">
                                            {goldMembershipStatus?.length > 0  && goldMembershipStatus?.benifits.map((benefit, index) => (
                                                <li key={index} className="pt_5">
                                                    <span className="bullet" />
                                                    <span style={{ color: "#E2E2E6", fontWeight: "400" }}>{benefit?.benifits}</span>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>

                                </div>
                            </div>
                            <div className="Gold_btn_text mb_20">
                                <button className="Gold_btns" onClick={() => {props.onHide(); createBooking(goldMembershipStatus)}}>Buy  Now</button>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        </>
    );
};

export default MemberShip;
