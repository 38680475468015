import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import { Icon, Logo } from "../../../../Utilities/Icons";
import { Modal, Button } from "react-bootstrap";
import "../../../../Assets/css/responsive.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import { HiOutlineMenu } from 'react-icons/hi';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ApplyCouponModel from "../../../Common/ApplyCouponModel/ApplyCouponModel";
import {
  CardHome,
  clearSelectedCouponCode,
  cleaSelectedDate,
  CouponList,
} from "../../../../Store/Reducer/BookingSlice";
import LabTestSlotModel from "../../../Common/LabTestModel/LabTestSlotModel";
import {
  LabtestAddressList,
  LabtestBookingCreate,
  LabtestStates,
  removeItem,
  LabtestBookingDetails,
  clearCartItem,
  clearSelectedMember,
  LabtestSlot,
} from "../../../../Store/Reducer/LabtestSlice";
import moment from "moment";
import SelectAddressModel from "../../../Common/LabTestModel/SelectAddressModel";
import LabTestModel from "../../../../Components/Common/LabTestModel/LabTestModel";
import SelecteMemberModel from "../../../Common/MemberModel/SelecteMemberModel";
import PaymentSuccessfullModel from "../../../Common/LabTestModel/PaymentSuccessfullModel";
import { GoldMemberBokingstatus, walletPrice } from "../../../../Store/Reducer/RadiologySlice";
import { clickCardHome } from "../../../../Store/Reducer/ProfileReducer";

function LabTestCart() {
  var today = new Date();
  function formatDate(dateString) {
    if (!dateString) return ""; // Return an empty string if dateString is empty or undefined
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0"); // Get day (2 digits)
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Get month (2 digits)
    const year = date.getFullYear().toString().slice(-2); // Get last 2 digits of the year
    return `${day}-${month}-${year}`;
  }
  const { session } = useSelector(({ AuthSlice }) => AuthSlice);
  const { labCart, selectedMemberDetail, labTestList, goldMembershipdetail, labTestCenterDetailsById, labbookinglist } = useSelector(
    ({ LabtestSlice }) => LabtestSlice
  );
  const navigate = useNavigate();
  let collection_charge = 100;
  const {
    wallet_price
  } = useSelector(({ RadiologySlice }) => RadiologySlice);
  const { goldMembershipStatus } = useSelector(
    ({ RadiologySlice }) => RadiologySlice
  );
  const {coupencodeid } = useSelector(
    ({ BookingSlice }) => BookingSlice
  );


  const [updatedCart, setUpdatedCart] = useState();
  const { userWallet } = useSelector(({ ProfileSlice }) => ProfileSlice);
  const getTotal = () => {
    let totalQuantity = 0;
    let totalPrice = 0;
    labCart?.forEach((item) => {
      totalPrice += item?.price * item?.quantity;
    });
    return { totalPrice, totalQuantity };
  };
  const [labtestmodel, setLabtestmodel] = useState(false);
  const [selectMember, setSelectMember] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [modalShow1, setModalShow1] = useState(false);
  const [timeSample, settimeSample] = useState(false);
  const [applyCoupon, setApplyCoupon] = useState(false);
  const [walletbox, setwalletbox] = React.useState(userWallet);
  const [slectedCityId, setSelectedCityId] = useState();

  const [totalamount, setTotalAmount] = useState(
    Math.round(getTotal().totalPrice)
  );
  const [show, SetShow] = useState(false);

  const dispatch = useDispatch();
  const { couponList, couponCodeDetail } = useSelector(
    ({ BookingSlice }) => BookingSlice
  );
  const {
    cards
  } = useSelector(({ BookingSlice }) => BookingSlice);
  useEffect(() => {
    dispatch(CardHome())
    dispatch(GoldMemberBokingstatus(cards?.gold_membership?.id));
  }, [dispatch, cards?.gold_membership?.id])
  const [selectAddressModel, setSelecteAddressModel] = useState(false);
  const [successfullModel, setSuccessfullModel] = useState(false);

  const options = {
    key: process.env.REACT_APP_PAYMENT_KEY,
    key_secret: process.env.REACT_APP_PAYMENT_SECRET_KEY,

    prefill: {
      name: session?.name,
      contact: session?.mobile_number,
      email: session?.email,
    },
    notes: {
      address: "some address",
    },
    theme: {
      color: "#F37254",
      hide_topbar: false,
    },
  };

  const openPayModal = (options) => {
    var rzp1 = new window.Razorpay(options);

    rzp1.open();
  };
  let test_code = updatedCart?.map(function (i) {
    return i?.test_code;
  }); 
  let test_ids = labCart?.map(function (i) {
    return i?.['Test-data']?.id;
  });

  const createBooking = (values, resetForm) => {
    try {
      let tempOptions = {
        ...options,
        amount: Math.round(totalamount) * 100,
        currency: "INR",
        name: "THS-User",
        description: "some description",
        image: Logo.THS_logo,
        handler: async function (response) {
          let updatedValues = {
            ...values,
            transaction_id: response.razorpay_payment_id,
            total_amount: totalamount,
            total_paid_amount: totalamount,
            test_ids: test_ids?.toString(),
            wallet: wallet_price?.cut_amount,
            payment_type: "wallet",
            coupen_code:coupencodeid

          };
          if (response) {
            dispatch(LabtestBookingCreate(updatedValues)).then((res) => {
              if (res.payload) {
                dispatch(LabtestBookingDetails(res?.payload?.data?.order_id)).then((res1) => {
                  if (res1?.payload) {
                    dispatch(clearCartItem());
                    dispatch(clearSelectedCouponCode());
                    dispatch(clearSelectedMember());
                    setSuccessfullModel(true);
                    dispatch(cleaSelectedDate());
                    resetForm();
                    setTotalAmount("");
                  }
                });
              }
            });
          }
        },
      };
      const walletprice = Math.round(getTotal().totalPrice) - discountAmount
      let OtherOptions = {
        member_id: selectedMemberDetail?.id,
        center_id: labTestCenterDetailsById?.id,
        discount: couponCodeDetail?.coupon_percentage
          ? couponCodeDetail?.coupon_percentage
          : 0,
        wallet: wallet_price?.cut_amount,
        // ADDRESS: "",
        // MEMBER: "",
        address_id: 2,
        transaction_id: "user_wallet",
        convenience_fee: "10",
        // type: "",
        gold_discount: "10",
        total_amount: totalamount,
        total_paid_amount: totalamount,
        // member_id: selectedMemberDetail?.id,
        slot: formik?.values?.slot,
        test_ids: test_ids?.toString(),
        booking_date: moment(today).format("DD-MM-YYYY"),
        booking_time: moment(today).format("HH:SS"),
        golddiscount: goldMembershipStatus?.discount,
        coupen_code:coupencodeid

      }
      if (totalamount == 0) {
        dispatch(LabtestBookingCreate(OtherOptions)).then((res) => {
       
          dispatch(LabtestBookingDetails(res?.payload?.data?.order_id)).then((res1) => {
            dispatch(clearCartItem());
            dispatch(clearSelectedCouponCode());
            dispatch(clearSelectedMember());
            setSuccessfullModel(true);
            dispatch(cleaSelectedDate());
          });
        
        });
      } else {
        openPayModal(tempOptions);
      }

    } catch (error) { }
  };
  useEffect(() => {
    dispatch(
      clickCardHome({
        type: "4",
        labtestid: test_ids?.toString(),
      })
    );
  }, [dispatch, labCart]);
  const labTestSchema = Yup.object({
    ADDRESS: Yup.string().required("Please Select Address"),
    slot: Yup.string().required("Please Select Slot"),
    termsAndConditions: Yup.bool().oneOf(
      [true],
      "Before you book your lab test ,make sure you are aware about THS terms and conditions."
    ),
  });

  const formik = useFormik({
    initialValues: {
      member_id: selectedMemberDetail?.id,
      slot: "",
      booking_date: moment(today).format("DD-MM-YYYY"),
      booking_time: moment(today).format("HH:SS"),
      discount: couponCodeDetail?.coupon_percentage
        ? couponCodeDetail?.coupon_percentage
        : "",
      golddiscount: goldMembershipStatus?.discount,
      center_id: labTestCenterDetailsById?.id,
      address_id: 2,
      ADDRESS: "",


    },
    validationSchema: labTestSchema,
    onSubmit: (values, { resetForm }) => {
      // do your stuff
      createBooking(values, resetForm);
    },
  });

  const handleCheckboxClick = () => {
    // Replace the URL below with the link you want to open
    const link = 'https://thsindia.in/medical-web/privacy-policy';
    window.open(link, '_blank'); // Opens the link in a new tab
  };
  function handleChange(event) {
    setwalletbox((prevFormData) => {
      return {
        ...prevFormData,
        [event.target.name]: event.target.checked,
      };
    });
    if (event.target.checked) {
      if (couponCodeDetail) {
        const amout = (getTotal()?.totalPrice - walletbox.wallet - coupendiscount - discountAmount + collection_charge) / 2;
        setTotalAmount(amout <= 0 ? "0" : amout);
        const cutOfPrice = getTotal()?.totalPrice - (coupendiscount + discountAmount)
        const incAmount = walletbox.wallet - cutOfPrice;
        dispatch(walletPrice({ "wallet_prices": cutOfPrice >= walletbox?.wallet ? "0" : incAmount, "cut_amount": cutOfPrice >= walletbox?.wallet ? "0" : cutOfPrice }));
      } else {
        const amout = totalamount - walletbox.wallet
        const data = totalamount - walletbox.wallet - coupendiscount - discountAmount + collection_charge
        let removeDis;
        if (goldMembershipdetail !== true) {
          removeDis = totalamount
        } else {
          removeDis = totalamount

        }
        dispatch(walletPrice({ "wallet_prices": removeDis <= 0 ? 0 : (walletbox?.wallet - removeDis) <= 0 ? 0 : walletbox?.wallet - removeDis, "cut_amount": walletbox?.wallet - removeDis <= 0 ? walletbox?.wallet : removeDis }));
        if (amout >= 0) {
          setTotalAmount(Math.max(isNaN(amout) ? 0 : amout));
        } else {
          setTotalAmount(Math.max(isNaN(data) ? 0 : data));
        }
      }
    } else {
      if (couponCodeDetail) {
        const amount = getTotal()?.totalPrice - (coupendiscount + discountAmount + collection_charge )
        if( wallet_price?.wallet_prices == 0 ){
          dispatch(walletPrice({ "wallet_prices": wallet_price?.cut_amount }));
          setTotalAmount(amount <= 0 ? "0" : amount);
        }else{
          dispatch(walletPrice({ "wallet_prices": wallet_price?.wallet_prices + (getTotal()?.totalPrice - (coupendiscount + discountAmount  + collection_charge)) }));
          setTotalAmount(amount <= 0 ? "0" : amount);
        }
      } else {
        if (goldMembershipdetail !== true) {
          const amount = (getTotal()?.totalPrice - discountAmount + collection_charge);
    
          if( wallet_price?.wallet_prices == 0 ){
            dispatch(walletPrice({ "wallet_prices": wallet_price?.cut_amount }));
            setTotalAmount(amount <= 0 ? "0" : amount);
          }else{
            dispatch(walletPrice({ "wallet_prices": wallet_price?.wallet_prices + (getTotal()?.totalPrice - discountAmount  + collection_charge) }));
            setTotalAmount(amount <= 0 ? "0" : amount);
          }
        } else {
          const amount = getTotal()?.totalPrice + collection_charge
          if( wallet_price?.wallet_prices == 0 ){
            dispatch(walletPrice({ "wallet_prices": wallet_price?.cut_amount }));
            setTotalAmount(amount <= 0 ? "0" : amount);
          }else{
            dispatch(walletPrice({ "wallet_prices": wallet_price?.wallet_prices + (getTotal()?.totalPrice  + collection_charge) }));
            setTotalAmount(amount <= 0 ? "0" : amount);
          }
        }
      }
    }
  }

  useEffect(() => {
    let slotData = {
      test_center_id: labTestCenterDetailsById?.id,
      date: moment(today).format("DD-MMM-YYYY"),
    };
    if (slotData) {
      dispatch(LabtestSlot(slotData));
    }
    dispatch(LabtestAddressList());
    dispatch(LabtestStates());
  }, [dispatch, labTestCenterDetailsById?.id]);
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);

  useEffect(() => {
    dispatch(CouponList({ coupon_type: 2 }));
    dispatch(walletPrice({ "wallet_prices": walletbox?.wallet, "cut_amount": 0 }));
    dispatch(clearSelectedCouponCode())
  }, [dispatch]);

  const getDiscount = (cupon) => {
    if (cupon?.coupon_percentage) {
      let amt = Math.round(getTotal().totalPrice) -
        (Math.round(getTotal().totalPrice) *
          Number(cupon?.coupon_percentage)) /
        100 
      setTotalAmount(
        amt.toFixed(0)
      );
    }
  };
 
  const { bookingDetail } = useSelector(({ BookingSlice }) => BookingSlice);
  useEffect(() => {
    if (selectedMemberDetail) {
      formik.setFieldValue("member_id", selectedMemberDetail?.id);
      formik.setFieldValue("FIRST_NAME", selectedMemberDetail?.name);
      formik.setFieldValue("PTNTNM", selectedMemberDetail?.name);
      formik.setFieldValue(
        "DOB",
        moment(selectedMemberDetail?.birthdate).format("DD-MMM-YYYY")
      );
    }
  }, [selectedMemberDetail]);
  useEffect(() => {
    if (labCart.length > 0) {
   
      if (goldMembershipdetail != true) {
        const discountAmount = (Math.round(getTotal()?.totalPrice) * goldMembershipStatus?.discount) / 100;
     
        setTotalAmount(Math.round(getTotal()?.totalPrice) - discountAmount + collection_charge);
      } else {
      
        setTotalAmount(Math.round(getTotal()?.totalPrice) + collection_charge);
        setUpdatedCart(labCart);
      }

    } else {
      setTotalAmount(Math.round(getTotal()?.totalPrice) + collection_charge);
      setUpdatedCart(labCart);
    }
  }, [labCart, updatedCart]);


  useEffect(() => {
    if (couponCodeDetail) {
      if (goldMembershipdetail != true) {
     
        const discountAmount = (Math.round(getTotal()?.totalPrice) * goldMembershipStatus?.discount) / 100;

        setTotalAmount(
          Math.round(getTotal()?.totalPrice) -
          (Math.round(getTotal()?.totalPrice) *
            Number(couponCodeDetail?.coupon_percentage)) /
          100 - discountAmount + collection_charge
        );
    
      } else {
   
        setTotalAmount(
          Math.round(getTotal()?.totalPrice) -
          ((Math.round(getTotal()?.totalPrice) *
            Number(couponCodeDetail?.coupon_percentage)) /
            100 + collection_charge)
        );
      }

    } else if (goldMembershipdetail != true) {
      const discountAmount = (Math.round(getTotal()?.totalPrice) * goldMembershipStatus?.discount) / 100;
      setTotalAmount(Math.round(getTotal()?.totalPrice) - discountAmount + collection_charge);
   
    } else {
      setTotalAmount(Math.round(getTotal()?.totalPrice + collection_charge));
    }
  }, [couponCodeDetail, goldMembershipdetail]);
  const discountAmount = (Math.round(getTotal()?.totalPrice) * goldMembershipStatus?.discount) / 100;
  const coupendiscount = (Math.round(getTotal()?.totalPrice) * Number(couponCodeDetail?.coupon_percentage)) / 100


  return (
    <>
      <PaymentSuccessfullModel
        show={successfullModel}
        onHide={() => setSuccessfullModel(false)}
      />
      <LabTestModel
        show={labtestmodel}
        onNext={() => {
          setLabtestmodel(false);
        }}
        onHide={(e) => {
          setLabtestmodel(false);
        }}
      />
      <SelecteMemberModel
        show={selectMember}
        onHide={() => setSelectMember(false)}
      />
      <SelectAddressModel
        show={selectAddressModel}
        setSelectedCityId={setSelectedCityId}
        formik={formik}
        onHide={(e) => {
          setSelecteAddressModel(false);
        }}
      />
      <LabTestSlotModel
        formik={formik}
        show={timeSample}
        slectedCityId={slectedCityId}
        onHide={() => settimeSample(false)}
      />
      <ApplyCouponModel
        couponList={couponList}
        show={applyCoupon}
        getDiscount={getDiscount}
        couponType={2}
        onHide={() => setApplyCoupon(!couponList)}
      />
      <form onSubmit={formik.handleSubmit}>
        <Container fluid className="lab_test_cart_container">
          <Container>
            <div className="row">
              <div className="profilesidebarmain col-md-12">
                <Button
                  className="back_btn"
                  onClick={() => {
                    navigate(-1);
                    dispatch(clearCartItem());
                  }}
                >
                  <img src={Icon.backarrow} className="back_btn_icon"></img>{" "}
                  <span className="back_btn_text">Back</span>
                </Button>
                <Button className="back_btns togglesidebar" onClick={() => SetShow(!show)}>
                  <HiOutlineMenu className="back_btn_texts" />
                </Button>
              </div>
              <div className="col-md-12 p-3">
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <h3 className="cart_head">Cart</h3>
              </div>
            </div>
            <div className="lab_test_cart_box">
              <div className="lab_test_cart_box_inner">
                <div className="row">
                  <div className="col-xl-8 col-md-7 lab_test_right_padding">
                    <div className="lab_test_cart_left_side">
                      <div className="row mb_15">
                        <div className="col-md-6 col-6">
                          <h3 className="lab_test_cart_left_side_title">
                            Lab Tests
                          </h3>
                        </div>
                        <div className="col-md-6 col-6">
                          <Button
                            className="lab_test_add_test_btn"
                            onClick={() => setLabtestmodel(true)}
                          >
                            Add Test
                          </Button>
                        </div>
                      </div>
                      <div>
                        <div className="pt_10">
                          {labCart.length &&
                            labCart?.map((item) => (
                              <>
                                <div className="row mt_20">
                                  <div className="col-md-6 col-6">
                                    <div className="lab_test_for_test_box">
                                      <img
                                        src={Icon.trash}
                                        className="lab_test_for_test_icon"
                                        alt=""
                                        onClick={() =>
                                          dispatch(removeItem(item?.id))
                                        }
                                      ></img>
                                      <h3 className="lab_test_for_test_name">
                                        {item?.['Test-data']?.test_name}
                                      </h3>
                                    </div>
                                  </div>
                                  <div className="col-md-6 col-6">
                                    <h5 className="lab_test_for_test_value">
                                      ₹ {item?.price}
                                    </h5>
                                  </div>
                                </div>
                                <hr className="lab_test_for_test_hr" />
                              </>
                            ))}
                        </div>
                      </div>
                    </div>
                    <div className="lab_test_cart_left_side_2">
                      <div className="row">
                        <div className="col-md-6">
                          <h3 className="lab_test_address">Sample  Address</h3>
                          <div className="lab_test_address_box" onClick={() => setSelecteAddressModel(true)}>
                            {formik?.values?.address_type ? (
                              <>
                                <h2 className="lab_test_address_box_texts">{formik.values.address_type}</h2>
                                <h5 className="lab_test_address_box_textes">
                                  {formik.values.ADDRESS ? formik.values.ADDRESS + ", " : ""}
                                  {formik.values.CITY ? formik.values.CITY + ", " : ""}
                                  {formik.values.STATE ? formik.values.STATE + ", " : ""}
                                  {formik.values.COUNTRY ? formik.values.COUNTRY : ""}
                                </h5>
                                <div className="changese">
                                  <img src={Icon.edit} alt="Edit Icon" className="editimg" />
                                  <p className="changes">Change Address</p>
                                </div>
                              </>
                            ) : <>
                              <h2 className="lab_test_address_box_texts">
                                {formik?.values?.address_type
                                  ? formik?.values?.address_type
                                  : "Select"}
                              </h2>
                            </>}
                          </div>

                          <div className="error_text pt-2">
                            {" "}
                            {formik.errors.ADDRESS &&
                              formik.touched.ADDRESS &&
                              formik.errors.ADDRESS}
                          </div>
                        </div>

                        <div className="col-md-6">
                          <h3 className="lab_test_time">Select Member</h3>
                          <div className="lab_test_address_box" onClick={() => setSelectMember(true)}>
                            {
                              selectedMemberDetail == [] ? <>
                                <h2 className="lab_test_address_box_texts">Select</h2>
                              </>
                                :
                                <>
                                  <div className="changese">
                                    <div>
                                      <img src={selectedMemberDetail?.image} className="memberimg"></img>
                                    </div>
                                    <div>
                                      <h5 className="radiology_booked_test_for_name">
                                        {selectedMemberDetail?.name}
                                      </h5>
                                      <p className="memberlist">{selectedMemberDetail?.gender},Age: {selectedMemberDetail?.age}</p>
                                    </div>
                                  </div>
                                </>
                            }

                          </div>
                          <div className="pt_20">
                            <h3 className="lab_test_time">Date and Time</h3>
                            <div className="lab_test_address_box" onClick={() => settimeSample(true)} >
                              <h5 className="lab_test_time_box_text">

                                {
                                  formik?.values?.slot
                                    ? `${formatDate(formik?.values?.date)} , ${formik?.values?.slot}`
                                    : "Select"
                                }
                              </h5>
                            </div>
                            <div className="error_text pt-2">
                              {" "}
                              {formik.errors.slot &&
                                formik.touched.slot &&
                                formik.errors.slot}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-md-5 lab_test_left_padding">
                    <div className="lab_test_cart_right_side">
                      <h5 className="offer_benifit_text">
                        Offers &amp; Benifits
                      </h5>
                      <div
                        className="offer_benifit_card"
                        onClick={() => setApplyCoupon(true)}
                      >
                        <div className="apply_coupon_box">
                          <img
                            src={Icon.coupon}
                            className="apply_coupon_icon"
                            alt=""
                          ></img>
                          <h5 className="offer_benifit_text">Apply Coupon</h5>
                        </div>
                        <div>
                          <svg
                            width="8"
                            height="14"
                            className="subscrip_consult_card_arrow"
                            viewBox="0 0 8 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1.25 12.5L6.75 7L1.25 1.5"
                              stroke="#9393AA"
                              strokeWidth="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </div>
                      </div>
                      <h5 className="payment_summary">Payment Summary</h5>
                      <div className="payment_summary_card">
                        <div className="payment_summary_card_inner mb_5">
                          <div>
                            <h5 className="payment_summary_title">
                              Total amount
                            </h5>
                          </div>
                          <div>
                            <h5 className="payment_summary_price">
                              ₹ {Math.round(getTotal().totalPrice)}
                            </h5>
                          </div>
                        </div>
                        {couponCodeDetail && (
                          <div className="payment_summary_card_inner">
                            <div>
                              <h5 className="payment_summary_title">
                                Discount
                              </h5>
                            </div>
                            <div>
                              <h5 className="payment_summary_price_discount">
                                - ₹{" "}
                                {coupendiscount}
                              </h5>
                            </div>
                          </div>
                        )}
                        {
                          goldMembershipdetail == true ? " " : <>
                            <div className="payment_summary_card_inner">
                              <div>
                                <h5 className="payment_done_payment_info_title" style={{ color: "#48988E" }}>
                                  Gold Discount [{goldMembershipStatus?.discount}%]
                                </h5>
                              </div>
                              <div>
                                <h5 className="payment_summary_price_discount">
                                  - ₹{" "}
                                  {discountAmount && discountAmount}

                                </h5>
                              </div>
                            </div>
                          </>
                        }
                        <div className="payment_summary_card_inner">
                          <div>
                            <h5 className="payment_summary_title">
                              Sample collection charge
                            </h5>
                          </div>
                          <div>
                            <h5 className="payment_summary_price_discount">
                              + ₹ {collection_charge}
                            </h5>
                          </div>
                        </div>

                        <hr className="payment_hr" />
                        <div className="payment_summary_card_inner">
                          <div>
                            <h5 className="payment_summary_total_title">
                              Total Payable
                            </h5>
                          </div>
                          <div>
                            <h5 className="payment_summary_total_price">
                              ₹ {totalamount ? parseFloat(totalamount).toFixed(0) : 0}
                            </h5>
                          </div>
                        </div>
                      </div>
                      <div className="wallet_card">
                        <div>
                          <div className="form-group">
                            <input
                              type="checkbox"
                              id={walletbox.id}
                              onChange={handleChange}
                              name={userWallet?.wallet}
                              value={walletbox}
                            />
                            <label className="wallet_text" for={walletbox.id}>
                              <span className="wallet_subtext">
                                Use wallet Amount
                              </span>
                            </label>
                          </div>
                        </div>
                        <div>
                          <h5 className="available_bal_text">
                            Avbl. bal.
                            <span className="available_bal_price">
                       
                              ₹{parseFloat(wallet_price?.wallet_prices).toFixed(0) || "0"}
                            </span>
                          </h5>
                        </div>
                      </div>
                      <h5 className="T_C_title pt_10">Terms and conditions</h5>
                      <div className="lab_T_C_card">
                        <p className="T_C_text">
                          Before you book your lab test ,make sure you are aware about THS terms and conditions.

                        </p>
                        <div className="col-md-12">
                          <div>
                            <div className="healthpackage">
                              <input
                                id="checkbox1"
                                type="checkbox"
                                className="vh"
                                name="termsAndConditions"
                                onChange={() =>
                                  formik.setFieldValue(
                                    "termsAndConditions",
                                    !formik.values.termsAndConditions
                                  )
                                }
                              />
                              <label
                                className="healthpackage_checkbox_title"
                                htmlFor="checkbox1"

                                style={{
                                  display: 'inline-flex',
                                  alignItems: 'center',
                                }}
                              >
                                {" "}
                                <span
                                  className="i_agree_cancellation_policy"
                                  style={{ padding: "25px" }}
                                >
                                  <h5 className="view_T_C_text"
                                    onClick={handleCheckboxClick}
                                  >
                                    View Terms And Conditions
                                  </h5>
                                </span>
                              </label>
                            </div>

                            {formik?.errors?.termsAndConditions &&
                              formik.touched.termsAndConditions && (
                                <div className="terms_conditions_error_text">
                                  {formik?.errors?.termsAndConditions}
                                </div>
                              )}
                          </div>
                        </div>
                        {/* <h5 className="view_T_C_text">
                            View Terms And Conditions
                          </h5> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="lab_test_footer_card">
                <div className="lab_test_card_box">
                  <div className="lab_test_price_box">
                    <h5 className="lab_test_price">Rs {totalamount ? parseFloat(totalamount).toFixed() : 0}</h5>
                    <h6 className="lab_test_subtitle">Amount to pay</h6>
                  </div>
                  <div>
                    <Button className="lab_test_btn" type="submit">
                      Pay Now
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </Container>
      </form >
    </>
  );
}


export default LabTestCart;
