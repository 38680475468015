import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import { Icon } from "../../../Utilities/Icons";
import { Button } from "react-bootstrap";
import "../../../Assets/css/responsive.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import {
  decrementQuantity,
  GetMedicine,
  incrementQuantity,
  madicineAddToCart,
} from "../../../Store/Reducer/MedicineSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Table from "../../Common/Layouts/Table";
import ReactPaginate from "react-paginate";
const Medicines = () => {
  const { medicines, cart } = useSelector(({ MedicineSlice }) => MedicineSlice);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useState({ q: "a", page: 2 });
  const [show, setShow] = useState(false);
  const [text, setText] = useState("");

  const [medicinesList, setMedicinesList] = useState([]);

  const dispatch = useDispatch();

  const columns = [
    {
      Header: "Medicine Name",
      accessor: "appointment_id",
      Cell: ({
        cell: {
          row: { original },
        },
      }) => {
        return (
          <a
            role="button"
            onClick={() => { dispatch(madicineAddToCart(original)); navigate(`/medicinesdetails/${original?.id}`) }}
          >
            {original.name}
          </a>
        );
      },
    },
    {
      Header: "MRP",
      accessor: "mrp",
    },
    {
      Header: "Sales price",
      accessor: "sales_price",
    },
    {
      Header: "Action",
      class: "amount",
      accessor: "id",
      Cell: ({
        cell: {
          row: { original },
        },
      }) => {
        return cart.filter((cart) => cart.id === original.id).length > 0 ? (
          <div className="quantity_field mt_10">
            <Button
              className="decrease_button"
              onClick={() => dispatch(decrementQuantity(original.id))}
            >
              -
            </Button>
            {cart?.map((item) => (
              <>
                {item?.id == original?.id && (
                  <div className="number">{item?.quantity}</div>
                )}
              </>
            ))}

            <Button
              className="increase_button"
              onClick={() => dispatch(incrementQuantity(original.id))}
            >
              +
            </Button>
          </div>
        ) : (
          <Button
            className="add_btn mt_5"
            onClick={() => {
              dispatch(madicineAddToCart(original));
            }}
          >
            ADD
          </Button>
        );
      },
    },
  ];

  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {


    if (searchParams?.page && searchParams?.q) {

      setTimeout(() => {
        dispatch(GetMedicine(searchParams)).then((res) => {
        
          setMedicinesList(res?.payload?.products);
          setShow(true);
        });
      }, 1000);
    } else {
      setShow(false);
    }
  }, [searchParams]);

  const onChangeText = (e) => {
    let q = e?.target?.value;
    setText(q);
    // setMedicinesList([]);
    setSearchParams((state) => ({ ...state, q }));
  };
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setMedicinesList([]); // Reset the medicines list
      setSearchParams((state) => ({ ...state, page: 2 })); // Reset the page
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [text]);
  useEffect(() => {
 

    if (searchParams?.page && searchParams?.q) {
      setTimeout(() => {
        // Subtract 1 from currentPage before sending it to API
        dispatch(GetMedicine({ ...searchParams, page: currentPage - 1 })).then((res) => {
 
          setMedicinesList(res?.payload?.products);
          setShow(true);
        });
      }, 1000);
    } else {
      setShow(false);
    }
  }, [searchParams, currentPage]);
  const [loading, setLoading] = useState(false);
  const fetchMedicines = () => {
    try {
      setLoading(true);
      let filteredData = medicines?.products?.filter((item) => {

        if (
          (item?.is_refrigerated === false) &
          (item?.is_discontinued === false) &
          (item?.can_sell_online === true)
        ) {
          return item;
        }
      });

      // setMedicinesList(updatedMedicines?.map((v) => ({ ...v, quantity: 0 })));
      const updatedMedicines = filteredData.map(v => ({ ...v, quantity: 0 }));
      setMedicinesList(prevMedicines => [...prevMedicines, ...updatedMedicines]);
      setSearchParams((state) => ({ ...state, page: state.page + 1 }));
    } catch (error) {
   
    } finally {
      setLoading(false);
    }

  };
  // const PER_PAGE = 9;
  // const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    // Update the searchParams when currentPage changes
    setSearchParams((prevState) => ({
      ...prevState,
      page: currentPage,
    }));
  }, [currentPage]);

  // Handle page change

  // const handleScroll = () => {
  //   if (
  //     window.innerHeight + window.scrollY >=
  //     document.body.offsetHeight - 200 // Adjust this value as needed
  //   ) {
  //     fetchMedicines();
  //   }
  // };

  // useEffect(() => {
  //   window.addEventListener("scroll", handleScroll);
  // }, [handleScroll]);
  useEffect(() => {
    fetchMedicines();
  }, [dispatch]);
  const getTotalQuantity = () => {
    let total = 0;
    cart?.forEach((item) => {
      total += item.quantity;
    });
   
    return total;
  };
  const getTotal = () => {
    let totalQuantity = 0;
    let totalPrice = 0;
    cart?.forEach((item) => {
      totalQuantity += item.quantity;
      totalPrice += item.sales_price * item.quantity;
    });
    return { totalPrice, totalQuantity };
  };
  const PER_PAGE = 9;

  function handlePageClick({ selected }) {
    setCurrentPage(selected + 1); // Add 1 to match your UI display
  }

  return (
    <>
      <Container fluid className="medicine_container">

        <Container>
          <div className="row">
            <div className="row">
              <div className="col-md-12">
                <Button
                  className="back_btn"
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  <img
                    src={Icon.backarrow}
                    className="back_btn_icon"
                    alt=""
                  ></img>{" "}
                  <span className="back_btn_text">Back</span>
                </Button>
              </div>
            </div>
            <div className="col-md-12 mt-4">
              <h3 className="health_package_container_title">Medicine</h3>
            </div>
          </div>
          <div className="medicine_card_box">
            <div className="row">
              <div className="col-md-4">
                <img
                  src={Icon.search}
                  className="select_city_search_icon"
                ></img>
                <input
                  type="search"
                  className="medicine_input_search"
                  placeholder="Search for medicine"
                  aria-label="Search"
                  value={text}
                  onChange={onChangeText}
                />
              </div>
              <div className="col-md-8">
                <h6 className="medicine_search_heading"></h6>
              </div>


            </div>
            <div className="row">
              <Table
                data={medicinesList || []}
                columns={columns}
                pagination={true}
              />
           
            </div>
            <ReactPaginate
              pageCount={medicines?.meta?.last_page}
              pageRangeDisplayed={5}
              marginPagesDisplayed={2}
              previousLabel={"Previous"}
              nextLabel={"Next"}
              breakLabel={"..."}
              onPageChange={handlePageClick}
              forcePage={currentPage - 1} // Subtract 1 to match 0-based index used by ReactPaginate
              containerClassName={"paginationsss"}
              pageClassName={"page-item"} // Use the page-item class for each page
              activeClassName={"active"} // Apply the active class for the selected page
            />


            {cart?.length > 0 && (
              <div className="row">
                <div className="">
                  <div className="medicine_footer">
                    <div className="medicine_footer_details">
                      <div>
                        <h5 className="item_number">
                          {getTotalQuantity() || 0}
                        </h5>
                        <h5 className="items_value">Items</h5>
                      </div>
                      <div>
                        <h5 className="item_number">
                          Rs {Math?.round(getTotal()?.totalPrice)}
                        </h5>
                        <h5 className="items_value">Amount to pay</h5>
                      </div>
                      <Button
                        className="go_to_cart_btn"
                        onClick={() => navigate("/buymedicinecart")}
                      >
                        Go to Cart
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </Container>
      </Container>
    </>
  );
};

export default Medicines;
