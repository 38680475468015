import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import "../../../Assets/css/responsive.css";
import { useDispatch, useSelector } from "react-redux";
import { Icon } from "../../../Utilities/Icons";
import { useNavigate } from "react-router-dom";
import AddMember from "../../Common/MemberModel/AddMember";
import { AddNewMembers, Deleteaccount } from "../../../Store/Reducer/ProfileReducer";
import { DeleteAccount } from "../../../Routes/Service";


const Profile = (props) => {
  const [newMembers, setNewMembers] = useState(false);
  const { memberList , deleteaccountdata } = useSelector(({ ProfileSlice }) => ProfileSlice);
  const navigate = useNavigate();
  let addMemberinitialState = {
    name: "",
    email: "",
    mobile_number: "",
    age: "",
    height: "",
    weight: "",
    gender: "",
    blood_group: "",
    birthdate: "",
    medical_history: [],
  };
   
  
  let colors = ["red", "blue", "green", "purple", "pink"];
  const randomColor = () => {
    return colors[(Math.random() * colors.length) >> 0];
  };
  
  const dispatch = useDispatch();
  const handlechange = () => {
    dispatch(Deleteaccount())
    if(deleteaccountdata?.success === 'true'){
      navigate='/'
    }
  }

  return (
    <>
      <AddMember
        addEditMemberinitialState={addMemberinitialState}
        addEditMember={AddNewMembers}
        show={newMembers}
        onHide={() => setNewMembers(false)}
      />
      <div className="profiledash_body_box_card_head">
        <div className="row">
          <div className="col-md-6 col-6">
            <h5 className="profiledash_body_card_head_title">Profile</h5>
          </div>
          <div className="col-md-6 col-6">
            <Button className="profiledash_body_card_head_btn" onClick={handlechange}>
              Delete Account
            </Button>
          </div>
        </div>
      </div>

      <div className="profiledash_body_box_card_body">
        <div className="profiledash_member_box">
          <div className="profiledash_member_card">
            <h5 className="addnewmember" onClick={() => setNewMembers(true)}>
              <img src={Icon.addmember} className="addnewmember_icon"></img> Add
              New Member
            </h5>
          </div>
          {memberList?.length &&
            memberList?.map((member, index) => (

              <div
                className="profiledash_member_card profiledash_member_card_border"
                key={index}
                onClick={() => {
                  navigate(`/userProfile/${member?.id}`);
                }}
              >
                
                <div className="profiledash_member_card_inner">
                  {
                    member?.image == undefined ? <>
                      <div>
                        <div
                          style={{
                            alignItems: "center",
                            display: "flex",
                            justifyContent: "center",
                            /* Colors */
                            backgroundColor: randomColor(),
                            color: "#fff",
                            /* Rounded border */
                            borderRadius: "50%",
                            height: "3rem",
                            width: "3rem",
                          }}
                        >
                          {" "}
                          {member.name.charAt(0)}{" "}
                        </div>
                      </div></> : <>
                      <div>
                        <div
                          style={{
                            alignItems: "center",
                            display: "flex",
                            justifyContent: "center",
                            /* Colors */
                            color: "#fff",
                            /* Rounded border */
                            borderRadius: "50%",
                            height: "3rem",
                            width: "3rem",
                          }}
                        >
                          <img src={member?.image} style={{
                            borderRadius: "50%",
                            height: "3rem",
                            width: "3rem",
                          }}></img>
                        </div>
                      </div>
                    </>
                  }

                  <div>
                    <h5 className="addnewmember_name">{member.name}</h5>
                    <h6 className="addnewmember_subtitle">
                      {member.gender}, {member.age}
                    </h6>
                    <span>
                      <span className="addnewmember_subtitle">
                        Height : {member.height}
                      </span>{" "}
                      <span className="addnewmember_subtitle ml_5">
                        Weight {member.weight}
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default Profile;
