import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  LabtestCancelReasons,
  LabtestPastBookings,
  SelectedCancelReasons,
} from "../../../../Store/Reducer/LabtestSlice";
import { Icon } from "../../../../Utilities/Icons";
import LabCancelationReasonModel from "../../../Common/LabTestModel/LabCancelationReasonModel";

const ProfileTestBooking = () => {
  const [cancelationReasonModel, setCancelationReasonModel] = useState(false);
  const {
    labtestUpcomingBookingList,
    labtestCancelReasons,
    labtestPastBookingsList,
  } = useSelector(({ LabtestSlice }) => LabtestSlice);
  const [profileByIdLebTest, setProfileByIdLebTest] = useState(
    labtestUpcomingBookingList
  );
  const [profileByIdPastLebTest, setProfileByIdPastLebTest] = useState(
    labtestPastBookingsList
  );
  const dispatch = useDispatch();
  const { id } = useParams();

  const navigate = useNavigate();
  useEffect(() => {
    dispatch(LabtestCancelReasons("C000145595"));
  }, [dispatch]);
  useEffect(() => {
    dispatch(LabtestPastBookings());
    let newData =
      labtestUpcomingBookingList?.length &&
      labtestUpcomingBookingList?.filter(function (item) {
        if (id == item?.member_id) {
          return item;
        }
      });
    let newData2 =
      labtestUpcomingBookingList?.length &&
      labtestUpcomingBookingList?.filter(function (item) {
        if (id == item?.member_id) {
          return item;
        }
      });
    setProfileByIdPastLebTest(newData2);
    setProfileByIdLebTest(newData);
  }, [dispatch, id]);
  return (
    <>
      <LabCancelationReasonModel
        show={cancelationReasonModel}
        cancelationReason={labtestCancelReasons}
        onHide={() => {
          setCancelationReasonModel(!cancelationReasonModel);
        }}
      />
      <div className="my_test_body_box_card_head">
        <div className="row">
          <div className="col-md-6">
            <h3 className="my_test_body_card_head_title">Consultations</h3>
          </div>
          <div className="col-md-6"></div>
        </div>
      </div>
      <div className="my_test_body_box_card_body">
        <div className="row">
          <div className="col-md-12">
            <h3 className="upcoming_test_booking_title">
              Upcoming Test Booking
            </h3>

            {profileByIdLebTest?.length &&
              profileByIdLebTest?.map((labtestupcomingBooking) => (
                <div>
                  <div
                    className="upcoming_test_card_head"
                    onClick={() => {
                      navigate(
                        `/lab-test-detail/${labtestupcomingBooking?.order_id}`
                      );
                    }}
                  >
                    <img
                      src={Icon.scheduled}
                      className="upcoming_consultation_card_icon"
                      alt=""
                    ></img>{" "}
                    <span className="upcoming_consultation_card_title">
                      {moment(labtestupcomingBooking?.booking_date).format(
                        "DD/MMM/YYYY"
                      )}{" "}
                      at {labtestupcomingBooking?.booking_time} am
                    </span>
                  </div>
                  <div className="upcoming_test_card_body mb_25">
                    <div className="row">
                      <div className="col-md-12">
                        <h5 className="my_test_id">
                          ID#{labtestupcomingBooking?.order_id}
                        </h5>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="row">
                          <div className="col-md-6 col-6">
                            <h5 className="radiology_booked_test_for">
                              Test For
                            </h5>
                            <div className="radiology_booked_test_profile_box mb_10">
                              <div>
                                <div className="addnewmember_profile_icon">
                                  {" "}
                                  {labtestupcomingBooking?.member_details?.name?.charAt(
                                    0
                                  )}{" "}
                                </div>
                              </div>
                              <div>
                                <h5 className="radiology_booked_test_for_name">
                                  {labtestupcomingBooking?.member_details?.name}
                                </h5>
                              </div>
                            </div>
                            <h3 className="my_test_card_head_title">
                              Test: {labtestupcomingBooking?.test_name}
                            </h3>
                            <h5 className="my_test_note">Note: --</h5>
                          </div>
                          {/* <div className="col-md-6 col-6">
                          <h5 className="radiology_booked_test_for">
                            Test For
                          </h5>
                          <div className="radiology_booked_test_profile_box mb_10">
                            <div>
                              <div className="my_test_profile_icon_1"> B </div>
                            </div>
                            <div>
                              <h5 className="radiology_booked_test_for_name">
                                Brother
                              </h5>
                            </div>
                          </div>
                          <h3 className="my_test_card_head_title">
                            Test: Covid -19
                          </h3>
                          <h5 className="my_test_note">Note: --</h5>
                        </div> */}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="consultation_option_box">
                          <div
                            className="consultation_option_box_inner_1"
                            onClick={() => {
                              setCancelationReasonModel(true);
                              dispatch(
                                SelectedCancelReasons(labtestupcomingBooking)
                              );
                            }}
                          >
                            <img
                              src={Icon.cancel}
                              className="cancel_icon"
                              alt=""
                            ></img>{" "}
                            <span className="cancel_appointment">
                              Cancle Appointment
                            </span>
                          </div>
                          <div className="consultation_option_box_inner_2">
                            <img
                              src={Icon.reschedule}
                              className="reschedule_icon"
                              alt=""
                            ></img>{" "}
                            <span className="reschedule_title">Reschedule</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}

            <h3 className="past_test_booking_title mt_25">Past Test Booking</h3>
            {profileByIdPastLebTest?.length &&
              profileByIdPastLebTest?.map((labtestPastBookings) => (
                <div>
                  <div className="past_test_card_head">
                    <img
                      src={Icon.scheduled_green}
                      className="upcoming_consultation_card_icon"
                      alt=""
                    ></img>{" "}
                    <span className="past_test_card_title">Completed</span>
                  </div>
                  <div className="past_test_card_body mb_25">
                    <div className="row">
                      <div className="col-md-12">
                        <h5 className="my_test_id">
                          ID#{labtestPastBookings?.order_id}
                        </h5>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="row">
                          <div className="col-md-6 col-6">
                            <h5 className="radiology_booked_test_for">
                              Test For
                            </h5>
                            <div className="radiology_booked_test_profile_box mb_10">
                              <div>
                                <div className="addnewmember_profile_icon">
                                {" "}
                                  {labtestPastBookings?.member_details?.name?.charAt(
                                    0
                                  )}{" "}
                                </div>
                              </div>
                              <div>
                                <h5 className="radiology_booked_test_for_name">
                                  {labtestPastBookings?.member_details?.name}
                                </h5>
                              </div>
                            </div>
                            <h3 className="my_test_card_head_title">
                              Test: {labtestPastBookings?.test_name}
                            </h3>
                            <h5 className="my_test_note">Note: --</h5>
                          </div>
                          <div className="col-md-6 col-6">
                            <h5 className="radiology_booked_test_for">
                              Test For
                            </h5>
                            <div className="radiology_booked_test_profile_box mb_10">
                              <div>
                                <div className="my_test_profile_icon_1">
                                  {" "}
                                  B{" "}
                                </div>
                              </div>
                              <div>
                                <h5 className="radiology_booked_test_for_name">
                                  Brother
                                </h5>
                              </div>
                            </div>
                            <h3 className="my_test_card_head_title">
                              Test: Covid -19
                            </h3>
                            <h5 className="my_test_note">Note: --</h5>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6"></div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileTestBooking;
