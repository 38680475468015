import React, { useEffect } from "react";
import Container from "react-bootstrap/Container";
import { Icon } from "../../../../Utilities/Icons";
import { Button } from "react-bootstrap";
import "../../../../Assets/css/responsive.css";
import { useNavigate, useParams } from "react-router-dom";
import { LabtestBookingDetails } from "../../../../Store/Reducer/LabtestSlice";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import jsPDF from "jspdf";
function LabTestCompleteDetails() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { labtestId } = useParams();
  const { labtestBookingDetailsById } = useSelector(
    ({ LabtestSlice }) => LabtestSlice
  );
  useEffect(() => {
    dispatch(LabtestBookingDetails(labtestId));
  }, [dispatch, labtestId]);
  const handleSubmit = async () => {
    const doc = new jsPDF();


    if (labtestBookingDetailsById?.e_report) {
      try {
        const response = await fetch(labtestBookingDetailsById.e_report);
        const blob = await response.blob();

        const img = new Image();
        img.src = URL.createObjectURL(blob);
        img.onload = () => {
          const canvas = document.createElement("canvas");
          canvas.width = img.width;
          canvas.height = img.height;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0);

          canvas.toBlob(async (canvasBlob) => {
            const imgDataUrl = URL.createObjectURL(canvasBlob);

            // Add image to the PDF
            doc.addImage(imgDataUrl, "JPEG", 10, 40, 50, 50);
            doc.save("formData.pdf");

            // Clean up the object URLs
            URL.revokeObjectURL(img.src);
            URL.revokeObjectURL(imgDataUrl);
          }, "image/jpeg");
        };
      } catch (error) {
        // console.error("Error loading image:", error);
      }
    } else {
      doc.save("formData.pdf");
    }
  };




  return (
    <>
      <Container fluid className="test_completed_details_container">
        <Container>
          <div className="row">
            <div className="col-md-12 pb-3">
              <Button
                className="back_btn"
                onClick={() => {
                  navigate(-1);
                }}
              >
                <img
                  src={Icon.backarrow}
                  className="back_btn_icon"
                  alt=""
                ></img>{" "}
                <span className="back_btn_text">Back</span>
              </Button>
            </div>
            <div className="col-md-12">
              <h3 className="test_completed_head">
                ID #{labtestBookingDetailsById?.booking_id}
              </h3>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="test_completed_card_box">
                <div className="row">
                  <div className="test_completed_lab_test_title">Lab Test</div>
                  {labtestBookingDetailsById?.test?.map((item) => (
                    <div className="row mt_10">
                      <div className="col-md-6">
                        <h3 className="test_completed_card_head_title">
                          {item?.test_name}
                        </h3>
                        <h5 className="test_completed_note">
                          Note:{item?.test_preparation}.
                        </h5>
                      </div>
                  
                      {
                        item.e_report != undefined &&
                        <div className="col-md-6">
                          <div className="test_completed_option_box_1">
                            <div className="download_e_report_width" onClick={() => { handleSubmit() }}>
                              <div className="download_e_report">
                                Download E- Report
                                <img
                                  src={require("../../../../Assets/img/png/download_black.png")}
                                  className="e-report_icon"
                                  alt=""
                                ></img>
                              </div>
                            </div>
                          </div>
                        </div>
                      }

                    </div>
                  ))}
                </div>
              </div>
              <div className="test_completed_card_box mt_10">
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12">
                    <div className="row">
                      <div className="col-md-12">
                        <h5 className="test_completed_body_title">Tracking</h5>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 col-12">
                        <h5 className="test_completed_time_title">
                          Executive will react at your home to collect test
                          samples on
                        </h5>
                        <h3 className="test_completed_time_text">
                          21/06/2022 at 4:30 Pm
                        </h3>
                      </div>
                    </div>
                    <hr className="test_completed_hr" />
                    <div className="order_tracking">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="row">
                            <div className="col-12 col-md-10 pt45 pb20">
                              <div className="row">
                                <div className={`order-tracking   completed`}>
                                  <span className="is-complete"></span>
                                  <h3 className="booking_placed_title active">
                                    Booking Placed
                                  </h3>
                                  {/* <p>21 Jun, 2022 | 2:00 pm</p> */}
                                </div>
                      
                                <div className={`order-tracking ${[1, 3, 5, 6].includes(labtestBookingDetailsById?.status) && "completed"}`}>
                                  <span className="is-complete"></span>
                                  <h3 className="booking_placed_title active">
                                    Booking Confirmed
                                  </h3>
                                  {/* <p>21 Jun, 2022 | 2:00 pm</p> */}
                                </div>
                            
                                    <div className={`order-tracking ${[ 3, 5, 6].includes(labtestBookingDetailsById?.status) && "completed"}`}>
                                  <span className="is-complete"></span>
                                  <h3 className="booking_placed_title active">
                                    Sample Colelcted
                                  </h3>
                                  {/* <p>21 Jun, 2022 | 2:00 pm</p> */}
                                </div>
                                <div className={`order-tracking ${[ 3, 5].includes(labtestBookingDetailsById?.status) && "completed"}`}>
                                  <span className="is-complete"></span>
                                  <h3 className="booking_placed_title active">
                                    E - Report Generated
                                  </h3>
                                  {/* <p>21 Jun, 2022 | 2:00 pm</p> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="test_completed_card_box mt_10">
                <div className="row">
                  <div className="col-md-12">
                    <h3 className="sample_collected_by">
                      {" "}
                      Sample Collected by
                    </h3>
                    <div className="sample_collected_by_box">
                      <div>
                        <img src={Icon.dr_shreeja} alt=""></img>
                      </div>
                      <div>
                        <h3 className="sample_collected_doc">
                          {labtestBookingDetailsById?.member_name}
                        </h3>
                        <h5 className="sample_collected_emp_id">
                          Employeed id: {labtestBookingDetailsById?.member_id}
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <h3 className="sample_collection_add">
                      Sample Collection Address
                    </h3>
                    <h5 className="sample_collection_add_title">
                      {labtestBookingDetailsById?.address_details?.type}
                    </h5>
                    <p className="sample_collection_add_para">
                      {labtestBookingDetailsById?.address_details?.address}
                    </p>
                  </div>
                </div>
              </div>
            </div>
           
            <div className="col-md-6">
              <div className="test_completed_card_box mt_10">
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-xs-8 col-sm-12">
                    <h3 className="test_payment_title">Payment Summary</h3>
                    <div className="row">
                      <div className="col-md-6 col-6">
                        <h5 className="test_payment_summary_text">Amount</h5>
                      </div>
                      <div className="col-md-6 col-6">
                        <h5 className="test_payment_summary_value">
                          ₹ {labtestBookingDetailsById?.total_amount}
                        </h5>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 col-6">
                        <h5 className="test_payment_summary_discount_text">
                          Discount
                        </h5>
                      </div>
                      <div className="col-md-6 col-6">
                        <h5 className="test_payment_summary_discount_value">
                          ₹ {labtestBookingDetailsById?.Discount_amount}
                        </h5>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 col-6">
                        <h5 className="test_payment_summary_text">
                          Total amount
                        </h5>
                      </div>
                      <div className="col-md-6 col-6">
                        <h5 className="test_payment_summary_value">
                          ₹ {labtestBookingDetailsById?.total_amount}
                        </h5>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 col-6">
                        <h5 className="test_payment_summary_text">Status</h5>
                      </div>
                      <div className="col-md-6 col-6">
                        <h5 className="test_payment_summary_discount_value">
                          {labtestBookingDetailsById?.status}
                        </h5>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 col-6">
                        <h5 className="test_payment_summary_text">Trxn Id</h5>
                      </div>
                      <div className="col-md-6 col-6">
                        <h5 className="test_payment_summary_value">
                          {labtestBookingDetailsById?.transaction_id}
                        </h5>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-5 col-5">
                        <h5 className="test_payment_summary_text">
                          Date and Time
                        </h5>
                      </div>
                      <div className="col-md-7 col-7">
                        <h5 className="test_payment_summary_value">
                          <p>
                            {" "}
                            {moment(
                              labtestBookingDetailsById?.booking_date_time
                            ).format("DD-MMM-YYYY | h:mm A")}{" "}
                            {/* {moment(
                              labtestBookingDetailsById?.booking_date
                            ).format("DD-MMM-YYYY")}{" "}
                            |{" "}
                            {
                              moment(
                                labtestBookingDetailsById?.booking_time,
                                "HH:mm"
                              ).format("h:mm A") // "14:00"
                            } */}
                          </p>
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </Container>
    </>
  );
}

export default LabTestCompleteDetails;
