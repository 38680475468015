import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import { Icon, Logo } from "../../../Utilities/Icons";
import { Modal, Button } from "react-bootstrap";
import '../../../Assets/css/responsive.css'
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import ApplyCouponModel from "../../Common/ApplyCouponModel/ApplyCouponModel";
import {
    clearSelectedCouponCode,
    cleaSelectedDate,
    CouponList,
} from "../../../Store/Reducer/BookingSlice";
import LabTestSlotModel from "../../Common/LabTestModel/LabTestSlotModel";
import {
    LabtestAddressList,
    LabtestBookingCreate,
    LabtestStates,
    removeItem,
    LabtestBookingDetails,
    clearCartItem,
    clearSelectedMember,
    LabtestSlot,
    HealthPackageBookingCreate,
    HealthCareBookingDetails,
    HealthPackageBookingDetails,
    addToCart,
    LabtestLabs,
    addToCarted,
} from "../../../Store/Reducer/LabtestSlice";
import moment from "moment";
import SelectAddressModel from "../../Common/LabTestModel/SelectAddressModel";
import LabTestModel from "../../Common/LabTestModel/LabTestModel";
import SelecteMemberModel from "../../Common/MemberModel/SelecteMemberModel";
import PaymentSuccessfullModel from "../../Common/LabTestModel/PaymentSuccessfullModel";
import HealthMember from "./HealthMember";
import HealthCartPayment from "./HealthCartPayment";
import { walletPrice } from "../../../Store/Reducer/RadiologySlice";
import { clickCardHome } from "../../../Store/Reducer/ProfileReducer";

function HealthCartDetail(props) {
    const {
        wallet_price
    } = useSelector(({ RadiologySlice }) => RadiologySlice);

    var today = new Date();
    const { session } = useSelector(({ AuthSlice }) => AuthSlice);
    const { labCart, selectedMemberDetail, labTestList, labTestCenterDetailsById, goldMembershipdetail, healthPackageBookingCreate, healthPackageBookingDetails } = useSelector(
        ({ LabtestSlice }) => LabtestSlice
    );
    const { goldMembershipStatus } = useSelector(
        ({ RadiologySlice }) => RadiologySlice
    );
    const { coupencodeid } = useSelector(
        ({ BookingSlice }) => BookingSlice
    );
    const [updatedCart, setUpdatedCart] = useState();

    const { userWallet } = useSelector(({ ProfileSlice }) => ProfileSlice);
    const data = localStorage.getItem("packageItemList");
    const packageItemList = JSON.parse(data);

    const getTotal = () => {
        let totalQuantity = 0;
        let totalPrice = 0;
        labCart?.forEach((item) => {

            totalPrice += parseInt(item?.price);

        });
        return { totalPrice, totalQuantity };
    };

    const [labtestmodel, setLabtestmodel] = useState(false);
    const [selectMember, setSelectMember] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [modalShow1, setModalShow1] = useState(false);
    const [timeSample, settimeSample] = useState(false);
    const [applyCoupon, setApplyCoupon] = useState(false);
    const [walletbox, setwalletbox] = React.useState(userWallet);
    const [slectedCityId, setSelectedCityId] = useState();
    const [valForLoad, setValForLoad] = useState(false);
    const [totalamount, setTotalAmount] = useState(
        packageItemList?.discount_amount
    );
    const dispatch = useDispatch();
    const { couponList, couponCodeDetail } = useSelector(
        ({ BookingSlice }) => BookingSlice
    );
    const [selectAddressModel, setSelecteAddressModel] = useState(false);
    const [successfullModel, setSuccessfullModel] = useState(false);
    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://checkout.razorpay.com/v1/checkout.js";
        script.async = true;
        document.body.appendChild(script);
    }, []);
    const options = {
        key: process.env.REACT_APP_PAYMENT_KEY,
        key_secret: process.env.REACT_APP_PAYMENT_SECRET_KEY,

        prefill: {
            name: session?.name,
            contact: session?.mobile_number,
            email: session?.email,
        },
        notes: {
            address: "some address",
        },
        theme: {
            color: "#F37254",
            hide_topbar: false,
        },
    };

    const openPayModal = (options) => {
        var rzp1 = new window.Razorpay(options);

        rzp1.open();
    };
    let test_code = updatedCart?.map(function (i) {
        return i?.test_code;
    });
    let test_ids = updatedCart?.map(function (i) {
        return i?.id;
    });
    let test_idsss = packageItemList?.labtestdetails?.map(function (i) {
        return i?.id;
    });

    useEffect(() => {
        dispatch(
            clickCardHome({
                type: "5",
                health_package_id: test_idsss?.toString(),
            })
        );
    }, [dispatch, labCart]);
  
    const createBooking = (values, resetForm) => {
        try {
            let tempOptions = {
                ...options,
                amount: Math.round(totalamount) * 100,
                currency: "INR",
                name: "THS-User",
                description: "some description",
                image: Logo.THS_logo,
                handler: async function (response) {
                    let updatedValues = {
                        ...values,
                        transaction_id: response.razorpay_payment_id,
                        total_amount: totalamount,
                        total_paid_amount: totalamount,
                        health_package: packageItemList?.id,
                        wallet_amount: wallet_price?.cut_amount,
                        booking_date: moment(formik?.values?.date).format("DD-MM-YYYY"),
                        booking_time: moment(formik?.values?.slot, "hh:mm A").format("hh:mm"),
                        coupen_code: coupencodeid

                    };
                    if (response) {
                    
                        dispatch(HealthPackageBookingCreate(updatedValues)).then((res) => {
                            if (res?.payload?.data?.order_id) {
                             
                                dispatch(HealthPackageBookingDetails(res?.payload?.data?.order_id)).then((res1) => {
                                    dispatch(clearCartItem());
                                    dispatch(clearSelectedCouponCode());
                                    dispatch(clearSelectedMember());
                                    setSuccessfullModel(true);
                                    dispatch(cleaSelectedDate());
                                    setTotalAmount("");
                                });
                            }

                        });
                    }
                },
            };
            const walletprice = packageItemList?.discount_amount - discountAmount
            // let OtherOptions = {
            //     member_id: selectedMemberDetail?.id,
            //     discount: couponCodeDetail?.coupon_percentage
            //         ? couponCodeDetail?.coupon_percentage
            //         : 0,

            //     wallet_amount: wallet_price?.cut_amount,
            //     health_package: packageItemList?.id,
            //     ADDRESS: "",
            //     MEMBER: "",
            //     address_id: "",
            //     transaction_id: "user_wallet",
            //     convenience_fee: "10",
            //     type: "",
            //     gold_discount: "10",
            //     total_amount: totalamount,
            //     total_paid_amount: totalamount,
            //     member_id: selectedMemberDetail?.id,
            //     slot: "",
            //     // booking_date: moment(today).format("DD-MM-YYYY"),
            //     // booking_time: moment(today).format("HH:SS"),
            //     golddiscount: goldMembershipStatus?.discount,
            //     FLAG: "I",
            //     PTNT_GNDR: "M",
            //     FIRST_NAME: selectedMemberDetail?.name,
            //     PTNTNM: selectedMemberDetail?.name,
            //     DOB: moment(selectedMemberDetail?.birthdate).format("DD-MMM-YYYY"),
            //     DOB_ACT_FLG: "Y",
            //     ADDRESS: "",
            //     LOCATION: "address1",
            //     CITY: "",
            //     STATE: "",
            //     COUNTRY: "INDIA",
            //     ZIP: "400064",
            //     COLL_TYPE: "H",
            //     ORDER_SOURCE: "THS",
            //     CREATED_BY: "C000145595",
            //     address_id: "",
            //     termsAndConditions: false,
            //     address_type: "",
            // }
            let updatedValueses = {
                ...values,
                transaction_id: "user_wallet",
                total_amount: totalamount,
                total_paid_amount: totalamount,
                health_package: packageItemList?.id,
                wallet_amount: wallet_price?.cut_amount,
                booking_date: moment(formik?.values?.date).format("DD-MM-YYYY"),
                booking_time: moment(formik?.values?.slot, "hh:mm A").format("hh:mm"),
                coupen_code: coupencodeid

            };
        
            if (totalamount == 0) {
                dispatch(HealthPackageBookingCreate(updatedValueses)).then((res) => {
                    if (res?.payload?.data?.order_id) {
                       
                        dispatch(HealthPackageBookingDetails(res?.payload?.data?.order_id));
                        setSuccessfullModel(true);
                    }
                });

            } else {
                openPayModal(tempOptions);
            }
        } catch (error) { }
    };

    const labTestSchema = Yup.object({
        ADDRESS: Yup.string().required("Please Select Address"),
        slot: Yup.string().required("Please Select Slot"),
        termsAndConditions: Yup.bool().oneOf(
            [true],
            "Before you book your health test ,make sure you are aware about THS terms and conditions."
        ),
    });
    useEffect(() => {

        dispatch(walletPrice({ "wallet_prices": walletbox?.wallet, "cut_amount": 0 }));

    }, [dispatch]);

    const formik = useFormik({
        initialValues: {
            member_id: selectedMemberDetail?.id,
            slot: "",
            // booking_date: moment(today).format("DD-MM-YYYY"),
            // booking_time: moment(today).format("HH:SS"),
            center_id: "17",
            discount: couponCodeDetail?.coupon_percentage
                ? couponCodeDetail?.coupon_percentage
                : "",
            // wallet_amount: userWallet?.wallet,
            delivery_charges: "10",
            address_id: "",
            termsAndConditions: false,
            address_type: "",
            ADDRESS: "",

        },
        validationSchema: labTestSchema,
        onSubmit: (values, { resetForm }) => {
            // do your stuff
            createBooking(values, resetForm);
        },
    });

    useEffect(() => {
        dispatch(CouponList({ coupon_type: 2 }));
        // setSelectMember(true);
    }, [dispatch]);
    const handleCheckboxClick = () => {
        // Replace the URL below with the link you want to open
        const link = 'https://thsindia.in/medical-web/privacy-policy';
        window.open(link, '_blank'); // Opens the link in a new tab
    };
    const coupendiscount = (packageItemList?.discount_amount * Number(couponCodeDetail?.coupon_percentage)) / 100

    function handleChange(event) {
        setwalletbox((prevFormData) => {
            return {
                ...prevFormData,
                [event.target.name]: event.target.checked,
            };
        });

        if (event.target.checked) {
            if (couponCodeDetail) {
                const amout = packageItemList?.discount_amount - walletbox.wallet - coupendiscount - discountAmount;
                setTotalAmount(amout <= 0 ? "0" : amout);
                const cutOfPrice = packageItemList?.discount_amount - (coupendiscount + discountAmount)
                const incAmount = walletbox.wallet - cutOfPrice;
                dispatch(walletPrice({ "wallet_prices": cutOfPrice >= walletbox?.wallet ? "0" : incAmount, "cut_amount": cutOfPrice >= walletbox?.wallet ? "0" : cutOfPrice }));
            } else {
                const amout = totalamount - walletbox.wallet
                const data = totalamount - walletbox.wallet - coupendiscount - discountAmount
                let removeDis;
                if (goldMembershipdetail !== true) {
                    removeDis = totalamount
                } else {
                    removeDis = totalamount
                }
                dispatch(walletPrice({ "wallet_prices": removeDis <= 0 ? 0 : (walletbox?.wallet - removeDis) <= 0 ? 0 : walletbox?.wallet - removeDis, "cut_amount": walletbox?.wallet - removeDis <= 0 ? walletbox?.wallet : removeDis }));
                if (amout >= 0) {
                    setTotalAmount(Math.max(isNaN(amout) ? 0 : amout));
                } else {
                    setTotalAmount(Math.max(isNaN(data) ? 0 : data));
                }
            }
        } else {
            if (couponCodeDetail) {
                const amount = packageItemList?.discount_amount - (coupendiscount + discountAmount)
                if (wallet_price?.wallet_prices == 0) {
                    dispatch(walletPrice({ "wallet_prices": wallet_price?.cut_amount }));
                    setTotalAmount(amount <= 0 ? "0" : amount);
                } else {
                    dispatch(walletPrice({ "wallet_prices": wallet_price?.wallet_prices + (packageItemList?.discount_amount - (coupendiscount + discountAmount)) }));
                    setTotalAmount(amount <= 0 ? "0" : amount);
                }
            } else {
                if (goldMembershipdetail !== true) {
                    const amount = (packageItemList?.discount_amount - discountAmount)
                    if (wallet_price?.wallet_prices == 0) {
                        dispatch(walletPrice({ "wallet_prices": wallet_price?.cut_amount }));
                        setTotalAmount(amount <= 0 ? "0" : amount);
                    } else {
                        dispatch(walletPrice({ "wallet_prices": wallet_price?.wallet_prices + (packageItemList?.discount_amount - discountAmount) }));
                        setTotalAmount(amount <= 0 ? "0" : amount);
                    }
                } else {
                    const amount = packageItemList?.discount_amount
                    if (wallet_price?.wallet_prices == 0) {
                        dispatch(walletPrice({ "wallet_prices": wallet_price?.cut_amount }));
                        setTotalAmount(amount <= 0 ? "0" : amount);
                    } else {
                        dispatch(walletPrice({ "wallet_prices": wallet_price?.wallet_prices + (packageItemList?.discount_amount) }));
                        setTotalAmount(amount <= 0 ? "0" : amount);
                    }
                }
            }
        }
    }

    useEffect(() => {
        let slotData = {
            test_center_id: slectedCityId?.City_id,
            date: moment(today).format("DD-MMM-YYYY"),
        };
        if (slotData) {
            dispatch(LabtestSlot(slotData));
        }
        dispatch(LabtestAddressList());
        dispatch(LabtestStates());
    }, [dispatch]);

    const getDiscount = (cupon) => {
        if (cupon?.coupon_percentage) {
            setTotalAmount(
                packageItemList?.discount_amount -
                (packageItemList?.discount_amount *
                    Number(cupon?.coupon_percentage)) /
                100
            );
        }
    };
    const { bookingDetail } = useSelector(({ BookingSlice }) => BookingSlice);
    function formatDate(dateString) {
        if (!dateString) return ""; // Return an empty string if dateString is empty or undefined
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, "0"); // Get day (2 digits)
        const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Get month (2 digits)
        const year = date.getFullYear().toString().slice(-2); // Get last 2 digits of the year
        return `${day}-${month}-${year}`;
    }

    useEffect(() => {
        if (bookingDetail?.id) {
            // setLabtestmodel(true);
        }
    }, [bookingDetail?.id]);

    useEffect(() => {
        if (selectedMemberDetail) {
            formik.setFieldValue("member_id", selectedMemberDetail?.id);
            formik.setFieldValue("FIRST_NAME", selectedMemberDetail?.name);
            formik.setFieldValue("PTNTNM", selectedMemberDetail?.name);
            formik.setFieldValue(
                "DOB",
                moment(selectedMemberDetail?.birthdate).format("DD-MMM-YYYY")
            );
        }
    }, [selectedMemberDetail]);

    useEffect(() => {
      
        if (labCart.length > 0) {
          
            if (goldMembershipStatus?.discount) {
                const discountAmount = (packageItemList?.discount_amount * goldMembershipStatus?.discount) / 100;
              
                setTotalAmount(packageItemList?.discount_amount - discountAmount);
            } else {
             
                setTotalAmount(packageItemList?.discount_amount);
                setUpdatedCart(labCart);
            }

        } else {
            setTotalAmount(packageItemList?.discount_amount);
            setUpdatedCart(labCart);
        }
    }, [labCart, updatedCart]);
    useEffect(() => {
     
        if (couponCodeDetail) {
            const discountAmount = (packageItemList?.discount_amount * goldMembershipStatus?.discount) / 100;
            setTotalAmount(
                packageItemList?.discount_amount -
                (packageItemList?.discount_amount *
                    Number(couponCodeDetail?.coupon_percentage)) /
                100 - discountAmount
            );
        } else if (goldMembershipdetail != true) {
          
            const discountAmount = (packageItemList?.discount_amount * goldMembershipStatus?.discount) / 100;
         
            setTotalAmount(packageItemList?.discount_amount - discountAmount);
        } else {
             setTotalAmount(packageItemList?.discount_amount);
        }
    }, [couponCodeDetail, goldMembershipdetail, goldMembershipStatus?.discount]);
    const discountAmount = (packageItemList?.discount_amount * goldMembershipStatus?.discount) / 100;





    let test_name = labCart?.map(function (i) {
        return i?.['Test-data']?.id;
    })

    const [isCheck, setIsCheck] = useState(test_name);





    useEffect(() => {
        setValForLoad(true)

        dispatch(addToCarted(labTestList));
    }, [labTestList])
    useEffect(() => {
        // if (21) {

        dispatch(LabtestLabs({
            center_id: labTestCenterDetailsById && labTestCenterDetailsById?.id,
            city_id: labTestCenterDetailsById && labTestCenterDetailsById?.city_id
        }));
        // }
    }, [dispatch, props?.show]);


    return (
        <>
            <HealthCartPayment
                show={successfullModel}
                onHide={() => setSuccessfullModel(false)}
                healthPackageBookingDetails={healthPackageBookingDetails}
                id={healthPackageBookingCreate?.data?.order_id}
            />
            <LabTestModel
                show={labtestmodel}
                onNext={() => {
                    setLabtestmodel(false);
                }}
                onHide={(e) => {
                    setLabtestmodel(false);
                }}
            />
            <HealthMember
                show={selectMember}
                onHide={() => setSelectMember(false)}
            />
            <SelectAddressModel
                show={selectAddressModel}
                setSelectedCityId={setSelectedCityId}
                formik={formik}
                onHide={(e) => {
                    setSelecteAddressModel(false);
                }}
            />

            <MyModal show={modalShow} onHide={() => setModalShow(false)} />

            <MyModal_1
                show={modalShow1}
                onHide={(e) => {
                    setModalShow1(false);
                }}
            />
            <LabTestSlotModel
                formik={formik}
                show={timeSample}
                slectedCityId={slectedCityId}
                onHide={() => settimeSample(false)}
            />
            <ApplyCouponModel
                couponList={couponList}
                show={applyCoupon}
                getDiscount={getDiscount}
                couponType={2}
                onHide={() => setApplyCoupon(!couponList)}
            />
            <form onSubmit={formik.handleSubmit}>
                <Container fluid className="lab_test_cart_container">
                    <Container>
                        <div className="row">
                            <div className="col-md-12">
                                <h3 className="cart_head">Cart</h3>
                            </div>
                        </div>
                        <div className="lab_test_cart_box">
                            <div className="lab_test_cart_box_inner">
                                <div className="row">
                                    <div className="col-xl-8 col-md-7 lab_test_right_padding">
                                        <div className="lab_test_cart_left_side">
                                            <div className="row mb_15">
                                                <div className="col-md-6 col-6">
                                                    <h3 className="lab_test_cart_left_side_title">
                                                        Lab Tests
                                                    </h3>
                                                </div>

                                            </div>
                                            <div>
                                                <div className="pt_10">
                                                  
                                                    {
                                                        packageItemList?.labtestdetails?.map((item) => (
                                                            <>
                                                           
                                                                <div className="row mt_20">
                                                                    <div className="col-md-6 col-6">
                                                                        <div className="lab_test_for_test_box">
                                                                            {/* <img
                                                                                src={Icon.trash}
                                                                                className="lab_test_for_test_icon"
                                                                                alt=""
                                                                                onClick={() =>
                                                                                    dispatch(removeItem(item?.id))
                                                                                }
                                                                            ></img> */}
                                                                            <h3 className="lab_test_for_test_name pl_10">
                                                                                {item?.test_name}
                                                                            </h3>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6 col-6">
                                                                        {/* <h5 className="lab_test_for_test_value">
                                                                            ₹ {item?.price}
                                                                        </h5> */}
                                                                    </div>
                                                                </div>
                                                                <hr className="lab_test_for_test_hr" />
                                                            </>
                                                        ))}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="lab_test_cart_left_side_2">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <h3 className="lab_test_address">Sample Collection Address</h3>
                                                    <div className="lab_test_address_box" onClick={() => setSelecteAddressModel(true)}>
                                                        {formik?.values?.address_type ? (
                                                            <>
                                                                <h2 className="lab_test_address_box_texts">{formik.values.address_type}</h2>
                                                                <h5 className="lab_test_address_box_textes">
                                                                    {formik.values.ADDRESS ? formik.values.ADDRESS + ", " : ""}
                                                                    {formik.values.CITY ? formik.values.CITY + ", " : ""}
                                                                    {formik.values.STATE ? formik.values.STATE + ", " : ""}
                                                                    {formik.values.COUNTRY ? formik.values.COUNTRY : ""}
                                                                </h5>
                                                                <div className="changese">
                                                                    <img src={Icon.edit} alt="Edit Icon" className="editimg" />
                                                                    <p className="changes">Change Address</p>
                                                                </div>
                                                            </>
                                                        ) : <>
                                                            <h2 className="lab_test_address_box_texts">
                                                                {formik?.values?.address_type
                                                                    ? formik?.values?.address_type
                                                                    : "Select"}
                                                            </h2>
                                                        </>}
                                                    </div>

                                                    <div className="error_text pt-2">
                                                        {" "}
                                                        {formik.errors.ADDRESS &&
                                                            formik.touched.ADDRESS &&
                                                            formik.errors.ADDRESS}
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <h3 className="lab_test_time">Select Member</h3>
                                                    <div className="lab_test_address_box" onClick={() => setSelectMember(true)}>

                                                        {
                                                            selectedMemberDetail == [] ? <>
                                                                <h2 className="lab_test_address_box_texts">Select</h2>
                                                            </>
                                                                :
                                                                <>
                                                                    <div className="changese">
                                                                        <div>
                                                                            <img src={selectedMemberDetail?.image} className="memberimg"></img>
                                                                        </div>
                                                                        <div>
                                                                            <h5 className="radiology_booked_test_for_name">
                                                                                {selectedMemberDetail?.name}
                                                                            </h5>
                                                                            <p className="memberlist">{selectedMemberDetail?.gender},Age: {selectedMemberDetail?.age}</p>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                        }

                                                    </div>
                                                    <div className="pt_20">
                                                        <h3 className="lab_test_time">Date and Time</h3>
                                                        <div className="lab_test_address_box" onClick={() => settimeSample(true)} >
                                                            <h5 className="lab_test_time_box_text">
                                                                {
                                                                    formik?.values?.slot
                                                                        ? `${formatDate(formik?.values?.date)} , ${formik?.values?.slot}`
                                                                        : "Select"
                                                                }

                                                            </h5>
                                                        </div>
                                                        <div className="error_text pt-2">
                                                            {" "}
                                                            {formik.errors.slot &&
                                                                formik.touched.slot &&
                                                                formik.errors.slot}
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-md-5 lab_test_left_padding">
                                        <div className="lab_test_cart_right_side">
                                            <h5 className="offer_benifit_text">
                                                Offers &amp; Benifits
                                            </h5>
                                            <div
                                                className="offer_benifit_card"
                                                onClick={() => setApplyCoupon(true)}
                                            >
                                                <div className="apply_coupon_box">
                                                    <img
                                                        src={Icon.coupon}
                                                        className="apply_coupon_icon"
                                                        alt=""
                                                    ></img>
                                                    <h5 className="offer_benifit_text">Apply Coupon</h5>
                                                </div>
                                                <div>
                                                    <svg
                                                        width="8"
                                                        height="14"
                                                        className="subscrip_consult_card_arrow"
                                                        viewBox="0 0 8 14"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M1.25 12.5L6.75 7L1.25 1.5"
                                                            stroke="#9393AA"
                                                            strokeWidth="2"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                        />
                                                    </svg>
                                                </div>
                                            </div>
                                            <h5 className="payment_summary">Payment Summary</h5>
                                            <div className="payment_summary_card">
                                                <div className="payment_summary_card_inner mb_5">
                                                    <div>
                                                        <h5 className="payment_summary_title">
                                                            Total amount
                                                        </h5>
                                                    </div>
                                                    <div>
                                                        <h5 className="payment_summary_price">
                                                            {/* ₹ {packageItemList?.discount_amount} */}
                                                            ₹  {packageItemList?.discount_amount}

                                                        </h5>
                                                    </div>
                                                </div>
                                                {couponCodeDetail && (
                                                    <div className="payment_summary_card_inner">
                                                        <div>
                                                            <h5 className="payment_summary_title">
                                                                Discount
                                                            </h5>
                                                        </div>
                                                        <div>
                                                            {/* <h5 className="payment_summary_price_discount">
                                                                - ₹{" "}
                                                                {packageItemList?.discount_amount -
                                                                    totalamount}
                                                            </h5> */}
                                                            <h5 className="payment_summary_price_discount">
                                                                - ₹{" "}
                                                                {(coupendiscount).toFixed(0)}
                                                            </h5>

                                                        </div>
                                                    </div>
                                                )}
                                                {
                                                    goldMembershipdetail == true ? "" : <>
                                                        <div className="payment_summary_card_inner">
                                                            <div>
                                                                <h5 className="payment_done_payment_info_title" style={{ color: "#48988E" }}>
                                                                    Gold Discount [{goldMembershipStatus?.discount}%]
                                                                </h5>
                                                            </div>
                                                            <div>
                                                                <h5 className="payment_summary_price_discount">
                                                                    - ₹{" "}
                                                                    {discountAmount && discountAmount.toFixed(0)}


                                                                </h5>
                                                            </div>
                                                        </div>
                                                    </>
                                                }

                                                <hr className="payment_hr" />
                                                <div className="payment_summary_card_inner">
                                                    <div>
                                                        <h5 className="payment_summary_total_title">
                                                            Total Payable
                                                        </h5>
                                                    </div>
                                                    <div>
                                                        <h5 className="payment_summary_total_price">
                                                            ₹ {totalamount ? parseFloat(totalamount).toFixed(0) : 0}
                                                        </h5>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="wallet_card">
                                                <div>
                                                    <div className="form-group">
                                                        <input
                                                            type="checkbox"
                                                            id={walletbox.id}
                                                            onChange={handleChange}
                                                            name={userWallet?.wallet}
                                                            value={walletbox}
                                                        />
                                                        <label className="wallet_text" for={walletbox.id}>
                                                            <span className="wallet_subtext">
                                                                Use wallet Amount
                                                            </span>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div>
                                                    <h5 className="available_bal_text">
                                                        Avbl. bal.
                                                        <span className="available_bal_price">
                                                            ₹{parseFloat(wallet_price?.wallet_prices).toFixed(0) || "0"}
                                                        </span>
                                                    </h5>
                                                </div>
                                            </div>
                                            <h5 className="T_C_title pt_10">Terms and conditions</h5>
                                            <div className="lab_T_C_card">
                                                <p className="T_C_text">
                                                    Before you book consultation, make sure you are aware
                                                    about THS terms and conditions.
                                                </p>
                                                <div className="col-md-12">
                                                    <div>
                                                        <div className="healthpackage">
                                                            <input
                                                                id="checkbox1"
                                                                type="checkbox"
                                                                className="vh"
                                                                name="termsAndConditions"
                                                                onChange={() =>
                                                                    formik.setFieldValue(
                                                                        "termsAndConditions",
                                                                        !formik.values.termsAndConditions
                                                                    )
                                                                }
                                                            />
                                                            <label
                                                                className="healthpackage_checkbox_title"
                                                                htmlFor="checkbox1"

                                                                style={{
                                                                    display: 'inline-flex',
                                                                    alignItems: 'center',
                                                                }}
                                                            >
                                                                {" "}
                                                                <span
                                                                    className="i_agree_cancellation_policy"
                                                                    style={{ padding: "25px" }}
                                                                >
                                                                    <h5 className="view_T_C_text"
                                                                        onClick={handleCheckboxClick}
                                                                    >
                                                                        View Terms And Conditions
                                                                    </h5>
                                                                </span>
                                                            </label>
                                                        </div>

                                                        {formik?.errors?.termsAndConditions &&
                                                            formik.touched.termsAndConditions && (
                                                                <div className="terms_conditions_error_text">
                                                                    {formik?.errors?.termsAndConditions}
                                                                </div>
                                                            )}
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="lab_test_footer_card">
                                <div className="lab_test_card_box">
                                    <div className="lab_test_price_box">
                                        <h5 className="lab_test_price">Rs {totalamount ? parseFloat(totalamount).toFixed() : 0}</h5>
                                        <h6 className="lab_test_subtitle">Amount to pay</h6>
                                    </div>
                                    <div>
                                        <Button className="lab_test_btn" type="submit">
                                            Pay Now
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Container>
                </Container>
            </form >
        </>
    );
}

const MyModal = (props) => {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            dialogClassName="modal_476"
        >
            <Modal.Header
                className=""
                style={{ marginBottom: "none", border: "none" }}
            >
                <h5 className="select_consult_member_modal_title">
                    Select Consultation Member
                </h5>
                <img
                    src={Icon.cross}
                    className="book_modal_close"
                    onClick={props.onHide}
                    alt=""
                ></img>
            </Modal.Header>
            <Modal.Body>
                <div className="consult_member_box">
                    <div className="consult_member_box_inner">
                        <div>
                            <h5 className="consult_members_title">
                                Rushabh Patel
                                <span className="consult_members_subtitle">
                                    ( Male, Age:25 )
                                </span>
                            </h5>
                        </div>
                        <div>
                            <input type="radio" id="test1" name="radio-group" checked />
                        </div>
                    </div>
                </div>
                <hr className="consult_member_hr" />
                <div className="consult_member_box">
                    <div className="consult_member_box_inner_2">
                        <div>
                            <h5 className="consult_members_title">
                                Panjak Patel
                                <span className="consult_members_subtitle">
                                    ( Male, Age:65 )
                                </span>
                            </h5>
                        </div>
                        <div>
                            <input type="radio" id="test1" name="radio-group" />
                        </div>
                    </div>
                </div>
                <div className="add_member_box">
                    <img src={Icon.add} className="add_member_icon" alt=""></img>
                    <h5 className="add_member_text">Add New Member</h5>
                </div>
                <center>
                    <Button className="consult_modal_btn">Continue</Button>
                </center>
            </Modal.Body>
        </Modal>
    );
};

const MyModal_1 = (props1) => {
    return (
        <Modal
            {...props1}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            dialogClassName="modal_500"
        >
            <Modal.Header
                className="lab_test_modal_box_head_padding"
                style={{ marginBottom: "none", border: "none" }}
            >
                <h5 className="lab_test_popup_title">
                    Select Address
                </h5>
                <img
                    src={Icon.cross}
                    className="lab_test_popup_close"
                    alt=""
                    onClick={props1.onHide}
                ></img>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <div className="select_member_box_2 select_box">
                        <div className="consult_member_box_1">
                            <div className="consult_member_box_inner">
                                <div>
                                    <input
                                        type="radio"
                                        id="test1"
                                        name="radio-group"
                                        className="mt_10"
                                        style={{ marginTop: "20px", accentColor: "#199a8e" }}
                                        checked
                                    />
                                    <lable for="test1">
                                        <h5 className="lab_sample_collection_add_title">HOME</h5>
                                        <h5 className="lab_sample_collection_add_para">
                                            11 shivam flat, newar sport complex,
                                            <br /> tulishidham, manjalpur, vadodara
                                        </h5>
                                    </lable>
                                </div>
                            </div>
                        </div>
                        <hr className="consult_member_hr" />
                        <div className="consult_member_box_1">
                            <div
                                className="consult_member_box_inner_2"
                                style={{ marginTop: "3px" }}
                            >
                                <div>
                                    <input
                                        type="radio"
                                        id="test2"
                                        name="radio-group"
                                        style={{ marginTop: "20px", accentColor: "#199a8e" }}

                                    />
                                    <lable for="test2">
                                        <h5 className="lab_sample_collection_add_title">OFFICE</h5>
                                        <h5 className="lab_sample_collection_add_para">
                                            11 shivam flat, newar sport complex,
                                            <br />
                                            tulishidham, manjalpur, vadodara
                                        </h5>
                                    </lable>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="add_member_box">
                        <img src={Icon.add} className="add_member_icon" alt=""></img>
                        <h5
                            className="add_member_text"
                            onClick={() => {
                                props1.onHide(true);
                            }}
                        >
                            Add New Address
                        </h5>
                    </div>
                    <center>
                        <Button className="consult_modal_btn" onClick={props1.onHide}>
                            Continue
                        </Button>
                    </center>
                </div>
            </Modal.Body>
            <Modal.Footer style={{ border: "none", display: "none" }}></Modal.Footer>
        </Modal>
    );
};

export default HealthCartDetail;
