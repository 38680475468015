import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  LabtestAddressListAPI,
  LabtestBookingCreateAPI,
  LabtestBookingDetailsAPI,
  LabtestCancelAPI,
  LabtestCancelReasonsAPI,
  LabtestCitiesAPI,
  LabtestCoupanListAPI,
  LabtestLabsAPI,
  LabtestPastBookingsAPI,
  LabtestResultReportAPI,
  LabtestSlotAPI,
  LabtestStatesAPI,
  LabtestStatusAPI,
  LabtestUpcomingBookingAPI,
  LabtestUserAddressAPI,
  LabTestCentersApi,
  LabTestCenterDetailsApi,
  HealthCareBookingDetailsAPI,
  HealthPackageBookingCreateAPI,
  HealthPackageUpcomingBookingAPI,
  HealthPackageBookingDetailsAPI,
  HealthPackagePastBookingAPI,
  MemberShipDeatilBookingCreateAPI,
  HealthPackageUpcomingBookingesAPI,
} from "../../Routes/Service";
import { AlertEnum } from "../../Utilities/Enums";
import { setLoading, setMessage } from "./LayoutsSice";

const initialState = {
  labTestList: [],
  labCart: [],
  bookNow: {},
  selectedMemberDetail: "",
  labtestSlotList: [],
  labtestAddressList: [],
  labtestStates: [],
  labtestCities: [],
  labtestUpcomingBookingList: [],
  healthPackageUpcomingBookingLists: [],
  HealthPackageUpcomingBookingesList: [],
  healthPackagepastBookingList: [],
  labtestCancelReasons: [],
  selectedCancelReasons: "",
  labtestBookingDetailsById: "",
  healthCareBookingDetailsById: "",
  labtestPastBookingsList: [],
  labtestCoupanListData: [],
  labTestCenterList: [],
  labTestCenterDetailsById: "",
  labTestCenterDetalisList: [],
  goldMembershipdetail: "",
  labbookinglist: "",
  list: [],
  healthPackageBookingCreate: "",
  healthPackageBookingDetails: ""
};

export const LabtestLabs = createAsyncThunk(
  "LabtestLabs",
  async (values, { dispatch }) => {
    try {
      const result = await LabtestLabsAPI(values);
      if (result) {
        return result?.data;
      } else {
        throw result;
      }
    } catch (error) {
      dispatch(setLoading(false));
      // dispatch(
      //   setMessage({
      //     text: error?.message,
      //     type: AlertEnum.Error,
      //   })
      // );
      return error;
    }
  }
);
export const LabTestCenters = createAsyncThunk(
  "LabTestCenters",
  async (values, { dispatch }) => {
    try {
      const result = await LabTestCentersApi(values);
      if (result) {
        return result?.data;
      } else {
        throw result;
      }
    } catch (error) {
      dispatch(setLoading(false));
      // dispatch(
      //   setMessage({
      //     text: error?.message,
      //     type: AlertEnum.Error,
      //   })
      // );
      return error;
    }
  }
);
export const LabTestCenterDetails = createAsyncThunk(
  "LabTestCenterDetails",
  async (values, { dispatch }) => {
    try {
      const result = await LabTestCenterDetailsApi({
        test_center_id: values,
      });
      if (result) {
        return result?.data;
      } else {
        throw result;
      }
    } catch (error) {
      dispatch(setLoading(false));
      // dispatch(
      //   setMessage({
      //     text: error?.message,
      //     type: AlertEnum.Error,
      //   })
      // );
      return error;
    }
  }
);

export const LabtestSlot = createAsyncThunk(
  "labtestSlotList",
  async (values, { dispatch }) => {
    try {
      const result = await LabtestSlotAPI(values);
      return result?.data

      // if (result) {
      //   return result?.RSP_MSG;
      // } else {
      //   throw result;
      // }
    } catch (error) {
      // dispatch(setLoading(false));
      // dispatch(
      //   setMessage({
      //     text: error?.message,
      //     type: AlertEnum.Error,
      //   })
      // );
      // return error;
    }
  }
);
export const LabtestCancelReasons = createAsyncThunk(
  "LabtestCancelReasons",
  async (values, { dispatch }) => {
    try {
      const result = await LabtestCancelReasonsAPI({ pClientCode: values });

      return result?.data
      // if (result) {
      //   return result?.RSP_MSG;
      // } else {
      //   throw result;
      // }
    } catch (error) {
      dispatch(setLoading(false));
      // dispatch(
      //   setMessage({
      //     text: error?.message,
      //     type: AlertEnum.Error,
      //   })
      // );
      return error;
    }
  }
);
export const LabtestCancel = createAsyncThunk(
  "LabtestCancel",
  async (values, { dispatch }) => {
    try {
      const result = await LabtestCancelAPI(values);
      if (result) {
        dispatch(
          setMessage({
            text: result?.RSP_DESC,
            type: AlertEnum.Success,
          })
        );
        dispatch(LabtestUpcomingBooking());
        return result?.data;
      } else {
        throw result;
      }
    } catch (error) {
      dispatch(setLoading(false));
      // dispatch(
      //   setMessage({
      //     text: error?.message,
      //     type: AlertEnum.Error,
      //   })
      // );
      return error;
    }
  }
);
export const LabtestStatus = createAsyncThunk(
  "LabtestStatus",
  async (values, { dispatch }) => {
    try {
      const result = await LabtestStatusAPI(values);
      if (result) {
        return result?.data;
      } else {
        throw result;
      }
    } catch (error) {
      dispatch(setLoading(false));
      // dispatch(
      //   setMessage({
      //     text: error?.message,
      //     type: AlertEnum.Error,
      //   })
      // );
      return error;
    }
  }
);
export const LabtestResultReport = createAsyncThunk(
  "LabtestResultReport",
  async (values, { dispatch }) => {
    try {
      const result = await LabtestResultReportAPI(values);
      if (result) {
        return result?.data;
      } else {
        throw result;
      }
    } catch (error) {
      dispatch(setLoading(false));
      // dispatch(
      //   setMessage({
      //     text: error?.message,
      //     type: AlertEnum.Error,
      //   })
      // );
      return error;
    }
  }
);
export const LabtestBookingDetails = createAsyncThunk(
  "LabtestBookingDetails",
  async (values, { dispatch }) => {
    try {
      const result = await LabtestBookingDetailsAPI({ booking_id: values });
      if (result) {
        return result?.data;
      } else {
        throw result;
      }
    } catch (error) {
      dispatch(setLoading(false));
      // dispatch(
      //   setMessage({
      //     text: error?.message,
      //     type: AlertEnum.Error,
      //   })
      // );
      return error;
    }
  }
);
export const HealthCareBookingDetails = createAsyncThunk(
  "HealthCareBookingDetails",
  async (values, { dispatch }) => {
    try {
      const result = await HealthCareBookingDetailsAPI({ booking_id: values });

      if (result) {
        return result?.data;
      } else {
        throw result;
      }
    } catch (error) {
      dispatch(setLoading(false));
      // dispatch(
      //   setMessage({
      //     text: error?.message,
      //     type: AlertEnum.Error,
      //   })
      // );
      return error;
    }
  }
);
export const HealthPackageBookingDetails = createAsyncThunk(
  "HealthPackageBookingDetails",
  async (values, { dispatch }) => {
    try {
      const result = await HealthPackageBookingDetailsAPI({ booking_id: values });

      if (result) {
        return result;
      } else {
        throw result;
      }
    } catch (error) {
      dispatch(setLoading(false));
      // dispatch(
      //   setMessage({
      //     text: error?.message,
      //     type: AlertEnum.Error,
      //   })
      // );
      return error;
    }
  }
);
export const GoldMemberBookingDetails = createAsyncThunk(
  "GoldMemberBookingDetails",
  async (values, { dispatch }) => {
    try {
      const result = await MemberShipDeatilBookingCreateAPI();

      if (result) {
        return result?.data;
      } else {
        throw result;
      }
    } catch (error) {
      dispatch(setLoading(false));
      // dispatch(
      //   setMessage({
      //     text: error?.message,
      //     type: AlertEnum.Error,
      //   })
      // );
      return error;
    }
  }
);
export const LabtestStates = createAsyncThunk(
  "LabtestStates",
  async (values, { dispatch }) => {
    try {
      const result = await LabtestStatesAPI(values);
      if (result) {
        return result?.RSP_MSG;
      } else {
        throw result;
      }
    } catch (error) {
      dispatch(setLoading(false));
      // dispatch(
      //   setMessage({
      //     text: error?.message,
      //     type: AlertEnum.Error,
      //   })
      // );
      return error;
    }
  }
);

export const LabtestCities = createAsyncThunk(
  "LabtestCities",
  async (values, { dispatch }) => {
    try {

      const result = await LabtestCitiesAPI({ StateID: values });

      if (result) {

        return result?.RSP_MSG;
      } else {

        throw result;
      }
    } catch (error) {
      dispatch(setLoading(false));
      // dispatch(
      //   setMessage({
      //     text: error?.message,
      //     type: AlertEnum.Error,
      //   })
      // );
      return error;
    }
  }
);

export const LabtestAddressList = createAsyncThunk(
  "LabtestAddressList",
  async (values, { dispatch }) => {
    try {
      const result = await LabtestAddressListAPI(values);
      if (result) {
        return result?.data;
      } else {
        throw result;
      }
    } catch (error) {
      dispatch(setLoading(false));
      // dispatch(
      //   setMessage({
      //     text: error?.message,
      //     type: AlertEnum.Error,
      //   })
      // );
      return error;
    }
  }
);

export const LabtestUserAddress = createAsyncThunk(
  "LabtestUserAddress",
  async (values, { dispatch }) => {
    try {
      const result = await LabtestUserAddressAPI(values);
      if (result) {
        dispatch(LabtestAddressList());
        return result?.data;
      } else {
        throw result;
      }
    } catch (error) {
      dispatch(setLoading(false));
      // dispatch(
      //   setMessage({
      //     text: error?.message,
      //     type: AlertEnum.Error,
      //   })
      // );
      return error;
    }
  }
);

export const LabtestUpcomingBooking = createAsyncThunk(
  "LabtestUpcomingBooking",
  async (values, { dispatch }) => {
    try {
      const result = await LabtestUpcomingBookingAPI(values);
      if (result) {
        return result?.data;
      } else {
        throw result;
      }
    } catch (error) {
      dispatch(setLoading(false));
      // dispatch(
      //   setMessage({
      //     text: error?.message,
      //     type: AlertEnum.Error,
      //   })
      // );
      return error;
    }
  }
);
export const HealthPackageUpcomingBooking = createAsyncThunk(
  "HealthPackageUpcomingBooking",
  async (values, { dispatch }) => {
    try {
      const result = await HealthPackageUpcomingBookingAPI(values);
      if (result) {
    
        return result?.data;
      } else {
        throw result;
      }
    } catch (error) {
      dispatch(setLoading(false));
      // dispatch(
      //   setMessage({
      //     text: error?.message,
      //     type: AlertEnum.Error,
      //   })
      // );
      return error;
    }
  }
);




export const HealthPackageUpcomingBookinges = createAsyncThunk(
  "HealthPackageUpcomingBookinges",
  async (values, { dispatch }) => {
    try {
   
      const result = await HealthPackageUpcomingBookingesAPI(values);
      if (result) {

        return result?.data;
      } else {
        throw result;
      }
    } catch (error) {
      dispatch(setLoading(false));
      // dispatch(
      //   setMessage({
      //     text: error?.message,
      //     type: AlertEnum.Error,
      //   })
      // );
      return error;
    }
  }
);




export const HealthPackagePastBooking = createAsyncThunk(
  "HealthPackagePastBooking",
  async (values, { dispatch }) => {
    try {
      const result = await HealthPackagePastBookingAPI(values);
      if (result) {
        return result?.data;
      } else {
        throw result;
      }
    } catch (error) {
      dispatch(setLoading(false));
      // dispatch(
      //   setMessage({
      //     text: error?.message,
      //     type: AlertEnum.Error,
      //   })
      // );
      return error;
    }
  }
);


export const LabtestBookingCreate = createAsyncThunk(
  "LabtestBookingCreate",
  async (values, { dispatch }) => {
    try {
      const result = await LabtestBookingCreateAPI(values);
   

      if (result) {
        // dispatch(LabtestUpcomingBooking());
        return result;
      } else {
        throw result;
      }
    } catch (error) {
      dispatch(setLoading(false));
      // dispatch(
      //   setMessage({
      //     text: error?.message,
      //     type: AlertEnum.Error,
      //   })
      // );
      return error;
    }
  }
);
export const HealthPackageBookingCreate = createAsyncThunk(
  "HealthPackageBookingCreate",
  async (values, { dispatch }) => {
    try {
      const result = await HealthPackageBookingCreateAPI(values);
      if (result) {
        // dispatch(LabtestUpcomingBooking());
        // dispatch(HealthPackageUpcomingBookingAPI());
        // dispatch(HealthPackagePastBookingAPI());

        return result;
      } else {
        throw result;
      }
    } catch (error) {
      dispatch(setLoading(false));
      // dispatch(
      //   setMessage({
      //     text: error?.message,
      //     type: AlertEnum.Error,
      //   })
      // );
      return error;
    }
  }
);
export const LabtestPastBookings = createAsyncThunk(
  "LabtestPastBookings",
  async (values, { dispatch }) => {
    try {
      const result = await LabtestPastBookingsAPI(values);
      if (result) {
        return result?.data;
      } else {
        throw result;
      }
    } catch (error) {
      dispatch(setLoading(false));
      // dispatch(
      //   setMessage({
      //     text: error?.message,
      //     type: AlertEnum.Error,
      //   })
      // );
      return error;
    }
  }
);
export const LabtestCoupanList = createAsyncThunk(
  "LabtestCoupanList",
  async (values, { dispatch }) => {
    try {
      const result = await LabtestCoupanListAPI(values);
      if (result) {
        return result?.data;
      } else {
        throw result;
      }
    } catch (error) {
      dispatch(setLoading(false));
      // dispatch(
      //   setMessage({
      //     text: error?.message,
      //     type: AlertEnum.Error,
      //   })
      // );
      return error;
    }
  }
);

export const LabtestSlice = createSlice({
  name: "LabtestSlice",
  initialState,
  reducers: {
    SelectedMember: (state, action) => {
      state.selectedMemberDetail = action?.payload;
    },
    clearSelectedMember: (state, action) => {
      state.selectedMemberDetail = "";
    },
    addToCart: (state, action) => {

      if (action.payload) {
     
        const itemInCart = state.labCart.find(
          (item) => item.id === action.payload.id
        );
        if (itemInCart) {
          itemInCart.quantity++;
        } else {
          state.labCart.push({ ...action.payload, quantity: 1 });
        }
      }
    },

    bookButton: (state, action) => {
      state.bookNow = action.payload
    },
    clearbookButton: (state, action) => {
      state.bookNow = {}
    },
    updatelist: (state, action) => {
      // state.list = [ ...state.list , ...action.payload ];
 
      state.list.push(...action.payload)
    },

    clearList: (state, action) => {
 
      state.list = []
    },


    addToCarted: (state, action) => {
      state.labCart = action.payload
      // const itemInCart = state.labCart.find(
      //   (item) => item.id === action.payload.id
      // );
      // if (itemInCart) {
      //   itemInCart.quantity++;
      // } else {
      //   state.labCart.push({ ...action.payload, quantity: 1 });
      // }
    },


    removeItem: (state, action) => {
      const removeItem = state.labCart.filter(
        (item) => item.id !== action.payload
      );
      state.labCart = removeItem;
    },
    clearCartItem: (state, action) => {
      state.labCart = [];
    },
    SelectedCancelReasons: (state, action) => {
   
      state.selectedCancelReasons = action?.payload;
    },
    clearLabTestCenterDetailsById: (state, action) => {
      state.labTestCenterDetailsById = "";
    },
    setLabTestCenterDetailsById: (state, action) => {
      state.labTestCenterDetailsById = action.payload;
    },

    setLabBookingDetailsById: (state, action) => {
      state.labtestBookingDetailsById = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(LabtestLabs.fulfilled, (state, action) => {
      state.labTestList = action.payload;
    
      if (state.list[0] == undefined || state.list[0].id != action.payload[0].id) {
        state.list.push(...action.payload)
      }
      
    });

    builder.addCase(LabtestSlot.fulfilled, (state, action) => {

      state.labtestSlotList = action.payload;
    });
    builder.addCase(LabtestAddressList.fulfilled, (state, action) => {
      state.labtestAddressList = action.payload;
    });
    builder.addCase(HealthPackageBookingCreate.fulfilled, (state, action) => {

      state.healthPackageBookingCreate = action.payload;
    });

    builder.addCase(LabtestStates.fulfilled, (state, action) => {
      state.labtestStates = action.payload;
    });

    builder.addCase(HealthPackageBookingDetails.fulfilled, (state, action) => {

      state.healthPackageBookingDetails = action.payload;
    });

    builder.addCase(LabtestCities.fulfilled, (state, action) => {
      if (action.payload == undefined) {
        state.labtestCities = []
      } else {
        state.labtestCities = action.payload;
      }

    });
    builder.addCase(LabtestUpcomingBooking.fulfilled, (state, action) => {
      state.labtestUpcomingBookingList = action.payload;
    });
    builder.addCase(HealthPackageUpcomingBooking.fulfilled, (state, action) => {
      state.healthPackageUpcomingBookingLists = ""
      state.healthPackageUpcomingBookingLists = action.payload;
    });

    builder.addCase(HealthPackageUpcomingBookinges.fulfilled, (state, action) => {
      state.HealthPackageUpcomingBookingesList = action.payload;
    });
    builder.addCase(HealthPackagePastBooking.fulfilled, (state, action) => {
      state.healthPackagepastBookingList = action.payload;
    });
    builder.addCase(LabtestCancelReasons.fulfilled, (state, action) => {
  
      state.labtestCancelReasons = action.payload;
    });
    builder.addCase(LabtestBookingDetails.fulfilled, (state, action) => {
      state.labtestBookingDetailsById = action.payload;
    });
    builder.addCase(GoldMemberBookingDetails.fulfilled, (state, action) => {
   
      {
        if (action.payload == undefined) {
          state.goldMembershipdetail = true;
        } else {
          state.goldMembershipdetail = action.payload;
        }
      }
      // state.goldMembershipdetail = action.payload;
    });
    builder.addCase(HealthCareBookingDetails.fulfilled, (state, action) => {
    
      state.healthCareBookingDetailsById = action.payload;
    });
    builder.addCase(LabtestPastBookings.fulfilled, (state, action) => {
      state.labtestPastBookingsList = action.payload;
    });
    builder.addCase(LabtestCoupanList.fulfilled, (state, action) => {
      state.labtestCoupanListData = action.payload;
    });
    builder.addCase(LabTestCenters.fulfilled, (state, action) => {
      state.labTestCenterList = action.payload;
    });
    builder.addCase(LabTestCenterDetails.fulfilled, (state, action) => {
      state.labTestCenterDetalisList = action.payload;
    });
    builder.addCase(LabtestBookingCreate.fulfilled, (state, action) => {
      state.labbookinglist = action.payload;
    });

  },
});

export const {
  addToCart,
  addToCarted,
  SelectedMember,
  removeItem,
  SelectedCancelReasons,
  clearCartItem,
  clearSelectedMember,
  setLabTestCenterDetailsById,
  clearLabTestCenterDetailsById,
  updatelist,
  clearList,
  bookButton,
  clearbookButton
} = LabtestSlice.actions;
export default LabtestSlice.reducer;
