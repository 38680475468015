import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { VerifySessionAPI } from "../../Components/Common/Service";
import {
  OTPResendSignInAPI,
  OTPVerifySignInAPI,
  SignInAPI,
  GetMedKartTokenAPI,
} from "../../Routes/Service";
import {
  AlertEnum,
  SESSION,
  TOKEN,
  MK_APPID,
  MK_CLIENT,
  MK_SECRET,
  MK_TOKEN,
} from "../../Utilities/Enums";
import { setLoading, setMessage } from "./LayoutsSice";
import { GetFirbaseToken } from "../../Utilities/Firebase.config";

const initialState = {
  token: `${localStorage.getItem(TOKEN) || ""}`,
  session: JSON.parse(localStorage.getItem(SESSION)) || "",
  medkart_token: `${localStorage.getItem(MK_TOKEN) || ""}`,
};

export const SignIn = createAsyncThunk(
  "SignIn",
  async (values, { dispatch }) => {
    const fcmToken = await GetFirbaseToken();
    try {
      if (fcmToken) {
        values["device_token"] = fcmToken;
      }
      dispatch(setLoading(true));
      const result = await SignInAPI(values);
      if (result?.success) {
        dispatch(setLoading(false));
        dispatch(setSession(result?.data));
        // dispatch(GetUserProfile());
        return result;
      } else {
        throw result;
      }
    } catch (error) {
      dispatch(setLoading(false));
      // dispatch(
      //   setMessage({
      //     text: error?.message,
      //     type: AlertEnum.Error,
      //   })
      // );
      return error;
    }
  }
);
export const OTPResendSignIn = createAsyncThunk(
  "OTPResendSignIn",
  async (values, { dispatch }) => {
    const fcmToken = await GetFirbaseToken();
    try {
      if (fcmToken) {
        values["device_token"] = fcmToken;
      }
      const result = await OTPResendSignInAPI(values);
      if (result?.success) {
        dispatch(
          setMessage({
            text: result?.message,
            type: AlertEnum.Success,
          })
        );
        return result;
      } else {
        throw result;
      }
    } catch (error) {
      dispatch(setLoading(false));
      // dispatch(
      //   setMessage({
      //     text: error?.message,
      //     type: AlertEnum.Error,
      //   })
      // );
      return error;
    }
  }
);

export const OTPVerifySignIn = createAsyncThunk(
  "OTPVerifySignIn",
  async (values, { dispatch }) => {
    const fcmToken = await GetFirbaseToken();

    try {
      if (fcmToken) {
        values["device_token"] = fcmToken;
      }
      const result = await OTPVerifySignInAPI(values);
      if (result?.success) {
        return result.data;
      } else {
        dispatch(
          setMessage({
            text: result?.message,
            type: AlertEnum.Success,
          })
        );
        throw result;
      }
    } catch (error) {
      dispatch(setLoading(false));
      // dispatch(
      //   setMessage({
      //     text: error?.message,
      //     type: AlertEnum.Error,
      //   })
      // );
      return error;
    }
  }
);
export const VerifySession = createAsyncThunk(
  "VerifySession",
  async (values, { dispatch }) => {
    try {
      const result = await VerifySessionAPI(values);
      if (result?.success) {
        return result;
      } else {
        throw result;
      }
    } catch (error) {
      // dispatch(
      //   setMessage({
      //     text: error?.message,
      //     type: AlertEnum.Error,
      //   })
      // );
      return error;
    }
  }
);
export const GetMedkartToken = createAsyncThunk(
  "GetMedkartToken",
  async (values, { dispatch }) => {
    try {
      const result = await GetMedKartTokenAPI({
        client_id: MK_CLIENT,
        app_id: MK_APPID,
        secret: MK_SECRET,
      });
      if (result) {
        localStorage.setItem(MK_TOKEN, result?.data?.token);
        return result;
      } else {
        throw result;
      }
    } catch (error) {
      // dispatch(
      //   setMessage({
      //     text: error?.message,
      //     type: AlertEnum.Error,
      //   })
      // );
      return error;
    }
  }
);

export const AuthSlice = createSlice({
  name: "AuthSlice",
  initialState,
  reducers: {
    setSession: (state, action) => {
      state.session = action?.payload;
      state.token = action?.payload?.token;
      localStorage.setItem(SESSION, JSON.stringify(action?.payload));
      localStorage.setItem(TOKEN, action?.payload?.token);
    },
    removeSession: (state) => {
      localStorage.removeItem(SESSION);
      localStorage.removeItem(TOKEN);
      localStorage.removeItem(MK_TOKEN);

      state.session = "";
      state.token = "";
    },
  },
});

export const { setSession, removeSession } = AuthSlice.actions;

export default AuthSlice.reducer;
