import React, { useState } from "react";
import { useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { LabTestCenters, LabtestCities, setLabTestCenterDetailsById } from "../../../Store/Reducer/LabtestSlice";
import { Icon } from "../../../Utilities/Icons";
import location from '../../../Assets/img/png/location.png'
import CommonSlice, { GetCity } from "../../../Store/Reducer/CommonSlice";

const LabCityModal = (props) => {

    const dispatch = useDispatch();

    const { labtestStates, labtestCities } = useSelector(
        ({ LabtestSlice }) => LabtestSlice
    );
    const { city } = useSelector(({ CommonSlice }) => CommonSlice);

    useEffect(() => {
        dispatch(GetCity())
    }, [dispatch]);


    const [selectedCity, setSelectedCity] = useState(city?.length > 0 && city);
    const [indexs, activeIndex] = useState()
    const [inputText, setInputText] = useState("");
    let inputHandler = (e) => {
        //convert input text to lower case
        var lowerCase = e.target.value.toLowerCase(); //.toLowerCase();
        setInputText(lowerCase);
    };
    const currentDate = new Date().toISOString().split('T')[0];
    const filteredData =
        city &&
        city?.filter((el) => {
            //if no input the return the original
            if (inputText === "") {
                return el;
            }
            //return the item which contains the user input
            else {
                return el?.label?.toLowerCase().includes(inputText);
            }
        });

    // useEffect(() => {
    //     setSelectedCity(filteredData.length > 0 && filteredData[0])
    // }, [selectedCity])


    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                dialogClassName="lab_test_modal_box"
                backdrop="static"
            >
                <Modal.Header
                    className="lab_test_modal_box_head_padding"
                    style={{ marginBottom: "none", border: "none" }}
                >
                    <h5 className="lab_test_popup_title"> Select your City</h5>
                    <img
                        src={Icon.cross}
                        className="lab_test_popup_close"
                        alt=""
                        onClick={() => { props.onHide() }}
                    ></img>
                </Modal.Header>
                <Modal.Body>
                    <>
                        <div className="">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="">
                                                    <div className="">
                                                        <h3 className="select_your_city">
                                                        </h3>
                                                        <div className="signup_details_box">
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <img
                                                                        src={Icon.search}
                                                                        className="select_city_search_icon"
                                                                        alt=""
                                                                    ></img>
                                                                    <input
                                                                        type="search"
                                                                        style={{ width: "100%" }}
                                                                        className="health_package_input_search"
                                                                        placeholder="Search"
                                                                        onChange={inputHandler}
                                                                    />
                                                                </div>
                                                                <div
                                                                    style={{
                                                                        height: "300px",
                                                                        overflow: "auto",
                                                                    }}
                                                                >
                                                                    {
                                                                        filteredData[0] != undefined && filteredData
                                                                            ?.map((item, index) => (
                                                                                <div className="col-md-12 " onClick={() => { activeIndex(index) }}>
                                                                                    <label className="city-label-with">
                                                                                        <input
                                                                                            type="radio"
                                                                                            name="city_id"
                                                                                            value={item?.label}
                                                                                            style={{ accentColor: "#199a8e" }}
                                                                                        />
                                                                                        <div className={`sign-up-city_card   ${index === indexs
                                                                                            ? "sign-up-city_card_active"
                                                                                            : null
                                                                                            } `}
                                                                                            key={index}
                                                                                        >
                                                                                            <h5 className="sign-up-city_card_text  sign-up-city_card_text_active" onClick={() => { setSelectedCity(item) }}>
                                                                                                {item?.label}
                                                                                            </h5>
                                                                                        </div>
                                                                                    </label>
                                                                                </div>
                                                                            ))}

                                                                </div>

                                                            </div>

                                                            <div className="row">
                                                                <div className="col-md-12">

                                                                    <Button
                                                                        type="submit"
                                                                        className="next mt_30 "
                                                                        onClick={() => { props.onNext(selectedCity) }}
                                                                    >
                                                                        Next
                                                                    </Button>



                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                </Modal.Body>
            </Modal>
            {/* {isCityModalOpen && <LabCityModal />} */}
        </>



    );
};

export default LabCityModal;
