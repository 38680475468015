import React, { useEffect } from "react";
import "../../../../Assets/css/responsive.css";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Container } from "react-bootstrap";
import { BackGround, Icon } from "../../../../Utilities/Icons";
import { useDispatch, useSelector } from "react-redux";


import {
    GetMySubscribedPlansListDetail
} from "../../../../Store/Reducer/ProfileReducer";
import { useState } from "react";

function MySubscriptionsDetail() {
    const dispatch = useDispatch();
    const { id } = useParams();
  
    const [detailSub, setDetailSub] = useState()
    useEffect(() => {
        dispatch(GetMySubscribedPlansListDetail(id)).then(
            (res) => {
               
                setDetailSub(res)
            }
        );
    }, [])

    const navigate = useNavigate();
    const stars = Array(5).fill('');



    return (
        <>
            <Container fluid className="subscription_package_container">
                <Container>
                    <div className="row">
                        <div className="col-md-12">
                            <Button
                                className="back_btn"
                                onClick={() => {
                                    navigate(-1);
                                }}
                            >
                                <img
                                    src={Icon.backarrow}
                                    className="back_btn_icon"
                                    alt=""
                                ></img>{" "}
                                <span className="back_btn_text">Back</span>
                            </Button>
                        </div>
                        <div className="mt-4">
                            <h1 className="subscription_package_head">Subscription details</h1>
                        </div>
                    </div>
                    <div className="subscription_package_card">
                        <div className="subscription_card_details">
                            <div>
                                <p className="header_card">{detailSub?.payload?.consultations?.length} Consultations</p>
                                <p className="header_desc">{detailSub?.payload?.description}</p>
                                <p className="header_count">{detailSub?.payload?.members?.length} Members added</p>
                            </div>
                            <div>
                                <p className="header_card">₹{detailSub?.payload?.price}</p>
                                <p className="header_consult">{detailSub?.payload?.left_consultations} Consultaions Left</p>
                                <p className="header_desc">Valid till 30 Jun 2020</p>
                            </div>
                        </div>

                        <div className="subscription_package_card_hr"></div>

                        <div className="subscription_package_members_box my-3">
                            <div className="center_img">
                                <h1 className="subscription_title">Members</h1>
                            </div>
                            <div className="subscription_package_members_profile_box_inner">
                                <div className="center_img">
                                    <img
                                        src={Icon.add}
                                        className="add"
                                        alt=""
                                    ></img>{" "}
                                </div>
                                <div className=" center_img">
                                    <p className="subscription_package_body_card_add">Add New Member</p>
                                </div>
                            </div>

                        </div>
                        <div className="subscription_package_members_profile_box">
                            {
                                detailSub?.payload?.members.map((val) => {
                                    const firstLetter = val.name.charAt(0);
                                    return (
                                        <>
                                            <div className="subscription_package_members_profile_box_inner">
                                                <div className="subscription_package_members_profile_d">
                                                    {firstLetter}
                                                </div>
                                                <div>
                                                    <p className="subscription_package_members_profile_title">{val.name}</p>
                                                    <p className="subscription_package_members_profile_remove">Remove</p>
                                                </div>
                                            </div>
                                        </>
                                    )
                                })
                            }
                        </div>

                        <div className="subscription_package_card_hr mt-4"></div>
                        

                        <div className="my-4">
                            <p className="subscription_package_payment_title ">Payment Summary</p>
                            <div className="main_div">
                                <div className="same_div">
                                    <p className="subscription_package_payment_summary_title">Amount</p>
                                    <p className="subscription_package_payment_summary_value">₹ {detailSub?.payload?.price}</p>
                                </div>
                                <div className="same_div">
                                    <p className="subscription_package_payment_summary_discount_value">Discount</p>
                                    <p className="subscription_package_payment_summary_discount_value">-₹ 0</p>
                                </div>
                                <div className="same_div">
                                    <p className="subscription_package_payment_summary_title">Total amount</p>
                                    <p className="subscription_package_payment_summary_value">₹ {detailSub?.payload?.price}</p>
                                </div>
                                <div className="same_div">
                                    <p className="subscription_package_payment_summary_title">Status</p>
                                    <p className="subscription_package_payment_summary_discount_value">Paid</p>
                                </div>
                                <div className="same_div">
                                    <p className="subscription_package_payment_summary_title">Trxn Id</p>
                                    <p className="subscription_package_payment_summary_value">{detailSub?.payload?.transaction_id} </p>
                                </div>
                                <div className="same_div">
                                    <p className="subscription_package_payment_summary_title">Date and Time</p>
                                    {/* <p className="subscription_package_payment_summary_value">25 Aug 2022 | 11:43 am</p> */}
                                    <p className="subscription_package_payment_summary_value">{detailSub?.payload?.purchase_date}</p>
                                </div>
                            </div>
                        </div>

                        <div className="subscription_package_card_hr mt-4"></div>
                        <div className="my-4">
                            <p className="subscription_package_payment_title ">Subscription Consultations</p>

                            {detailSub?.payload?.consultations.map((val) => {

                                return (
                                    <>
                                        {
                                            detailSub?.payload?.consultations?.status == 1 ? <>
                                                <div>
                                                    <div className="subscription_package_consultation_card_head">
                                                        <div className="d-flex">
                                                            <img
                                                                src={Icon.scheduled}
                                                                className="subscription_package_consultation_card_head_icon"
                                                                alt=""
                                                            ></img>{" "}
                                                            <div className="subscription_package_consultation_card_head_title">
                                                                Scheduled  on {val?.appointment_date} at {val?.appointment_time}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="subscription_package_consultation_card_body">
                                                        <div className="main_description">
                                                            <div className="discription_under">
                                                                <div>
                                                                    <img
                                                                        src={val?.doctor_image}
                                                                        className=""
                                                                        alt=""
                                                                        style={{ height: "95px", width: "85px", borderRadius: "11px" }}
                                                                    ></img>{" "}
                                                                </div>
                                                                <div className="desc_second">
                                                                    <p className="subscription_package_box_toptitle">Appointment with</p>
                                                                    <p className="subscription_package_box_title">{val?.doctor_name}</p>
                                                                    <p className="subscription_package_box_subtitle">For Rushabh Patel</p>
                                                                    <p className="subscription_package_box_endtitle">Problem :{val?.health_problem_name}</p>
                                                                </div>
                                                            </div>
                                                            <div className="second_description">
                                                                <div className="d-flex">
                                                                    <div className="subscription_package_cancel_icon">
                                                                        <img
                                                                            src={Icon.cancel}
                                                                            className="add"
                                                                            alt=""
                                                                        ></img>{" "}
                                                                    </div>
                                                                    <div className="subscription_package_cancel_appointment">
                                                                        Cancle Appointment
                                                                    </div>
                                                                </div>
                                                                <div className="or_sub">
                                                                    |
                                                                </div>
                                                                <div className="d-flex">
                                                                    <div className="subscription_package_cancel_icon">
                                                                        <img
                                                                            src={Icon.reschedule}
                                                                            className="add"
                                                                            alt=""
                                                                        ></img>{" "}
                                                                    </div>
                                                                    <div className="subscription_package_reschedule_title">
                                                                        Reschedule
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </> : detailSub?.payload?.consultations?.status == 2 ? <>
                                                <div className="mt-4">
                                                    <div className="subscription_package_consultation_complete_head">
                                                        <div className="d-flex">
                                                            <img
                                                                src={Icon.scheduled_black}
                                                                className="subscription_package_consultation_card_head_icon"
                                                                alt=""
                                                            ></img>{" "}
                                                            <div className="subscription_package_consultation_complete_head_title">
                                                                Completed on {val?.appointment_date} at {val?.appointment_time}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="subscription_package_consultation_card_body">
                                                        <div className="main_description">
                                                            <div className="discription_under">
                                                                <div>
                                                                    <img
                                                                        src={val?.doctor_image}
                                                                        className=""
                                                                        alt=""
                                                                        style={{ height: "95px", width: "85px", borderRadius: "11px" }}
                                                                    ></img>{" "}
                                                                </div>
                                                                <div className="desc_second">
                                                                    <p className="subscription_package_box_toptitle">Appointment with</p>
                                                                    <p className="subscription_package_box_title">{val?.doctor_name}</p>
                                                                    <p className="subscription_package_box_subtitle">For Rushabh Patel</p>
                                                                    <p className="subscription_package_box_endtitle">Problem : {val?.health_problem_name}</p>
                                                                </div>
                                                            </div>
                                                            <div className="second_div_desc">
                                                                <p className="rate_title">Rate Our Service</p>
                                                                <div>
                                                                    {stars.map((_, index) => (
                                                                        <img
                                                                            src={Icon.star}
                                                                            className="star"
                                                                            alt=""
                                                                        ></img>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div></> : <>
                                                <div className="mt-4">
                                                    <div className="subscription_package_consultation_complete_head">
                                                        <div className="d-flex">
                                                            <img
                                                                src={Icon.scheduled_black}
                                                                className="subscription_package_consultation_card_head_icon"
                                                                alt=""
                                                            ></img>{" "}
                                                            <div className="subscription_package_consultation_cancel_head_title">
                                                                Cancelled on {val?.appointment_date} at {val?.appointment_time}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="subscription_package_consultation_card_body">
                                                        <div className="main_description">
                                                            <div className="discription_under">
                                                                <div>
                                                                    <img
                                                                        src={val?.doctor_image}
                                                                        className=""
                                                                        alt=""
                                                                        style={{ height: "95px", width: "85px", borderRadius: "11px" }}
                                                                    ></img>{" "}
                                                                </div>
                                                                <div className="desc_second">
                                                                    <p className="subscription_package_box_toptitle">Appointment with</p>
                                                                    <p className="subscription_package_box_title">{val?.doctor_name}</p>
                                                                    <p className="subscription_package_box_subtitle">For Rushabh Patel</p>
                                                                    <p className="subscription_package_box_endtitle">Problem : {val?.health_problem_name}</p>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        }

                                    </>
                                )
                            })}



                        </div>
                    </div>

                </Container>
            </Container>
        </>
    );
}

export default MySubscriptionsDetail;
