import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import { BackGround, Icon } from "../../../Utilities/Icons";
import { Modal, Button } from "react-bootstrap";
import "../../../Assets/css/responsive.css";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import location from '../../../Assets/img/png/location.png'
import downloadArrow from '../../../Assets/img/svg/downarrow_solid.svg'
import {
  clearbookButton,
  clearCartItem,
  clearList,
  LabTestCenters,
  LabtestCities,
  LabtestLabs,
  setLabTestCenterDetailsById,
} from "../../../Store/Reducer/LabtestSlice";
import { useDispatch, useSelector } from "react-redux";
import LabTestModel from "../LabTestModel/LabTestModel";
import SelecteMemberModel from "../MemberModel/SelecteMemberModel";
import { GetMember } from "../../../Store/Reducer/ProfileReducer";
import {
  RadiologyTestCenters,
  clearRadiologyCartItem,
  setRadiologyTestCenterDetailsById,
} from "../../../Store/Reducer/RadiologySlice";
import { setPackageId } from "../../../Store/Reducer/PackageSlice";
import { useNavigate } from "react-router-dom";
import RadiologicalModel from "../RadiolodicalModel/RadiologicalModel";
import RadiologyCenterModel from "../RadiolodicalModel/RadiologyCenterModel";
import RadiologyCenterDetailModel from "../RadiolodicalModel/RadiologyCenterDetailModel";
import { PackageList } from "../../../Store/Reducer/PackageSlice";
import HealthPackageDetailModel from "./HealthPackageDetailModel";
import PathologyLab from "../LabTestModel/PathologyLab";
import PathologySuceessModal from "../LabTestModel/PathologySuceessModal";
import LabCityModal from "../LabTestModel/LabCityModal";

function LabTestPackage(props) {
  const navigate = useNavigate();
  const [radiomodal, setRadioshow] = useState(false);
  const [radiomodal1, setRadioshow1] = useState(false);
  const dispatch = useDispatch();
  const [packagedetail, setPackageDetails] = useState(false);
  const { labTestList } = useSelector(({ LabtestSlice }) => LabtestSlice);
  const [labtestmodel, setLabtestmodel] = useState(false);
  const [selectedCity, setSelectedCity] = useState("");
  const [labcentermodal, setLabcentermodal] = useState(false);
  const [labcitymodal, setLabcitymodal] = useState(false);
  const [labdetailmodal, setLabdetailmodal] = useState(false);
  const [selectMember, setSelectMember] = useState(false);
  const [cityModal, setCityModal] = useState(false);
  const [radiologyCenterDetailModel, setRadiologyCenterDetailModel] =
    useState(false);
  const [healthPackageDetailModel, setHealthPackageDetailModel] =
    useState(false);
  const { radiologyData, radiologyCart, radiologyTestCenterList } = useSelector(
    ({ RadiologySlice }) => RadiologySlice
  );

  const { labtestStates, labtestCities } = useSelector(
    ({ LabtestSlice }) => LabtestSlice
  );

  const cityJSON = localStorage.getItem("USER");
  const cityObject = JSON.parse(cityJSON);
  useEffect(() => {

    dispatch(LabtestCities(cityObject?.city_details?.state_id))
  }, [dispatch]);
  useEffect(() => {

    setSelectedCity(labtestCities?.length > 0 && labtestCities[0])
  }, [dispatch, labtestCities])

  const { packageList } = useSelector(({ PackageSlice }) => PackageSlice);
  const { session } = useSelector(({ AuthSlice }) => AuthSlice);


  const [inputText, setInputText] = useState("");

  let inputHandler = (e) => {
    //convert input text to lower case
    var lowerCase = e.target.value.toLowerCase(); //.toLowerCase();
    setInputText(lowerCase);
  };

  const labradiologytest = localStorage?.getItem("labradiologytest");

  useEffect(() => {

    dispatch(PackageList(2));

  }, [dispatch]);

  useEffect(() => {
    dispatch(clearRadiologyCartItem());
  }, [dispatch]);

  //lablist



  const { labTestCenterList, labTestCenterDetailsById } =
    useSelector(({ LabtestSlice }) => LabtestSlice);
  //   const { session } = useSelector(({ AuthSlice }) => AuthSlice);
  const data = localStorage.getItem("healthcare")

  useEffect(() => {

    let request = {
      city_id: cityObject?.city_id,
    };
    dispatch(LabTestCenters(request));
    dispatch(RadiologyTestCenters(request));
  }, [dispatch, cityObject?.city_id]);


  const [gender, setGender] = useState();

  function onChangeValue(event) {
    setGender(event.target.value);
  }

  useEffect(() => {

    if (gender) {
      labTestCenterList?.filter(function (item) {
        if (gender == item?.id) {
          dispatch(setLabTestCenterDetailsById(item));
          props?.formik?.setFieldValue("center_id", item?.id);
          props?.formik?.setFieldValue("city_id", item?.city_id);
        }
      });
    } else {
      setGender(labTestCenterDetailsById?.id);
    }
  }, [gender]);
  const [page, setpage] = useState(1);

  const fetchData = async (item) => {
    try {
      dispatch(clearList());
      dispatch(LabtestLabs({
        center_id: item?.id,
        city_id: item?.city_id,
        page: 1
      }));
    } catch (error) {
      // console.error('Error fetching data:', error);
    }
  };
  useEffect(() => {

    dispatch(clearList());
  }, [dispatch, labTestCenterDetailsById])

  return (
    <>
      <LabTestModel
        show={labtestmodel}
        onNext={() => {
          setLabdetailmodal(true);
          setLabtestmodel(false);
        }}
        onHide={(e) => {
          setLabtestmodel(false);
        }}
        gender={gender}
      />
      <PathologyLab
        show={labcentermodal}
        onNext={() => {
          // setLabdetailmodal(true);
          setLabtestmodel(true);
          setLabcentermodal(false);
        }}
        onNextCityModal={() => {
          setCityModal(true)
          setLabcentermodal(false)
          setLabcitymodal(true)
        }}
        selectedCitys={selectedCity}
        onHide={(e) => {
          setLabcentermodal(false);
        }}
      />

      {cityModal && <LabCityModal
        show={labcitymodal}
        onNextCityModal={() => {
          setLabcentermodal(false);
        }}
        onNext={(selectedCity) => {
          setLabcentermodal(true);
          //  dispatch(clearList());
          setLabcitymodal(false);
          setSelectedCity(selectedCity)
        }}
        onHide={(e) => {
          setLabcitymodal(false);
        }}
      />}

      <PathologySuceessModal
        show={labdetailmodal}
        onNext={() => {
          navigate("/labtestcart");

          // window.location = '/patient/labtestcart'
          setLabdetailmodal(false);
        }}
        onHide={(e) => {
          setLabdetailmodal(false);
        }}
      />
      <RadiologicalModel
        show={radiomodal}
        radiologyData={radiologyData}
        onNext={() => {
          setRadioshow(false);
          setSelectMember(true);
          setRadioshow1(false);
        }}
        onHide={(e) => {
          setRadioshow(false);
          e === true && setRadioshow1(true);
        }}
      />
      <RadiologyCenterModel
        show={radiomodal1}
        onNext={() => {
          setRadioshow(true);
          setRadiologyCenterDetailModel(false);
        }}
        setRadiologyCenterDetailModel={() =>
          setRadiologyCenterDetailModel(true)
        }
        onHide={(e) => {
          setRadioshow1(false);
          e === true && setRadioshow(true);
        }}
      />
      <RadiologyCenterDetailModel
        show={radiologyCenterDetailModel}
        onHide={() => setRadiologyCenterDetailModel(false)}
      />
      <HealthPackageDetailModel
        show={healthPackageDetailModel}
        onHide={(e) => {
          setHealthPackageDetailModel(false);
        }}
      />

      <MyModal_5
        show={packagedetail}
        onHide={(e) => {
          setPackageDetails(false);
          e === true && setSelectMember(true);
        }}
      />
      <SelecteMemberModel
        show={selectMember}
        onHide={() => setSelectMember(false)}
        radiologyCart={radiologyCart}
      />
      <Container fluid className="health_package_container">
        <Container>
          <div className="row">
            <div className="col-md-12">
              <h3 className="health_package_container_title"> Lab Test</h3>
            </div>
          </div>
          <div className="health_package_card_box">
            <div className="row">
              <div className="col-xl-12 col-md-12 health_package_left_padding">
                <div className="health_package_right_side_section_1">
                  <div className="health_package_lab_test">
                    <div className="row">
                      <div className="" style={{ display: "flex", justifyContent: "space-between" }}>
                        <div className="main" >
                          <form className="search">
                            <img
                              src={Icon.search}
                              className="select_city_search_icon"
                              alt="My Awesome Image"
                            ></img>
                            <input
                              type="search"
                              className="health_package_input_search"
                              placeholder="Search"
                            // onChange={inputHandler}
                            />
                            <ul className="results">
                              <div className="row mb_20">
                                <div className="col-md-6 col-6">
                                  <h3 className="health_package_lab_test_title">
                                    Lab Test
                                  </h3>
                                </div>
                                <div className="col-md-6 col-6"></div>
                              </div>
                              {labTestList
                                ?.filter((data) =>
                                  data?.test_name
                                    ?.toLowerCase()
                                    ?.includes(inputText.toLowerCase())
                                )
                                .map((item) => (
                                  <>
                                    <div className="row">
                                      <div className="col-md-6 col-6">
                                        <h5 className="health_package_lab_title">
                                          {item?.test_name}
                                        </h5>
                                      </div>
                                      <div className="col-md-6 col-6">
                                        <h5 className="health_package_lab_value">
                                          {item?.price}
                                        </h5>
                                      </div>
                                    </div>
                                    <hr className="health_package_lab_text_hr" />
                                  </>
                                ))}
                            </ul>
                            <br />
                          </form>
                        </div>
                        <div className="location-dropdown">
                          <div className="location-header" onClick={() => { setCityModal(true); setLabcitymodal(true) }}>
                            <img src={location} alt="Location Icon" />
                            <span className="city-name">
                              {
                                selectedCity?.label ? selectedCity?.label :
                                  <>{
                                    props?.selectedCitys?.label === undefined
                                      ? (cityObject?.city_details?.name === undefined ? "plz choose city" : cityObject?.city_details?.name)
                                      : props?.selectedCitys?.label
                                  }</>
                              }

                            </span>
                            <img src={downloadArrow} ></img>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    {labTestCenterList?.length > 0 && (
                      <>
                        <div className="row mb_25">
                          <div className="col-md-6 col-6">
                            <h3 className="health_package_lab_test_title">
                              Lab Test Centers
                            </h3>
                          </div>
                          {labTestCenterList?.length > 5 && <div className="col-md-6 col-6">
                            <Button
                              className="health_package_lab_test_view_all_btn"
                              onClick={() => {
                                setLabcentermodal(true);
                                dispatch(clearRadiologyCartItem());
                              }}
                            >
                              View all
                            </Button>
                          </div>}
                        </div>
                        {labTestCenterList?.length &&
                          labTestCenterList?.map((item, index) => (
                            <>
                              <div className="lab_member_box" key={index}>
                                <div className="health_radiological_popup_list_box_inner">
                                  <div>
                                    <img
                                      className="select_radiology_img"
                                      src={`${item?.lab_logo}`}
                                      alt=""
                                      height="70px"
                                      width="70px"
                                    ></img>
                                  </div>
                                  <div>
                                    <label for="Radiological_Test7">
                                      <h3
                                        className="healthradiological_radiological_test_title"
                                        onClick={() => {
                                          props.setRadiologyCenterDetailModel(true);
                                          props.onHide();
                                          // dispatch(
                                          //   setRadiologyTestCenterDetailsById(item)
                                          // );
                                        }}
                                      >
                                        {item?.lab_name}
                                      </h3>
                                      <div className="healthradiological_star_box">
                                        {
                                          item?.ratings.length > 0 && (
                                            <>
                                              <h5 className="healthradiological_star_text">
                                                <img
                                                  src={Icon.star}
                                                  className="healthradiological_star_icon"
                                                  alt=""
                                                ></img>
                                                {item?.ratings?.length}
                                              </h5>
                                              <h6 className="healthradiological_star_review">
                                                {item?.avg_ratings} Reviews
                                              </h6>
                                            </>
                                          )
                                        }
                                      </div>
                                    </label>
                                  </div>
                                </div>
                                <div>
                                  <input
                                    type="radio"
                                    id={item?.id}
                                    name="gender"
                                    className="healthradiological_input"
                                    checked={gender == item?.id}
                                    onChange={(e) => onChangeValue(e)}
                                    value={item?.id}
                                    onClick={() => { setLabtestmodel(true); dispatch(setLabTestCenterDetailsById(item)); fetchData(item); }}
                                    style={{ accentColor: "#199a8e" }}
                                  />
                                </div>

                              </div>


                              <hr className="consult_member_hr" />
                            </>
                          ))}
                        {labTestCenterList?.length == 0 && (
                          <>
                            <h6> No Lab Tests Found</h6>
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>

              </div>
            </div>
          </div>
        </Container>
      </Container>
    </>
  );
}

const MyModal_5 = (props5) => {
  return (
    <Modal
      {...props5}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="lab_test_modal_box"
    >
      <Modal.Header
        className="lab_test_modal_box_head_padding"
        style={{ marginBottom: "none", border: "none" }}
      >
        <h5 className="lab_test_popup_title">Package Details</h5>
        <img
          src={Icon.cross}
          className="lab_test_popup_close"
          onClick={props5.onHide}
          alt=""
        ></img>
      </Modal.Header>
      <Modal.Body>
        <div className="lab_test_modal_box_2">
          <div className="fullbody_package_box">
            <div className="fullbody_package_box_inner">
              <div>
                <img
                  src={BackGround.package}
                  className="fullbody_package_img"
                  alt=""
                ></img>
              </div>
              <div>
                <h3 className="fullbody_package_title">Silver Package</h3>
                <h5 className="fullbody_package_subtitle">14 tests</h5>
                <h6 className="fullbody_package_price">₹ 499</h6>
              </div>
            </div>
            <div>
              <Button
                className="fullbody_package_btn"
                onClick={() => {
                  props5.onHide(true);
                }}
              >
                Book Now
              </Button>
            </div>
          </div>
          <hr className="fullbody_package_hr" />
        </div>
        <div className="fullbody_package_scroll_box">
          <p className="fullbody_package_scroll_para">
            <span className="fullbody_package_scroll_paratitle">
              Test Instructions:
            </span>{" "}
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s
          </p>
          <h3 className="fullbody_package_scroll_test_title">Tets Included</h3>
          <h5 className="fullbody_package_scroll_test_name">FBG</h5>
          <h6 className="fullbody_package_scroll_test_para">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry
          </h6>
          <hr className="fullbody_package_hr" />
          <h5 className="fullbody_package_scroll_test_name">Test Name</h5>
          <h6 className="fullbody_package_scroll_test_para">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry
          </h6>
          <hr className="fullbody_package_hr" />
          <h5 className="fullbody_package_scroll_test_name">Test Name</h5>
          <h6 className="fullbody_package_scroll_test_para">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry
          </h6>
          <hr className="fullbody_package_hr" />
          <h5 className="fullbody_package_scroll_test_name">Test Name</h5>
          <h6 className="fullbody_package_scroll_test_para">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry
          </h6>
          <hr className="fullbody_package_hr" />
          <h5 className="fullbody_package_scroll_test_name">Test Name</h5>
          <h6 className="fullbody_package_scroll_test_para">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry
          </h6>
          <hr className="fullbody_package_hr" />
          <h5 className="fullbody_package_scroll_test_name">Test Name</h5>
          <h6 className="fullbody_package_scroll_test_para">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry
          </h6>
          <hr className="fullbody_package_hr" />
          <h5 className="fullbody_package_scroll_test_name">Test Name</h5>
          <h6 className="fullbody_package_scroll_test_para">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry
          </h6>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default LabTestPackage;
