import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
    HealthCareBookingDetails,
    HealthPackagePastBooking,
    HealthPackageUpcomingBooking,
    LabtestCancelReasons,
    LabtestPastBookings,
    LabtestUpcomingBooking,
    SelectedCancelReasons,
} from "../../../../Store/Reducer/LabtestSlice";
import { HealthCareUpcomingBookings } from "../../../../Store/Reducer/RadiologySlice";
import { Icon } from "../../../../Utilities/Icons";
import LabCancelationReasonModel from "../../../Common/LabTestModel/LabCancelationReasonModel";
import Arrow from '../../../../Assets/img/svg/Frame.svg'
import { Button, Pagination } from "react-bootstrap";
import PaidModal from "../../../Common/Healthpackagemodal/PaidModal";
import PayModal from "../../../Common/Healthpackagemodal/PayModal";

const Care = () => {
    const [cancelationReasonModel, setCancelationReasonModel] = useState(false);

    const {
        healthCareUpcomingBookings,
        healthCareUpcomingBookingsList
    } = useSelector(({ RadiologySlice }) => RadiologySlice);


    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { selectedMemberDetail, labCart, healthCareBookingDetailsById } = useSelector(
        ({ LabtestSlice }) => LabtestSlice
    );

    useEffect(() => {
        dispatch(HealthCareUpcomingBookings({ test_member_id: selectedMemberDetail?.id }))



    }, [dispatch]);

    const [currentPage, setCurrentPage] = useState(0); // Current page index
    const itemsPerPage = 5; // Number of bookings to display per page

    const handlePageChange = (selected) => {
        setCurrentPage(selected); // Update the current page index
    };


    const [paid, Setpaid] = useState(false);
    const [paidData, setPaidData] = useState({})
    const [pay, SetPay] = useState(false);
    return (
        <>
            <PaidModal
                show={paid}
                onHide={(e) => {
                    Setpaid(false);
                }}
                paidData={paidData}
            />
            <PayModal
                show={pay}
                onHide={(e) => {
                    SetPay(false);
                }}
                onNext={() => {
                    SetPay(false);
                }}
                paidData={paidData}
            />
       
            <div className="my_test_body_box_card_head">
                <div className="row">
                    <div className="col-md-6">
                        <h3 className="my_test_body_card_head_title">My Health Care Bookings</h3>
                    </div>
                    <div className="col-md-6"></div>
                </div>
            </div>
            <div className="my_test_body_box_card_body">
                <div className="row" style={{ gap: "15px" }}>
                    {
                        healthCareUpcomingBookingsList && healthCareUpcomingBookingsList?.slice(0).map((val, index) => {
                            const formattedDate = new Date(val?.booking_date_time);
                            const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' };
                            const formattedString = formattedDate.toLocaleString('en-US', options);

                            return index + 1 <= 5 ? (
                                <>
                                    <div className="col-md-12">
                                        <div className="">
                                            <div className="mainprecptiondivs">
                                                <div className="prec">
                                                    <div className="">
                                                        <p className="careparagraphs" style={{ marginBottom: "10px" }}>Booking Id {val?.order_id}</p>
                                                        <p className="careparagraph">on {formattedString}</p>
                                                    </div>
                                                    {
                                                        val?.type == 1 ? <>
                                                            <div>
                                                                <Button className="paybtn" onClick={() => { SetPay(true); setPaidData(val); dispatch(HealthCareBookingDetails(val?.order_id)) }}>Pay Now</Button>
                                                            </div></> :
                                                            <>
                                                                <div>
                                                                    <Button className="paybtns" onClick={() => { Setpaid(true); setPaidData(val); dispatch(HealthCareBookingDetails(val?.order_id)) }}>Paid</Button>
                                                                </div>
                                                            </>
                                                    }
                                                   
                                                </div>
                                                <hr />
                                                <div className="">
                                                    <button className="carebtn">{val?.service?.map((val) => {
                                                        return (
                                                            val?.category?.name
                                                        )
                                                    })}</button>
                                                  
                                                    <p className="careparagraph pt_10">Service:{val?.service?.map((val) => {
                                                        return (
                                                            val?.name
                                                        )
                                                    })}</p>
                                                    <p style={{ marginBottom: "0px" }}>For {val?.member_details?.name}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ) : ""
                        })
                    }
                </div>
            </div>
        </>
    );
};

export default Care;
