import { useFormik } from "formik";
import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { Icon } from "../../../Utilities/Icons";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  LabtestCities,
  LabtestUserAddress,
} from "../../../Store/Reducer/LabtestSlice";

const AddressModel = (props) => {
  // labtestStates
  const { labtestStates, labtestCities } = useSelector(
    ({ LabtestSlice }) => LabtestSlice
  );

  const [initialAddress, setinitialAddress] = useState({
    Address: "",
    Pincode: "",
    City: "",
    City_id: "",
    State: "",
    State_id: "",
    type: "",
  });
  const dispatch = useDispatch();
  const AddressSchema = Yup.object({
    Pincode: Yup.string()
      .length(6)
      .matches(/^[0-9]{5}/)
      .required()
      .label("Postal Code"),
    Address: Yup.string().required("Please Enter address"),

    type: Yup.string().required("Please Enter type"),
  });

  const formik = useFormik({
    initialValues: initialAddress,
    validationSchema: AddressSchema,
    onSubmit: (values) => {
      dispatch(LabtestUserAddress(values));
      props.onHide();
    },
  });

  const handleState = (e) => {
    const { value } = e.target;
    labtestStates?.filter(function (item) {
      if (value == item.STATE_NAME) {
        formik.setFieldValue("State_id", item?.STATE_ID);
        formik.setFieldValue("State", item?.STATE_NAME);
        if (item?.STATE_ID) {
          dispatch(LabtestCities(item?.STATE_ID)).then((res) => {
            formik?.setFieldValue("City_id", res.payload[0].CITY_ID);
            formik?.setFieldValue("City", res.payload[0].CITY_NAM);
          });
        }
      }
    });
  };
  const handleCity = (e) => {
    const { value } = e.target;
    labtestCities?.filter(function (item) {
      if (value == item.CITY_NAM) {
        formik?.setFieldValue("City_id", item.CITY_ID);
        formik?.setFieldValue("City", item.CITY_NAM);
      }
    });
  };
  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="modal_476"
      backdrop="static"
    >
      <Modal.Header style={{ border: "none" }}>
        <h5 className="select_consult_member_modal_title">Add New Address</h5>
        <img
          src={Icon.cross}
          className="book_modal_close"
          onClick={() => {
            props.onHide();
            setinitialAddress(!initialAddress);
          }}
        ></img>
      </Modal.Header>
      <Modal.Body>
        <div className="add_member_modal_box">
          <form onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-md-12">
                <div className="input_box">
                  <div className="form_group">
                    <input
                      control="input"
                      type="text"
                      name="Pincode"
                      className="add_member_input"
                      placeholder="Pin Code"
                      value={formik.values.Pincode}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {/* <lable className="lab_add_tag">
                          {session?.city_details?.name}, Gujarat
                        </lable> */}
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="input_box">
                  <div className="form_group">
                    <input
                      control="input"
                      type="text"
                      name="Address"
                      className="add_member_input"
                      placeholder="Address"
                      value={formik.values.Address}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="input_box">
                  <div className="form_group">
                    <Form.Select
                      aria-label="Default select example"
                      name="State"
                      value={formik.values.State}
                      onChange={(e) => {
                        handleState(e);
                      }}
                      onBlur={formik.handleBlur}
                      className="add_member_input"
                    >
                      {labtestStates?.length &&
                        labtestStates?.map((state) => (
                          <option value={state?.STATE_NAME}>
                            {state?.STATE_NAME}
                          </option>
                        ))}
                    </Form.Select>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="input_box">
                  <div className="form_group">
                    <Form.Select
                      aria-label="Default select example"
                      name="City"
                      value={formik.values.City}
                      onChange={(e) => {
                        handleCity(e);
                      }}
                      onBlur={formik.handleBlur}
                      className="add_member_input"
                    >
                      {labtestCities?.length &&
                        labtestCities?.map((city) => (
                          <>
                            <option value={city?.CITY_NAM}>
                              {city?.CITY_NAM}
                            </option>
                          </>
                        ))}
                    </Form.Select>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <h5 className="medical_history_title">Save as</h5>
              </div>
            </div>
            <div className="row mb_15">
              <div className="medical_history_box">
                <div className="medical_history_box_inner">
                  <input
                    type="radio"
                    id="Home"
                    value="Home"
                    checked={formik.values.type === "Home"}
                    onChange={() => formik.setFieldValue("type", "Home")}
                    className="mt_10"
                  />
                  <lable for="Home">
                    <h5 className="medical_history_title_1">Home</h5>
                  </lable>
                </div>
                <div className="medical_history_box_inner">
                  <input
                    type="radio"
                    id="Office"
                    value="Office"
                    checked={formik.values.type === "Office"}
                    onChange={() => formik.setFieldValue("type", "Office")}
                    className="mt_10"
                  />
                  <lable for="Office">
                    <h5 className="medical_history_title_1">Office</h5>
                  </lable>
                </div>
                <div className="medical_history_box_inner">
                  <input
                    type="radio"
                    id="Other"
                    value="Other"
                    checked={formik.values.type === "Other"}
                    onChange={() => formik.setFieldValue("type", "Other")}
                    className="mt_10"
                  />
                  <lable for="Other">
                    <h5 className="medical_history_title_1">Other</h5>
                  </lable>
                </div>
              </div>
              <div className="terms_conditions_error_text pt-2">
                {formik.errors.type &&
                  formik.touched.type &&
                  formik.errors.type}
              </div>
            </div>
            <center>
              <Button
                type="submit"
                className="consult_modal_btn"
                //onClick={props.onHide}
              >
                Save
              </Button>
            </center>
          </form>
        </div>
      </Modal.Body>
      <Modal.Footer style={{ border: "none", display: "none" }}></Modal.Footer>
    </Modal>
  );
};

export default AddressModel;
