import moment from "moment";
import React, { useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { GetConsultDetails } from "../../../Store/Reducer/BookingSlice";
import { Icon, Logo } from "../../../Utilities/Icons";

const PresciptionDetails = (props) => {
  const { bookingDetail, consultDetails } = useSelector(
    ({ BookingSlice }) => BookingSlice
  );
  const { booking_id } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (bookingDetail?.id) {
      dispatch(GetConsultDetails({ booking_id: bookingDetail?.id }));
    } else if (booking_id) {
      dispatch(GetConsultDetails({ booking_id: booking_id }));
    }
  }, [bookingDetail?.id, booking_id, dispatch]);

  return (
    <Modal
      show={props.show}
      onHide={() => props.onHide()}
      className="generate-prescription-modal"
      centered
      backdrop="static"
    >
      <Modal.Header className="prescription-modal-header">
        <div>
          <img
            alt="myImg"
            height={"37px"}
            width={"67px"}
            src={Logo.THS_WHITE}
            className="logo ml_10"
          />
        </div>
        <div>
          <img src={Icon.close} onClick={()=> {props.onHide()}}></img>
          <h4>Dr {consultDetails?.doctor_name}</h4>

          <p>
            {consultDetails?.qualifications} -{" "}
            {consultDetails?.doctor_speciality}
          </p>
          <p>
            {consultDetails?.city_name},{consultDetails?.state_name}
          </p>
          <p>
            Medical Registration Number: {consultDetails?.registration_number}
          </p>
        </div>
      </Modal.Header>
      <Modal.Body className="prescription-modal-body-text">
        <div className="prescription_appoinment_input">
          <div>
            {" "}
            <p className="prescription-left-text">
              {consultDetails?.prescription_data?.patient_details?.name}
            </p>
            <span>
              {consultDetails?.prescription_data?.patient_details?.age} |{" "}
              {consultDetails?.prescription_data?.patient_details?.gender?.toLowerCase() ===
              "male"
                ? "M"
                : "F"}{" "}
              (THS Id: {consultDetails?.prescription_data?.patient_details?.id})
            </span>
          </div>
          <div>
            {" "}
            <p className="prescription-right-text">
              {moment(
                consultDetails?.prescription_data?.prescription_date
              ).format("DD MMM,YYYY")}
            </p>
            <span>
              Prescription Id:{" "}
              {consultDetails?.prescription_data?.prescription_id}
            </span>
          </div>
        </div>

        <hr />

        {consultDetails?.prescription_data?.doctor_notes && (
          <>
            <div className="prescription_appoinment_input">
              <div>
                {" "}
                <span className="text-uppercase">Diagnosis</span>
                <p className="prescription-left-text mt_5">
                  {consultDetails?.prescription_data?.doctor_notes?.diagnosis}
                </p>
              </div>
              <div>
                {" "}
                <span className="text-uppercase">Medical History</span>
                <p className="prescription-right-text mt_5">
                  {
                    consultDetails?.prescription_data?.doctor_notes
                      ?.medical_history
                  }
                </p>
              </div>
              <div>
                {" "}
                <span className="text-uppercase">Chef Complaints</span>
                <p className="prescription-right-text mt_5">
                  {
                    consultDetails?.prescription_data?.doctor_notes
                      ?.chef_complaints
                  }
                </p>
              </div>
            </div>
            <hr />
          </>
        )}

        {consultDetails?.prescription_data?.medicines?.length ? (
          <div className="prescription_table_appoinment_input">
            <div className="table-responsive">
              <span className="text-uppercase">Mecdicine</span>
              <table className="table prescription_table">
                <thead>
                  <tr className="prescription_table_head">
                    <th className="prescription_table_head_text">Name</th>
                    <th className="prescription_table_head_text">Mor</th>
                    <th className="prescription_table_head_text">Aft</th>
                    <th className="prescription_table_head_text">Ngt</th>
                    <th className="prescription_table_head_text">Condition</th>
                    <th className="prescription_table_head_text">
                      <center>Days</center>
                    </th>
                    <th className="prescription_table_head_text">
                      <center>Qty</center>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {consultDetails?.prescription_data?.medicines?.map(
                    (item, index) => (
                      <tr
                        key={item?.medicine_id}
                        className="prescription_table_body_row"
                      >
                        <td className="prescription_table_body_text">
                          {item?.medicine_name}
                        </td>
                        <td className="prescription_table_body_text">
                          {item?.morning || "---"}
                        </td>
                        <td className="prescription_table_body_text">
                          {item?.afternoon || "---"}
                        </td>
                        <td className="prescription_table_body_text">
                          {item?.night || "---"}
                        </td>
                        <td className="prescription_table_body_text">
                          {item?.conditions === "after_food"
                            ? "After Food"
                            : item?.conditions === "before_food"
                            ? "Before Food"
                            : "---"}
                        </td>
                        <td className="prescription_table_body_text">
                          {item?.days}
                        </td>

                        <td className="prescription_table_body_text">
                          {(parseInt(item?.morning || 1) +
                            parseInt(item?.afternoon || 0) +
                            parseInt(item?.evening || 0) +
                            parseInt(item?.night || 0)) *
                            parseInt(item?.days)}
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          <></>
        )}

        {consultDetails?.prescription_data?.lab_test?.length ||
        consultDetails?.prescription_data?.refer_doctors?.length ? (
          <>
            <div className="prescription_appoinment_input">
              {consultDetails?.prescription_data?.lab_test?.length ? (
                <div>
                  {" "}
                  <span className="text-uppercase">Lab Test</span>
                  {consultDetails?.prescription_data?.lab_test?.map(
                    (item, index) => (
                      <p className="prescription-left-text mt_5" key={index}>
                        {item.lab_test_name}
                      </p>
                    )
                  )}
                </div>
              ) : (
                <div></div>
              )}
              {consultDetails?.prescription_data?.refer_doctors?.length ? (
                <div>
                  {" "}
                  <span className="text-uppercase">Recommended Doctor</span>
                  <p className="prescription-right-text mt_5">
                    {consultDetails?.prescription_data?.refer_doctors
                      ?.map((item) => item?.speciality_name)
                      .join(", ")}
                  </p>
                </div>
              ) : (
                <></>
              )}
            </div>
            <hr />
          </>
        ) : (
          <></>
        )}

        {consultDetails?.prescription_data?.Radiological_test?.length ||
        consultDetails?.prescription_data?.refer_doctors?.length ? (
          <>
            <div className="prescription_appoinment_input">
              {consultDetails?.prescription_data?.Radiological_test?.length ? (
                <div>
                  {" "}
                  <span className="text-uppercase">Radiological Test</span>
                  {consultDetails?.prescription_data?.Radiological_test?.map(
                    (item, index) => (
                      <p className="prescription-left-text mt_5" key={index}>
                        {item.lab_test_name}
                      </p>
                    )
                  )}
                </div>
              ) : (
                <div></div>
              )}
              {/* {consultDetails?.prescription_data?.refer_doctors?.length ? (
                <div>
                  {" "}
                  <span className="text-uppercase">Recommended Doctor</span>
                  <p className="prescription-right-text mt_5">
                    {consultDetails?.prescription_data?.refer_doctors
                      ?.map((item) => item?.speciality_name)
                      .join(", ")}
                  </p>
                </div>
              ) : (
                <></>
              )} */}
            </div>
            <hr />
          </>
        ) : (
          <></>
        )}
        {consultDetails?.prescription_data?.doctor_notes && (
          <div className="prescription_appoinment_input">
            <div>
              {" "}
              <span className="text-uppercase">instructions</span>
              <p className="prescription-left-text mt_5">
                {consultDetails?.prescription_data?.doctor_notes?.instruction}
              </p>
            </div>
            <div>
              {" "}
              <span className="text-uppercase">Follow up after</span>
              <p className="prescription-right-text mt_5">
                {
                  consultDetails?.prescription_data?.doctor_notes
                    ?.follow_up_days
                }{" "}
                days
              </p>
            </div>
          </div>
        )}
        <div className="prescription_sign_appoinment_input d-flex justify-content-end text-end">
          <div>
            {" "}
            <img
              style={{ height: "100px" }}
              alt="myImg"
              src={consultDetails?.signature}
              className="work_profile_certificate "
            ></img>
            <br />
            <h6>{consultDetails?.name}</h6>
            <p className="prescription-right-text mt_5">
              {consultDetails?.qualifications} - {consultDetails?.speciality}
            </p>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="prescription-btn-modal-footer">
        <div className="d-flex">
          {/* {(!location.pathname.includes("chat") ||
            consultDetails?.status < 2) && (
            <Button
              className="close_btn"
              onClick={() => {
                // dispatch(toggleReview(false));
                navigate(`/prescription/${consultDetails?.prescription_id}`);
              }}
            >
              Edit
            </Button>
          )} */}

          {location.pathname.includes("chat") ? (
            <Button
              className="verify_btn"
              variant="primary"
              onClick={(e) => {
                props.onHide();
                e.preventDefault();

              }}
            >
              Close
            </Button>
          ) : location.pathname.includes("prescription") ? (
            <Button className="verify_btn" variant="primary">
              Send Prescription
            </Button>
          ) : (
            <Button
              className="verify_btn"
              variant="primary"
              onClick={() => navigate(`/chat/${consultDetails?.id}`)}
            >
              Open Chat
            </Button>
          )}
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default PresciptionDetails;
