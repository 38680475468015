import React, { useEffect, useState } from "react";
import "../../../../Assets/css/responsive.css";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { GetUserWallet, GetUserWalletList } from "../../../../Store/Reducer/ProfileReducer";
const Wallet = () => {
  const { walletList, userWallet } = useSelector(({ ProfileSlice }) => ProfileSlice);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GetUserWallet());
    dispatch(GetUserWalletList());
  }, [dispatch])
  return (
    <>
      <div className="wallet_body_box_card_head">
        <div className="row">
          <div className="col-md-6">
            <h3 className="wallet_body_card_head_title">Wallet</h3>
          </div>
          <div className="col-md-6"></div>
        </div>
      </div>
      <div className="wallet_body_box_card_body">
        <div className="row">
          <div className="col-md-12">
            <div className="wallet_balance_card">
              <h5 className="wallet_balance_card_title">Your balance</h5>
              <h3 className="wallet_balance_card_value">₹ {userWallet.wallet}</h3>
            </div>

            {walletList?.length > 0 ? (
              <>
                {walletList?.map((userWallets, index) => (
                  <div className="wallet_card_box mb_25">
                    <div className="wallet_card_head">
                      <div className="row">
                        <div className="col-md-12">
                          <h3 className="wallet_transaction_id">
                            #{userWallets.booking_id}
                          </h3>
                        </div>
                      </div>
                    </div>
                    <div className="wallet_card_body">
                      <div className="row">
                        <div className="col-xl-4 col-md-6 col-7">
                          <div className="wallet_card_body_box">
                            <h5 className="wallet_card_body_title">Trxn on</h5>
                            <h3 className="wallet_card_body_value">
                              {moment(userWallets?.consult_on).format(
                                "DD-MMM-YYYY | h:mm A"
                              )}
                            </h3>
                          </div>
                        </div>
                        <div className="col-xl-6 col-md-3 col-5">
                          <div className="wallet_card_body_box">
                            <h5 className="wallet_card_body_title">For</h5>
                            <h3 className="wallet_card_body_for">{userWallets.type}</h3>
                          </div>
                        </div>
                        <div className="col-xl-2 col-md-3 d-flex" style={{ gap: "20px" }}>
                          {
                            userWallets.total_price === "Free" ? <>
                              <div className="wallet_card_body_box">
                                <h5 className="wallet_card_body_title">Paid</h5>
                                <h3 className="wallet_card_body_value">{userWallets.total_price}</h3>
                              </div>
                            </> : <>
                              <div className="wallet_card_body_box">
                                <h5 className="wallet_card_body_title">Paid</h5>
                                <h3 className="wallet_card_body_value">₹ {parseFloat(userWallets.total_price).toFixed(0)}</h3>
                              </div>
                              <div className="wallet_card_body_box">
                                <h5 className="wallet_card_body_title">Wallet</h5>
                                <h3 className="wallet_card_body_value">₹ {parseFloat(userWallets.wallet).toFixed(0)}</h3>
                              </div></>
                          }

                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <div>No Wallets Found</div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Wallet;
