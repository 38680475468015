import RootRoutes from "./Routes/RootRoutes";
import LayoutProvider from "./Components/Common/Layouts/LayoutProvider";
import { setMessage } from "./Store/Reducer/LayoutsSice";
import { AlertEnum } from "./Utilities/Enums";
import { LocalServiceWorkerRegister } from "./Utilities/Functions";
import { useDispatch } from "react-redux";
import React, { useEffect } from "react";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "react-datepicker/dist/react-datepicker.css";
import "agora-react-uikit/dist/index.css";
import "./Assets/css/style.css";
import "./Assets/css/responsive.css";
import "./Components/Common/style.css";
import { onMessageListener } from "./Utilities/Firebase.config";
import { clearRadiologyCartItem, params } from "./Store/Reducer/RadiologySlice";
// import { onMessageListener } from "../public/firebase-messaging-sw";
import { useLocation, useNavigate, useParams } from "react-router-dom";

function App() {
  const dispatch = useDispatch();
  const location = useLocation();


  useEffect(() => {
    dispatch(params(location.pathname))

  }, [])

  var search = location.search.substring(1);
  localStorage.setItem("location", search)
//  JSON.parse('{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}', function (key, value) { return key === "" ? value : decodeURIComponent(value) })
  console.log("values", search);
 
    useEffect(() => {
      const url = window.location.search;

      // Parse the URL to extract query parameters
      const urlParams = new URLSearchParams(url);
      console.log("urlParams",url);
  
      // Access individual query parameters
      const source = urlParams.get("utm_source");
      const medium = urlParams.get("utm_medium");
      const campaign = urlParams.get("utm_campaign");
      if(source != null ){
        localStorage.setItem("utm_source",source)
   
      }
      if(medium != null){
        localStorage.setItem("utm_medium",medium)
  
      }
      if(campaign != null){
        localStorage.setItem("utm_campaign",campaign)
      }
  
 
    },[])

  setInterval(() => {
    onMessageListener()
      .then((payload) => {

        let messageBody;
        if (payload?.data?.incomming_call_type == 0) {
          messageBody = {
            text: `${payload?.notification?.title} started audio call.`,
            subText: payload?.notification?.body,
            metaData: payload?.data,
            type: AlertEnum.Call,
          };
        }
        if (payload?.data?.incomming_call_type == 1) {
          messageBody = {
            text: `${payload?.notification?.title} started video call.`,
            subText: payload?.notification?.body,
            metaData: payload?.data,
            type: AlertEnum.Call,
          };
        }
        if (payload?.data?.incomming_call_type == 3) {
          messageBody = {
            text: `${payload?.notification?.title} booked a consultation.`,
            subText: payload?.notification?.body,
            type: AlertEnum.Booking,
          };
        }
        if (payload?.data?.incomming_call_type == 4) {
          messageBody = {
            text: `${payload?.notification?.title} sent you a message.`,
            subText: payload?.notification?.body,
            type: AlertEnum.Message,
          };
        }

        dispatch(setMessage(messageBody));
      })
      .catch((err) => console.log("", err));
  }, 1000)

  return (
    <>

      <LayoutProvider>
        <RootRoutes />
      </LayoutProvider>
    </>
  );
}

export default App;
