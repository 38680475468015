import React, { useState } from 'react';
import ReactPaginate from 'react-paginate';

const Pagination = ({ totalItems, itemsPerPage, onPageChange }) => {
    const [currentPage, setCurrentPage] = useState(0); // Current page index
    const pageCount = Math.ceil(totalItems / itemsPerPage); // Total number of pages

    const handlePageChange = ({ selected }) => {
        
        setCurrentPage(selected); // Update the current page index
        onPageChange(selected); // Call the provided onPageChange callback with the selected page index
    };

    return (
        <div className='common_pagination'>
            <ReactPaginate
                previousLabel={'Previous'}
                nextLabel={'Next'}
                breakLabel={'...'}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageChange}
                containerClassName={'pagination'}
                activeClassName={'active'}
            />
        </div>

    );
};

export default Pagination;