import React, { useState } from "react";
import { useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { LabTestCenters, LabtestCities, LabtestLabs, clearList, setLabTestCenterDetailsById } from "../../../Store/Reducer/LabtestSlice";
import { Icon } from "../../../Utilities/Icons";
import location from '../../../Assets/img/png/location.png'
import CommonSlice, { GetCity } from "../../../Store/Reducer/CommonSlice";
import downloadArrow from '../../../Assets/img/svg/downarrow_solid.svg'
import LabCityModal from "./LabCityModal";

const PathologyLab = (props) => {

  const { labTestCenterList, labTestCenterDetailsById } =
    useSelector(({ LabtestSlice }) => LabtestSlice);
  //   const { session } = useSelector(({ AuthSlice }) => AuthSlice);
  const [cityId, setCityId] = useState()
  const dispatch = useDispatch();


  const [gender, setGender] = useState();

  function onChangeValue(event) {
    setGender(event.target.value);
  }

  const { city } = useSelector(({ CommonSlice }) => CommonSlice);

  useEffect(() => {
    dispatch(GetCity())
  }, [dispatch]);


  useEffect(() => {
    if (gender) {
      labTestCenterList?.filter(function (item) {
        if (gender == item?.id) {
          dispatch(setLabTestCenterDetailsById(item));
          props?.formik?.setFieldValue("center_id", item?.id);
        }
      });
    } else {
      setGender(labTestCenterDetailsById.id);
    }
  }, [gender]);


  const cityJSON = localStorage.getItem("USER");
  const cityObject = JSON.parse(cityJSON);


  useEffect(() => {
    const selectedCityId = props?.selectedCitys?.value || cityObject?.city_id;

    let request = {
      city_id: selectedCityId,
    };

    dispatch(LabTestCenters(request));
  }, [dispatch, props?.selectedCitys?.value, cityObject?.city_id]);
  const fetchData = async () => {
    try {
      dispatch(clearList());
      dispatch(LabtestLabs({
        center_id: labTestCenterDetailsById?.id,
        city_id: labTestCenterDetailsById?.city_id,
        page: 1
      }));
      // setList([ ...list , ...labTestList ])
      // dispatch(updatelist([...list, ...labTestList]))
    } catch (error) {
      // console.error('Error fetching data:', error);
    }
  };
  // useEffect(() => {
  //   dispatch(clearList());
  // },[dispatch])
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="lab_test_modal_box"
        backdrop="static"
      >
        <Modal.Header
          className="lab_test_modal_box_head_padding"
          style={{ marginBottom: "none", border: "none" }}
        >
          <h5 className="lab_test_popup_title">Select Pathology Lab</h5>
          <img
            src={Icon.cross}
            className="lab_test_popup_close"
            onClick={props.onHide}
            alt=""
          ></img>
        </Modal.Header>
        <Modal.Body>
          <div className="lab_test_modal_box_2">
            <div className="location-dropdown">
              <div className="location-header" onClick={() => { props.onNextCityModal() }}>
                <img src={location} alt="Location Icon" />
                <span className="city-name">
                {
                                    props?.selectedCitys?.label === undefined
                                      ? (cityObject?.city_details?.name === undefined ? "plz choose city" : cityObject?.city_details?.name)
                                      : props?.selectedCitys?.label
                                  }
                </span>
                <img src={downloadArrow} ></img>
              </div>
            </div>
            {labTestCenterList?.length > 0 ?
              labTestCenterList?.map((item, index) => (
                <>
                  <div className="lab_member_box" key={index}>

                    <div className="lab_member_box_inner">
                      <div>
                        <div className="health_radiological_popup_list_box_inner">
                          <div>
                            <img
                              className="select_radiology_img"
                              src={`${item?.lab_logo}`}
                              alt=""
                              height="70px"
                              width="70px"
                            ></img>
                          </div>
                          <div>
                            <label for="Radiological_Test7">
                              <h3
                                className="healthradiological_radiological_test_title"
                                onClick={() => {
                                  props.setRadiologyCenterDetailModel(true);
                                  props.onHide();

                                }}
                              >
                                {item?.lab_name}
                              </h3>
                              <div className="healthradiological_star_box">
                                {
                                  item?.ratings.length > 0 && (
                                    <>
                                      <h5 className="healthradiological_star_text">
                                        <img
                                          src={Icon.star}
                                          className="healthradiological_star_icon"
                                          alt=""
                                        ></img>
                                        {item?.ratings?.length}
                                      </h5>
                                      <h6 className="healthradiological_star_review">
                                        {item?.avg_ratings} Reviews
                                      </h6>
                                    </>
                                  )
                                }
                              </div>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div>
                        <input
                          type="radio"
                          id={item?.id}
                          name="gender"
                          className="healthradiological_input"
                          checked={gender == item?.id}
                          onChange={(e) => onChangeValue(e)}
                          value={item?.id}
                          onClick={() => { fetchData(); dispatch(clearList()); dispatch(setLabTestCenterDetailsById(item)); }}
                          style={{ accentColor: "#199a8e" }}
                        />
                      </div>
                    </div>
                  </div>
                  <hr className="consult_member_hr" />
                </>
              )) : <>
                <p className="nodata">No lab Found!</p>
              </>}

            <center>
              {gender ? (
                <Button
                  className="lab_member_modal_btn"
                  onClick={() => {
                    props.onHide();
                    props.onNext();
                  }}
                >
                  Continue
                </Button>
              ) : null}
            </center>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PathologyLab;
