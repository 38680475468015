import { BASE_URL, GET, MEDKART_URL, MKPOST, POST } from "../Utilities/HTTP";

//Common API
export const GetBannerListForNonLoggedUserAPI = (data) =>
  GET(`${BASE_URL}/user/com/banner-list`, data);

export const GetHealthProblemsForNonLoggedUserAPI = (data) =>
  GET(`${BASE_URL}/user/com/health-problem-list`, data);

export const SpecialityListForNonLoggedUserAPI = (data) =>
  GET(`${BASE_URL}/user/com/speciality`, data);



//* SIGNIN API REQUEST
export const SignInAPI = (data) => POST(`${BASE_URL}/user/login`, data);
export const SignOutAPI = (data) => POST(`${BASE_URL}/user/logout`, data);
export const DeleteAccount = (data) => POST(`${BASE_URL}/user/delete-account`, data);
export const OTPResendSignInAPI = (data) =>
  POST(`${BASE_URL}/user/resend-otp`, data);

export const EditUserProfileAPI = (data) =>
  POST(`${BASE_URL}/user/edit-profile`, data);
export const EditMemberProfileAPI = (data) =>
  POST(`${BASE_URL}/user/consultation-member-edit`, data);
export const GetCityAPI = (data) => POST(`${BASE_URL}/user/city-list`, data);
export const OTPVerifySignInAPI = (data) =>
  POST(`${BASE_URL}/user/verify-otp`, data);
export const GetUserProfileAPI = (data) =>
  POST(`${BASE_URL}/user/profile-details`, data);
export const GetSubscriptionPlanAPI = (data) =>
  POST(`${BASE_URL}/user/subscription-plan-list`, data);
export const GetSubscriptionHomePlanAPI = (data) =>
  POST(`${BASE_URL}/user/subscription-plan-list-new`, data);
export const CardHomeAPI = (data) =>
  POST(`${BASE_URL}/user/home`, data);
export const ShareMyLinkAPI = (data) =>
  POST(`${BASE_URL}/user/doctor-details`, data);
export const GetMemberList = (data) =>
  POST(`${BASE_URL}/user/consultation-member-list`, data);
export const GetDoctorList = (data) =>
  POST(`${BASE_URL}/user/consult-doctor-list`, data);

export const GetBannerListAPI = (data) =>
  POST(`${BASE_URL}/user/banner-list`, data);

//DOCTOR PROFILE

export const DoctorProfiles = (data) =>
  POST(`${BASE_URL}/doctor/profile-details`, data);

// ADD MEMBER
export const AddNewMember = (data) =>
  POST(`${BASE_URL}/user/consultation-member-create`, data);
export const ConsultationMemberDeleteAPI = (data) =>
  POST(`${BASE_URL}/user/consultation-member-delete`, data);
export const GetMemberDetails = (data) =>
  POST(`${BASE_URL}/user/member-details`, data);
export const GetHeightListAPI = (data) =>
  POST(`${BASE_URL}/user/height-list`, data);
export const GetWeightListAPI = (data) =>
  POST(`${BASE_URL}/user/weight-list`, data);
export const GetHealthProblemsAPI = (data) =>
  POST(`${BASE_URL}/user/health-problem-list`, data);
export const CreateBookConsultant = (data) =>
  POST(`${BASE_URL}/user/booking-create`, data);
export const UpcomingBookings = (data) =>
  POST(`${BASE_URL}/user/upcoming-bookings`, data);
export const PastBookings = (data) =>
  POST(`${BASE_URL}/user/past-bookings`, data);
export const BookingCancel = (data) =>
  POST(`${BASE_URL}/user/booking-cancel`, data);
export const CancelationReason = (data) =>
  POST(`${BASE_URL}/user/cancelation-reason`, data);
export const RescheduleBooking = (data) =>
  POST(`${BASE_URL}/user/reschedule-booking`, data);
export const AddRating = (data) => POST(`${BASE_URL}/user/add-rating`, data);

//Medicine Flow API
export const GetMedKartTokenAPI = async (data) =>
  POST(`${MEDKART_URL}/v1/token`, data);
export const GetMedicineAPI = async (data) =>
  GET(`${MEDKART_URL}/v1/product/search`, data);
export const GetMedicineDetailAPI = async (data) =>
  GET(`${MEDKART_URL}/v1/product/detail?=`, data);
export const OrderPlaceAPI = async (data) =>
  MKPOST(`${MEDKART_URL}/v1/order/place`, data);
export const OrderCancelAPI = async (data) =>
  MKPOST(`${MEDKART_URL}/v1/order/cancel`, data);
//Medicine Orders
export const MedicineOrderCreateAPI = async (data) =>
  POST(`${BASE_URL}/user/medicine-order-create`, data);
export const MedicineOrderDetailsAPI = async (data) =>
  POST(`${BASE_URL}/user/medicine-order-details`, data);
export const MedicineOrdersAPI = async (data) =>
  POST(`${BASE_URL}/user/medicine-orders`, data);
export const UserWalletAPI = (data) =>
  POST(`${BASE_URL}/user/user-wallet`, data);
export const RescheduleDoctorDetailsAPI = (data) =>
  POST(`${BASE_URL}/user/doctor-details-list`, data);
export const CouponListAPI = (data) =>
  POST(`${BASE_URL}/user/coupon-list`, data);
export const MedicineCouponListAPI = (data) =>
  POST(`${BASE_URL}/user/medicine-coupon-list`, data);
export const UpcomingFollowupsAPI = (data) =>
  POST(`${BASE_URL}/user/upcoming-followups`, data);
export const CancelFollowupsAPI = (data) =>
  POST(`${BASE_URL}/user/cancel-followups`, data);
export const ConsultDetailsAPI = (data) =>
  POST(`${BASE_URL}/user/booking-details`, data);
export const MedicalHistoryListAPI = (data) =>
  POST(`${BASE_URL}/user/medical-history-list`, data);



//speciality
export const SpecialityListAPI = (data) =>
  POST(`${BASE_URL}/user/speciality`, data);
export const SpecialityWiseHealthProblemsAPI = (data) =>
  POST(`${BASE_URL}/user/speciality-wise-health-problems`, data);
export const SubscribedPlansListAPI = (data) =>
  POST(`${BASE_URL}/user/subscribed-plans`, data);

//Check Booking Slot befor book consultation
export const CheckBookingSlotAPI = (data) =>
  POST(`${BASE_URL}/user/check-booking-slot`, data);




//reschedule doctor detail
export const RescheduleDoctorDetailsSlot = (data) =>
  POST(`${BASE_URL}/user/reschedule-doctor-details`, data);

//address Flow API
export const AddressCreateAPI = (data) =>
  POST(`${BASE_URL}/user/address-create`, data);
export const AddressListAPI = (data) =>
  POST(`${BASE_URL}/user/address-list`, data);
export const AddressDeleteAPI = (data) =>
  POST(`${BASE_URL}/user/address-delete`, data);
export const AddressEditAPI = (data) =>
  POST(`${BASE_URL}/user/address-edit`, data);

//Labtest Flow API
export const LabtestLabsAPI = (data) =>
  POST(`${BASE_URL}/user/Labtest-labs-new`, data);
export const LabtestBookingCreateAPI = (data) =>
  POST(`${BASE_URL}/user/Labtest-booking`, data);
export const LabtestSlotAPI = (data) =>
  POST(`${BASE_URL}/user/Labtest-labs-center-slots`, data);
export const LabtestCancelReasonsAPI = (data) =>
  POST(`${BASE_URL}/user/Labtest-cancel-reasons`, data);
export const LabtestCancelAPI = (data) =>
  POST(`${BASE_URL}/user/Labtest-Cancel`, data);
export const LabtestStatusAPI = (data) =>
  POST(`${BASE_URL}/user/Labtest-Status`, data);
export const LabtestResultReportAPI = (data) =>
  POST(`${BASE_URL}/user/Labtest-ResultReport`, data);
export const LabtestBookingDetailsAPI = (data) =>
  POST(`${BASE_URL}/user/Labtest-booking-details`, data);
export const LabtestStatesAPI = (data) =>
  POST(`${BASE_URL}/user/Labtest-states`, data);
export const LabtestCitiesAPI = (data) =>
  POST(`${BASE_URL}/user/Labtest-Cities`, data);
export const LabtestUserAddressAPI = (data) =>
  POST(`${BASE_URL}/user/Labtest-userAddress`, data);
export const LabtestAddressListAPI = (data) =>
  POST(`${BASE_URL}/user/Labtest-addressList`, data);
export const LabtestUpcomingBookingAPI = (data) =>
  POST(`${BASE_URL}/user/Labtest-upcoming-bookings`, data);
export const LabtestPastBookingsAPI = (data) =>
  POST(`${BASE_URL}/user/Labtest-past-bookings`, data);
export const LabtestCoupanListAPI = (data) =>
  POST(`${BASE_URL}/user/Labtest-coupon-list`, data);
export const LabTestCentersApi = (data) =>
  POST(`${BASE_URL}/user/Labtest-labs-centers`, data);
export const LabTestCenterDetailsApi = (data) =>
  POST(`${BASE_URL}/user/Labtest-labs-center-details`, data);

//Radiological Test Flow API
export const RadiologyDataApi = (data) =>
  POST(`${BASE_URL}/user/radiological-labs`, data);
export const RadiologyBookingCreateApi = (data) =>
  POST(`${BASE_URL}/user/radiological-booking-create`, data);
export const RadiologyBookingDetailsApi = (data) =>
  POST(`${BASE_URL}/user/radiological-booking-details`, data);
export const RadiologyTestCentersApi = (data) =>
  POST(`${BASE_URL}/user/radiological-test-centers`, data);
export const RadiologyTestCenterDetailsApi = (data) =>
  POST(`${BASE_URL}/user/radiological-test-center-details`, data);
export const RadiologyTestCenterSlotsApi = (data) =>
  POST(`${BASE_URL}/user/radiological-test-center-slots`, data);
export const RadiologyUpcomingBookingsApi = (data) =>
  POST(`${BASE_URL}/user/radiological-upcoming-bookings`, data);
export const RadiologyPastBookingApi = (data) =>
  POST(`${BASE_URL}/user/radiological-past-booking`, data);
export const RadiologyRescheduleBookingApi = (data) =>
  POST(`${BASE_URL}/user/radiological-reschedule-booking`, data);
export const RadiologyCancelBookingApi = (data) =>
  POST(`${BASE_URL}/user/radiological-cancel-bookings`, data);
export const RadiologicalCoupanListAPI = (data) =>
  POST(`${BASE_URL}/user/radiological-coupon-list`, data);


export const HealthList = (data) =>
  POST(`${BASE_URL}/user/service-list`, data);
export const HealthCategoryList = (data) =>
  POST(`${BASE_URL}/user/category-list`, data);
export const HealthCareBookingCreateApi = (data) =>
  POST(`${BASE_URL}/user/home-health-care-booking`, data);
export const HealthCareUpcomingBookingsApi = (data) =>
  POST(`${BASE_URL}/user/home-health-care-upcoming-bookings`, data);
export const HealthCareBookingDetailsAPI = (data) =>
  POST(`${BASE_URL}/user/home-health-care-booking-details`, data);
export const CreateSpecialityBookingAPI = (data) =>
  POST(`${BASE_URL}/user/subscription-plan-booking`, data);



export const SurgeryList = (data) =>
  POST(`${BASE_URL}/user/surgery-category-list`, data);
export const SurgeryServiceList = (data) =>
  POST(`${BASE_URL}/user/surgery-service-list`, data);
export const SurgeryBookingAPI = (data) =>
  POST(`${BASE_URL}/user/surgery-booking`, data);


export const HealthPackageBookingCreateAPI = (data) =>
  POST(`${BASE_URL}/user/package-booking`, data);
export const HealthPackageBookingDetailsAPI = (data) =>
  POST(`${BASE_URL}/user/package-booking-details`, data);
export const HealthPackageUpcomingBookingAPI = (data) =>
  POST(`${BASE_URL}/user/package-Labtest-upcoming-bookings`);
export const HealthPackageUpcomingBookingesAPI = (data) =>
  POST(`${BASE_URL}/user/package-Labtest-upcoming-bookings`);
export const HealthPackagePastBookingAPI = (data) =>
  POST(`${BASE_URL}/user/package-Labtest-past-bookings`, data);

//member ship
export const MemberShipDeatilBookingCreateAPI = (data) =>
  POST(`${BASE_URL}/user/membership-booking-status`, data);
export const MemberShipBooking = (data) =>
  POST(`${BASE_URL}/user/membership-booking`, data);
export const MemberShipBookingStatus = (data) =>
  POST(`${BASE_URL}/user/membership-details`, data);

//refer
export const ReferalDetail = (data) =>
  POST(`${BASE_URL}/user/refferal`, data);


//precepion
export const PreceptionApi = (data) =>
  POST(`${BASE_URL}/user/my_prescriptions`, data);

//package-list
export const PackageListAPI = (data) =>
  POST(`${BASE_URL}/user/package-list`, data);

export const PackageDetailsAPI = (data) =>
  POST(`${BASE_URL}/user/package-details`, data);

//wallet-list

export const GetMyWalletListAPI = (data) =>
  POST(`${BASE_URL}/user/transaction-history`, data);

// My Subscription-list
export const GetMySubscribedPlansListAPI = (data) =>
  POST(`${BASE_URL}/user/all-subscribed-plans`, data);

// My Subscription-list-detail
export const GetMySubscribedPlansListDetailAPI = (data) =>
  POST(`${BASE_URL}/user/all-subscribed-plan-details`, data);

export const NotifyAPI = (data) => POST(`${BASE_URL}/user/notification-send`, data);


//checking leads
export const ClickCard = (data) => POST(`${BASE_URL}/user/click-event-lead`, data);
export const consultionClick = (data) => POST(`${BASE_URL}/user/booking-create-lead`, data);