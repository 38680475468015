import moment from "moment";
import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const PaymentSuccessfullModel = (props) => {
  const navigate = useNavigate();

  const { radiologyBookingDetailsById , wallet_price } = useSelector(
    ({ RadiologySlice }) => RadiologySlice
  );

  
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="modal_455"
      backdrop="static"
    >
      <Modal.Header
        className="modal_bg popup_modal_head"
        style={{ marginBottom: "none", border: "none" }}
      >
        <div className="">
          <h5 className="payment_done_popup_modal_title">
            Payment Successfully Done
          </h5>
        </div>
      </Modal.Header>
      <Modal.Body className="modal_bg payment_modal_body">
        <div className="payment_done_card_box mb_10">
          <h5 className="radiology_booked_test_for">Test For</h5>
          <div className="radiology_booked_test_profile_box">
            <div>
              <div className="addnewmember_profile_icon">
                {radiologyBookingDetailsById?.payload?.member_name?.charAt(0)}{" "}
              </div>
            </div>
            <div>
              <h5 className="radiology_booked_test_for_name">
                {radiologyBookingDetailsById?.payload?.member_name}
              </h5>
            </div>
          </div>
          {radiologyBookingDetailsById?.payload?.test_data?.map(
            (item, index) => (
              <div className="d-flex justify-content-between">
              <h3 className="payment_done_test_name"> {item?.test_name}</h3>
              <h3 className="payment_done_test_name"> ₹{item?.test_price}</h3>
             </div>
            )
          )}
          <div className="row mb_10">
            <div className="col-md-6 col-5">
              <h3 className="payment_done_book">Booked for</h3>
            </div>
            <div className="col-md-6 col-7">
              <h3 className="payment_done_book_time">
                {" "}
                {moment(
                  radiologyBookingDetailsById?.payload?.booking_date
                ).format("DD MMM YYYY")}{" "}
                |{" "}
                {
                  moment(
                    radiologyBookingDetailsById?.payload?.booking_time,
                    "HH:mm"
                  ).format("h:mm A") // "14:00"
                }
              </h3>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-5">
              <h3 className="payment_done_book">Booking id </h3>
            </div>
            <div className="col-md-6 col-7">
              <h5 className="healthradio_center_title">
                {radiologyBookingDetailsById?.payload?.booking_id}
              </h5>
              {/* <h5 className="healthradio_center_subtitle">
                11 shivam flat, newar sport complex, tulishidham,
              </h5> */}
            </div>
          </div>
        </div>
        <div className="payment_done_card_box mb_10">
          {/* <h3 className="healthradio_center_name">Radiology Center</h3> */}
          <h5 className="healthradio_center_title_name">Radiology Center</h5>
          <h5 className="healthradio_center_title_add">
            {radiologyBookingDetailsById?.payload?.center_address}
            {/* 11 shivam flat, newar sport complex,
            <br /> tulishidham, manjalpur, vadodara */}
          </h5>
        </div>
        <div className="payment_done_card_box">
          <h3 className="payment_done_payment_info">Payment info</h3>
          <hr className="payment_done_hr" />
          <div className="row">
            <div className="col-md-6 col-6">
              <h5 className="payment_done_payment_info_title">Paid amount</h5>
            </div>
            <div className="col-md-6 col-6">
              <h5 className="payment_done_payment_info_value">
                ₹{radiologyBookingDetailsById?.payload?.paid_amount}
              </h5>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-6">
              <h5 className="payment_done_payment_info_title">Wallet amount</h5>
            </div>
            <div className="col-md-6 col-6">
              <h5 className="payment_done_payment_info_value">
                ₹ {wallet_price?.cut_amount || 0}
              </h5>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-6">
              <h5 className="payment_done_payment_info_title">Trxn ID</h5>
            </div>
            <div className="col-md-6 col-6">
              <h5 className="payment_done_payment_info_value">
                {radiologyBookingDetailsById?.payload?.transaction_id}
              </h5>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-6">
              <h5 className="payment_done_payment_info_title">Date and Time</h5>
            </div>
            <div className="col-md-6 col-6">
              <h5 className="payment_done_payment_info_value">
                {moment(
                  radiologyBookingDetailsById?.payload?.payment_date
                ).format("DD MMM YYYY | h:mm A")}
                {/* {moment(
                  radiologyBookingDetailsById?.payload?.booking_date
                ).format("DD-MMM-YYYY")}{" "}
                |{" "}
                {
                  moment(
                    radiologyBookingDetailsById?.payload?.booking_time,
                    "HH:mm"
                  ).format("h:mm A") // "14:00"
                } */}
              </h5>
            </div>
          </div>
        </div>
        <center>
          <Button
            className="payment_ok_btn"
            onClick={() => {
              props.onHide();
              navigate("/");
            }}
          >
            OK
          </Button>
        </center>
      </Modal.Body>
      <Modal.Footer className="modal_bg"></Modal.Footer>
    </Modal>
  );
};

export default PaymentSuccessfullModel;
