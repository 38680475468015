import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAJh9bSIe-gZ74qFYxfWzsOmkr_z1FviyA",
  authDomain: "thsmedical-3333e.firebaseapp.com",
  projectId: "thsmedical-3333e",
  storageBucket: "thsmedical-3333e.appspot.com",
  messagingSenderId: "850749344600",
  appId: "1:850749344600:web:64d72f1207caea2af3332d",
  measurementId: "G-41PQEPW141",
};

const FirebaseApp = initializeApp(firebaseConfig);
export const messaging = getMessaging(FirebaseApp);
if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("/patient/firebase-messaging-sw.js") // Adjust the path as needed
    .then((registration) => {
    })
    .catch((error) => {
    });
}
export const FirebaseDB = getFirestore(FirebaseApp);

export const GetFirbaseToken = async () => {
  try {
    if (Notification.permission === 'default') {
      const permission = await Notification.requestPermission();
      if (permission !== 'granted') {
    
        return null;
      }
    }

    const currentToken = await getToken(messaging, {
      vapidKey: "BJbEZL3uHsKTBM6_d-3hR3bepIKfIjLWpFQ1IIs-U33ouIRe0sn4qryjPtzAWQHuLX29M7mLMVF6qwqTVHCuIls",
    });
  

    if (currentToken) {
      return currentToken;
    } else {
     
      return null;
    }
  } catch (error) {
  
    return null;
  }
};
export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
     
      resolve(payload);
    });
  });