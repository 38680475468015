import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  HealthPackagePastBooking,
  HealthPackageUpcomingBooking,
  LabtestCancelReasons,
  LabtestPastBookings,
  LabtestUpcomingBooking,
  SelectedCancelReasons,
} from "../../../../Store/Reducer/LabtestSlice";
import { Icon } from "../../../../Utilities/Icons";
import LabCancelationReasonModel from "../../../Common/LabTestModel/LabCancelationReasonModel";
import Pagination from "../../../Common/Pagination/Pagination";
import backimg from '../../../../Assets/img/png/NoDocuments.png'

const My_Test_Bookings = () => {
  const [cancelationReasonModel, setCancelationReasonModel] = useState(false);
  const [upcomingBookings, setUpcomingBookings] = useState([]);
  const [pastbooking, SetPastbooking] = useState([]);

  const {
    labtestUpcomingBookingList,
    labtestCancelReasons,
    labtestPastBookingsList,
    healthPackageUpcomingBookingList,
    healthPackagepastBookingList
  } = useSelector(({ LabtestSlice }) => LabtestSlice);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(LabtestCancelReasons("C000145595"));
  }, [dispatch]);
  useEffect(() => {
    dispatch(LabtestPastBookings());
    dispatch(LabtestUpcomingBooking());

  }, [dispatch]);
  // useEffect(() => {
  //   dispatch(HealthPackageUpcomingBooking())
  //   dispatch(HealthPackagePastBooking())
  // }, [dispatch])
  useEffect(() => {
    setUpcomingBookings(labtestUpcomingBookingList)
  }, [labtestUpcomingBookingList])
  useEffect(() => {
    SetPastbooking(labtestPastBookingsList)
  }, [labtestPastBookingsList])

  const [currentPage, setCurrentPage] = useState(0); // Current page index
  const itemsPerPage = 5;
  const handlePageChange = (selected) => {
    setCurrentPage(selected); // Update the current page index
  };
  const upcomingpagination = upcomingBookings.hasError
    ? []
    : upcomingBookings?.slice(
      currentPage * itemsPerPage,
      (currentPage + 1) * itemsPerPage
    );
  const [currentPage1, setCurrentPage1] = useState(0);
  const handlePageChanges = (selected) => {
    setCurrentPage1(selected); // Update the current page index
  };
  const pastpagination = pastbooking.hasError
    ? []
    : pastbooking?.slice(
      currentPage * itemsPerPage,
      (currentPage + 1) * itemsPerPage
    );

  return (
    <>
      <LabCancelationReasonModel
        show={cancelationReasonModel}
        cancelationReason={labtestCancelReasons}
        onHide={() => {
          setCancelationReasonModel(!cancelationReasonModel);
        }}
      />
      <div className="my_test_body_box_card_head">
        <div className="row">
          <div className="col-md-6">
            <h3 className="my_test_body_card_head_title">Lab Test Bookings</h3>
          </div>
          <div className="col-md-6"></div>
        </div>
      </div>
      <div className="my_test_body_box_card_body">
        <div className="row">
          <div className="col-md-12">
            <h3 className="upcoming_test_booking_title">
              Upcoming Test Booking
            </h3>

            {upcomingpagination?.length > 0 ? (
              <>
                {upcomingpagination?.map((upcomingBookings) => {

                  return (

                    <div>

                      <div
                        className="upcoming_test_card_head"
                        onClick={() => {
                          navigate(
                            `/lab-test-detail/${upcomingBookings?.order_id}`
                          );
                        }}
                      >
                        <img
                          src={Icon.scheduled}
                          className="upcoming_consultation_card_icon"
                        ></img>{" "}
                        <span className="upcoming_consultation_card_title">
                          {moment(upcomingBookings?.booking_date).format(
                            "DD/MMM/YYYY"
                          )}{" "}
                          at {upcomingBookings?.booking_time} am
                        </span>
                      </div>
                      <div className="upcoming_test_card_body mb_25">
                        <div className="row">
                          <div className="col-md-12">
                            <h5 className="my_test_id">
                              ID#{upcomingBookings?.order_id}
                            </h5>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="row">
                              <div className="col-md-6 col-6">
                                <h5 className="radiology_booked_test_for">
                                  Test For
                                </h5>
                                <div className="radiology_booked_test_profile_box mb_10">
                                  <div>
                                    <div className="addnewmember_profile_icon">
                                      {" "}
                                      {upcomingBookings?.member_details?.name?.charAt(
                                        0
                                      )}{" "}
                                    </div>
                                  </div>
                                  <div>
                                    <h5 className="radiology_booked_test_for_name">
                                      {
                                        upcomingBookings?.member_details
                                          ?.name
                                      }
                                    </h5>
                                  </div>
                                </div>
                                <h3 className="my_test_card_head_title">
                                  Test: {
                                    upcomingBookings?.test?.map((val) => {
                                      return val?.test_name;
                                    })
                                  }

                                </h3>
                                {/* <h5 className="my_test_note">Note: --</h5> */}
                              </div>

                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="consultation_option_box">
                              <div
                                className="consultation_option_box_inner_1"
                                onClick={() => {
                                  setCancelationReasonModel(true);
                                  dispatch(
                                    SelectedCancelReasons(upcomingBookings)
                                  );
                                }}
                              >
                                <img
                                  src={Icon.cancel}
                                  className="cancel_icon"
                                ></img>{" "}
                                <span className="cancel_appointment">
                                  Cancle Appointment
                                </span>
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}

              </>
            ) : (
              <div>
                <div className="col-md-12 d-flex justify-content-center align-items-center doctorequ ">
                  <div className="">
                    <img src={backimg} alt="Image Description" />
                    <p className="text-center">You don't have any upcoming test booking.</p>
                  </div>
                </div>
              </div>
            )}

            <Pagination
              totalItems={upcomingBookings.length}
              itemsPerPage={itemsPerPage}
              onPageChange={handlePageChange}
            />


            <h3 className="past_test_booking_title mt_25">Past Test Booking</h3>

            {pastpagination?.length > 0 ? (
              <>
                {pastpagination?.slice(0).map((labtestPastBookings, index) => {
                  return (
                    <>
                      <div>
                        <div className="past_test_card_head"
                          onClick={() => {
                            navigate(
                              `/lab-test-detail/${labtestPastBookings?.order_id}`
                            );
                          }}
                        >
                          <img
                            src={Icon.scheduled_green}
                            className="upcoming_consultation_card_icon"
                          ></img>{" "}
                          <span className="past_test_card_title">Completed</span>
                        </div>
                        <div className="past_test_card_body mb_25">
                          <div className="row">
                            <div className="col-md-12">
                              <h5 className="my_test_id">
                                ID#{labtestPastBookings?.order_id}
                              </h5>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="row">
                                <div className="col-md-6 col-6">
                                  <h5 className="radiology_booked_test_for">
                                    Test For
                                  </h5>
                                  <div className="radiology_booked_test_profile_box mb_10">
                                    <div>
                                      <div className="addnewmember_profile_icon">
                                        {" "}
                                        {labtestPastBookings?.member_details?.name?.charAt(
                                          0
                                        )}{" "}
                                      </div>
                                    </div>
                                    <div>
                                      <h5 className="radiology_booked_test_for_name">
                                        {labtestPastBookings?.member_details?.name}
                                      </h5>
                                    </div>
                                  </div>
                                  <h3 className="my_test_card_head_title">

                                    Test: {
                                      labtestPastBookings?.test?.map((val) => {
                                        return val?.test_name;
                                      })
                                    }


                                  </h3>
                                  {/* <h5 className="my_test_note">Note: --</h5> */}
                                </div>

                              </div>
                            </div>
                            <div className="col-md-6"></div>
                          </div>
                        </div>
                      </div>
                    </>
                  )
                })}

              </>
            ) : (
              <div>
                <div>
                  <div className="col-md-12 d-flex justify-content-center align-items-center doctorequ ">
                    <div className="">
                      <img src={backimg} alt="Image Description" />
                      <p className="text-center">You don't have any past test booking.</p>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <Pagination
              totalItems={pastbooking.length}
              itemsPerPage={itemsPerPage}
              onPageChange={handlePageChanges}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default My_Test_Bookings;
