import React, { useState, useEffect } from "react";
import { Modal, Button, Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Icon } from "../../../Utilities/Icons";
import { Formik } from "formik";
import { walletPrice } from "../../../Store/Reducer/RadiologySlice";
import { useDispatch } from "react-redux";
export const SpecialityhealthModel = (props) => {
  const navigate = useNavigate();
  const { specialityHealthProblemsList } = useSelector(
    ({ BookingSlice }) => BookingSlice
  );
  const dispatch = useDispatch();

  const { selectedSpecialityId } = useSelector(
    ({ BookingSlice }) => BookingSlice
  );
  const [inputText, setInputText] = useState("");
  let inputHandler = (e) => {
    //convert input text to lower case
    var lowerCase = e.target.value.toLowerCase(); //.toLowerCase();
    setInputText(lowerCase);
  };


  const filteredData =
    specialityHealthProblemsList?.length &&
    specialityHealthProblemsList?.filter((el) => {
      //if no input the return the original
      if (inputText === "") {
        return el;
      }
      //return the item which contains the user input
      else {
        return el.title?.toLowerCase().includes(inputText);
      }
    });

  return (
    <Container>
      <div className="" style={{ padding: "40px 0px" }}>
        <div style={{ border: "none" }}>
          <div className="consultation_cancel_modal_head_box" style={{ paddingBottom: "20px" }}>
            <h5 className="consultation_cancel_modal_title">
              Select Health Problems
            </h5>
          </div>
        </div>
        <div>
          <div className="row">
            <div className="col-md-12">
              <img
                src={Icon.search}
                className="city_search_icon_input"
                alt=""
              ></img>
              <input
                type="text"
                className="health_problem_serach_input"
                placeholder="Enter Health Problems"
                onChange={inputHandler}
              />
            </div>
          </div>
          <div className="consultation_cancel_modal_body_box">
            <Formik
              initialValues={{
                selectedHealthId: [],
              }}
              onSubmit={(values) => {
                localStorage.setItem("selectedHealthId", values.selectedHealthId);
                navigate(`/booking/${selectedSpecialityId}`);
                //values = { ...values, groups: values?.groups.filter((e) => e) };
                // navigate(
                //   `/booking/${values?.selectedHealthId?.id}/${selectedSpecialityId}`
                // );
              }}
            >
              {({
                values,
                handleChange,
                setFieldValue,
                handleBlur,
                handleSubmit,
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-12 p-3">
                      <div
                        style={{
                          height: "60vh",
                          overflow: "hidden",
                          overflowY: "scroll",
                        }}
                      >
                        {
                          filteredData?.length > 0 &&
                          filteredData?.map((item, index) => (
                            <>
                              <label
                                className="consultation_cancel_modal"
                                key={index}
                                style={{ width: "100%" }}
                              >
                                <div
                                  className="consultation_cancel_box_inner"
                                  style={{ alignItems: "center" }}
                                >
                                  <div className="healthpackage health_checkbox">
                                    <input
                                      type="checkbox"
                                      name="selectedHealthId"
                                      className="vh"
                                      id={item.id}
                                      value={item.id}
                                      onChange={handleChange}
                                      onBlur={handleBlur}

                                    />
                                    <label
                                      style={{
                                        display: "inline-flex",
                                        alignItems: "center",
                                      }}
                                      className="healthpackage_checkbox_title"
                                      for={item.id}
                                    >
                                      <div
                                        style={{
                                          alignItems: "center",
                                          display: "flex",
                                        }}
                                      >
                                        <img
                                          src={item.image}
                                          className="health_problems_img "
                                          alt=""
                                        ></img>
                                        <h5 className="health_problems_list_title">
                                          {item?.title}
                                        </h5>
                                      </div>
                                    </label>
                                  </div>
                                </div>
                              </label>
                              <hr className="consultation_cancel_modal_hr" />
                            </>
                          ))}

                        {
                          filteredData?.length == 0
                          && (
                            <h6 text-center>No Health Problems Found</h6>
                          )}
                      </div>
                    </div>
                    {values?.selectedHealthId?.length > 0 && (
                      <div
                        className="col-md-12 text-center"
                        style={{
                          position: "fixed",
                          bottom: "20px",
                          left: 0,
                          right: 0,
                        }}
                      >
                        <Button type="submit" className="cancel_booking_btn">
                          Continue
                        </Button>
                      </div>
                    )}

                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
        <div style={{ border: "none", display: "none" }}></div>
      </div>
    </Container>
  );
};
export default SpecialityhealthModel;
