import moment from "moment";
import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const PaymentSuccessfullModel = (props) => {
  const navigate = useNavigate();
  const { labtestBookingDetailsById } = useSelector(
    ({ LabtestSlice }) => LabtestSlice
  );
  const { goldMembershipStatus, goldMembershipStatus1, wallet_price } = useSelector(
    ({ RadiologySlice }) => RadiologySlice
  );

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="modal_455"
      backdrop="static"
    >
      <Modal.Header
        className="modal_bg popup_modal_head"
        style={{ marginBottom: "none", border: "none" }}
      >
        <div className="">
          <h5 className="payment_done_popup_modal_title">
            Payment Successfully Done
          </h5>
        </div>
      </Modal.Header>
      <Modal.Body className="modal_bg payment_modal_body">
        <div className="payment_done_card_box mb_10">
          <h5 className="radiology_booked_test_for">Test For</h5>
          <div className="radiology_booked_test_profile_box pb_20">
            <div>
              <div className="addnewmember_profile_icon">
                {" "}
                {labtestBookingDetailsById?.member_name?.charAt(0)}{" "}
              </div>
            </div>
            <div>
              <h5 className="radiology_booked_test_for_name">
                {labtestBookingDetailsById?.member_name}
              </h5>
            </div>
          </div>
          {labtestBookingDetailsById?.test?.map((item) => (
            <div className="d-flex justify-content-between">
               <h3 className="payment_done_test_name"> {item?.test_name}</h3>
               <h3 className="payment_done_test_name"> ₹{item?.labs?.amount}</h3>
              </div>
           
          ))}

          <div className="row">
            <div className="col-md-6 col-5">
              <h3 className="payment_done_book">Booked for</h3>
            </div>
            <div className="col-md-6 col-7">
              <h3 className="payment_done_book_time">
                {" "}
                {moment(labtestBookingDetailsById?.booking_date).format(
                  "DD MMM YYYY"
                )}{" "}
                | {labtestBookingDetailsById?.slot}
              </h3>
            </div>
          </div>
        </div>
        <div className="payment_done_card_box">
          <h3 className="payment_done_payment_info">Payment info</h3>
          <hr className="payment_done_hr" />
          <div className="row">
            <div className="col-md-6 col-6">
              <h5 className="payment_done_payment_info_title">prepaid amount</h5>
            </div>
            <div className="col-md-6 col-6">
              <h5 className="payment_done_payment_info_value">
                ₹ {labtestBookingDetailsById?.paid_amount}
              </h5>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-6">
              <h5 className="payment_done_payment_info_title">Wallet amount</h5>
            </div>
            <div className="col-md-6 col-6">
              <h5 className="payment_done_payment_info_value">
                ₹ {wallet_price?.cut_amount}
              </h5>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-6">
              <h5 className="payment_done_payment_info_title">Trxn ID</h5>
            </div>
            <div className="col-md-6 col-6">
              <h5 className="payment_done_payment_info_value">
                {labtestBookingDetailsById?.transaction_id}
              </h5>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-6">
              <h5 className="payment_done_payment_info_title">Date and Time</h5>
            </div>
            <div className="col-md-6 col-6">
              <h5 className="payment_done_payment_info_value">
                {moment(labtestBookingDetailsById?.booking_date_time).format(
                  "DD MMM YYYY | h:mm A"
                )}{" "}
              </h5>
            </div>
          </div>
        </div>
        <center>
          <Button
            className="payment_ok_btn"
            onClick={() => {
              props.onHide();
              navigate("/");
            }}
          >
            OK
          </Button>
        </center>
      </Modal.Body>
      <Modal.Footer className="modal_bg"></Modal.Footer>
    </Modal>
  );
};

export default PaymentSuccessfullModel;
