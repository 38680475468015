import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import { Button } from "react-bootstrap";
import "../../../../Assets/css/responsive.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { GetMedicineDetail } from "../../../../Store/Reducer/MedicineSlice";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  decrementQuantity,
   GetMedicine,
  incrementQuantity,
  madicineAddToCart,
  setQuantity,
} from "../../../../Store/Reducer/MedicineSlice";
function MedicinesDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { medicineDetail } = useSelector(({ MedicineSlice }) => MedicineSlice);
  const { cart } = useSelector(({ MedicineSlice }) => MedicineSlice);

  const [previewImage, setPreviewImage] = useState();
  useEffect(() => {
    dispatch(GetMedicineDetail(id));
  }, []);
  useEffect(() => {
    setPreviewImage(medicineDetail?.product?.images[0]);
  }, [medicineDetail]);

  const getTotalQuantity = () => {
    let total = 0;
    cart?.forEach((item) => {
      total += item.quantity;
    });
 
    return total;
  };
  const getTotal = () => {
    let totalQuantity = 0;
    let totalPrice = 0;
    cart?.forEach((item) => {
      totalQuantity += item.quantity;
      totalPrice += item.sales_price * item.quantity;
    });
    return { totalPrice, totalQuantity };
  };
  return (
    <>
      {/* <div className="header">
        <Container>
          <div className="row">
            <div className="col-md-12">
              <div className="home_text">
                Home / Buy Medicine /
                <h3 className="bones_text">Medicine Details</h3>
              </div>
            </div>
          </div>
        </Container>
      </div> */}
      <Container fluid className="medicine_container">
        <Container>
          <div className="medicine_card_box">
            <div className="row">
              <div className="col-md-7">
                <div className="medicine_slider">
                  <div className="medicine_slider_box_width">
                    {medicineDetail?.product?.images?.map((img, index) => (
                      <div
                        className={`${previewImage?.url === img?.url
                          ? "medicine_slider_box_active"
                          : null
                          }`}
                      >
                        <img
                          key={index}
                          src={img.url}
                          className="slider_img"
                          alt=""
                          onClick={() => setPreviewImage(img)}
                        ></img>
                      </div>
                    ))}
                  </div>
                  <div className="">
                    <div className="medicine_slider_img">
                      <img
                        src={previewImage?.url}
                        className="medicine_slider_img"
                        alt=""
                      ></img>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-5">
                <div>
                  <h3 className="medicine_name">
                    {medicineDetail.product?.name}
                  </h3>
                  <div className="mt_10">
                    <span className="medicine_mrp">MRP</span>
                    <span className="medicine_mrp_through_value">
                      ₹{medicineDetail.product?.mrp}
                    </span>
                    <span className="medicine_mrp_value">
                      ₹ {medicineDetail.product?.sales_price}
                    </span>
                  </div>
                  <h5 className="medicine_strip_of mt_10">Strip of 15</h5>
                  <div className="custom-select mt_10">
                    <select name="custom-select" id="custom-select" onChange={(e) => {dispatch(setQuantity({qty :e.target.value , id :medicineDetail?.product?.id }  ))}}>
                      <option value="1" >1 Stripe</option>
                      <option value="2">2 Stripe</option>
                      <option value="3">3 Stripe</option>
                      <option value="4">4 Stripe</option>
                      <option value="5">5 Stripe</option>
                    </select>
                  </div>
                  <Button className="add_to_cart_btn mt_20"
                    onClick={() =>  { dispatch(madicineAddToCart(medicineDetail.product)) ; {navigate("/buymedicinecart")}}}
                  >Add to Cart</Button>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                {
                  medicineDetail?.description == "null" ? <>
                    <div className="product_details">
                      <h3 className="product_details_text">Product Details</h3>
                      <p className="product_details_para">
                        Dolo-650 Tablet 15's belongs to the className of drugs known
                        as 'non-steroidal anti-inflammatory drug' (NSAID). It is a
                        fixed-dose combination composed of aceclofenac, paracetamol
                        and serratiopeptidase. Dolo-650 Tablet 15's is used in the
                        reduction of pain and inflammation due to bone or soft
                        tissue injury, resolution of postoperative inflammation,
                        oedema (swollen tissue with fluid) and pain. Aceclofenac
                        works by blocking the action of an enzyme known as
                        cyclo-oxygenase (COX) which causes pain and swelling in the
                        injured or damaged tissue. Paracetamol acts as a mild
                        analgesic (pain reducer) and antipyretic (fever reducer)
                        which enhances the pain relief action of aceclofenac.
                        Serratiopeptidase is an enzyme which helps in the breakdown
                        of a protein (fibrin) which is formed as a by-product of the
                        clotted blood at the site of injury. It thus causes thinning
                        of the fluids around the site of injury thereby making fluid
                        drainage smoother in the swollen tissue.
                      </p>
                    </div>
                  </> : <>
                    <h3 className="product_details_text">Product Details not found</h3>
                  </>
                }

              </div>
            </div>
          </div>
        </Container>
      </Container>
    </>
  );
}

export default MedicinesDetails;
