import React, { useState } from "react";
import { useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  RadiologyTestCenters,
  setRadiologyTestCenterDetailsById,
} from "../../../Store/Reducer/RadiologySlice";
import { Icon } from "../../../Utilities/Icons";
import LabCityModal from "../LabTestModel/LabCityModal";
import location from '../../../Assets/img/png/location.png';
import downloadArrow from '../../../Assets/img/svg/downarrow_solid.svg';
const RadiologyCenterModel = (props) => {
  const { radiologyTestCenterList, radiologyTestCenterDetailsById } =
    useSelector(({ RadiologySlice }) => RadiologySlice);
  const { session } = useSelector(({ AuthSlice }) => AuthSlice);

  const dispatch = useDispatch();
  const cityJSON = localStorage.getItem("USER");
  const cityObject = JSON.parse(cityJSON);
  // useEffect(() => {
  //   let request = {
  //     city_id: cityObject?.city_id,
  //   };
  //   dispatch(RadiologyTestCenters(request));
  // }, [dispatch, cityObject?.city_id]);

  useEffect(() => {
    const selectedCityId = props?.selectedCitys?.value || cityObject?.city_id;

    let request = {
      city_id: selectedCityId,
    };
 
    dispatch(RadiologyTestCenters(request));
  }, [dispatch, props?.selectedCitys?.value, cityObject?.city_id]);


  const [gender, setGender] = useState();

  function onChangeValue(event) {
    setGender(event.target.value);
  }
  const [cityModal, setCityModal] = useState(false);
  const [labcitymodal, setLabcitymodal] = useState(false);
  const [labcentermodal, setLabcentermodal] = useState(false);
  const [radiomodal1, setRadioshow1] = useState(false);
  const [selectedCity, setSelectedCity] = useState("");

  useEffect(() => {
    if (gender) {
      radiologyTestCenterList?.filter(function (item) {
        if (gender == item?.id) {
          dispatch(setRadiologyTestCenterDetailsById(item));
          props?.formik?.setFieldValue("center_id", item?.id);
        }
      });
    } else {
      setGender(radiologyTestCenterDetailsById.id);
    }
  }, [gender]);




  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="lab_test_modal_box"
      backdrop="static"
    >
      <Modal.Header
        className="lab_test_modal_box_head_padding"
        style={{ marginBottom: "none", border: "none" }}
      >
        <h5 className="lab_test_popup_title">Select Radiology Center</h5>
        <img
          src={Icon.cross}
          className="lab_test_popup_close"
          onClick={props.onHide}
          alt=""
        ></img>
      </Modal.Header>
      <Modal.Body>
        <div className="lab_test_modal_box_2">
          <div className="location-dropdown">
            <div className="location-header" onClick={() => { props.onNextCityModal() }}>
              <img src={location} alt="Location Icon" />
              <span className="city-name">
              {
                                    props?.selectedCitys?.label === undefined
                                      ? (cityObject?.city_details?.name === undefined ? "plz choose city" : cityObject?.city_details?.name)
                                      : props?.selectedCitys?.label
                                  }
              </span>
              <img src={downloadArrow} ></img>
            </div>
          </div>
          {radiologyTestCenterList?.length > 0 ? (
            radiologyTestCenterList.map((item, index) => (
              <div className="lab_member_box" key={index}>
                <div className="lab_member_box_inner">
                  <div>
                    <div className="health_radiological_popup_list_box_inner">
                      <div>
                        <img
                          className="select_radiology_img"
                          src={`${item?.lab_logo}`}
                          alt=""
                          height="70px"
                          width="70px"
                        ></img>
                      </div>
                      <div>
                        <label for="Radiological_Test7">
                          <h3
                            className="healthradiological_radiological_test_title"
                            onClick={() => {
                              props.setRadiologyCenterDetailModel(true);
                              props.onHide();
                              dispatch(
                                setRadiologyTestCenterDetailsById(item)
                              );
                            }}
                          >
                            {item?.lab_name}
                          </h3>
                          <div className="healthradiological_star_box">
                            {item?.ratings.length > 0 && (
                              <>
                                <h5 className="healthradiological_star_text">
                                  <img
                                    src={Icon.star}
                                    className="healthradiological_star_icon"
                                    alt=""
                                  ></img>
                                  {item?.ratings?.length}
                                </h5>
                                <h6 className="healthradiological_star_review">
                                  {item?.avg_ratings} Reviews
                                </h6>
                              </>
                            )}
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div>
                    <input
                      type="radio"
                      // id={item?.id}
                      id="test1"
                      name="gender"
                      className="healthradiological_input"
                      value={item?.id}
                      checked={gender == item?.id}
                      onChange={(e) => onChangeValue(e)}
                      // value={item}
                      // onChange={(e) => {
                      //   dispatch(setRadiologyTestCenterDetailsById(item?.id));
                      //   props?.formik?.setFieldValue("center_id", item?.id);
                      // }}
                      style={{ accentColor: "#199a8e" }}
                    />
                    <lable for="test1"></lable>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p className="text-center pt_20">No Radilogy found</p>
          )}
          <center>
            {
              gender && <Button
                className="lab_member_modal_btn"
                onClick={() => {
                  props.onHide();
                  props.onNext();
                }}
              >
                Continue
              </Button>
            }

          </center>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default RadiologyCenterModel;
