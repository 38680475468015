import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import person from '../../../Assets/img/svg/per.svg'
import location from '../../../Assets/img/svg/loc.svg'
import call from '../../../Assets/img/png/call1.png'
import {
    addToCart,
    LabtestLabs,
    removeItem,
} from "../../../Store/Reducer/LabtestSlice";
import { Icon } from "../../../Utilities/Icons";
import Checkbox from "../Checkbox";
import { HealthList } from "../../../Routes/Service";
import { CardHome } from "../../../Store/Reducer/BookingSlice";
import { SpecialityBoking, SurgeryBookingCreate } from "../../../Store/Reducer/RadiologySlice";
import { GetCity } from "../../../Store/Reducer/CommonSlice";

const SurgeryBooking = (props) => {
    const { labTestList, labCart, labTestCenterDetailsById } = useSelector(
        ({ LabtestSlice }) => LabtestSlice
    );
    const { surgeryserviceList, surgerybooking , specialitybooking ,setServicesurgeryid } = useSelector(
        ({ RadiologySlice }) => RadiologySlice
    );

    const dispatch = useDispatch();

    const [inputText, setInputText] = useState("");

    let inputHandler = (e) => {
        //convert input text to lower case
        var lowerCase = e.target.value.toLowerCase(); //.toLowerCase();
        setInputText(lowerCase);
    };


    const filteredData = surgeryserviceList?.filter((el) => {
        //if no input the return the original
        if (inputText === "") {
            return el;
        }
        //return the item which contains the user input
        else {
            return el.test_name?.toLowerCase().includes(inputText);
        }
    });

    const getTotal = () => {
        let totalQuantity = 0;
        let totalPrice = 0;
        labCart?.forEach((item) => {
            totalPrice += item?.amount * item?.quantity;
        });
        return { totalPrice, totalQuantity };
    };

    let test_name = labCart?.map(function (i) {
        return i?.id;
    });

    const [isCheck, setIsCheck] = useState(test_name);

    const handleClick = (e) => {
        const { id, checked } = e.target;
        const numberId = Number(id);
        setIsCheck([...isCheck, numberId]);
        filteredData?.filter(function (item) {
            if (numberId == item?.id) {
                dispatch(addToCart(item));
            }
        });

        if (!checked) {
            filteredData?.filter(function (item) {
                if (numberId == item?.id) {
                    dispatch(removeItem(item?.id));
                }
            });
            setIsCheck(isCheck.filter((item) => item !== numberId));
        }
    };

    const { city } = useSelector(({ CommonSlice }) => CommonSlice);
    const { session } = useSelector(({ AuthSlice }) => AuthSlice);

    const {
        cards
    } = useSelector(({ BookingSlice }) => BookingSlice);

    useEffect(() => {
        dispatch(CardHome());
    }, [dispatch]);

    const [mobile, setMobile] = useState("");
    const [fullName, setFullName] = useState("");
    const [citys, setCity] = useState("");
    const [mobileError, setMobileError] = useState("");

    const handleMobileChange = (e) => {
        const value = e.target.value;
        setMobile(value);

        // Validate the mobile number
        if (!/^[0-9]{10}$/.test(value)) {
            setMobileError("Please enter a valid 10-digit mobile number.");
        } else {
            setMobileError(""); // Clear the error message if valid
        }
    };

    const [fullNameError, setFullNameError] = useState("");

    const handleFullNameChange = (e) => {
        const value = e.target.value;
        setFullName(value);

        // Validate the Full Name
        if (value.length > 20) {
            setFullNameError("Full Name should not exceed 32 characters.");
        } else {
            setFullNameError(""); // Clear the error message if valid
        }
    };
    const handleCityChange = (e) => {
        setCity(e.target.value);
    };

    useEffect(() => {
        dispatch(GetCity()); // Dispatch action to fetch city list from API
    }, [dispatch]);

    const handleRequestCallBack = () => {
        if (mobileError) {
            // Mobile number is invalid, prevent form submission
            return;
        }

        dispatch(
            SurgeryBookingCreate({
                plan_id: 1,
                member_id: cards?.gold_membership?.id,
                service: setServicesurgeryid && setServicesurgeryid,
                mobile: mobile,
                full_name: fullName,
                city_name: citys,
            })
        );
        if (specialitybooking === true) {
            // Handle special case here
        } else {
            props.onNext(); // Call props.onNext() if API call is successful
        }
    };

    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                dialogClassName="lab_test_modal_box"
                backdrop="static"
            >
                <Modal.Header
                    className="lab_test_modal_box_head_padding"
                    style={{ marginBottom: "none", border: "none" }}
                >
                    <div>
                        <h5 className="lab_test_popup_title">Surgery</h5>
                        <p className="surgeyPtext pt_5">{props?.category?.name}</p>
                    </div>
                    <img
                        src={Icon.cross}
                        className="lab_test_popup_close"
                        onClick={() => {
                            props.onHide();
                        }}
                    ></img>
                </Modal.Header>
                <Modal.Body className="">
                    <form>
                        <div className="lab_test_modal_box_inner_1">
                            <div className="row">
                                <div className="col-md-12">
                                    <img
                                        src={call}
                                        className="select_city_search_icon"
                                    ></img>
                                    <input
                                        type="search"
                                        className="inputtext"
                                        placeholder="Enter Mobile Number"
                                        value={mobile}
                                        onChange={handleMobileChange}
                                        maxLength={10} 
                                    />
                                    {mobileError && <div className="error-message" style={{color:"red"}}>{mobileError}</div>}
                                </div>
                            </div>
                        </div>
                        <div className="lab_test_modal_box_inner_1">
                            <div className="row">
                                <div className="col-md-12">
                                    <img
                                        src={person}
                                        className="select_city_search_icon"
                                    ></img>
                                    <input
                                        type="search"
                                        className="inputtext"
                                        placeholder="Full Name"
                                        value={fullName}
                                        onChange={handleFullNameChange}
                                        maxLength={20}
                                    />
                                     {fullNameError && <div className="error-message" style={{ color: "red" }}>{fullNameError}</div>}
                                </div>
                            </div>
                        </div>
                        <div className="lab_test_modal_box_inner_1">
                            <div className="row">
                                <div className="col-md-12">
                                    <img
                                        src={location}
                                        className="select_city_search_icon"
                                    ></img>
                                    <select
                                        className="inputtext"
                                        value={citys} // Set selected city from state
                                        onChange={handleCityChange}
                                    >
                                        <option value="">Select City</option>
                                        {city && city.map((cityItem) => (
                                            <option key={cityItem.id} value={cityItem.name}>
                                                {cityItem.label}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <center>
                            <Button
                                className="lab_member_modal_btn"
                                onClick={() => { handleRequestCallBack() }}
                            >
                                Request Call Back
                            </Button>
                        </center>
                        <center>
                            <h4 className="centertext">In case you have any difficulties you can
                                reach out to our customer care by Email</h4>
                            <h3 className="supporttext">support@thsindia.in</h3>
                        </center>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default SurgeryBooking;
