import { Timestamp } from "firebase/firestore";
import React, { useState, useImperativeHandle, useRef } from "react";
import { useDispatch } from "react-redux";
import { SendMessage } from "../../../../Store/Reducer/ChatReducer";
import { UploadFile } from "../../../../Store/Reducer/CommonSlice";
import { MessageEnum } from "../../../../Utilities/Enums";
import { Icon } from "../../../../Utilities/Icons";

const ChatInput = React.forwardRef(
  ({ localFile, setLocalFile, consultDetails }, ref) => {
    const dispatch = useDispatch();
    const [text, setText] = useState();
    const [message, setMessage] = useState(MessageEnum);

    const docRef = useRef();
    const mediaRef = useRef();

    const prepareMessage = (data, type) => {
      try {
        let tempMessage = { ...message };
        tempMessage.dateTime = Timestamp.now();
        if (typeof data === "string") tempMessage.message = data;
        if (type !== 0) {
          tempMessage.sizeOfDocument = data?.size;
          tempMessage.extension = data?.type;
          tempMessage.imageName = data?.name;
          tempMessage.documentType = type;
        }
        setMessage(tempMessage);
      } catch (error) {
 
      }
    };

    const handleDoc = (e) => {
      e.preventDefault();
      let file = e.target.files[0];
      setLocalFile(e.target.files[0]);
      prepareMessage(file, 3);
    };
    const handleMedia = (e) => {
      e.preventDefault();
      let file = e.target.files[0];
      let type = file?.type.includes("image") ? 1 : 2;
      setLocalFile(e.target.files[0]);
      prepareMessage(file, type);
    };

    const send = async (e) => {
      e.preventDefault();
      if (!localFile) {
        dispatch(
          SendMessage({ ...message, doctor_id: consultDetails?.doctor_id })
        );
      } else {
        dispatch(UploadFile({ file: localFile })).then(({ payload }) => {
          let tempMessage = { ...message };
          tempMessage.imageUrl = payload?.data;
          dispatch(
            SendMessage({
              ...tempMessage,
              doctor_id: consultDetails?.doctor_id,
            })
          );
        });
      }
      setText("");
      setMessage(MessageEnum);
      setLocalFile(undefined);
    };
    useImperativeHandle(
      ref,
      () => ({
        clearMessage: () => {
          return setMessage({ ...MessageEnum });
        },
      }),
      [localFile]
    );

    return (
      <div className="consult_chat_message_list">
        <div className="message_box_inner_2">
          <input
            type="text"
            className="message_input"
            placeholder="Write your message..."
            value={text}
            onKeyDown={(e) => {
              if (e.key === "Enter" && (text || localFile)) {
                send(e);
              }
            }}
            onChange={(e) => {
              e.preventDefault();
              setText(e.target.value);
              prepareMessage(e.target.value, 0);
            }}
          />

          {!localFile && (
            <>
              <div className="message_input_attach_icon">
                <input
                  ref={docRef}
                  onChange={handleDoc}
                  type="file"
                  accept={`application/pdf`}
                  hidden
                />
                <img
                  src={Icon.attach_pin}
                  onClick={(e) => {
                    e.preventDefault();
                    docRef?.current?.click();
                  }}
                  alt=""
                />
              </div>

              <div className="message_input_img_icon">
                <input
                  ref={mediaRef}
                  onChange={handleMedia}
                  type="file"
                  accept={`image/png, image/gif, image/jpeg, video/mp4, video/x-m4v, video/*`}
                  hidden
                />
                <img
                  src={Icon.attach_image}
                  onClick={(e) => {
                    e.preventDefault();
                    mediaRef?.current?.click();
                  }}
                  alt=""
                />
              </div>
            </>
          )}
          {(text || localFile) && (
            <button onClick={send} className="btn-send">
              <img src={Icon.Send} alt="send" />
            </button>
          )}
        </div>
      </div>
    );
  }
);
export default ChatInput;
