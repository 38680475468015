import React, { useState, useEffect } from "react";
import "../../../Assets/css/responsive.css";
import { useNavigate } from "react-router-dom";
import { Button, Container } from "react-bootstrap";
import { Icon } from "../../../Utilities/Icons";
import ConsultationList from "../userProfile/myConsutation/ConsultationList";
import Profile from "./Profile";
import My_Test_Bookings from "./myTestBookings/My_Test_Bookings";
import MedicineOrders from "./MedicineOrders/MedicineOrders";
import NewLabTest from "./NewLabTest/NewLabTest";
import Wallet from "./Wallet/Wallet";
import Address from "./Address/Address";
import MySubscriptions from "./MySubscriptions/MySubscriptions";
import Support from "./Support/Support";
import { useDispatch } from "react-redux";
import TabComponent from "../../Common/Tabs";
import { MedicalHistoryList } from "../../../Store/Reducer/BookingSlice";
import { RadiologyUpcomingBookings } from "../../../Store/Reducer/RadiologySlice";
import { HiOutlineMenu } from 'react-icons/hi';
import Preception from "./Preciption/Preception";
import Care from "./HelathCare/Care";
import GoldMembership from "./MySubscriptions/GoldMembership";
import Refer from "./Refer";
import HealthPackageProfile from "./HealthPackageProfile";

function ProfileSidebar() {
  const [activeKey, setActiveKey] = useState("profile");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [show, SetShow] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const tabs = [
    {
      title: "Profile",
      key: "profile",
      icon: Icon.user,
      component: () => <Profile />,
    },
    {
      title: "My Consultations",
      key: "my_Consultations",
      icon: Icon.Consultations,
      component: () => <ConsultationList />,
    },
    {
      title: "New Lab Test",
      key: "my_Test_Bookings",
      icon: Icon.NewLabTest,

      component: () => <My_Test_Bookings />,
    },
    {
      title: "My Health Care  ",
      key: "My Health Care ",
      icon: Icon.care,
      component: () => <Care/>,
    },
    {
      title: "Health Package Booking ",
      key: "Health Package Booking ",
      icon: Icon.care,
      component: () => <HealthPackageProfile/>,
    },
    {
      title: "Medicine Orders",
      key: "medicine_Orders",
      icon: Icon.MedicineOrders,
      component: () => <MedicineOrders />,
    },
    {
      title: "New  Radiology Test ",
      key: "New_Lab_Test",
      icon: Icon.TestBookings,
      component: () => <NewLabTest />,
    },
    {
      title: "My Prescription ",
      key: "My Prescription",
      icon: Icon.perciption,
      component: () => <Preception />,
    },
    {
      title: "Wallet",
      key: "wallet",
      icon: Icon.Wallet,
      component: () => <Wallet />,
    },
    {
      title: "Address",
      key: "address",
      icon: Icon.Address,
      component: () => <Address />,
    },
    {
      title: "My Gold_Membership",
      key: "My Gold_Membership",
      icon: Icon.MySubscriptions,
      component: () => <GoldMembership/>,
    },
    {
      title: "My Subscriptions",
      key: "my_Subscriptions",
      icon: Icon.MySubscriptions,
      component: () => <MySubscriptions />,
    },
    {
      title: "Refer and Earn",
      key: "Refer and Earn",
      icon: Icon.share,
      component: () => <Refer />,
    },
    {
      title: "Support",
      key: "support",
      icon: Icon.Support,
      component: () => <Support />,
    },
    {
      title: "Log Out",
      key: "log_Out",
      icon: Icon.LogOut,
      component: () => <ConsultationList />,
    },
  ];

  useEffect(() => {
    dispatch(MedicalHistoryList());
    dispatch(RadiologyUpcomingBookings());
  }, [dispatch]);
  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth <= 767);
    }
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      <Container fluid className="chat_container">
        <Container>
          <div className="row">
            <div className="profilesidebarmain col-md-12">
              <Button
                className="back_btn"
                onClick={() => {
                  navigate(-1);
                }}
              >
                <img src={Icon.backarrow} className="back_btn_icon"></img>{" "}
                <span className="back_btn_text">Back</span>
              </Button>
              <Button className="back_btns togglesidebar" onClick={() => SetShow(!show)}>
                <HiOutlineMenu className="back_btn_texts" />
              </Button>
            </div>
            <div className="col-md-12 p-3">
            </div>
          </div>
          <div>
            {isMobile ? <TabComponent tabs={tabs} active="profile" showTab={show} setShow={() => { SetShow(false) }} />
              :
              <TabComponent tabs={tabs} active="profile"/>
            }
          </div>

        </Container>
      </Container>
    </>
  );
}
export default ProfileSidebar;
